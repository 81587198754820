import React from "react";
import {
  Select as MSelect,
  FormControl as MFormControl,
  InputLabel as MInputLabel,
  MenuItem as MOptions,
} from "@mui/material";
import produce from "immer";

const MSelectbox = (props) => {
  const formControlProps = produce(props, (draft) => {
    delete draft.children;
    delete draft.data;
  });

  const data = props.data || [];
  const options = props.options || {};
  const matchCd = options.matchCd || "id";
  const matchNm = options.matchNm || "text";

  return (
    <MFormControl {...formControlProps}>
      {React.Children.map(props.children, (child) => {
        if (child.type.muiName === "Select") {
          return (
            <MSelect {...child.props}>
              {data.map((option, index) => {
                return (
                  <MOptions key={index} value={option[matchCd]}>
                    {option[matchNm]}
                  </MOptions>
                );
              })}
            </MSelect>
          );
        } else {
          return <MInputLabel {...child.props} />;
        }
      })}
    </MFormControl>
  );
};

export default MSelectbox;
