import WijmoGrid from "components/common/element/WijmoGrid";
import CommonUtils from "components/common/utils/CommonUtils";
import {
  AdminSettingButton,
  AdminSettingContents,
} from "components/setting/config/AdminSettingMain";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import CodeService from "services/common/CodeService";
import styled from "styled-components";

const columns = [
  {
    field: "codeMstCd",
    headerName: "코드 분류",
    width: 150,
  },
  {
    field: "codeMstNm",
    headerName: "코드 명",
    width: "2*",
  },
  {
    field: "codeMstType",
    headerName: "코드 타입",
    width: 150,
  },
  {
    field: "moduleCd",
    headerName: "모듈 코드",
    width: 150,
  },
  {
    field: "updtUserId",
    headerName: "최종 수정자",
    width: "*",
  },
  {
    field: "updtDt",
    headerName: "최종 수정 일자",
    width: 200,
    renderCell: (params) => `${CommonUtils.getDate(params.updtDt, "datetime")}`,
    // valueGetter: (params) =>
    //   `${CommonUtils.getDate(params.row.updtDt, "datetime")}`,
  },
];

const CodeManageBox = styled(AdminSettingContents)`
  width: 100%;
`;

function SystemCodeManage() {
  const [codeList, setCodeList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageSize, setPageSize] = useState(20);
  useEffect(() => {
    getCodeList();
  }, []);

  const getCodeList = () => {
    setIsLoading(true);
    CodeService.getCodeList({}, (res) => {
      setIsLoading(false);
      setCodeList(res.data);
    });
  };

  return (
    <>
      <CodeManageBox>
        <WijmoGrid
          isLoading={isLoading}
          columns={columns}
          rows={codeList}
          pageSize={pageSize}
          rowsPerPageOptions={[pageSize]}
          style={{ height: "70vh" }}
        />
      </CodeManageBox>
      <AdminSettingButton>
        <Button>저장</Button>
      </AdminSettingButton>
    </>
  );
}

export default SystemCodeManage;
