import React, { useContext } from "react";
import styled, { css } from "styled-components";
import { SettingContext } from "components/setting/SettingMenu";

export const SettingButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  padding-right: 20px;
  align-items: center;
  & button {
    max-height: 35px;
  }
`;

export const SettingSearchBar = styled.div`
  display: flex;
  gap: 10px;
  & span {
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & input {
    border: 1px solid lightgray;
    border-radius: 5px;
    padding-left: 10px;
  }
`;

function Contents() {
  const { selectedMenu } = useContext(SettingContext);
  return (
    <div className="setting-config-box">
      {selectedMenu.component ? (
        <selectedMenu.component title={selectedMenu.title} />
      ) : (
        "메뉴를 선택해주세요"
      )}
    </div>
  );
}

export default Contents;

export const ContentsRow = styled.div`
  width: 100%;
  display: flex;
  min-height: 10%;
  border-bottom: 1px solid lightgray;

  & > div {
    display: flex;
    align-items: center;
    padding-left: 5%;
  }
  .title {
    width: 30%;
  }
  .contents {
    width: 70%;
    display: flex;
    align-items: center;
    gap: 10px;
    & > * {
      cursor: ${(props) => (props.disabled ? "not-allowed" : "auto")};
    }

    & input {
      border: 1px solid lightgray;
      border-radius: 8px;
      height: 70%;
      padding-left: 10px;
    }

    & select {
      min-width: 200px;
      border: 1px solid lightgray;
      border-radius: 8px;
      height: 70%;
    }
  }
`;

export const ContentsLabel = ({ ...props }) => {
  return (
    <div className="contents-row" style={{ minHeight: "5%", border: "none" }}>
      <div className="title">{props.children}</div>{" "}
    </div>
  );
};

export const ContentsItem = ({ title, disabled, style, ...props }) => {
  if (disabled) {
    return (
      <div
        className={`contents-row ${disabled ? "disabled" : ""}`}
        style={style}
      >
        <div className="title">{title}</div>
        <div className="contents" style={{ position: "relative" }}>
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              background: "#3c3c3c33",
            }}
          />
          {props.children}
        </div>
      </div>
    );
  } else {
    return (
      <div className="contents-row" style={style}>
        <div className="title">{title}</div>
        <div className="contents">{props.children}</div>
      </div>
    );
  }
};
