import { Accordion, Button, Form } from "react-bootstrap";
import React from "react";
import { RiDeleteRow, RiInsertRowBottom } from "react-icons/ri";
import { BiDetail } from "react-icons/bi";

import LayoutComponent from "components/builder/ui/uiComponents/layout/LayoutComponent";
import {
  ListWidget,
  PropertyLable,
  PropertyValue,
  PropertyValueSet,
} from "components/builder/ui/uiComponents/UIComponentStyle";
import {
  StringUtils,
  ArrayUtils,
  JsonUtils,
  NumberUtils,
  ObjectUtils,
} from "components/common/utils/CommonUtils";
import UIComponentSection from "components/builder/ui/editor/UIComponentSection";
import USelectbox from "components/common/element/USelectbox";
import UITemplateHelper from "components/builder/ui/editor/helper/UITemplateHelper";
import Popup from "components/common/Popup";
import { AppContext } from "components/common/AppContextProvider";
import UTextarea from "components/common/element/UTextarea";
import StepDetailConfigPopup from "page/popup/StepDetailConfigPopup";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { Enums } from "components/builder/BuilderEnum";
import Message from "components/common/Message";
import produce from "immer";

class StepWizardContainer extends LayoutComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      childComp: [],
      stepContHeight: "auto",
    };
  }
  static contextType = AppContext;

  /**
   * determine re-rendering
   * @param {*} nextProps
   * @param {*} nextState
   * @returns
   */
  shouldComponentUpdate(nextProps, nextState) {
    //tab 변경시 editor refresh 허용
    if (nextProps.event === "renderEditor") {
      return (
        super.shouldComponentUpdate(nextProps, nextState) ||
        this.props.activedStep !== nextProps.activedStep ||
        nextState.stepContHeight !== this.state.stepContHeight
      );
    } else if (nextProps.event === "renderPropertiesPanel") {
      return super.shouldComponentUpdate(nextProps, nextState);
    }
  }

  /**
   * load후 실행
   */
  componentDidMount() {
    if (this.props.event === "renderEditor") {
      this.setStepContHeight();
    } else if (this.props.event === "renderPropertiesPanel") {
      this.setState({ childComp: this.props.fn.getChild() });
    }
  }

  /**
   * [Properties]
   * element 추가
   * @param {*} element
   */
  onInsertCompClick = (element) => {
    let defaultTabProps = {
      //id: StringUtils.getUuid(),
      id: this.state.propertyValue.id + "s" + (this.state.childComp.length + 1),
    };
    this.props.fn.addComponent(defaultTabProps, "A", element);
  };

  /**
   * [Properties]
   * element 삭제
   * @param {*} element
   */
  onDeleteCompClick = (element) => {
    this.props.fn.deleteComponent(element);
  };

  /**
   * [Properties]
   * 신규 element
   */
  onNewCompClick = () => {
    const componentContext = this.context.component;
    /* template 용 표준 base components - e.g) page, form, row, col... */
    const templateComponents =
      UITemplateHelper.getTemplateComponents(componentContext);

    const inputTemplate = UITemplateHelper.getTabTemplate(templateComponents);
    this.onInsertCompClick(inputTemplate);
  };

  /**
   * Setp Wizard Type 변경시...
   * [Properties]
   * @param {} event
   */
  onChangeStepWizardType = (event) => {
    this.onChangePropertyValue(event.target.id, event.target.value);
    //통합 step일 경우..child programId, url을 지워야하는데...
  };

  /**
   * Setp Design 유형 변경시...
   * [Properties]
   * @param {*} event
   */
  onChangeStepDesignType = (event) => {
    //template 조회
    let templateProps = UITemplateHelper.getStepWizardTemplate(
      event.target.value
    );
    templateProps[event.target.id] = event.target.value;
    this.onChangePropertyValues(templateProps);
  };

  /**
   * [Properties]
   * Tab 상세 설정 버튼 클릭
   * @param {*} event
   */
  onStepDetailClick = (index) => {
    const options = {
      keyDownEvent: false,
      style: {
        content: {
          width: "50%",
        },
      },
    };
    const element = this.state.childComp[index];
    const eventWorkspace = element.propertyValue.eventWorkspace || {};
    const eventName = "displayFnc";

    const getEventInfo = () => {
      return {
        eventWorkspace: eventWorkspace[eventName] || {},
        compId: element.compId,
        eventCd: "step.display",
        eventType: Enums.EventHandlerEventType.USR_EVENT_FUNCTION,
        builderEventType: eventName,
        targetType: "all",
        originalOutput: element.propertyValue[eventName] || "",
        programType: this.props.output.page.propertyValue?.programType || "M",
      };
    };

    Popup.open(
      <StepDetailConfigPopup
        workspace={this.context.workspace.Info}
        modalTitle="Step 상세 설정"
        item={element.propertyValue}
        containerProps={this.state.propertyValue}
        callbackFnc={(backItems) => {
          if (
            !ObjectUtils.isEmpty(eventWorkspace[eventName]) &&
            element.propertyValue[eventName].replace("\n", "") !==
              backItems[eventName].replaceAll("\n", "")
          ) {
            //함수가 달라지는 경우에 이벤트워크스페이스가 있으면 초기화
            this.showEventChangeConfirmMessage(
              () => {
                delete backItems.eventWorkspace;
                this.props.fn.updateProperty(
                  { ...backItems },
                  { ...element },
                  true
                );
              },
              () => {
                this.props.fn.updateProperty(
                  { ...backItems },
                  { ...element },
                  true
                );
              }
            );
          } else {
            this.props.fn.updateProperty(
              { ...backItems },
              { ...element },
              true
            );
          }
        }}
        onClickEventBuilder={() => {
          this.props.fn.onClickEventBuilder(getEventInfo());
        }}
        getEventInfo={getEventInfo}
      />,
      options
    );
  };

  /**
   * [Editor]
   * Step Content 영역의 높이를 사용자 화면 높이에 맞춘다.
   */
  setStepContHeight = () => {
    let space = 20;
    let mHeight = space + this.stepCont.getBoundingClientRect().y;
    //step contents 영역 높이지정
    let currentStep = this.getCurrentStep();
    if (!ArrayUtils.isEmpty(currentStep)) {
      mHeight += 40;
    }
    this.setState({ stepContHeight: "calc((100vh - " + mHeight + "px)" });
  };

  /**
   * [Editor]
   * Step 정보 변경
   * @param {*} event
   * @param {*} element
   */
  onChangeStep = (event, index) => {
    const element = this.state.childComp[index];

    if (element.propertyValue[event.target.id] === event.target.value) return;

    let propertyValue = {
      ...element.propertyValue,
      ...{ [event.target.id]: event.target.value },
    };
    this.props.fn.updateProperty(propertyValue, element, true);
  };

  /**
   * [Editor]
   * Step wizard의 template text를 변환해서 화면은 redering한다.
   * @param {*} stepProps
   * @param {*} tabindex
   * @returns
   */
  renderStepTemplate = (stepProps, tabindex) => {
    let wizardProps = this.props.componentInfo.propertyValue || {};

    if (StringUtils.isEmpty(wizardProps.stepTemplate)) {
      return "<div>Template을 찾을 수 없습니다.</div>";
    } else {
      let stepTemplate = wizardProps.stepTemplate;

      var regexp = new RegExp("{step}", "gi");
      stepTemplate = stepTemplate.replace(regexp, tabindex + 1);

      Object.keys(stepProps).map((key, index) => {
        regexp = new RegExp("{" + key + "}", "gi");
        stepTemplate = stepTemplate.replace(regexp, stepProps[key]);
      });
      return stepTemplate;
    }
  };

  /**
   * [Editor]
   * 현재 active되어있는 step
   * @returns
   */
  getCurrentStep = () => {
    if (ArrayUtils.isEmpty(this.props.componentInfo.child)) return null;
    let currentStep = this.props.componentInfo.child.filter(
      (step) =>
        step.propertyValue &&
        step.propertyValue.id === this.props.activedStep &&
        step.propertyValue.buttons !== "none"
    );

    return currentStep;
  };

  /**
   * [Editor]
   * 이전 Page 이동
   * @param {*} event
   * @param {*} currStepIndex
   */
  gotoPrevious = (event, currStepIndex) => {
    if (currStepIndex > 0) {
      let stepProps =
        this.props.componentInfo.child[currStepIndex - 1].propertyValue || {};
      this.props.onStepChange(event, stepProps);
    }
  };

  /**
   * [Editor]
   * 다음 페이지 이동
   * @param {*} event
   * @param {*} currStepIndex
   */
  gotoNext = (event, currStepIndex) => {
    if (this.props.componentInfo.child.length >= currStepIndex + 1) {
      let stepProps =
        this.props.componentInfo.child[currStepIndex + 1].propertyValue || {};
      this.props.onStepChange(event, stepProps);
    }
  };
  /**
   * [Editor]
   * Button 생성
   * @returns
   */
  renderStepButtons = () => {
    let currentStep = this.getCurrentStep();
    if (!ArrayUtils.isEmpty(currentStep)) {
      let steps = this.props.componentInfo.child;
      let stepIndex = steps.indexOf(currentStep[0]);
      return (
        <div className="step-wizard-buttons">
          <Button
            variant="primary"
            disabled={stepIndex === 0}
            onClick={(e) => this.gotoPrevious(e, stepIndex)}
          >
            <FaChevronLeft />
            {"  "}이전
          </Button>
          <Button
            variant="primary"
            disabled={stepIndex === steps.length - 1}
            onClick={(e) => this.gotoNext(e, stepIndex)}
          >
            다음{"  "}
            <FaChevronRight />
          </Button>
        </div>
      );
    }
  };

  /**
   * Properties tab panel을 Redering
   * @returns
   */
  renderPropertiesPanel = () => {
    return (
      <React.Fragment>
        {/* Title */}
        {this.renderComponentTitle("Step Container")}
        <Accordion defaultActiveKey={[0, 1, 2]} alwaysOpen>
          <Accordion.Item eventKey={0}>
            <Accordion.Header>기본정보</Accordion.Header>
            <Accordion.Body>
              <PropertyLable>ID</PropertyLable>
              <PropertyValue>
                <input
                  type="text"
                  id="id"
                  defaultValue={StringUtils.defaultString(
                    this.state.propertyValue.id
                  )}
                  className="form-control form-control-sm"
                  onBlur={this.onChange}
                />
              </PropertyValue>
              <PropertyLable>Step 프로그램</PropertyLable>
              <PropertyValue>
                <USelectbox
                  id="stepWizardType"
                  defaultValue={StringUtils.defaultString(
                    this.state.propertyValue.stepWizardType
                  )}
                  items={[
                    { id: "A", text: "통합 Step 프로그램" },
                    { id: "S", text: "개별 Step 프로그램" },
                  ]}
                  options={{ matchCd: "id", matchNm: "text" }}
                  onChange={this.onChangeStepWizardType}
                />
              </PropertyValue>
              <PropertyLable>Step 디자인</PropertyLable>
              <PropertyValue>
                <USelectbox
                  id="stepDesignType"
                  defaultValue={StringUtils.defaultString(
                    this.state.propertyValue.stepDesignType
                  )}
                  items={[
                    { id: "S", text: "Simple형" },
                    { id: "B", text: "기본형" },
                  ]}
                  options={{ matchCd: "id", matchNm: "text" }}
                  onChange={this.onChangeStepDesignType}
                />
              </PropertyValue>
              <PropertyLable>최초활성화 Step</PropertyLable>
              <PropertyValue>
                <input
                  type="number"
                  id="activeStep"
                  defaultValue={NumberUtils.defaultValue(
                    this.state.propertyValue.activeStep,
                    1
                  )}
                  className="form-control form-control-sm"
                  onBlur={this.onChange}
                />
              </PropertyValue>
              <PropertyLable>Step Template</PropertyLable>
              <PropertyValue>
                <UTextarea
                  popTitle="Step Template"
                  textareaId="stepTemplate"
                  fieldType="template"
                  isEventBuilder={false}
                  defaultValue={StringUtils.defaultString(
                    this.state.propertyValue.stepTemplate
                  )}
                  onBlur={this.onChange}
                />
              </PropertyValue>
              <PropertyLable>추가 class</PropertyLable>
              <PropertyValue>
                <input
                  type="text"
                  id="css"
                  defaultValue={StringUtils.defaultString(
                    this.state.propertyValue.css
                  )}
                  className="form-control form-control-sm"
                  onBlur={this.onChange}
                />
              </PropertyValue>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey={1}>
            <Accordion.Header>Steps</Accordion.Header>
            <Accordion.Body>
              <PropertyValue className="w-full">
                {!ArrayUtils.isEmpty(this.state.childComp) ? (
                  this.state.childComp.map((element, index) => {
                    return (
                      <PropertyValueSet key={index}>
                        <input
                          type="text"
                          id="title"
                          placeholder="New Step"
                          defaultValue={StringUtils.defaultString(
                            element.propertyValue.title
                          )}
                          className="form-control form-control-sm"
                          onBlur={(e) => this.onChangeStep(e, index)}
                        />
                        <Button
                          onClick={() => this.onStepDetailClick(index)}
                          size="sm"
                          variant={"outline-secondary"}
                          className="light-font-color"
                        >
                          <BiDetail size="20" />
                        </Button>
                        <Button
                          onClick={() => this.onInsertCompClick(element)}
                          size="sm"
                          variant={"outline-secondary"}
                          className="light-font-color"
                        >
                          <RiInsertRowBottom size="20" />
                        </Button>
                        <Button
                          onClick={() => this.onDeleteCompClick(element)}
                          size="sm"
                          variant={"outline-secondary"}
                          className="light-font-color"
                        >
                          <RiDeleteRow size="20" />
                        </Button>
                      </PropertyValueSet>
                    );
                  })
                ) : (
                  <Button
                    onClick={() => this.onNewCompClick()}
                    size="sm"
                    variant={"outline-secondary"}
                    className="light-font-color"
                  >
                    <RiDeleteRow size="20" /> 신규 추가
                  </Button>
                )}
              </PropertyValue>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey={2}>
            <Accordion.Header>Event</Accordion.Header>
            <Accordion.Body>
              <div className="event-list-props">
                <div className="event-list-group">
                  <ListWidget
                    title="Step 변경 전"
                    desc="Step 변경 전에 실행 됩니다. return이 false일 경우 Step을 변경 할 수 없습니다."
                  >
                    {this.renderEventTextArea(
                      "beforeChange",
                      "Step 변경전 사용자 처리 함수",
                      {
                        eventCd: "step.change",
                        eventType: "before",
                        returnType: "boolean",
                      }
                    )}
                  </ListWidget>
                  <ListWidget
                    title="Step 변경 후"
                    desc="Step 변경 후에 실행 됩니다"
                  >
                    {this.renderEventTextArea(
                      "onChange",
                      "Step 변경 이후 사용자 처리 함수",
                      {
                        eventCd: "step.change",
                        eventType: "after",
                      }
                    )}
                  </ListWidget>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>

          {/* Style property */}
          {this.renderStylePanel("Row", 3)}
        </Accordion>
      </React.Fragment>
    );
  };

  /**
   * Editor의 component를 Redering
   * << Layout editor props>>
   *   - compId - 현재 component의 고유 ID
   *   - componentInfo - drag & drop시 생성된 component object
   *   - style - dragging style이 포함된 style (사용자가 정의한 style[propertyValue.style]은 각 component에서 적절히 적용해야함)
   *   - event="renderEditor" - 요청 구분
   * @returns
   */
  renderEditor = () => {
    /**
     * UIComponentSection props
     * -item
     * -style
     * -className
     */

    return (
      <UIComponentSection
        item={this.props.componentInfo}
        style={{
          ...this.props.style,
          ...this.getPropsStyle(this.props.componentInfo),
        }}
        className={`editor-base draggable editor-step-wizard ${StringUtils.defaultString(
          this.state.editorAttr.className,
          "builder-step"
        )} ${JsonUtils.defaultString(
          this.props.componentInfo.propertyValue,
          "css"
        )}`}
        title="STEP WIZARD"
      >
        <div className="step-wrap">
          {!ArrayUtils.isEmpty(this.props.componentInfo.child)
            ? this.props.componentInfo.child.map((step, tabindex) => {
                const stepProps = step.propertyValue || {};
                const stepId = StringUtils.defaultString(
                  stepProps.id,
                  step.compId
                );
                return (
                  <div
                    key={stepId}
                    data-toggle="tab"
                    role="tab"
                    className={`step-item ${
                      stepId === this.props.activedStep ? "on active" : ""
                    }`}
                    onClick={(e) => {
                      if (stepId !== this.props.activedStep)
                        this.props.onStepChange(e, stepProps);
                    }}
                    dangerouslySetInnerHTML={{
                      __html: this.renderStepTemplate(stepProps, tabindex),
                    }}
                  />
                );
              })
            : ""}
        </div>
        <div
          className="step-content tab-content"
          ref={(stepCont) => {
            this.stepCont = stepCont;
          }}
          style={{ height: this.state.stepContHeight }}
          id={`step-content${this.props.componentInfo.compId}`}
        >
          {this.props.children}
        </div>
        {this.renderStepButtons()}
      </UIComponentSection>
    );
  };
}
export default StepWizardContainer;
