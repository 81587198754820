/*!
 * Builder UI Builder Reducers for react v.17
 *
 * UI Builder reducer만 취합 관리
 * - state 명 subfix 는 UI (ENT : Entity Builder, API Builder : API, Report Builder : RPT)
 *
 *   Author: Bizentro
 *   Date: 2021-04
 */

import ActivedComponentReducer from "components/builder/entity/reducers/ActivedComponentReducer";
import EntityBuilderOutputReducer from "components/builder/entity/reducers/EntityBuilderOutputReducer";

const EntityBuilderReducers = {
  activedENTComponent: ActivedComponentReducer,
  outputENT: EntityBuilderOutputReducer,
};

export default EntityBuilderReducers;
