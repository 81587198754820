import React from "react";
import "css/builder/workflow.css";
import { ReactFlowProvider } from "reactflow";
import WorkflowBuilder from "components/builder/workflow/WorkflowBuilder";

function WorkflowMain() {
  return (
    <ReactFlowProvider>
      <WorkflowBuilder />
    </ReactFlowProvider>
  );
}

export default WorkflowMain;
