import MuiConfig from "components/common/config/MuiConfig";
import WijmoGrid from "components/common/element/WijmoGrid";
import Modal from "components/common/modal/UModal";
import UmodalTemplate from "components/common/modal/UModalTemplate";
import CommonUtils from "components/common/utils/CommonUtils";

const UnremovableEntityProgramListPopup = ({ programList }) => {
  const columns = [
    { field: "programId", headerName: "프로그램 아이디", width: 150 },
    {
      field: "tenantId",
      headerName: "Tenant ID",
      width: 130,
    },
    {
      field: "coCd",
      headerName: "회사 코드",
      width: 130,
    },
    {
      field: "programNm",
      headerName: "프로그램 명",
      width: "*",
    },
    {
      field: "updtUserId",
      headerName: "최종 수정자",
      width: 125,
    },
    {
      field: "updtDt",
      headerName: "최종 수정일",
      width: 125,
      renderCell: (params) => `${CommonUtils.getDate(params.updtDt)}`,
    },
  ];

  return (
    <Modal>
      <Modal.Header title={"사용중인 프로그램 목록"} />
      <Modal.Body>
        <UmodalTemplate>
          <div style={{ height: 310 }}>
            <WijmoGrid
              columns={columns}
              getRowId={(row) => row.programId}
              rows={programList}
              {...MuiConfig.grid.options}
              pageSize={20}
              rowsPerPageOptions={[20]}
              style={{ height: `calc(30vh + 20px)` }}
            />
          </div>
          <span className="blink warning-text">
            위 프로그램에서 사용중이기 때문에 삭제할 수 없습니다. 사용중인
            프로그램에서 해당 모델을 변경 또는 삭제 후 시도해주세요
          </span>
        </UmodalTemplate>
      </Modal.Body>
      <Modal.Footer />
    </Modal>
  );
};

export default UnremovableEntityProgramListPopup;
