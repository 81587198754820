class NumberUtils {
  /**
   * check numeric
   * option
   *   - undefined or "1" : 모든 10진수 (부호 선택, 자릿수구분기호 선택, 소수점 선택)
   *   - "2" : 부호 미사용, 자릿수구분기호 선택, 소수점 선택
   *   - "3" : 부호 미사용, 자릿수구분기호 미사용, 소수점 선택
   *   - "기타값" :  only 숫자만(부호 미사용, 자릿수구분기호 미사용, 소수점 미사용)
   * @param {*} number
   * @param {*} option
   * @returns
   */
  static isNumeric = (number, option) => {
    // 좌우 trim(공백제거)을 해준다.
    let num = String(number).replace(/^\s+|\s+$/g, "");

    // only 숫자만(부호 미사용, 자릿수구분기호 미사용, 소수점 미사용)
    let regex = /^[0-9]$/g;

    if (typeof option == "undefined" || option === "1") {
      // 모든 10진수 (부호 선택, 자릿수구분기호 선택, 소수점 선택)
      regex = /^[+-]?(([1-9][0-9]{0,2}(,[0-9]{3})*)|[0-9]+){1}(\.[0-9]+)?$/g;
    } else if (option === "2") {
      // 부호 미사용, 자릿수구분기호 선택, 소수점 선택
      regex = /^(([1-9][0-9]{0,2}(,[0-9]{3})*)|[0-9]+){1}(\.[0-9]+)?$/g;
    } else if (option === "3") {
      // 부호 미사용, 자릿수구분기호 미사용, 소수점 선택
      regex = /^[0-9]+(\.[0-9]+)?$/g;
    }

    if (regex.test(num)) {
      num = num.replace(/,/g, "");
      return isNaN(num) ? false : true;
    } else {
      return false;
    }
  };

  /**
   * defaultValue
   * @param {number} number
   * @param {number} repNum
   * @returns
   */
  static defaultValue = (number, repNum) => {
    return isNaN(number) ? repNum : number;
  };
}

export default NumberUtils;
