import React, { useEffect } from "react";
import Modal from "components/common/modal/UModal";
import UmodalTemplate from "components/common/modal/UModalTemplate";
import { Button, Col, Form, FormGroup, FormLabel, Row } from "react-bootstrap";
import { useState } from "react";
import Message from "components/common/Message";
import AppService from "services/common/AppService";
import Popup from "components/common/Popup";
import { Enums } from "components/builder/BuilderEnum";
import styled from "styled-components";
import { FaTrash } from "react-icons/fa";

const ApplyModuleBox = styled.div`
  min-height: 150px;
  overflow: auto;
`;

const ApplyModuleRow = styled.div`
  display: flex;
  height: 35px;
  border-bottom: 1px solid lightgray;
  & > div {
    display: flex;
    align-items: center;
    width: 100%;
  }
`;

function TenantEditPopup({
  tenantMst,
  callback,
  releaseList: _releaseList,
  ...props
}) {
  const [tenantNm, setTenantNm] = useState(tenantMst.tenantNm);
  const [tenantId, setTenantId] = useState(tenantMst.tenantId);
  const [coCd, setCoCd] = useState(tenantMst.coCd);
  const [coNm, setCoNm] = useState(tenantMst.coNm);
  const [releaseList, setReleaseList] = useState(_releaseList || []);

  const onEditTenant = () => {
    Message.confirm(
      `${tenantMst.tenantNm}와 관련한 내용을 일괄적으로 변환합니다.`,
      () => {
        const body = {
          targetTenantNm: tenantMst.tenantNm,
          targetTenantId: tenantMst.tenantId,
          targetCoCd: tenantMst.coCd,
          targetCoNm: tenantMst.coNm,
          tenantNm: tenantNm,
          tenantId: tenantId,
          coCd: coCd,
          coNm: coNm,
          appReleaseList: releaseList,
        };

        AppService.updateTenantMst(body, (res) => {
          Message.alert(res.message, Enums.MessageType.SUCCESS);
          Popup.close();
          if (callback) {
            callback();
          }
        });
      }
    );
  };

  const onDeleteTenant = (e, release) => {
    const { appReleaseId } = release;
    const index = releaseList.findIndex((a) => a.appReleaseId === appReleaseId);
    if (release.deletedYn === "Y") {
      release.deletedYn = "N";
    } else {
      release.deletedYn = "Y";
    }
    const newList = [...releaseList];
    newList.splice(index, 1, release);
    setReleaseList(newList);
  };

  return (
    <Modal>
      <Modal.Header title={"테넌트 수정"} />
      <Modal.Body>
        <UmodalTemplate>
          <Row className="mb-3 gy-3">
            <FormGroup as={Col} xs={6}>
              <FormLabel className="required">Tenant ID</FormLabel>
              <Form.Control
                value={tenantId}
                onChange={(e) => setTenantId(e.currentTarget.value)}
              />
            </FormGroup>
            <FormGroup as={Col} xs={6}>
              <FormLabel className="required">Tenant 이름</FormLabel>
              <Form.Control
                value={tenantNm}
                onChange={(e) => setTenantNm(e.currentTarget.value)}
              />
            </FormGroup>
            <FormGroup as={Col} xs={6}>
              <FormLabel className="required">회사 코드</FormLabel>
              <Form.Control
                value={coCd}
                onChange={(e) => setCoCd(e.currentTarget.value)}
              />
            </FormGroup>
            <FormGroup as={Col} xs={6}>
              <FormLabel className="required">회사 이름</FormLabel>
              <Form.Control
                value={coNm}
                onChange={(e) => setCoNm(e.currentTarget.value)}
              />
            </FormGroup>

            <FormGroup as={Col} xs={12} className="form-control">
              <FormLabel className="required">적용된 모듈 버전</FormLabel>
              <ApplyModuleBox>
                <ApplyModuleRow>
                  <div>모듈 명</div>
                  <div>버전</div>
                  <div></div>
                </ApplyModuleRow>
                {releaseList.map((release) => {
                  return (
                    <ApplyModuleRow>
                      {" "}
                      <div>
                        {release.moduleNm}{" "}
                        {release.deletedYn === "Y" && (
                          <FaTrash size={15} color="tomato" />
                        )}
                      </div>
                      <div>{release.version}</div>
                      <div>
                        {release.deletedYn === "Y" ? (
                          <Button
                            variant="outline-primary"
                            onClick={(e) => onDeleteTenant(e, release)}
                          >
                            삭제 취소
                          </Button>
                        ) : (
                          <Button
                            variant="outline-danger"
                            onClick={(e) => onDeleteTenant(e, release)}
                          >
                            삭제
                          </Button>
                        )}
                      </div>
                    </ApplyModuleRow>
                  );
                })}
              </ApplyModuleBox>
            </FormGroup>
          </Row>
        </UmodalTemplate>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Button onClick={onEditTenant}>수정</Modal.Footer.Button>
      </Modal.Footer>
    </Modal>
  );
}

export default TenantEditPopup;
