import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { Enums } from "components/builder/BuilderEnum";
import Message from "components/common/Message";
import Modal from "components/common/modal/UModal";
import UModalTemplate from "components/common/modal/UModalTemplate";
import Popup from "components/common/Popup";
import JsonUtils from "components/common/utils/JsonUtils";
import StringUtils from "components/common/utils/StringUtils";
import produce from "immer";
import React, { useState } from "react";
import { useRef } from "react";
import { Form } from "react-bootstrap";
import AppService from "services/common/AppService";
import DataModelService from "services/datamodel/DataModelService";
import ProgramService from "services/ui/ProgramService";
import WorkflowService from "services/workflow/WorkflowService";

function ProgramExportPopup({ program, appList, ...props }) {
  const [isLoading, setIsLoading] = useState(false);
  const [appId, setAppId] = useState("");
  const [moduleCd, setModuleCd] = useState("");
  const [appReleaseId, setAppReleaseId] = useState("");
  const [moduleList, setModuleList] = useState([]);
  const [appReleaseList, setAppReleaseList] = useState([]);
  const [dataModelCopy, setDataModelCopy] = useState(false);
  const [workflowCopy, setWorkflowCopy] = useState(true);

  const programIdRef = useRef();

  const onChangeAppId = (e) => {
    const value = e.target.value;
    setAppId(value);
    setAppReleaseList([]);
    AppService.getAppModuleList({ appId: value }, (res) => {
      setModuleList(res.data);
      if (res.data.length > 0) {
        onChangeModuleCd(
          { target: { value: res.data[0].moduleCd } },
          { appId: value }
        );
      }
    });
  };

  const onChangeModuleCd = (e, _body) => {
    const value = e.target.value;
    setModuleCd(value);
    AppService.getReleaseList(
      { appId: _body ? _body.appId : appId, moduleCd: value },
      (res) => {
        setAppReleaseList(res.data);
        if (res.data.length > 0) {
          setAppReleaseId(res.data[0].appReleaseId);
        }
      }
    );
  };

  const onExportProgram = () => {
    if (StringUtils.isEmpty(programIdRef.current.value))
      return Message.alert(
        "저장될 프로그램 ID를 입력해주세요.",
        Enums.MessageType.INFO
      );
    Message.confirm(
      "현재 프로그램을 내보내기 하시겠습니까?\n 저장하지 않은 부분은 반영 되지 않습니다.",
      () => {
        setIsLoading(true);
        const newProgram = { ...program };
        //내보내기 from 정보
        const succeedSource = {
          appId: newProgram.appId,
          moduleCd: newProgram.moduleCd,
          appReleaseId: newProgram.appReleaseId,
        };
        //내보내기 to 정보
        const succeedTarget = {
          appId: appId,
          moduleCd: moduleCd,
          appReleaseId: appReleaseId,
        };

        succeedDataModel(succeedSource, succeedTarget, newProgram)
          .then((_newProgram) => {
            return succeedWorkflow(succeedSource, succeedTarget, _newProgram);
          })
          .then((_newProgram) => {
            saveProgram(_newProgram);
          })
          .catch((err) => setIsLoading(false));
      }
    );
  };

  /**
   * @param {Object} to
   * @param {Object} from
   * @param {Object} program 프로그램
   * @returns {Promise} 복사한 데이터 모델 목록
   */
  const succeedDataModel = (from, to, program) => {
    const dmModelNm = program.programContent.page.propertyValue.dataModelNm;
    return new Promise((resolve, reject) => {
      if (dataModelCopy) {
        from.dataModelId = program.dataModelId;
        from.dataModelNm = dmModelNm;
        DataModelService.succeedDataModel(
          {
            from,
            to,
          },
          (res) => {
            if (!res.isError) {
              if (
                res.data.merge &&
                StringUtils.equalsIgnoreCase(res.data.merge, "Y")
              ) {
                Message.alert(
                  "해당 위치에 동일한 이름으로 저장된 데이터 모델이 있기 때문에 해당 모델로 전환하였습니다.\n 프로그램 상세 정보를 확인해주세요",
                  Enums.MessageType.INFO
                );
              }
              const newProgramList =
                ProgramService.succeedDataModelToProgramContent(
                  { appReleaseId: appReleaseId },
                  res.data,
                  [program]
                );
              if (newProgramList.length > 0) resolve(newProgramList[0]);
              else reject("데이터 모델 전환작업이 정상적이지 않습니다.");
            } else {
              reject(res.message);
            }
          },
          (err) => reject(err)
        );
      } else {
        resolve(program);
      }
    });
  };

  /**
   * @param {Object} to
   * @param {Object} from
   * @returns {Promise} 복사한 데이터 모델 목록
   */
  const succeedWorkflow = (from, to, program) => {
    const { programContent } = program;
    return new Promise((resolve, reject) => {
      if (workflowCopy) {
        const serviceUidList = [];
        for (const sid of JsonUtils.findNodeValues(
          programContent.page,
          "serviceUid"
        )) {
          if (serviceUidList.indexOf(sid) === -1) {
            serviceUidList.push(sid);
          }
        }
        if (serviceUidList.length === 0) resolve(program);
        else
          WorkflowService.succeedWorkflow(
            {
              serviceUidList,
              from,
              to,
            },
            (res) => {
              if (!res.isError) {
                const { reuse, serviceUidMapper } = res.data;
                if (reuse)
                  Message.alert(
                    "해당 위치에 동일한 Service ID으로 저장된 워크플로우가 이미 있기 때문에 해당 모델로 전환하였습니다.\n 프로그램 상세 정보를 확인해주세요",
                    Enums.MessageType.INFO
                  );
                resolve(
                  ProgramService.succeedServiceUid(serviceUidMapper, program)
                );
              } else {
                reject(res.message);
              }
            },
            (err) => reject(err)
          );
      } else {
        resolve(program);
      }
    });
  };

  /**
   * 프로그램 저장
   * 배포 마지막 단계에서 저장
   * @param {*} _program
   */
  const saveProgram = (_program) => {
    const _updatedContent = produce(_program.programContent, (draft) => {
      draft.page.propertyValue.programId = programIdRef.current.value;
    });
    const succededProgram = produce(_program, (draft) => {
      draft.appId = appId;
      draft.moduleCd = moduleCd;
      draft.appReleaseId = appReleaseId;
      draft.tenantId = "*";
      draft.coCd = "*";
      draft.programUid = null;
      draft.newProgram = "Y";
      draft.appApplyType = "N";
      draft.programId = programIdRef.current.value;
      draft.programContent = JSON.stringify(_updatedContent);
    });
    ProgramService.saveProgram(
      succededProgram,
      (res) => {
        setIsLoading(false);
        Message.alert(
          "프로그램 내보내기가 실행되었습니다.",
          Enums.MessageType.SUCCESS
        );
        Popup.close();
      },
      () => setIsLoading(false)
    );
  };

  return (
    <Modal>
      <Modal.Header title={`${program.programNm} 내보내기`} />
      <Modal.Body>
        <UModalTemplate>
          <Form.Group className="mb-3">
            <Form.Label className="required">대상 Application 선택</Form.Label>
            <Form.Select value={appId} onChange={onChangeAppId}>
              <option value="">선택</option>
              {appList.map((app) => {
                return (
                  <option value={app.appId} key={app.appId}>
                    {app.appNm}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="required">대상 모듈 선택</Form.Label>
            <Form.Select value={moduleCd} onChange={onChangeModuleCd}>
              {moduleList.length === 0 ? (
                <option value={""}>Application을 선택해주세요.</option>
              ) : (
                moduleList.map((module) => {
                  return (
                    <option value={module.moduleCd} key={module.moduleCd}>
                      {module.moduleNm}
                    </option>
                  );
                })
              )}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="required">대상 버전 선택</Form.Label>
            <Form.Select>
              {appReleaseList.length === 0 ? (
                <option value={""}>모듈을 선택해주세요.</option>
              ) : (
                appReleaseList.map((release) => {
                  return (
                    <option
                      value={release.appReleaseId}
                      key={release.appReleaseId}
                    >
                      {release.version}
                    </option>
                  );
                })
              )}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="required">저장될 프로그램 ID</Form.Label>
            <Form.Control defaultValue={program.programId} ref={programIdRef} />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="required">데이터 모델 복사 여부</Form.Label>
            <div>
              <BootstrapSwitchButton
                id="dataModelCopy"
                checked={dataModelCopy}
                size="sm"
                onstyle="primary"
                offstyle="dark"
                onlabel="Yes"
                offlabel="No"
                onChange={(value) => setDataModelCopy(value)}
              />
            </div>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="required">
              프로그램 내 서비스( 워크플로우 ) 복사 여부
            </Form.Label>
            <div>
              <BootstrapSwitchButton
                id="workflowCopy"
                checked={workflowCopy}
                size="sm"
                onstyle="primary"
                offstyle="dark"
                onlabel="Yes"
                offlabel="No"
                onChange={(value) => setWorkflowCopy(value)}
              />
            </div>
          </Form.Group>
        </UModalTemplate>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.ProgressButton
          doing={isLoading}
          onClick={onExportProgram}
        >
          내보내기
        </Modal.Footer.ProgressButton>
      </Modal.Footer>
    </Modal>
  );
}

export default ProgramExportPopup;
