import { TreeItem, TreeView } from "@mui/lab";
import { Tooltip } from "@mui/material";
import StringUtils from "components/common/utils/StringUtils";
import User from "components/common/utils/UserUtils";
import React, { useContext, useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { MdOutlineExpandLess, MdOutlineExpandMore } from "react-icons/md";
import styled from "styled-components";
import { SettingContext } from "components/setting/SettingMenu";

function Tree() {
  const { selectedMenu, setSelectedMenu, TreeMenus } =
    useContext(SettingContext);
  const [searchTerm, setSearchTerm] = useState("");
  const [dpMenu, setdpMenu] = useState([]);
  const userAuth = useRef(User.getAuthType());

  useEffect(() => {
    setdpMenu(TreeMenus);
  }, []);

  const onClickTreeItem = (e, menu) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedMenu(menu);
  };

  const renderTree = (list) => {
    return list.map((menu, idx) => {
      if (menu.auth && menu.auth.indexOf(userAuth.current) === -1) {
        return null;
      } else {
        return (
          <TreeItem
            key={menu.key}
            nodeId={menu.key + idx}
            label={menu.title}
            onClick={(e) => onClickTreeItem(e, menu)}
          >
            {menu.child && renderTree(menu.child)}
          </TreeItem>
        );
      }
    });
  };

  const onChangeSearchTerm = (e) => {
    const value = String(e.target.value).toLowerCase();
    setSearchTerm(value);
    if (StringUtils.isEmpty(value)) {
      setdpMenu(TreeMenus);
    } else {
      const menuFilter = (list) => {
        const menus = [];
        for (let tree of list) {
          const key = tree.key.toLowerCase();
          const title = tree.title.toLowerCase();
          if (key.indexOf(value) > -1 || title.indexOf(value) > -1) {
            const menu = { ...tree };
            menus.push(menu);
          } else if (tree.child) {
            const menu = { ...tree };
            menu.child = menuFilter(menu.child);
            if (menu.child.length > 0) {
              menus.push(menu);
            }
          }
        }
        return menus;
      };
      const filtered = menuFilter(TreeMenus);
      setdpMenu(filtered);
    }
  };

  return (
    <div className="tree-box">
      <Tooltip title="메뉴 명 또는 키값을 입력해주세요" placement="top">
        <input
          value={searchTerm}
          onChange={onChangeSearchTerm}
          placeholder={"메뉴 명 입력..."}
          type="search"
          id="treeSearch"
        />
      </Tooltip>

      <TreeView
        defaultCollapseIcon={<MdOutlineExpandLess />}
        defaultExpandIcon={<MdOutlineExpandMore />}
        multiSelect={false}
        sx={{
          flexGrow: 1,
          height: "calc(100% - 30px)",
          overflowY: "auto",
          overflowX: "auto",
          border: "1px solid lightgray",
          borderRadius: "5px",
        }}
      >
        {dpMenu.length > 0 ? (
          renderTree(dpMenu)
        ) : (
          <TreeItem nodeId="empty" label="메뉴 목록이 없습니다." />
        )}
      </TreeView>
    </div>
  );
}

export default Tree;
