import { Enums } from "components/builder/BuilderEnum";
import WorkflowNodeList from "components/builder/workflow/components/WorkflowNodeList";
import {
  BundleNodeType,
  CodeNodeType,
  ConditionNodeType,
  ConnectorNodeType,
  IteratorNodeType,
  MemoNodeType,
  processEdgeType,
  ProcessNodeType,
  ServiceNodeType,
} from "components/builder/workflow/components/WorkflowNodeTypes";
import useWorkflowRender from "components/builder/workflow/editor/render/useWorkflowRender";
import Popup from "components/common/Popup";
import ArrayUtils from "components/common/utils/ArrayUtils";
import StringUtils from "components/common/utils/StringUtils";
import User from "components/common/utils/UserUtils";
import produce from "immer";
import ConnectorPopup from "page/popup/workflow/ConnectorPopup";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  MdDownload,
  MdOutlineNightlightRound,
  MdOutlineWbSunny,
} from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
  Background,
  ConnectionMode,
  ControlButton,
  Controls,
  MiniMap,
  ReactFlow,
  SelectionMode,
  useEdgesState,
  useNodesState,
  useOnViewportChange,
} from "reactflow";

import * as StompJs from "@stomp/stompjs";
import WorkflowCodeMirror from "components/builder/workflow/components/WorkflowCodeMirror";
import WorkflowConnectorEdge from "components/builder/workflow/components/WorkflowConnectorEdge";
import WorkflowReduxHelper from "components/builder/workflow/editor/helper/WorkflowReduxHelper";
import {
  setWFBreakpointType,
  setWFErrType,
  setWFInCommunication,
  setWFIsDebugging,
  setWFProcess,
  setWFTrace,
} from "components/builder/workflow/reducer/WorkflowDebugAction";
import { AppContext } from "components/common/AppContextProvider";
import Message from "components/common/Message";
import JsonUtils from "components/common/utils/JsonUtils";
import ObjectUtils from "components/common/utils/ObjectUtils";
import ConnectorValidationPopup from "page/popup/workflow/ConnectorValidationPopup";
import RunWorkflowPopup from "page/popup/workflow/RunWorkflowPopup";
import { useContext } from "react";
import WorkflowService from "services/workflow/WorkflowService";
import * as SockJS from "sockjs-client";
import { stopEvent } from "../ui/editor/handler/UIEditorEventHandler";
import { initCommand } from "../ui/reducers/CommandAction";
import WorkflowDebugConsole from "./components/WorkflowDebugConsole";
import WorkflowDebugExpression from "./components/WorkflowDebugExpression";
import WorkflowNoConnectorEdge from "./components/WorkflowNoConnectorEdge";
import useWorkflowDropEvent from "./editor/render/useWorkflowDropEvent";
import {
  getConnectorPosition,
  getNodeBetweenList,
} from "./editor/render/WorkflowRenderUtils";
import { updateService, updateViewport } from "./reducer/WorkflowAction";

import { CircularProgress } from "@mui/material";
import { toPng } from "html-to-image";
import ConnectorConditionPopup from "page/popup/workflow/ConnectorConditionPopup";
import RunWorkflowQuestPopup from "page/popup/workflow/RunWorkflowQuestPopup";
import { WorkflowContext } from "page/workflow";
import ConnectionService from "services/common/ConnectionService";
import LocalStorageService from "services/common/LocalService";
import WorkflowBundlingTab from "./components/WorkflowBundlingTab";
import WorkflowCommandButton from "./components/WorkflowCommandButton";
import WorkflowCsLogConsole from "./components/WorkflowCsLogConsole";
import WorkflowCsServiceList from "./components/WorkflowCsServiceList";

const nodeTypes = {
  [Enums.WorkflowNodeType.MEMO]: MemoNodeType, // 메모노드
  [Enums.WorkflowNodeType.PROCESS]: ProcessNodeType, // 프로세스 노드
  [Enums.WorkflowNodeType.CONNECTOR]: ConnectorNodeType, //커넥터 노드
  [Enums.WorkflowNodeType.PROCESS_EDGE]: processEdgeType, //프로세스 엣지 노드
  [Enums.WorkflowNodeType.ITERATOR]: IteratorNodeType, //ITERATOR 노드
  [Enums.WorkflowNodeType.CONDITION]: ConditionNodeType, //Condition 노드
  [Enums.WorkflowNodeType.SERVICE]: ServiceNodeType, //서비스 노드
  [Enums.WorkflowNodeType.CODE]: CodeNodeType, //ITERATOR 노드
  [Enums.WorkflowNodeType.BUNDLE]: BundleNodeType, //번들 노드
};

const edgeTypes = {
  noConnect: WorkflowNoConnectorEdge,
  connect: WorkflowConnectorEdge,
};

export const ModalWidth = {
  other: "800px",
  GetConfig: "900px",
  GetMinor: "900px",
  UNIERPConnector: "950px",
  RestAPIConnector: "950px",
  CallStoredProcedure: "920px",
};
export const Connector_popup_width = {
  EntityValidation: "450px",
  other: "800px",
};

const zoomSelector = (s) => s.transform[2];

const WorkflowBuilder = () => {
  const userTheme = LocalStorageService.get(
    Enums.LocalStorageName.EDITOR_THEME
  );
  const [editorTheme, setEditorTheme] = useState(
    userTheme
      ? userTheme.userId === User.getId()
        ? userTheme.theme
        : "light"
      : "light"
  );
  const dispatch = useDispatch();
  const workflow = useSelector((state) => state.workflow);
  const {
    breakpoint: workflowBreakpoints,
    isDebugging,
    inCommunication,
  } = useSelector((state) => state.workflowDebug);
  const workspace = useSelector((state) => state.workspace);
  const debug = useSelector((state) => state.workflowDebug);
  const {
    connection: { openPopup: connectionPopupOpen, Info: connection },
  } = useContext(AppContext);
  const workflowContext = useContext(WorkflowContext);
  //state
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [tabType, setTabType] = useState("E");
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [builderMode, setBuilderMode] = useState("edit");
  const [isDeploying, setIsDeploying] = useState(false);

  //debug
  const [debugExpressionMode, setDebugExpressionMode] = useState("");
  const [debugConsoleMode, setDebugConsoleMode] = useState(false);
  const [debugVariables, setDebugVariables] = useState([]);
  const flowRef = useRef({});
  const runType = useRef(); // 디버그 구동 유형 Run || Debug
  const client = useRef({}); //socket client
  const [debugParameter, setDebugParameter] = useState({}); //디버그 작업에 들어가는 파라미터 상태값
  const [debugExprenssion, setDebugExprenssion] = useState({}); //

  //디버깅 실행 중에 쓰이는 변수, state를 기억하고 있기 때문에 Ref로 변수 관리
  const debuggingRef = useRef({
    currentServiceUid: workflow.serviceInfo?.serviceUid,
    prevWorkflow: {
      [workflow.serviceInfo?.serviceUid]: {
        ...workflow,
      },
    },
  });
  const socketId = useRef(StringUtils.getUuid());
  const [debugConsoleSubscribe, setDebugConsoleSubscribe] = useState();
  const [consoleLogAutoLoad, setConsoleLogAutoLoad] = useState(false);
  const logConfig = useRef({
    preEndPoint: 0,
  });
  const logRef = useRef("");
  const [log, setLog] = useState("");

  //hook
  const reactFlowWrapper = useRef(null);
  const nodeChangeRef = useRef();
  const inDebounce = useRef();
  const {
    onDropIterator,
    onDropCondition,
    onDropProcess,
    onDropService,
    onDropCode,
  } = useWorkflowDropEvent();
  const [flowNodes, flowEdges] = useWorkflowRender(editorTheme);

  //뷰포트 기억할때 쓰는 함수
  const debounceScroll = (func, delay) => {
    if (inDebounce.current) clearTimeout(inDebounce.current);
    inDebounce.current = setTimeout(() => func(), delay);
  };

  /**
   * 뷰포트 바뀔때 redux에 저장
   */
  const viewPortLogger = useOnViewportChange({
    onEnd: useCallback(
      (viewport) =>
        debounceScroll(() => {
          dispatch(updateViewport(viewport));
        }, 250),
      []
    ),
  });

  useEffect(() => {
    setNodes(flowNodes);
    // setEdges(flowEdges);
  }, [flowNodes]);
  useEffect(() => {
    setEdges(flowEdges);
  }, [flowEdges]);

  useEffect(() => {
    dispatch(initCommand());
    return () => {
      setReactFlowInstance(null);
      setEdges([]);
      setNodes([]);

      //disconnect
      disConnectSocket();
    };
  }, []);

  useEffect(() => {
    if (reactFlowInstance) {
      reactFlowInstance.setViewport(workflow.output.service.viewport);
    }
    setDebugParameter({});
  }, [workflow.output.service.compId]);

  useEffect(() => {
    if (!ObjectUtils.isEmpty(debug.process)) {
    }
  }, [debug.process]);

  /**
   * 드랍 이벤트
   */
  const onDrop = useCallback(
    async (event) => {
      event.preventDefault();
      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });
      const _NodeData = event.dataTransfer.getData("application/reactflow");
      // check if the dropped element is valid
      if (!_NodeData) return false; //데이터가 없는 경우
      const NodeData = JSON.parse(_NodeData);
      const { type } = NodeData;
      if (StringUtils.equalsIgnoreCase(Enums.WorkflowNodeType.PROCESS, type)) {
        onDropProcess(position, { nodes, edges });
      } else if (
        StringUtils.equalsIgnoreCase(Enums.WorkflowNodeType.SERVICE, type)
      ) {
        onDropService(position, { nodes, edges });
      } else if (
        StringUtils.equalsIgnoreCase(Enums.WorkflowNodeType.ITERATOR, type)
      ) {
        onDropIterator(position, { nodes, edges });
      } else if (
        StringUtils.equalsIgnoreCase(Enums.WorkflowNodeType.CONDITION, type)
      ) {
        onDropCondition(position, { nodes, edges });
      } else if (
        StringUtils.equalsIgnoreCase(Enums.WorkflowNodeType.CODE, type)
      ) {
        onDropCode(position, { nodes, edges });
      } else if (
        StringUtils.equalsIgnoreCase(Enums.WorkflowNodeType.MEMO, type)
      ) {
        onDropMemo(position);
      }
    },
    [reactFlowInstance, workflow, nodes, edges]
  );

  /**
   * 메모를 올렸을때
   * @param {*} position
   */
  const onDropMemo = (position) => {
    WorkflowReduxHelper.addMemo(
      dispatch,
      {
        compId: StringUtils.getUuid(),
        description: "",
        position,
        style: { width: 300, height: 300 },
        type: Enums.WorkflowNodeType.MEMO,
      },
      workflow
    );
  };

  /**
   * 테마 변경
   * @param {*} e
   * @param {*} theme
   */
  const onChangeTheme = (e, theme) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    setEditorTheme(theme);
    LocalStorageService.set(Enums.LocalStorageName.EDITOR_THEME, {
      userId: User.getId(),
      theme,
    });
  };

  /**
   * 미니맵 또는 엔티티 목록에서 노드 선택시 해당 노드를 중앙으로 포커스 이동
   * @param {*} e
   * @param {*} _node
   */
  const onNodeClick = (e, _node) => {};

  /**
   * 연결하는 노드가 Iterator 안의 노드인지 확인
   * @param {*} processList 프로세스 목록 workflow.output.service.child.process
   * @param {*} compId
   * @param {*} parentsNode 리턴용
   * @returns
   */
  const findParentIterator = (processList, compId, parentsNode = {}) => {
    const nodeInfo = JsonUtils.findNode(nodes, "id", compId);
    if (nodeInfo.parentNode) {
      return JsonUtils.findNode(nodes, "compId", nodeInfo.parentNode);
    } else {
      return null;
    }
  };

  /**
   * 노드 연결 이벤트
   */
  const onConnect = useCallback(
    (connection) => {
      // return getSourceEntity(connection.source, workflow.output);
      const { source, target } = connection;
      /**
       * 메세지 프로세스는 Source로서 사용되지 못한다.
       * Iterator의 반복문 제어는 Source가 될수 없다.
       */
      const sourceNode = nodes.find((n) => n.id === source);
      const targetNode = nodes.find((n) => n.id === target);
      if (
        StringUtils.equalsIgnoreCase(
          sourceNode.data.process?.processType,
          Enums.WorkflowProcessType.MESSAGE
        )
      ) {
        return Message.alert(
          "메세지는 다른 노드와 연결할 수 없습니다.",
          Enums.MessageType.WARN
        );
      }
      if (
        StringUtils.equalsIgnoreCase(
          sourceNode.data.process?.processType,
          Enums.WorkflowProcessType.LOOP_CONTROL_KEYWORD
        )
      ) {
        return Message.alert(
          "반복문 제어 노드는 다른 노르에 연결 할 수 없습니다.",
          Enums.MessageType.WARN
        );
      }
      if (
        StringUtils.equalsIgnoreCase(
          sourceNode.type,
          Enums.WorkflowNodeType.CONNECTOR
        ) &&
        sourceNode.type === targetNode.type
      ) {
        return Message.alert(
          "커넥터 간 연결할 수 없습니다.",
          Enums.MessageType.WARN
        );
      }
      if (source === target) return false;
      const connectorNodes = nodes.filter(
        (c) => c.type === Enums.WorkflowNodeType.CONNECTOR
      );
      //이미 연결된 엣지인지 확인
      const isEdgeConnected = edges.find(
        (c) => c.source === source && c.target === target
      );
      //이미 연결된
      const isConnectorConnected = connectorNodes.find(
        (c) =>
          c.data.connector.processFrom === source &&
          c.data.connector.processTo === target
      );
      //커넥터로부터의 연결을 수정하는 경우
      const FromConnector = connectorNodes.find((c) => c.id === source);
      const ToConnector = connectorNodes.find((c) => c.id === target);
      //1. 커넥터가 연결된 경우
      if (
        (isEdgeConnected || isConnectorConnected) &&
        !(FromConnector || ToConnector)
      ) {
        let connector;
        if (isEdgeConnected) {
          connector = JsonUtils.findNode(
            workflow,
            "compId",
            isEdgeConnected.id
          );
        } else {
          connector = JsonUtils.findNode(
            workflow,
            "compId",
            isConnectorConnected.data.connector.compId
          );
        }
        const _newConnection = getConnectorPosition({
          sourceNode,
          targetNode,
          connection,
          connector,
        });
        connector = { ...connector, ..._newConnection };
        WorkflowReduxHelper.updateNodes(dispatch, [connector], workflow);
      } else if (FromConnector || ToConnector) {
        // 커넥터 노드는 가상 노드이기 때문에 nodes와 Edge에서 원천 노드를 찾아서 업데이트 한다.
        if (
          StringUtils.equalsIgnoreCase(
            sourceNode.type,
            Enums.WorkflowNodeType.CONNECTOR
          )
        ) {
          const conCompId = FromConnector.id;
          //수정할 커넥터 찾기
          const connector = connectorNodes.find((c) => c.id === source).data
            .connector;
          let newConnector = produce(connector, (draft) => {
            //edgeInfo 삭제 및 edgeDetailInfo 추가
            if (!draft.edgeDetailInfo) draft.edgeDetailInfo = {};
            draft.edgeDetailInfo.to = connection;
            // draft.edgeDetailInfo.from = connection;
            if (!draft.edgeDetailInfo.from)
              draft.edgeDetailInfo.from = {
                ...draft.edgeInfo,
                target: conCompId,
              };
            // delete draft.edgeInfo;
            if (FromConnector.data.connector.processTo !== target) {
              draft.processTo = target;
            }
            // draft.edgeDetailInfo = newEdgeDetailInfo;
          });

          WorkflowReduxHelper.updateNodes(dispatch, [newConnector], workflow);
        } else if (FromConnector) {
          const conCompId = FromConnector.id;
          //수정할 커넥터 찾기
          const connector = connectorNodes.find((c) => c.id === source).data
            .connector;
          let newConnector = produce(connector, (draft) => {
            //edgeInfo 삭제 및 edgeDetailInfo 추가
            if (!draft.edgeDetailInfo) draft.edgeDetailInfo = {};
            draft.edgeDetailInfo.to = connection;
            // draft.edgeDetailInfo.from = connection;
            if (!draft.edgeDetailInfo.from)
              draft.edgeDetailInfo.from = {
                ...draft.edgeInfo,
                target: conCompId,
              };
            delete draft.edgeInfo;
            if (FromConnector.data.connector.processTo !== target) {
              draft.processTo = target;
            }
            // draft.edgeDetailInfo = newEdgeDetailInfo;
          });

          WorkflowReduxHelper.updateNodes(dispatch, [newConnector], workflow);
        } else {
          // 임의의 프로세스로 부터 커넥터로의 연결을 수정하는 경우
          const conCompId = ToConnector.id;
          //실제 원천 Source : Connector에 연결된 원천 Source
          //수정할 커넥터 찾기
          const connector = connectorNodes.find((c) => c.id === target).data
            .connector;
          let newConnector = produce(connector, (draft) => {
            if (!draft.edgeDetailInfo) draft.edgeDetailInfo = {};
            draft.edgeDetailInfo.from = connection;
            if (!draft.edgeDetailInfo.to)
              draft.edgeDetailInfo.to = {
                ...draft.edgeInfo,
                target: conCompId,
              };

            //edgeInfo 삭제 및 edgeDetailInfo 추가
            if (ToConnector.data.connector.processFrom !== source) {
              draft.processFrom = source;
            }
            delete draft.edgeInfo;
            // draft.edgeDetailInfo = newEdgeDetailInfo;
          });
          WorkflowReduxHelper.updateNodes(dispatch, [newConnector], workflow);
        }
      } else {
        //같은 이터레이터 안에서만 연결됨
        const fromNode = JsonUtils.findNode(nodes, "id", source);
        const toNode = JsonUtils.findNode(nodes, "id", target);
        if (fromNode.parentNode !== toNode.parentNode) {
          return Message.alert(
            "같은 레벨의 노드끼리만 연결 할 수 있습니다.",
            Enums.MessageType.WARN
          );
        }

        let flags = "";
        //fromNode가 condition일 경우 해당 노드에서 나온 connector의 개수가 2개일 경우, 알림 -> return;
        if (fromNode.type === Enums.WorkflowNodeType.CONDITION) {
          const fromConditionNodes = connectorNodes.filter(
            (item) => item.data.connector.processFrom === fromNode.id
          );
          if (fromConditionNodes.length === 2) {
            return Message.alert(
              "Condition 노드는 최대 2개의 connector 연결이 가능합니다.",
              Enums.MessageType.WARN
            );
          } else if (fromConditionNodes.length === 1) {
            flags = fromConditionNodes[0].data.connector.propertyValue.filter
              ? false
              : true;
          }
        }

        let iterator = findParentIterator(
          workflow.output.service.child.process,
          source
        );

        //새로운 커넥터 추가
        const callbackFnc = (connectorInfo) => {
          Popup.close();

          const body = {
            processFrom: source,
            processTo: target,
            compId: StringUtils.getUuid(),
            type: Enums.WorkflowNodeType.CONNECTOR,
            edgeInfo: connection,
          };
          if (connectorInfo) {
            body.propertyValue = connectorInfo;
          }

          //커넥터가 추가된다면 해당 커넥터의 좌표를 계산
          const sourceComp = JsonUtils.findNode(
            workflow.output.service.child,
            "compId",
            source
          );
          const targetComp = JsonUtils.findNode(
            workflow.output.service.child,
            "compId",
            target
          );
          const { position, edgeDetailInfo } = getConnectorPosition({
            sourceNode: sourceComp,
            targetNode: targetComp,
            connection,
            connector: body,
          });
          body.position = position;
          body.edgeDetailInfo = edgeDetailInfo;
          delete body.edgeInfo;

          if (ObjectUtils.isEmpty(iterator)) {
            //일반 노드
            WorkflowReduxHelper.addConnector(dispatch, body, workflow);
          } else {
            //이터레이터 안의 노드
            WorkflowReduxHelper.addIteratorConnector(
              dispatch,
              body,
              iterator,
              workflow
            );
          }
        };
        const options = {
          effect: Popup.ScaleUp,
          style: {
            content: {
              width: Connector_popup_width.other,
            },
          },
        };

        //소스가 Validation 인지 확인
        const sourceNode = JsonUtils.findNode(
          workflow.output,
          "compId",
          source
        );
        const isFromValidation = StringUtils.equalsIgnoreCase(
          sourceNode.processType,
          "EntityValidation"
        );
        if (fromNode.type === Enums.WorkflowNodeType.CONDITION) {
          if (flags === "") {
            //fromNode가 condition(IF 문) 일 경우 true/false 팝업
            Popup.open(
              <ConnectorConditionPopup
                callbackFnc={callbackFnc}
                connection={connection}
                workflow={workflow.output}
                iterator={iterator}
              />,
              {
                effect: Popup.ScaleUp,
                style: {
                  content: {
                    width: Connector_popup_width.EntityValidation,
                  },
                },
              }
            );
          } else {
            const body = {
              processFrom: source,
              processTo: target,
              compId: StringUtils.getUuid(),
              type: Enums.WorkflowNodeType.CONNECTOR,
              edgeInfo: connection,
              propertyValue: {
                filter: flags,
                connectorNm: `${flags ? "TRUE" : "FALSE"}`,
              },
            };
            //커넥터가 추가된다면 해당 커넥터의 좌표를 계산
            const sourceComp = JsonUtils.findNode(
              workflow.output.service.child,
              "compId",
              source
            );
            const targetComp = JsonUtils.findNode(
              workflow.output.service.child,
              "compId",
              target
            );
            const { position, edgeDetailInfo } = getConnectorPosition({
              sourceNode: sourceComp,
              targetNode: targetComp,
              connection,
              connector: body,
            });
            body.position = position;
            body.edgeDetailInfo = edgeDetailInfo;
            delete body.edgeInfo;

            if (ObjectUtils.isEmpty(iterator)) {
              //일반 노드
              WorkflowReduxHelper.addConnector(dispatch, body, workflow);
            } else {
              //이터레이터 안의 노드
              WorkflowReduxHelper.addIteratorConnector(
                dispatch,
                body,
                iterator,
                workflow
              );
            }
          }
        } else if (isFromValidation) {
          Popup.open(
            <ConnectorValidationPopup
              callbackFnc={callbackFnc}
              connection={connection}
              workflow={workflow.output}
              iterator={iterator}
            />,
            {
              effect: Popup.ScaleUp,
              style: {
                content: {
                  width: Connector_popup_width.EntityValidation,
                },
              },
            }
          );
        } else {
          Popup.open(
            <ConnectorPopup
              callbackFnc={callbackFnc}
              connection={connection}
              sourceCompId={source}
              targetCompId={target}
              workflow={workflow.output}
              iterator={iterator}
              nodes={nodes}
              edges={edges}
              compId={source}
              connector={true}
            />,
            options
          );
        }
      }
      return false;
    },
    [setEdges, workflow.output, nodes, edges]
  );

  /**
   * Flow Panel 위에서 드래그 되었을때 이벤트
   */
  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  /**
   * 바뀐 노드 목록 리턴
   * onChangeNode에서 사용
   * @param {String} key
   * @returns {Array}
   */
  const getChangedNode = (key) => {
    const changedEntities = nodeChangeRef.current.map((_nodeInfo) => {
      let changedContents = {};
      const { id } = _nodeInfo;
      changedContents = JsonUtils.findNode(workflow, "compId", id);
      changedContents = produce(changedContents, (draft) => {
        if (StringUtils.equalsIgnoreCase(key, "dimensions")) {
          JsonUtils.overrideNode(draft, "compId", id, "style", _nodeInfo[key]);
        } else {
          JsonUtils.overrideNode(draft, "compId", id, key, _nodeInfo[key]);
        }
      });
      return changedContents;
    });
    return changedEntities;
  };

  const getIsProcessNode = useCallback((node) => {
    const isProcessNode = !StringUtils.includes(node.type, [
      Enums.WorkflowNodeType.MEMO,
      Enums.WorkflowNodeType.PROCESS_EDGE,
      Enums.WorkflowNodeType.CONNECTOR,
    ]);
    return isProcessNode;
  }, []);

  /**
   * 노드 위치 이동시 포지션값 적용
   * @param {[Object]} nodeChange
   */
  const onNodeCustomChange = (nodeChange) => {
    onNodesChange(nodeChange);
    let changedEntities = [];
    if (!ArrayUtils.isEmpty(nodeChange)) {
      //번들링 모드에서 선택
      if (workflowContext.bundle.bundlingMode) {
        //select는 select가 이동할때 기 select가 된 노드가 false로 바뀌고 , 신규 노드가 true로 전환된다.
        const selectedInfo = nodeChange.find(
          (nc) => nc.type === "select" && nc.selected
        );
        if (selectedInfo) {
          const node = nodes.find((n) => n.id === selectedInfo.id);
          if (node.type === Enums.WorkflowNodeType.BUNDLE)
            return Message.alert(
              "노드 그룹은 선택할 수 없습니다.",
              Enums.MessageType.WARN
            );
          if (ObjectUtils.isEmpty(workflowContext.bundle.bundleStartNode)) {
            //번들링 시작할 startNode 선택
            if (node && getIsProcessNode(node)) {
              workflowContext.bundle.setBundleStartNode(node);
            } else {
              return Message.alert(
                "그룹핑 시에는 Process 노드만 선택가능합니다.",
                Enums.MessageType.WARN
              );
            }
          } else {
            //번들링 마무리할 엔드노드 선택,
            //엔드노드가 선택된 상태에서 또 누르면 초기화하고 스타팅 노드로 전환함
            if (ObjectUtils.isEmpty(workflowContext.bundle.bundleEndNode)) {
              if (node && getIsProcessNode(node)) {
                workflowContext.bundle.setBundleEndNode(node);
                //
                const betweenList = getNodeBetweenList(
                  workflowContext.bundle.bundleStartNode,
                  node,
                  nodes
                );

                if (
                  !ArrayUtils.isArray(betweenList) ||
                  ArrayUtils.isEmpty(betweenList)
                )
                  return Message.alert(
                    "중간 노드 연결을 찾을 수 없거나 , 노드 그룹이 포함되있습니다.",
                    Enums.MessageType.WARN
                  );
                const nodeIdMap = {};
                betweenList.forEach((node) => {
                  nodeIdMap[node.id] = true;
                });
                workflowContext.bundle.setBundleNodeList(nodeIdMap);
              } else {
                return Message.alert(
                  "그룹핑 시에는 Process 노드만 선택가능합니다.",
                  Enums.MessageType.WARN
                );
              }
            } else {
              const node = nodes.find((n) => n.id === nodeChange[0].id);
              if (node && getIsProcessNode(node)) {
                workflowContext.bundle.setBundleStartNode(node);
                workflowContext.bundle.setBundleEndNode({});
              }
            }
          }
        }
      }

      //포지션 변경
      else if (nodeChange[0].type === "position") {
        if (nodeChange.length === 1 && nodeChange[0].id === "track") {
        } else if (nodeChange[0].dragging === true) {
          nodeChangeRef.current = nodeChange;
        } else if (nodeChange[0].dragging === false) {
          if (nodeChangeRef.current) {
            changedEntities = getChangedNode("position");
            nodeChangeRef.current = null;
          }
        }
      } else if (nodeChange[0].type === "dimensions") {
        if (
          nodeChange[0].updateStyle === true &&
          nodeChange[0].resizing === true
        ) {
          nodeChangeRef.current = nodeChange;
        } else if (nodeChange[0].resizing === false) {
          if (nodeChangeRef.current) {
            changedEntities = getChangedNode("dimensions");
            nodeChangeRef.current = null;
          }
        }
      }
      if (changedEntities.length > 0) {
        WorkflowReduxHelper.updateNodes(dispatch, changedEntities, workflow);
      }
    }
  };

  /**
   * 선택한 프로세스 노드 주석처리
   * @param {*} e
   */

  const onCommentProcess = useCallback(
    (e) => {
      stopEvent(e);
      //Selected Process Node
      const selectedNode = nodes.filter(
        (n) =>
          n.selected &&
          !StringUtils.includesIgnoreCase(n.type, [
            Enums.WorkflowNodeType.CONNECTOR,
            Enums.WorkflowNodeType.PROCESS_EDGE,
          ])
      );
      //Connected Node
      const ConnectorNode = nodes.filter((n) => n.type === "connector");

      if (ArrayUtils.isEmpty(selectedNode)) {
        return Message.alert(
          "선택된 프로세스 노드가 없습니다.",
          Enums.MessageType.WARN
        );
      }
      //선택된 노드가 전부 이미 주석처리된것에 있으면 주석 해제
      const isAllComment = selectedNode.reduce(
        (ac, cu) => ac && cu.data.comment,
        true
      );

      // 엣지 중에 selectedNode에 source & target이 포함되지 않는 엣지는 삭제
      const willDeleteEdge = edges.filter((e) => {
        return (
          (selectedNode.find((n) => n.id === e.source) ? true : false) ^
            (selectedNode.find((n) => n.id === e.target) ? true : false) &&
          e.type === Enums.WorkflowNodeType.NO_CONNECTOR
        );
      });
      //삭제될 커넥터
      const willDeleteConnector = ConnectorNode.filter(
        (c) =>
          (selectedNode.find((n) => n.id === c.data.connector.processFrom)
            ? true
            : false) ^
          (selectedNode.find((n) => n.id === c.data.connector.processTo)
            ? true
            : false)
      );

      const willStoreNode = selectedNode;
      //보존될 Edge
      const willStoreEdge = edges.filter(
        (e) =>
          selectedNode.find((n) => n.id === e.source) &&
          selectedNode.find((n) => n.id === e.target) &&
          e.type === Enums.WorkflowNodeType.NO_CONNECTOR
      );
      //보존될 Connector
      const willStoreConnector = ConnectorNode.filter(
        (c) =>
          selectedNode.find((n) => n.id === c.data.connector.processFrom) &&
          selectedNode.find((n) => n.id === c.data.connector.processTo)
      );

      let newWorkflow = { ...workflow };

      if (isAllComment) {
        //주석 해제 로직
        newWorkflow = produce(newWorkflow, (draft) => {
          for (const node of selectedNode) {
            //노드 복구 하면서 커넥터도 확인 후 같이 복구
            const targetNode = JsonUtils.findNode(
              draft.serviceComment,
              "compId",
              node.id
            );
            //노드 복구
            if (targetNode.parentNode) {
              const pNode = JsonUtils.findNode(
                draft.output,
                "compId",
                targetNode.parentNode
              );
              if (pNode) {
                pNode.child.process.push(targetNode);
                for (const con of willStoreConnector) {
                  pNode.child.connector.push(con.data.connector);
                  JsonUtils.removeNode(draft.serviceComment, "compId", con.id);
                }
                JsonUtils.overrideNode(
                  draft.output,
                  "compId",
                  pNode.compId,
                  "child",
                  pNode.child
                );
              }
            } else {
              for (const con of willStoreConnector) {
                if (!con.parentNode) {
                  draft.output.service.child.connector.push(con.data.connector);
                  JsonUtils.removeNode(draft.serviceComment, "compId", con.id);
                }
              }
              draft.output.service.child.process.push(targetNode);
            }
            JsonUtils.removeNode(draft.serviceComment, "compId", node.id);
          }
          //엣지 및 커넥터 복구
          for (const edge of willStoreEdge) {
            //edge는 노드 정보가 없어서 부모여부는 From Node로 판단
            const edgeInfo = JsonUtils.findNode(
              draft.serviceComment,
              "compId",
              edge.id
            );
            if (edgeInfo.parentNode) {
              const pNode = JsonUtils.findNode(
                draft.output,
                "compId",
                edgeInfo.parentNode
              );
              if (pNode) {
                delete edgeInfo.parentNode;
                pNode.child.connector.push(edgeInfo);
              }
              JsonUtils.overrideNode(
                draft.output,
                "compId",
                pNode.compId,
                "child",
                pNode.child
              );
            } else {
              draft.output.service.child.connector.push(edgeInfo);
            }
            JsonUtils.removeNode(draft.serviceComment, "compId", edge.id);
          }
        });
      } else {
        //주석 시키는 로직 - 보관될 노드, 보관될 엣지, 보관될 커넥터
        newWorkflow = produce(workflow, (draft) => {
          //node
          for (const node of willStoreNode) {
            if (
              !workflow.serviceComment.process.find((n) => n.compId === node.id)
            ) {
              //iterator의 자녀 인경우 부모 compId도 함께 넣음
              const nodeInfo = { ...node.data.process };
              if (node.parentNode) nodeInfo.parentNode = node.parentNode;
              JsonUtils.removeNode(draft, "compId", node.id);
              draft.serviceComment.process.push(nodeInfo);
            }
          }
          //connector
          for (const node of willStoreConnector) {
            if (
              !workflow.serviceComment.connector.find(
                (n) => n.compId === node.id
              )
            ) {
              const nodeInfo = { ...node.data.connector };
              if (node.parentNode) nodeInfo.parentNode = node.parentNode;
              JsonUtils.removeNode(draft, "compId", node.id);
              draft.serviceComment.connector.push(nodeInfo);
            }
          }
          // edge
          for (const edge of willStoreEdge) {
            if (
              !workflow.serviceComment.connector.find(
                (n) => n.compId === edge.id
              )
            ) {
              const edgeInfo = {
                ...JsonUtils.findNode(workflow, "compId", edge.id),
              };
              //edge는 노드 정보가 없어서 부모여부는 From Node로 판단
              const fromNode = nodes.find((n) => n.id === edge.source);
              if (fromNode.parentNode)
                edgeInfo.parentNode = fromNode.parentNode;
              JsonUtils.removeNode(draft, "compId", edge.id);
              draft.serviceComment.connector.push(edgeInfo);
            }
          }
        });
      }
      newWorkflow = produce(newWorkflow, (draft) => {
        //삭제될 커넥터 및 엣지
        for (const connector of willDeleteConnector) {
          JsonUtils.removeNode(draft, "compId", connector.id);
        }
        for (const edge of willDeleteEdge) {
          JsonUtils.removeNode(draft, "compId", edge.id);
        }
      });
      WorkflowReduxHelper.updateWorkflow(dispatch, newWorkflow, workflow);
    },
    [nodes]
  );

  /**
   * 선택된 항목 삭제하는 로직
   */
  const onDeleteSelectedNode = useCallback(() => {
    const selectedNode = nodes.filter(
      (n) =>
        n.selected &&
        StringUtils.includes(n.type, [
          Enums.WorkflowNodeType.SERVICE,
          Enums.WorkflowNodeType.PROCESS,
          Enums.WorkflowNodeType.ITERATOR,
          Enums.WorkflowNodeType.CONDITION,
          Enums.WorkflowNodeType.CODE,
          Enums.WorkflowNodeType.MEMO,
        ])
    );
    if (ArrayUtils.isEmpty(selectedNode)) return false;
    else {
      WorkflowReduxHelper.deleteProcess(
        dispatch,
        selectedNode.map((n) => n.id),
        workflow
      );
    }
  }, [nodes]);

  /**
   * 워크플로우 커멘더 라인에서 설정하는 선택 또는 편집 기능
   * 노드 셀렉터 설정용
   * @returns
   */
  const setWorkflowOptions = () => {
    const panOnDrag = [1, 2];
    if (StringUtils.equalsIgnoreCase("select", builderMode)) {
      return {
        selectionOnDrag: true,
        panOnDrag: panOnDrag,
        selectionMode: SelectionMode.Partial,
        onDragCapture: (e) => false,
      };
    } else {
      return {};
    }
  };

  /**
   * 워크플로우 테스트 실행 또는 디버그 실행
   * @param {*} e
   * @param {*} type run || debug
   */
  const onOpenRunConsole = (e, type) => {
    if (
      workflow.output.service.child.process.filter(
        (p) => p.type !== "processEdge"
      ).length === 0
    ) {
      return Message.alert(
        "실행가능한 노드가 없습니다. 플로우를 확인해주세요",
        Enums.MessageType.WARN
      );
    }
    if (inCommunication) return;
    const connection = User.getConnection(workspace.tenantMstId);
    if (!connection) {
      connectionPopupOpen();
      return Message.alert("서버 연결이 필요합니다.", Enums.MessageType.WARN);
    }
    const {
      deployDate,
      // serviceMemo,
      serviceContent: prevServiceContent,
    } = workflow.serviceInfo;
    const { serviceMemo } = workflow;

    /**
     * workflow 실행
     */
    const runWorkflow = () => {
      //컨텐츠 충돌없으면 Run 실행
      const callbackFnc = (data) => {
        const body = {
          requestParams: { ...data },
        };
        runType.current = type;
        if (type === "debug") {
          //디버그 모드일 때 세부항목을 추가함
          body.breakpoints = workflowBreakpoints.filter((point) => point.check);
          //디버깅용 참조데이터 init
          debuggingRef.current = {
            currentServiceUid: workflow.serviceInfo?.serviceUid,
            prevWorkflow: {
              [workflow.serviceInfo?.serviceUid]: {
                prevService: workflow.prevService,
                serviceContent: workflow.serviceInfo.serviceContent,
                serviceComment: workflow.serviceComment,
                ...workflow.serviceInfo,
              },
            },
          };
        }
        // 연결 요청
        Popup.close();
        connectSocket(body);
        setDebugParameter(data);
      };

      Popup.open(
        <RunWorkflowPopup
          callbackFnc={callbackFnc}
          debugParameter={debugParameter}
          nodes={nodes}
        />,
        {
          style: { content: { width: "800px" } },
        }
      );
    };

    /**
     * 자동 저장하는 질문 팝업 열기
     */
    const questPopupOpen = () => {
      const autoDeploy = LocalStorageService.get(
        Enums.LocalStorageName.WORKFLOW_AUTO_DEPLOY
      );

      const deploy = () => {
        setIsDeploying(true);
        const body = {
          ...workflow.serviceInfo,
          convertLog: workflow.serviceInfo.convertLog
            ? workflow.serviceInfo.convertLog
            : null,
          serviceContent: workflow.output,
          serviceComment: workflow.serviceComment,
          serviceMemo: serviceMemo,
          useYn: "Y",
          deployDate: new Date(),
          commitComment: "[ 디버깅 배포 ]",
          ...workspace,
        };
        workflowContext.deploy(body, (isSuccess) => {
          setIsDeploying(false);
          if (isSuccess) {
            runWorkflow();
          }
        });
      };

      if (
        autoDeploy &&
        autoDeploy.userId === User.getId() &&
        autoDeploy.autoSave === "Y"
      ) {
        deploy();
      } else {
        LocalStorageService.remove(Enums.LocalStorageName.WORKFLOW_AUTO_DEPLOY);
        Popup.open(<RunWorkflowQuestPopup callback={deploy} />, {
          effect: {
            ...Popup.ScaleUp,
            end: {
              top: "30%",
              opacity: 1,
            },
          },
          style: { content: { width: "400px", top: "400px" } },
        });
      }
    };

    /**
     * 1. serviceUid가 없는 경우
     * 2. 배포된 적이 없는 경우
     * 3. 수정된 날짜와 배포된 날짜가 다른 경우
     * 위 경우는 배포(저장)를 새롭게 해야한다.
     */
    if (!deployDate) {
      questPopupOpen();
    } else {
      //viewport는 다른경우가 많기 때문에 빼고 비교
      const prev = produce(prevServiceContent, (draft) => {
        JsonUtils.removeNode(draft, "viewport");
      });
      const next = produce(workflow.output, (draft) => {
        JsonUtils.removeNode(draft, "viewport");
      });

      if (JSON.stringify(prev) !== JSON.stringify(next)) {
        questPopupOpen();
      } else {
        runWorkflow();
      }
    }
  };

  /**
   * 디버그 실행중 멈춤
   * @param {*} e
   */
  const onStopDebug = (e) => {
    setDebugExpressionMode("");
    setDebugExprenssion({});
    // dispatch(setWFIsDebugging(false)); //디버깅 중 종료
    dispatch(setWFProcess({})); // 현재 진행중인 프로세스 초기화
    dispatch(setWFInCommunication(false)); // 통신 중 종료
    disConnectSocket();
    if (!ObjectUtils.isEmpty(debugConsoleSubscribe))
      debugConsoleSubscribe.unsubscribe();
    setDebugConsoleSubscribe(null);
    runType.current = "";
  };
  /**
   * 1. Socket을 연결한다.
   * @param {*} params
   */

  const connectSocket = (params) => {
    console.log("soket connect.........");
    //이미 연결된 상태 일 경우 (이전 debug 이후 socket이 disconnect되지 않았을 경우)
    if (client.current.connected) {
      onConnectSocket(params);
      //신규 연결
    } else {
      socketId.current = StringUtils.getUuid();
      let connectHeaders = {
        Authorization: User.getConnection(workspace.tenantMstId, "token"),
        userId: User.getId() /* 개발자 id */,
        serviceUid: workflow.serviceInfo.serviceUid,
        socketId: socketId.current,
        language: User.getLanguage(),
        socketUrl: "",
      };
      if (connection.connectionType === "direct") {
        connectHeaders.socketUrl = ConnectionService.setPortNumberToURL(
          connection.runtimeProtocol + "://" + connection.runtimeHost
        );
      } else {
        connectHeaders.socketUrl = ConnectionService.setPortNumberToURL(
          connection.protocol + "://" + connection.host
        );
      }

      const _runtimeProtocol = User.getConnection(
        workspace.tenantMstId,
        "runtimeProtocol"
      );
      const _runtimeHost = User.getConnection(
        workspace.tenantMstId,
        "runtimeHost"
      );

      if (StringUtils.isEmpty(_runtimeHost)) {
        return Message.alert(
          "Runtime Host를 설정해주세요.",
          Enums.MessageType.ERROR
        );
      } else {
        let _targetUrl = `${_runtimeProtocol}://${_runtimeHost}`;
        if (!_targetUrl.endsWith("/")) _targetUrl += "/";
        // return console.log(connectHeaders);
        client.current = new StompJs.Client({
          //WebSocket은 ws protocol만 가능, 따라서 일반적으로 ws가 지원되지 않는 브라우저를 위해 WebSocket 대신 SockJS를 사용
          //brokerURL: "ws://localhost:81/adm/api/WorkflowSocket",

          //CORS Policy때문에 proxy를 통한 접속을 하도록 한다.
          webSocketFactory: () => new SockJS(`${_targetUrl}api/WorkflowSocket`), //연결 URL + "/api/WorkflowSocket"
          connectHeaders: connectHeaders,
          debug: function (str) {
            console.log(str);
          },
          onConnect: () => {
            subscribeSocket();
            subscribeConsoleSocket();
            onConnectSocket(params);
          },
          onStompError: (frame) => {
            console.error(frame);
            // 헤더 메세지
            const { message } = frame.headers;
            if (message) {
              const _message = message.split("\\c");
              Message.alert(_message[1], Enums.MessageType.ERROR);
            }
            onStopDebug();
          },
          reconnectDelay: 0, //try to reconnct every 5000ms
          heartbeatIncoming: 4000, // receive heartbeats every 4000ms from server
          heartbeatOutgoing: 4000, // send heartbeats every 4000ms to server
        });
        client.current.activate();
      }
    }
  };

  /**
   * 2. socket 연결 성공
   *   - subscribe socket
   *   - send debug starting message
   * @param {*} params
   */
  const onConnectSocket = (params) => {
    //debug 최초 시작 - message 전송
    sendSocketMessage("connect", params);
  };

  /**
   * 2-1 subscribe socket
   */
  const subscribeSocket = () => {
    client.current.subscribe(
      "/wfDebug/wait/" + User.getId() + "/" + socketId.current,
      async ({ body }) => {
        let receivedData;
        try {
          if (body) receivedData = JSON.parse(body);
        } catch (e) {
          console.log(e);
        }
        if (!receivedData) return;

        /**
         * forceConnect 부분
         * **/
        if (StringUtils.equalsIgnoreCase(receivedData.errType, "confirm")) {
          return Message.confirm(receivedData.message, () => {
            const body = {
              requestParams: { ...debugParameter },
              breakpoints: workflowBreakpoints.filter((point) => point.check),
            };
            sendSocketMessage("forceConnect", body);
          });
        }
        if (receivedData.isError) {
          dispatch(setWFErrType(receivedData.errType));
          getConsoleLog();
          //message 처리
          let msg = receivedData.message || receivedData.data;
          Message.alert(msg, Enums.MessageType.ERROR);
          if (StringUtils.equalsIgnoreCase(receivedData.errType, "system")) {
            onStopDebug();
            if (!StringUtils.isEmpty(receivedData.sourceTrace)) {
              const _errorTrace = JSON.parse(receivedData.sourceTrace);
              await traceTracker(_errorTrace);
            }
          } else if (
            StringUtils.equalsIgnoreCase(receivedData.errType, "invalid")
          ) {
            const { expression, expressionResult: _expressionResult } =
              receivedData.data;
            if (_expressionResult) {
              let _msg = _expressionResult.split(" ");
              _msg.splice(0, 1);
              _msg = _msg.join(" ");
              setDebugExprenssion({
                expression,
                result: _msg,
              });
            }
          }
        } else if (receivedData.data) {
          //connection 성공
          if (receivedData.data.messageType === "connection") {
            dispatch(setWFInCommunication(true));
            dispatch(setWFBreakpointType(""));
            dispatch(setWFTrace([]));
            dispatch(setWFProcess({}));
            if (StringUtils.equalsIgnoreCase(runType.current, "Debug")) {
              dispatch(setWFIsDebugging(true));
              setDebugExpressionMode("variable");
            }
            //variables
          } else if (receivedData.data.messageType === "variable") {
            dispatch(setWFInCommunication(true));
            if (StringUtils.equalsIgnoreCase(runType.current, "Debug")) {
              dispatch(setWFIsDebugging(true));
            }
            /**
             * trace : 지나온 길 역추적
             * process : 현재 위치
             * breakpointType : before || after 현재 브레이크 포인트 실행 전 후
             * traceTracker : 현재 추적중인 노드의 서비스를 화면에 노출함
             *  */
            const { trace, process, breakpointType, context } =
              receivedData.data;
            setDebugVariables(context);
            dispatch(setWFProcess(process));
            dispatch(setWFBreakpointType(breakpointType));
            //트래킹 중인 프로세스는 selected로 처리함
            await traceTracker(trace);
            // process Node 포커싱
            const _processNode = document.querySelector(
              `div[data-id="${process.compId}"]`
            );
            if (_processNode) _processNode.style.zIndex = 1000;
          } else if (receivedData.data.messageType === "expression") {
            const { expression, expressionResult: result } = receivedData.data;
            /**
             * subscribeSocket 이 실행 될때 prev state를 기억하고 있어서
             * 여기서는 state의 변화만 주고 expression List는 해당 컴포넌트에서 추가하는 것으로 수정
             *  */
            if (expression) setDebugExprenssion({ expression, result });
          } else if (receivedData.data.messageType === "finish") {
            onStopDebug();
            Message.alert(
              "Debugging이 완료 되었습니다.",
              Enums.MessageType.SUCCESS
            );
            if (!isDebugging) dispatch(setWFIsDebugging(true)); //디버깅 중 종료
            if (receivedData.data.trace)
              dispatch(setWFTrace(receivedData.data.trace));
          }
        }
      }
    );
  };

  /**
   * Debug Console 용 subscription
   * @returns
   */
  const subscribeConsoleSocket = () => {
    const clientSubscription = client.current.subscribe(
      // "/wfDebug/console/" + User.getId(),
      "/wfDebug/console/" + User.getId() + "/" + socketId.current,
      async ({ body }) => {
        let receivedData;
        try {
          if (body) receivedData = JSON.parse(body);
        } catch (e) {
          console.log(e);
        }
        if (!receivedData) return;

        if (receivedData.isError === true) {
          setConsoleLogAutoLoad(false);
          logRef.current += receivedData.message + "\n";
        } else {
          logConfig.current = {
            preEndPoint: receivedData.data.endPoint,
          };
          logRef.current += receivedData.data.log;
        }
        setLog(logRef.current);
      },
      { id: User.getId() }
    );
    setDebugConsoleSubscribe(clientSubscription);
  };

  /**
   * 디버깅 이력 표시하는 로직
   * @param {*} trace
   * @returns
   */
  const traceTracker = async (trace) => {
    dispatch(setWFTrace(trace || []));
    return new Promise((resolve, reject) => {
      if (ArrayUtils.isArray(trace)) {
        /**
         * 오류난 서비스로 화면이동
         * 1. 순서대로 만들기 (breacrum 만들기)
         * 2. 마지막으로 잡히는 화면이 현재 워크플로와 같다면 화면 유지
         *  다르면 화면이이동
         * **/
        let _prevT = {};
        let _breadcrumb = [];
        for (const _et of trace) {
          if (_et.serviceUid !== _prevT.serviceUid) {
            _prevT = _et;
            _breadcrumb.push({
              serviceUid: _et.serviceUid,
              serviceName: _et.serviceName,
            });
          }
        }
        let _lastServiceUid = _breadcrumb[_breadcrumb.length - 1].serviceUid;
        if (_lastServiceUid !== debuggingRef.current.currentServiceUid) {
          /////////////////////////////////////////////////
          // 여기 ref에 기존 데이터 기억시키고 디버깅 시에만 가져와서 쓸것
          // 디버깅 종료후에는 REf 초기화
          /////////////////////////////////////////////////
          const _isOpenedService =
            debuggingRef.current.prevWorkflow[_lastServiceUid];

          //이전에 실행했던 서비스이면 prevService의 해당 서비스 인덱스까지 잘라서 업데이트
          if (_isOpenedService) {
            let _breadcrumbIndex = _breadcrumb.findIndex(
              (s) => s.serviceUid === _lastServiceUid
            );
            _breadcrumb = _breadcrumb.splice(0, _breadcrumbIndex);
            //이전에 호출했던 기록이 있으면 해당 서비스 바로 호출
            dispatch(initCommand());
            dispatch(updateService(_isOpenedService));
          } else {
            //이전에 호출했던 기록이 없으면 신규로 가져옴
            _breadcrumb = _breadcrumb.splice(0, _breadcrumb.length - 1);
            WorkflowService.getService(
              { serviceUid: _lastServiceUid },
              (res) => {
                const serviceDetail = WorkflowService.setData(res.data);
                serviceDetail.prevService = _breadcrumb;
                dispatch(initCommand());
                dispatch(updateService(serviceDetail));
                //디버깅용 참조데이터 init
                debuggingRef.current.prevWorkflow[_lastServiceUid] = {
                  ...serviceDetail,
                };
                resolve(true);
              }
            );
          }
          //현재 서비스 UID 기억
          debuggingRef.current.currentServiceUid = _lastServiceUid;
        } else {
          resolve(true);
        }
      }
    });
  };

  /**
   * 2-2 soket Message 전송
   * @param {*} actionType ("connect, stepOver, stepInto, stepOut,disconnect,forceConnect, expression)
   * @param {*} requestParams
   */
  const sendSocketMessage = (actionType, requestParams) => {
    if (!client.current.connected) {
      //정상적으로 연결되지 않은 상태...
      return;
    }
    //headers에 token 추가
    client.current.publish({
      destination: "/" + actionType,
      body: JSON.stringify(requestParams),
    });
  };

  /**
   * disconnect WebSocket
   */
  const disConnectSocket = () => {
    if (!ObjectUtils.isEmpty(client.current)) client.current.deactivate();
  };

  /**
   * 디버깅 중 로그 호출로직
   * @returns
   */
  const getConsoleLog = () => {
    //Console은 debugging Mode (soket이 연결되지 않은 상태)에만 실행 됩니다.
    if (ObjectUtils.isEmpty(client.current) || !client.current.connected) {
      return;
    }

    // const connection = User.getConnection(workspace.tenantMstId)
    if (!connection || User.getConnection(workspace.tenantMstId, "expired")) {
      setConsoleLogAutoLoad(false);
      Message.alert("서버가 연결되지 않았습니다.", Enums.MessageType.WARN);
      return connectionPopupOpen();
    }

    const params = {
      ...connection,
      ...logConfig.current,
    };
    //parameter는 preEndPoint만 필요함..
    sendSocketMessage("consoleLog", params);
  };

  /**
   * Key down 이벤트
   */
  const onKeyDownAction = (e) => {
    if (StringUtils.equalsIgnoreCase(e.key, "delete")) {
      const selectedList = nodes.filter((n) => n.selected);
      if (!ArrayUtils.isEmpty(selectedList)) {
        let list = selectedList
          .filter(
            (s) =>
              s.data[Object.keys(s.data)[0]].type !==
              Enums.WorkflowNodeType.PROCESS_EDGE
          )
          .map((s) => s.data[Object.keys(s.data)[0]]);
        WorkflowReduxHelper.deleteProcess(
          dispatch,
          list.filter((l) => l?.compId).map((l) => l.compId),
          workflow
        );
      }
    }
  };

  const onClickFlowToPng = () => {
    if (!workflow.output.service.serviceId)
      return Message.alert(
        "서비스 ID가 설정 되지 않았습니다.",
        Enums.MessageType.WARN
      );
    // we calculate a transform for the nodes so that all nodes are visible
    // we then overwrite the transform of the `.react-flow__viewport` element
    // with the style option of the html-to-image library
    toPng(flowRef.current, {
      filter: (node) =>
        !(
          node?.classList?.contains("react-flow__minimap") ||
          node?.classList?.contains("react-flow__controls") ||
          node?.classList?.contains("workflow-node-list")
        ),
    }).then((dataUrl) => {
      const a = document.createElement("a");

      a.setAttribute(
        "download",
        `${workflow.output.service.serviceId}(${workflow.output.service.serviceName}).png`
      );
      a.setAttribute("href", dataUrl);
      a.click();
    });
  };

  const bundleCallback = ({ description, color, groupNm }) => {
    const bundleNodeList = workflowContext.bundle.bundleNodeList;
    //2개의 프로세스가 있어야 하기 떄문에 selectedNodeList 는 최소 3개 이상이어야 한다. (1개는 커넥터)
    if (Object.keys(bundleNodeList).length < 3) {
      return Message.alert(
        "2개 이상의 프로세스가 서로 연결되어야 합니다.",
        Enums.MessageType.WARN
      );
    }
    const selectedNodeList = nodes.filter((node) => bundleNodeList[node.id]);
    const isBundleInList = selectedNodeList.find(
      (n) => n.type === Enums.WorkflowNodeType.BUNDLE
    );
    if (isBundleInList)
      return Message.alert(
        "노드 그룹은 중첩될 수 없습니다.",
        Enums.MessageType.WARN
      );

    /**
     * 1. 선택된 노드들을 감싸는 타입의 노드를 생성한다.
     * 2. 해당 노드 안에 선택된 노드들을 주입한다.
     * 3. 각 노드 데이터를 전부 주입하진 않는다. 각 노드의 CompId와 position만 가져간다.
     * 4. 번들의 최초 포지션은 좌상단 노드를 기준으로 한다.
     * 5. 내부 노드의 변형된 포지션은 기존 데이터 간격을 맞춰서 넣도록 한다.
     * 6. 번들 타이틀 크기를 30px로 하기 때문에 4번에서 나온 포지션에서 Y축 -30px를 부여한다.
     */

    const bundleNode = {
      type: Enums.WorkflowNodeType.BUNDLE,
      position: { x: 0, y: 0 },
      style: {
        width: 500,
        height: 500,
      },
      compId: StringUtils.getUuid(),
      propertyValue: {
        expand: true,
        headerColor: color,
        nodeList: [],
        groupNm: groupNm,
        description: description,
        processNm: description,
      },
    };

    const initialPosition = {
      x: selectedNodeList[0].position.x,
      y: selectedNodeList[0].position.y,
    };

    let rightTopPosition = { ...initialPosition };
    let leftBottomPosition = { ...initialPosition };
    let rightBottomPosition = { ...initialPosition };

    //좌상단 노드 확인 밒 포지션 확보
    const processNodeHeight = 180;
    const processNodeWidth = 250;
    //노드와 번들 경계선 폭
    const paddingSize = 50;
    const headerSize = 40;

    const leftTopPosition = selectedNodeList.reduce(
      (position, node) => {
        if (node.position.x < position.x) {
          position.x = node.position.x;
          leftBottomPosition.x = node.position.x;
        }
        if (node.position.y < position.y) {
          position.y = node.position.y;
          rightTopPosition.y = node.position.y;
        }

        if (node.position.y > leftBottomPosition.y) {
          leftBottomPosition.y = node.position.y;
          rightBottomPosition.y = node.position.y;
        }
        if (node.position.x > rightTopPosition.x) {
          rightTopPosition.x = node.position.x;
          rightBottomPosition.x = node.position.x;
        }

        return position;
      },
      { ...initialPosition }
    );

    //사이즈 보정
    leftTopPosition.y = leftTopPosition.y - paddingSize - headerSize;
    leftTopPosition.x = leftTopPosition.x - paddingSize;

    leftBottomPosition.y =
      leftBottomPosition.y + processNodeHeight + paddingSize;
    leftBottomPosition.x = leftTopPosition.x + 0;
    rightTopPosition.x = rightTopPosition.x + processNodeWidth + paddingSize;
    rightTopPosition.y = leftTopPosition.y + 0;

    rightBottomPosition.x = rightTopPosition.x;
    rightBottomPosition.y = leftBottomPosition.y;

    const bundleStyle = {
      width: rightTopPosition.x - leftTopPosition.x,
      height: leftBottomPosition.y - leftTopPosition.y,
    };

    //번들 사이즈 설정
    bundleNode.style = bundleStyle;
    //포지션 설정
    bundleNode.position = leftTopPosition;
    /**
     * 선택된 노드 추가
     * 1. 노드의 compId와 position만 넣는다.
     * 2. xxxx position의 경우 원래 포지션에서 bundlePosition의 값을 뺀만큼으로 한다.
     * 3. 포지션을 별도로 둘 경우 번들 삭제 또는 각 노드 업데이트 시 change 이벤트가 복잡해진다.
     * 4. output 내 실제 노드 포지션을 번들 내부 포지션 값으로 변경
     */
    const positionUpdatedNodeList = [];
    selectedNodeList.forEach((node, index) => {
      //노드 정보
      bundleNode.propertyValue.nodeList.push({
        compId: node.id,
      });
      const nodeData = node.data[Object.keys(node.data)[0]];
      positionUpdatedNodeList.push({
        ...nodeData,
        position: {
          x: node.position.x - leftTopPosition.x,
          y: node.position.y - leftTopPosition.y,
        },
      });
    });
    //번들 최초 사이즈 정보
    WorkflowReduxHelper.saveBundle(
      dispatch,
      workflow,
      bundleNode,
      positionUpdatedNodeList
    );
    //그룹핑 초기화
    workflowContext.bundle.setBundlingMode(false);
  };

  return (
    <div className="reactflow-wrapper workflow" ref={reactFlowWrapper}>
      {
        // isDeploying
        isDeploying && (
          <div
            className="data-load-wrapper"
            style={{ background: "#d3d3d39c", zIndex: 1000 }}
          >
            <div className="data-load-box">
              <CircularProgress color="inherit" size={13} />
              &nbsp;&nbsp;&nbsp; 배포 중입니다.
            </div>
          </div>
        )
      }
      <div className="command-button-wrapper">
        <WorkflowCommandButton
          tabType={tabType}
          setTabType={setTabType}
          builderMode={builderMode}
          setBuilderMode={setBuilderMode}
          onCommentProcess={onCommentProcess}
          onDeleteSelectedNode={onDeleteSelectedNode}
          debugWorkflow={(e) => onOpenRunConsole(e, "debug")}
          runWorkflow={(e) => onOpenRunConsole(e, "run")}
          expressionMode={debugExpressionMode}
          setDebugExpressionMode={(mode) => setDebugExpressionMode(mode)}
          debugExprenssion={setDebugExpressionMode}
          debugConsoleMode={debugConsoleMode}
          setDebugConsoleMode={setDebugConsoleMode}
          stop={(e) => sendSocketMessage("action/stop")}
          stepOver={(e) => sendSocketMessage("action/stepOver")}
          stepInto={(e) => sendSocketMessage("action/stepInto")}
          stepOut={(e) => sendSocketMessage("action/stepOut")}
          resume={(e) => sendSocketMessage("action/resume")}
          bundlingMode={workflowContext.bundle.bundlingMode}
          setBundlingMode={() => {
            if (!workflowContext.bundle.bundlingMode) {
              const selectedNodes = nodes.find((node) => node.selected);
              if (selectedNodes && getIsProcessNode(selectedNodes)) {
                workflowContext.bundle.setBundleStartNode(selectedNodes);
              }
            }
            workflowContext.bundle.setBundlingMode(
              !workflowContext.bundle.bundlingMode
            );
          }}
          nodes={nodes}
          edges={edges}
        />
      </div>

      {StringUtils.equalsIgnoreCase(tabType, "E") ? (
        <>
          <div className="flow-editor-wrapper">
            <ReactFlow
              nodes={nodes}
              nodeTypes={nodeTypes}
              edges={edges}
              maxZoom={5}
              minZoom={0.2}
              onNodesChange={onNodeCustomChange}
              onInit={setReactFlowInstance}
              onDrop={onDrop}
              onDragOver={onDragOver}
              zoomOnDoubleClick
              snapToGrid
              onConnect={onConnect}
              edgeTypes={edgeTypes}
              multiSelectionKeyCode={"Control"}
              defaultViewport={workflow.output.service.viewport}
              connectionMode={ConnectionMode.Loose}
              proOptions={{ hideAttribution: true }}
              onKeyDown={onKeyDownAction}
              ref={flowRef}
              {...setWorkflowOptions()}
            >
              <Controls
                position={"top-left"}
                style={{
                  left: -12,
                  display: "flex",
                  outline: "1px solid gray",
                  borderRadius: "5px",
                  boxShadow: "1px 1px 3px black",
                }}
              >
                {editorTheme === "light" ? (
                  <ControlButton
                    title="어둡게"
                    onClick={(e) => onChangeTheme(e, "dark")}
                  >
                    <MdOutlineNightlightRound />
                  </ControlButton>
                ) : (
                  <ControlButton
                    title="밝게"
                    onClick={(e) => onChangeTheme(e, "light")}
                  >
                    <MdOutlineWbSunny />
                  </ControlButton>
                )}
                <ControlButton
                  onClick={
                    // () => {
                    // if (flowRef.current === null) return;
                    // toPng(flowRef.current, {}).then((dataUrl) => {
                    //   const a = document.createElement("a");
                    //   a.setAttribute("download", "reactflow.png");
                    //   a.setAttribute("href", dataUrl);
                    //   a.click();
                    // });
                    onClickFlowToPng
                  }
                >
                  <MdDownload />
                </ControlButton>
              </Controls>
              <Background
                style={{
                  background: editorTheme === "light" ? "white" : "#282828",
                }}
              />
              <MiniMap
                zoomable
                pannable
                style={{
                  outline: "1px solid gray",
                  borderRadius: "5px",
                  boxShadow: "1px 1px 3px black",
                  top: 350,
                  left: -12,
                }}
                nodeColor={"#3c3c3c"}
                onNodeClick={onNodeClick}
                maskColor={"#D3D3D3cc"}
                position={"top-left"}
              />
              <WorkflowNodeList theme={editorTheme} />
            </ReactFlow>
            <WorkflowDebugExpression
              isDebugging={true}
              tab={debugExpressionMode}
              variables={debugVariables}
              close={(e) => setDebugExpressionMode("")}
              setTab={setDebugExpressionMode}
              debugExprenssion={debugExprenssion}
              sendExpression={(command) => {
                sendSocketMessage("expression", {
                  command,
                });
              }}
            />

            <WorkflowDebugConsole
              debugConsoleMode={debugConsoleMode}
              setDebugConsoleMode={setDebugConsoleMode}
              getConsoleLog={getConsoleLog}
              getSocketId={() => socketId.current}
              sendSocketMessage={sendSocketMessage}
              log={log}
              setLog={setLog}
              consoleLogAutoLoad={consoleLogAutoLoad}
              setConsoleLogAutoLoad={setConsoleLogAutoLoad}
            />
            {workflowContext.bundle.bundlingMode && (
              <WorkflowBundlingTab callback={bundleCallback} />
            )}

            <WorkflowCsServiceList />
            <WorkflowCsLogConsole />
          </div>
        </>
      ) : (
        <WorkflowCodeMirror />
      )}
    </div>
  );
};

export default WorkflowBuilder;
