import Blockly from "blockly";

/**
 * Blockly icon class link : https://github.com/google/blockly/blob/develop/core/icons/icon.ts
 * /
/**
 * Blockly custom icon link: https://developers.google.com/blockly/guides/create-custom-blocks/icons/creating-custom-icons/basic-implementation
 */

/**
 * collapsed icon 클래스 정의
 */
class BlockCollapsedIcon extends Blockly.icons.Icon {
  collapsed = false;

  constructor(sourceBlock) {
    super(sourceBlock);
  }
  getType() {
    return new Blockly.icons.IconType("collapsed_icon");
  }
  initView(pointerdownListener) {
    if (this.svgRoot) return; // Already initialized.

    // This adds the pointerdownListener to the svgRoot element.
    // If you do not call `super` you must do this yourself.
    super.initView(pointerdownListener);

    if (this.sourceBlock.isCollapsed()) {
      this.collapsed = true;
    } else {
      this.collapsed = false;
    }

    this.upArrow = Blockly.utils.dom.createSvgElement(
      Blockly.utils.Svg.SVG,
      {
        width: "20px",
        height: "20px",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 24 24",
        fill: "none",
      },
      this.svgRoot // Append to the svgRoot.
    );

    Blockly.utils.dom.createSvgElement(
      Blockly.utils.Svg.PATH,
      {
        d: "M3 12C3 4.5885 4.5885 3 12 3C19.4115 3 21 4.5885 21 12C21 19.4115 19.4115 21 12 21C4.5885 21 3 19.4115 3 12Z",
        opacity: "0.1",
        fill: "#323232",
      },
      this.upArrow
    );

    Blockly.utils.dom.createSvgElement(
      Blockly.utils.Svg.PATH,
      {
        d: "M3 12C3 4.5885 4.5885 3 12 3C19.4115 3 21 4.5885 21 12C21 19.4115 19.4115 21 12 21C4.5885 21 3 19.4115 3 12Z",
        stroke: "#323232",
        "stroke-width": "2",
      },
      this.upArrow
    );

    Blockly.utils.dom.createSvgElement(
      Blockly.utils.Svg.PATH,
      {
        d: "M15 13L12.2014 10.2014V10.2014C12.0901 10.0901 11.9099 10.0901 11.7986 10.2014V10.2014L9 13",
        stroke: "#323232",
        "stroke-width": "2",
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
      },
      this.upArrow
    );

    this.downArrow = Blockly.utils.dom.createSvgElement(
      Blockly.utils.Svg.SVG,
      {
        width: "20px",
        height: "20px",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 24 24",
        fill: "none",
      },
      this.svgRoot // Append to the svgRoot.
    );

    Blockly.utils.dom.createSvgElement(
      Blockly.utils.Svg.PATH,
      {
        d: "M3 12C3 4.5885 4.5885 3 12 3C19.4115 3 21 4.5885 21 12C21 19.4115 19.4115 21 12 21C4.5885 21 3 19.4115 3 12Z",
        opacity: "0.1",
        fill: "#323232",
      },
      this.downArrow
    );

    Blockly.utils.dom.createSvgElement(
      Blockly.utils.Svg.PATH,
      {
        d: "M3 12C3 4.5885 4.5885 3 12 3C19.4115 3 21 4.5885 21 12C21 19.4115 19.4115 21 12 21C4.5885 21 3 19.4115 3 12Z",
        stroke: "#323232",
        "stroke-width": "2",
      },
      this.downArrow
    );

    Blockly.utils.dom.createSvgElement(
      Blockly.utils.Svg.PATH,
      {
        d: "M9 11L11.6002 13.6002V13.6002C11.821 13.821 12.179 13.821 12.3998 13.6002V13.6002L15 11",
        stroke: "#323232",
        "stroke-width": "2",
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
      },
      this.downArrow
    );

    if (this.collapsed) {
      this.upArrow.setAttribute("display", "none");
      this.downArrow.setAttribute("display", "block");
    } else {
      this.upArrow.setAttribute("display", "block");
      this.downArrow.setAttribute("display", "none");
    }
  }
  getSize() {
    return new Blockly.utils.Size(20, 20);
  }
  updateCollapsed() {
    if (!this.svgRoot) return;

    if (this.sourceBlock.isCollapsed()) {
      this.svgRoot.style.display = "block";
      return true;
    }
    if (this.downArrow && this.upArrow) {
      if (!this.sourceBlock.isCollapsed()) {
        this.collapsed = false;
        this.downArrow.setAttribute("display", "none");
        this.upArrow.setAttribute("display", "block");
      }
    }
    if (Blockly.hasBubble(this)) {
      this.setBubbleVisible(false);
    }
  }
  dispose() {
    // Always call super!
    super.dispose();

    this.myBubble?.dispose();
    this.myOtherReference?.dispose();
  }
  saveState() {
    return this.state; // Some arbirtary JSON-compatible data.
  }
  loadState(state) {
    this.state = state;
  }
  onLocationChange(blockOrigin) {
    this.workspaceLocation = Blockly.utils.Coordinate.sum(
      blockOrigin,
      this.offsetInBlock
    );
  }
  isShownWhenCollapsed() {
    return true;
  }
  onClick() {
    if (this.collapsed) {
      this.sourceBlock.setCollapsed(false);
      this.collapsed = false;
      this.downArrow.setAttribute("display", "none");
      this.upArrow.setAttribute("display", "block");
    } else {
      this.sourceBlock.setCollapsed(true);
      this.collapsed = true;
      this.downArrow.setAttribute("display", "block");
      this.upArrow.setAttribute("display", "none");
    }
  }
}

Blockly.icons.registry.register(
  new Blockly.icons.IconType("collapsed_icon"),
  BlockCollapsedIcon
);
