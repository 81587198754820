import Blockly from "blockly";
import { comboType } from "./BlockRegisterExtension";

// Block이 생성될 때 extension 재실행
export const startExtension = (
  block,
  output,
  blockInfo,
  additionalInfo,
  execute
) => {
  comboType.map((fieldName) => {
    if (block.getField(fieldName)) {
      Blockly.Extensions.TEST_ONLY.allExtensions[fieldName].apply(block, [
        output,
        null,
        additionalInfo,
        execute,
      ]);
    }
  });
};

// 선택된 block의 부모 combo box(gridId, formId)가 변경될 때 호출
export const restartChildComboExtension = (fieldName) => {
  if (
    fieldName &&
    Blockly.getSelected() &&
    Blockly.getSelected().getField(fieldName)
  ) {
    Blockly.Extensions.apply(fieldName, Blockly.getSelected(), false);
  }
};

// 현재 event workspace에 있는 block의 extension 재실행
export const restartExtension = (
  output,
  blocklyWorkspaceRef,
  blockInfo,
  additionalInfo,
  execute
) => {
  blocklyWorkspaceRef.current.getAllBlocks().map((block) => {
    comboType.map((fieldName) => {
      if (block.getField(fieldName)) {
        applyExtension(
          output,
          fieldName,
          block,
          blockInfo,
          additionalInfo,
          execute
        );
      }
    });
  });
};

export const applyExtension = (
  output,
  fieldName,
  block,
  blockInfo,
  additionalInfo,
  execute
) => {
  const fieldValue = block.getField(fieldName).getValue();
  blockInfo.map((info) => {
    if (info.id === block.id) {
      Blockly.Extensions.TEST_ONLY.allExtensions[fieldName].apply(block, [
        output,
        info,
        additionalInfo,
        execute,
      ]);
    }
  });
  // Blockly.Extensions.apply(fieldName, block, false);
  block.getField(fieldName).setValue(fieldValue);
};
