import { CircularProgress, Tooltip } from "@mui/material";
import Popup from "components/common/Popup";
import UnremovableEntityProgramListPopup from "page/popup/UnremovableEntityProgramListPopup";
import { useState } from "react";
import { AiOutlineApi, AiOutlineClose, AiOutlineExpand } from "react-icons/ai";
import { MdUnfoldLess, MdUnfoldMore } from "react-icons/md";
import { RiDeleteBin6Line, RiInsertRowBottom } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { useStore } from "reactflow";
import ProgramService from "services/ui/ProgramService";
import EntityReduxHelper from "components/builder/entity/editor/helper/EntityReduxHelper";
import * as Event from "components/builder/entity/editor/handler/EntityEditorEventHandler";
import produce from "immer";
import { BiNotepad } from "react-icons/bi";
import { stopEvent } from "components/builder/ui/editor/handler/UIEditorEventHandler";
import { useRef } from "react";
import User from "components/common/utils/UserUtils";
import moment from "moment";
import CommonUtils from "components/common/utils/CommonUtils";
import { Button } from "react-bootstrap";

const zoomSelector = (s) => s.transform[2];

export const TableToolbar = ({
  removeTable,
  entity,
  openAddRowPopup,
  onClickRelation,
  mini,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const zoom = useStore(zoomSelector);
  const output = useSelector((state) => state.outputENT.output);
  const activedComponent = useSelector((state) => state.activedENTComponent);
  const dispatch = useDispatch();
  const [isMemoExpand, setIsMemoExpand] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const memoRef = useRef();

  /**
   * 삭제버튼
   * @param {*} e
   */
  const onClickRemove = (e) => {
    if (entity.entityId) {
      setIsLoading(true);
      const body = {
        entityId: entity.entityId,
      };
      ProgramService.getEntityUsingProgramList(
        body,
        (res) => {
          if (res.data.length > 0) {
            e.preventDefault();
            //팝업창 열기
            const options = {
              effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
              style: {
                content: {
                  width: "50%", //popup의 크기를 50% (default 60%)
                },
              },
            };
            setIsLoading(false);
            Popup.open(
              <UnremovableEntityProgramListPopup programList={res.data} />,
              options
            );
          } else if (res.data.length === 0) {
            if (removeTable) {
              removeTable(e);
            }
          }
        },
        () => {
          setIsLoading(true);
        }
      );
    } else {
      if (removeTable) {
        removeTable(e);
      }
    }
  };

  /**
   * 엔티티 접기
   * @param {*} e
   * @param {*} fold
   */
  const onClickFold = (e, fold) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    const newEntity = produce(entity, (draft) => {
      if (!draft.remark) draft.remark = {};
      draft.remark.fold = fold;
    });
    EntityReduxHelper.updateEntity(dispatch, output, newEntity);
  };

  /**
   * 메모 펼치기
   * @param {*} e
   */
  const onExpandNote = (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    setIsMemoExpand(!isMemoExpand);
  };

  /**
   * 메모 수정
   * @param {*} e
   */
  const onBlurDescription = (e) => {
    stopEvent(e);
    const description = e.currentTarget.value;
    const memo = {
      author: User.getId(),
      description: description,
      time: moment().toDate().getTime(),
    };
    updateMemo(memo);
  };

  /**
   * 메모 수정
   * @param {*} e
   */
  const onConfirmMemo = (e) => {
    stopEvent(e);
    const memo = {
      author: User.getId(),
      description: memoRef.current.value,
      time: moment().toDate().getTime(),
    };
    updateMemo(memo);
  };

  const updateMemo = (memo) => {
    setIsEditMode(false);
    const newEntity = produce(entity, (draft) => {
      if (!draft.remark) draft.remark = {};
      draft.remark.memo = memo;
    });
    EntityReduxHelper.updateEntity(dispatch, output, newEntity);
  };

  return (
    <div className={`table-toolbar ${mini ? "mini" : ""}`}>
      {zoom > 0.5 ? (
        <div className="side left"> {entity.entityType} </div>
      ) : (
        <span />
      )}
      <div
        className={`entity-memo ${isMemoExpand ? "" : "hidden"}`}
        onClick={stopEvent}
      >
        <div className="header">
          <button
            onClick={(e) => {
              stopEvent(e);
              setIsMemoExpand(false);
            }}
          >
            <AiOutlineClose />
          </button>
        </div>
        <div className="description-wrapper">
          {isEditMode ? (
            <textarea
              className="description nodrag"
              defaultValue={entity.remark?.memo?.description}
              ref={memoRef}
              // onBlur={onBlurDescription}
              onClick={stopEvent}
              onScroll={stopEvent}
              onScrollCapture={stopEvent}
            />
          ) : (
            <div
              className="description"
              onClick={stopEvent}
              onDoubleClick={(e) => {
                setIsEditMode(true);
              }}
            >
              {entity.remark?.memo?.description}
            </div>
          )}
        </div>
        <div className="button-wrapper">
          <div className="memo-info">
            <span>{entity.remark?.memo?.author}</span>
            <span>{CommonUtils.getDate(entity.remark?.memo?.time)}</span>
          </div>
          <div>
            {isEditMode ? (
              <>
                <Button
                  size={"sm"}
                  variant="outline-primary"
                  onClick={onConfirmMemo}
                >
                  확인
                </Button>
                <Button
                  size={"sm"}
                  style={{ marginLeft: "3px" }}
                  variant="outline-danger"
                  onClick={(e) => setIsEditMode(false)}
                >
                  취소
                </Button>
              </>
            ) : (
              <Button
                size={"sm"}
                variant="outline-success"
                onClick={(e) => {
                  stopEvent(e);
                  setIsEditMode(true);
                }}
              >
                수정
              </Button>
            )}
          </div>
        </div>
      </div>
      <div className="side right">
        <Tooltip title="메모 작성" placement="left">
          <span onClick={onExpandNote}>
            <BiNotepad size={20 / zoom < 10 ? 15 : 20 / zoom} />
          </span>
        </Tooltip>
        {openAddRowPopup && (
          <Tooltip title="가상칼럼 추가" placement="top">
            <span onClick={openAddRowPopup}>
              <RiInsertRowBottom size={20 / zoom < 10 ? 15 : 20 / zoom} />
            </span>
          </Tooltip>
        )}

        {isLoading ? (
          <div>
            <CircularProgress size={20} />
          </div>
        ) : (
          <Tooltip title="엔티티 삭제" placement="top">
            <span onClick={onClickRemove}>
              <RiDeleteBin6Line size={20 / zoom < 10 ? 15 : 20 / zoom} />
            </span>
          </Tooltip>
        )}
        {onClickRelation && (
          <Tooltip title="엔티티 연결" placement="top">
            <span onClick={onClickRelation}>
              <AiOutlineApi size={20 / zoom < 10 ? 15 : 20 / zoom} />
            </span>
          </Tooltip>
        )}
        {entity.remark?.fold ? (
          <Tooltip title={"펼치기"} placement="right">
            <span
              onClick={(e) =>
                onClickFold(e, entity.remark?.fold ? false : true)
              }
            >
              <MdUnfoldMore size={20 / zoom < 10 ? 15 : 20 / zoom} />
            </span>
          </Tooltip>
        ) : (
          <Tooltip title={"접기"} placement="right">
            <span
              onClick={(e) =>
                onClickFold(e, entity.remark?.fold ? false : true)
              }
            >
              <MdUnfoldLess size={20 / zoom < 10 ? 15 : 20 / zoom} />
            </span>
          </Tooltip>
        )}
      </div>
    </div>
  );
};
