import ArrayUtils from "components/common/utils/ArrayUtils";
import produce from "immer";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import ErdReduxHelper from "../helper/ErdReduxHelper";

/**
 * ERD Builder & ERD 각 노드에서 쓰이는 콜백을 정의하는 HOOK
 * ERD가 변화할때 같이 변화하기 때문에 각 함수들은 useCallback으로 메모리 소모를 최소화한다.
 * @returns
 */
function useErdCallback() {
  const erd = useSelector((state) => state.erd);
  const dispatch = useDispatch();

  /**
   * relation 팝업의 callback
   */
  const relationPopupCallback = useCallback(
    (sourceTable, targetTable, relationType, joinColumns, props) => {
      /**
         *  식별 관계(Identifying)
              - 개체 A, B 사이의 관계에서 A 개체의 기본키가 B 개체의 외래키이면서 동시에 기본키가 되는 관계
              - B 개체의 존재 여부가 A 개체의 존재 여부에 의존적인 경우에 발생, ER 도형에서 식별 관계는 실선으로 표시
            비식별 관계(Non-identifying)
              - 개체 A, B 사이의 관계에서 A 개체의 기본키가 B 개체의 비기본키 영역에서 외래키가 되는 관계
              - B 개체의 존재 여부는 A 개체의 존재 여부와 관계없이 존재
              - 일반적으로 두 개체는 비식별 관계로 존재하는 경우가 많으며, ER 도형에서 점선으로 표기
         */
      const ChangedNodes = [];

      /**
       * 커넥터 정보 입력를 SourceNode에 입력
       */
      const newSourceNode = produce(sourceTable, (draft) => {
        if (!sourceTable.relation) draft.relation = [];
        const relation = {
          target: targetTable.propertyValue.physicalTableNm,
          relationType,
          joinColumns,
        };
        draft.relation.push(relation);
      });
      ChangedNodes.push(newSourceNode);

      if (relationType === "identifying") {
        if (!ArrayUtils.isEmpty(props.keyColumns)) {
          /**
           * 대상 테이블에 컬럼 추가
           * sourceTable에서 넘어온 key 컬럼들이 이미 존재하면 추가 하지 않음
           */
          let kCloumns = [...props.keyColumns];
          const newTargetTable = produce(targetTable, (draft) => {
            draft.propertyValue.columnList =
              targetTable.propertyValue.columnList.map((c) => {
                const col = { ...c };
                const dupPkIndex = kCloumns.findIndex(
                  (pkc) => pkc.physicalName === c.physicalName
                );
                if (dupPkIndex > -1) {
                  col.pkYn = "Y";
                  col.notNull = "Y";
                  if (!col.relationFrom) {
                    col.relationFrom = [];
                  }
                  if (col.relationFrom.indexOf(sourceTable.compId) === -1)
                    col.relationFrom = [...col.relationFrom, sourceTable.id];
                  kCloumns.splice(dupPkIndex, 1);
                }
                return col;
              });
            draft.propertyValue.columnList = [
              ...draft.propertyValue.columnList,
              ...kCloumns,
            ];
          });
          ChangedNodes.push(newTargetTable);
        }
      }
      ErdReduxHelper.updateNodes(dispatch, ChangedNodes, erd);
      if (props.cb) props.cb();
    },
    [erd]
  );

  /**
   * 릴레이션 삭제
   */
  const removeRelation = useCallback(
    (sourceTable, relation, props) => {
      const newTable = produce(sourceTable, (draft) => {
        const { relation: sourceRelation } = sourceTable;
        //해당 릴레이션 삭제
        const relIndex = sourceRelation.findIndex(
          (r) => r.target === relation.target
        );
        if (relIndex > -1) {
          draft.relation.splice(relIndex, 1);
        }
      });

      ErdReduxHelper.updateNodes(dispatch, newTable, erd);
      if (props.cb) props.cb();
    },
    [erd]
  );

  return [relationPopupCallback, removeRelation];
}

export default useErdCallback;
