import { Enums } from "components/builder/BuilderEnum";
import Api from "components/common/Api";
import User from "components/common/utils/UserUtils";
import LocalStorageService from "services/common/LocalService";

class ErdService {
  /**
   * ERD 저장
   * @param {*} data
   * @param {*} callback
   * @param {*} errorCallback
   */
  static saveErd(data, callback, errorCallback) {
    Api.post("/erd/saveErd", data, callback, errorCallback);
  }
  /**
   * ERD 목록 호출
   * 상세 코드는 가져오지 않음
   * @param {*} data
   * @param {*} callback
   * @param {*} errorCallback
   */
  static getErdList(data, callback, errorCallback) {
    Api.post("/erd/getErdList", data, callback, errorCallback);
  }
  /**
   * ERD 단일 전체 정보 호출
   * @param {*} data
   * @param {*} callback
   * @param {*} errorCallback
   */
  static getErd(data, callback, errorCallback) {
    Api.post("/erd/getErd", data, callback, errorCallback);
  }

  /**
   * ERD 삭제
   * @param {*} data
   * @param {*} callback
   * @param {*} errorCallback
   */
  static deleteErd(data, callback, errorCallback) {
    Api.post("/erd/deleteErd", data, callback, errorCallback);
  }

  /**
   * 로컬 스토리지 저장
   * @param {*} erd
   */
  static localStorageSave(erd) {
    const thisTarget = {
      appReleaseId: erd.appReleaseId,
      erdUid: erd.erdUid,
      erdName: erd.erdName,
    };

    const saveAsData = () => {
      const body = {
        userId: User.getId(),
        list: [thisTarget],
      };
      LocalStorageService.set(Enums.LocalStorageName.ERD_HISTORY, body);
    };

    const localHistStorage = LocalStorageService.get(
      Enums.LocalStorageName.ERD_HISTORY
    );
    if (localHistStorage) {
      if (localHistStorage.list.length > 10) localHistStorage.list.splice(10);
      //기존에 동일한 프로그램 아이디가 저장되어있으면 해당 인덱스 삭제
      const isDupIndex = localHistStorage.list.findIndex(
        (p) => p.erdUid === thisTarget.erdUid
      );
      if (isDupIndex > -1) localHistStorage.list.splice(isDupIndex, 1);
      localHistStorage.list.splice(0, 0, thisTarget);
      LocalStorageService.set(
        Enums.LocalStorageName.ERD_HISTORY,
        localHistStorage
      );
    } else {
      saveAsData();
    }
  }
}

export default ErdService;
