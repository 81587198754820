import MuiConfig from "components/common/config/MuiConfig";
import WijmoGrid from "components/common/element/WijmoGrid";
import Modal from "components/common/modal/UModal";
import UModalTemplate from "components/common/modal/UModalTemplate";
import { useEffect, useState } from "react";
import ConnectionService from "services/common/ConnectionService";

const columns = [
  {
    field: "tenantId",
    headerName: "테넌트 ID",
    width: 130,
  },
  {
    field: "coCd",
    headerName: "회사 코드",
    width: 100,
  },
  {
    field: "connectionNm",
    headerName: "연결 명",
    width: 100,
  },
  {
    field: "protocol",
    headerName: "스키마",
    width: 130,
  },
  {
    field: "host",
    headerName: "호스트",
    width: "*",
  },
  {
    field: "userId",
    headerName: "사용자 ID",
    width: 130,
  },
];

function ConnectionListPopup(props) {
  const { title, callbackFnc } = props;

  const [connectionList, setConnectionList] = useState([]);

  useEffect(() => {
    ConnectionService.getAllConnectionList({}, (res) => {
      if (!res.isError) {
        setConnectionList(res.data);
      }
    });
  }, []);
  const onOpenConnect = (data, event) => {
    callbackFnc(event, data);
  };

  return (
    <Modal>
      <Modal.Header title={title} />
      <Modal.Body>
        <UModalTemplate style={{ height: "630px" }}>
          <WijmoGrid
            getRowId={(row) => row.connectionId}
            columns={columns}
            rows={connectionList}
            onRowDoubleClick={onOpenConnect}
            {...MuiConfig.grid.options}
            pageSize={15}
            rowsPerPageOptions={[15]}
            style={{ height: `calc(60vh + 50px)` }}
          />
        </UModalTemplate>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
}

export default ConnectionListPopup;
