import { Enums } from "components/builder/BuilderEnum";
import { stopEvent } from "components/builder/ui/editor/handler/UIEditorEventHandler";
import Message from "components/common/Message";
import USelectbox from "components/common/element/USelectbox";
import UTextarea from "components/common/element/UTextarea";
import Modal from "components/common/modal/UModal";
import UModalTemplate from "components/common/modal/UModalTemplate";
import ArrayUtils from "components/common/utils/ArrayUtils";
import StringUtils from "components/common/utils/StringUtils";
import produce from "immer";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import EventhandlerService from "services/eventhandler/EventhandlerService";

const EventHandlerSavePopup = ({ callback, ...props }) => {
  //이벤트 전체 목록
  const [eventList, setEventList] = useState([]);
  //이벤트를 각 단위별로 분류한 목록
  const [categoryList, setCategoryList] = useState({
    firstList: [],
    secondList: [],
    thirdList: [],
    fourthList: [],
  });

  //첫번째 이벤트 분류값
  const [firstCategory, setFirstCategory] = useState("");
  //두번째 이벤트 분류값
  const [secondCategory, setSecondCategory] = useState("");
  //세번째 이벤트 분류값
  const [thirdCategory, setThirdCategory] = useState("");
  //네번째 이벤트 분류값
  const [fourthCategory, setFourthCategory] = useState("");
  /**
   * 각 분류 값을 1개의 배열로 해도 될거같긴 한데 차후 보수 차원에서 일단
   * 4개로 분리함
   */

  const [saveContents, setSaveContents] = useState({
    eventHandleNm: "",
    eventHandleDesc: "",
    programType: props.componentProp.programType || "A",
    targetType: props.componentProp.targetType || "all",
    eventType: props.componentProp.eventType || "",
  });
  const [isEditable, setIsEditable] = useState(true);

  useEffect(() => {
    /**
     * 이벤트 목록 호출 후 초기 세팅
     */
    EventhandlerService.getEventComponentList({}, (res) => {
      setEventList(res.data);
      let fList = [];
      let sList = [];
      let tList = [];
      let foList = [];
      const findEventCategory = (category, index) => {
        let list = [];
        for (const event of res.data) {
          if (event.eventCd.indexOf(category) > -1) {
            const eventCategory = event.eventCd.split(".");
            if (list.indexOf(eventCategory[index]) === -1) {
              list.push(eventCategory[index]);
            }
          }
        }
        return list;
      };
      for (const event of res.data) {
        const eventCategory = event.eventCd.split(".");
        if (fList.indexOf(eventCategory[0]) === -1) {
          fList.push(eventCategory[0]);
        }
      }

      if (!StringUtils.isEmpty(props.componentProp.eventCd)) {
        const splitEventCd = props.componentProp.eventCd.split(".");
        for (const [index, item] of splitEventCd.entries()) {
          if (!StringUtils.isEmpty(item)) {
            if (index === 0) {
              sList = findEventCategory(`${splitEventCd[0]}.`, 1);
              setFirstCategory(item);
            }
            if (index === 1) {
              tList = findEventCategory(
                `${splitEventCd[0]}.${splitEventCd[1]}.`,
                2
              );
              setSecondCategory(item);
            }
            if (index === 2) {
              foList = findEventCategory(
                `${splitEventCd[0]}.${splitEventCd[1]}.${splitEventCd[2]}`,
                3
              );
              setThirdCategory(item);
            }
            if (index === 3) setFourthCategory(item);
          }
        }
      }

      setCategoryList(
        produce(categoryList, (draft) => {
          draft.firstList = fList;
          if (!ArrayUtils.isEmpty(sList)) draft.secondList = sList;
          if (!ArrayUtils.isEmpty(tList)) draft.thirdList = tList;
          if (!ArrayUtils.isEmpty(foList)) draft.fourthList = foList;
        })
      );
    });
  }, []);

  /**
   * Select 콤보 체인져
   * @param {*} e
   */
  const onChangeValue = (e) => {
    setSaveContents(
      produce(saveContents, (draft) => {
        draft[e.target.id] = e.target.value;
      })
    );
  };

  /**
   * 저장
   * @param {*} e
   */
  const onClickSave = (e) => {
    stopEvent(e);
    if (StringUtils.isEmpty(saveContents.eventHandleNm)) {
      return Message.alert("이벤트 명을 입력해주세요.", Enums.MessageType.WARN);
    }
    if (categoryList.firstList.length > 0 && StringUtils.isEmpty(firstCategory))
      return Message.alert(
        "첫번째 분류를 선택해주세요",
        Enums.MessageType.WARN
      );
    if (
      categoryList.secondList.length > 0 &&
      StringUtils.isEmpty(secondCategory)
    )
      return Message.alert(
        "두번째 분류를 선택해주세요",
        Enums.MessageType.WARN
      );
    if (categoryList.thirdList.length > 0 && StringUtils.isEmpty(thirdCategory))
      return Message.alert(
        "세번째 분류를 선택해주세요",
        Enums.MessageType.WARN
      );
    if (
      categoryList.fourthList.length > 0 &&
      StringUtils.isEmpty(fourthCategory)
    )
      return Message.alert(
        "네번째 분류를 선택해주세요",
        Enums.MessageType.WARN
      );
    if (StringUtils.isEmpty(saveContents.programType))
      return Message.alert(
        "프로그램 유형을 선택해주세요",
        Enums.MessageType.WARN
      );
    if (StringUtils.isEmpty(saveContents.targetType))
      return Message.alert("타겟 유형을 선택해주세요", Enums.MessageType.WARN);
    if (StringUtils.isEmpty(saveContents.eventType))
      return Message.alert(
        "이벤트 유형을 선택해주세요",
        Enums.MessageType.WARN
      );

    let eventCd = firstCategory;
    if (!StringUtils.isEmpty(secondCategory)) eventCd += `.${secondCategory}`;
    if (!StringUtils.isEmpty(thirdCategory)) eventCd += `.${thirdCategory}`;
    if (!StringUtils.isEmpty(fourthCategory)) eventCd += `.${fourthCategory}`;

    if (callback) {
      callback({
        ...saveContents,
        eventCd,
      });
    }
  };

  /**
   * 콤보 값 변경시 리스트 항목 바꿔주는 함수
   * @param {*} e
   * @param {*} index
   */
  const setList = (value, key, index) => {
    let sList = eventList.filter((event) => event.eventCd.indexOf(value) > -1);
    let eventCdList = [];
    for (const event of sList) {
      let eCd = event.eventCd.split(".")[index + 1];
      if (eCd && eventCdList.indexOf(eCd) === -1) {
        eventCdList.push(eCd);
      }
    }

    setCategoryList(
      produce(categoryList, (draft) => {
        draft[key] = eventCdList;
        if (index === 0) {
          draft.thirdList = [];
          draft.fourthList = [];
          setSecondCategory("");
          setThirdCategory("");
          setFourthCategory("");
        } else if (index === 1) {
          draft.fourthList = [];
          setThirdCategory("");
          setFourthCategory("");
        }
      })
    );
  };

  return (
    <Modal>
      <Modal.Header title={"Event 저장"} />
      <Modal.Body>
        <UModalTemplate>
          <Row>
            <Form.Group className="mb-3" as={Col}>
              <Form.Label>Application</Form.Label>
              <Form.Control disabled defaultValue={props.workspace.appNm} />
            </Form.Group>
            <Form.Group className="mb-3" as={Col}>
              <Form.Label>모듈</Form.Label>
              <Form.Control disabled defaultValue={props.workspace.moduleNm} />
            </Form.Group>
            <Form.Group className="mb-3" as={Col}>
              <Form.Label>버전</Form.Label>
              <Form.Control disabled defaultValue={props.workspace.version} />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} className="mb-3">
              <Form.Label>테넌트 ID</Form.Label>
              <Form.Control disabled defaultValue={props.workspace.tenantId} />
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>회사 코드</Form.Label>
              <Form.Control disabled defaultValue={props.workspace.coCd} />
            </Form.Group>
          </Row>

          <Row>
            <Form.Label className="required">이벤트 분류</Form.Label>
            <Form.Group className="mb-3" as={Col}>
              <Form.Label className="required">분류 1</Form.Label>
              <Form.Select
                value={firstCategory}
                onChange={(e) => {
                  setFirstCategory(e.target.value);
                  setList(e.target.value, "secondList", 0);
                }}
              >
                <option value={""}>선택</option>
                {categoryList.firstList.map((e) => {
                  return (
                    <option value={e} key={e}>
                      {e}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" as={Col}>
              {categoryList.secondList.length > 0 && (
                <>
                  <Form.Label className="required">분류 2</Form.Label>
                  <Form.Select
                    value={secondCategory}
                    onChange={(e) => {
                      setSecondCategory(e.target.value);
                      setList(
                        `${firstCategory}.${e.target.value}`,
                        "thirdList",
                        1
                      );
                    }}
                  >
                    <option value={""}>선택</option>
                    {categoryList.secondList.map((e) => {
                      return (
                        <option value={e} key={e}>
                          {e}
                        </option>
                      );
                    })}
                  </Form.Select>
                </>
              )}
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className="mb-3" as={Col}>
              {categoryList.thirdList.length > 0 && (
                <>
                  <Form.Label className="required">분류 3</Form.Label>
                  <Form.Select
                    value={thirdCategory}
                    onChange={(e) => {
                      setThirdCategory(e.target.value);
                      setList(
                        `${firstCategory}.${secondCategory}.${e.target.value}`,
                        "fourthList",
                        2
                      );
                    }}
                  >
                    <option value={""}>선택</option>
                    {categoryList.thirdList.map((e) => {
                      return (
                        <option value={e} key={e}>
                          {e}
                        </option>
                      );
                    })}
                  </Form.Select>
                </>
              )}
            </Form.Group>
            <Form.Group className="mb-3" as={Col}>
              {categoryList.fourthList.length > 0 && (
                <>
                  <Form.Label className="required">분류 4</Form.Label>
                  <Form.Select
                    value={fourthCategory}
                    onChange={(e) => {
                      setFourthCategory(e.target.value);
                    }}
                  >
                    <option value={""}>선택</option>
                    {categoryList.fourthList.map((e) => {
                      return (
                        <option value={e} key={e}>
                          {e}
                        </option>
                      );
                    })}
                  </Form.Select>
                </>
              )}
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} className="mb-3">
              <Form.Label className="required">프로그램 유형</Form.Label>
              <Form.Select
                value={saveContents.programType}
                id={"programType"}
                onChange={onChangeValue}
              >
                <option value="A">전체</option>
                <option value="M">메뉴 프로그램</option>
                <option value="P">팝업 프로그램</option>
              </Form.Select>
            </Form.Group>
            <Form.Group as={Col} className="mb-3">
              <Form.Label className="required">타겟 유형</Form.Label>
              <Form.Select
                value={saveContents.targetType}
                id={"targetType"}
                onChange={onChangeValue}
              >
                <option value="all">전체</option>
                <option value="form">폼</option>
                <option value="grid">그리드</option>
              </Form.Select>
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label className="required">이벤트 유형</Form.Label>
              <Form.Select
                value={saveContents.eventType}
                id={"eventType"}
                onChange={onChangeValue}
              >
                <option value="">선택</option>
                <option value="before">전 처리</option>
                <option value="after">후 처리</option>
                <option value="userFnc">사용자 정의</option>
                <option value="userWrapFnc">사용자 정의 랜더링</option>
                <option value="load">호출</option>
              </Form.Select>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className="mb-3">
              <Form.Label className="required">이벤트 명</Form.Label>
              <input
                type="text"
                id="eventHandleNm"
                className="form-control"
                onChange={onChangeValue}
                value={saveContents.eventHandleNm}
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className="mb-3">
              <Form.Label>이벤트 설명</Form.Label>
              <UTextarea
                popTitle="이벤트 설명"
                textareaId="eventHandleDesc"
                onChange={onChangeValue}
                defaultValue={saveContents.eventHandleDesc}
                codeBox={false}
              />
            </Form.Group>
          </Row>
        </UModalTemplate>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Button onClick={onClickSave}>저장</Modal.Footer.Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EventHandlerSavePopup;
