import {
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import { Enums } from "components/builder/BuilderEnum";
import { AppContext } from "components/common/AppContextProvider";
import Message from "components/common/Message";
import Popup from "components/common/Popup";
import ModuleSelector from "components/common/element/ModuleSelector";
import WijmoGrid from "components/common/element/WijmoGrid";
import CommonUtils, {
  ObjectUtils,
  StringUtils,
} from "components/common/utils/CommonUtils";
import AppModuleRegisterPopup from "page/popup/app/AppModuleRegisterPopup";
import AppReleasePopup from "page/popup/app/AppReleasePopup";
import { useContext, useEffect, useState } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { FaEdit, FaSave, FaTimes } from "react-icons/fa";
import AppService from "services/common/AppService";
import ComponentService from "services/datamodel/DataModelService";
import ProgramService from "services/ui/ProgramService";
import WorkflowService from "services/workflow/WorkflowService";

const columns = [
  {
    field: "appNm",
    headerName: "앱 이름",
    width: 200,
  },
  {
    field: "moduleNm",
    headerName: "모듈명",
    width: 120,
    align: "left",
    headerAlign: "center",
    renderCell: (row) => {
      if (row.moduleCd) {
        return row.moduleNm;
      } else {
        return (
          <span className="blink" style={{ color: "tomato" }}>
            버전 생성 필요
          </span>
        );
      }
    },
    // colSpan: ({ row }) => {
    //   return 2;
    // },
  },
  {
    field: "version",
    headerName: "버전",
    align: "left",
    headerAlign: "center",
  },
  {
    field: "briefDesc",
    headerName: "버전 설명",
    width: "*",
  },
  {
    field: "releaseDate",
    headerName: "배포 날짜",
    width: 160,
    renderCell: (param) => CommonUtils.getDate(param.releaseDate, "datetime"),
    // valueGetter: (param) =>
    //   CommonUtils.getDate(param.row.releaseDate, "datetime"),
    align: "left",
    headerAlign: "center",
  },
];

function AppRelease() {
  const applicationList = useContext(
    AppContext
  ).application.availableList.filter((app) => app.deployable);
  const [appId, setAppId] = useState("");
  const [moduleCd, setModuleCd] = useState("");
  const [version, setVersion] = useState("");
  const [releaseList, setReleaseList] = useState([]);
  const [selectedRelease, setSelectedRelease] = useState({});
  const [moduleOptionIn, setModuleOptionIn] = useState([]);
  const [moduleOptionNotIn, setModuleOptionNotIn] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("로딩 중...");

  useEffect(() => {
    pageInit();
    return () => {
      setReleaseList([]);
    };
  }, []);

  /**
   * 처음 페이지 호출
   * @param {*} e
   */
  const pageInit = (e) => {
    if (e) e.preventDefault();
    onReleaseSearch();
    // getNoVersionApp();
  };

  /**
   * 신규버전 등록 팝업 호출
   * @param {*} e
   */
  const onReleasePopupOpen = (e) => {
    if (e) e.preventDefault();

    const options = {
      effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
      style: {
        content: {
          width: "550px", //popup의 크기를 50% (default 60%)
        },
      },
    };
    Popup.open(
      <AppReleasePopup
        appList={applicationList}
        callBack={onReleaseSearch}
        moduleCreatePopup={onModuleRegisterPopupOpen}
      />,
      options
    );
  };

  /**
   * 신규 모듈 등록 팝업
   * @param {*} e
   */
  const onModuleRegisterPopupOpen = (e, _appId) => {
    if (e) e.preventDefault();

    const options = {
      effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
      style: {
        content: {
          width: "350px", //popup의 크기를 50% (default 60%)
        },
      },
    };
    Popup.open(
      <AppModuleRegisterPopup
        appList={applicationList}
        callBack={onReleaseSearch}
        selectedAppId={_appId || selectedRelease?.appId}
      />,
      options
    );
  };

  /**
   * App 목록 호출
   * @param {*} e
   */
  const onReleaseSearch = (e) => {
    const body = {
      appId,
      moduleCd,
      version,
      useYn: "Y",
    };

    AppService.getReleaseList(body, (res) => {
      //가능한 리스트
      const availableList = res.data.filter((app) =>
        applicationList.reduce((ac, cu) => {
          return ac || StringUtils.equalsIgnoreType(cu.appId, app.appId);
        }, false)
      );
      //접근 가능하지만 버전 등록이 필요한 리스트
      setReleaseList(getNoVersionApp(availableList));

      Message.alert(res.message, Enums.MessageType.INFO);
    });
  };

  /**
   * App 사용 중지
   * @param {*} e
   */
  const onDeleteRelease = (e) => {
    if (e) e.preventDefault();
    Message.confirm(
      "해당 버전을 삭제 하시겠습니까?\n저장된 프로그램, 데이터모델 등이 함께 삭제됩니다.",
      () => {
        setIsLoading(true);
        setLoadingMessage("Application을 삭제 중입니다.");
        AppService.deleteRelease(
          selectedRelease,
          (res) => {
            setLoadingMessage("프로그램을 삭제 중입니다.");
            ProgramService.deleteByAppReleaseId(
              selectedRelease,
              () => {
                setLoadingMessage("데이터 모델을 삭제 중입니다.");
                ComponentService.deleteByAppReleaseId(
                  selectedRelease,
                  () => {
                    setLoadingMessage("워크플로우를 삭제 중입니다.");
                    WorkflowService.deleteByAppReleaseId(
                      selectedRelease,
                      () => {
                        onReleaseSearch();
                        setIsLoading(false);
                        Message.alert(res.message, Enums.MessageType.SUCCESS);
                      },
                      () => setIsLoading(false)
                    );
                  },
                  () => setIsLoading(false)
                );
              },
              () => setIsLoading(false)
            );
          },
          () => setIsLoading(false)
        );
      }
    );
  };

  /**
   * 앱 선택
   * @param {*} param
   */
  const onRowClick = (param) => {
    if (!param.appReleaseId) return false;
    setSelectedRelease(param);

    const { appModule } = param;
    if (appModule && appModule.moduleOption) {
      const moduleOption = JSON.parse(appModule.moduleOption);
      setModuleOptionIn(moduleOption.in);

      if (StringUtils.isEmpty(moduleOption.notIn.join(","))) {
        setModuleOptionNotIn([]);
      } else {
        setModuleOptionNotIn(moduleOption.notIn);
      }
    }
  };

  const onSavePrefix = (value, key) => {
    const { appModule } = selectedRelease;
    let moduleOption = JSON.parse(appModule.moduleOption);
    if (key === "in" && StringUtils.isEmpty(value)) {
      value = "*";
      Message.alert(
        "포함 Prefix에서 미입력시 전체 조회(*) 로 입력됩니다.",
        Enums.MessageType.INFO
      );
    }
    moduleOption[key] = value.split(",");
    appModule.moduleOption = JSON.stringify(moduleOption);

    AppService.saveAppModule(appModule, (res) => {
      const index = releaseList.findIndex(
        (r) => r.appReleaseId === selectedRelease.appReleaseId
      );
      const newList = [...releaseList];
      newList[index].appModule = res.data;
      setReleaseList(newList);
    });
  };

  /**
   * 버전이 등록되지 않은 앱을 붙여서 리턴
   */
  const getNoVersionApp = (_releaseList) => {
    let noVersionApp = applicationList.filter(
      (a) =>
        !_releaseList.find((r) =>
          StringUtils.equalsIgnoreType(r.appId, a.appId)
        )
    );
    for (const _app of noVersionApp) {
      _app.appReleaseId = StringUtils.getUuid();
    }

    return [..._releaseList, ...noVersionApp];
  };

  return (
    <>
      <div className="setting-header">Application 버전 관리</div>
      <div
        className="setting-container"
        style={{ height: "60vh", position: "relative" }}
      >
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <FormControl fullWidth size="small">
              <InputLabel id="application-label">Application 선택</InputLabel>
              <Select
                labelId="application-label"
                label="Application 선택"
                value={appId}
                onChange={(e) => setAppId(e.target.value)}
              >
                <MenuItem value={""}>전체 선택</MenuItem>
                {applicationList.map((app) => {
                  return (
                    <MenuItem value={app.appId} key={app.appId}>
                      {app.appNm}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <ModuleSelector
              size="small"
              value={moduleCd}
              onChange={(e) => setModuleCd(e.target.value)}
              appId={appId}
              disabled={!appId}
              placeholder={appId ? "선택" : "Application을 먼저 선택해주세요"}
              showEmpty
            />
          </Grid>
          <Grid item xs={2}>
            <Button onClick={onReleaseSearch}>검색</Button>
          </Grid>
          <Grid item xs={8}>
            <WijmoGrid
              selectMode="Row"
              columns={columns}
              rows={[...releaseList]}
              style={{ height: "50vh" }}
              onRowClick={onRowClick}
              emptyMessage={"등록된 APP 버전이 없습니다."}
            />
          </Grid>
          <Grid item xs={4} md={4}>
            <div className="application-detail" style={{ height: "50vh" }}>
              {!ObjectUtils.isEmpty(selectedRelease) && (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Form.Label className="bold">릴리즈 버전</Form.Label>
                    <Form.Control
                      value={selectedRelease.version}
                      onChange={() => {}}
                      size={"sm"}
                      readOnly
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Form.Label>모듈 명</Form.Label>
                    <Form.Control
                      value={selectedRelease.moduleNm}
                      onChange={() => {}}
                      size={"sm"}
                      readOnly
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Form.Label>어플리케이션 명</Form.Label>
                    <Form.Control
                      value={selectedRelease.appNm}
                      onChange={() => {}}
                      size={"sm"}
                      readOnly
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Form.Label>어플리케이션 유형</Form.Label>
                    <Form.Control
                      value={selectedRelease.appType}
                      onChange={() => {}}
                      size={"sm"}
                      readOnly
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Form.Label>데이터 모델 조회 Prefix</Form.Label>
                    <Row className="gy-1">
                      <Col xs={2}>
                        <Form.Control defaultValue={"포함"} disabled />
                      </Col>
                      <Col xs={10}>
                        <PrefixEditInput
                          value={moduleOptionIn}
                          category={"in"}
                          onSavePrefix={onSavePrefix}
                        />
                      </Col>
                      <Col xs={2}>
                        <Form.Control defaultValue={"불포함"} disabled />
                      </Col>
                      <Col xs={10}>
                        <PrefixEditInput
                          value={moduleOptionNotIn}
                          category={"notIn"}
                          onSavePrefix={onSavePrefix}
                        />
                      </Col>
                    </Row>
                  </Grid>

                  <Grid item xs={12}>
                    <Form.Label>버전 설명</Form.Label>
                    <Form.Control
                      value={selectedRelease.briefDesc}
                      size={"md"}
                      style={{ resize: "none" }}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Form.Label>개정 내용</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={5}
                      value={selectedRelease.releaseNotes || ""}
                      size={"md"}
                      style={{ resize: "none" }}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Form.Label>배포일</Form.Label>
                    <Form.Control
                      defaultValue={CommonUtils.getDate(
                        selectedRelease.releaseDate,
                        "datetime"
                      )}
                      size={"sm"}
                      readOnly
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Form.Label>최종 배포자</Form.Label>
                    <Form.Control
                      defaultValue={selectedRelease.updtUserId}
                      size={"sm"}
                      readOnly
                    />
                  </Grid>
                </Grid>
              )}
            </div>
          </Grid>
        </Grid>
        {isLoading && (
          <div className="data-load-wrapper">
            <div className="data-load-box">
              <CircularProgress color="inherit" size={13} />
              &nbsp;&nbsp;&nbsp; {loadingMessage}
            </div>
          </div>
        )}
      </div>
      <div className="setting-button">
        <Button
          variant="success"
          onClick={onReleasePopupOpen}
          disabled={isLoading}
        >
          신규 버전 등록
        </Button>
        <Button
          variant="outline-primary"
          onClick={onModuleRegisterPopupOpen}
          disabled={isLoading}
        >
          신규 모듈 등록
        </Button>
        {selectedRelease.appReleaseId && (
          <Button
            variant="danger"
            onClick={onDeleteRelease}
            disabled={isLoading}
          >
            버전 삭제
          </Button>
        )}
      </div>
    </>
  );
}

export default AppRelease;

const PrefixEditInput = ({
  value: _value,
  onSavePrefix,
  category,
  ...props
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [prefixValue, setPrefixValue] = useState("");
  useEffect(() => {
    setPrefixValue(_value);
  }, [_value]);

  const onClickSave = () => {
    onSavePrefix(prefixValue, category);
    if (category === "in" && StringUtils.isEmpty(prefixValue)) {
      setPrefixValue("*");
    }
    setIsEdit(false);
  };
  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      onClickSave();
    }
  };

  return (
    <InputGroup>
      <Form.Control
        value={prefixValue}
        onChange={(e) => setPrefixValue(e.currentTarget.value)}
        readOnly={!isEdit}
        onKeyDown={onKeyDown}
      />
      {isEdit ? (
        <>
          <Button variant="outline-primary" onClick={onClickSave}>
            <FaSave />
          </Button>
          <Button variant="outline-danger" onClick={(e) => setIsEdit(false)}>
            <FaTimes />
          </Button>
        </>
      ) : (
        <Tooltip title="prefix 편집">
          <Button
            variant="outline-success"
            size="sm"
            onClick={(e) => setIsEdit(true)}
          >
            <FaEdit />
          </Button>
        </Tooltip>
      )}
    </InputGroup>
  );
};
