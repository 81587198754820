import { json } from "@codemirror/lang-json";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import CodeMirror from "@uiw/react-codemirror";
import { Enums } from "components/builder/BuilderEnum";
import { initCommand } from "components/builder/ui/reducers/CommandAction";
import { updateService } from "components/builder/workflow/reducer/WorkflowAction";
import Message from "components/common/Message";
import CommonUtils, { StringUtils } from "components/common/utils/CommonUtils";
import { useEffect, useState } from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { HiOutlineClipboardCopy } from "react-icons/hi";
import { MdExpandMore } from "react-icons/md";
import { RiFileCopyLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import WorkflowService from "services/workflow/WorkflowService";
import styled from "styled-components";

const AccordionHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 50px;
  gap: 5px;
`;
const AccordionInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
`;
const AccordionContentsWrapper = styled.div`
  width: 100%;
  max-height: 600px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
const EditorViewer = styled.div`
  max-height: 500px;
  width: 73vw;
  overflow-y: auto;
`;
const ControllButton = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
function WorkflowHistoryAccordionContents({ service: _service, ...props }) {
  const {
    commitComment,
    updtUserId,
    insertDt,
    appApplyType,
    serviceHistoryId,
  } = _service;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const workspace = useSelector((state) => state.workspace);
  const [isExpanded, setIsExpanded] = useState(false);
  const { serviceUid } = useParams();
  const [service, setService] = useState(_service);

  useEffect(() => {
    if (StringUtils.isEmpty(service.serviceContent) && isExpanded)
      getServiceHistory();
  }, [isExpanded]);

  const getServiceHistory = () => {
    WorkflowService.getServiceHistory({ serviceHistoryId }, (res) => {
      setService(res.data);
    });
  };

  /**
   * 선택한 서비스 열기
   */
  const onOpenService = () => {
    Message.confirm(
      "기존 작업하던 워크플로우를 덮어씌웁니다. 진행하시겠습니까?",
      () => {
        const prevService = {
          ...WorkflowService.setData(service),
          serviceUid,
        };
        dispatch(initCommand());
        dispatch(updateService(prevService));
        navigate(
          Enums.BuilderPath.WORKFLOW.MAIN +
            "/" +
            Enums.BuilderPath.WORKFLOW.BUILDER
        );
      }
    );
  };

  /**
   * 선택한 서비스 복원 하기
   */
  const onCopyCodeToCurrentEditor = () => {
    Message.confirm(
      "해당 서비스를 최신 버전으로 덮어씌웁니다. 진행하시겠습니까?",
      () => {
        const prevService = {
          ...workspace,
          ...service,
          serviceUid,
          useYn: "Y",
          releaseCommentYn: "N",
          commitComment: `${CommonUtils.getDate(
            insertDt,
            "datetime"
          )} 항목 복원`,
        };

        WorkflowService.saveService(prevService, (res) => {
          Message.alert("저장 되었습니다.", Enums.MessageType.SUCCESS);
        });
      }
    );
  };
  return (
    <Accordion
      onChange={(e, expanded) => {
        setIsExpanded(expanded);
      }}
    >
      <AccordionSummary
        expandIcon={<MdExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <AccordionHeader>
          <span>{commitComment}</span>
          <AccordionInfo>
            <div>
              {updtUserId} / {CommonUtils.getTZTime(insertDt).fromNow()}에 저장
              되었습니다.
            </div>
            <div>
              <ButtonGroup
                variant="outlined"
                aria-label="outlined button group"
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={appApplyType === "Y" ? true : false}
                      onClick={(e) => e.stopPropagation()}
                    />
                  }
                  label="앱 반영"
                />
              </ButtonGroup>
            </div>
          </AccordionInfo>
        </AccordionHeader>
      </AccordionSummary>
      <AccordionDetails>
        <div></div>
        <AccordionContentsWrapper>
          <ControllButton>
            <ButtonGroup>
              <Button
                onClick={onOpenService}
                style={{ fontSize: "12px" }}
                variant="outline-success"
              >
                <RiFileCopyLine fontSize={"15px"} /> 열기
              </Button>
              <Button
                onClick={onCopyCodeToCurrentEditor}
                style={{ fontSize: "12px" }}
                variant="outline-primary"
              >
                <HiOutlineClipboardCopy fontSize={"15px"} /> 복원
              </Button>
            </ButtonGroup>
          </ControllButton>

          <EditorViewer>
            {!StringUtils.isEmpty(service.serviceContent) && (
              <CodeMirror
                value={JSON.stringify(
                  JSON.parse(service.serviceContent),
                  null,
                  2
                )}
                className="source-container"
                height="500px"
                extensions={[json(true)]}
                autoFocus={false}
                editable={false}
              />
            )}
          </EditorViewer>
        </AccordionContentsWrapper>
      </AccordionDetails>
    </Accordion>
  );
}

export default WorkflowHistoryAccordionContents;
