import ActionType from "components/builder/BuilderActionType";
import produce from "immer";

const initialState = {
  isSidebarCollapsed: true,
  programSearchTerm: {},
  dataModelSearchTerm: {},
  workflowSearchTerm: {},
};

export default function MenuReducer(state = initialState, action) {
  switch (action.type) {
    case ActionType.MENU.SET_SIDEBAR_COLLAPSE:
      return produce(state, (draft) => {
        draft.isSidebarCollapsed = action.payload;
      });
    case ActionType.MENU.INIT:
      return initialState;
    case ActionType.MENU.SEARCH_TERM.PROGRAM:
      return produce(state, (draft) => {
        draft.programSearchTerm = action.payload;
      });
    case ActionType.MENU.SEARCH_TERM.DATA_MODEL:
      return produce(state, (draft) => {
        draft.dataModelSearchTerm = action.payload;
      });
    case ActionType.MENU.SEARCH_TERM.WORKFLOW:
      return produce(state, (draft) => {
        draft.workflowSearchTerm = action.payload;
      });
    default:
      return state;
  }
}
