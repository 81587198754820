import Blockly from "blockly";
import ProgramListPopup from "page/popup/ProgramListPopup";
import ModalOptionPopup from "page/popup/eventhandler/ModalOptionPopup";
import WorkflowListPopup from "page/popup/workflow/WorkflowListPopup";
import Popup from "components/common/Popup";
import { findParentInput } from "../blockExtension/BlockRegisterExtension";
import MessageListPopup from "page/popup/MessageListPopup";

// Blockly create custom field link : https://developers.google.com/blockly/guides/create-custom-blocks/fields/customizing-fields/creating

// Blockly field github link : https://github.com/google/blockly/blob/develop/core/field.ts

// Blockly field_image github link : https://github.com/google/blockly/blob/develop/core/field_image.ts

// Blockly field_dropdown github link : https://github.com/google/blockly/blob/develop/core/field_dropdown.ts

/**
 * popup button field class
 * @param {*} workspace
 */
const BlockFieldPopupButton = (workspace) => {
  if (Blockly.registry.hasItem("Field", "field_popup_button")) {
    Blockly.fieldRegistry.unregister("field_popup_button");
  }
  class FieldPopupButton extends Blockly.Field {
    context = {};

    EDITABLE = true;

    SERIALIZABLE = true;

    clickHandler = null;

    clickTarget_ = null;

    constructor(onClick, config) {
      super(Blockly.Field.SKIP_SETUP);

      if (onClick) {
        this.clickHandler = onClick;
      }

      if (config) {
        this.configure_(config);
      }

      this.setValue("...");
    }

    initView() {
      if (this.shouldAddBorderRect_()) {
        this.createBorderRect_();
      } else {
        this.clickTarget_ = this.sourceBlock_.getSvgRoot();
      }

      this.createTextElement_();

      if (this.borderRect_) {
        Blockly.utils.dom.addClass(this.borderRect_, "blocklyDropdownRect");
      }
    }

    shouldAddBorderRect_() {
      return (
        !this.getConstants().FIELD_DROPDOWN_NO_BORDER_RECT_SHADOW ||
        (this.getConstants().FIELD_DROPDOWN_NO_BORDER_RECT_SHADOW &&
          !this.getSourceBlock()?.isShadow())
      );
    }

    applyColour() {
      if (this.borderRect_) {
        this.borderRect_.setAttribute("fill", "#e3e3e3");
      }
    }

    // Calls showEditor_ when the field is clicked if the field is clickable.
    showEditor_() {
      const parentInput = findParentInput(this.sourceBlock_, this.name);

      let connectField = [];

      if (parentInput) {
        parentInput[0].fieldRow.map((field) => {
          if (field.CURSOR === "text") {
            connectField.push(field);
          }
        });
      }

      if (workspace) {
        if (this.clickHandler === "openProgramListPopup") {
          openProgramListPopup(workspace.Info, connectField[0]);
        } else if (this.clickHandler === "openModalOptionPopup") {
          openModalOptionPopup(this, connectField[0]);
        } else if (this.clickHandler === "openWorkflowListPopup") {
          openWorkflowListPopup(
            workspace.Info,
            this,
            connectField[0],
            connectField[1]
          );
        } else if (this.clickHandler === "openMessageListPopup") {
          openMessageListPopup(workspace, connectField[0]);
        }
      }
    }

    static fromJson(options) {
      const onClick = Blockly.utils.parsing.replaceMessageReferences(
        options["onClick"]
      );
      return new this(onClick);
    }

    doClassValidation_(newValue) {
      if (typeof newValue !== "string") {
        return null;
      }
      return newValue;
    }
  }

  Blockly.fieldRegistry.register("field_popup_button", FieldPopupButton);
};

export default BlockFieldPopupButton;

// ProgramListPopup open 함수
const openProgramListPopup = (workspace, connectField) => {
  const options = {
    effect: Popup.ScaleUp,
    style: {
      content: {
        width: "60%",
        height: "750px",
      },
    },
  };

  Popup.open(
    <ProgramListPopup
      workspace={workspace}
      title="팝업 프로그램 불러오기"
      params={{
        programType: {
          readonly: true,
          value: "P",
        },
      }}
      callbackFnc={(data) => {
        connectField.setValue(`"${data.programId}"`);
        Popup.close();
      }}
    />,
    options
  );
};

// ModalOptionPopup open 함수
const openModalOptionPopup = (buttonField, connectField) => {
  const options = {
    effect: Popup.ScaleUp,
    style: {
      content: {
        width: "50%",
        height: "450px",
      },
    },
  };

  const connectFieldData = connectField.getValue();

  Popup.open(
    <ModalOptionPopup
      title="모달창 Option 설정"
      callbackFnc={(data) => {
        connectField.setValue(data);
        buttonField.options = JSON.parse(data);
        Popup.close();
      }}
      connectFieldData={connectFieldData.replace(/ /gi, "")}
      existOptions={buttonField.options || {}}
    />,
    options
  );
};

// Workflow list 선택 팝업
const openWorkflowListPopup = (workspace, buttonField, nameField, idField) => {
  const options = {
    style: {
      content: {
        width: "55%",
      },
    },
  };

  Popup.open(
    <WorkflowListPopup
      workspace={workspace}
      callbackFnc={(data) => {
        nameField.setValue(data.serviceName);
        idField.setValue(data.serviceId);
        buttonField.uid = String(data.serviceUid);
        Popup.close();
      }}
    />,
    options
  );
};

const openMessageListPopup = (workspace, connectField) => {
  const options = {
    style: {
      content: {
        width: "55%",
      },
    },
  };

  Popup.open(
    <MessageListPopup
      workspace={workspace}
      callbackFnc={(data) => {
        connectField.setValue(`"${data.msgCd}"`);
        Popup.close();
      }}
    />,
    options
  );
};
