import classNames from "classnames";
import { useDrag, useDrop, useDragLayer } from "react-dnd";

const UElementDropContainer = ({ index, moveBetweenItem }) => {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: "elType",
    hover: (item) => {},
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    drop: (item) => {
      moveBetweenItem(item.index, index);
    },
    canDrop: (item) => {
      if (item.index === index || item.index + 1 === index) {
        return false;
      } else {
        return true;
      }
    },
  });

  return (
    <div
      className={classNames("drop-zone", { active: isOver && canDrop })}
      style={{
        width: "parent",
        height: isOver && canDrop ? "20px" : "5px",
        backgroundColor: isOver && canDrop ? "#00a2ff" : "transparent",
      }}
      ref={(node) => drop(node)}
    ></div>
  );
};

export default UElementDropContainer;
