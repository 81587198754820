import React, { Fragment } from "react";
import {
  Accordion,
  Button,
  Card,
  Col,
  Form,
  FormLabel,
  InputGroup,
  Overlay,
  Popover,
  Row,
} from "react-bootstrap";
import * as Fa from "react-icons/fa";
import styled from "styled-components";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import produce from "immer";
import { Button as MButton } from "@mui/material";
import { Tabs, Tab } from "react-bootstrap";

import {
  ListWidget,
  PropertyLable,
  PropertyValue,
} from "components/builder/ui/uiComponents/UIComponentStyle";
import {
  StringUtils,
  ObjectUtils,
  ArrayUtils,
  JsonUtils,
} from "components/common/utils/CommonUtils";
import UIComponentSection from "components/builder/ui/editor/UIComponentSection";
import FormComponent from "components/builder/ui/uiComponents/form/FormComponent";
import * as Enums from "components/builder/BuilderEnum";
import UInputPopup from "components/common/element/UInputPopup";
import Popup from "components/common/Popup";
import UElementList from "components/common/element/UElementList";
import USelectbox from "components/common/element/USelectbox";

import GridToolbarBtnPopup from "page/popup/GridToolbarBtnPopup";
import ExtendPopup from "page/popup/ExtendPopup";
import Message from "components/common/Message";
import { AppContext } from "components/common/AppContextProvider";

import { BiCommentDetail } from "react-icons/bi";
import UTextarea from "components/common/element/UTextarea";
import PopupHandleConfigPopup from "page/popup/PopupHandleConfigPopup";
import MesFunTabPopup from "page/popup/MesFunTabPopup";
import { BsArrowReturnRight } from "react-icons/bs";
import { AiOutlineFunction } from "react-icons/ai";
import IconPopup from "page/popup/IconPopup";
import GridNumberingFormatPopup from "page/popup/GridNumberingFormatPopup";

const GridStyled = styled.div`
  /* ---------툴바 탭 Style----------- */
  .nav-item {
    width: 120px;
  }
  #toolbarOptions_buttons .btn-group.el-title {
    display: none;
  }
  .w-12p {
    width: 12% !important;
  }
  .w-18p {
    width: 18% !important;
  }
  .w-23p {
    width: 23% !important;
  }
  .w-35p {
    width: 35% !important;
  }
  .w-37p {
    width: 37% !important;
  }
`;
let navigate;

class Grid extends FormComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      targets: [],
      groupInfo: [{}],
    };

    this.btnChange = this.btnChange.bind(this); //BootstrapSwitchButton Change Event
    this.onChangeGridOptions = this.onChangeGridOptions.bind(this); //on Change GridOptions
    this.onChangeAdditionalOptions = this.onChangeAdditionalOptions.bind(this); //on Change AdditionalOptions
    this.onAddLevelRowIcons = this.onAddLevelRowIcons.bind(this);
    this.onDeleteLevelRowIcons = this.onDeleteLevelRowIcons.bind(this);
    this.onChangeLevelRowIcons = this.onChangeLevelRowIcons.bind(this);
    this.onChangeRowNumberingFormat =
      this.onChangeRowNumberingFormat.bind(this);
    this.openPopupSetButton = this.openPopupSetButton.bind(this); //툴바 버튼 설정의 "상세"버튼 클릭시 popup연결
    this.openPopupEvent = this.openPopupEvent.bind(this); //Event 설정의 "Eveent 상세"버튼 클릭시 popup연결
    this.openPopupIcon = this.openPopupIcon.bind(this);
    this.openNumberingFormatPopup = this.openNumberingFormatPopup.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleClickParam = this.handleClickParam.bind(this);
    this.handleClickScrollY = this.handleClickScrollY.bind(this);
  }
  static contextType = AppContext;

  popoverRef = React.createRef();
  popoverRefParam = React.createRef();
  popoverRefScrollY = React.createRef();

  componentDidMount = () => {
    if (this.props.navigate) {
      navigate = this.props.navigate;
    }
  };
  /**
   * Grid toolbar buttons
   */
  GRID_TOOLBAR_DEFAULT_BUTTONS = {
    add: {
      icon: "fa-plus-square",
      name: "행추가",
    },
    clone: {
      icon: "fa-copy",
      name: "행복사",
    },
    remove: {
      icon: "fa-trash",
      name: "행삭제",
    },
    undo: {
      icon: "fa-times-circle",
      name: "행취소",
    },
    downExcelFromServer: {
      icon: "fa-file-excel", //"fa-file-excel-o",
      name: "엑셀다운로드",
    },
    downExcelFromLocal: {
      icon: "fa-file-excel", //"fa-file-excel-o",
      name: "엑셀다운로드",
    },
    pivot: {
      icon: "fa-sync-alt", //"fa-refresh",
      name: "피봇",
    },
  };

  TREE_GRID_OPTIONS = [
    { id: "keyColumnNm", label: "key 칼럼 명" },
    { id: "parentIdColumnNm", label: "상위 칼럼 명" },
    { id: "levelColumnNm", label: "계층 칼럼 명" },
    { id: "sortSeqColumnNm", label: "정렬순서 칼럼 명" },
  ];

  // componentDidMount() {}

  /**
   * BootstrapSwitchButton Change Event
   * @param {*} pId
   * @param {*} pValue
   */
  btnChange = (pId, pValue) => {
    if (pValue === false) {
      console.log(pValue);
    }
    this.onChangeIdValue(pId, pValue);
  };

  /**
   * type에 따른 change option 지정
   * @param {*} pId
   * @param {*} pVal
   * @param {*} pType
   */
  onChangeIdValue = (pId, pVal, pType) => {
    if (pType === "additionalOptions") {
      this.onChangeAdditionalOptions({ target: { id: pId, value: pVal } });
    } else {
      this.onChangeGridOptions({ target: { id: pId, value: pVal } });
    }
  };

  /**
   * on Change GridOptions
   * @param {Event} event
   */
  onChangeGridOptions = (event) => {
    let optionId = event.target.id;
    let optionValue = event.target.value;
    if (
      !StringUtils.isEmpty(optionValue) &&
      event.currentTarget &&
      event.currentTarget.type === "number"
    ) {
      optionValue = Number(optionValue);
    }

    if (
      StringUtils.equalsIgnoreCase(optionId, "gridId") &&
      StringUtils.isEmpty(optionValue)
    ) {
      optionValue = StringUtils.getUuid();
      Message.alert(
        "Grid ID는 공백값이 들어갈 수 없습니다.",
        Enums.MessageType.WARN
      );
    }
    const gridOptions = this.state.propertyValue.gridOptions;
    if (StringUtils.defaultString(gridOptions[optionId]) !== optionValue) {
      //이벤트 처리는 다르게 진행
      if (gridOptions.eventWorkspace && gridOptions.eventWorkspace[optionId]) {
        if (
          gridOptions[optionId].replaceAll("\n", "") !==
          optionValue.replaceAll("\n", "")
        ) {
          this.showEventChangeConfirmMessage(
            () => {
              let newGridOption = this.onDeleteEventWorkspace(
                event.target.id,
                null,
                {
                  eventWorkspacePath: gridOptions,
                }
              );
              const newPropertyValue = produce(
                this.state.propertyValue,
                (draft) => {
                  draft.gridOptions = { ...newGridOption };
                  if (StringUtils.isEmpty(optionValue)) {
                    delete draft.gridOptions[optionId];
                  } else {
                    draft.gridOptions[optionId] = optionValue;
                  }
                }
              );
              this.setState(
                {
                  propertyValue: newPropertyValue,
                },
                () => {
                  //propertyValue.compId 로 target component node를 찾아 json정보를 update한다.
                  this.props.fn.updateProperty(this.state.propertyValue);
                }
              );
            },
            () => {}
          );
        }
      } else {
        const newPropertyValue = produce(this.state.propertyValue, (draft) => {
          if (StringUtils.isEmpty(optionValue)) {
            delete draft.gridOptions[optionId];
          } else {
            draft.gridOptions[optionId] = optionValue;
          }

          //groupInfo 등록 및 삭제
          if (optionId === "aggregationType") {
            if (optionValue !== "P") {
              delete draft.gridOptions["groupInfo"];
            }
          }

          if (
            optionId === "gridId" &&
            !ArrayUtils.isEmpty(draft.gridOptions.columns)
          ) {
            draft.gridOptions.columns.map((column, index) => {
              column.gridId = optionValue;
            });
          }

          if (optionId === "displayMode") {
            if (!ArrayUtils.isEmpty(draft.gridOptions.columns)) {
              draft.gridOptions.columns.map((column, index) => {
                //fileupload일 경우, Data 편집을 "조회"로 고정한다.
                if (
                  StringUtils.isEmpty(column.dataType) ||
                  column.dataType.indexOf("fileupload") < 0
                ) {
                  column.editType = optionValue === "E" ? optionValue : "R";
                }
              });
            }
            // 조회용 Grid일 때 Grid Button 비활성화
            if (optionValue === "D") {
              const newButtons = {};

              [...this.state.buttons].map((button) => {
                if (button.eventFnc) {
                  newButtons[button.id] = button;
                }
              });
              draft.gridOptions.additionalOptions.toolbarOptions.buttons = {
                ...newButtons,
              };
            }
            // 입력용 Grid일 때 Grid Button 활성화
            else if (optionValue === "E") {
              const newButtons = {};

              [...this.state.buttons].map((button) => {
                if (button.eventFnc) {
                  newButtons[button.id] = button;
                }
              });

              for (let i = 0; i < 4; i++) {
                newButtons[this.state.buttons[i].id] = this.state.buttons[i];
              }
              draft.gridOptions.additionalOptions.toolbarOptions.buttons = {
                ...newButtons,
              };
            }
          }

          if (optionId === "dataModelEntityId") {
            // 엔티티 타입 정의
            draft.gridOptions.entityType =
              event.target._data.entityType || "TABLE";

            if (
              StringUtils.includesIgnoreCase(draft.gridOptions.entityType, [
                "PROCEDURE",
                "FUNCTION",
              ])
            ) {
              // 엔티티가 프로시져 타입일때 파라미터 이름 정의
              draft.gridOptions.procedureName = event.target._data.tableNm;
              // 파라미터 목록 정의
              draft.gridOptions.insertOption = this.settingInsertOption({
                parameterList: JSON.parse(event.target._data.parameterList),
                procedureName: event.target._data.tableNm,
              });
            } else {
              delete draft.gridOptions.insertOption;
              delete draft.gridOptions.procedureName;
            }
          }
          //autoRowHeights가 true, autoRowHeightType이 활성화 되면 defaltValue 셋팅
          if (
            optionId === "autoRowHeights" &&
            optionValue &&
            !draft.gridOptions.autoRowHeightType
          ) {
            draft.gridOptions.autoRowHeightType = "SYNC";
          }
        });
        if (optionId === "displayMode") {
          let newButtons = [...this.state.buttons];

          // 입력용 Grid 일 때 Grid Button 사용여부 true
          if (optionValue === "E") {
            newButtons = produce(this.state.buttons, (draft) => {
              for (let i = 0; i < 4; i++) {
                draft[i].useYn = true;
              }
            });
          }
          // 조회용 Grid 일 때 Grid Button 사용여부 false
          else if (optionValue === "D") {
            newButtons = produce(this.state.buttons, (draft) => {
              draft.map((item) => {
                if (!item.eventFnc) {
                  item.useYn = false;
                }
              });
            });
          }
          this.setState({
            ...this.state,
            buttons: newButtons,
          });

          let val = [];
          newButtons
            .filter((item) => item.useYn)
            .map((item, index) => {
              val = { ...val, [item.id]: item };
            });
        }
        this.setState(
          {
            ...this.state.propertyValue,
            propertyValue: newPropertyValue,
          },
          () => {
            //propertyValue.compId 로 target component node를 찾아 json정보를 update한다.
            this.props.fn.updateProperty(this.state.propertyValue);
          }
        );
      }
    }
  };

  /**
   * on Change AdditionalOptions
   * @param {Event} event
   */
  onChangeAdditionalOptions = (event) => {
    let optionId = event.target.id;
    let optionValue = event.target.value;
    let pPropertyId = "";
    let pPropertyValue = "";
    const onBlurCallback = () => {
      if (!ObjectUtils.isEmpty(event.property)) {
        pPropertyId = event.property.id;
        pPropertyValue = event.property.value;
      }
      let strArr = [];

      if (!StringUtils.isEmpty(optionId)) {
        strArr = optionId.split("_");
        optionId = strArr[strArr.length - 1];
      }

      const newPropertyValue = produce(
        this.state.propertyValue.gridOptions,
        (draft) => {
          if (StringUtils.isEmpty(pPropertyValue)) {
            delete draft[pPropertyId];
          } else {
            draft[pPropertyId] = pPropertyValue;
          }

          if (
            ArrayUtils.isArray(optionValue) &&
            optionValue.length === 1 &&
            ObjectUtils.isEmpty(optionValue[0])
          ) {
            //console.log("empty");

            //if (ObjectUtils.isEmpty(optionValue)) {
            //빈값이 들어왔을 경우
            if (strArr[0] === "toolbarOptions") {
              delete draft.additionalOptions.toolbarOptions[optionId];

              if (ObjectUtils.isEmpty(draft.additionalOptions.toolbarOptions)) {
                delete draft.additionalOptions.toolbarOptions;
              }
            } else {
              if (ObjectUtils.isEmpty(draft.additionalOptions[optionId])) {
                delete draft.additionalOptions[optionId];
              }
            }
            if (ObjectUtils.isEmpty(draft.additionalOptions)) {
              delete draft.additionalOptions;
            }
          } else {
            if (ObjectUtils.isEmpty(draft["additionalOptions"])) {
              draft["additionalOptions"] = {};
            }
            //toolbarOptions._ 일 경우
            if (strArr[0] === "toolbarOptions") {
              if (
                ObjectUtils.isEmpty(draft.additionalOptions["toolbarOptions"])
              ) {
                draft["additionalOptions"]["toolbarOptions"] = {};
              }
              if (
                StringUtils.defaultString(
                  draft.additionalOptions["toolbarOptions"][optionId]
                ) !== optionValue
              ) {
                draft.additionalOptions.toolbarOptions[optionId] = optionValue;
              }
            } else if (
              StringUtils.defaultString(draft.additionalOptions[optionId]) !==
              optionValue
            ) {
              draft.additionalOptions[optionId] = optionValue;
            }
          }
        }
      );

      this.setStateGridOptions(newPropertyValue);
    };
    // optionId === id 인경우 커스텀 buttons의 ID가 바뀐것 -> 이벤트 워크스페이스를 검색해서 해당 ID의 워크스페이스를 삭제.
    if (
      optionId === "toolbarOptions_customButton" &&
      !ObjectUtils.isEmpty(this.props.componentInfo.editorAttr.eventWorkspace)
    ) {
      //기존 커스텀과 비교
      let willDeleteId = [];
      const customButtons =
        this.state.propertyValue.gridOptions.additionalOptions.toolbarOptions
          .customButton || [];
      for (const btn of customButtons) {
        if (!StringUtils.isEmpty(btn.id)) {
          //기존 커스텀 버튼과 새로 들어온 커스텀 버튼을 비교해서 신규 배열에서 없는 버튼 아이디를 별도로 수집함
          const willStoreButton = optionValue.find((nb) => nb.id === btn.id);
          if (!willStoreButton) {
            willDeleteId.push(btn.id);
          }
        }
      }

      if (!ArrayUtils.isEmpty(willDeleteId)) {
        //없는 아이디를 이벤트 워크스페이스에서 찾음
        const isDeleteFncDo = willDeleteId.reduce(
          (ac, cu) =>
            this.state.editorAttr.eventWorkspace[cu] ? true : false && ac,
          false
        );
        //찾는게 있으면 삭제 명령 실행
        if (isDeleteFncDo) {
          this.showEventChangeConfirmMessage(() => {
            const newEditorAttr = produce(this.state.editorAttr, (draft) => {
              for (const btnId of willDeleteId) {
                delete draft.eventWorkspace[btnId];
              }
            });
            this.props.fn.updateEditorAttr(this.props.compId, newEditorAttr);
            onBlurCallback();
          });
        } else {
          onBlurCallback();
        }
      } else {
        onBlurCallback();
      }
    } else {
      onBlurCallback();
    }
  };

  /**
   * Tree Grid 일 경우 계층별로 Icon 추가
   * @param {*} index
   * @param {*} icon
   */
  onAddLevelRowIcons = (index, icon) => {
    const newPropertyValue = produce(this.state.propertyValue, (draft) => {
      if (
        draft.gridOptions.levelRowIcons == null ||
        draft.gridOptions.levelRowIcons.length === 0
      ) {
        draft.gridOptions.levelRowIcons = [{ level: null, icon: null }];
      }
      draft.gridOptions.levelRowIcons[index + 1] = { level: null, icon: null };
    });

    this.setState(
      {
        ...this.state.propertyValue,
        propertyValue: newPropertyValue,
      },
      () => {
        //propertyValue.compId 로 target component node를 찾아 json정보를 update한다.
        this.props.fn.updateProperty(this.state.propertyValue);
      }
    );
  };

  /**
   * Tree Grid 일 경우 계층 별로 Icon 삭제
   * @param {*} index
   * @param {*} icon
   */
  onDeleteLevelRowIcons = (index, icon) => {
    const newPropertyValue = produce(this.state.propertyValue, (draft) => {
      if (
        ArrayUtils.isArray(draft.gridOptions.levelRowIcons) &&
        draft.gridOptions.levelRowIcons.length > 0
      ) {
        if (draft.gridOptions.levelRowIcons.length === 1) {
          draft.gridOptions.levelRowIcons = null;
        } else {
          draft.gridOptions.levelRowIcons.splice(index, 1);
        }
      }
    });

    this.setState(
      {
        ...this.state.propertyValue,
        propertyValue: newPropertyValue,
      },
      () => {
        //propertyValue.compId 로 target component node를 찾아 json정보를 update한다.
        this.props.fn.updateProperty(this.state.propertyValue);
      }
    );
  };

  /**
   * Tree Grid 일 경우 계층별 Icon 저장
   * @param {*} index
   * @param {*} icon
   */
  onChangeLevelRowIcons = (index, id, value) => {
    const newPropertyValue = produce(this.state.propertyValue, (draft) => {
      if (
        draft.gridOptions.levelRowIcons == null ||
        draft.gridOptions.levelRowIcons.length === 0
      ) {
        draft.gridOptions.levelRowIcons = [{ level: null, icon: null }];
      }
      if (id === "icon") {
        const level = draft.gridOptions.levelRowIcons[index].level;
        draft.gridOptions.levelRowIcons[index] = { level: level, icon: value };
      } else if (id === "level") {
        const icon = draft.gridOptions.levelRowIcons[index].icon;
        draft.gridOptions.levelRowIcons[index] = { level: value, icon: icon };
      }
    });

    this.setState(
      {
        ...this.state.propertyValue,
        propertyValue: newPropertyValue,
      },
      () => {
        //propertyValue.compId 로 target component node를 찾아 json정보를 update한다.
        this.props.fn.updateProperty(this.state.propertyValue);
      }
    );
  };

  /**
   * 번호 Numbering format
   * @param {*} value
   */
  onChangeRowNumberingFormat = (value) => {
    const newPropertyValue = produce(this.state.propertyValue, (draft) => {
      draft.gridOptions.rowNumberingFormat = value;
    });

    this.setState(
      {
        ...this.state.propertyValue,
        propertyValue: newPropertyValue,
      },
      () => {
        //propertyValue.compId 로 target component node를 찾아 json정보를 update한다.
        this.props.fn.updateProperty(this.state.propertyValue);
      }
    );
  };

  /**
   * 공통부분 - this.setState
   * @param {*} newPropertyValue
   */
  setStateGridOptions = (newPropertyValue) => {
    this.setState(
      {
        propertyValue: {
          ...this.state.propertyValue,
          gridOptions: newPropertyValue,
        },
      },
      () => {
        //propertyValue.compId 로 target component node를 찾아 json정보를 update한다.
        this.props.fn.updateProperty(this.state.propertyValue);
      }
    );
  };

  /**
   *
   * @param {*} e
   * @param {*} title
   */
  openOptionsPopup = (e, title) => {
    const options = {
      keyDownEvent: false,
      effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
      style: {
        content: {
          minHeight: "200px",
        },
      },
    };
    const id = e.currentTarget.formTarget;
    const val = document.getElementById(id).value;

    Popup.open(
      <ExtendPopup
        title={title}
        fieldType="json"
        defaultValue={val}
        callbackFnc={(popVal) => this.callbackFnc(id, popVal)}
      />,
      options
    );
  };

  callbackFnc = (id, val) => {
    const jsonVal = JsonUtils.parseJson(val);
    this.setElementValue("input", id, val);
    this.onChangeGridOptions({ target: { id: id, value: jsonVal } });
  };

  /**
   * Event빌더 팝업 열기위한 프롭스
   * @param {*} type toolbar || event
   * @param {*} item 이벤트 정보 컨테이너
   * @param {String} 툴바 버튼 타입 -> 툴바일때만 입력
   * @returns
   */
  setEventBuilderProps = (type, item, pId) => {
    const eventType = item?.eventType || item?.id;
    let eventWorkspace = item.eventWorkspace || {};

    if (StringUtils.equalsIgnoreCase(type, "toolbar")) {
      eventWorkspace = !ObjectUtils.isEmpty(eventWorkspace)
        ? eventWorkspace
        : {};

      const getEventInfo = (eventType, eventInfo) => {
        return {
          eventWorkspace: eventWorkspace[eventType],
          compId: this.props.componentInfo.compId,
          eventType:
            Enums.EventHandlerEventType[eventType] ||
            Enums.EventHandlerEventType.USR_EVENT_FUNCTION,
          componentEventType: eventInfo.id || eventInfo.eventHandleCd,
          builderEventType: eventType,
          eventCd: eventInfo.eventCd,
          targetType: "grid",
          eventCategory: type,
          buttonId: item.id,
          buttonEventType: pId,
          programType: this.props.output.page.propertyValue?.programType || "M",
        };
      };

      return {
        getEventInfo,
        onClickEventBuilder: (_type, eventInfo) => {
          if ((item.useYn != null || item.useYn !== undefined) && !item.useYn)
            return Message.alert(
              "사용 중인 버튼에만 이벤트를 추가할 수 있습니다.",
              Enums.MessageType.WARN
            );
          else {
            this.props.fn.onClickEventBuilder(getEventInfo(_type, eventInfo));
          }
        },
      };
    } else if (StringUtils.equalsIgnoreCase(type, "event")) {
      let eventInfo = this.props.componentInfo.componentEvent.find(
        (event, index) => event.eventHandleCd === eventType
      );
      let targetType = "";
      let targetObject;
      if (this.state.propertyValue.gridOptions.event) {
        targetObject = this.state.propertyValue.gridOptions.event.find(
          (e) => e.eventType === eventType
        );
        if (!targetObject) {
          const newPropertyValue = produce(
            this.state.propertyValue.gridOptions.event,
            (draft) => {
              const newItem = {
                eventType: eventType,
              };
              if (eventType === "GRID_LOAD") {
                newItem.target = this.state.propertyValue.gridOptions.gridId;
                newItem.type = "grid";
              } else if (
                eventType === "GRID_ROW_CLICK_U" ||
                eventType === "GRID_ROW_DBCLICK_U"
              ) {
                newItem.target = "none";
              }
              draft.push(newItem);
              targetObject = { ...newItem };
            }
          );
          this.onChangeIdValue("event", newPropertyValue);
        }
      } else {
        const newItem = {
          eventType: eventType,
        };
        if (eventType === "GRID_LOAD") {
          newItem.target = this.state.propertyValue.gridOptions.gridId;
          newItem.type = "grid";
        } else if (
          eventType === "GRID_ROW_CLICK_U" ||
          eventType === "GRID_ROW_DBCLICK_U"
        ) {
          newItem.target = "none";
        }
        this.onChangeIdValue("event", [newItem]);
        targetObject = { ...newItem };
      }

      if (targetObject) targetType = targetObject.type;

      const getEventInfo = (eventType) => {
        return {
          eventWorkspace: eventWorkspace[eventType],
          compId: this.props.componentInfo.compId,
          eventType:
            Enums.EventHandlerEventType[eventType] ||
            Enums.EventHandlerEventType.USR_EVENT_FUNCTION,
          builderEventType: item.eventType || eventType,
          eventCd: eventInfo.eventCd,
          targetType: targetType || "grid",
          componentEventType: eventType,
          eventCategory: type,
          programType: this.props.output.page.propertyValue?.programType || "M",
        };
      };

      return {
        eventWorkspace: !ObjectUtils.isEmpty(eventWorkspace)
          ? eventWorkspace
          : null,
        getEventInfo,
        onClickEventBuilder: (_type) => {
          this.props.fn.onClickEventBuilder(getEventInfo(_type));
        },
      };
    }
  };

  /**
   * 툴바 버튼 이벤트 상세 설정 팝업 오픈
   * @param {*} pId
   * @param {*} e
   */
  openPopupSetButton = (pId, e) => {
    const targetId = e.currentTarget.formTarget || e.currentTarget.id;
    const additionalOptions =
      this.state.propertyValue.gridOptions["additionalOptions"] || {};

    const id = StringUtils.substringBefore(targetId, "_");
    const index = StringUtils.substringAfter(targetId, "_");
    let items = [{}];

    if (pId === "buttons") {
      items = [...this.state.buttons];
    } else if (
      !ObjectUtils.isEmpty(additionalOptions) &&
      !ObjectUtils.isEmpty(additionalOptions.toolbarOptions[pId])
    ) {
      let list =
        this.state.propertyValue.gridOptions.additionalOptions.toolbarOptions[
          pId
        ];
      if (pId === "customButton") {
        const customOffButton =
          this.state.propertyValue.gridOptions.additionalOptions.toolbarOptions[
            "customOffButton"
          ];
        if (customOffButton) {
          list = [
            ...this.state.propertyValue.gridOptions.additionalOptions
              .toolbarOptions[pId],
            ...customOffButton,
          ].sort((a, b) => {
            if (a.index && b.index) {
              return a.index - b.index;
            } else if (a.index) {
              return 1;
            } else if (b.index) {
              return -1;
            } else {
              return 0;
            }
          });
        }
      }
      items = list.slice();
    }

    let item = items[index];

    const options = {
      keyDownEvent: false,
      effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
      style: {
        content: {
          width: "50%",
          height: "calc(auto + 300px)", //"680px",
        },
      },
    };
    Popup.open(
      <GridToolbarBtnPopup
        title="툴바 버튼 상세 설정"
        type={pId}
        data={item}
        componentInfo={this.props.componentInfo}
        dataModelId={this.getDataModel()}
        entityId={this.state.propertyValue.gridOptions.dataModelEntityId}
        workspace={this.context.workspace}
        navigate={navigate}
        edit={this.props.fn.updateService}
        codeList={this.context.code}
        callbackFnc={(backItems) => {
          const parentId = "toolbarOptions_" + pId;
          //input box에 값 지정
          if (pId === "customButton") {
            let parent = document.getElementById(parentId);

            let el = parent.querySelector("#id_" + index);
            el.value = backItems["id"];
            this.doInputChangeEvent(el, "input");

            el = parent.querySelector("#name_" + index);
            el.value = backItems["name"];
            this.doInputChangeEvent(el, "input");
          }

          /**
           * 그리드 데이터 적용시 공통된 함수적용
           */
          const applyResult = (data) => {
            items[index] = data;
            if (pId === "buttons") {
              let val = {};
              this.setState({ ...this.state, buttons: items });
              items
                .filter((item) => item.useYn)
                .map((item, index) => {
                  val = { ...val, [item.id]: item };
                });

              this.onChangeAdditionalOptions({
                target: { id: parentId, value: val },
              });
            } else if (pId === "customButton") {
              this.onChangeIdValue(parentId, items, "additionalOptions");
            }
          };

          /**
           * Event Workspace 제거 로직
           */
          if (item.eventWorkspace) {
            let eventNames = [];
            for (const key of Object.keys(backItems)) {
              if (item.eventWorkspace[key] && item[key] !== backItems[key]) {
                eventNames.push(key);
              }
              //빈값으로 들어온 backItem 삭제
              if (StringUtils.isEmpty(backItems[key])) {
                delete item[key];
              } else {
                item = { ...item, [key]: backItems[key] };
              }
            }
            if (!ArrayUtils.isEmpty(eventNames)) {
              this.showEventChangeConfirmMessage(() => {
                //이벤트 워크스페이스 제거
                const newItem = produce(item, (draft) => {
                  for (const eName of eventNames) {
                    delete draft.eventWorkspace[eName];
                  }
                });
                applyResult(newItem);
              });
            } else {
              applyResult(item);
            }
          } else {
            //빈값으로 들어온 backItem 삭제
            Object.keys(backItems).map((key, index) => {
              if (StringUtils.isEmpty(backItems[key])) {
                delete item[key];
              } else {
                // if (pId !== "customButton")
                item = { ...item, [key]: backItems[key] };
              }
            });
            applyResult(item);
          }
        }}
        {...this.setEventBuilderProps("toolbar", item, pId)}
      />,
      options
    );
  };

  /**
   * Event 상세 Popup 연결
   * @param {*} e
   */
  openPopupEvent = (e) => {
    const targetId = e.currentTarget.formTarget || e.currentTarget.id;
    const id = StringUtils.substringBefore(targetId, "_");
    const index = StringUtils.substringAfter(targetId, "_");

    let gridOptions = this.state.propertyValue.gridOptions;
    let items = !ObjectUtils.isEmpty(gridOptions.event)
      ? gridOptions.event.slice()
      : [{}];
    let item = items[index];
    if (StringUtils.isEmpty(item.eventType)) {
      return Message.alert(
        "Event는 필수 입력 항목입니다.",
        Enums.MessageType.ERROR
      );
    }

    /**
     * 팝업 데이터 콜백 함수
     * PopupHandleConfigPopup , MesFunTabPopup
     * 전처리 후처리가 탭으로 나뉘어진 팝업에 사용
     * 이벤트 워크스페이스를 삭제하는 로직을 공유함
     * @param {*} backItems - 콜백 값(파라미터)
     * @param {*} target - 대상 컴포넌트
     * @returns
     */
    const popupCallback = (backItems, target) => {
      return new Promise((resolve, reject) => {
        let data = { ...target, ...backItems };
        JsonUtils.cleanup(data); //"" 공백값 제거
        if (
          !ObjectUtils.isEmpty(backItems.eventWorkspace) &&
          ((target["beforeSubmit"] &&
            target["beforeSubmit"] !== backItems.beforeSubmit) ||
            (target["afterSubmit"] &&
              target["afterSubmit"] !== backItems.afterSubmit))
        ) {
          this.showEventChangeConfirmMessage(
            () => {
              data = produce(data, (draft) => {
                if (backItems.beforeSubmit)
                  delete draft.eventWorkspace.beforeSubmit;
                if (backItems.afterSubmit)
                  delete draft.eventWorkspace.afterSubmit;
              });
              resolve(data);
            },
            () => resolve()
          );
        } else {
          resolve(data);
        }
      });
    };

    if (
      item.eventType === "GRID_ROW_DBCLICK_P" ||
      item.eventType === "GRID_ROW_CLICK_P"
    ) {
      const sizeList = this.context.code.getCodeList("Z0021");
      const positionList = this.context.code.getCodeList("Z0013");

      const options = {
        effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
        style: {
          content: {
            width: "50%",
          },
        },
      };

      Popup.open(
        <PopupHandleConfigPopup
          workspace={this.context.workspace.Info}
          title="팝업 호출 설정"
          id={id}
          fieldType="json"
          item={item}
          data={{
            size: sizeList,
            position: positionList,
          }}
          entityId={this.state.propertyValue.gridOptions.dataModelEntityId}
          callbackFnc={(backItems) => {
            popupCallback(backItems, item).then((data) => {
              if (!ObjectUtils.isEmpty(data)) {
                items[index] = data;
                this.onChangeIdValue("event", items);
              }
            });
          }}
          {...this.setEventBuilderProps("event", item)}
        />,
        options
      );
    } else if (item.eventType === "GRID_ROW_CLICK_Q") {
      const options = {
        keyDownEvent: false,
        effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
        style: {
          content: {
            width: "50%",
          },
        },
      };

      let eventInfo = this.props.componentInfo.componentEvent.filter(
        (event, index) => event.eventHandleCd === "GRID_ROW_CLICK_Q"
      );

      Popup.open(
        <MesFunTabPopup
          title="그리드 Row 클릭 설정"
          fieldType="json"
          item={item}
          eventInfo={eventInfo[0]}
          entityId={this.state.propertyValue.gridOptions.dataModelEntityId}
          callbackFnc={(backItems) => {
            popupCallback(backItems, item).then((data) => {
              if (!ObjectUtils.isEmpty(data)) {
                items[index] = data;
                this.onChangeIdValue("event", items);
              }
            });
          }}
          codeList={this.context.code}
          isShowOperator={true}
          {...this.setEventBuilderProps("event", item)}
        />,
        options
      );
    } else {
      const options = {
        keyDownEvent: false,
        effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
        style: {
          content: {
            width: "50%",
            height: "calc(auto + 100px)",
          },
        },
      };

      Popup.open(
        <GridToolbarBtnPopup
          title="Event 상세 설정"
          type="event"
          data={item}
          componentInfo={this.props.componentInfo}
          componentDtlId={this.props.componentInfo.componentDtlId}
          entityId={this.state.propertyValue.gridOptions.dataModelEntityId}
          codeList={this.context.code}
          callbackFnc={(backItems) => {
            //빈값으로 들어온 backItem 삭제
            Object.keys(backItems).map((key, index) => {
              if (StringUtils.isEmpty(backItems[key])) {
                delete backItems[key];
              }
            });
            //eventWorkspace가 있는 경우 & 함수가 임의 변경된 경우 EventWorspace 삭제
            if (
              !ObjectUtils.isEmpty(item.eventWorkspace) &&
              ((item["beforeSubmit"] &&
                item["beforeSubmit"] !== backItems.beforeSubmit) ||
                (item["eventFnc"] && item["eventFnc"] !== backItems.eventFnc) ||
                (item["afterSubmit"] &&
                  item["afterSubmit"] !== backItems.afterSubmit))
            ) {
              this.showEventChangeConfirmMessage(() => {
                const changeEventNames = [];
                if (item["beforeSubmit"] !== backItems.beforeSubmit)
                  changeEventNames.push("beforeSubmit");
                if (item["eventFnc"] !== backItems.eventFnc)
                  changeEventNames.push("eventFnc");
                if (item["afterSubmit"] !== backItems.afterSubmit)
                  changeEventNames.push("afterSubmit");

                if (!ArrayUtils.isEmpty(changeEventNames)) {
                  backItems.eventWorkspace = produce(
                    backItems.eventWorkspace,
                    (draft) => {
                      changeEventNames.map((key) => delete draft[key]);
                    }
                  );
                }
                items[index] = { ...backItems };
                this.onChangeIdValue("event", items);
              });
            } else {
              items[index] = { ...backItems };
              this.onChangeIdValue("event", items);
            }
          }}
          {...this.setEventBuilderProps("event", item)}
        />,
        options
      );
    }
  };

  /**
   * Icon Popup Open
   * @param {*} e
   */
  openPopupIcon = (icon, index) => {
    const popOptions = {
      effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
      style: {
        content: {
          height: "700px",
        },
      },
    };

    Popup.open(
      <IconPopup
        title="Icon 조회"
        defaultValue={icon}
        callbackFnc={(icon) => {
          this.onChangeLevelRowIcons(index, "icon", icon);
        }}
      />,
      popOptions
    );
  };

  /**
   * Header 번호 매기기 포맷 선택 popup 창 오픈
   */
  openNumberingFormatPopup = () => {
    const options = {
      effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
      style: {
        content: {
          width: "800px",
          maxHeight: "700px",
        },
      },
    };
    Popup.open(
      <GridNumberingFormatPopup
        title="Grid Progress Bar Cell Template 사용 가이드"
        defaultValue={this.state.propertyValue.gridOptions.rowNumberingFormat}
        callbackFnc={(value) => {
          this.onChangeRowNumberingFormat(value);
        }}
      />,
      options
    );
  };

  handleClick = (event) => {
    this.setState({
      popoverShow: !this.state.popoverShow,
      popoverTarget: event.target,
    });
  };

  handleClickParam = (event) => {
    this.setState({
      popoverShowParam: !this.state.popoverShowParam,
      popoverTargetParam: event.target,
    });
  };

  handleClickScrollY = (event) => {
    this.setState({
      popoverShowScrollY: !this.state.popoverShowScrollY,
      popoverTargetScrollY: event.target,
    });
  };

  onClickAddGroupInfo = (e, index) => {
    const newParams = produce(this.state.groupInfo, (draft) => {
      draft.splice(index + 1, 0, {});
    });
    this.setState({ groupInfo: newParams });
  };

  onClickDeleteGroupInfo = (e, index) => {
    const newParams = produce(this.state.groupInfo, (draft) => {
      draft.splice(index, 1);
      if (draft.length === 0) {
        draft.splice(0, 0, {});
      }
    });
    this.setState({ groupInfo: newParams });

    let newGroupInfo = [];
    newParams.map((data, i) => {
      newGroupInfo.push(data.value);
    });
    this.onChangeGridOptions({
      target: {
        id: "groupInfo",
        value: newGroupInfo,
      },
    });
  };

  /**
   * Properties tab panel을 Redering
   * @returns
   */
  renderPropertiesPanel = () => {
    const gridOptions = this.state.propertyValue.gridOptions || {};
    const additionalOptions = gridOptions.additionalOptions || {};
    const toolbarOptions = additionalOptions.toolbarOptions || {};

    if (
      !ObjectUtils.isEmpty(additionalOptions) &&
      !ObjectUtils.isEmpty(toolbarOptions) &&
      !ObjectUtils.isEmpty(toolbarOptions.buttons)
    ) {
      let btnList = Object.keys(this.GRID_TOOLBAR_DEFAULT_BUTTONS).map(
        (key) => ({
          ...this.GRID_TOOLBAR_DEFAULT_BUTTONS[key],
          ...toolbarOptions.buttons[key],
          id: key,
          useYn: ArrayUtils.isEmpty(toolbarOptions.buttons)
            ? toolbarOptions.buttons.hasOwnProperty(key)
            : toolbarOptions.buttons.indexOf(key) !== -1,
        })
      );

      //비교
      if (
        ObjectUtils.isEmpty(this.state.buttons) ||
        !ObjectUtils.isEmpty(
          btnList.filter((el) => this.state.buttons.includes(el))
        )
      ) {
        this.setState({
          ...this.state,
          buttons: btnList.sort((a, b) => {
            return a.sortIndex - b.sortIndex;
          }),
        });
      }
    } else if (ObjectUtils.isEmpty(this.state.buttons)) {
      let btnList = Object.keys(this.GRID_TOOLBAR_DEFAULT_BUTTONS).map(
        (key) => ({
          ...this.GRID_TOOLBAR_DEFAULT_BUTTONS[key],
          id: key,
          useYn: false,
        })
      );

      this.setState({ ...this.state, buttons: btnList });
    }

    //Event 설정 - Target Combo Item List
    let newTargets = [{ id: "none", text: "None Data" }];
    newTargets = newTargets.concat(
      this.getEventTargetNode(
        [Enums.ComponentType.FORM, Enums.ComponentType.GRID],
        false,
        [Enums.FormType.SEARCH]
      )
    );

    // newTargets.unshift({ id: "", text: "선택" }); //첫째 항목으로 null값 추가
    if (JSON.stringify(this.state.targets) !== JSON.stringify(newTargets)) {
      this.setState({ targets: newTargets });
    }

    //기타 설정 - 그룹 기준
    let groupInfo = !ObjectUtils.isEmpty(gridOptions.groupInfo)
      ? gridOptions.groupInfo
      : null;

    let newGroupInfo = [];
    const stateGorupInfo = JSON.stringify(this.state.groupInfo);
    if (!ArrayUtils.isEmpty(groupInfo)) {
      groupInfo.map((item, index) => {
        newGroupInfo.push({ value: item });
      });

      if (
        !ObjectUtils.isEmpty(groupInfo) &&
        stateGorupInfo !== JSON.stringify(newGroupInfo)
      ) {
        let flag = false;
        if (
          !ObjectUtils.isEmpty(this.state.groupInfo) &&
          stateGorupInfo !== JSON.stringify([{}])
        ) {
          this.state.groupInfo.map((data, i) => {
            if (!StringUtils.isEmpty(data.value)) {
              flag = data.value !== newGroupInfo[i].value;
            }
          });
        } else {
          flag = true;
        }

        //실제 데이터 설정
        if (flag) {
          this.setState({ groupInfo: newGroupInfo });
        }
      }
    }
    return (
      <GridStyled>
        <React.Fragment>
          {/* Title */}
          {this.renderComponentTitle("Grid")}
          <Accordion defaultActiveKey={[0, 5, 10, 32]} alwaysOpen>
            <Accordion.Item eventKey={0}>
              <Accordion.Header>기본정보</Accordion.Header>
              <Accordion.Body>
                <PropertyLable requried="true">ID</PropertyLable>
                <PropertyValue>
                  <input
                    type="text"
                    id="gridId"
                    defaultValue={StringUtils.defaultString(gridOptions.gridId)}
                    className="form-control form-control-sm"
                    onBlur={this.onChangeGridOptions}
                  />
                </PropertyValue>
                <PropertyLable requried="true">Description</PropertyLable>
                <PropertyValue>
                  <input
                    type="text"
                    id="description"
                    defaultValue={StringUtils.defaultString(
                      gridOptions.description
                    )}
                    className="form-control form-control-sm"
                    onBlur={this.onChangeGridOptions}
                  />
                </PropertyValue>
                <PropertyLable requried="true">Data Entity</PropertyLable>
                <PropertyValue>
                  <USelectbox
                    type="search"
                    id="dataModelEntityId"
                    defaultValue={StringUtils.defaultString(
                      gridOptions.dataModelEntityId
                    )}
                    onChange={this.onChangeGridOptions}
                    url="/datamodel/getDataModelEntityList"
                    params={{
                      dataModelId: this.getDataModel(),
                    }}
                    options={{
                      // isChoose: false,
                      matchId: "id",
                      matchNm: "text",
                      beforeChkFn: () => {
                        if (StringUtils.isEmpty(this.getDataModel())) {
                          // Message.alert(
                          //   "Page에 Data Model을 먼저 등록해주세요.",
                          //   Enums.MessageType.ERROR
                          // );
                          return false;
                        }
                        return true;
                      },
                    }}
                  />
                </PropertyValue>
                <PropertyLable>Display 유형</PropertyLable>
                <PropertyValue>
                  <USelectbox
                    type="common"
                    mstCd="Z0006"
                    id="displayMode"
                    defaultValue={StringUtils.defaultString(
                      gridOptions.displayMode
                    )}
                    onChange={this.onChangeGridOptions}
                  />
                </PropertyValue>
                <PropertyLable>Grid 유형</PropertyLable>
                <PropertyValue>
                  <USelectbox
                    id="gridType"
                    defaultValue={StringUtils.defaultString(
                      gridOptions.gridType,
                      "grid"
                    )}
                    items={[
                      {
                        id: "grid",
                        text: "일반 Grid",
                      },
                      {
                        id: "treeGrid",
                        text: "Tree Grid",
                      },
                    ]}
                    onChange={this.onChangeGridOptions}
                    options={{ matchCd: "id", matchNm: "text" }}
                  />
                </PropertyValue>
                {gridOptions.gridType === "treeGrid" && (
                  <>
                    {this.TREE_GRID_OPTIONS.map((option) => {
                      return (
                        <>
                          <PropertyLable requried="true">
                            {option.label}
                          </PropertyLable>
                          <PropertyValue>
                            <USelectbox
                              type="entityField"
                              id={option.id}
                              defaultValue={StringUtils.defaultString(
                                gridOptions[option.id]
                              )}
                              onChange={this.onChangeGridOptions}
                              entityId={gridOptions.dataModelEntityId}
                              options={{
                                matchId: "id",
                                matchNm: "text",
                                beforeChkFn: () => {
                                  if (
                                    StringUtils.isEmpty(
                                      gridOptions.dataModelEntityId
                                    )
                                  ) {
                                    return false;
                                  }
                                  return true;
                                },
                              }}
                            />
                          </PropertyValue>
                        </>
                      );
                    })}
                  </>
                )}
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey={5}>
              <Accordion.Header>툴바 설정</Accordion.Header>
              <Accordion.Body>
                <PropertyLable>Title</PropertyLable>
                <PropertyValue>
                  <input
                    type="text"
                    id="title"
                    defaultValue={StringUtils.defaultString(gridOptions.title)}
                    className="form-control form-control-sm"
                    onBlur={(e) => {
                      let changeData = {
                        target: {
                          id: "toolbarOptions_showTitle",
                          value: false,
                        },
                        property: {
                          id: e.target.id,
                          value: e.target.value,
                        },
                      };

                      if (!StringUtils.isEmpty(e.target.value)) {
                        changeData.target.value = true;
                      }

                      this.onChangeAdditionalOptions(changeData);
                    }}
                  />
                </PropertyValue>
                <PropertyLable>도움말</PropertyLable>
                <PropertyValue>
                  <input
                    type="text"
                    id="helpText"
                    defaultValue={StringUtils.defaultString(
                      gridOptions.helpText
                    )}
                    className="form-control form-control-sm"
                    onBlur={this.onChangeGridOptions}
                  />
                </PropertyValue>
              </Accordion.Body>
            </Accordion.Item>

            {/* 툴바 버튼 설정 */}
            <Accordion.Item eventKey={10}>
              <Accordion.Header>툴바 버튼 설정</Accordion.Header>
              <Accordion.Body>
                <Tabs justify defaultActiveKey="tab-default" id="toolbar-tabs">
                  <Tab
                    eventKey="tab-default"
                    title="기본 버튼"
                    id="tab-default"
                  >
                    <UElementList
                      isDisplay={true}
                      className="pt-2"
                      // bodyStyle={{ height: "250px" }}
                      id="toolbarOptions_buttons"
                      data={this.state.buttons}
                      onBlur={(pId, pValue) => {
                        this.setState({ ...this.state, buttons: pValue });

                        let val = [];
                        pValue
                          .filter((item) => item.useYn)
                          .map((item, index) => {
                            var newItem = { ...item };
                            newItem.sortIndex = index;
                            val = { ...val, [item.id]: newItem };
                          });

                        this.onChangeAdditionalOptions({
                          target: { id: pId, value: val },
                        });
                      }}
                      cols={[
                        {
                          label: "ID",
                          type: "input",
                          id: "id",
                          className: "w-37p",
                          readonly: true,
                        },
                        {
                          label: "Label",
                          type: "input",
                          id: "name",
                          className: "w-30p",
                          placeholder: "grid Button",
                          readonly: true,
                        },
                        {
                          label: "상세",
                          type: "button",
                          id: "btnInfo",
                          className: "w-18p",
                          isShowIcon: true,
                          settings: {
                            readonlyTp: "input",
                            onClick: (e) =>
                              this.openPopupSetButton("buttons", e),
                          },
                        },
                        {
                          label: "사용여부",
                          type: "switch",
                          id: "useYn",
                          className: "w-15p",
                          onlabel: "여",
                          offlabel: "부",
                        },
                      ]}
                      options={{
                        isMulti: false,
                        isHeader: false,
                      }}
                    />
                  </Tab>
                  <Tab eventKey="tab-custom" title="사용자정의 버튼">
                    <UElementList
                      isDisplay={true}
                      // bodyStyle={{ height: "225px" }}
                      id="toolbarOptions_customButton"
                      data={
                        additionalOptions && additionalOptions.toolbarOptions
                          ? additionalOptions.toolbarOptions.customButton
                            ? additionalOptions.toolbarOptions.customButton
                            : [{ useYn: false }]
                          : [{}]
                      }
                      onBlur={(pId, pValue) => {
                        this.onChangeAdditionalOptions({
                          target: { id: pId, value: pValue },
                        });
                      }}
                      cols={[
                        {
                          label: "ID",
                          type: "input",
                          id: "id",
                          className: "w-30p",
                          placeholder: "버튼ID",
                          // readonly: true,
                        },
                        {
                          label: "Label",
                          type: "input",
                          id: "name",
                          className: "w-30p",
                          placeholder: "버튼명",
                          // readonly: true,
                        },
                        {
                          label: "상세",
                          type: "button",
                          id: "btnInfo",
                          className: "w-18p",
                          isShowIcon: true,
                          settings: {
                            readonlyTp: "input",
                            onClick: (e) =>
                              this.openPopupSetButton("customButton", e),
                          },
                        },
                        {
                          label: "사용여부",
                          type: "switch",
                          id: "useYn",
                          className: "w-15p",
                          onlabel: "여",
                          offlabel: "부",
                        },
                      ]}
                      options={{
                        isHeader: false,
                        defaultParams: {
                          eventType: "BTN_USR_EVENT",
                          useYn: true,
                        },
                      }}
                    />
                  </Tab>
                </Tabs>
              </Accordion.Body>
            </Accordion.Item>

            {/* 일반 설정 */}
            <Accordion.Item eventKey={15}>
              <Accordion.Header>일반 설정</Accordion.Header>
              <Accordion.Body>
                <PropertyLable>Header 표시</PropertyLable>
                <PropertyValue>
                  <USelectbox
                    type="common"
                    mstCd="Z0007"
                    id="showHeader"
                    defaultValue={StringUtils.defaultString(
                      gridOptions.showHeader
                    )}
                    onChange={(e) => {
                      this.onChangeGridOptions(e);
                    }}
                  />
                </PropertyValue>

                <PropertyLable>Header 줄바꿈</PropertyLable>
                <PropertyValue>
                  <BootstrapSwitchButton
                    id="lineBreak"
                    checked={StringUtils.defaultString(gridOptions.lineBreak)}
                    size="sm"
                    onstyle="primary"
                    offstyle="dark"
                    onlabel="Yes"
                    offlabel="No"
                    onChange={(value) => this.btnChange("lineBreak", value)}
                  />
                </PropertyValue>
                <PropertyLable>Data 자동 조회</PropertyLable>
                <PropertyValue>
                  <BootstrapSwitchButton
                    id="dataInitialLoad"
                    checked={StringUtils.defaultString(
                      gridOptions.dataInitialLoad
                    )}
                    size="sm"
                    onstyle="primary"
                    offstyle="dark"
                    onlabel="Yes"
                    offlabel="No"
                    onChange={(value) =>
                      this.btnChange("dataInitialLoad", value)
                    }
                  />
                </PropertyValue>
                <PropertyLable>컨텍스트 메뉴</PropertyLable>
                <PropertyValue className="align-self-center">
                  <InputGroup>
                    <BootstrapSwitchButton
                      id="useCtxMenu"
                      checked={StringUtils.defaultString(
                        gridOptions.useCtxMenu,
                        true
                      )}
                      size="sm"
                      width={60}
                      // height={40}
                      onstyle="primary"
                      offstyle="dark"
                      onlabel="사용"
                      offlabel="미사용"
                      onChange={(value) => this.btnChange("useCtxMenu", value)}
                    />
                    {!StringUtils.isEmpty(gridOptions.useCtxMenu) ? (
                      gridOptions.useCtxMenu === false ? (
                        <FormLabel className="ms-2 text-danger">
                          그리드 설정/드릴다운 사용불가
                        </FormLabel>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </InputGroup>
                </PropertyValue>

                <PropertyLable>Prograss Bar</PropertyLable>
                <PropertyValue>
                  <BootstrapSwitchButton
                    id="processing"
                    checked={StringUtils.defaultString(gridOptions.processing)}
                    size="sm"
                    onstyle="primary"
                    offstyle="dark"
                    onlabel="Yes"
                    offlabel="No"
                    onChange={(value) => this.btnChange("processing", value)}
                  />
                </PropertyValue>
                <PropertyLable>엑셀 다운로드</PropertyLable>
                <PropertyValue>
                  <BootstrapSwitchButton
                    id="info"
                    checked={StringUtils.defaultString(gridOptions.info)}
                    size="sm"
                    onstyle="primary"
                    offstyle="dark"
                    onlabel="Yes"
                    offlabel="No"
                    onChange={(value) => this.btnChange("info", value)}
                  />
                </PropertyValue>

                <PropertyLable>높이</PropertyLable>
                <PropertyValue>
                  <InputGroup>
                    <input
                      type="text"
                      id="scrollY"
                      defaultValue={StringUtils.defaultString(
                        gridOptions.scrollY
                      )}
                      onBlur={this.onChangeGridOptions}
                      className="form-control form-control-sm"
                    />
                    <div ref={this.popoverRefScrollY}>
                      <Button
                        variant="outline-secondary"
                        onClick={this.handleClickScrollY}
                        size="sm"
                        className="light-font-color"
                      >
                        도움말
                      </Button>

                      <Overlay
                        show={this.state.popoverShowScrollY}
                        target={this.state.popoverTargetScrollY}
                        placement="bottom"
                        container={this.popoverRefScrollY}
                        containerPadding={20}
                      >
                        <Popover id="popover-contained">
                          <Popover.Header as="h3">
                            Advanced example
                          </Popover.Header>
                          <Popover.Body>
                            <ul>
                              <li>
                                <strong>▣ 예제</strong>
                                <br></br>
                                ㆍauto : 높이 자동 조정
                                <br />
                                ㆍ100px, 200처럼 숫자 입력 시 높이 고정
                              </li>
                            </ul>
                          </Popover.Body>
                        </Popover>
                      </Overlay>
                    </div>
                  </InputGroup>
                </PropertyValue>
                <PropertyLable>높이 자동 맞춤</PropertyLable>
                <PropertyValue>
                  <BootstrapSwitchButton
                    id="scrollCollapse"
                    checked={StringUtils.defaultString(
                      gridOptions.scrollCollapse
                    )}
                    size="sm"
                    onstyle="primary"
                    offstyle="dark"
                    onlabel="Yes"
                    offlabel="No"
                    onChange={(value) =>
                      this.btnChange("scrollCollapse", value)
                    }
                  />
                </PropertyValue>
                <PropertyLable>Cell 선택 유형</PropertyLable>
                <PropertyValue>
                  <USelectbox
                    type="common"
                    mstCd="Z0048"
                    id="selectionMode"
                    defaultValue={StringUtils.defaultString(
                      additionalOptions ? additionalOptions.selectionMode : "",
                      "MultiRange"
                    )}
                    onChange={this.onChangeAdditionalOptions}
                  />
                </PropertyValue>
              </Accordion.Body>
            </Accordion.Item>

            {/* Column 설정 */}
            <Accordion.Item eventKey={20}>
              <Accordion.Header>Column 설정</Accordion.Header>
              <Accordion.Body>
                <PropertyLable>틀 고정 컬럼</PropertyLable>
                <PropertyValue>
                  <USelectbox
                    type="search"
                    id="frozenColumns"
                    defaultValue={StringUtils.defaultString(
                      additionalOptions ? additionalOptions.frozenColumns : ""
                    )}
                    onChange={this.onChangeAdditionalOptions}
                    url="/datamodel/getDataBindingList"
                    params={{
                      entityId: gridOptions.dataModelEntityId,
                    }}
                    extIds={["none"]}
                    options={{
                      matchId: "id",
                      matchNm: "text",
                      beforeChkFn: () => {
                        if (
                          StringUtils.isEmpty(gridOptions.dataModelEntityId)
                        ) {
                          // Message.alert(
                          //   "Grid에 Data Entity을 선택해주세요.",
                          //   Enums.MessageType.ERROR
                          // );
                          return false;
                        }
                        return true;
                      },
                    }}
                  />
                </PropertyValue>

                <PropertyLable className="w-33p">틀 고정 표시</PropertyLable>
                <PropertyLable className="w-33p">Filter 표시</PropertyLable>
                <PropertyLable className="w-33p">
                  Column 설정 표시
                </PropertyLable>
                <PropertyValue className="w-33p">
                  <BootstrapSwitchButton
                    id="pinning"
                    checked={StringUtils.defaultString(gridOptions.pinning)}
                    size="sm"
                    onstyle="primary"
                    offstyle="dark"
                    onlabel="Yes"
                    offlabel="No"
                    onChange={(value) => this.btnChange("pinning", value)}
                  />
                </PropertyValue>
                <PropertyValue className="w-33p">
                  <BootstrapSwitchButton
                    id="isFilter"
                    checked={StringUtils.defaultString(gridOptions.isFilter)}
                    size="sm"
                    onstyle="primary"
                    offstyle="dark"
                    onlabel="Yes"
                    offlabel="No"
                    onChange={(value) => this.btnChange("isFilter", value)}
                  />
                </PropertyValue>
                <PropertyValue className="w-33p">
                  <BootstrapSwitchButton
                    id="colvis"
                    checked={StringUtils.defaultString(gridOptions.colvis)}
                    size="sm"
                    onstyle="primary"
                    offstyle="dark"
                    onlabel="Yes"
                    offlabel="No"
                    onChange={(value) => this.btnChange("colvis", value)}
                  />
                </PropertyValue>

                <PropertyLable className="w-33p">Column 이동</PropertyLable>
                <PropertyLable className="w-33p">Column 정렬</PropertyLable>
                <PropertyLable className="w-33p">Column 크기조절</PropertyLable>
                <PropertyValue className="w-33p">
                  <BootstrapSwitchButton
                    id="colReorder"
                    checked={StringUtils.defaultString(gridOptions.colReorder)}
                    size="sm"
                    onstyle="primary"
                    offstyle="dark"
                    onlabel="Yes"
                    offlabel="No"
                    onChange={(value) => this.btnChange("colReorder", value)}
                  />
                </PropertyValue>
                <PropertyValue className="w-33p">
                  <BootstrapSwitchButton
                    id="orderable"
                    checked={StringUtils.defaultString(gridOptions.orderable)}
                    size="sm"
                    onstyle="primary"
                    offstyle="dark"
                    onlabel="Yes"
                    offlabel="No"
                    onChange={(value) => this.btnChange("orderable", value)}
                  />
                </PropertyValue>
                <PropertyValue className="w-33p">
                  <BootstrapSwitchButton
                    id="resizable"
                    checked={StringUtils.defaultString(gridOptions.resizable)}
                    size="sm"
                    onstyle="primary"
                    offstyle="dark"
                    onlabel="Yes"
                    offlabel="No"
                    onChange={(value) => this.btnChange("resizable", value)}
                  />
                </PropertyValue>
              </Accordion.Body>
            </Accordion.Item>

            {/* Row 설정 */}
            <Accordion.Item eventKey={25}>
              <Accordion.Header>Row 설정</Accordion.Header>
              <Accordion.Body>
                <PropertyLable className="w-33p">
                  Row 높이 자동 조절
                </PropertyLable>
                <PropertyLable className="w-33p">Row 이동</PropertyLable>
                <PropertyLable className="w-33p">Row 순번 표시</PropertyLable>
                <PropertyValue className="w-33p">
                  <BootstrapSwitchButton
                    id="autoRowHeights"
                    checked={StringUtils.defaultString(
                      gridOptions.autoRowHeights
                    )}
                    size="sm"
                    onstyle="primary"
                    offstyle="dark"
                    onlabel="Yes"
                    offlabel="No"
                    onChange={(value) =>
                      this.btnChange("autoRowHeights", value)
                    }
                  />
                </PropertyValue>
                <PropertyValue className="w-33p">
                  <BootstrapSwitchButton
                    id="rowReorder"
                    checked={StringUtils.defaultString(gridOptions.rowReorder)}
                    size="sm"
                    onstyle="primary"
                    offstyle="dark"
                    onlabel="Yes"
                    offlabel="No"
                    onChange={(value) => this.btnChange("rowReorder", value)}
                  />
                </PropertyValue>
                <PropertyValue className="w-33p">
                  <BootstrapSwitchButton
                    id="numbering"
                    checked={StringUtils.defaultString(gridOptions.numbering)}
                    size="sm"
                    onstyle="primary"
                    offstyle="dark"
                    onlabel="Yes"
                    offlabel="No"
                    onChange={(value) => this.btnChange("numbering", value)}
                  />
                </PropertyValue>
                {gridOptions.autoRowHeights === true ? (
                  <Fragment>
                    <PropertyLable>Row 높이 조절 방법</PropertyLable>
                    <PropertyValue>
                      <USelectbox
                        id="autoRowHeightType"
                        type="common"
                        mstCd="Z0052"
                        onChange={this.onChangeGridOptions}
                        defaultValue={StringUtils.defaultString(
                          gridOptions.autoRowHeightType,
                          "SYNC"
                        )}
                      />
                    </PropertyValue>
                  </Fragment>
                ) : (
                  ""
                )}
                <PropertyLable>Header Height</PropertyLable>
                <PropertyValue>
                  <input
                    type="number"
                    id="headerHeight"
                    defaultValue={StringUtils.defaultString(
                      gridOptions.headerHeight
                    )}
                    placeholder="default 38"
                    className="form-control form-control-sm"
                    onBlur={this.onChangeGridOptions}
                  />
                </PropertyValue>
                <PropertyLable>Row Height</PropertyLable>
                <PropertyValue>
                  <input
                    type="number"
                    id="rowHeight"
                    defaultValue={StringUtils.defaultString(
                      gridOptions.rowHeight
                    )}
                    placeholder="default 40"
                    className="form-control form-control-sm"
                    onBlur={this.onChangeGridOptions}
                  />
                </PropertyValue>
                <PropertyLable>Row 선택 유형</PropertyLable>
                <PropertyValue>
                  <USelectbox
                    type="common"
                    mstCd="Z0022"
                    id="selectionType"
                    defaultValue={
                      gridOptions.displayMode === 'D' ? 'N' :
                      StringUtils.defaultString(
                      gridOptions.selectionType
                    )}
                    onChange={this.onChangeGridOptions}
                    options={{
                      isChoose: false,
                    }}
                  />
                </PropertyValue>
                {gridOptions.gridType === "treeGrid" && (
                  <>
                    <PropertyLable>
                      Header 번호<br></br>매기기 포맷
                    </PropertyLable>
                    <PropertyValue>
                      <UInputPopup
                        id="rowNumberingFormat"
                        defaultValue={StringUtils.defaultString(
                          gridOptions.rowNumberingFormat
                        )}
                        //onBlur={onBlur}
                        onClick={this.openNumberingFormatPopup}
                        textReadonly={true}
                      />
                    </PropertyValue>
                    <PropertyLable>계층별 Icon</PropertyLable>
                    <PropertyValue>
                      {ArrayUtils.isArray(gridOptions.levelRowIcons) &&
                      gridOptions.levelRowIcons.length > 0 ? (
                        gridOptions.levelRowIcons.map((item, index) => {
                          let Icon = null;
                          if (item.icon !== null) {
                            Icon =
                              Fa[
                                item.icon
                                  .split("-")
                                  .map((name) => {
                                    return (
                                      name.charAt(0).toUpperCase() +
                                      name.slice(1)
                                    );
                                  })
                                  .join("")
                              ];
                          }
                          return (
                            <Card
                              style={{
                                flexDirection: "row",
                                backgroundColor: "transparent",
                                paddingBottom: "0.5em",
                              }}
                            >
                              <input
                                placeholder="계층 입력"
                                type="number"
                                id="rowLevel"
                                defaultValue={item.level}
                                onBlur={(e) =>
                                  this.onChangeLevelRowIcons(
                                    index,
                                    "level",
                                    e.target.value
                                  )
                                }
                                className="form-control form-control-sm"
                                style={{ width: "40%", marginRight: "0.3em" }}
                              ></input>
                              <Button
                                size="sm"
                                variant="light"
                                onClick={(e) =>
                                  this.openPopupIcon(item.icon, index)
                                }
                                style={{ marginRight: "0.3em" }}
                              >
                                {Icon && <Icon className="mr-5" />}
                                Icon 선택
                              </Button>
                              <Button
                                size="sm"
                                variant="outline-light"
                                onClick={(e) => this.onAddLevelRowIcons(index)}
                                style={{ marginRight: "0.3em" }}
                              >
                                <Fa.FaPlus />
                              </Button>
                              <Button
                                size="sm"
                                variant="outline-light"
                                onClick={(e) =>
                                  this.onDeleteLevelRowIcons(index)
                                }
                                style={{ marginRight: "0.3em" }}
                              >
                                <Fa.FaMinus />
                              </Button>
                            </Card>
                          );
                        })
                      ) : (
                        <Card
                          style={{
                            flexDirection: "row",
                            backgroundColor: "transparent",
                            paddingBottom: "0.5em",
                          }}
                        >
                          <input
                            placeholder="계층 입력"
                            type="number"
                            id="rowLevel"
                            onBlur={(e) =>
                              this.onChangeLevelRowIcons(
                                0,
                                "level",
                                e.target.value
                              )
                            }
                            className="form-control form-control-sm"
                            style={{ width: "40%", marginRight: "0.3em" }}
                          ></input>
                          <Button
                            size="sm"
                            variant="light"
                            onClick={(e) => this.openPopupIcon(null, 0)}
                            style={{ marginRight: "0.3em" }}
                          >
                            Icon 선택
                          </Button>
                          <Button
                            size="sm"
                            variant="outline-light"
                            onClick={(e) => this.onAddLevelRowIcons(0)}
                            style={{ marginRight: "0.3em" }}
                          >
                            <Fa.FaPlus />
                          </Button>
                          <Button
                            size="sm"
                            variant="outline-light"
                            onClick={(e) => this.onDeleteLevelRowIcons(0)}
                            style={{ marginRight: "0.3em" }}
                          >
                            <Fa.FaMinus />
                          </Button>
                        </Card>
                      )}
                    </PropertyValue>
                  </>
                )}
              </Accordion.Body>
            </Accordion.Item>

            {/* Paging 설정 */}
            {/* <Accordion.Item eventKey={30}>
              <Accordion.Header>Paging 설정</Accordion.Header>
              <Accordion.Body>
                <PropertyLable>Paging</PropertyLable>
                <PropertyValue>
                  <BootstrapSwitchButton
                    id="paging"
                    checked={StringUtils.defaultString(gridOptions.paging)}
                    size="sm"
                    onstyle="primary"
                    offstyle="dark"
                    onlabel="Yes"
                    offlabel="No"
                    onChange={(value) => {
                      this.onChangeGridOptions("pageLength", "");
                      this.btnChange("paging", value);
                    }}
                  />
                </PropertyValue>
                <PropertyLable>페이지당 건수</PropertyLable>
                <PropertyValue>
                  <input
                    type="number"
                    id="pageLength"
                    defaultValue={StringUtils.defaultString(
                      gridOptions.pageLength
                    )}
                    className="form-control form-control-sm"
                    onBlur={this.onChangeGridOptions}
                    readOnly={StringUtils.defaultString(
                      !gridOptions.paging,
                      false
                    )}
                  />
                </PropertyValue>
                <PropertyLable>Data 건수 선택</PropertyLable>
                <PropertyValue>
                  <BootstrapSwitchButton
                    id="lengthMenu"
                    checked={StringUtils.defaultString(gridOptions.lengthMenu)}
                    size="sm"
                    onstyle="primary"
                    offstyle="dark"
                    onlabel="Yes"
                    offlabel="No"
                    onChange={(value) => this.btnChange("lengthMenu", value)}
                    disabled={StringUtils.defaultString(
                      !gridOptions.paging,
                      false
                    )}
                  />
                </PropertyValue>
              </Accordion.Body>
            </Accordion.Item> */}

            <Accordion.Item eventKey={31}>
              <Accordion.Header>Event 설정</Accordion.Header>
              <Accordion.Body>
                {/*<div className="event-list-props">
                  <div className="event-list-group">
                     {this.props.componentInfo.componentEvent
                      .filter(
                        (e) => e.comboDisplayYn === "Y" || !e.comboDisplayYn
                      )
                      .map((event, index) => {
                        return (
                          <ListWidget
                            title={event.eventHandleNm}
                            desc={event.componentEventDesc}
                            target={
                              <div
                                className={"propertyValue w-60p"}
                                key={StringUtils.getUuid()}
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  flexFlow: "row nowrap",
                                  margin: "7px 0px 5px 0px",
                                }}
                              >
                                <PropertyLable
                                  style={{ margin: "3px 7px 0px 0px" }}
                                  key={StringUtils.getUuid()}
                                  className="w-40p"
                                >
                                  Target
                                </PropertyLable>
                                <USelectbox
                                  id={"target_" + index}
                                  defaultValue={
                                    event.eventHandleCd === "GRID_LOAD"
                                      ? gridOptions.gridId
                                      : event.eventHandleCd ===
                                        "GRID_ROW_CLICK_U"
                                      ? "none"
                                      : event.eventHandleCd ===
                                        "GRID_ROW_DBCLICK_U"
                                      ? "none"
                                      : gridOptions.event &&
                                        !ArrayUtils.isEmpty(
                                          gridOptions.event.filter(
                                            (item) =>
                                              item.eventType ===
                                              event.eventHandleCd
                                          )
                                        )
                                      ? StringUtils.defaultString(
                                          gridOptions.event.filter(
                                            (item) =>
                                              item.eventType ===
                                              event.eventHandleCd
                                          )[0]["target"]
                                        )
                                      : ""
                                  }
                                  onChange={(targetData, data, e) => {
                                    const newPropertyValue = produce(
                                      gridOptions.event,
                                      (draft) => {
                                        if (
                                          ArrayUtils.isEmpty(
                                            [...draft].filter(
                                              (item) =>
                                                item.eventType ===
                                                event.eventHandleCd
                                            )
                                          )
                                        ) {
                                          const newData = {
                                            eventType: event.eventHandleCd,
                                            target: data.id,
                                            type: data.type,
                                          };
                                          draft.push(newData);
                                        } else {
                                          for (
                                            let i = 0;
                                            i < draft.length;
                                            i++
                                          ) {
                                            if (
                                              draft[i].eventType ===
                                              event.eventHandleCd
                                            ) {
                                              draft[i].target = data.id;
                                              draft[i].type = data.type;
                                            }
                                          }
                                        }
                                      }
                                    );

                                    this.onChangeIdValue(
                                      "event",
                                      newPropertyValue
                                    );
                                  }}
                                  disabled={
                                    StringUtils.isEmpty(event.eventHandleCd) ||
                                    [
                                      "GRID_LOAD",
                                      "GRID_ROW_CLICK_U",
                                      "GRID_ROW_DBCLICK_U",
                                    ].indexOf(event.eventHandleCd) !== -1
                                  }
                                  {...{
                                    type: "static",
                                    items: this.state.targets,
                                    options: {
                                      matchId: "id",
                                      matchNm: "text",
                                      filter: (
                                        codeItem,
                                        codeIndex,
                                        selectProps,
                                        allItems
                                      ) => {
                                        let eventType = event.eventHandleCd;

                                        /**
                                         *  - Row 클릭 (Query, Binding) - 검색Form과 자신 Grid는 제외 시켜야함 | GRID_ROW_CLICK_Q, GRID_ROW_CLICK_B
                                         *  - Row 더블클릭 - Target- 팝업 프로그램 검색 으로 변경, Event 상세 수정 필요 | GRID_ROW_DBCLICK_P
                                         
                                        if (
                                          [
                                            "GRID_ROW_CLICK_Q",
                                            "GRID_ROW_CLICK_B",
                                          ].indexOf(eventType) !== -1
                                        ) {
                                          return !(
                                            codeItem.type === "grid" &&
                                            codeItem.id === gridOptions.gridId
                                          );
                                        } else if (
                                          eventType === "GRID_ROW_DBCLICK_P"
                                        ) {
                                        }
                                        return true;
                                      },
                                    },
                                  }}
                                />
                              </div>
                            }
                          >
                            <Button
                              id={"btnInfo_" + index}
                              onClick={(e) => {
                                this.openPopupEvent(e, event.eventHandleCd);
                              }}
                              className="btn btn-sm w-full el-list-btn"
                              data-item={JSON.stringify({
                                eventType: event.eventHandlerCd,
                              })}
                              variant="light"
                            >
                              <AiOutlineFunction className="mr-5" />
                            </Button>
                          </ListWidget>
                        );
                      })}
                  </div>
                    </div>*/}
                <UElementList
                  isDisplay={true}
                  bodyStyle={{ maxHeight: "225px", minHeight: "38px" }}
                  id="event"
                  data={gridOptions.event}
                  onBlur={(pId, pValue) => this.onChangeIdValue(pId, pValue)}
                  cols={[
                    {
                      label: "Event",
                      type: "select",
                      id: "eventType",
                      className: "w-40p",
                      requried: true,
                      onChange: (changeItem, changeValue) => {
                        if (changeValue === undefined) {
                          return {};
                        }
                        let ent = this.props.componentInfo.componentEvent;
                        ent = ent.filter(
                          (e) => e["eventHandleCd"] === changeValue
                        )[0];
                        if (ent.allowMultipleYn === "N") {
                          if (
                            !ObjectUtils.isEmpty(gridOptions.event) &&
                            gridOptions.event.filter(
                              (e) => changeValue === e.eventType
                            ).length > 0
                          ) {
                            Message.alert(
                              "Event의 [" +
                                ent.eventHandleNm +
                                "]는(은) 단일 입력항목입니다.",
                              Enums.MessageType.ERROR
                            );
                            return { ...changeItem, eventType: "" };
                          }
                        }

                        /**
                         *  - Grid initial Data load - Target 은 자신 Grid로 Fix되어야 함 | GRID_LOAD
                         *  - Row 클릭 (사용자 정의) - Target이 불필요함( None으로 표시) | GRID_ROW_CLICK_U
                         *  - Row 더블클릭 (사용자 정의) - Target이 불필요함( None으로 표시) | GRID_ROW_DBCLICK_U
                         */
                        if (changeValue === "GRID_LOAD") {
                          changeItem.target = gridOptions.gridId;
                          changeItem.type = "grid";
                        } else if (changeValue === "GRID_ROW_CLICK_U") {
                          changeItem.target = "none";
                        } else if (changeValue === "GRID_ROW_DBCLICK_U") {
                          changeItem.target = "none";
                        }

                        return changeItem;
                      },
                      settings: {
                        type: "static",
                        items: this.props.componentInfo.componentEvent.filter(
                          (e) => e.comboDisplayYn === "Y" || !e.comboDisplayYn
                        ),
                        options: {
                          matchId: "eventHandleCd",
                          matchNm: "eventHandleNm",
                        },
                      },
                    },
                    {
                      label: "Target",
                      type: "select",
                      id: "target",
                      className: "w-40p",
                      readonly: (item, index) => {
                        return (
                          StringUtils.isEmpty(item.eventType) ||
                          [
                            "GRID_LOAD",
                            "GRID_ROW_CLICK_U",
                            "GRID_ROW_DBCLICK_U",
                          ].indexOf(item.eventType) !== -1
                        );
                      },
                      onChange: (rowData, colData, comboItem) => {
                        rowData.type = comboItem.type;
                        return rowData;
                      },
                      settings: {
                        type: "static",
                        items: this.state.targets,
                        options: {
                          matchId: "id",
                          matchNm: "text",
                          filter: (
                            codeItem,
                            codeIndex,
                            selectProps,
                            allItems
                          ) => {
                            let idArr = selectProps.id.split("_");
                            let index = idArr[idArr.length - 1];
                            let rowData = ArrayUtils.isEmpty(gridOptions.event)
                              ? {}
                              : gridOptions.event[index];
                            let eventType = rowData.eventType;

                            /**
                             *  - Row 클릭 (Query, Binding) - 검색Form과 자신 Grid는 제외 시켜야함 | GRID_ROW_CLICK_Q, GRID_ROW_CLICK_B
                             *  - Row 더블클릭 - Target- 팝업 프로그램 검색 으로 변경, Event 상세 수정 필요 | GRID_ROW_DBCLICK_P
                             */
                            if (
                              ["GRID_ROW_CLICK_Q", "GRID_ROW_CLICK_B"].indexOf(
                                eventType
                              ) !== -1
                            ) {
                              return !(
                                codeItem.type === "grid" &&
                                codeItem.id === gridOptions.gridId
                              );
                            } else if (eventType === "GRID_ROW_DBCLICK_P") {
                            }
                            return true;
                          },
                        },
                      },
                    },
                    {
                      label: "상세",
                      type: "button",
                      id: "btnInfo",
                      icon: BiCommentDetail,
                      className: "w-10p",
                      isShowIcon: true,
                      isShowLabel: false,
                      settings: {
                        readonlyTp: "input",
                        onClick: (e) => this.openPopupEvent(e),
                      },
                    },
                  ]}
                />
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey={32}>
              <Accordion.Header>Column 편집 Event 설정</Accordion.Header>
              <Accordion.Body>
                <div className="w-full">
                  <div ref={this.popoverRefParam}>
                    <Button
                      variant="outline-secondary"
                      onClick={this.handleClickParam}
                      size="sm"
                      className="light-font-color float-right"
                    >
                      매개변수
                    </Button>

                    <Overlay
                      show={this.state.popoverShowParam}
                      target={this.state.popoverTargetParam}
                      placement="bottom"
                      container={this.popoverRefParam}
                      containerPadding={20}
                    >
                      <Popover id="popover-contained-format">
                        <Popover.Header as="h3">Event 매개변수</Popover.Header>
                        <Popover.Body>
                          <ul>
                            <li>1. $grid : Grid Object</li>
                            <li>2. $colIndex : 선택된 Column Index</li>
                            <li>3. $colName : 선택된 Column Name</li>
                            <li>4. $colData : 선택된 Column Data</li>
                            <li>5. $rowIndex : 선택된 Row Index</li>
                            <li>6. $rowData : 선택된 Row Data</li>
                            <li>7. $event : Wijmo Event 기능</li>
                            <li>
                              8. $selectedItems
                              <br />
                              Data Type이 Select인 경우, Selectbox Row Data
                              <br />
                              (cellEditEnded, cellEditEnding에서만 사용 가능)
                            </li>
                            <li>
                              9. $panel : Wijmo FlexGrid Panel
                              <br />
                              (cellColorFormatter에서만 사용 가능)
                            </li>
                            <li>
                              10. $cellObj : Cell Object
                              <br />
                              (cellColorFormatter에서만 사용 가능)
                            </li>
                          </ul>
                        </Popover.Body>
                      </Popover>
                    </Overlay>
                  </div>
                </div>
                <div className="event-list-props">
                  <div className="event-list-group">
                    <ListWidget
                      title="beginningEdit"
                      desc="Grid Cell이 편집 모드로 바뀌기 전에 실행됩니다."
                    >
                      {this.renderEventTextArea(
                        "beginningEdit",
                        "beginningEdit 상세",
                        {
                          eventWorkspacePath: gridOptions,
                          defaultValue: StringUtils.defaultString(
                            gridOptions.beginningEdit
                          ),
                          onBlur: this.onChangeGridOptions,
                          targetType: "grid",
                          eventCd: "grid.cell.beginningedit",
                        }
                      )}
                    </ListWidget>
                    <ListWidget
                      title="cellReadOnly"
                      desc="Cell을 조건에 따라 Readonly처리 합니다."
                    >
                      {this.renderEventTextArea(
                        "cellReadOnly",
                        "cellReadOnly 상세",
                        {
                          eventWorkspacePath: gridOptions,
                          defaultValue: StringUtils.defaultString(
                            gridOptions.cellReadOnly
                          ),
                          onBlur: this.onChangeGridOptions,
                          targetType: "grid",
                          eventCd: "grid.cell.readonly",
                        }
                      )}
                    </ListWidget>
                    <ListWidget
                      title="cellEditEnding"
                      desc="Grid Cell의 편집을 완료 하려고 할때 실행됩니다. false로 리턴하면 Cell의 편집된 정보가 취소됩니다."
                    >
                      {this.renderEventTextArea(
                        "cellEditEnding",
                        "cellEditEnding 상세",
                        {
                          eventWorkspacePath: gridOptions,
                          defaultValue: StringUtils.defaultString(
                            gridOptions.cellEditEnding
                          ),
                          onBlur: this.onChangeGridOptions,
                          targetType: "grid",
                          eventCd: "grid.cell.editending",
                        }
                      )}
                    </ListWidget>
                    <ListWidget
                      title="cellEditEnded"
                      desc="Grid Cell 편집이 완료되었을때 실행됩니다."
                    >
                      {this.renderEventTextArea(
                        "cellEditEnded",
                        "cellEditEnded 상세",
                        {
                          eventWorkspacePath: gridOptions,
                          defaultValue: StringUtils.defaultString(
                            gridOptions.cellEditEnded
                          ),
                          onBlur: this.onChangeGridOptions,
                          targetType: "grid",
                          eventCd: "grid.cell.editended",
                        }
                      )}
                    </ListWidget>
                    <ListWidget
                      title="cellColorFormatter"
                      desc="Grid Cell의 모양 및 색상을 조건에 따라 다르게 적용 할 수 있습니다."
                    >
                      {this.renderEventTextArea(
                        "cellColorFormatter",
                        "cellColorFormatter 상세",
                        {
                          eventWorkspacePath: gridOptions,
                          defaultValue: StringUtils.defaultString(
                            gridOptions.cellColorFormatter
                          ),
                          onBlur: this.onChangeGridOptions,
                          targetType: "grid",
                          eventCd: "grid.cell.colorformatter",
                        }
                      )}
                    </ListWidget>
                    <ListWidget
                      title="columnHeaderClickEvent"
                      desc="Grid Header를 클릭 했을 때 실행 됩니다."
                    >
                      {this.renderEventTextArea(
                        "columnHeaderClickEvent",
                        "columnHeaderClickEvent 상세",
                        {
                          defaultValue: StringUtils.defaultString(
                            gridOptions.columnHeaderClickEvent
                          ),
                          onBlur: this.onChangeGridOptions,
                          targetType: "grid",
                          eventCd: "grid.cell.columnHeaderClickEvent",
                        }
                      )}
                    </ListWidget>
                    <ListWidget
                      title="selectionChanged"
                      desc="Grid의 선택된 Cell이 변경 되었을때 실행됩니다."
                    >
                      {this.renderEventTextArea(
                        "selectionChanged",
                        "selectionChanged 상세",
                        {
                          eventWorkspacePath: gridOptions,
                          defaultValue: StringUtils.defaultString(
                            gridOptions.selectionChanged
                          ),
                          onBlur: this.onChangeGridOptions,
                          targetType: "grid",
                          eventCd: "grid.selection.changed",
                        }
                      )}
                    </ListWidget>
                    <ListWidget
                      title="validationRule"
                      desc="Grid의 각 Cell의 입력값에 대한 Validation Rule을 적용합니다."
                    >
                      {this.renderEventTextArea(
                        "validationRule",
                        "Validation Rule",
                        {
                          eventWorkspacePath: gridOptions,
                          defaultValue: StringUtils.defaultString(
                            gridOptions.validationRule
                          ),
                          onBlur: this.onChangeGridOptions,
                          targetType: "grid",
                          eventCd: "grid.cell.validation",
                        }
                      )}
                    </ListWidget>

                    {/* 추가 */}
                    <ListWidget
                      title="formatItem"
                      desc="JavaScript를 이용해 조건에 따라 셀의 스타일이나 내용을 변경 할 수 있습니다."
                    >
                      {this.renderEventTextArea("formatItem", "Format Item", {
                        eventWorkspacePath: gridOptions,
                        defaultValue: StringUtils.defaultString(
                          gridOptions.formatItem
                        ),
                        onBlur: this.onChangeGridOptions,
                        targetType: "grid",
                        eventCd: "grid.cell.formatItem",
                      })}
                    </ListWidget>

                    {!StringUtils.equalsIgnoreType(
                      gridOptions.selectionType,
                      "N"
                    ) ? (
                      <ListWidget
                        title="checkboxChanged"
                        desc="Selection 체크박스가 체크 또는 해제될때 호출 됩니다."
                      >
                        {this.renderEventTextArea(
                          "checkboxChanged",
                          "Checkbox Changed",
                          {
                            eventWorkspacePath: gridOptions,
                            defaultValue: StringUtils.defaultString(
                              gridOptions.checkboxChanged
                            ),
                            onBlur: this.onChangeGridOptions,
                            targetType: "grid",
                            eventCd: "grid.checkbox.changed",
                          }
                        )}
                      </ListWidget>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>

            {/* 기타 설정 */}
            <Accordion.Item eventKey={35}>
              <Accordion.Header>기타 설정</Accordion.Header>
              <Accordion.Body>
                <PropertyLable>Grid Toggle</PropertyLable>
                <PropertyValue>
                  <USelectbox
                    type="common"
                    mstCd="Z0025"
                    id="showToggle"
                    defaultValue={StringUtils.defaultString(
                      gridOptions.showToggle
                    )}
                    onChange={this.onChangeGridOptions}
                    options={{
                      isChoose: false,
                    }}
                  />
                </PropertyValue>
                <PropertyLable>Data 삭제 유형</PropertyLable>
                <PropertyValue>
                  <USelectbox
                    type="common"
                    mstCd="Z0008"
                    id="deleteMode"
                    defaultValue={StringUtils.defaultString(
                      gridOptions.deleteMode
                    )}
                    onChange={this.onChangeGridOptions}
                  />
                </PropertyValue>
                <PropertyLable>Grouping</PropertyLable>
                <PropertyValue>
                  <USelectbox
                    type="common"
                    mstCd="Z0009"
                    id="aggregationType"
                    defaultValue={StringUtils.defaultString(
                      gridOptions.aggregationType
                    )}
                    onChange={(e) => {
                      this.onChangeGridOptions(e);
                    }}
                  />
                </PropertyValue>
                {!StringUtils.isEmpty(gridOptions.aggregationType) &&
                gridOptions.aggregationType === "P" ? (
                  <React.Fragment>
                    <PropertyLable>Panel 표시</PropertyLable>
                    <PropertyValue>
                      <BootstrapSwitchButton
                        id="showGroupPanel"
                        checked={StringUtils.defaultString(
                          gridOptions.showGroupPanel
                        )}
                        size="sm"
                        onstyle="primary"
                        offstyle="dark"
                        onlabel="Yes"
                        offlabel="No"
                        onChange={(value) =>
                          this.btnChange("showGroupPanel", value)
                        }
                      />
                    </PropertyValue>
                    <PropertyLable>그룹 기준</PropertyLable>
                    <PropertyValue>
                      <Form.Group
                        style={{
                          maxHeight: "80px",
                          overflowY: "auto",
                          overflowX: "hidden",
                        }}
                      >
                        {!ArrayUtils.isEmpty(this.state.groupInfo)
                          ? this.state.groupInfo.map((param, index) => {
                              return (
                                <Row className="mb-2" key={index}>
                                  <Col>
                                    <USelectbox
                                      type="entityField"
                                      id="value"
                                      defaultValue={StringUtils.defaultString(
                                        param.value
                                      )}
                                      onChange={(e) => {
                                        //State 설정
                                        const newParams = produce(
                                          this.state.groupInfo,
                                          (draft) => {
                                            draft[index] = {
                                              ...param,
                                              [e.target.id]: e.target.value,
                                            };
                                          }
                                        );

                                        this.setState({ groupInfo: newParams });

                                        //실제 데이터 설정
                                        let newGroupInfo = [];
                                        newParams.map((data, i) => {
                                          if (
                                            !StringUtils.isEmpty(data.value)
                                          ) {
                                            newGroupInfo.push(data.value);
                                          }
                                        });

                                        this.onChangeGridOptions({
                                          target: {
                                            id: "groupInfo",
                                            value: newGroupInfo,
                                          },
                                        });
                                      }}
                                      entityId={gridOptions.dataModelEntityId}
                                      options={{
                                        matchId: "id",
                                        matchNm: "text",
                                      }}
                                    />
                                  </Col>
                                  <Col xs={4}>
                                    <Button
                                      id="btnAdd"
                                      onClick={(e) =>
                                        this.onClickAddGroupInfo(e, index)
                                      }
                                      variant={"outline-secondary"}
                                      className="light-font-color"
                                      size="sm"
                                    >
                                      <Fa.FaPlus size="14" />
                                    </Button>
                                    <Button
                                      id="btnRemove"
                                      onClick={(e) =>
                                        this.onClickDeleteGroupInfo(e, index)
                                      }
                                      variant={"outline-secondary"}
                                      className="light-font-color"
                                      size="sm"
                                    >
                                      <Fa.FaMinus size="14" />
                                    </Button>
                                  </Col>
                                </Row>
                              );
                            })
                          : ""}
                      </Form.Group>
                    </PropertyValue>
                  </React.Fragment>
                ) : (
                  ""
                )}
                <PropertyLable>Merge</PropertyLable>
                <PropertyValue>
                  <USelectbox
                    type="common"
                    mstCd="Z0010"
                    id="merging"
                    defaultValue={StringUtils.defaultString(
                      gridOptions.merging
                    )}
                    onChange={this.onChangeGridOptions}
                  />
                </PropertyValue>
                {!StringUtils.isEmpty(gridOptions.merging) ? (
                  <Fragment>
                    <PropertyLable>
                      <BsArrowReturnRight></BsArrowReturnRight> Merge 유형
                    </PropertyLable>
                    <PropertyValue>
                      <USelectbox
                        id="mergingType"
                        onChange={this.onChangeGridOptions}
                        defaultValue={StringUtils.defaultString(
                          gridOptions.mergingType
                        )}
                        items={[
                          { id: "", text: "단계별 동일한 값 병합" },
                          { id: "G", text: "동일한 값 무조건 병합" },
                        ]}
                        options={{
                          matchCd: "id",
                          matchNm: "text",
                          isChoose: false,
                        }}
                      />
                    </PropertyValue>
                  </Fragment>
                ) : (
                  ""
                )}
                <PropertyLable>No Data 메시지</PropertyLable>
                <PropertyValue>
                  <BootstrapSwitchButton
                    id="showEmptyMessage"
                    checked={StringUtils.defaultString(
                      gridOptions.showEmptyMessage
                    )}
                    size="sm"
                    onstyle="primary"
                    offstyle="dark"
                    onlabel="Yes"
                    offlabel="No"
                    onChange={(value) =>
                      this.btnChange("showEmptyMessage", value)
                    }
                  />
                </PropertyValue>
                <PropertyLable>Multi-Row 옵션</PropertyLable>
                <PropertyValue>
                  <UInputPopup
                    popTitle="Multi-Row 옵션 상세"
                    id="multirowOptions"
                    fieldType="json"
                    defaultValue={StringUtils.defaultString(
                      JSON.stringify(gridOptions.multirowOptions)
                    )}
                    onChange={this.onChangeGridOptions}
                    onClick={(e) =>
                      this.openOptionsPopup(e, "Multi-Row 옵션 상세")
                    }
                  />
                </PropertyValue>
                <PropertyLable>추가 옵션</PropertyLable>
                <PropertyValue>
                  <UInputPopup
                    id="additionalOptions"
                    fieldType="json"
                    defaultValue={StringUtils.defaultString(
                      JSON.stringify(gridOptions.additionalOptions)
                    )}
                    onChange={this.onChangeGridOptions}
                    onClick={(e) => this.openOptionsPopup(e, "추가 옵션 상세")}
                  />
                </PropertyValue>
                <PropertyLable>Pivot 옵션</PropertyLable>
                <PropertyValue>
                  <UInputPopup
                    id="pivotOptions"
                    fieldType="json"
                    defaultValue={StringUtils.defaultString(
                      JSON.stringify(gridOptions.pivotOptions)
                    )}
                    onChange={this.onChangeGridOptions}
                    onClick={(e) => this.openOptionsPopup(e, "Pivot 옵션 상세")}
                  />
                </PropertyValue>
              </Accordion.Body>
            </Accordion.Item>

            {/* Style property */}
            {/* {this.renderStylePanel("Grid", 99)} */}
          </Accordion>
        </React.Fragment>
      </GridStyled>
    );
  };

  renderGridToolbar = (gridOptions, toolbarOptions) => {
    const buttons = toolbarOptions.buttons || [];
    const customButton = toolbarOptions.customButton || [];

    return (
      <div className="editor-grid-toolbar">
        {!StringUtils.isEmpty(gridOptions.title) ||
        !ObjectUtils.isEmpty(buttons) ||
        !ArrayUtils.isEmpty(customButton) ? (
          <div
            className={`editor-grid-title ${
              !StringUtils.isEmpty(gridOptions.helpText) ? "whole-wrap" : ""
            }`}
          >
            <h5
              className={`${
                !StringUtils.isEmpty(gridOptions.title) ? "tit-grid" : ""
              }`}
            >
              {gridOptions.title}
              {gridOptions.showToggle && gridOptions.showToggle !== "none" ? (
                <Fa.FaCaretDown />
              ) : (
                ""
              )}
            </h5>
          </div>
        ) : (
          ""
        )}

        <div className="editor-gird-toolbar-buttons">
          {!ArrayUtils.isEmpty(buttons)
            ? buttons.map((button, index) => {
                //['add', 'clone'] 형식
                let buttonInfo = this.GRID_TOOLBAR_DEFAULT_BUTTONS[button];
                if (ObjectUtils.isEmpty(buttonInfo)) return false;

                let IconTag =
                  Fa[StringUtils.convertKebabToPascal(buttonInfo.icon)];

                return (
                  <MButton color="primary" variant="contained" key={index}>
                    {IconTag ? <IconTag className="mr-5" /> : ""}
                    {buttonInfo.name}
                  </MButton>
                );
              })
            : Object.keys(buttons)
                .sort((a, b) => {
                  return buttons[a].sortIndex - buttons[b].sortIndex;
                })
                .map((key, index) => {
                  //{add: {}, clone: {}} 형식
                  let buttonInfo = this.GRID_TOOLBAR_DEFAULT_BUTTONS[key];
                  if (ObjectUtils.isEmpty(buttonInfo)) return false;

                  let IconTag =
                    Fa[StringUtils.convertKebabToPascal(buttonInfo.icon)];

                  return (
                    <MButton color="primary" variant="contained" key={index}>
                      {IconTag ? <IconTag className="mr-5" /> : ""}
                      {buttonInfo.name}
                    </MButton>
                  );
                })}

          {!ArrayUtils.isEmpty(customButton)
            ? customButton.map((buttonInfo, index) => {
                let IconTag =
                  Fa[StringUtils.convertKebabToPascal(buttonInfo.icon)];

                if (buttonInfo.useYn || buttonInfo.useYn === undefined) {
                  return (
                    <MButton color="primary" variant="contained" key={index}>
                      {IconTag ? <IconTag className="mr-5" /> : ""}
                      {buttonInfo.name}
                    </MButton>
                  );
                }
              })
            : ""}
        </div>
      </div>
    );
  };

  renderGridShowHeader = (gridOptions) => {
    return (
      <div className="grid-show-header">
        <div></div>
      </div>
    );
  };

  /**
   * Editor의 component를 Redering
   * <<Grid editor props>>
   *   - compId - 현재 component의 고유 ID
   *   - componentInfo - drag & drop시 생성된 component object
   *   - style - dragging style이 포함된 style (사용자가 정의한 style은 각 component에서 적절히 적용해야함)
   *   - event="renderEditor" - 요청 구분
   * @returns
   */
  renderEditor = () => {
    const gridOptions =
      this.props.componentInfo.propertyValue.gridOptions || {};
    const toolbarOptions = gridOptions.additionalOptions
      ? gridOptions.additionalOptions.toolbarOptions || {}
      : {};

    return (
      <UIComponentSection
        item={this.props.componentInfo}
        style={this.props.style}
        className={`editor-base draggable editor-grid ${StringUtils.defaultString(
          this.state.editorAttr.className,
          "builder-grid"
        )} ${this.state.propertyValue.className}`}
      >
        {!StringUtils.isEmpty(gridOptions.title) ||
        !ObjectUtils.isEmpty(toolbarOptions)
          ? this.renderGridToolbar(gridOptions, toolbarOptions)
          : ""}

        {!StringUtils.isEmpty(gridOptions.showHeader)
          ? this.renderGridShowHeader(gridOptions)
          : ""}
        {this.props.children}
        {gridOptions.info ? (
          <div className="editor-grid-footer">
            전체 : 1건
            <Fa.FaFileExcel className="grid-footer-icon" />
          </div>
        ) : (
          ""
        )}
      </UIComponentSection>
    );
  };
}

export default Grid;
