import { Enums } from "components/builder/BuilderEnum";
import BuilderHeaderComponent from "components/builder/BuilderHeaderComponent";
import UiBuilderHeader from "components/builder/ui/UiBuilderHeader";
import StringUtils from "components/common/utils/StringUtils";
import React from "react";
import { Outlet } from "react-router-dom";

const UIBuilder = ({ ...props }) => {
  const getUIPath = (url) => {
    return Enums.BuilderPath.UI.MAIN + "/" + url;
  };

  return (
    <>
      {StringUtils.includes(window.location.pathname, [
        getUIPath(Enums.BuilderPath.UI.EDITOR),
      ]) ? (
        <UiBuilderHeader {...props.header} />
      ) : (
        <BuilderHeaderComponent {...props.header} />
      )}
      <Outlet />
    </>
  );
};

export default UIBuilder;
