import DraggableComponent from "components/builder/ui/DraggableComponent";
import { PropertiesHeader } from "components/builder/ui/uiComponents/UIComponentStyle";
import { AppContext } from "components/common/AppContextProvider";
import { ComponentSavePopupButton } from "page/popup/ComponentSavePopup";
import { useContext, useEffect, useRef, useState } from "react";
import { Accordion, Button, Form, InputGroup } from "react-bootstrap";
import { BiFolder, BiFolderOpen } from "react-icons/bi";
import { MdSearch } from "react-icons/md";
import { Scrollbar } from "smooth-scrollbar-react";

/**
 *
 * 2024-08-12
 * useEffect 사용을 위해서 함수형 컴포넌트로 전환함
 * @param {*} param0
 * @returns
 */
function ServiceComponentsTab() {
  const {
    serviceComponent: { serviceComponents: componentList },
  } = useContext(AppContext);
  const [svcComponentList, setSvcComponentList] = useState([]);

  const listRef = useRef([]);

  useEffect(() => {
    setSvcComponentList(componentList);
    listRef.current = componentList;
  }, [componentList]);

  const onChangeSvcComponentSearchTerm = (e) => {
    const searchTerm = String(e.target.value).toLowerCase();
    if (searchTerm) {
      const newList = listRef.current.map((mst) => {
        const newMst = { ...mst };
        newMst.child = newMst.serviceComponentDtl.filter((dtl) => {
          return (
            dtl.componentClass.indexOf("Folder") === -1 &&
            (String(dtl.svcComponentDtlNm).toLowerCase().indexOf(searchTerm) >
              -1 ||
              String(dtl.svcComponentDtlId).toLowerCase().indexOf(searchTerm) >
                -1)
          );
        });
        return newMst;
      });
      setSvcComponentList(newList);
    } else {
      setSvcComponentList(listRef.current);
    }
  };

  return (
    <Scrollbar
      className="custom-class"
      plugins={{
        overscroll: {
          effect: "glow",
        },
      }}
    >
      <div className="service-component-panel">
        <PropertiesHeader>
          <InputGroup>
            <Form.Control
              size="sm"
              placeholder="컴포넌트 명 또는 UID 입력..."
              onChange={onChangeSvcComponentSearchTerm}
            />
            <Button size="sm">
              <MdSearch />
            </Button>
            <ComponentSavePopupButton saveDisabled variant="light">
              컴포넌트 관리자
            </ComponentSavePopupButton>
          </InputGroup>
        </PropertiesHeader>
        <Accordion
          defaultActiveKey={[0]}
          // activeKey={this.state.activeKey}
          alwaysOpen
          style={{
            height: `calc(100vh - 50px - 81px)`,
          }}
        >
          {svcComponentList.map((compMst, mstIdx) => {
            return (
              <Accordion.Item
                eventKey={compMst.svcComponentMstId}
                key={compMst.svcComponentMstId}
              >
                <Accordion.Header>{compMst.svcComponentMstNm}</Accordion.Header>
                <Accordion.Body>
                  {compMst.child.map((compDtl, dtlIdx) => {
                    return (
                      <ServiceDraggableComponent
                        key={compDtl.svcComponentDtlId}
                        data={compDtl}
                      />
                    );
                  })}
                </Accordion.Body>
              </Accordion.Item>
            );
          })}
        </Accordion>
      </div>
    </Scrollbar>
  );
}

export default ServiceComponentsTab;
const ServiceDraggableComponent = ({ data }) => {
  const svcData = {
    ...data,
    componentDtlId: data.svcComponentDtlId,
    componentMstId: data.svcComponentMstId,
    componentDtlNm: data.svcComponentDtlNm,
    defaultProperty: data.componentOuput,
    icon: null,
  };
  const style = {
    // paddingLeft: `${0.3 * (1 + Number(data.level))}rem`,
  };
  const [selected, setSelected] = useState(false);

  const onClickAccodion = (e) => {
    setSelected(!selected);
    e.stopPropagation();
  };
  if (data.child) {
    return (
      <Accordion.Item
        eventKey={`p${data.parentsId}c${data.svcComponentDtlId}`}
        onClick={onClickAccodion}
      >
        <Accordion.Header>
          {selected ? <BiFolderOpen size={20} /> : <BiFolder size={20} />}
          {data.svcComponentDtlNm}
        </Accordion.Header>
        <Accordion.Body>
          {data.child.map((compDtl, dtlIdx) => {
            return (
              <ServiceDraggableComponent
                key={compDtl.svcComponentDtlId}
                data={compDtl}
              />
            );
          })}
        </Accordion.Body>
      </Accordion.Item>
    );
  } else {
    return <DraggableComponent data={svcData} style={style} />;
  }
};
