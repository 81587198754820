import { Enums } from "components/builder/BuilderEnum";
import { EventHandlerContext } from "page/eventhandler/EventHandlerBuilderMain";
import { useContext, useEffect, useState } from "react";
import { FaPuzzlePiece, FaStar, FaUsersCog } from "react-icons/fa";
import { HiDocumentDuplicate } from "react-icons/hi";
import { MdExpandMore } from "react-icons/md";

import EventhandlerService from "services/eventhandler/EventhandlerService";
/**
 * 이벤트 핸들러 왼쪽 메뉴
 * @param {*} param0
 * @returns
 */
const EventHandlerLeftMenu = ({ ...props }) => {
  const [fncList, setFncList] = useState([]);
  const { setSelectedFncDtl } = useContext(EventHandlerContext);

  useEffect(() => {
    getCategoryList();
    return () => {
      setFncList([]);
    };
  }, []);

  const getCategoryList = () => {
    EventhandlerService.getFncMstList({}, (res) => {
      setFncList(res.data);
    });
  };

  return (
    <div className="eventhandler-left-menu-wrapper">
      <div className="title category">
        <span>
          <FaPuzzlePiece />
        </span>
        <span>Event Components</span>
      </div>
      <div
        className="category"
        onClick={(e) => setSelectedFncDtl({ type: "favorite" })}
      >
        <span>
          <FaStar />
        </span>
        <span>자주 쓰는 기능</span>
      </div>
      <div
        className="category"
        onClick={(e) => setSelectedFncDtl({ type: "custom" })}
      >
        <span>
          <FaUsersCog />
        </span>
        <span>사용자 정의 함수</span>
      </div>
      {fncList.map((fnc, index) => {
        const Icon =
          Enums.EventCategoryIconEnums[fnc.fncMstId] || HiDocumentDuplicate;
        return <FncAccordion Icon={Icon} fnc={fnc} key={fnc.fncMstId} />;
      })}
    </div>
  );
};

export default EventHandlerLeftMenu;

const FncAccordion = ({ Icon, fnc, ...props }) => {
  const [expand, setExpand] = useState(true);
  const { selectedFncDtl, setSelectedFncDtl } = useContext(EventHandlerContext);

  return (
    <>
      {fnc.eventFncDtlList && fnc.eventFncDtlList.length > 0 ? (
        <>
          <div className={`category `} onClick={(e) => setExpand(!expand)}>
            <span>
              <Icon />
            </span>
            <span>{fnc.fncMstName}</span>
            <span className={`expand-icon ${expand ? "expand" : "fold"}`}>
              <MdExpandMore />
            </span>
          </div>
          <div
            className={`child-wrapper ${expand ? "expand" : "fold"}`}
            style={{
              height: expand
                ? `calc( 30px * ${fnc.eventFncDtlList.length})`
                : 0,
            }}
          >
            {fnc.eventFncDtlList.map((dtl, index) => {
              return (
                <div
                  className={`child ${
                    selectedFncDtl.fncDtlId === dtl.fncDtlId ? "selected" : ""
                  }`}
                  key={dtl.fncDtlId}
                  onClick={(e) => setSelectedFncDtl({ ...dtl, type: "func" })}
                >
                  <span>{dtl.fncDtlName}</span>
                </div>
              );
            })}
          </div>
        </>
      ) : (
        <div className="category" onClick={(e) => setSelectedFncDtl(fnc)}>
          <span>
            <Icon />
          </span>
          <span>{fnc.fncMstName}</span>
        </div>
      )}
    </>
  );
};
