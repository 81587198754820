import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { Enums } from "components/builder/BuilderEnum";
import { AppContext } from "components/common/AppContextProvider";
import Message from "components/common/Message";
import StringUtils from "components/common/utils/StringUtils";
import User from "components/common/utils/UserUtils";
import PageTemplate from "page/common/PageTemplate";
import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { FaEdit, FaList } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import AppService from "services/common/AppService";
import CodeService from "services/common/CodeService";
import UserService from "services/common/UserService";
import { MultiCheckSelectBox } from "./UserDetail";

function MyDetail() {
  const param = useParams();
  const navigate = useNavigate();
  const applicationList = useContext(AppContext).application.list;

  const [userId, setUserId] = useState("");
  const [userNm, setUserNm] = useState("");
  const [userEngNm, setUserEngNm] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [pwd, setPwd] = useState("");
  const [pwdConfirm, setPwdConfirm] = useState("");
  const [defaultLangCd, setDefaultLangCd] = useState("KO");
  const [useYn, setUseYn] = useState("");
  const [user, setUser] = useState({});

  const [isUserDev, setIsUserDev] = useState(true);
  const [isMine, setIsMine] = useState(true);

  useEffect(() => {
    UserService.getUser(
      {
        userMastRecId: User.getMastRecId(),
      },
      (res) => {
        if (res.data) {
          setUser(res.data);
          setUserId(res.data.userId);
          setUserNm(res.data.userNm);
          setUserEngNm(res.data.userEngNm);
          setUserEmail(res.data.userEmail);
          setPwd(res.data.pwd);
          setDefaultLangCd(res.data.defaultLangCd);
          setUseYn(res.data.useYn);
        } else {
          Message.confirm(
            "사용자 정보를 찾을 수 없습니다. 이전 페이지로 돌아갑니다.",
            () => {
              navigate(-1);
            }
          );
        }
      }
    );
    return () => {};
  }, []);

  const onMoveToList = () => {
    if (isUserDev) {
      navigate(Enums.BuilderPath.USER.MAIN);
    } else {
      navigate(`${Enums.BuilderPath.USER.MAIN}/list/${param.appId}`);
    }
  };

  /**
   * 저장
   * @returns
   */
  const updateUser = () => {
    if (StringUtils.isEmpty(userId))
      return Message.alert("아이디를 입력해주세요", Enums.MessageType.WARN);
    if (StringUtils.isEmpty(userNm))
      return Message.alert("국문명 입력해주세요", Enums.MessageType.WARN);
    if (StringUtils.isEmpty(userEmail))
      return Message.alert("이메일을 입력해주세요", Enums.MessageType.WARN);

    //비밀번호 수정한 경우에만 검증 단계
    if (!StringUtils.isEmpty(pwd)) {
      if (pwd !== pwdConfirm)
        return Message.alert(
          "비밀번호가 맞지 않습니다.",
          Enums.MessageType.WARN
        );
      if (StringUtils.isEmpty(pwdConfirm))
        return Message.alert(
          "비밀번호 확인을 입력해주세요",
          Enums.MessageType.WARN
        );
    }

    if (StringUtils.isEmpty(useYn))
      return Message.alert("사용여부를 선택해주세요", Enums.MessageType.WARN);

    const body = {
      ...user,
      userNm,
      userEngNm,
      userEmail,
      defaultLangCd,
      pwd,
      useYn,
    };

    Message.confirm("정보를 수정 하시겠습니까?", () => {
      UserService.update(body, (res) => {
        const { isSuccess, message } = res.data;
        if (isSuccess === "Y") {
          Message.alert("사용자가 수정되었습니다.", Enums.MessageType.SUCCESS);
        } else {
          Message.alert(message, Enums.MessageType.ERROR);
        }
      });
    });
  };

  let breadcrum = [
    {
      name: "사용자 상세",
      subName: userNm + "-" + userId,
      active: true,
    },
  ];

  return (
    <PageTemplate breadcrum={breadcrum}>
      <PageTemplate.Box boxClass="mb-0">
        <div className="box-control box-line button-group">
          <Button variant="outline-dark" size="sm" onClick={updateUser}>
            <FaEdit /> 수정
          </Button>{" "}
          <Button variant="outline-dark" size="sm" onClick={onMoveToList}>
            <FaList /> 목록
          </Button>
        </div>
        <Form>
          <Row className="mt-4">
            <Col sm="6" style={{ paddingRight: "20px" }}>
              <Form.Group className="mb-3">
                <Form.Label className="required">사용자 ID</Form.Label>
                <Form.Control value={userId} readOnly={true} disabled={true} />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="required">사용자 국문명</Form.Label>
                <Form.Control
                  value={userNm}
                  onChange={(e) => setUserNm(e.currentTarget.value)}
                />
                <Form.Text></Form.Text>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="required">사용자 영문명</Form.Label>
                <Form.Control
                  value={userEngNm}
                  onChange={(e) => setUserEngNm(e.currentTarget.value)}
                />
                <Form.Text></Form.Text>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="required">사용자 이메일</Form.Label>
                <Form.Control
                  value={userEmail}
                  onChange={(e) => setUserEmail(e.currentTarget.value)}
                />
                <Form.Text></Form.Text>
              </Form.Group>
              {isMine ? (
                <>
                  <Form.Group className="mb-3">
                    <Form.Label className="required">비밀번호</Form.Label>
                    <Form.Control
                      value={pwd}
                      type="password"
                      onChange={(e) => setPwd(e.currentTarget.value)}
                    />
                    <Form.Text></Form.Text>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="required">비밀번호 확인</Form.Label>
                    <Form.Control
                      value={pwdConfirm}
                      type="password"
                      onChange={(e) => setPwdConfirm(e.currentTarget.value)}
                    />
                    <Form.Text></Form.Text>
                  </Form.Group>
                </>
              ) : null}
            </Col>

            <Col sm="6" style={{ paddingLeft: "20px" }}>
              <Form.Group className="mb-3">
                <Form.Label>사용 언어</Form.Label>
                <Form.Select
                  value={defaultLangCd}
                  onChange={(e) => setDefaultLangCd(e.target.value)}
                >
                  <option value={"KO"}>한국어</option>
                  <option value={"EN"}>영어</option>
                  <option value={"ZH"}>중국어</option>
                  <option value={"JA"}>일본어</option>
                  <option value={"VN"}>베트남어</option>
                </Form.Select>
                <Form.Text></Form.Text>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>사용 여부</Form.Label>
                <Form.Select
                  value={useYn}
                  onChange={(e) => setUseYn(e.target.value)}
                >
                  <option value={""}>선택</option>
                  <option value={"Y"}>예</option>
                  <option value={"N"}>아니오</option>
                </Form.Select>
                <Form.Text></Form.Text>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </PageTemplate.Box>
    </PageTemplate>
  );
}

export default MyDetail;
