import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { BsThreeDots } from "react-icons/bs";
import { StringUtils } from "components/common/utils/CommonUtils";
import { useRef } from "react";

export const InputPopupStyle = styled.div`
  .input-container {
    position: relative;
    display: table;
    border-collapse: separate;
    width: 100%;
    height: 100%;
  }
  .input-container input {
    display: table-cell;
    box-sizing: border-box;
    padding-right: 20px;
    border-radius: 0.2rem 0 0 0.2rem;
    border-right: none;
  }
  .input-container button {
    background-color: #e3e3e3;
    padding: 0px 3px;
    height: 100%;
    border: 1px solid #cdcdcd;
    border-radius: 0 0.2rem 0.2rem 0;
    border-left: none;
  }
  .input-container .btn-span {
    min-width: auto;
    display: table-cell;
    width: 1%;
    white-space: nowrap;
    vertical-align: top;
  }
`;

/**
 *  history: 22.03.16 init
 */
const UInputPopup = (props) => {
  const {
    id,
    name,
    defaultValue,
    onBlur,
    onClick,
    className,
    style,
    textReadonly,
    readonlyTp,
    onChange,
    size,
    placeholder,
  } = props;

  const inputRef = useRef();
  useEffect(() => {
    inputRef.current.value = defaultValue;
  }, [defaultValue]);

  const onBlurInput = (e) => {
    if (!textReadonly && onBlur) {
      onBlur(e);
    }
  };

  const onChangeInput = (e) => {
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <InputPopupStyle className={className} style={style}>
      <div className="input-container">
        <input
          ref={inputRef}
          type="text"
          id={id}
          name={StringUtils.defaultString(name, id)}
          defaultValue={defaultValue}
          className={`form-control form-control-${size || "sm"}`}
          onBlur={onBlurInput}
          onChange={onChangeInput}
          readOnly={textReadonly}
          placeholder={placeholder}
          disabled={StringUtils.defaultString(
            readonlyTp === "all" || readonlyTp === "input",
            false
          )}
        />
        <span className="btn-span">
          <button
            className="btn btn-sm"
            onClick={(e) => onClick.call(this, e)}
            formTarget={id}
            disabled={StringUtils.defaultString(
              readonlyTp === "all" || readonlyTp === "button",
              false
            )}
          >
            <BsThreeDots />
          </button>
        </span>
      </div>
    </InputPopupStyle>
  );
};

export default UInputPopup;
