import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { BsThreeDots } from "react-icons/bs";

import Popup from "components/common/Popup";
import ExtendPopup from "page/popup/ExtendPopup";
import ExtendTextEditorPopup from "page/popup/ExtendTextEditorPopup";
import StringUtils from "components/common/utils/StringUtils";
import ObjectUtils from "../utils/ObjectUtils";
import Message from "../Message";

const TextareaStyle = styled.div`
  width: 100%;
  .textarea-container {
    position: relative;
    width: 100%;
  }
  .textarea-container textarea {
    resize: none;
    box-sizing: border-box;
    // overflow: hidden;
  }
  .textarea-container button {
    position: absolute;
    bottom: 0px;
    right: 0px;
    background-color: #e3e3e3;
    padding: 0px 3px;
    border: 1px solid #cdcdcd;
    min-height: calc(1.5em + 0.5rem + 2px);
  }
`;

/**
 *  history: 22.03.08 init
 *
 *
 *
 *
 */
function UTextarea(props) {
  const {
    popTitle,
    textareaId,
    defaultValue,
    fieldType,
    callbackFnc,
    onBlur,
    onChange,
    isReadonly,
    placeholder,
    codeBox = true,
    onClickEventBuilder,
    eventWorkspace, //이벤트 워크스페이스
    onDeleteEventWorkspace, //이벤트 워크스페이스 삭제로직
    isEventBuilder = true, //Event빌더 네비게이터 노출 여부
  } = props;
  const [textValue, setTextValue] = useState("");

  const initTextarea = () => {
    setTextValue(defaultValue);
  };

  const textChange = (e) => {
    setTextValue(e.target.value);
    if (onChange) {
      onChange(e);
    }
  };

  const openExtendPopup = (e) => {
    const options = {
      effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
      style: {
        content: {
          minHeight: "200px",
        },
      },
    };

    let PopupEditor;

    if (fieldType === "html") {
      PopupEditor = ExtendTextEditorPopup;
    } else {
      PopupEditor = ExtendPopup;
    }
    Popup.open(
      <PopupEditor
        title={popTitle}
        defaultValue={textValue}
        setValue={setTextValue}
        fieldType={fieldType}
        jsonValidation={props.jsonValidation}
        callbackFnc={extendPopupCallback}
        placeholder={placeholder}
        onClickEventBuilder={onClickEventBuilder}
        eventWorkspace={eventWorkspace}
        onDeleteEventWorkspace={onDeleteEventWorkspace}
        isEventBuilder={isEventBuilder}
      />,
      options
    );
  };

  const extendPopupCallback = (popVal) => {
    if (onBlur != null) {
      onBlur.call(this, { target: { id: textareaId, value: popVal } });
    }
    if (callbackFnc != null) {
      callbackFnc.call(this, popVal);
    }
  };

  useEffect(() => {
    initTextarea();
  }, []);

  useEffect(() => {
    setTextValue(props.defaultValue);
  }, [props.defaultValue]);

  return (
    <TextareaStyle>
      <div className="textarea-container">
        <textarea
          className="form-control form-control-sm"
          cols={StringUtils.defaultString(props.cols, 10)}
          rows={StringUtils.defaultString(props.rows, 3)}
          id={textareaId}
          onChange={textChange}
          onBlur={onBlur}
          readOnly={StringUtils.defaultString(isReadonly, false)}
          placeholder={placeholder}
          value={textValue}
        />
        {codeBox ? (
          <button
            id={"btn-" + textareaId}
            className="btn btn-sm"
            onClick={openExtendPopup}
            disabled={StringUtils.defaultString(isReadonly, false)}
          >
            <BsThreeDots />
          </button>
        ) : null}
      </div>
    </TextareaStyle>
  );
}

export default UTextarea;
