/*!
 * Entity Builder EventHandler for react v.17
 *
 * Entity Builder에서 발생되는 Event 및 Event 관련 로직
 *
 *   Author: Bizentro
 *   Date: 2021-04
 */
import * as Enums from "components/builder/BuilderEnum";
import { StringUtils } from "components/common/utils/CommonUtils";
import EntityReduxHelper from "components/builder/entity/editor/helper/EntityReduxHelper";

export const handlePageClick = (e, dispatch, activedComponent, output) => {
  if (activedComponent.type === Enums.EntityComponentType.DATA_MODEL) {
    stopEvent(e);
    return;
  }
  const { dataModelEntities, ...DataModel } = output;
  EntityReduxHelper.activateComponent(dispatch, DataModel);
  stopEvent(e);
};

export const HandleEntityClick = (
  e,
  dispatch,
  activedComponent,
  dataModelEntity
) => {
  if (
    activedComponent.type === Enums.EntityComponentType.ENTITY &&
    activedComponent.compId === dataModelEntity.compId
  ) {
    stopEvent(e);
    return;
  }
  EntityReduxHelper.activateComponent(dispatch, dataModelEntity);
  stopEvent(e);
};

/**
 * Event 중단
 * @param {Event} e
 */
export const stopEvent = (e) => {
  if (e) {
    e.preventDefault(); //event 수행을 중단함.
    e.stopPropagation(); //상위 엘리먼트에게 event가 전달되지 않토록함
  }
};

/**
 * Drag & drap handle
 * @param {*} item
 * @param {*} output
 * @param {*} monitor
 * @param {*} dispatch
 * @returns
 */
export const handleDrop = (item, output, position, dispatch, relatedTables) => {
  /***************************************************
   * 1.drag & drop sidebar - Table
   * *************************************************/
  if (item.type === Enums.EntityComponentType.TABLE) {
    console.log(
      "EntityEditorEnvetHeler, handleDrop.TABLE get component from [Drag Object]"
    );
    // const position = monitor.getClientOffset();
    // console.log(monitor.getClientOffset());
    // console.log(monitor.getSourceClientOffset());

    const setProperties = (_table, order) => {
      let newItem;
      // order === 0 의 메인 컴포넌트는 드래그 item의 component에 담겨져온다.
      if (order === 0) {
        newItem = { ..._table.component };
      } else {
        newItem = { ..._table };
      }

      newItem.position = {
        x: position.x * (order + 1),
        y: position.y * (order + 1),
      };
      newItem.type = Enums.EntityComponentType.ENTITY;
      newItem.compId = StringUtils.getUuid();

      const generatedDataModelEntityFields = newItem.dataModelEntityFields.map(
        (_field) => {
          const obj = { ..._field };
          obj.compId = StringUtils.getUuid();
          obj.type = Enums.EntityComponentType.ENTITY_FIELD;
          return obj;
        }
      );
      newItem.dataModelEntityFields = generatedDataModelEntityFields;
      return newItem;
    };
    const firstItem = setProperties(item, 0);
    EntityReduxHelper.activateComponent(dispatch, firstItem);

    const Entities = [];
    Entities.push(firstItem);

    //연계된 테이블 전부 렌더링
    if (relatedTables.length > 0) {
      relatedTables.map((related, idx) => {
        const relEntity = setProperties(related, idx + 1);
        Entities.push(relEntity);
      });
    }

    EntityReduxHelper.addNewEntities(dispatch, output, Entities);

    /***************************************************
     * 2.drag & drop inside Editor
     * *************************************************/
  } else if (item.type === Enums.EntityComponentType.ENTITY) {
    // const position = monitor.getClientOffset();
    //output에서 해당 item의 compId와 일치하는 node를 찾은후 position을 update한후 redux를 update한다.
    console.log(
      "EntityEditorEnvetHeler, handleDrop.TABLE get component from [Drag LAYER]"
    );

    const newItem = { ...item };
    newItem.position = position; //포지션만 업데이트
    EntityReduxHelper.activateComponent(dispatch, newItem);
    EntityReduxHelper.updateEntity(dispatch, output, newItem);
  }
};
