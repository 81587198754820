import Blockly from "blockly";

/**
 * Blockly icon class link : https://github.com/google/blockly/blob/develop/core/icons/icon.ts
 * /
/**
 * Blockly custom icon link: https://developers.google.com/blockly/guides/create-custom-blocks/icons/creating-custom-icons/basic-implementation
 */
/**
 * 그리드 category에 있는 Block에 사용할 icon 클래스 정의
 */
class BlockGridIcon extends Blockly.icons.Icon {
  constructor(sourceBlock) {
    super(sourceBlock);
  }
  getType() {
    return new Blockly.icons.IconType("grid_icon");
  }
  initView(pointerdownListener) {
    if (this.svgRoot) return; // Already initialized.

    // This adds the pointerdownListener to the svgRoot element.
    // If you do not call `super` you must do this yourself.
    super.initView(pointerdownListener);

    const svg = Blockly.utils.dom.createSvgElement(
      Blockly.utils.Svg.SVG,
      {
        width: "20px",
        height: "20px",
        fill: "white",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 48 48",
        "fill-opacity": "0.5",
      },
      this.svgRoot // Append to the svgRoot.
    );
    Blockly.utils.dom.createSvgElement(
      Blockly.utils.Svg.RECT,
      {
        width: "48",
        height: "48",
        fill: "none",
        "fill-opacity": "0.1",
      },
      svg
    );
    Blockly.utils.dom.createSvgElement(
      Blockly.utils.Svg.PATH,
      {
        fill: "white",
        "fill-opacity": "0.01",
        d: "M48 0H0V48H48V0Z",
      },
      svg
    );
    Blockly.utils.dom.createSvgElement(
      Blockly.utils.Svg.PATH,
      {
        "stroke-width": "4",
        d: "M41 4H7C5.34315 4 4 5.34315 4 7V41C4 42.6569 5.34315 44 7 44H41C42.6569 44 44 42.6569 44 41V7C44 5.34315 42.6569 4 41 4Z",
        stroke: "#1C274C",
      },
      svg
    );
    Blockly.utils.dom.createSvgElement(
      Blockly.utils.Svg.PATH,
      {
        d: "M15 4L15 44",
        stroke: "#1C274C",
        "stroke-width": "4",
        "stroke-linecap": "round",
      },
      svg
    );
    Blockly.utils.dom.createSvgElement(
      Blockly.utils.Svg.PATH,
      {
        d: "M4 17.0378L44 17",
        stroke: "#1C274C",
        "stroke-width": "4",
        "stroke-linecap": "round",
      },
      svg
    );
    Blockly.utils.dom.createSvgElement(
      Blockly.utils.Svg.PATH,
      {
        d: "M4 30.5187L44 30.481",
        stroke: "#1C274C",
        "stroke-width": "4",
        "stroke-linecap": "round",
      },
      svg
    );
  }
  getSize() {
    return new Blockly.utils.Size(20, 20);
  }
  updateCollapsed() {
    if (!this.svgRoot) return;
    if (this.sourceBlock.isCollapsed()) {
      this.svgRoot.style.display = "block";
      return true;
    }
    if (Blockly.hasBubble(this)) {
      this.setBubbleVisible(false);
    }
  }
  dispose() {
    // Always call super!
    super.dispose();

    this.myBubble?.dispose();
    this.myOtherReference?.dispose();
  }
  saveState() {
    return this.state; // Some arbirtary JSON-compatible data.
  }
  loadState(state) {
    this.state = state;
  }
  onLocationChange(blockOrigin) {
    this.workspaceLocation = Blockly.utils.Coordinate.sum(
      blockOrigin,
      this.offsetInBlock
    );
  }
  isShownWhenCollapsed() {
    return true;
  }
}

Blockly.icons.registry.register(
  new Blockly.icons.IconType("grid_icon"),
  BlockGridIcon
);
