import { Enums } from "components/builder/BuilderEnum";
import Message from "components/common/Message";
import Modal from "components/common/modal/UModal";
import UmodalTemplate from "components/common/modal/UModalTemplate";
import StringUtils from "components/common/utils/StringUtils";
import React from "react";
import { useState } from "react";
import { Form } from "react-bootstrap";

function EntityAddPopup({ onOk, entity, output, ...props }) {
  const [physEntityNm, setPhysEntityNm] = useState(entity.physEntityNm);

  const onConfirm = (e) => {
    if (StringUtils.isEmpty(physEntityNm)) {
      return Message.alert("물리명을 입력해주세요", Enums.MessageType.WARN);
    }

    if (
      output.dataModelEntities.find((dme) =>
        StringUtils.equalsIgnoreType(dme.physEntityNm, physEntityNm)
      )
    ) {
      return Message.alert(
        "동일한 물리명을 가진 엔티티가 존재합니다.",
        Enums.MessageType.WARN
      );
    }
    e.preventDefault();
    e.stopPropagation();
    if (onOk) {
      onOk(physEntityNm);
    }
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      onConfirm();
    } else {
      return true;
    }
  };

  return (
    <Modal>
      <Modal.Header title={"신규 테이블 물리명 입력"} />
      <Modal.Body>
        <UmodalTemplate>
          <Form onSubmit={onConfirm}>
            <Form.Group className="mt-3 ">
              <Form.Label className="required">신규 엔티티 물리 명</Form.Label>
              <Form.Control
                value={physEntityNm}
                onChange={(e) => setPhysEntityNm(e.currentTarget.value)}
                onKeyDown={onKeyDown}
              />
            </Form.Group>
          </Form>
        </UmodalTemplate>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Button onClick={onConfirm}>저장</Modal.Footer.Button>
      </Modal.Footer>
    </Modal>
  );
}

export default EntityAddPopup;
