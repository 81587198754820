import { Checkbox, FormControlLabel } from "@mui/material";
import { Enums } from "components/builder/BuilderEnum";
import { stopEvent } from "components/builder/ui/editor/handler/UIEditorEventHandler";
import Popup from "components/common/Popup";
import Modal from "components/common/modal/UModal";
import User from "components/common/utils/UserUtils";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import LocalStorageService from "services/common/LocalService";

function AutoSaveQuestPopup({ callback, cancelCallback, ...props }) {
  const [autoSave, setAutoSave] = useState("N");

  const onClickAutoSave = (e) => {
    e.stopPropagation();
    setAutoSave(e.target.checked ? "Y" : "N");
  };

  const onClickConfirm = (e) => {
    stopEvent(e);
    if (autoSave === "Y") {
      const userId = User.getId();
      const body = {
        userId,
        autoSave,
      };
      LocalStorageService.set(Enums.LocalStorageName.EVENT_AUTO_SAVE, body);
    }
    if (callback) {
      callback();
    }
  };

  const onClickClose = (e) => {
    if (cancelCallback) {
      cancelCallback();
    }
    Popup.close();
  };

  return (
    <Modal>
      <Modal.Header />
      <Modal.Body>
        <p>변경된 내용이 있습니다.</p>
        <p>적용하시겠습니까?</p>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={autoSave === "Y" ? true : false}
                onClick={onClickAutoSave}
              />
            }
            label="다시 묻지 않고 자동 적용"
          />
        </div>
      </Modal.Body>
      <Modal.Footer invisibleClose>
        <button
          type="button"
          className="btn btn-default"
          onClick={onClickClose}
          style={{ float: "right" }}
        >
          아니오
        </button>
        <Modal.Footer.Button onClick={onClickConfirm}>예</Modal.Footer.Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AutoSaveQuestPopup;
