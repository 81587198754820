import Blockly from "blockly";

/**
 * Blockly icon class link : https://github.com/google/blockly/blob/develop/core/icons/icon.ts
 * /
/**
 * Blockly custom icon link: https://developers.google.com/blockly/guides/create-custom-blocks/icons/creating-custom-icons/basic-implementation
 */

/**
 * System category에 있는 Block에 사용할 icon 클래스 정의
 */
class BlockSystemIcon extends Blockly.icons.Icon {
  constructor(sourceBlock) {
    super(sourceBlock);
  }
  getType() {
    return new Blockly.icons.IconType("system_icon");
  }
  initView(pointerdownListener) {
    if (this.svgRoot) return; // Already initialized.

    // This adds the pointerdownListener to the svgRoot element.
    // If you do not call `super` you must do this yourself.
    super.initView(pointerdownListener);

    const svg = Blockly.utils.dom.createSvgElement(
      Blockly.utils.Svg.SVG,
      {
        width: "20px",
        height: "20px",
        viewBox: "0 0 48 48",
      },
      this.svgRoot // Append to the svgRoot.
    );

    let g = Blockly.utils.dom.createSvgElement(Blockly.utils.Svg.G, {}, svg);
    Blockly.utils.dom.createSvgElement(
      Blockly.utils.Svg.RECT,
      {
        width: "48",
        height: "48",
        fill: "none",
      },
      g
    );
    g = Blockly.utils.dom.createSvgElement(Blockly.utils.Svg.G, {}, svg);
    Blockly.utils.dom.createSvgElement(
      Blockly.utils.Svg.CIRCLE,
      {
        cx: "38",
        cy: "24",
        r: "2",
      },
      g
    );
    Blockly.utils.dom.createSvgElement(
      Blockly.utils.Svg.CIRCLE,
      {
        cx: "32",
        cy: "24",
        r: "2",
      },
      g
    );
    Blockly.utils.dom.createSvgElement(
      Blockly.utils.Svg.CIRCLE,
      {
        cx: "38",
        cy: "36",
        r: "2",
      },
      g
    );
    Blockly.utils.dom.createSvgElement(
      Blockly.utils.Svg.CIRCLE,
      {
        cx: "32",
        cy: "36",
        r: "2",
      },
      g
    );
    Blockly.utils.dom.createSvgElement(
      Blockly.utils.Svg.PATH,
      {
        d: "M44,4H4A2,2,0,0,0,2,6V42a2,2,0,0,0,2,2H44a2,2,0,0,0,2-2V6A2,2,0,0,0,44,4ZM6,20H42v8H6ZM6,8H42v8H6ZM42,40H6V32H42Z",
      },
      g
    );
    Blockly.utils.dom.createSvgElement(
      Blockly.utils.Svg.CIRCLE,
      {
        cx: "38",
        cy: "12",
        r: "2",
      },
      g
    );
    Blockly.utils.dom.createSvgElement(
      Blockly.utils.Svg.CIRCLE,
      {
        cx: "32",
        cy: "12",
        r: "2",
      },
      g
    );
  }
  getSize() {
    return new Blockly.utils.Size(20, 20);
  }
  updateCollapsed() {
    if (!this.svgRoot) return;
    if (this.sourceBlock.isCollapsed()) {
      this.svgRoot.style.display = "block";
      return true;
    }
    if (Blockly.hasBubble(this)) {
      this.setBubbleVisible(false);
    }
  }
  dispose() {
    // Always call super!
    super.dispose();

    this.myBubble?.dispose();
    this.myOtherReference?.dispose();
  }
  saveState() {
    return this.state; // Some arbirtary JSON-compatible data.
  }
  loadState(state) {
    this.state = state;
  }
  onLocationChange(blockOrigin) {
    this.workspaceLocation = Blockly.utils.Coordinate.sum(
      blockOrigin,
      this.offsetInBlock
    );
  }
  isShownWhenCollapsed() {
    return true;
  }
}

Blockly.icons.registry.register(
  new Blockly.icons.IconType("system_icon"),
  BlockSystemIcon
);
