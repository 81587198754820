import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { Enums } from "components/builder/BuilderEnum";
import Message from "components/common/Message";
import Popup from "components/common/Popup";
import Modal from "components/common/modal/UModal";
import UModalTemplate from "components/common/modal/UModalTemplate";
import StringUtils from "components/common/utils/StringUtils";
import produce from "immer";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Form } from "react-bootstrap";

function ColumnOptionPopup(props) {
  const [columnOption, setColumnOption] = useState({
    physicalName: "",
    logicalName: "",
    dataType: "",
    pkYn: false,
    notNull: false,
    default: "",
    autoIncrement: false,
    extra: "",
  });

  useEffect(() => {
    if (props.column) {
      setColumnOption(
        produce(columnOption, (draft) => {
          for (const key in props.column) {
            draft[key] = props.column[key];
          }
        })
      );
    }
  }, []);

  const onChangePropValue = (e) => {
    setColumnOption(
      produce(columnOption, (draft) => {
        if (StringUtils.equalsIgnoreCase(e.target.type, "checkbox")) {
          draft[e.target.id] = e.target.checked;
        } else {
          draft[e.target.id] = e.target.value;
        }
      })
    );
  };

  const onClickConfirm = () => {
    const data = produce(columnOption, (draft) => {
      for (const key in columnOption) {
        if (typeof columnOption[key] === "string") {
          draft[key] = columnOption[key].trim();
        }
      }
    });
    if (StringUtils.isEmpty(data.physicalName))
      return Message.alert(
        "컬럼 물리명을 입력 해주세요.",
        Enums.MessageType.WARN
      );
    if (StringUtils.isEmpty(data.dataType))
      return Message.alert(
        "데이터 타입을 입력 해주세요.",
        Enums.MessageType.WARN
      );

    if (props.callback) {
      props.callback(data);
      Popup.close();
    }
  };

  return (
    <Modal>
      <Modal.Header title={"컬럼 상세 설정"} />
      <Modal.Body>
        <UModalTemplate>
          <Form.Group className="mb-3">
            <Form.Label className="required">컬럼 명 (물리)</Form.Label>
            <Form.Control
              id={"physicalName"}
              value={columnOption.physicalName}
              onChange={onChangePropValue}
              placeholder="컬럼 명 입력"
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>컬럼 명 (논리)</Form.Label>
            <Form.Control
              id={"logicalName"}
              value={columnOption.logicalName}
              onChange={onChangePropValue}
              placeholder="컬럼 명 입력"
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="required">데이터 타입</Form.Label>
            <Form.Control
              id={"dataType"}
              value={columnOption.dataType}
              onChange={onChangePropValue}
              placeholder="데이터 타입 입력"
            />
          </Form.Group>
          <Form.Group
            className="mb-3"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Form.Label>PK 여부</Form.Label>
            <BootstrapSwitchButton
              size="sm"
              onstyle="primary"
              offstyle="dark"
              onlabel="사용"
              offlabel="사용하지 않음"
              width={100}
              checked={columnOption.pkYn}
              id={"pkYn"}
              onChange={(flag) =>
                setColumnOption(
                  produce(columnOption, (draft) => {
                    draft.pkYn = flag;
                  })
                )
              }
            />
          </Form.Group>
          <Form.Group
            className="mb-3"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Form.Label>Not Null</Form.Label>
            <BootstrapSwitchButton
              size="sm"
              onstyle="primary"
              offstyle="dark"
              onlabel="사용"
              offlabel="사용하지 않음"
              width={100}
              checked={columnOption.notNull}
              id={"notNull"}
              onChange={(flag) =>
                setColumnOption(
                  produce(columnOption, (draft) => {
                    draft.notNull = flag;
                  })
                )
              }
            />
          </Form.Group>
          <Form.Group
            className="mb-3"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Form.Label>자동 증가</Form.Label>
            <BootstrapSwitchButton
              size="sm"
              onstyle="primary"
              offstyle="dark"
              onlabel="사용"
              offlabel="사용하지 않음"
              width={100}
              checked={columnOption.autoIncrement}
              id={"autoIncrement"}
              onChange={(flag) =>
                setColumnOption(
                  produce(columnOption, (draft) => {
                    draft.autoIncrement = flag;
                  })
                )
              }
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>기본값</Form.Label>
            <Form.Control
              id={"default"}
              value={columnOption.default}
              onChange={onChangePropValue}
              placeholder="기본값 입력"
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Extra</Form.Label>
            <Form.Control
              id={"extra"}
              value={columnOption.extra}
              onChange={onChangePropValue}
            />
          </Form.Group>
        </UModalTemplate>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Button onClick={onClickConfirm}>확인</Modal.Footer.Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ColumnOptionPopup;
