import { Checkbox, FormControlLabel } from "@mui/material";
import { Enums } from "components/builder/BuilderEnum";
import { stopEvent } from "components/builder/ui/editor/handler/UIEditorEventHandler";
import Popup from "components/common/Popup";
import Modal from "components/common/modal/UModal";
import User from "components/common/utils/UserUtils";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import LocalStorageService from "services/common/LocalService";

function SaveProcedureWarnPopup({ callback, ...props }) {
  //   const [autoSave, setAutoSave] = useState("N");
  const [agree, setAgree] = useState("N");

  const onClickAutoSave = (e) => {
    e.stopPropagation();
    setAgree(e.target.checked ? "Y" : "N");
  };

  const onClickConfirm = (e) => {
    stopEvent(e);
    if (agree === "Y") {
      const userId = User.getId();
      const body = {
        userId,
        agree,
      };
      LocalStorageService.set(Enums.LocalStorageName.PROCEDURE_WARN, body);
    }
    if (callback) {
      callback();
    }
  };

  return (
    <Modal>
      <Modal.Header />
      <Modal.Body>
        <p>
          <span style={{ color: "tomato", fontWeight: "bold" }}>
            7월 말 이후
          </span>
        </p>
        <p>
          사용자 트랜잭선에서{" "}
          <span style={{ color: "tomato" }}>
            프로시저 기능을 지원하지 않습니다.
          </span>
        </p>
        <p>
          <span className="blink">워크플로우 기능을 활용해주세요.</span>{" "}
        </p>
        <p>진행하시겠습니까?</p>
      </Modal.Body>
      <Modal.Footer closeText={"취소"}>
        <Modal.Footer.Button onClick={onClickConfirm}>
          확인 및 진행
        </Modal.Footer.Button>
      </Modal.Footer>
    </Modal>
  );
}

export default SaveProcedureWarnPopup;
