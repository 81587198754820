import ActionType from "components/builder/BuilderActionType";

/**
 * 워크플로우 초기화
 * @returns
 */
export const initWorkflow = () => {
  return {
    type: ActionType.WORKFLOW.INIT,
  };
};

/**
 * 신규서비스 생성
 * @returns
 */
export const createNewService = (serviceInfo) => {
  return {
    type: ActionType.WORKFLOW.CREATE,
    payload: serviceInfo,
  };
};

/**
 * 프로세스 추가
 * @param {*} processInfo
 * @returns
 */
export const addProcess = (processInfo) => {
  return {
    type: ActionType.WORKFLOW.PROCESS.ADD,
    payload: processInfo,
  };
};

/**
 * 프로세스 수정
 * @param {*} processInfo
 * @returns
 */
export const updateProcess = (processInfo) => {
  return {
    type: ActionType.WORKFLOW.PROCESS.UPDATE,
    payload: processInfo,
  };
};

/**
 * 프로세스 삭제
 * @param {*} processId
 * @returns
 */
export const deleteProcess = (processId) => {
  return {
    type: ActionType.WORKFLOW.PROCESS.DELETE,
    payload: processId,
  };
};
/**
 * 커넥터 추가
 * @param {*} processId
 * @returns
 */
export const addConnector = (addConnector) => {
  return {
    type: ActionType.WORKFLOW.CONNECTOR.ADD,
    payload: addConnector,
  };
};
/**
 * 커넥터 삭제
 * @param {*} compId
 * @returns
 */
export const deleteConnector = (compId) => {
  return {
    type: ActionType.WORKFLOW.CONNECTOR.DELETE,
    payload: compId,
  };
};

/**
 * 커넥터 수정
 * @param {*} connector
 * @returns
 */
export const updateConnector = (connector) => {
  return {
    type: ActionType.WORKFLOW.CONNECTOR.UPDATE,
    payload: connector,
  };
};

/**
 * 워크플로우 아웃풋 업데이트
 * @param {*} workflow
 * @returns
 */
export const updateWorkflowOutput = (workflow) => {
  return {
    type: ActionType.WORKFLOW.UPDATE_OUTPUT,
    payload: workflow,
  };
};

/**
 * 워크플로우 업데이트
 * @param {*} workflow
 * @returns
 */
export const updateWorkflow = (workflow) => {
  return {
    type: ActionType.WORKFLOW.UPDATE,
    payload: workflow,
  };
};

/**
 * 서비스 업데이트
 * @param {*} service
 * @returns
 */
export const updateService = (service) => {
  return {
    type: ActionType.WORKFLOW.UPDATE_SERVICE,
    payload: service,
  };
};

/**
 * 리덕스의 줌 업데이트
 * @param {*} zoom
 * @returns
 */
export const updateViewport = (viewport) => {
  return {
    type: ActionType.WORKFLOW.UPDATE_VIEWPORT,
    payload: viewport,
  };
};

/**
 * CS 파일에서 상세 선택하는 로직
 * @param {*} fileInfo
 * @returns
 */
export const setCsFileInfoUpdate = (fileInfo) => {
  return {
    type: ActionType.WORKFLOW.SET_CS_FILE_INFO,
    payload: fileInfo,
  };
};

/**
 * CS 로그 화면 노출하는 로직
 * @param {*} fileInfo
 * @returns
 */
export const setCsServiceLog = (mode) => {
  return {
    type: ActionType.WORKFLOW.SET_LOG_VIEW_MODE,
    payload: mode,
  };
};

/**
 * CS 로그 확인 수정 적용
 * @param {*} fileInfo
 * @returns
 */
export const changeLogState = (data) => {
  return {
    type: ActionType.WORKFLOW.LOG_STATE_CHANGE,
    payload: data,
  };
};

/**
 * ouput 바뀔때마다 실행
 * CS 서비스 업데이트
 * @param {*} fileInfo
 * @returns
 */
export const updateCSFileService = (data) => {
  return {
    type: ActionType.WORKFLOW.UPDATE_CS_SERVICE,
  };
};

/**
 * CS 서비스 목록에서 서비스 호출
 * @param {*} fileInfo
 * @returns
 */
export const setCSService = (service) => {
  return {
    type: ActionType.WORKFLOW.SET_CS_SERVICE,
    payload: service,
  };
};

export const updateBundle = (bundle) => {
  return {
    type: ActionType.WORKFLOW.UPDATE_BUNDLE,
    payload: bundle,
  };
};
