import { Enums } from "components/builder/BuilderEnum";
import ArrayUtils from "components/common/utils/ArrayUtils";
import ObjectUtils from "components/common/utils/ObjectUtils";
import StringUtils from "components/common/utils/StringUtils";
import { WorkflowContext } from "page/workflow";
import { useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { MarkerType } from "reactflow";

/**
 * 엣지(커넥션)와 프로세스 노드를 랜더링 하는 HOOK 입니다.
 * Redux의 Workflow output의 변화를 감지하여 각 속성을 랜더링합니다.
 * 결과적으로 랜더링 준비가 된 노드 배열과, 엣지 배열을 리턴합니다.
 * @param {*} theme
 * @returns
 */
function useWorkflowRender(theme) {
  const [Nodes, setNodes] = useState([]);
  const [Edge, setEdge] = useState([]);
  const workflow = useSelector((state) => state.workflow);
  const debug = useSelector((state) => state.workflowDebug);
  const {
    bundle: { bundleNodeList },
  } = useContext(WorkflowContext);

  const bundleNodeMap = useRef({});
  const fromConnectorForBundle = useRef([]);
  const toConnectorForBundle = useRef([]);

  useEffect(() => {
    renderNode();
    //번들링에 사용된 ref가 랜더링 호출 될때 계속 남아있기때문에 호출될때 초기화
    bundleNodeMap.current = {};
    fromConnectorForBundle.current = [];
    toConnectorForBundle.current = [];
  }, [workflow, theme, debug, bundleNodeList]);

  /**
   * 엣지 연결하는 로직
   * @param {*} connector
   * @param {*} IdType
   * @param {*} nodes
   * @param {*} edges
   * @param {*} iterator
   * @param {*} comment //주석 여부
   */
  const addEdge = (connector, nodes, edges, iterator, comment) => {
    const { edgeInfo, edgeDetailInfo } = connector;

    let fromCompId = connector.processFrom;
    let toCompId = connector.processTo;
    //그룹핑 중인지 여부 판단
    const isBundling = bundleNodeList[connector.compId];
    if (connector.propertyValue) {
      //1. 커넥터 노드 세팅
      const node = {
        id: connector.compId,
        type: Enums.WorkflowNodeType.CONNECTOR,
        position: connector.position,
        data: {
          connector: connector,
          isBundling: isBundling ? true : false, //번들링 중일때는 깜빡거려야 해서 플래그 넣음
        },
      };
      if (comment) node.data.comment = comment;
      if (iterator) {
        node.parentNode = iterator.compId;
        node.extent = "parent";
      }

      //번들여부에 따라 노드 랜더링이 다른데, 커넥터 노드가 안그려지는데, 엣지 추가할 필요없으니, 플래그로 통제
      let isAddEgde = true;

      if (bundleNodeMap.current[connector.compId]) {
        const bundleChildProps = bundleNodeMap.current[connector.compId];
        const parentsBundle = bundleChildProps.bundle;
        node.parentNode = parentsBundle.compId;
        //번들에 종속적인 형태로 구성한다.
        node.extent = "parent";
        // 포지션은 번들에서 정의된 대로 진행한다.
        node.position = bundleChildProps.position;
        if (parentsBundle.propertyValue.expand) {
          nodes.push(node);
        } else {
          isAddEgde = false;
        }
      } else {
        nodes.push(node);
      }
      if (isAddEgde) {
        //2. 커넥터 노드로 소스 to 커넥터, 커넥터 to 타겟 선 연결
        const edge = {
          id: connector.compId,
          type: "connect",
          sourceHandle: "d",
          targetHandle: "a",
          style: {
            strokeWidth: 2,
            stroke: theme === "light" ? "#3c3c3c" : "lightgray",
          },
          markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 10,
            height: 10,
            color: theme === "light" ? "#3c3c3c" : "lightgray",
          },
        };

        //커넥터가 번들을 향하고 있는지 확인
        const toBundle = fromConnectorForBundle.current[connector.compId];
        if (toBundle && !toBundle.propertyValue.expand) {
          //번들을 향하는 커넥터가 있고 해당 번들이 접혀 있으면
          toCompId = toBundle.compId;
        }
        const fromBundle = toConnectorForBundle.current[connector.compId];
        if (fromBundle && !fromBundle.propertyValue.expand) {
          //번들에서나오는 커넥터가 있고 해당 번들이 접혀 있으면
          fromCompId = fromBundle.compId;
        }
        //디버그 트레이스에 포함되어있는지 확인
        if (debug.isDebugging) {
          if (!ArrayUtils.isEmpty(debug.trace)) {
            for (const [index, item] of debug.trace.entries()) {
              if (
                index + 1 < debug.trace.length &&
                connector.processFrom === item.compId
              ) {
                if (debug.trace[index + 1].compId === connector.processTo)
                  edge.animated = true;
              }
            }
          }
        }
        const fromEdge = { ...edge };
        fromEdge.id = "To" + fromEdge.id;
        fromEdge.source = fromCompId;
        fromEdge.target = connector.compId;

        const toEdge = { ...edge };
        toEdge.id = "From" + toEdge.id;
        toEdge.source = connector.compId;
        toEdge.target = toCompId;
        if (edgeInfo) {
          fromEdge.sourceHandle = edgeInfo.sourceHandle;
          fromEdge.targetHandle = edgeInfo.targetHandle;
          toEdge.sourceHandle = edgeInfo.sourceHandle;
          toEdge.targetHandle = edgeInfo.targetHandle;
        } else if (edgeDetailInfo) {
          fromEdge.sourceHandle = edgeDetailInfo.from.sourceHandle;
          fromEdge.targetHandle = edgeDetailInfo.from.targetHandle;
          toEdge.sourceHandle = edgeDetailInfo.to.sourceHandle;
          toEdge.targetHandle = edgeDetailInfo.to.targetHandle;
        }

        //주석에서 풀면 엣지가 중복되어 생성됨. 원인파악 못함
        if (!edges.find((e) => e.id === fromEdge.id)) edges.push(fromEdge);
        if (!edges.find((e) => e.id === toEdge.id)) {
          edges.push(toEdge);
        }
      }
    } else {
      const edge = {
        id: connector.compId,
        type: "noConnect",
        source: fromCompId,
        target: toCompId,
        sourceHandle: "d",
        targetHandle: "a",
        style: {
          strokeWidth: 2,
          stroke: theme === "light" ? "#3c3c3c" : "lightgray",
        },
        markerEnd: {
          type: MarkerType.ArrowClosed,
          width: 10,
          height: 10,
          color: theme === "light" ? "#3c3c3c" : "lightgray",
        },
      };
      if (edgeInfo) {
        edge.sourceHandle = edgeInfo.sourceHandle;
        edge.targetHandle = edgeInfo.targetHandle;
      }

      if (!edges.find((e) => e.id === edge.id)) edges.push(edge);
    }
  };

  /**
   * 이터레이터 자녀 추가하는 로직
   * @param {*} process
   * @param {*} nodes
   * @param {*} edges
   */
  const addIteratorProcess = (process, nodes, edges) => {
    if (
      StringUtils.equalsIgnoreCase(
        process.type,
        Enums.WorkflowNodeType.ITERATOR
      )
    ) {
      if (!ObjectUtils.isEmpty(process.child)) {
        for (const _childNode of process.child.process) {
          const childNode = {
            id: _childNode.compId,
            type: _childNode.type,
            position: _childNode.position,
            style: _childNode.style,
            parentNode: process.compId,
            extent: "parent",
            data: {
              process: _childNode,
              theme,
            },
          };
          nodes.push(childNode);
          if (
            StringUtils.equalsIgnoreCase(
              _childNode.type,
              Enums.WorkflowNodeType.ITERATOR
            )
          ) {
            addIteratorProcess(_childNode, nodes, edges);
          }
        }

        for (const _childConnector of process.child.connector) {
          addEdge(_childConnector, nodes, edges, process);
        }
      }
    }
  };

  const renderNode = () => {
    let nodes = []; //랜더링되는 노드
    let edges = []; //랜더링 되는 연결선
    const {
      output: {
        service: {
          child: { process, connector },
        },
        bundle: bundleList = [],
      },
      serviceComment,
      serviceMemo: memoList,
    } = workflow;

    /**
     * 1. 번들 map 데이터 생성
     * [compId] : bundle 형태로 진행
     * 번들 먼저 생성 후 , 노드 랜더링 할때 compId를 검사하여 번들안에 있다고 판단되면
     * 렌더링을 bundle의 자녀로 교체함
     */
    bundleList.forEach((bundle) => {
      const {
        propertyValue: { nodeList, connectorFrom = [], connectorTo = [] },
      } = bundle;
      nodeList.forEach((node) => {
        bundleNodeMap.current[node.compId] = {
          bundle: bundle,
          position: node.position,
        };
      });
      connectorFrom?.forEach((connectorId) => {
        fromConnectorForBundle.current[connectorId] = bundle;
      });
      connectorTo?.forEach((connectorId) => {
        toConnectorForBundle.current[connectorId] = bundle;
      });

      //실제로 랜더링 될 번들 노드
      //번들이 펼쳐져 있을때만 스타일 적용
      const bundleNode = {
        //노드 정보
        id: bundle.compId,
        type: Enums.WorkflowNodeType.BUNDLE, //WorkflowNodeTypes에서 찾을것
        position: bundle.position,
        data: {
          process: bundle,
        },
      };
      if (bundle.propertyValue.expand) bundleNode.style = bundle.style;
      nodes.push(bundleNode);
    });

    let commentConnector,
      commentProcess = [];

    if (serviceComment) {
      commentProcess = serviceComment.process || [];
      commentConnector = serviceComment.connector || [];
    }
    //process
    if (process.length > 0) {
      for (const _process of process) {
        //그룹핑 중인지 여부 판단
        const isBundling = bundleNodeList[_process.compId];
        const node = {
          id: _process.compId,
          type: _process.type,
          position: _process.position,
          style: _process.style,
          data: {
            process: _process,
            theme,
            isBundling: isBundling ? true : false,
          },
        };

        //번들에 들어가는 경우
        if (bundleNodeMap.current[_process.compId]) {
          const bundleChildProps = bundleNodeMap.current[_process.compId];
          const parentsBundle = bundleChildProps.bundle;
          node.parentNode = parentsBundle.compId;
          //번들에 종속적인 형태로 구성한다.
          node.extent = "parent";
          // 포지션은 번들에서 정의된 대로 진행한다.
          node.position = bundleChildProps.position;
          //번들이 펼쳐져 있을때만 랜더링 한다.
          if (parentsBundle.propertyValue.expand) {
            nodes.push(node);
          }
        } else {
          nodes.push(node);
        }
        addIteratorProcess(_process, nodes, edges);
      }
    }
    //connector
    if (connector.length > 0) {
      for (const _connector of connector) {
        addEdge(_connector, nodes, edges);
      }
    }

    //memo
    for (const memo of memoList) {
      const node = {
        //노드 정보
        id: memo.compId,
        style: memo.style,
        type: Enums.WorkflowNodeType.MEMO, //WorkflowNodeTypes에서 찾을것
        position: memo.position,
        data: {
          memo,
        },
      };
      nodes.push(node);
    }

    //주석된 항목 렌더링
    if (!ArrayUtils.isEmpty(commentProcess)) {
      for (const _process of commentProcess) {
        const node = {
          id: _process.compId,
          type: _process.type,
          position: _process.position,
          style: _process.style,
          data: {
            process: _process,
            theme,
            comment: true,
          },
        };
        nodes.push(node);
        if (_process.parentNode) {
          node.parentNode = _process.parentNode;
          node.extent = "parent";
        }
        addIteratorProcess(_process, nodes, edges);
      }
    }
    if (!ArrayUtils.isEmpty(commentConnector)) {
      for (const _connector of commentConnector) {
        if (_connector.parentNode) {
          addEdge(
            _connector,
            nodes,
            edges,
            {
              compId: _connector.parentNode,
            },
            true
          );
        } else {
          addEdge(_connector, nodes, edges, null, true);
        }
      }
    }

    setNodes(nodes);
    setEdge(edges);
  };

  return [Nodes, Edge];
}

export default useWorkflowRender;
