import React, { useState } from "react";
import styled from "styled-components";
import Popup from "components/common/Popup";
import { BsPencilSquare } from "react-icons/bs";

import produce from "immer";
import Modal from "components/common/modal/UModal";
import UmodalTemplate from "components/common/modal/UModalTemplate";
import ArrayUtils from "components/common/utils/ArrayUtils";
import StringUtils from "components/common/utils/StringUtils";

import { MdLibraryAdd } from "react-icons/md";
import { FaTrash } from "react-icons/fa";
import { Button, Col, Form, Row } from "react-bootstrap";
import Message from "components/common/Message";
import { Enums } from "components/builder/BuilderEnum";

const PopupStyle = styled.div`
  .edit-source {
    border: 1px solid #ddd;
  }
`;

/**
 * Builder 사용자 정의 포맷 설정 Popup
 *
 * @author 김민호
 * @since 2023.12.08
 * @version 1.0
 * @see
 *
 *      <pre>
 * << 개정이력(Modification Information) >>
 *
 * 수정일 수정자 수정내용
 * ------- -------- ---------------------------
 * 2023.12.08 김민호 최초 생성
 */

/**
 *
 * @param {*} props
 * @returns value
 */
const FormatSettingPopup = (props) => {
  const { title, callbackFnc, userDefindFormat, contextData } = props;
  const [params, setParams] = useState(
    !ArrayUtils.isEmpty(userDefindFormat) ? userDefindFormat : [{ formatId: StringUtils.getUuid() }]
  );
  const textInput = React.createRef();

  let items = {};

  const setRetItems = (id, value) => {
    items = { ...items, [id]: value };
  };

  /**
   * 확인 버튼 클릭시 page.js 의 propertyValue에 커스텀 포맷 내용 저장 및 중복/빈값체크
   */
  const closePopup = () => {
    const retValue = [...params];
    const closeEventCheck = [
      retValue.some((element) => {
        if (
          StringUtils.isEmpty(element.formatName) ||
          StringUtils.isEmpty(element.decimalPoint) ||
          StringUtils.isEmpty(element.roundingPolicy)
        ) {
          Message.alert("빈 입력값이 있습니다. ", Enums.MessageType.ERROR);
          return true;
        } else return false;
      }),
      retValue.some((element) => {
        if (
          retValue.filter((item) => item.formatName === element.formatName)
            .length > 1
        ) {
          Message.alert(
            "포맷명은 중복될 수 없습니다. : " + element.formatName,
            Enums.MessageType.ERROR
          );
          return true;
        } else return false;
      }),
    ];

    if (!closeEventCheck.filter((item) => (item ? true : false)).length > 0) {
      callbackFnc.call(this, retValue);
      Popup.close();
    }
  };

  const onChangeParam = (e, param, index) => {
    changeParam(e, param, index);
  };

  /**
   * input과 combobox의 내용 변경시 change 감지 함수
   * @param {*} e
   * @param {*} param
   * @param {*} index
   */
  const changeParam = (e, param, index) => {
    const newParams = produce(params, (draft) => {
      draft[index] = {
        ...param,
        [e.target.id]: e.target.value,
      };
    });
    setParams(newParams);
    onChangeSetRetItems(e, "params", newParams);
  };

  // input low 추가
  const onInsertClick = (e, param, index) => {
    const newParams = produce(params, (draft) => {
      draft.splice(index + 1, 0,{ formatId: StringUtils.getUuid() });
    });
    setParams(newParams);
    onChangeSetRetItems(e, "params", newParams);
  };

  // input low 삭제
  const onDeleteClick = (e, param, index) => {
    const newParams = produce(params, (draft) => {
      draft.splice(index, 1);
      if (draft.length === 0) {
        draft.splice(0, 0, {});
        textInput.current.value = "";
      }
    });
    setParams(newParams);
    onChangeSetRetItems(e, "params", newParams);
  };

  /**
   * setRetItems : popupHandleConfigPopup에서 재 items 설정을 위해
   * @param {*} e
   * @param {*} pId
   * @param {*} pItem
   */
  const onChangeSetRetItems = (e, pId, pItem) => {
    if (setRetItems) {
      if (!StringUtils.isEmpty(pId)) {
        setRetItems.call(this, pId, pItem);
      } else {
        setRetItems.call(this, e.target.id, e.target.value);
      }
    }
  };

  return (
    <PopupStyle>
      <Modal>
        <Modal.Header title={title} />
        <Modal.Body>
          <UmodalTemplate>
            <Form.Group className="mb-3">
              <Col>
                <Row className="mb-2">
                  <Col>
                    <Form.Label className="required">포맷 명</Form.Label>
                  </Col>
                  <Col>
                    <Form.Label className="required">소수점 자릿수</Form.Label>
                  </Col>
                  <Col>
                    <Form.Label className="required">올림 구분</Form.Label>
                  </Col>
                  <Col></Col>
                </Row>
                {!ArrayUtils.isEmpty(params)
                  ? params.map((param, index) => {
                      return (
                        <div>
                          <Row className="pb-2">
                            <Col>
                              <input
                                type="text"
                                id="formatName"
                                ref={textInput}
                                value={param.formatName}
                                className="form-control form-select-sm"
                                onChange={(e) => {
                                  onChangeParam(e, param, index);
                                }}
                              />
                            </Col>
                            <Col>
                              <Form.Control
                                size="sm"
                                id="decimalPoint"
                                type="number"
                                max={30}
                                min={0}
                                value={param.decimalPoint || 0}
                                onChange={(e) => {
                                  let decimal = {
                                    target: {
                                      value: e.target.value,
                                      id: "decimalPoint",
                                    },
                                  };
                                  if (e.target.value > 30) {
                                    decimal = {
                                      target: { value: 30, id: "decimalPoint" },
                                    };
                                  }
                                  onChangeParam(decimal, param, index);
                                }}
                              />
                            </Col>
                            <Col>
                              <Form.Select
                                size="sm"
                                id="roundingPolicy"
                                onChange={(e) => onChangeParam(e, param, index)}
                                value={StringUtils.defaultString(
                                  param.roundingPolicy
                                )}
                              >
                                <option>선택</option>
                                {contextData.map((param, index) => {
                                  return (
                                    <option key={index} value={param.codeDtlCd}>
                                      {param.codeDtlNm}
                                    </option>
                                  );
                                })}
                              </Form.Select>
                            </Col>
                            <Col>
                              <Button
                                onClick={(e) => onInsertClick(e, param, index)}
                                size="sm"
                                className="mr-5"
                                variant={"outline-primary"}
                              >
                                <MdLibraryAdd size="16" />
                              </Button>
                              <Button
                                onClick={(e) => onDeleteClick(e, param, index)}
                                size="sm"
                                variant={"outline-danger"}
                              >
                                <FaTrash size="16" />
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      );
                    })
                  : ""}
              </Col>
            </Form.Group>
          </UmodalTemplate>
        </Modal.Body>
        <Modal.Footer>
          <Modal.Footer.Button
            type="button"
            className="btn btn-primary"
            onClick={closePopup}
          >
            <BsPencilSquare />
            &nbsp;확인
          </Modal.Footer.Button>
        </Modal.Footer>
      </Modal>
    </PopupStyle>
  );
};

export default FormatSettingPopup;
