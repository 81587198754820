/* eslint-disable jsx-a11y/alt-text */
import React, { Fragment } from "react";

import Modal from "components/common/modal/UModal";

import UmodalTemplate from "components/common/modal/UModalTemplate";

const ValidationValueSettingGuide = (props) => {
  return (
    <Modal>
      <Modal.Header title={props.title} />
      <Modal.Body>
        <UmodalTemplate>
          <Fragment>
            <h4 id="html">1. Text (문자) Type</h4>
            <p>
              Text (문자) Type에 대한 Validation 규칙 및 사용 법은 다음과
              같습니다.
            </p>
            <table className="doc-table" style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th style={{ width: "33%" }}>
                    <div>검증 내용</div>
                  </th>
                  <th style={{ width: "33%" }}>
                    <div>고정 값 직접 입력</div>
                  </th>
                  <th style={{ width: "33%" }}>
                    <div>비교 대상 Element 지정</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div>입력 해야 할 최소의 byte</div>
                  </td>
                  <td>
                    <font color="blue">가능</font>
                    <br />
                    최소 30 byte 이상 입력 해야합니다. <br></br>
                    <code> 검증 값 : 30</code>
                  </td>
                  <td>
                    <div>
                      <font color="red">불가</font>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>입력 가능한 최대의 byte</div>
                  </td>
                  <td>
                    <font color="blue">가능</font>
                    <br />
                    최대 30 byte 까지 입력 가능합니다. <br></br>
                    <code> 검증 값 : 30</code>
                  </td>
                  <td>
                    <div>
                      <font color="red">불가</font>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>지정된 element의 값과 일치</div>
                  </td>
                  <td>
                    <font color="red">불가</font>
                  </td>
                  <td>
                    <div>
                      <font color="blue">가능</font>
                      <br />
                      "신규 비밀번호" 와 "신규 비밀번호 확인"값의 일치 여부
                      <br></br>
                      <code>
                        검증 값 : <br />
                        1. #confirmPassword /*동일 Form*/ <br />
                        2. #SaveForm #confirmPassword <br />
                        3. 기타 JQuery Select 사용 가능
                      </code>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>주민등록번호 유효성 체크</div>
                  </td>
                  <td>
                    <font color="red">불가</font>
                  </td>
                  <td>
                    <div>
                      <font color="red">불가</font>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <h4 id="html">2. Number (숫자) Type</h4>
            <p>
              Number (숫자) Type에 대한 Validation 규칙 및 사용 법은 다음과
              같습니다.
            </p>
            <table className="doc-table" style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th style={{ width: "33%" }}>
                    <div>검증 내용</div>
                  </th>
                  <th style={{ width: "33%" }}>
                    <div>고정 값 직접 입력</div>
                  </th>
                  <th style={{ width: "33%" }}>
                    <div>비교 대상 Element 지정</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div>입력 해야 할 최소의 숫자 길이</div>
                  </td>
                  <td>
                    <font color="blue">가능</font>
                    <br />
                    최소 3자리 이상 숫자(1000) 를 입력 해야합니다.
                    <br></br>
                    <code> 검증 값 : 3</code>
                  </td>
                  <td>
                    <div>
                      <font color="red">불가</font>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>입력 가능한 최대의 숫자 길이</div>
                  </td>
                  <td>
                    <font color="blue">가능</font>
                    <br />
                    2자리 숫자(99) 까지 입력 가능합니다.<br></br>
                    <code> 검증 값 : 2</code>
                  </td>
                  <td>
                    <div>
                      <font color="red">불가</font>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="pt-1">
                      지정된 값 또는 element의 값보다 큰 값
                    </div>
                    <div className="pt-1">
                      지정된 값 또는 element의 값과 같거나 큰 값
                    </div>
                    <div className="pt-1">
                      지정된 값 또는 element의 값보다 작은 값
                    </div>
                    <div className="pt-1">
                      지정된 값 또는 element의 과 같거나 작은 값
                    </div>
                  </td>
                  <td>
                    <font color="blue">가능</font>
                    <br />
                    100 또는 100보다 큰 값을 입력 해야 합니다.
                    <br />
                    <code>검증 값 : 100</code>
                  </td>
                  <td>
                    <div>
                      <font color="blue">가능</font>
                      <br />
                      재고 수량을 초과 할 수 없습니다.
                      <br></br>
                      <code>
                        검증 값 : <br />
                        1. #invQty /*동일 Form*/ <br />
                        2. #InvForm #invQty
                        <br />
                        3. 기타 JQuery Select 사용 가능
                      </code>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <h4 id="html">3. Date (날짜) Type</h4>
            <p>
              Date (날짜) Type에 대한 Validation 규칙 및 사용 법은 다음과
              같습니다.
            </p>
            <table className="doc-table" style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th style={{ width: "33%" }}>
                    <div>검증 내용</div>
                  </th>
                  <th style={{ width: "33%" }}>
                    <div>고정 값 직접 입력</div>
                  </th>
                  <th style={{ width: "33%" }}>
                    <div>비교 대상 Element 지정</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className="pt-1">
                      지정된 값 또는 element의 이후 날짜
                    </div>
                    <div className="pt-1">
                      지정된 값 또는 element의 이전 날짜
                    </div>
                    <div className="pt-1">
                      지정된 값 또는 element와 같거나 이후 날짜
                    </div>
                    <div className="pt-1">
                      지정된 값 또는 element와 같거나 이전 날짜
                    </div>
                  </td>
                  <td>
                    <font color="blue">가능</font>
                    <br />
                    (+-)[숫자]d : <font color="green">현재 일자</font> 기준
                    (+-)[숫자]일 이후
                    <code>
                      {" "}
                      10d : 10일 후 <br />
                      -15d : 15일 전 <br />
                    </code>
                    (+-)[숫자]m : <font color="green">현재 일자</font>
                    기준(+-)[숫자]개월 이후
                    <code>
                      {" "}
                      2m : 2개월 후 <br />
                      -6m : 6개월 전 <br />
                    </code>
                    (+-)[숫자]y : <font color="green">현재 일자</font>
                    기준(+-)[숫자]년 이후
                    <code>
                      {" "}
                      1y : 1년 후 <br />
                      -2y : 2년 전 <br />
                    </code>
                    today : 오늘
                    <br />
                    <code>검증 값 : today</code>
                  </td>
                  <td>
                    <div>
                      <font color="blue">가능</font>
                      <br />
                      주문 일자 이후 날짜를 입력 해야 합니다.
                      <br></br>
                      <code>
                        검증 값 : <br />
                        1. #orderDate /*동일 Form*/ <br />
                        2. #OrderForm #orderDate <br />
                        3. 기타 JQuery Select 사용 가능
                      </code>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </Fragment>
        </UmodalTemplate>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default ValidationValueSettingGuide;
