import React from "react";
import UModalCodeEditor from "components/common/code/UModalCodeEditor";

class UModalStringEditor extends UModalCodeEditor {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      textValue: this.props.defaultValue,
    };
  }

  /**
   * Code Mirror를 화면에 표시
   * @override
   * @returns
   */
  renderCodeMirror = () => {
    return (
      <textarea
        className="form-control form-control-sm"
        placeholder={this.props.placeholder}
        defaultValue={this.state.textValue}
        onBlur={(e) => {
          this.setState({ textValue: e.target.value });
          this.changeValue(e.target.value);
        }}
      />
    );
  };

  render() {
    return <div className="text-editor">{this.renderCodeMirror()}</div>;
  }
}

export default React.memo(UModalStringEditor);
