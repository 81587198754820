import { Enums } from "components/builder/BuilderEnum";
import BuilderHeaderComponent from "components/builder/BuilderHeaderComponent";
import ErdBuilderHeader from "components/builder/erd/ErdBuilderHeader";
import StringUtils from "components/common/utils/StringUtils";
import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";

function Erd(props) {
  const [currentPath, setCurrentPath] = useState();
  const location = useLocation();
  useEffect(() => {
    const CurrentPath = location.pathname.split("/")[2];
    setCurrentPath(CurrentPath);
    return () => {};
  }, [location]);
  return (
    <>
      {StringUtils.equalsIgnoreCase(
        Enums.BuilderPath.WORKFLOW.BUILDER,
        currentPath
      ) ? (
        <ErdBuilderHeader {...props.header} />
      ) : (
        <BuilderHeaderComponent {...props.header} />
      )}

      <Outlet />
    </>
  );
}

export default Erd;
