import { BsSearch } from "react-icons/bs";
import React, { useState, useContext, useMemo, useEffect } from "react";
import { AppContext } from "components/common/AppContextProvider";
import { EventHandlerContext } from "page/eventhandler/EventHandlerBuilderMain";
import { debounce } from "lodash";

// Blockly workspace search link: https://github.com/google/blockly-samples/blob/master/plugins/workspace-search/src/workspace_search.ts#L196

/**
 * Block search component
 */
const EventHandlerSearch = ({ eventWorkspace }) => {
  const [searchText, setSearchText] = useState();
  const {
    eventBuilder: { blockListContainer },
  } = useContext(AppContext);
  const { selectedFncDtl } = useContext(EventHandlerContext);

  useEffect(() => {
    return () => {
      debounceGetMatchingBlocks.cancel();
    };
  }, []);

  // Block Searching
  const getMatchingBlocks = (e) => {
    let searchText = e.target.value;

    const searchBlockList = [];
    const categorySearchBlockList = [];

    // 검색 문자가 없을 경우 선택된 카테고리에 해당하는 블록들로 업데이트
    if (!searchText) {
      blockListContainer.map((block) => {
        if (selectedFncDtl) {
          if (block.eventFncDtl.fncDtlId === selectedFncDtl.fncDtlId) {
            searchBlockList.push(block);
          }
        }
      });
    } else if (searchText) {
      searchText = searchText.toLowerCase();

      // 검색 문자가 띄어쓰기 밖에 없는 경우
      if (searchText.replace(/ /gi, "") === "") {
        return;
      } else {
        blockListContainer.map((block) => {
          // 선택된 카테고리의 블록들을 먼저 search
          if (block.eventFncDtl.fncDtlId === selectedFncDtl.fncDtlId) {
            // if (block.blockName.toLowerCase().includes(searchText)) {
            //   categorySearchBlockList.push(block);
            // } else if (block.blockDesc.toLowerCase().includes(searchText)) {
            //   categorySearchBlockList.push(block);
            // } else if (block.functionName) {
            //   if (block.functionName.toLowerCase().includes(searchText)) {
            //     categorySearchBlockList.push(block);
            //   } else if (block.functionName.toLowerCase() === searchText) {
            //     categorySearchBlockList.push(block);
            //   }
            // }
            const searchBlock = searchingBlock(searchText, block);
            if (searchBlock) {
              categorySearchBlockList.push(searchBlock);
            }
          } else {
            // if (block.blockName.toLowerCase().includes(searchText)) {
            //   searchBlockList.push(block);
            // } else if (block.blockDesc.toLowerCase().includes(searchText)) {
            //   searchBlockList.push(block);
            // } else if (block.functionName) {
            //   if (block.functionName.toLowerCase().includes(searchText)) {
            //     searchBlockList.push(block);
            //   } else if (block.functionName.toLowerCase() === searchText) {
            //     searchBlockList.push(block);
            //   }
            // }
            const searchBlock = searchingBlock(searchText, block);
            if (searchBlock) {
              searchBlockList.push(searchBlock);
            }
          }
        });
      }
    }

    // 블록 update
    eventWorkspace.current.updateToolbox({
      kind: "flyoutToolbox",
      contents: [...categorySearchBlockList, ...searchBlockList].map(
        (block) => {
          const obj = {
            kind: "block",
            type: block.blockName,
            icons: JSON.parse(block.blockOption)?.icons,
            inputs: setChildBlock(JSON.parse(block.blockOption)?.childBlock),
            fields: JSON.parse(block.blockOption)?.fields,
            collapsed: JSON.parse(block.blockOption)?.icons ? true : false,
          };
          return obj;
        }
      ),
    });

    eventWorkspace.current.getFlyout().position();
  };

  const debounceGetMatchingBlocks = useMemo(
    () =>
      debounce((e) => {
        getMatchingBlocks(e);
      }, 500),
    [blockListContainer, selectedFncDtl]
  );

  const onChange = (e) => {
    debounceGetMatchingBlocks(e);
  };

  // 자식 Block 정의
  const setChildBlock = (childBlock) => {
    if (!childBlock) {
      return;
    }
    for (const key in childBlock) {
      for (let i = 0; i < blockListContainer.length; i++) {
        const block = blockListContainer[i];

        if (block.blockName === childBlock[key].block.type) {
          childBlock[key].block.inputs = setChildBlock(
            JSON.parse(block.blockOption)?.childBlock
          );
          break;
        }
      }
    }
    return childBlock;
  };

  // block을 search하는 함수
  const searchingBlock = (searchText, block) => {
    if (searchText.length < 2) {
      if (
        block.functionName &&
        block.functionName.toLowerCase() === searchText
      ) {
        return block;
      }
      return;
    }
    if (block.blockName.toLowerCase().includes(searchText)) {
      return block;
    } else if (block.blockDesc.toLowerCase().includes(searchText)) {
      return block;
    } else if (block.functionName) {
      if (block.functionName.toLowerCase().includes(searchText)) {
        return block;
      } else if (block.functionName.toLowerCase() === searchText) {
        return block;
      }
    }
    return;
  };

  return (
    <div
      className="blockly-ws-search"
      height="35px"
      width="270px"
      style={{ display: "flex", top: "0px" }}
    >
      <BsSearch size="18" />
      <div className="blockly-ws-search-input">
        <input
          onChange={onChange}
          value={searchText}
          type="text"
          placeholder="블록 검색"
          style={{ width: "220px", height: "25px" }}
        ></input>
      </div>
    </div>
  );
};

export default EventHandlerSearch;
