import ActionType from "components/builder/BuilderActionType";

export const ErdInit = () => {
  return {
    type: ActionType.ERD.INIT,
  };
};
/**
 * ERD 업데이트
 * @param {*} erd
 * @returns
 */
export const updateErd = (erd) => {
  return {
    type: ActionType.ERD.UPDATE,
    payload: erd,
  };
};

/**
 * ERD 정보 수정
 * @param {*} erd
 * @returns
 */
export const editErd = (erd) => {
  return {
    type: ActionType.ERD.EDIT,
    payload: erd,
  };
};

/**
 * ERD ViewPort 업데이트
 * @param {*} viewport
 * @returns
 */
export const updateErdViewport = (viewport, areaId) => {
  return {
    type: ActionType.ERD.UPDATE_VIEWPORT,
    payload: { viewport, areaId },
  };
};

/**
 * ERD를 신규 생성할때
 * @param {*} payload
 * @returns
 */
export const createErd = (payload) => {
  return {
    type: ActionType.ERD.CREATE,
    payload: payload,
  };
};

/**
 * 빌더 좌측 위젯 목록에서 ERD를 선택할때
 * @param {*} payload
 * @returns
 */
export const selectErd = (payload) => {
  return {
    type: ActionType.ERD.SELECT,
    payload: payload,
  };
};
