import React from "react";
import Blockly from "blockly";
import { Tooltip } from "@mui/material";
import { ButtonGroup, ToggleButton } from "react-bootstrap";
import { BiCheck, BiSave } from "react-icons/bi";
import Popup from "components/common/Popup";
import BlockSearchPopup from "page/popup/eventhandler/BlockSearchPopup";
import StringUtils from "components/common/utils/StringUtils";
import { AppContext } from "components/common/AppContextProvider";
import * as Enums from "components/builder/BuilderEnum";
import { connect } from "react-redux";
import { BsBodyText, BsCodeSlash, BsPencilSquare } from "react-icons/bs";
import CommandButton from "components/builder/CommandButton";
import {
  activateComponent,
  updateActivateEditorAttr,
  updateActivateProps,
} from "components/builder/ui/reducers/UIBuilderAction";
import UIReduxHelper from "components/builder/ui/editor/helper/UIReduxHelper";
import User from "components/common/utils/UserUtils";
import { RiJavascriptLine } from "react-icons/ri";
import { SiJavascript } from "react-icons/si";
import LocalStorageService from "services/common/LocalService";

class EventHandlerCommandButton extends CommandButton {
  constructor(props) {
    super(props);
    this.search = this.search.bind(this);
    this.openTree = this.openTree.bind(this);
    this.state = {
      tabType: "E",
      workspace: this.props.workspace,
      autoSave: false,
    };
    this.renderRightButton = this.renderRightButton.bind(this);
    this.page = this.props.output.page;
    this.updateEventWorkspaceProp = this.updateEventWorkspaceProp.bind(this);
  }
  static contextType = AppContext;

  componentDidMount() {
    const autoSaveConfig = LocalStorageService.get(
      Enums.LocalStorageName.EVENT_AUTO_SAVE
    );
    if (autoSaveConfig) {
      const info = JSON.parse(autoSaveConfig);
      if (info.userId === User.getId() && info.autoSave === "Y")
        this.setState({ autoSave: info.autoSave === "Y" });
    }
  }

  /**
   * Editor 또는 Code 버튼 클릭시 tabType 변경
   * @param {*} e
   */
  onChangeTabType(e) {
    const tabType = e.target.value;
    this.setState({
      tabType: tabType,
    });
    this.props.setTabType(tabType);
  }

  /**
   * Block 조회 버튼을 클릭 시 Popup 창 오픈
   * @param {*} e
   */
  search(e) {
    const options = {
      effect: Popup.ScaleUp,
      style: {
        content: {
          width: "50%",
        },
      },
    };

    const callbackFnc = () => {
      Popup.close();
    };
    Popup.open(
      <BlockSearchPopup
        searchBlock={callbackFnc}
        onDataSearch={this.props.onDataSearch}
        addSelectBlock={this.props.addSelectBlock}
      />,
      options
    );
  }

  /**
   * 문서 구조 Button 클릭 시 state 값 변경
   */
  openTree() {
    this.props.setOpenTree(!this.props.openTree);
  }

  /**
   * 현재 Component에 해당하는 JSON 데이터에 props값 추가 및 변경
   * @returns
   */
  updateEventWorkspaceProp() {
    const compProp = JSON.parse(
      JSON.stringify(this.props.targetComponent.propertyValue)
    );
    const compEventProp = this.props.eventHandler.componentProp;
    let newCompProp;

    if (!compProp.hasOwnProperty("eventWorkspace")) {
      compProp.eventWorkspace = {};
    }

    if (!compProp.eventWorkspace.hasOwnProperty(compEventProp.eventKind)) {
      compProp.eventWorkspace[compEventProp.eventKind] = {
        [compEventProp.eventProcess]: Blockly.serialization.workspaces.save(
          this.props.workspace.current
        ),
      };
    } else {
      compProp.eventWorkspace[compEventProp.eventKind][
        compEventProp.eventProcess
      ] = Blockly.serialization.workspaces.save(this.props.workspace.current);
    }

    newCompProp = this.updateEventFuncProp(
      compProp,
      compEventProp.propPath,
      0,
      compEventProp.propPath.length - 1
    );

    return newCompProp;
  }

  /**
   * 현재 Event의 props에 js 코드 값 넣기
   * @param {*} compProp
   * @param {*} propEventPath
   * @param {*} i
   * @param {*} finish
   * @returns
   */
  updateEventFuncProp = (compProp, propEventPath, i, finish) => {
    if (i === finish) {
      compProp[propEventPath[i]] = this.props.eventHandler.output;
      return compProp;
    }
    compProp[propEventPath[i]] = this.updateEventFuncProp(
      compProp[propEventPath[i]],
      propEventPath,
      i + 1,
      finish
    );
    return compProp;
  };

  setAutoSave = (e) => {
    const body = {
      userId: User.getId(),
      autoSave: e.target.checked ? "Y" : "N",
    };
    LocalStorageService.set(Enums.LocalStorageName.EVENT_AUTO_SAVE, body);
    this.setState({ autoSave: e.target.checked });
  };

  /**
   * Left side Button render
   * @returns
   */
  renderAdditionalButton() {
    return <></>;
  }

  /**
   * Right side Button render
   * @returns
   */
  renderRightButton() {
    return (
      <>
        <div className="program-id-label">
          <b>{this.props.parameterInfo?.eventName}</b>
        </div>
        <ButtonGroup></ButtonGroup>
      </>
    );
  }

  renderTabButton() {
    return (
      <ButtonGroup>
        <ToggleButton
          key={1}
          id="editorTab1"
          name="editorTab1"
          type="radio"
          variant="outline-dark"
          value="E"
          size="sm"
          checked={StringUtils.equalsIgnoreCase(this.state.tabType, "E")}
          onChange={this.onChangeTabType}
          style={{ fontSize: "smaller" }}
        >
          <BsPencilSquare size="14" /> Editor
        </ToggleButton>
        <ToggleButton
          key={2}
          id="editorTab2"
          name="editorTab2"
          type="radio"
          variant="outline-dark"
          value="S"
          size="sm"
          checked={StringUtils.equalsIgnoreCase(this.state.tabType, "S")}
          onChange={this.onChangeTabType}
          style={{ fontSize: "smaller" }}
        >
          <SiJavascript size="14" /> JavaScript Code
        </ToggleButton>
        <ToggleButton
          key={3}
          id="editorTab3"
          name="editorTab3"
          type="radio"
          variant="outline-dark"
          value="W"
          size="sm"
          checked={StringUtils.equalsIgnoreCase(this.state.tabType, "W")}
          onChange={this.onChangeTabType}
          style={{ fontSize: "smaller" }}
        >
          <BsBodyText size="14" /> Meta Data
        </ToggleButton>
      </ButtonGroup>
    );
  }

  render() {
    return (
      <div className="edit-tab">
        <div className="left side">
          {this.renderTabButton()}
          {this.renderAdditionalButton()}
        </div>
        <div className="right side">{this.renderRightButton()}</div>
      </div>
    );
  }
}

const reduxState = (state) => ({
  targetComponent: state.activedUIComponent.component,
  output: state.outputUI.output,
  eventHandler: state.eventHandler,
});
const dispatchState = (dispatch) => ({
  updateActivateProps: (component) => {
    dispatch(updateActivateEditorAttr(component.editorAttr));
    dispatch(updateActivateProps(component.propertyValue));
  },
  updateUI: (output) => UIReduxHelper.updateOutput(output, dispatch),
  activateComponent: (newComponent) =>
    dispatch(activateComponent(newComponent)),
});

export default connect(reduxState, dispatchState)(EventHandlerCommandButton);
