/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useMemo, useEffect, useCallback } from "react";
import Popup from "components/common/Popup";
import Modal from "components/common/modal/UModal";
import styled from "styled-components";
import "bootstrap/dist/css/bootstrap.min.css";
import { TextField as MInput } from "@mui/material";

import StringUtils from "components/common/utils/StringUtils";
import * as Fa from "react-icons/fa";
import { debounce } from "lodash";
import { faBrandIcon } from "components/builder/BrandIcons";

const IconPopupStyle = styled.div`
  .edit-source {
    border: 1px solid #ddd;
  }
  .btn-icon:hover {
    border: 3px solid #0d6efd !important;
  }
`;

/**
 * IconPopup : textarea을 확장해서 입력할 Popup
 *
 * 2022.03.11: init: songe.park
 * 2022.03.18: json, java등 다양한 언어 사용 가능하도록 수정
 *
 * @param {*} props
 * @returns value
 */
const IconPopup = (props) => {
  const { title, callbackFnc, defaultValue } = props;

  const [faIcon, setFaIcon] = useState(
    Object.keys(Fa)
      .filter((item) => {
        if (!faBrandIcon.includes(item)) {
          return item;
          //return customItem;
        }
      })
      .map((item) => {
        let customItem = item
          .replace(/([a-z])([0-9])/g, "$1-$2") //소문자 숫자   사이에 "-" ex) fa500px > fa-500px
          .replace(/([a-z])([A-Z])/g, "$1-$2") //소문자 대문자 사이에 "-" ex) faSearch > fa-search
          .replace(/[\s_]+/g, "-")
          .toLowerCase();
        return customItem;
      })
  );

  const [removeFaBrandIcon, setRemoveFaBrandIcon] = useState(
    Object.keys(Fa).filter((item) => {
      if (!faBrandIcon.includes(item)) {
        return item;
      }
    })
  );
  const [searchIconList, setSearchIconList] = useState(
    Object.keys(Fa).filter((item, index) => {
      if (!faBrandIcon.includes(item)) {
        return item;
      }
    })
  );

  //initPopup: popup open 하고 실행
  const initPopup = () => {};

  // closePopup: value값을 전달하고 popup 닫기
  const closePopup = (e) => {
    let value = e.target.id || e.currentTarget.id;

    if (callbackFnc != null) {
      callbackFnc.call(this, value);
    }

    // Popup.textChange
    Popup.close();
  };

  // 검색어를 입력하면 검색어에 맞는 icon을 찾는다
  const searchIcon = (text) => {
    if (text === "") {
      setSearchIconList(removeFaBrandIcon);
      return;
    }

    const newSearchIconList = [];
    for (let i = 0; i < faIcon.length; i++) {
      if (faIcon[i].indexOf(text.toLowerCase()) !== -1) {
        newSearchIconList.push(removeFaBrandIcon[i]);
      }
    }

    setSearchIconList(newSearchIconList);
  };

  // 검색할 때 debounce를 사용
  const debounceSearchIcon = useMemo(
    () =>
      debounce((value) => {
        searchIcon(value);
      }, 500),
    []
  );

  const onChange = (e) => {
    //setsearchText(e.target.value);
    debounceSearchIcon(e.target.value);
  };

  useEffect(() => {
    initPopup();
  }, []);

  return (
    <IconPopupStyle>
      <Modal>
        <Modal.Header title={title} />
        <Modal.Body className="h-full">
          <div className="row" style={{ display: "flex" }}>
            <div className="col">
              <MInput
                id="iconId"
                label="Icon ID"
                type="text"
                fullWidth
                size="small"
                className="xmall-input"
                color="primary"
                placeholder="Icon ID"
                onChange={onChange}
                // onKeyPress={onKeyPress}
              />
            </div>
          </div>
          <div
            className="row"
            style={{
              marginTop: "5px",
              maxHeight: "565px",
              overflow: "auto",
            }}
          >
            {searchIconList.map((icon, index) => {
              //let iconKey = Object.keys(Fa)[index];
              let IconTag = Fa[icon];

              let value = icon
                .replace(/([a-z])([0-9])/g, "$1-$2") //소문자 숫자   사이에 "-" ex) fa500px > fa-500px
                .replace(/([a-z])([A-Z])/g, "$1-$2") //소문자 대문자 사이에 "-" ex) faSearch > fa-search
                .replace(/[\s_]+/g, "-")
                .toLowerCase();

              return (
                <div
                  className="col col-1"
                  key={StringUtils.getUuid()}
                  style={{
                    textAlign: "center",
                  }}
                >
                  <button
                    id={value}
                    onClick={closePopup}
                    className="btn btn-icon"
                    style={{
                      width: "100%",
                      marginTop: "3px",
                      borderRadius: "5px",
                      border: "2px solid transparent",
                      minHeight: "64px",
                      backgroundColor: "#fff",
                      boxShadow:
                        "0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%)",
                    }}
                  >
                    <h2>{<IconTag />}</h2>
                  </button>
                  <p
                    style={{
                      marginTop: "5px",
                      fontSize: "0.75em",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      wordWrap: "normal",
                      overflow: "hidden",
                    }}
                  >
                    {value}
                  </p>
                </div>
              );
            })}
          </div>
        </Modal.Body>
        <Modal.Footer className="fixed">
          {/* <button
            type="button"
            className="btn btn-primary"
            onClick={closePopup}
          >
            <BsPencilSquare />
            &nbsp;확인
          </button> */}
        </Modal.Footer>
      </Modal>
    </IconPopupStyle>
  );
};

export default IconPopup;
