import ActionType from "components/builder/BuilderActionType";
import produce from "immer";

const initialState = {
  workspace: {},
  output: "",
  isInit: false,
  componentProp: {
    compId: "",
    eventType: "",
    eventTargetPath: [],
  },
};

export default function EventhandlerReducer(state = initialState, action) {
  switch (action.type) {
    case ActionType.EVENT_HANDLER.INIT:
      return initialState;
    case ActionType.EVENT_HANDLER.WORKSPACE.INIT:
      return { ...state, workspace: {} };
    case ActionType.EVENT_HANDLER.WORKSPACE.UPDATE:
      return produce(state, (draft) => {
        draft.workspace = action.payload;
      });
    case ActionType.EVENT_HANDLER.UPDATE_HANDLER:
      return { ...state, ...action.payload };
    case ActionType.EVENT_HANDLER.UPDATE_OUTPUT:
      return produce(state, (draft) => {
        draft.output = action.payload;
      });
    case ActionType.EVENT_HANDLER.UPDATE_VARIABLES:
      return produce(state, (draft) => {
        draft.varList.push(action.payload);
      });
    case ActionType.EVENT_HANDLER.UPDATE_COMPONENT_PROP:
      return produce(state, (draft) => {
        draft.componentProp = { ...action.payload };
      });
    case ActionType.EVENT_HANDLER.WORKSPACE.SET_IS_INIT:
      return produce(state, (draft) => {
        draft.isInit = action.payload;
      });
    default:
      return state;
  }
}
