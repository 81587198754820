import { Enums } from "components/builder/BuilderEnum";
import StringUtils from "components/common/utils/StringUtils";

class SessionUtils {
  static storageName = Enums.LocalStorageName.AUTOMATION;

  /**
   * obj를 암호화 하여 세션 스토리지에 저장
   * @param {*} obj
   * @param {*} item_name default value : "automation"
   */
  static set(obj, item_name = this.storageName) {
    try {
      let enc = {};
      const beforeDecrypt = window.sessionStorage.getItem(item_name);
      if (beforeDecrypt) {
        const dec = StringUtils.decData(beforeDecrypt);
        if (dec) {
          enc = { ...dec };
        }
      }
      enc = { ...enc, ...obj };
      window.sessionStorage.setItem(item_name, StringUtils.encJson(enc));
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * 세션에 저장된 데이터 삭제
   * @param {*} obj
   * @param {*} item_name default value : "automation"
   */
  static delete(key, item_name = this.storageName) {
    try {
      let sessionInfo = this.get(null, SessionUtils.storageName);
      delete sessionInfo[key];
      window.sessionStorage.setItem(
        item_name,
        StringUtils.encJson(sessionInfo)
      );
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * 세션스토리지에서 value 추출
   * @param {*} key optional
   * @param {*} item_name default value : "automation"
   * @returns {Object}
   */
  static get(key, item_name = this.storageName) {
    try {
      const beforeDecrypt = window.sessionStorage.getItem(item_name);
      if (!beforeDecrypt) return null;

      const dec = StringUtils.decData(beforeDecrypt);
      if (Object.keys(dec).length > 0) {
        if (!key) {
          //key값 없으면 스토리지 아이템 전체 추출
          return dec;
        } else {
          return dec[key];
        }
      } else {
        return null;
      }
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * 접속했는지 여부 확인
   * @returns boolean
   */
  static LoggedCheck() {
    try {
      const beforeDecrypt = window.sessionStorage.getItem(this.storageName);
      if (!beforeDecrypt) return false;
      else {
        const userPk = this.get("userMastRecId");
        const userId = this.get("userId");
        //아이디와 사용자 PK가 있으면 정상접속이라 판단
        if (userPk && userId) return true;
        else return false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  }
}

export default SessionUtils;
