import { deleteConnector } from "components/builder/workflow/reducer/WorkflowAction.js";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useStore, getStraightPath, getSmoothStepPath } from "reactflow";
import WorkflowReduxHelper from "../editor/helper/WorkflowReduxHelper.js";

/**
 * 커넥터가 없는 경우 엣지 형태 입니다.
 * 2023-06-14 기준 사용하지 않음
 * @param {*} param0
 * @returns
 */
function WorkflowNoConnectorEdge({
  id,
  source,
  target,
  markerEnd,
  style,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
}) {
  const workflow = useSelector((state) => state.workflow);
  const sourceNode = useStore(
    useCallback((store) => store.nodeInternals.get(source), [source])
  );
  const targetNode = useStore(
    useCallback((store) => store.nodeInternals.get(target), [target])
  );
  const dispatch = useDispatch();

  const foreignObjectSize = 30;

  if (!sourceNode || !targetNode) {
    return null;
  }

  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const onDeleteConnector = (e, id) => {
    WorkflowReduxHelper.deleteConnector(dispatch, id, workflow);
  };

  return (
    <>
      <path
        id={id}
        className="react-flow__edge-path"
        d={edgePath}
        strokeWidth={5}
        markerEnd={markerEnd}
        style={style}
      />
      <foreignObject
        width={foreignObjectSize}
        height={foreignObjectSize}
        x={labelX - foreignObjectSize / 2}
        y={labelY - foreignObjectSize / 2}
        className="edgebutton-foreignobject"
      >
        <div
          style={{ userSelect: "none" }}
          onClick={(event) => onDeleteConnector(event, id)}
        >
          ×
        </div>
      </foreignObject>
    </>
  );
}

export default WorkflowNoConnectorEdge;
