import React from "react";
import { StringUtils, ArrayUtils } from "components/common/utils/CommonUtils";
import { FaHome } from "react-icons/fa";
import { Link } from "react-router-dom";

const PageTemplate = (props) => {
  const breadcrum = props.breadcrum;

  let pageTitle = props.pageTitle;
  let pageSubTitle = props.pageSubTitle;

  if (!ArrayUtils.isEmpty(breadcrum) && StringUtils.isEmpty(pageTitle)) {
    const activeIndex = ArrayUtils.getIndex(breadcrum, "active", true);
    if (activeIndex > -1) {
      pageTitle = breadcrum[activeIndex].name;

      if (
        StringUtils.isEmpty(pageSubTitle) &&
        !StringUtils.isEmpty(breadcrum[activeIndex].subName)
      ) {
        pageSubTitle = breadcrum[activeIndex].subName;
      }
    }
  }

  return (
    <div className="page-wrapper">
      <div className="page-header">
        <h1>
          {pageTitle}
          {!StringUtils.isEmpty(pageSubTitle) ? (
            <small>{pageSubTitle}</small>
          ) : (
            ""
          )}
        </h1>
        <ol className="breadcrumb">
          {!ArrayUtils.isEmpty(breadcrum)
            ? breadcrum.map((page, index) => {
                return (
                  <li key={index}>
                    {index === 0 ? <FaHome /> : ""}{" "}
                    {page.active !== true && !StringUtils.isEmpty(page.url) ? (
                      <Link to={page.url}>{page.name}</Link>
                    ) : page.onClick ? (
                      <span onClick={page.onClick}>{page.name}</span>
                    ) : (
                      <React.Fragment>{page.name}</React.Fragment>
                    )}
                  </li>
                );
              })
            : ""}
        </ol>
      </div>
      <div className="page-content">{props.children}</div>
    </div>
  );
};

const Box = (props) => {
  const boxTheme = "box-" + StringUtils.defaultString(props.theme, "primary");
  const boxClass = StringUtils.defaultString(props.boxClass, "");
  return (
    <div className={`box ${boxTheme} ${boxClass}`}>
      {!StringUtils.isEmpty(props.title) ? (
        <div className="box-header with-border">
          <h3 className="box-title">{props.title}</h3>
        </div>
      ) : (
        ""
      )}
      <div className="box-body">{props.children}</div>
    </div>
  );
};

export default Object.assign(PageTemplate, {
  Box: Box,
});
