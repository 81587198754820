import Modal from "components/common/modal/UModal";
import UModalTemplate from "components/common/modal/UModalTemplate";
import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";

function ConnectorValidationPopup({ callbackFnc, ...props }) {
  const onClick = (e, flag) => {
    if (callbackFnc) {
      callbackFnc({
        filter: flag,
        connectorNm: `Validation ${flag ? "성공" : "실패"}`,
      });
    }
  };

  return (
    <Modal>
      <Modal.Header title={"Validation 조건을 선택해주세요."} />
      <Modal.Body>
        <UModalTemplate className={"connector-validation-wrapper"}>
          <Row>
            <Col xs={6}>
              <Button
                className="validation-button"
                variant="outline-primary"
                onClick={(e) => onClick(e, true)}
              >
                Validation 성공
              </Button>{" "}
            </Col>
            <Col xs={6}>
              <Button
                className="validation-button"
                variant="outline-danger"
                onClick={(e) => onClick(e, false)}
              >
                Validation 실패
              </Button>
            </Col>
          </Row>
        </UModalTemplate>
      </Modal.Body>
    </Modal>
  );
}

export default ConnectorValidationPopup;
