import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { Enums } from "components/builder/BuilderEnum";
import { AppContext } from "components/common/AppContextProvider";
import { RiDeleteBinLine } from "react-icons/ri";
import { BiEditAlt } from "react-icons/bi";
import Message from "components/common/Message";
import Popup from "components/common/Popup";
import CommonUtils from "components/common/utils/CommonUtils";
import StringUtils from "components/common/utils/StringUtils";
import AppModuleRegisterPopup from "page/popup/app/AppModuleRegisterPopup";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import AppService from "services/common/AppService";
import WijmoGrid from "components/common/element/WijmoGrid";

function AppModuleManange() {
  const applicationList = useContext(
    AppContext
  ).application.availableList.filter((app) => app.deployable);
  const [moduleMstList, setModuleMstList] = useState([]);
  const [appId, setAppId] = useState("");
  const [pageSize, setPageSize] = useState(20);
  const columns = [
    {
      field: "appNm",
      headerName: "Application  명",
      width: "*",
      renderCell: (param) => {
        const app = applicationList.find((a) =>
          StringUtils.equalsIgnoreType(a.appId, param.appId)
        );
        return app.appNm;
      },
    },
    {
      field: "moduleCd",
      headerName: "모듈 코드",
      width: 200,
    },
    {
      field: "moduleNm",
      headerName: "모듈 명",
      width: "*",
    },
    {
      field: "updtUserId",
      headerName: "최종 수정자",
      width: 150,
    },
    {
      field: "updtDt",
      headerName: "수정 시간",
      width: 200,
      renderCell: (param) => CommonUtils.getDate(param.updtDt, "datetime"),
    },
    {
      field: "remark",
      headerName: "비고",
      width: 100,
      align: "center",
      renderCell: (param) => (
        <div style={{ display: "flex", gap: "3px" }}>
          <Button
            size="sm"
            variant="outline-success"
            onClick={(e) => onModuleRegisterPopupOpen(e, param)}
          >
            <BiEditAlt />
          </Button>
          <Button
            size="sm"
            variant="outline-danger"
            onClick={(e) => onDeleteModuleMst(param)}
          >
            <RiDeleteBinLine />
          </Button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    getModuleMstList();
    return () => {
      setModuleMstList([]);
    };
  }, []);

  const getModuleMstList = () => {
    const body = {
      appId: appId ? appId : null,
    };
    AppService.getAppModuleList(body, (res) => {
      setModuleMstList(res.data);
    });
  };
  /**
   * 모듈 삭제
   */
  const onDeleteModuleMst = (ModuleMst) => {
    Message.confirm(
      "모듈을 삭제하시면 연결된 버전, 테넌트, 프로그램이 모두 삭제 됩니다. 삭제 하시겠습니까?",
      () => {
        AppService.deleteModule(ModuleMst, (res) => {
          Message.alert(res.message, Enums.MessageType.SUCCESS);
          getModuleMstList();
        });
      }
    );
  };

  /**
   * 모듈 신규등록 팝업
   * @param {*} e
   * @param {*} moduleInfo
   */
  const onModuleRegisterPopupOpen = (e, moduleInfo) => {
    if (e) e.preventDefault();

    const options = {
      effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
      style: {
        content: {
          width: "350px", //popup의 크기를 50% (default 60%)
        },
      },
    };
    Popup.open(
      <AppModuleRegisterPopup
        appList={applicationList}
        selectedAppId={appId}
        callBack={getModuleMstList}
        module={moduleInfo}
      />,
      options
    );
  };

  return (
    <>
      <div className="setting-header">Application 모듈 관리</div>
      <div className="setting-container">
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <FormControl fullWidth size="small">
              <InputLabel id="application-label">Application 선택</InputLabel>
              <Select
                labelId="application-label"
                label="Application 선택"
                value={appId}
                onChange={(e) => setAppId(e.target.value)}
              >
                <MenuItem value={""}>전체 선택</MenuItem>
                {applicationList.map((app) => {
                  return (
                    <MenuItem value={app.appId} key={app.appId}>
                      {app.appNm}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <Button onClick={getModuleMstList}>검색</Button>
          </Grid>
          <Grid item xs={12}>
            <WijmoGrid
              style={{ height: "50vh" }}
              columns={columns}
              rows={moduleMstList}
              onRowClick={(param) => setAppId(param.appId)}
            />
          </Grid>
        </Grid>
      </div>
      <div className="setting-button">
        <Button variant="outline-primary" onClick={onModuleRegisterPopupOpen}>
          신규 모듈 등록
        </Button>
      </div>
    </>
  );
}

export default AppModuleManange;
