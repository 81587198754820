import Blockly from "blockly";

/**
 * Blockly custom input link : https://developers.google.com/blockly/guides/create-custom-blocks/inputs/creating-custom-inputs
 */
export class BlockCallbackInput extends Blockly.inputs.Input {
  type = Blockly.inputTypes.VALUE;

  connection;

  constructor(name, block) {
    // Errors are maintained for people not using typescript.
    if (!name) throw new Error("Value inputs must have a non-empty name");

    super(name, block);
    this.connection = this.makeConnection(Blockly.ConnectionType.INPUT_VALUE);
  }
}
Blockly.registry.register(
  Blockly.registry.Type.INPUT,
  "input_callback",
  BlockCallbackInput
);
