import ActionType from "components/builder/BuilderActionType";

const initialState = {};

export default function workspaceReducer(state = initialState, action) {
  switch (action.type) {
    case ActionType.WORKSPACE.SET:
      return { ...action.payload };
    case ActionType.WORKSPACE.INIT:
      return initialState;
    default:
      return state;
  }
}
