import React, { useRef } from "react";
import { useDrag } from "react-dnd";
import produce from "immer";

import UIEditorChildRender from "components/builder/ui/editor/UIEditorChildRender";
import * as Enums from "components/builder/BuilderEnum";
import UIEditorDNDContainer from "components/builder/ui/editor/UIEditorDNDContainer";
import {
  ArrayUtils,
  JsonUtils,
  NumberUtils,
  ObjectUtils,
  StringUtils,
} from "components/common/utils/CommonUtils";
import WidgetContainer from "components/builder/ui/uiComponents/widget/WidgetContainer";

const style = {};
const UIEditorWidget = (props) => {
  const {
    data,
    handleDrop,
    path,
    templateComponents,
    rootLocation,
    childrenCount,
  } = props;
  const ref = useRef(null);

  const isRoot = path.split("-").length === 1;

  const item = produce(data, (draft) => {
    draft.path = path;
    draft.rootLocation = rootLocation;
  });
  const [{ isDragging }, drag] = useDrag({
    item: item,
    type: item.type,
    //canDrag: canDrag
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(ref);

  const propertyValue = ObjectUtils.isEmpty(item.propertyValue)
    ? {}
    : item.propertyValue;

  const DNDContainer = props.dndContainer
    ? props.dndContainer
    : UIEditorDNDContainer;

  let parentStyle = {}; //alignSelf: "center" };

  //desktop width는 적용해준다.
  if (NumberUtils.isNumeric(propertyValue.widthLaptop)) {
    parentStyle.width = (Number(propertyValue.widthLaptop) * 100) / 12 + "%";
  }

  if (propertyValue.style) {
    if (propertyValue.style.width) {
      parentStyle.width = propertyValue.style.width;
    }
  }

  return (
    <div ref={ref} className="whole-wrap" style={parentStyle}>
      <WidgetContainer
        style={{ ...style, opacity }}
        componentInfo={item}
        event="renderEditor"
      >
        {!ArrayUtils.isEmpty(item.child) && propertyValue.dropable === true ? (
          item.child.map((child, index) => {
            const currentPath = `${path}-${index}`;
            return (
              <React.Fragment key={index}>
                <UIEditorChildRender
                  key={child.compId}
                  data={child}
                  handleDrop={handleDrop}
                  templateComponents={templateComponents}
                  path={currentPath}
                  dndContainer={DNDContainer}
                  rootLocation={rootLocation}
                />
                <DNDContainer
                  data={{
                    location: Enums.ComponentType.CONTAINER,
                    rootLocation: rootLocation,
                    path: `${path}-${index + 1}`,
                    childrenCount: item.child.length,
                  }}
                  onDrop={handleDrop}
                  templateComponents={templateComponents}
                />
              </React.Fragment>
            );
          })
        ) : propertyValue.dropable === true ? (
          <DNDContainer
            data={{
              location: Enums.ComponentType.CONTAINER,
              rootLocation: rootLocation,
              path: `${path}-${
                ArrayUtils.isEmpty(item.child) ? 0 : item.child.length
              }`,
              childrenCount: ArrayUtils.isEmpty(item.child)
                ? 0
                : item.child.length,
            }}
            onDrop={handleDrop}
            templateComponents={templateComponents}
          />
        ) : (
          ""
        )}
      </WidgetContainer>
      {isRoot ? (
        <DNDContainer
          data={{
            location: Enums.ComponentType.PAGE,
            rootLocation: Enums.ComponentType.PAGE,
            path: path,
            childrenCount: childrenCount,
          }}
          onDrop={handleDrop}
          path={path}
          templateComponents={templateComponents}
          className="widget-dnd"
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default React.memo(UIEditorWidget);
