import { useSelector, useDispatch } from "react-redux";
import React, { useContext } from "react";

import { AppContext } from "components/common/AppContextProvider";
import { ObjectUtils, StringUtils } from "components/common/utils/CommonUtils";
import * as Enums from "components/builder/BuilderEnum";
import * as Event from "components/builder/ui/editor/handler/UIEditorEventHandler";

import {
  RiFileCopyLine,
  RiDragMoveFill,
  RiDeleteBin6Line,
  RiInsertColumnRight,
  RiInsertColumnLeft,
  RiInsertRowBottom,
  RiInsertRowTop,
} from "react-icons/ri";

import { BsArrowsCollapse, BsArrowsExpand } from "react-icons/bs";
import { setBuilderTreeNodeIds } from "components/builder/ui/reducers/UIBuilderAction";
import { generateNodeTreeIds } from "../tree/treeUtils";
import { BiCut } from "react-icons/bi";

const UIComponentSection = (props) => {
  const { item, style, className, children, id } = props;

  const output = useSelector((state) => state.outputUI.output);

  const activedComponent = useSelector(
    (state) => state.activedUIComponent.component
  );
  const treeNodeIds = useSelector(
    (state) => state.activedUIComponent.treeNodeIds
  );

  const context = useContext(AppContext);
  const components = useContext(AppContext).component.getComponentList("B");
  const pageCompnent = useContext(AppContext).component.getPageComponent();

  const dispatch = useDispatch();

  let collapse =
    !ObjectUtils.isEmpty(item.editorAttr) && item.editorAttr.collapse === true
      ? true
      : false;

  //title
  let title = StringUtils.defaultString(
    props.title,
    StringUtils.toUpperCase(item.type)
  );

  let itemCompId = StringUtils.defaultString(activedComponent.compId);

  //component wapper div 의 style class
  let componentClass = className;

  if (itemCompId === item.compId) {
    componentClass += " component-select";
  }
  if (props.focusDelegation === true) componentClass += " focus-delegation";

  if (collapse === true) componentClass += " layout-collapse";
  else componentClass += " layout-expand";

  /**
   * Component 클릭시 해당 Component를 activation 시키다.
   * @param {Event} e
   * @returns
   */
  const onClick = (e) => {
    //focus (Click event)를 component로 위임했을 경우 click event를 실행하지 않는다.
    if (props.focusDelegation === true) {
      Event.stopEvent(e);
      return;
    }

    let targetId = item.compId;
    const { type } = item;
    if (
      StringUtils.equalsIgnoreCase(type, Enums.ComponentType.GRID_CELL) ||
      StringUtils.equalsIgnoreCase(type, Enums.ComponentType.GRID_HEADER)
    ) {
      targetId = item.compId.split("-")[1];
    }
    Event.clickComponent(e, item, components, activedComponent, dispatch);
    const nodeIds = generateNodeTreeIds(output.page, targetId);
    dispatch(setBuilderTreeNodeIds(nodeIds));
  };

  /**
   * 선택된 Component 를 복사한후 이전 Node에 삽입
   * @param {Event} e
   */
  const insertBeforeNode = (e) => {
    Event.insertNode(e, "B", item, output, components, dispatch);
  };

  /**
   * 선택된 Component 를 복사한후 이후 Node에 삽입
   * @param {Event} e
   */
  const insertAfterNode = (e) => {
    Event.insertNode(e, "A", item, output, components, dispatch);
  };

  /**
   * 선택된  Node(Component)를 삭제한다.
   * @param {Event} e
   */
  const removeNode = (e) => {
    Event.removeNode(e, item, output, components, pageCompnent, dispatch);
  };

  /**
   * 복사 event
   * @param {Evnet} e
   */
  const copyNode = (e) => {
    Event.copyNode(e, activedComponent, output, dispatch);
  };

  /**
   * 잘라내기
   * @param {*} e
   */
  const cutNode = (e) => {
    Event.cutNode(e, activedComponent, output, treeNodeIds, context, dispatch);
  };

  /**
   * Component collapse, expand event
   * @param {Event} e
   */
  const callaseNode = (e) => {
    Event.callaseNode(e, item, output, collapse, dispatch);
  };

  return (
    <div
      data-id={item.compId}
      style={style}
      className={componentClass}
      onClick={onClick}
      id={id}
    >
      {props.noneToolbarTitle !== true ? (
        <div
          className={`comp-title ${
            props.focusDelegation === true ? "focus-delegation" : ""
          }`}
        >
          {props.noneCollapse !== true &&
          item.rootLocation !== Enums.ComponentType.FOOTER ? (
            <span className="collapse-button" onClick={callaseNode}>
              {collapse ? (
                <BsArrowsExpand size={16} />
              ) : (
                <BsArrowsCollapse size={16} />
              )}
            </span>
          ) : (
            ""
          )}
          <span>
            {StringUtils.defaultString(props.title, title)}
            {/* {StringUtils.isEmpty(item.id) ? "" : "-" + item.id} */}
          </span>
        </div>
      ) : (
        ""
      )}
      {props.noneToolbar !== true ? (
        <div className="comp-toolbar">
          <span className="comp-move-icon">
            <RiDragMoveFill size={16} />
          </span>
          <span onClick={copyNode}>
            <RiFileCopyLine size={16} />
          </span>
          {item.type === Enums.ComponentType.COLUMN ||
          item.type === Enums.ComponentType.GRID_CELL ||
          item.type === Enums.ComponentType.GRID_HEADER ? (
            <React.Fragment>
              <span onClick={insertBeforeNode}>
                <RiInsertColumnLeft size={16} />
              </span>
              <span onClick={insertAfterNode}>
                <RiInsertColumnRight size={16} />
              </span>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <span onClick={insertBeforeNode}>
                <RiInsertRowTop size={16} />
              </span>
              <span onClick={insertAfterNode}>
                <RiInsertRowBottom size={16} onClick={insertAfterNode} />
              </span>
            </React.Fragment>
          )}
          <span onClick={removeNode}>
            <RiDeleteBin6Line size={16} />
          </span>
        </div>
      ) : (
        ""
      )}
      {children}
    </div>
  );
};
export default React.memo(UIComponentSection);
