import ActionType from "components/builder/BuilderActionType";

export const setUserStyle = (style) => {
  return {
    type: ActionType.SETTING.STYLE.SET,
    payload: { ...style },
  };
};

export const initSetting = () => {
  return {
    type: ActionType.SETTING.INIT,
  };
};
