import React, { useRef } from "react";
import { AiOutlineClear, AiOutlineClose } from "react-icons/ai";
import { stopEvent } from "../../ui/editor/handler/UIEditorEventHandler";
import { BiMinus, BiWindow } from "react-icons/bi";
import { useEffect } from "react";
import { useState } from "react";
import WorkflowService from "services/workflow/WorkflowService";
import User from "components/common/utils/UserUtils";
import { useSelector } from "react-redux";
import { useContext } from "react";
import { AppContext } from "components/common/AppContextProvider";
import useInterval from "components/common/utils/useInterval";
import { FaPause, FaPlay } from "react-icons/fa";
import Message from "components/common/Message";
import { Enums } from "components/builder/BuilderEnum";

function WorkflowDebugConsole({
  debugConsoleMode,
  setDebugConsoleMode,
  getSocketRef,
  sendSocketMessage,
  getSocketId,
  getConsoleLog,
  log,
  setLog,
  setConsoleLogAutoLoad,
  consoleLogAutoLoad,
  ...props
}) {
  const consoleWrapperRef = useRef();
  const pageYRef = useRef();
  const titleHeight = useRef(30);
  const terminalRef = useRef();
  const onDragStart = (e) => {
    e.dataTransfer.setDragImage(e.target, e.pageX, 0);
  };
  const [isShow, setIsShow] = useState(false);
  const workspace = useSelector((state) => state.workspace);
  const {
    connection: { Info: connection, openPopup },
  } = useContext(AppContext);

  const [logConfig, setLogConfig] = useState({
    preEndPoint: 0,
  });
  const initalLogCallTimer = useRef(1);
  const [logCallTimer, setLogCallTimer] = useState(initalLogCallTimer.current);
  const logRef = useRef("");

  useEffect(() => {
    if (!debugConsoleMode) {
      onCloseConsole();
    } else {
      setIsShow(true);
      setTimeout(() => {
        consoleWrapperRef.current.style = `height:${400}px;transition:0.3s`;
        terminalRef.current.style = `height:${400 - 30}px`;
        setExpressionHeight(400);
      }, 100);
    }
    setConsoleLogAutoLoad(debugConsoleMode);
  }, [debugConsoleMode]);

  // useEffect(() => {
  //   client.current = getSocketRef();
  //   subscribeSocket();
  // }, [getSocketRef]);

  /**
   * 로그를 호출 카운터
   * 10초 마다 새로운 로그를 호출
   * Custom Hook으로 제작
   */
  useInterval(
    () => {
      const now = logCallTimer - 1;
      if (now === 0) {
        setLogCallTimer(initalLogCallTimer.current);
        getConsoleLog();
      } else {
        setLogCallTimer(now);
      }
    },
    [1000],
    consoleLogAutoLoad
    // false
  );

  /**
   * 우측 Varable,Expression 바 높이 조절
   * @param {*} consoleHeight
   */
  const setExpressionHeight = (consoleHeight) => {
    const _expressionMenu = document.querySelector(".debug-expression");
    if (_expressionMenu) {
      _expressionMenu.setAttribute(
        "style",
        `height : calc(100% - ${consoleHeight}px) `
      );
    }
  };

  /**
   * 드래그 이벤트
   * @param {*} e
   * @returns
   */
  const onDragComponent = (e) => {
    e.stopPropagation();
    const windowHeight = window.innerHeight;
    if (pageYRef.current === e.pageY) return false;
    else if (e.pageY) {
      pageYRef.current = e.pageY;
      let componentHeight = windowHeight - e.pageY;
      if (windowHeight - e.pageY < titleHeight.current) {
        componentHeight = titleHeight.current;
      } else if (e.pageY <= 50) {
        componentHeight = windowHeight - 50; //헤더 높이 까지만 설정
      }
      consoleWrapperRef.current.style = `height:${componentHeight}px`;
      terminalRef.current.style = `height:${componentHeight - 30}px`;

      setExpressionHeight(componentHeight);
    }
  };

  /**
   * 콘솔 닫기
   * @param {*} e
   */
  const onCloseConsole = (e) => {
    logRef.current = "";
    if (consoleWrapperRef.current.clientHeight > titleHeight.current) {
      consoleWrapperRef.current.style = `height:${titleHeight.current}px;transition:0.3s`;
      setTimeout(() => {
        setIsShow(false);
        setDebugConsoleMode(false);
        setLogCallTimer(initalLogCallTimer.current);
        setExpressionHeight(0);
      }, 300);
    } else {
      setDebugConsoleMode(false);
      setIsShow(false);
      setExpressionHeight(0);
    }
  };

  /**
   * 최소화
   * @param {*} e
   */
  const onClickMinimum = (e) => {
    stopEvent(e);
    consoleWrapperRef.current.style = `height:${titleHeight.current}px;transition:0.3s`;
    terminalRef.current.style = `height:${
      titleHeight.current - 30
    }px;transition:0.3s`;
    setExpressionHeight(30);
  };

  /**
   * 최대화
   * @param {*} e
   */
  const onClickMaximum = (e) => {
    stopEvent(e);
    const windowHeight = window.innerHeight;
    consoleWrapperRef.current.style = `height:${
      windowHeight - 50
    }px;transition:0.3s`;
    terminalRef.current.style = `height:${
      windowHeight - 50 - 30
    }px;transition:0.3s`;
  };

  const getConsoleLogOld = () => {
    // const connection = User.getConnection(workspace.tenantMstId)
    if (!connection || User.getConnection(workspace.tenantMstId, "expired")) {
      setConsoleLogAutoLoad(false);
      Message.alert("서버가 연결되지 않았습니다.", Enums.MessageType.WARN);
      return openPopup();
    }
    // ?return false;
    WorkflowService.getLog(
      {
        ...connection,
        ...logConfig,
        developerId: User.getId(),
      },
      (res) => {
        const { log: _log, endPoint, startPoint, isError } = res.data;
        let newLog = log + _log;

        setLogConfig({
          preEndPoint: endPoint,
        });
        if (isError) {
          setConsoleLogAutoLoad(false);
          newLog += "\n";
        }

        if (log !== newLog) {
          // setLog(newLog);
          setTimeout(() => {
            terminalRef.current.scrollTop = terminalRef.current.scrollHeight;
          }, 100);
        }
      },
      (err) => {
        setConsoleLogAutoLoad(false);
      }
    );
  };
  useEffect(() => {
    if (log && terminalRef.current) {
      setTimeout(() => {
        terminalRef.current.scrollTop = terminalRef.current.scrollHeight;
      }, 100);
    }
  }, [log]);

  const onClickLogCall = () => {
    setLogCallTimer(initalLogCallTimer.current);
    getConsoleLog();
  };

  const clearConsole = (e) => {
    setLog("");
    setLogConfig({
      preEndPoint: 0,
    });
  };

  return (
    <div
      className={`debug-console-wrapper ${isShow ? "show" : "hidden"}`}
      ref={consoleWrapperRef}
    >
      <div
        className="header"
        onDragStart={onDragStart}
        onDrag={onDragComponent}
        draggable
      >
        <div>Workflow Debugging Console</div>
        <div>
          {consoleLogAutoLoad ? (
            <button onClick={(e) => setConsoleLogAutoLoad(false)}>
              <FaPause />
            </button>
          ) : (
            <button onClick={(e) => setConsoleLogAutoLoad(true)}>
              <FaPlay />
            </button>
          )}

          <button onClick={clearConsole}>
            <AiOutlineClear />
          </button>
          <button onClick={onClickMinimum}>
            <BiMinus />
          </button>
          <button onClick={onClickMaximum}>
            <BiWindow />
          </button>
          <button onClick={onCloseConsole}>
            <AiOutlineClose />
          </button>
        </div>
      </div>
      <textarea
        className="terminal-console"
        value={log}
        ref={terminalRef}
        onChange={(e) => {}}
      />
    </div>
  );
}

export default WorkflowDebugConsole;
