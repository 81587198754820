import { Enums } from "components/builder/BuilderEnum";
import { stopEvent } from "components/builder/ui/editor/handler/UIEditorEventHandler";
import UTextarea from "components/common/element/UTextarea";
import Message from "components/common/Message";
import Modal from "components/common/modal/UModal";
import UModalTemplate from "components/common/modal/UModalTemplate";
import StringUtils from "components/common/utils/StringUtils";
import React from "react";
import { useState } from "react";
import { Form, FormGroup } from "react-bootstrap";

function NewServicePopup({ callbackFnc, ...props }) {
  const [serviceInfo, setServiceInfo] = useState({
    description: "",
    serviceId: "",
    serviceName: "",
    serviceType: "S",
  });

  const onChangeProperty = (e) => {
    setServiceInfo({
      ...serviceInfo,
      [e.target.id]: e.target.value,
    });
  };

  const onConfirmNewService = (e) => {
    stopEvent(e);
    if (StringUtils.isEmpty(serviceInfo.serviceId)) {
      return Message.alert("서비스 ID를 입력해주세요.", Enums.MessageType.INFO);
    }
    if (StringUtils.isEmpty(serviceInfo.serviceName)) {
      return Message.alert("서비스 명을 입력해주세요.", Enums.MessageType.INFO);
    }
    if (StringUtils.isEmpty(serviceInfo.serviceType)) {
      return Message.alert(
        "서비스 유형을 입력해주세요.",
        Enums.MessageType.INFO
      );
    }

    if (callbackFnc) {
      callbackFnc(serviceInfo);
    }
  };
  return (
    <Modal>
      <Modal.Header title="신규 서비스 생성" />
      <Modal.Body>
        <UModalTemplate>
          <FormGroup>
            <Form.Label className="required">서비스 ID</Form.Label>
            <Form.Control
              className="mb-3"
              onChange={onChangeProperty}
              id={"serviceId"}
              value={serviceInfo.serviceId}
              placeholder="서비스 ID를 입력해주세요"
            />
            <Form.Label className="required">서비스 명</Form.Label>
            <Form.Control
              className="mb-3"
              onChange={onChangeProperty}
              id={"serviceName"}
              value={serviceInfo.serviceName}
              placeholder="서비스 명을 입력해주세요"
            />
            <Form.Label className="required">서비스 타입</Form.Label>
            <Form.Select
              className="mb-3"
              onChange={onChangeProperty}
              id={"serviceType"}
              value={serviceInfo.serviceType}
            >
              <option value="">선택</option>
              <option value="S">비지니스 플로우</option>
            </Form.Select>
            <Form.Label>서비스 설명</Form.Label>
            <UTextarea
              popTitle="서비스 설명"
              textareaId="description"
              onChange={onChangeProperty}
              defaultValue={serviceInfo.description}
              codeBox={false}
              rows={10}
            />
          </FormGroup>
        </UModalTemplate>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Button onClick={onConfirmNewService}>
          확인
        </Modal.Footer.Button>
      </Modal.Footer>
    </Modal>
  );
}

export default NewServicePopup;
