/*!
 * Default configuration for MUI Component with react v.17
 *
 * MUI 관련 default configuration들
 *
 *   Author: Bizentro
 *   Date: 2021-04
 */
const MuiConfig = {
  fontFamily: {
    fontFamily: "Noto Sans KR, sans-serif",
  },
  grid: {
    options: {
      pageSize: 5,
      rowsPerPageOptions: [5],
      rowHeight: 38,
      headerHeight: 38,
      sx: {
        fontFamily: "Noto Sans KR, sans-serif",
        fontSize: "13px",
      },
    },
  },
  card: {
    boxShadow: "rgb(0 0 0 / 19%) 0px 10px 20px, rgb(0 0 0 / 23%) 0px 6px 6px",
  },
  tabContainer: {
    container: {
      top: { sx: { borderBottom: 1, borderColor: "#bcb0b0" } },
      bottom: { sx: { borderBottom: 1, borderColor: "#bcb0b0" } },
      left: {
        sx: {
          flexGrow: 1,
          bgcolor: "background.paper",
          display: "flex",
          height: "100%",
        },
      },
      right: {
        sx: {
          flexGrow: 1,
          bgcolor: "background.paper",
          display: "flex",
          height: "100%",
        },
      },
    },
    tabs: {
      top: { sx: { minHeight: "32px" } },
      bottom: { sx: { minHeight: "32px" } },
      left: {
        sx: { marginRight: "10px", borderRight: 1, borderColor: "#bcb0b0" },
      },
      right: {
        sx: { marginRight: "10px", borderRight: 1, borderColor: "#bcb0b0" },
      },
    },
    tab: {
      top: {
        variant: "scrollable",
        scrollButtons: "auto",
        sx: {
          borderRight: 1,
          borderTop: 1,
          borderBottom: 1,
          borderLeft: 1,
          borderColor: "divider",
          minHeight: "32px",
          padding: "9px 9px",
          fontSize: "0.9rem",
        },
      },
      bottom: {
        variant: "scrollable",
        scrollButtons: "auto",
        sx: {
          borderRight: 1,
          borderTop: 1,
          borderBottom: 1,
          borderLeft: 1,
          borderColor: "divider",
          minHeight: "32px",
          padding: "9px 9px",
          fontSize: "0.9rem",
        },
      },
      left: {
        variant: "scrollable",
        scrollButtons: "auto",
        sx: {
          borderRight: 1,
          borderTop: 1,
          borderBottom: 1,
          borderLeft: 1,
          borderColor: "divider",
          minHeight: "32px",
          padding: "9px 9px",
          fontSize: "0.9rem",
          textAlign: "left",
          justifyContent: "flex-start",
        },
      },
      right: {
        variant: "scrollable",
        scrollButtons: "auto",
        sx: {
          borderRight: 1,
          borderTop: 1,
          borderBottom: 1,
          borderLeft: 1,
          borderColor: "divider",
          minHeight: "32px",
          padding: "9px 9px",
          fontSize: "0.9rem",
          textAlign: "left",
          justifyContent: "flex-start",
        },
      },
    },
  },
};

export default MuiConfig;
