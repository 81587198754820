import { Enums } from "components/builder/BuilderEnum";
import BuilderHeaderComponent from "components/builder/BuilderHeaderComponent";
import SettingMenu from "components/setting/SettingMenu";
import PageTemplate from "page/common/PageTemplate";
import React from "react";

const breadcrum = [
  {
    name: "설정",
    url: Enums.BuilderPath.SETTING,
    active: true,
  },
];

function Setting(props) {
  return (
    <>
      <BuilderHeaderComponent {...props.header} />
      <PageTemplate breadcrum={breadcrum}>
        <PageTemplate.Box boxClass="mb-0">
          <SettingMenu />
        </PageTemplate.Box>
      </PageTemplate>
    </>
  );
}

export default Setting;
