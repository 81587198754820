import { Enums } from "components/builder/BuilderEnum";
import BuilderHeader, {
  HeaderMapDispatchToProps,
  HeaderMapStateToProps,
} from "components/builder/BuilderHeader";
import BuilderToolbarButton from "components/builder/BuilderToolbarButton";
import {
  initCommand,
  initDo,
} from "components/builder/ui/reducers/CommandAction";
import WorkflowReduxHelper from "components/builder/workflow/editor/helper/WorkflowReduxHelper";
import {
  setCsFileInfoUpdate,
  updateService,
} from "components/builder/workflow/reducer/WorkflowAction";
import { AppContext } from "components/common/AppContextProvider";
import Message from "components/common/Message";
import Popup from "components/common/Popup";
import ObjectUtils from "components/common/utils/ObjectUtils";
import {
  UserShortKey,
  shortKeyBind,
} from "components/setting/section/shortKey/ShortKeyConfig";
import produce from "immer";
import CSFileListPopup from "page/popup/workflow/CSFileListPopup";
import CSFileSavePopup from "page/popup/workflow/CSFileSavePopup";
import NewServicePopup from "page/popup/workflow/NewServicePopup";
import ServiceSavePopup from "page/popup/workflow/ServiceSavePopup";
import WorkflowExportPopup from "page/popup/workflow/WorkflowExportPopup";
import WorkflowListPopupLoad from "page/popup/workflow/WorkflowListPopupLoad";
import { createRef } from "react";
import { ImFileEmpty } from "react-icons/im";
import { connect } from "react-redux";
import WorkflowService from "services/workflow/WorkflowService";
import { stopEvent } from "../ui/editor/handler/UIEditorEventHandler";
import { initDebug } from "./reducer/WorkflowDebugAction";

class WorkflowHeader extends BuilderHeader {
  constructor(props) {
    super(props);
    this.toolbarStyle = props.toolbarStyle || "menu";
    this.setDropdownEvent = this.setDropdownEvent.bind(this);
    this.openNew = this.openNew.bind(this);
    this.openSave = this.openSave.bind(this);
    this.openDeployPopup = this.openDeployPopup.bind(this);
    this.onDeploy = this.onDeploy.bind(this);
    this.export = this.export.bind(this);
    this.openCsharpFileEdit = this.openCsharpFileEdit.bind(this);

    this.open = this.open.bind(this);

    this.state = {
      newWorkflowDropdown: false,
      saveDropdown: false,
    };
  }

  static contextType = AppContext;
  inputFileRef = createRef();

  /**
   * 새 이벤트 만들기
   */
  openNew() {
    const callbackFnc = (serviceInfo) => {
      this.props.createNewService(serviceInfo, this.props.workflow);
      Popup.close();
    };

    const options = {
      effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
      style: {
        content: {
          width: "600px",
        },
      },
    };
    Popup.open(<NewServicePopup callbackFnc={callbackFnc} />, options);
  }

  /**
   * 저장 팝업
   * @param {*} e
   * @param {*} saveAs
   */
  openSave(e, saveAs) {
    stopEvent(e);

    const isCsFileOpen = this.props.workflow.CSFileViewMode;
    if (isCsFileOpen) {
      const callback = (fileInfo) => {
        Message.alert("저장 되었습니다.", Enums.MessageType.SUCCESS);
        Popup.close();
        fileInfo.serviceList = fileInfo.serviceList.map((service) =>
          WorkflowService.setCsFileServiceToWorkflowData(
            service,
            this.props.workspace
          )
        );
        this.props.setCsFileInfo(fileInfo);
      };

      Popup.open(
        <CSFileSavePopup
          fileInfo={this.props.workflow.CSFileInfo}
          workspace={this.props.workspace}
          callback={callback}
        />,
        {
          effect: Popup.ScaleUp,
          style: {
            content: {
              width: "600px",
            },
          },
        }
      );
    } else {
      const onSaveService = (body, cb) => {
        WorkflowService.saveService(
          body,
          (res) => {
            if (!res.isError) {
              const resData = produce(res.data, (draft) => {
                draft.prevService = this.props.workflow.prevService;
              });
              this.props.updateService(resData);
              WorkflowService.localStorageSave(res.data);
              Message.alert(
                "서비스가 저장되었습니다.",
                Enums.MessageType.SUCCESS
              );
              cb();
              Popup.close();
            }
          },
          cb
        );
      };
      Popup.open(
        <ServiceSavePopup
          saveAs={saveAs}
          callbackFnc={onSaveService}
          workflow={this.props.workflow}
          workspace={this.props.workspace}
        />,
        {
          effect: Popup.ScaleUp,
          style: {
            content: {
              width: "600px",
            },
          },
        }
      );
    }
  }

  /**
   * 불러오기 버튼 클릭
   * @param {Event} event
   */
  open = (event) => {
    if (!this.controllEvent({ mode: Enums.EditMode.EDITOR })) return false;
    /**
     * TO-DO 작업중이거나 변경된게 있으면 경고 메시지 후 진행되도록 보완 필요
     */
    if (event) event.preventDefault();
    //팝업창 열기
    const popupTitle = <>workflow 불러오기</>;
    const options = {
      effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
      style: {
        content: {
          width: "70%",
        },
      },
    };

    Popup.open(
      <WorkflowListPopupLoad
        workspace={this.context.workspace.Info}
        title={popupTitle}
        callbackFnc={this.callbackLoadFnc}
      />,
      options
    );
  };

  /**
   * 불러오기 Popup Callback function
   * @param {*} args editor-data
   * **/
  callbackLoadFnc = (args) => {
    try {
      WorkflowService.getService(
        args,
        (res) => {
          const serviceDetail = WorkflowService.setData(res.data);
          this.props.updateService(serviceDetail);
          // this.props.initDebug();
          // this.props.initCommand();
          Message.alert(
            `${serviceDetail.serviceUid}번 프로그램을 불러왔습니다.`,
            Enums.MessageType.SUCCESS
          );
        },
        () => {
          throw new Error("프로그램을 호출 중 오류가 발생하였습니다.");
        }
      );
    } catch (e) {
      Message.alert(e, Enums.MessageType.ERROR);
    }
    Popup.close();
  };

  /**
   * 드롭다운 이벤트
   * @param {*} _state
   * @param {*} _buttonId
   * @param {*} editorScreenOnly
   * @returns
   */
  setDropdownEvent(_state, _buttonId, editorScreenOnly = true) {
    /**
     * @param {Event} event
     * @returns
     */
    const showDropdown = (event) => {
      event.preventDefault();

      const newState = {
        newWorkflowDropdown: false,
        saveDropdown: false,
      };
      newState[_state] = !this.state[_state];

      if (newState[_state]) {
        this.setState(newState, () => {
          document.addEventListener("click", closeDropdown);
        });
      } else {
        this.setState(newState, () => {
          document.removeEventListener("click", closeDropdown);
        });
      }
    };

    /**
     * dropdown menu close event
     * @param {Event} event
     */
    const closeDropdown = (event) => {
      const {
        target: {
          dataset: { buttonId },
        },
      } = event;
      if (
        buttonId !== _buttonId &&
        this.dropdownMenu != null &&
        !this.dropdownMenu.contains(event.target)
      ) {
        const newState = produce(this.state, (draft) => {
          draft[_state] = false;
        });
        this.setState(newState, () => {
          document.removeEventListener("click", closeDropdown);
        });
      }
    };

    return showDropdown;
  }

  getButtonClass = () => {
    if (this.getNowScreenEditor()) {
      return this.getEditorMode() === Enums.EditMode.PREVIEW ? "disabled" : "";
    } else {
      return "disabled";
    }
  };

  /**
   * 현재 보고 있는 화면이 에디터용 화면인지 확인
   * **/
  getNowScreenEditor() {
    return window.location.pathname.indexOf("/workflow") > -1;
  }

  /**
   * editor mode , editor, preview
   * @returns
   */
  getEditorMode = () => {
    return window.location.pathname === Enums.BuilderPath.PREVIEW
      ? Enums.EditMode.PREVIEW
      : Enums.EditMode.EDITOR;
  };

  /**
   * toolbar button event controll
   * - editor mode에만 허용된 기능
   * - output data가 있을 경우에만 허용되는 기능
   * @param {*} restric
   * @returns
   */
  controllEvent = (validation) => {
    if (!this.getNowScreenEditor()) return false;
    let isValid = true;
    for (const key in validation) {
      if (key === "mode") {
        if (validation[key] !== this.getEditorMode()) {
          isValid = false;
          break;
        }
      } else if (key === "checkOutput") {
        if (
          ObjectUtils.isEmpty(this.props.output) ||
          ObjectUtils.isEmpty(this.props.output.page)
        ) {
          Message.alert("Data가 존재하지 않습니다.", Enums.MessageType.ERROR);
          isValid = false;
          break;
        }
      }
    }

    return isValid;
  };

  /**
   * 워크플로우 배포
   * @param {*} body
   * @param {*} cb
   */
  onDeploy(body, cb) {
    this.props.deploy(body, cb);
  }

  openDeployPopup() {
    this.props.deployPopup();
  }

  /**
   * 내보내기 버튼 클릭
   * @param {Event} event
   */
  export = (event) => {
    if (event) event.preventDefault();
    const { serviceUid, tenantId, coCd } = this.props.workflow.serviceInfo;
    if (!serviceUid) {
      return Message.alert(
        "저장된 프로그램이 아닙니다. 먼저 저장해주세요",
        Enums.MessageType.INFO
      );
    }
    if (tenantId !== "*" || coCd !== "*") {
      return Message.alert(
        "표준 프로그램만 내보내기가 가능합니다.",
        Enums.MessageType.INFO
      );
    }

    const options = {
      effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
      style: {
        content: {
          width: "350px", //popup의 크기를 50% (default 60%)
        },
      },
    };
    Popup.open(
      <WorkflowExportPopup
        appList={this.context.application.availableList}
        workflow={this.props.workflow.serviceInfo}
      />,
      options
    );
  };

  componentDidMount() {
    this.headerInit();
  }

  componentWillUnmount() {
    this.unmountListener();
  }

  mapKeyToHeader = (event) => {
    shortKeyBind(event, Enums.ShortKeyDefine.NEW_TEMP, this.openNew);
    shortKeyBind(event, Enums.ShortKeyDefine.SAVE, this.openSave);
  };

  /**
   * CS파일 편집 버튼 클릭
   * 목록 표시 및 CS 파일 업로드
   * @param {*} e
   */
  openCsharpFileEdit = (e) => {
    stopEvent(e);
    const workspace = this.props.workspace;

    const uploadCallback = (data = [], fileInfo) => {
      const wAttachCsFileInfo = {
        ...fileInfo,
        serviceList: [],
      };
      wAttachCsFileInfo.serviceList = data.map((service) =>
        WorkflowService.setCsFileServiceToWorkflowData(service, workspace)
      );
      this.props.setCsFileInfo(wAttachCsFileInfo);
    };

    const loadCallback = (data) => {
      //converLog 부분은 파싱해줘야함
      const fileInfo = produce(data, (draft) => {
        draft.serviceList = draft.serviceList.map((service) => {
          return WorkflowService.setCsFileServiceToWorkflowData(
            service,
            workspace
          );
        });
      });
      this.props.setCsFileInfo(fileInfo);
      Popup.close();
    };

    Popup.open(
      <CSFileListPopup
        workspace={this.props.workspace}
        uploadCallback={uploadCallback}
        loadCallback={loadCallback}
      />,
      { style: { content: { width: "800px" } } }
    );
  };

  renderHeader() {
    const newDropdownEvent = this.setDropdownEvent(
      "newWorkflowDropdown",
      "empty"
    );
    const saveDropdownEvent = this.setDropdownEvent("saveDropdown", "edit");

    //CS FILE 업로드 여부
    const isCsFileOpen = this.props.workflow.CSFileViewMode;
    return (
      <>
        <li>
          <BuilderToolbarButton
            icon={<ImFileEmpty size={18} />}
            text=" 신규 서비스 "
            caret={true}
            buttonId="empty"
            onClick={newDropdownEvent}
            style={this.toolbarStyle}
            iconOnly={this.state.mini}
            tooltipTitle={"신규 서비스 "}
          />

          {this.state.newWorkflowDropdown ? (
            <>
              <div
                className="toolbar-dropdown"
                ref={(element) => {
                  this.dropdownMenu = element;
                }}
              >
                <ul>
                  <li
                    className="dropdown-item"
                    role="button"
                    onClick={this.openNew}
                    style={{ width: "250px" }}
                  >
                    새로운 서비스 생성 (Blank)
                    <span>Alt + N</span>
                  </li>
                </ul>
                <li
                  className="dropdown-item"
                  role="button"
                  onClick={this.openCsharpFileEdit}
                  style={{ width: "250px" }}
                >
                  C# 파일 편집
                </li>
              </div>
            </>
          ) : null}
        </li>
        <li>
          <BuilderToolbarButton
            style={this.toolbarStyle}
            className={this.getButtonClass()}
            buttonId="open"
            onClick={this.open}
            iconOnly={this.state.mini}
            tooltipTitle={`불러오기 ${UserShortKey(Enums.ShortKeyDefine.LOAD)}`}
          />
        </li>
        <li>
          <BuilderToolbarButton
            style={this.toolbarStyle}
            buttonId="edit"
            onClick={saveDropdownEvent}
            iconOnly={this.state.mini}
            tooltipTitle={"편집"}
          />
          {this.state.saveDropdown ? (
            <div
              className="toolbar-dropdown"
              ref={(element) => {
                this.dropdownMenu = element;
              }}
            >
              <ul>
                {isCsFileOpen ? (
                  <li
                    className="dropdown-item"
                    role="button"
                    onClick={this.openSave}
                    style={{ width: "200px" }}
                  >
                    파일 저장
                    <span>Alt + S</span>
                  </li>
                ) : (
                  <>
                    <li
                      className="dropdown-item"
                      role="button"
                      onClick={this.openSave}
                      style={{ width: "200px" }}
                    >
                      저장
                      <span>Alt + S</span>
                    </li>
                    <li
                      className="dropdown-item"
                      role="button"
                      onClick={(e) => this.openSave(e, true)}
                      style={{ width: "200px" }}
                    >
                      다른 이름으로 저장
                    </li>
                    <li
                      className="dropdown-item"
                      role="button"
                      onClick={this.export}
                    >
                      내보내기
                    </li>
                  </>
                )}
              </ul>
            </div>
          ) : null}
        </li>
        {!isCsFileOpen && (
          <li>
            <BuilderToolbarButton
              style={this.toolbarStyle}
              buttonId="deploy"
              text=" 서비스  배포"
              onClick={this.openDeployPopup}
              iconOnly={this.state.mini}
              tooltipTitle={"서비스 배포"}
            />
          </li>
        )}
      </>
    );
  }
}

const dispatchProps = (dispatch) => {
  return {
    createNewService: (serviceInfo, workflow) => {
      dispatch(initDo());
      WorkflowReduxHelper.createNewService(dispatch, serviceInfo, workflow);
    },
    setCsFileInfo: (fileInfo) => {
      dispatch(initDebug());
      dispatch(initCommand());
      dispatch(setCsFileInfoUpdate(fileInfo));
    },
    updateService: (newWorkflow, workflow) => {
      dispatch(updateService(newWorkflow, workflow));
    },
    ...HeaderMapDispatchToProps(dispatch),
  };
};

export default connect((state) => {
  return {
    workflow: state.workflow,
    workspace: state.workspace,
    ...HeaderMapStateToProps(state),
  };
}, dispatchProps)(WorkflowHeader);
