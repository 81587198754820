import React, { useEffect, useState, useRef } from "react";
import { CircularProgress, Grid } from "@mui/material";
import { Button, Form, InputGroup } from "react-bootstrap";
import Message from "components/common/Message";
import { Enums } from "components/builder/BuilderEnum";
import StringUtils from "components/common/utils/StringUtils";
import Modal from "components/common/modal/UModal";
import Blockly from "blockly";
import WijmoGrid from "components/common/element/WijmoGrid";
import { BsPencilSquare } from "react-icons/bs";

function BlockSearchPopup({ searchBlock, onDataSearch, addSelectBlock }) {
  const [searchTerm, setSearchTerm] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [addSearchBlock, setAddSearchBLock] = useState([]);
  const popupWorkspace = useRef();

  // const [blockRow, setBlockRow] = useState([]);
  // const columns = [
  //   {
  //     field: "category",
  //     headerName: "Category",
  //     headerAlign: "center",
  //     width: 150,
  //     align: "left",
  //   },
  //   {
  //     field: "blockNm",
  //     headerName: "Block 이름",
  //     headerAlign: "center",
  //     align: "left",
  //     width: 150,
  //   },
  //   {
  //     field: "blockTooltip",
  //     headerName: "Block 설명",
  //     headerAlign: "center",
  //     width: "*",
  //   },
  //   {
  //     field: "blockMessage",
  //     headerName: "Block 코드",
  //     headerAlign: "center",
  //     width: "*",
  //   },
  // ];

  // function onRowDoubleClick(blockItem) {
  //   console.log(blockItem);
  //   searchBlock();
  // }

  function enterSearch(e) {
    if (e.keyCode === 13) {
      onSearch();
    }
  }

  function onSearch() {
    if (StringUtils.isEmpty(searchTerm)) {
      return Message.alert("검색어를 입력해주세요.", Enums.MessageType.INFO);
    }
    setIsLoading(true);
    const result = onDataSearch(searchTerm);
    if (result.length > 0) {
      setAddSearchBLock(result);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      return Message.alert("검색된 데이터가 없습니다.", Enums.MessageType.INFO);
    }
  }

  function addBlockToMain() {
    const blockList = popupWorkspace.current.getAllBlocks(true);
    let typeList = [];
    blockList.map((block) => {
      typeList.push(block.type);
    });
    addSelectBlock(typeList);
    searchBlock();
  }

  return (
    <Modal>
      <Modal.Header title="Block 검색" />
      <Modal.Body>
        <Grid item xs={12}>
          <Form.Group>
            <Form.Label className="required">검색어 입력</Form.Label>
            <InputGroup>
              <Form.Control
                size="sm"
                placeholder="검색어를 입력해주세요."
                value={searchTerm || ""}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyDown={enterSearch}
              />
              {isLoading ? (
                <Button disabled={true}>
                  <CircularProgress color="inherit" size={13} /> 검색 중
                </Button>
              ) : (
                <Button onClick={onSearch}>검색</Button>
              )}
            </InputGroup>
          </Form.Group>
        </Grid>
        {addSearchBlock.length > 0 && (
          <Form.Group>
            <Form.Label>검색 결과</Form.Label>
            <FlyoutToolbox
              addSearchBlock={addSearchBlock}
              setIsLoading={setIsLoading}
              popupWorkspace={popupWorkspace}
            ></FlyoutToolbox>
          </Form.Group>

          // <WijmoGrid
          //   columns={columns}
          //   rows={blockRow}
          //   onRowDoubleClick={onRowDoubleClick}
          //   style={{ height: "60vh" }}
          // />
        )}
      </Modal.Body>
      <Modal.Footer>
        {addSearchBlock.length > 0 && (
          <Modal.Footer.Button onClick={addBlockToMain}>
            <BsPencilSquare />
            &nbsp;추가
          </Modal.Footer.Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default BlockSearchPopup;

function FlyoutToolbox({ addSearchBlock, setIsLoading, popupWorkspace }) {
  const toolboxList = {
    kind: "flyoutToolbox",
    contents: [],
  };
  const blocklyDiv = useRef();

  useEffect(() => {
    popupWorkspace.current = Blockly.inject(blocklyDiv.current, {
      toolbox: toolboxList,
    });
  }, []);

  useEffect(() => {
    if (addSearchBlock.length > 0) {
      let blockList = [];
      addSearchBlock.map((v) => {
        blockList.push(v.block);
      });

      popupWorkspace.current.getFlyout(true).show(blockList);
      popupWorkspace.current.getFlyout(true).setContainerVisible(true);
      popupWorkspace.current.getFlyout(true).getFlyoutScale();
      setIsLoading(false);
    }
  }, [addSearchBlock]);

  return (
    <div>
      <div
        id="blocklyDiv"
        ref={blocklyDiv}
        style={{ height: `calc(50vh)`, width: `calc(100vh + 5px)` }}
      ></div>
    </div>
  );
}
