import { CircularProgress, TextField } from "@mui/material";
import { Enums } from "components/builder/BuilderEnum";
import { initCommand } from "components/builder/ui/reducers/CommandAction";
import { updateService } from "components/builder/workflow/reducer/WorkflowAction";
import { AppContext } from "components/common/AppContextProvider";
import MuiConfig from "components/common/config/MuiConfig";
import Message from "components/common/Message";
import CommonUtils, {
  ArrayUtils,
  SessionUtils,
} from "components/common/utils/CommonUtils";
import ObjectUtils from "components/common/utils/ObjectUtils";
import User from "components/common/utils/UserUtils";
import PageTemplate from "page/common/PageTemplate";
import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { BiEditAlt } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import { RiDeleteBinLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ProgramService from "services/ui/ProgramService";
import WorkflowService from "services/workflow/WorkflowService";
import WijmoGrid from "components/common/element/WijmoGrid";
import { initDebug } from "components/builder/workflow/reducer/WorkflowDebugAction";
import { setWorkflowSearchTerm } from "components/builder/ui/reducers/MenuAction";
import { RxOpenInNewWindow } from "react-icons/rx";
import LocalStorageService from "services/common/LocalService";
import Popup from "components/common/Popup";
import WorkflowReferencePopup from "page/popup/workflow/WorkflowReferencePopup";
import { VscReferences } from "react-icons/vsc";

const WorkflowList = (props) => {
  const workspace = useSelector((state) => state.workspace);
  const workflowSearchTerm = useSelector(
    (state) => state.menu.workflowSearchTerm
  );
  const [isLoading, setIsLoading] = useState(false);
  const [serviceUid, setServiceUid] = useState(
    workflowSearchTerm.serviceUid || null
  );
  const [serviceId, setServiceId] = useState(
    workflowSearchTerm.serviceId || ""
  );
  const [serviceName, setServiceName] = useState(
    workflowSearchTerm.serviceName || ""
  );
  const [workerId, setWorkerId] = useState(workflowSearchTerm.workerId || "");
  const [serviceType, setServiceType] = useState("");
  const [serviceList, setServiceList] = useState([]);
  const [pageSize, setPageSize] = useState(20);
  const [isDeployLoading, setIsDeployLoading] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    workspace: { openPopup },
    connection: { openPopup: connectionOpenPopup },
  } = useContext(AppContext);

  useEffect(() => {
    getServiceList();
  }, []);

  const breadcrum = [
    {
      name: "워크플로우 ( 서비스 ) 목록",
      url:
        Enums.BuilderPath.WORKFLOW.MAIN + "/" + Enums.BuilderPath.WORKFLOW.LIST,
      active: true,
    },
  ];

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      onSearch();
    }
  };

  const onSearch = () => {
    const body = {
      serviceId: serviceId,
      serviceName: serviceName,
      workerId: workerId,
      serviceUid,
    };
    dispatch(setWorkflowSearchTerm(body));
    getServiceList();
  };

  const getServiceList = () => {
    setIsLoading(true);
    const body = {
      // moduleCd: moduleCd === "*" ? "" : moduleCd,
      appId: workspace.appId,
      moduleCd: workspace.moduleCd,
      appReleaseId: workspace.appReleaseId,
      tenantId: workspace.tenantId,
      coCd: workspace.coCd,
      serviceUid,
      workerId,
      serviceId,
      serviceName,
      serviceType,
    };
    WorkflowService.getServiceList(
      body,
      (res) => {
        if (!res?.isError) {
          const { data } = res;
          const list = ProgramService.filterByTenantIdAndCoCd(
            data,
            body.tenantId,
            body.coCd,
            "serviceId"
          );
          setIsLoading(false);
          setServiceList(list);
        }
      },
      () => {
        setIsLoading(false);
      }
    );
  };

  /**
   * 서비스 상세 & 수정
   * @param {*} service
   */
  const onEditService = (service) => {
    WorkflowService.getService(service, (res) => {
      const serviceDetail = WorkflowService.setData(res.data);
      dispatch(updateService(serviceDetail));
      dispatch(initDebug());
      dispatch(initCommand());
      navigate(
        Enums.BuilderPath.WORKFLOW.MAIN +
          "/" +
          Enums.BuilderPath.WORKFLOW.BUILDER
      );
    });
  };

  /**
   * 서비스 삭제
   * @param {*} service
   */
  const onDeleteService = (service) => {
    Message.confirm("서비스를 삭제하시겠습니까?", async () => {
      WorkflowService.deleteService(service, (res) => {
        getServiceList();
      });
    });
  };

  /**
   * 참조 목록 오픈
   * @param {*} service
   */
  const onOpenReferencePopup = (service) => {
    Popup.open(<WorkflowReferencePopup serviceInfo={service} />, {
      style: { content: { width: "800px" } },
    });
  };

  /**
   * 서비스 배포
   * @param {*} service
   */
  const onDeployService = (service) => {
    Message.confirm(`${service.serviceName}을 배포 하시겠습니까?`, () => {
      setIsDeployLoading(service.serviceUid);
      const deployConnection = User.getConnection(workspace.tenantMstId);
      if (!deployConnection?.token) {
        connectionOpenPopup();
        setIsDeployLoading(null);
        return Message.alert(
          "인증 서버 토큰이 확인 되지 않았습니다.\n 다시 연결 해주세요.",
          Enums.MessageType.ERROR
        );
      }
      //userId가 deploy서버 connectionID랑 param 명이 같기에 별도로 분리
      const { userId, ...connectionInfo } = deployConnection;

      WorkflowService.getService(
        service,
        (res) => {
          const body = {
            ...res.data,
            ...connectionInfo,
            connectionUserId: userId,
          };
          if (deployConnection.connectionType === Enums.ConnectionType.PROXY) {
            WorkflowService.deployService(
              body,
              (res) => {
                setIsDeployLoading(null);
                if (!res.isError) {
                  Message.alert(
                    "서비스가 배포되었습니다.",
                    Enums.MessageType.SUCCESS
                  );
                }
              },
              () => setIsDeployLoading(null)
            );
          } else if (
            deployConnection.connectionType === Enums.ConnectionType.DIRECT
          ) {
            const dBody = {
              ...res.data,
              resourceWf: body.serviceContent,
              connectionInfo,
              userId,
            };
            WorkflowService.deployDirectService(
              dBody,
              (res) => {
                setIsDeployLoading(null);
                Message.alert(
                  "서비스가 저장 및 배포되었습니다.",
                  Enums.MessageType.SUCCESS
                );
                setIsDeployLoading(null);
              },
              (err) => {
                setIsDeployLoading(null);
              }
            );
          }
        },
        () => setIsDeployLoading(null)
      );
    });
  };

  /**
   * 새탭으로 열기
   * @param {*} workflow
   */
  const onOpenNewTab = (workflow) => {
    LocalStorageService.set(Enums.LocalStorageName.WORKSPACE, {
      userId: User.getId(),
      workspace: workspace,
    });
    const url = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;
    window.open(
      `${url}/newTabRedirect/workflow/${workflow.serviceUid}`,
      "_blank"
    );
  };

  const columns = [
    {
      field: "tenantId",
      headerName: "테넌트 ID",
      width: 120,
    },
    {
      field: "coCd",
      headerName: "회사 코드",
      width: 120,
    },
    {
      field: "serviceUid",
      headerName: "서비스 UID",
      width: 130,
      align: "left",
    },
    {
      field: "serviceId",
      headerName: "서비스 ID",
      width: 150,
    },
    {
      field: "serviceName",
      headerName: "서비스 명",
      width: "*",
    },
    {
      field: "description",
      headerName: "서비스 설명",
      width: "*",
    },
    {
      field: "updtUserId",
      headerName: "최종 수정자",
      width: 130,
    },
    {
      field: "updtDt",
      headerName: "최종 수정일",
      width: 150,
      renderCell: (params) =>
        `${CommonUtils.getDate(params.updtDt, "datetime")}`,
    },
    {
      field: "serviceEdit",
      headerName: "편집",
      headerAlign: "center",
      width: 80,
      align: "center",
      renderCell: (params) => {
        return (
          <Button
            size="sm"
            variant="outline-success"
            onClick={(e) => onEditService(params)}
          >
            <BiEditAlt />
          </Button>
        );
      },
    },
    {
      field: "serviceRef",
      headerName: "참조",
      headerAlign: "center",
      width: 80,
      align: "center",
      renderCell: (params) => {
        return (
          <Button
            size="sm"
            variant="outline-secondary"
            onClick={(e) => onOpenReferencePopup(params)}
          >
            <VscReferences />
          </Button>
        );
      },
    },
    {
      field: "serviceDelete",
      headerName: "삭제",
      headerAlign: "center",
      width: 80,
      align: "center",
      renderCell: (params) => {
        return (
          <Button
            size="sm"
            variant="outline-danger"
            onClick={(e) => onDeleteService(params)}
          >
            <RiDeleteBinLine />
          </Button>
        );
      },
    },
    {
      field: "serviceDeploy",
      headerName: "배포",
      headerAlign: "center",
      width: 80,
      align: "center",
      renderCell: (params) => {
        if (isDeployLoading === params.serviceUid) {
          return (
            <Button size="sm" variant="outline-primary">
              <CircularProgress color="inherit" size={13} />
            </Button>
          );
        } else {
          return (
            <Button
              size="sm"
              variant="outline-primary"
              onClick={(e) => onDeployService(params)}
            >
              <AiOutlineCloudUpload />
            </Button>
          );
        }
      },
    },
    {
      field: "newTab",
      headerName: "새 탭",
      headerAlign: "center",
      width: 80,
      align: "center",
      renderCell: (params) => {
        return (
          <Button
            size="sm"
            variant="outline-secondary"
            onClick={() => onOpenNewTab(params)}
          >
            <RxOpenInNewWindow />
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <PageTemplate breadcrum={breadcrum}>
        <PageTemplate.Box boxClass="mb-0">
          {ObjectUtils.isEmpty(workspace) ? (
            <div className="workspace-empty-alert">
              <div className="alert-msg">워크 스페이스 설정이 필요 합니다.</div>
              <Button onClick={() => openPopup()}>팝업 열기</Button>
            </div>
          ) : (
            <>
              <Form>
                <Row className="pb-3 pt-3">
                  <Col xs={2}>
                    <TextField
                      size={"small"}
                      label="서비스 UID"
                      placeholder="서비스 UID 검색..."
                      value={serviceUid}
                      fullWidth={true}
                      onChange={(e) => setServiceUid(e.currentTarget.value)}
                      onKeyDown={onKeyDown}
                      type={"number"}
                    />
                  </Col>
                  <Col xs={2}>
                    <TextField
                      size={"small"}
                      label="서비스 ID"
                      placeholder="서비스 ID 검색..."
                      value={serviceId}
                      fullWidth={true}
                      onChange={(e) => setServiceId(e.currentTarget.value)}
                      onKeyDown={onKeyDown}
                    />
                  </Col>
                  <Col xs={2}>
                    <TextField
                      size="small"
                      label="서비스 명"
                      placeholder="서비스 명"
                      value={serviceName}
                      onChange={(e) => setServiceName(e.currentTarget.value)}
                      onKeyDown={onKeyDown}
                      fullWidth
                    />
                  </Col>
                  <Col xs={2}>
                    <TextField
                      size="small"
                      label="최종 수정자 ID"
                      placeholder="최종 수정자 ID"
                      value={workerId}
                      onChange={(e) => setWorkerId(e.currentTarget.value)}
                      onKeyDown={onKeyDown}
                      fullWidth
                    />
                  </Col>
                  <Col xs={1}>
                    <Button variant="primary" onClick={onSearch}>
                      검색 <BsSearch size="14" />
                    </Button>
                  </Col>
                </Row>
              </Form>
              <Row style={{ height: "calc(100% - 70px)" }}>
                <Col xs={12}>
                  <WijmoGrid
                    isLoading={isLoading}
                    columns={columns}
                    rows={serviceList}
                    onRowDoubleClick={(params) => onEditService(params)}
                    getRowId={(row) => row.serviceUid}
                    {...MuiConfig.grid.options}
                    rowsPerPageOptions={[pageSize]}
                    pageSize={pageSize}
                    style={{ height: "70vh" }}
                  />
                </Col>
              </Row>
            </>
          )}
        </PageTemplate.Box>
      </PageTemplate>
    </>
  );
};

export default WorkflowList;
