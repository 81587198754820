import React, { Component } from "react";
import PropTypes from "prop-types";
import CommandButton from "components/builder/CommandButton";
import { connect } from "react-redux";
import ObjectUtils from "components/common/utils/ObjectUtils";
import { redo, undo } from "../ui/reducers/CommandAction";
import { updateErd } from "./reducers/ErdAction";

class ErdCommandButton extends CommandButton {
  constructor(props) {
    super(props);
  }

  setRightSideData() {
    if (!ObjectUtils.isEmpty(this.props.erd.activatedErd)) {
      return `${this.props.erd.erdInfo.erdName} -  ${this.props.erd.activatedErd.name}`;
    } else {
      return "";
    }
  }

  //@override
  undo() {
    const { prev, undoIndex } = this.props.command;
    const prevRed = { ...prev.at(undoIndex) };
    this.props.undo(prevRed);
  }

  redo() {
    const { undoIndex, latest } = this.props.command;
    const erd = { ...latest.at(undoIndex + 1) };
    this.props.redo(erd);
  }
}

ErdCommandButton.propTypes = {};

export default connect(
  (state) => {
    return {
      command: state.command,
      erd: state.erd,
    };
  },
  (dispatch) => {
    return {
      undo: (prevErd) => {
        dispatch(updateErd(prevErd));
        dispatch(undo());
      },
      redo: (erd) => {
        dispatch(updateErd(erd));
        dispatch(redo());
      },
    };
  }
)(ErdCommandButton);
