import { CircularProgress, Tooltip } from "@mui/material";
import { Enums } from "components/builder/BuilderEnum";
import ModuleSelector from "components/common/element/ModuleSelector";
import Message from "components/common/Message";
import Modal from "components/common/modal/UModal";
import UmodalTemplate from "components/common/modal/UModalTemplate";
import Popup from "components/common/Popup";
import CommonUtils, {
  ArrayUtils,
  JsonUtils,
  ObjectUtils,
  StringUtils,
} from "components/common/utils/CommonUtils";
import React from "react";
import { useState } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import AppService from "services/common/AppService";
import DataModelService from "services/datamodel/DataModelService";
import ProgramService from "services/ui/ProgramService";
import WorkflowService from "services/workflow/WorkflowService";

function AppReleasePopup({
  appList = [],
  callBack,
  moduleCreatePopup,
  ...props
}) {
  const [selectedApp, setSelectedApp] = useState("");
  const [selectedModule, setSelectedModule] = useState("");
  const [fromVersion, setFromVersion] = useState("");
  const [version, setVersion] = useState("");
  const [briefDesc, setBriefDesc] = useState("");
  const [releaseNotes, setReleaseNotes] = useState("");

  const [fromVersionList, setFromVersionList] = useState([]);

  const [isExpandModelSearch, setIsExpandModelSearch] = useState(false);
  const [optionIn, setOptionIn] = useState("*");
  const [optionNotIn, setOptionNotIn] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("로딩 중...");

  const onChangeApp = (e) => {
    setSelectedApp(e.currentTarget.value);
    setIsExpandModelSearch(false);
    setSelectedModule("");
    setOptionIn("");
    setOptionNotIn("");
  };

  const onChangeModule = (e) => {
    setSelectedModule(e.target.value);
    if (e.target.value) {
      setIsExpandModelSearch(true);
    }
    //기존에 정해진 prefix있으면 가져옴
    AppService.getAppModule(
      { moduleCd: e.target.value, appId: selectedApp },
      (res) => {
        if (res.data) {
          const moduleOption = JSON.parse(res.data.moduleOption);
          setOptionIn(moduleOption.in.toString());
          setOptionNotIn(moduleOption.notIn.toString());
        } else {
          setOptionIn(e.target.value);
          setOptionNotIn("");
        }
      }
    );
    //참조할 버전 목록
    AppService.getAvailableAppReleaseList(
      { appId: selectedApp, moduleCd: e.target.value },
      (res) => {
        setFromVersionList(res.data);
      }
    );
  };

  const onChangeTargetVersion = (e) => {
    setFromVersion(e.target.value);
  };
  const onGetReleaseNotes = (e) => {
    setIsLoading(true);
    setLoadingMessage("개정 내용을 취합 하고 있습니다.");
    const body = {
      appId: selectedApp,
      moduleCd: selectedModule,
      appReleaseId: fromVersion,
      tenantId: "*",
      coCd: "*",
    };

    ProgramService.getProgramHistoryCommentList(
      body,
      (res) => {
        let releaseNotesText = "▶ 프로그램 목록\n";
        const historyMstList = res.data;
        if (historyMstList) {
          for (const historymst of historyMstList) {
            releaseNotesText += `\t● ${historymst.programId}\n`;
            if (historymst.commentList.length > 0) {
              for (const comment of historymst.commentList) {
                if (!StringUtils.isEmpty(comment.commitComment)) {
                  releaseNotesText += `\t\t - ${comment.commitComment}  |  ${
                    comment.updtUserId
                  }  |  ${CommonUtils.getDate(comment.updtDt)} \n`;
                }
              }
            }
          }
          setReleaseNotes(releaseNotesText);
        }
        setIsLoading(false);
        setLoadingMessage("로딩 중...");
      },
      () => {
        setIsLoading(false);
      }
    );
  };

  const onSaveRelease = (e) => {
    if (e) e.preventDefault();
    if (!selectedApp)
      return Message.alert(
        "Application을 선택해주세요",
        Enums.MessageType.WARN
      );
    if (!selectedModule)
      return Message.alert("모듈을 선택해주세요", Enums.MessageType.WARN);
    if (!version)
      return Message.alert("버전을 선택해주세요", Enums.MessageType.WARN);
    if (!briefDesc)
      return Message.alert("버전 설명을 입력해주세요.", Enums.MessageType.WARN);
    if (!releaseNotes)
      return Message.alert("개정 내용을 입력해주세요.", Enums.MessageType.WARN);

    const body = {
      appId: selectedApp,
      moduleCd: selectedModule,
      moduleOption: JSON.stringify({
        in: StringUtils.isEmpty(optionIn.replace(/ /g, ""))
          ? ["*"]
          : optionIn.replace(/ /g, "").split(","),
        notIn: optionNotIn.replace(/ /g, "").split(","),
      }),
      useYn: "Y",
      version,
      fromVersion,
      briefDesc,
      releaseNotes,
    };
    //1. APP 저장
    //2. 데이터 모델 복사
    //3. 데이터 모델 가져오고
    //4. 프로그램 목록 가져옴
    //5. 각 프로그램 내용에 변경된 데이터 모델 내용 적용
    //6. 변경된 프로그램 목록 저장
    //7. 완료

    //실패시 롤백
    setIsLoading(true);
    setLoadingMessage("Application을 생성합니다.");
    let newApp = {};
    let newDataModelObject = {};
    let newProgramList = [];
    let from = null;
    if (fromVersion) {
      from = fromVersionList.find((a) =>
        StringUtils.equalsIgnoreType(a.appReleaseId, fromVersion)
      );
    }

    //1. APP 저장
    saveRelease(body)
      .then((app) => {
        newApp = app;
        //2. 데이터 모델 복사 및 복사 목록 가져오기
        if (from) {
          return succeedDataModelList(app, from);
        } else {
          //참조 버전이 없으면 초기화 하고 종료
          callBack();
          Popup.close();
        }
      })
      .then((data) => {
        newDataModelObject = { ...data };
        //4. 이전버전 app의 프로그램 목록 가져옴
        return getPrevProgramList(from);
      })
      .then((programList) => {
        //5. 각 프로그램 내용에 변경된 데이터 모델 내용 적용
        setLoadingMessage("프로그램에 신규 내용을 적용 후 저장합니다.");
        newProgramList = ProgramService.succeedDataModelToProgramContent(
          newApp,
          newDataModelObject,
          programList
        );
        //6. 변경된 프로그램 목록 저장
        return succeedWorkflow(newApp, newProgramList);
      })
      .then(saveNewProgramList)
      .then((result) => {
        Message.alert(
          "배포를 정상적으로 진행하였습니다.",
          Enums.MessageType.SUCCESS
        );
        callBack();
        Popup.close();
      })
      .catch((err) => {
        //실패시 롤백
        setIsLoading(false);
        setLoadingMessage("로딩 중...");
        Message.alert(
          "오류가 발생하여 배포를 중단합니다. " + err.message,
          Enums.MessageType.ERROR
        );
        //app 삭제
        if (newApp) AppService.deleteRelease(newApp);
        // 데이터 모델 삭제
        if (
          !ObjectUtils.isEmpty(newDataModelObject) &&
          Object.keys(newDataModelObject.dataModelMapper).length > 0
        ) {
          const dataModelIds = Object.values(
            newDataModelObject.dataModelMapper
          );
          DataModelService.deleteDataModelList({ dataModelIds });
        }
        //프로그램은 Program Content 패치를 FE 단에서 하기 때문에 별도로 롤백을 진행하지 않음
      });
  };

  const saveRelease = (body) => {
    return new Promise((resolve, reject) => {
      AppService.saveRelease(
        body,
        (res) => {
          resolve(res.data);
        },
        (err) => reject(err)
      );
    });
  };
  /**
   *
   * @param {*} appInfo
   * @returns {Array} 복사한 데이터 모델 목록
   */
  const succeedDataModelList = (appInfo, from) => {
    //2. 데이터 모델 복사
    //기존 버전의 데이터 모델 목록을 신규 버전으로 새로 저장한 목록을 불러옴
    setLoadingMessage("데이터 모델을 생성합니다.");
    return new Promise((resolve, reject) => {
      const body = {
        from,
        to: appInfo,
      };
      DataModelService.succeedDataModelList(
        body,
        (res) => {
          resolve(res.data);
        },
        (err) => reject(err)
      );
    });
  };

  /**
   * 가져오고자할 버전의 프로그램 목록 호출
   * @param {*} from
   * @returns
   */
  const getPrevProgramList = (from) => {
    setLoadingMessage("이전 프로그램 목록을 가져옵니다.");
    return new Promise((resolve, reject) => {
      ProgramService.getProgramAllList(
        { ...from, tenantId: "*", coCd: "*" },
        (res) => {
          resolve(res.data);
        },
        (err) => reject(err)
      );
    });
  };

  /**
   * 데이터 모델 ID와 서비스 ID를 업데이트 한 프로그램 목록 저장
   * @param {*} programList
   * @returns
   */
  const saveNewProgramList = (programList) => {
    programList = programList.map((program) => {
      program.programContent = JSON.stringify(program.programContent);
      return program;
    });
    return new Promise((resolve, reject) => {
      ProgramService.saveProgramList(
        { programList },
        (res) => resolve(res.data),
        (err) => {
          reject(err);
        }
      );
    });
  };

  /**
   * 워크플로우 상속
   * @param {*} to
   * @param {*} programList
   * @returns
   */
  const succeedWorkflow = async (to, programList) => {
    setLoadingMessage("워크플로우를 적용 중입니다.");
    return new Promise(async (resolve, reject) => {
      const _programList = [];
      try {
        for (const program of programList) {
          const serviceUidList = JsonUtils.findNodeValues(
            program,
            "serviceUid"
          );
          if (ArrayUtils.isEmpty(serviceUidList)) {
            _programList.push(program);
          } else {
            await WorkflowService.succeedWorkflow(
              { to, serviceUidList },
              (res) => {
                const { serviceUidMapper } = res.data;
                const _wfSucceededProgram = ProgramService.succeedServiceUid(
                  serviceUidMapper,
                  program
                );
                _programList.push(_wfSucceededProgram);
              }
            );
          }
        }
        resolve(_programList);
      } catch (error) {
        reject(error);
      }
    });
  };

  /**
   * 모듈 만드는 팝업 오픈
   * @param {*} e
   */
  const onClickModulePopup = (e) => {
    if (moduleCreatePopup) {
      moduleCreatePopup(e, selectedApp);
    }
  };

  return (
    <Modal>
      {isLoading && (
        <div
          className="data-load-wrapper"
          style={{ background: "#d3d3d385", zIndex: 20 }}
        >
          <div className="data-load-box">
            <CircularProgress color="inherit" size={13} />
            &nbsp;&nbsp;&nbsp; {loadingMessage}
          </div>
        </div>
      )}

      <Modal.Header title="신규 Appliacation 배포" />
      <Modal.Body>
        <UmodalTemplate>
          <Row className="gy-3">
            <Form.Group as={Col} xs={12}>
              <Form.Label className="required">Application 선택</Form.Label>
              <Form.Select value={selectedApp} onChange={onChangeApp}>
                <option value="">선택</option>
                {appList.map((app) => {
                  return (
                    <option value={app.appId} key={app.appId}>
                      {app.appNm}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
            <Form.Group as={Col} xs={12}>
              <Form.Label className="required">모듈 선택</Form.Label>
              <ModuleSelector
                type="u"
                value={selectedModule}
                onChange={onChangeModule}
                showEmpty={true}
                disabled={!selectedApp}
                placeholder={
                  selectedApp ? "선택" : "Application을 선택해주세요."
                }
                appId={selectedApp}
                moduleCreatable
                moduleCreateCb={onClickModulePopup}
              />
            </Form.Group>
            <Form.Group as={Col} xs={12}>
              <Form.Label
                onClick={(e) => setIsExpandModelSearch(!isExpandModelSearch)}
                style={{ cursor: "pointer" }}
              >
                모델 검색 조건 Prefix 설정 {isExpandModelSearch ? "▲" : "▼"}
              </Form.Label>

              <Tooltip
                title={
                  <span style={{ fontSize: "larger" }}>
                    데이터 모델 검색시 포함하거나 제외할 테이블의 Prefix를
                    입력합니다. 설정 하지 않을시 각 모듈코드에 맞춘 목록을
                    호출합니다.
                  </span>
                }
                placement={"right"}
              >
                <span>
                  <AiOutlineQuestionCircle />
                </span>
              </Tooltip>
              {isExpandModelSearch ? (
                <Row className="gy-2">
                  <Col xs={2}>
                    <Form.Control disabled defaultValue={"포함"} />
                  </Col>
                  <Col xs={10}>
                    <Form.Control
                      placeholder="검색할 테이블의 Prefix를 입력(' , '로 구분 )"
                      value={optionIn}
                      onChange={(e) => setOptionIn(e.target.value)}
                    />
                  </Col>
                  <Col xs={2}>
                    <Form.Control disabled defaultValue={"불포함"} />
                  </Col>
                  <Col xs={10}>
                    <Form.Control
                      placeholder="검색 하지 않을 테이블의 Prefix를 입력(' , '로 구분 )"
                      value={optionNotIn}
                      onChange={(e) => setOptionNotIn(e.target.value)}
                    />
                  </Col>
                  {!optionIn && !optionNotIn && (
                    <Col xs={12} style={{ color: "tomato" }}>
                      전체 테이블 조회 : "*" 입력 <br />
                    </Col>
                  )}
                </Row>
              ) : (
                <></>
              )}
            </Form.Group>
            <Form.Group as={Col} xs={12}>
              <Form.Label className="required">버전 입력</Form.Label>
              <Form.Control
                placeholder={
                  selectedModule
                    ? "버전 입력.. ex)1.0 or 2.1 ..."
                    : "모듈을 선택해주세요"
                }
                disabled={!selectedModule}
                value={version}
                onChange={(e) => setVersion(e.currentTarget.value)}
              />
            </Form.Group>
            <Form.Group as={Col} xs={12}>
              <Form.Label>참조할 버전 선택</Form.Label>
              <InputGroup>
                <Form.Select
                  disabled={!version}
                  value={fromVersion}
                  onChange={onChangeTargetVersion}
                >
                  <option value="">선택</option>
                  {fromVersionList.map((version) => {
                    return (
                      <option
                        value={version.appReleaseId}
                        key={version.appReleaseId}
                      >
                        버전 : {version.version}{" "}
                        &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp; 배포일{" "}
                        {CommonUtils.getDate(version.releaseDate)}
                      </option>
                    );
                  })}
                </Form.Select>
                <Button onClick={onGetReleaseNotes}>개정 내용 가져오기</Button>
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} xs={12}>
              <Form.Label className="required">버전 설명</Form.Label>
              <Form.Control
                disabled={!version}
                maxLength={499}
                value={briefDesc}
                onChange={(e) => setBriefDesc(e.currentTarget.value)}
                placeholder="배포할 버전의 설명을 입력해주세요."
              />
            </Form.Group>
            <Form.Group as={Col} xs={12}>
              <Form.Label className="required">개정 내용</Form.Label>
              <Form.Control
                as="textarea"
                rows={10}
                style={{ resize: "none" }}
                value={releaseNotes}
                onChange={(e) => setReleaseNotes(e.currentTarget.value)}
              />
            </Form.Group>
          </Row>
        </UmodalTemplate>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.ProgressButton
          variant="success"
          onClick={onSaveRelease}
          doing={isLoading}
          doingText="실행 중"
        >
          등록
        </Modal.Footer.ProgressButton>
      </Modal.Footer>
    </Modal>
  );
}

export default AppReleasePopup;
