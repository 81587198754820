import UIBuilderReducer from "components/builder/ui/reducers/UIBuilderReducer"; /* UI Builder reducer */
import EntityBuilderReducers from "components/builder/entity/reducers/EntityBuilderReducers"; /* Entity Builder reducer */
import { combineReducers, createStore } from "redux";

const BuilderStore = createStore(
  combineReducers({ ...UIBuilderReducer, ...EntityBuilderReducers }),
  window.__REDUX_DEVTOOLS_EXTENSION__ &&
    window.__REDUX_DEVTOOLS_EXTENSION__({ trace: true })
);
export default BuilderStore;
