import BuilderHeaderComponent from "components/builder/BuilderHeaderComponent";
import BuilderHeader from "components/builder/BuilderSidebar";
import React from "react";
import { Outlet } from "react-router-dom";

function UserPage({ ...props }) {
  return (
    <>
      <BuilderHeaderComponent {...props.header} />
      <Outlet />
    </>
  );
}

export default UserPage;
