import produce from "immer";

const initialState = {
  isSearchConsoleCollapsed: true,
  isSearchConsoleHidden: true,
  searchType: "",
  searchTerm: "",
  searchList: [],
  searchOptions: {},
};

export default function SearchConsoleReducer(state = initialState, action) {
  switch (action.type) {
    case "set_search_console_collapse":
      return produce(state, (draft) => {
        draft.isSearchConsoleCollapsed = action.payload;
      });
    case "set_search_console_hidden":
      return produce(state, (draft) => {
        draft.isSearchConsoleHidden = action.payload;
      });
    case "set_search_list":
      return produce(state, (draft) => {
        draft.searchList = action.payload;
      });
    case "set_search_type":
      return produce(state, (draft) => {
        draft.searchType = action.payload;
      });
    case "set_search_term":
      return produce(state, (draft) => {
        draft.searchTerm = action.payload;
      });
    case "set_search_options":
      return produce(state, (draft) => {
        draft.searchOptions = action.payload;
      });
    case "init_search_console":
      return initialState;
    default:
      return state;
  }
}
