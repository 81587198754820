import * as Enums from "components/builder/BuilderEnum";
import Api from "components/common/Api";
import Message from "components/common/Message";
import JsonUtils from "components/common/utils/JsonUtils";
import ObjectUtils from "components/common/utils/ObjectUtils";
import StringUtils from "components/common/utils/StringUtils";
import User from "components/common/utils/UserUtils";
import produce from "immer";
import moment from "moment/moment";
/**
 * UI - builder 저장 및 조회
 * **/
class ProgramService {
  static requestMapping = "/program";

  /**
   * 프로그램 목록 호출
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static getProgramList(data, callbackFnc, errCallbackFnc) {
    Api.post(
      this.requestMapping + "/programList",
      data,
      callbackFnc,
      errCallbackFnc
    );
  }
  /**
   * 프로그램 목록 호출
   * 프로그램 컨텐츠(JSON) 데이터 포함
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static getProgramAllList(data, callbackFnc, errCallbackFnc) {
    Api.post(
      this.requestMapping + "/programAllList",
      data,
      callbackFnc,
      errCallbackFnc
    );
  }
  /**
   * 프로그램 이력 목록 호출
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static getHistoryProgramList(data, callbackFnc, errCallbackFnc) {
    Api.post(
      this.requestMapping + "/getHistoryProgramList",
      data,
      callbackFnc,
      errCallbackFnc
    );
  }

  /**
   * 단일 프로그램 조회
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static getProgram(data, callbackFnc, errCallbackFnc) {
    Api.post(
      this.requestMapping + "/getProgram",
      data,
      (res) => {
        const ans = { ...JsonUtils.parseJson(res.data.programContent) };
        ans.page.child = this.gridHeaderPerform(ans.page.child);
        res.data.programContent = JSON.stringify(ans);
        callbackFnc(res);
      },
      errCallbackFnc
    );
  }

  /**
   * json 데이터에 className 추가를 통해 gridHeader 정렬
   * @param {*} ans 변경할 json 데이터 (ans.page)
   */
  static gridHeaderPerform(ans) {
    if (StringUtils.isEmpty(ans.type)) {
      ans.forEach((element_) => {
        if (
          !StringUtils.isEmpty(element_.child) &&
          !StringUtils.includes(element_.type, ["grid"])
        ) {
          element_.child.forEach((el) => {
            if (StringUtils.isEmpty(el.child)) {
              el = this.gridHeaderPerform(el);
            } else {
              el.child = this.gridHeaderPerform(el.child);
            }
          });
        } else {
          element_ = this.gridHeaderPerform(element_);
        }
      });
    } else {
      if (ans.type === "grid") {
        ans.propertyValue.gridOptions.columns.forEach((element) => {
          if (StringUtils.isEmpty(element.className)) {
            if (StringUtils.includes(element.dataType, ["numeric", "number"])) {
              element.className = "header-right";
            } else {
              element.className = "header-left";
            }
          }
        });
      }
    }
    return ans;
  }

  /**
   * Application ID, programUid, tenant , cocd 를 기준으로 등록된 코멘트를 가져옴
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static getProgramHistoryCommentList(data, callbackFnc, errCallbackFnc) {
    Api.post(
      this.requestMapping + "/getProgramHistoryCommentList",
      data,
      callbackFnc,
      errCallbackFnc
    );
  }

  /**
   * 데이터 모델목록에서 삭제시 해당 데이터 모델을 사용중인 프로그램을 불러오는 조회
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   *
   */
  static getDataModelUsingProgramList(data, callbackFnc, errCallbackFnc) {
    Api.post(
      this.requestMapping + "/getDataModelUsingProgramList",
      data,
      callbackFnc,
      errCallbackFnc
    );
  }
  /**
   * 데이터 모델에서 엔티티 삭제시 해당 엔티티를 사용중인 프로그램을 불러오는 조회
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   *
   */
  static getEntityUsingProgramList(data, callbackFnc, errCallbackFnc) {
    Api.post(
      this.requestMapping + "/getEntityUsingProgramList",
      data,
      callbackFnc,
      errCallbackFnc
    );
  }
  /**
   * 프로그램 삭제
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static deleteProgram(data, callbackFnc, errCallbackFnc) {
    Api.post(
      this.requestMapping + "/deleteProgram",
      data,
      callbackFnc,
      errCallbackFnc
    );
  }

  /**
   * 프로그램 저장
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static async saveProgram(data, callbackFnc, errCallbackFnc) {
    Api.post(
      this.requestMapping + "/saveProgram",
      data,
      (res) => {
        const { isError, data, errType, token } = res;
        if (isError) {
          if (errType === "Invalid") {
            Message.alert(
              "토큰이 유효하지 않습니다.\n 호스트와 연결을 종료 합니다.",
              Enums.MessageType.WARN
            );
            User.disconnect(data.tenantMstId);
            res.forceRerender = true;
          } else if (data === false) {
            Message.alert(
              "배포과정에서 오류가 발생하였습니다.",
              Enums.MessageType.WARN
            );
          } else {
            Message.alert(
              "통신 오류가 발생하였습니다.",
              Enums.MessageType.WARN
            );
          }
        }
        callbackFnc(res);
      },
      errCallbackFnc
    );
  }

  /**
   * 직접 배포 코드
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static directDeploy(data, callbackFnc, errCallbackFnc) {
    if (!data.deployer) data.deployer = User.getId();
    if (!data.requester) data.requester = User.getId();
    const _modulCd = data.moduleCd === "*" ? "all" : data.moduleCd;
    Api.directPost(`/${_modulCd}/Deploy`, data, callbackFnc, errCallbackFnc);
  }
  /**
   * 프로그램 이력 목록 호출
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static selectProgramHistory(data, callbackFnc, errCallbackFnc) {
    Api.post(
      this.requestMapping + "/selectProgramHistory",
      data,
      callbackFnc,
      errCallbackFnc
    );
  }

  /**
   * 프로그램 이력 목록 호출
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static selectProgramHistoryGrid(data, callbackFnc, errCallbackFnc) {
    Api.post(
      this.requestMapping + "/selectProgramHistoryGrid",
      data,
      callbackFnc,
      errCallbackFnc
    );
  }

  /**
   * 공유 프로그램 목록 호출
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static async getShareProgramList(data, callbackFnc, errCallbackFnc) {
    data = {
      ...data,
      templateShareYn: "Y",
      moduleCds: User.selectAvailableModuleCds().map((item) => item.moduleCd),
    };

    Api.post(
      this.requestMapping + "/shareProgramList",
      data,
      (res) => {
        const { isError } = res;
        if (isError) {
          Message.alert(
            "데이터 호출 중 오류가 발생하였습니다.",
            Enums.MessageType.ERROR
          );
        }
        callbackFnc(res);
      },
      (err) => {
        if (errCallbackFnc) {
          errCallbackFnc(err);
        }
        Message.alert(
          "통신 중 오류가 발생하였습니다.",
          Enums.MessageType.ERROR
        );
      }
    );
  }

  /**
   * 프로그램 목록 저장
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static saveProgramList(data, callbackFnc, errCallbackFnc) {
    Api.post(
      this.requestMapping + "/saveProgramList",
      data,
      callbackFnc,
      errCallbackFnc
    );
  }

  /**
   * 프로그램 복원
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static recoverProgram(data, callbackFnc, errCallbackFnc) {
    Api.post(
      this.requestMapping + "/recoverProgram",
      data,
      callbackFnc,
      errCallbackFnc
    );
  }

  /**
   * 프로그램 삭제 By App Release Id
   * 버전 삭제할때 사용
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static deleteByAppReleaseId(data, callbackFnc, errCallbackFnc) {
    Api.post(
      this.requestMapping + "/deleteProgramByAppReleaseId",
      data,
      callbackFnc,
      errCallbackFnc
    );
  }

  /**
   * 프로그램 이력 상세에서 단일 이력 데이터 가져오는 함수
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static getProgramHistoryDetail(data, callbackFnc, errCallbackFnc) {
    Api.post(
      this.requestMapping + "/getProgramHistoryDetail",
      data,
      callbackFnc,
      errCallbackFnc
    );
  }

  /**
   *
   * @param {[Object]} _data : 필터할 목록
   * @param {String} tenantId
   * @param {String} coCd
   * @param {String} key : 중복할 키값-> UID를 제외한 ID 또는 Nm
   * 중복할 키값은 서로 다른 레벨에서 키( ID 또는 Name )의 값이 같을때 해당 값을 깊은 레벨의 값으로 대체할때 쓴다.
   * @returns
   */
  static filterByTenantIdAndCoCd(
    _data = [],
    tenantId,
    coCd,
    key = "programId"
  ) {
    const list = [];
    const sortByDate = (_list) => {
      let dateSorted = _list.sort((a, b) => {
        if (moment(a.updtDt) > moment(b.updtDt)) {
          return -1;
        } else {
          return 1;
        }
      });
      return dateSorted;
    };

    if (tenantId === "*" && coCd === "*") {
      return sortByDate([..._data]);
    } else if (tenantId !== "*" && coCd === "*") {
      for (const program of _data) {
        if (!list.find((item) => item[key] === program[key])) {
          const tenantPrograms = _data.filter((d) =>
            StringUtils.equalsIgnoreType(d[key], program[key])
          );
          if (tenantPrograms.length === 1) {
            list.push(tenantPrograms[0]);
          } else if (tenantPrograms.length > 1) {
            const tenantProgram = tenantPrograms.find(
              (t) => t.tenantId === tenantId && coCd === "*"
            );
            if (tenantProgram) {
              list.push(tenantProgram);
            }
          }
        }
      }
      return sortByDate(list);
    } else if (tenantId !== "*" && coCd !== "*") {
      for (const program of _data) {
        if (!list.find((item) => item[key] === program[key])) {
          const tenantPrograms = _data.filter((d) =>
            StringUtils.equalsIgnoreType(d[key], program[key])
          );
          if (tenantPrograms.length === 1) {
            list.push(tenantPrograms[0]);
          } else if (tenantPrograms.length > 1) {
            const coCdProgram = tenantPrograms.find(
              (t) => t.tenantId === tenantId && t.coCd === coCd
            );
            if (coCdProgram) {
              list.push(coCdProgram);
            } else {
              const tenantProgram = tenantPrograms.find(
                (t) => t.tenantId === tenantId && t.coCd === "*"
              );
              if (tenantProgram) {
                list.push(tenantProgram);
              }
            }
          }
        }
      }
      return sortByDate(list);
    }
  }

  /**
   * 테넌트 ID 및 CO CD 순서로 필터링
   * @param {[Object]} data 필터할 목록
   */
  static sortByTenantIdAndCoCd(data) {
    const filtered = data.sort((a, b) => {
      if (a.tenantId > b.tenantId) {
        return 1;
      } else if (a.tenantId < b.tenantId) {
        return -1;
      } else {
        if (a.coCd > b.coCd) {
          return 1;
        } else {
          return -1;
        }
      }
    });

    return filtered;
  }

  /**
   * 데이터 모델 승계 작업
   * 프로그램 내보내기 또는 신규버전 상속 시 사용
   * @param {*} newApp
   * @param {*} newDataModelObject
   * @param {*} _programList
   * @returns
   */
  static succeedDataModelToProgramContent(
    newApp,
    newDataModelObject,
    _programList
  ) {
    const { dataModelMapper, entityMapper, tnxMapper, DataModelList } =
      newDataModelObject;

    let thisDataModelId;

    const findNodeAndReplace = (data) => {
      if (data instanceof Array) {
        const newData = [];
        for (let item of data) {
          newData.push(findNodeAndReplace(item));
        }
        return newData;
      } else {
        if (!data) return data;
        let changedData = { ...data };
        const keys = Object.keys(data);
        for (const key of keys) {
          if (key === "dataModelId") {
            //데이터 모델 아이디
            thisDataModelId = dataModelMapper[changedData[key]];
            changedData[key] = dataModelMapper[changedData[key]];
            //데이터 모델 아이디는 1개 밖에 없기 때문에 타겟 키를 기억해 뒀다가 업데이트;
          } else if (key === "dataModelEntityId") {
            // 엔티티 아이디
            changedData[key] = entityMapper[Number(changedData[key])];
          } else if (key === "comboEntityId") {
            // 콤보 엔티티 아이디
            changedData[key] = entityMapper[Number(changedData[key])];
          } else if (key === "transaction") {
            //트랜잭션 아이디
            changedData[key] = tnxMapper[Number(changedData[key])];
          } else if (typeof changedData[key] === "object") {
            changedData[key] = findNodeAndReplace(changedData[key]);
          }
        }
        return changedData;
      }
    };

    try {
      const SucceededProgramList = [];
      for (const _program of _programList) {
        const program = { ..._program };
        program.appReleaseId = newApp.appReleaseId;
        thisDataModelId = null;
        if (
          StringUtils.equalsIgnoreCase(typeof program.programContent, "string")
        ) {
          program.programContent = {
            ...JsonUtils.parseJson(program.programContent),
          };
        }
        program.programContent = findNodeAndReplace(program.programContent);
        if (thisDataModelId) {
          program.dataModelId = thisDataModelId;
          program.dataModelList = JSON.stringify({
            dataModel: DataModelList.find(
              (model) => model.dataModelId === thisDataModelId
            ),
          });
        }
        SucceededProgramList.push(program);
      }
      return SucceededProgramList;
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  /**
   * 단일 프로그램에 대한 서비스 ID 승계 작업
   * 내보내기 또는 신규버전 배포시 사용
   * @param {*} serviceUidMapper
   * @param {*} program
   */
  static succeedServiceUid(serviceUidMapper, program) {
    if (ObjectUtils.isEmpty(program)) return null;

    program.programContent = produce(program.programContent, (draft) => {
      const _findAndChange = (data) => {
        for (const k in data) {
          if (typeof data[k] === "object") {
            _findAndChange(data[k]);
          } else if (k === "serviceUid") {
            data[k] = serviceUidMapper[data[k]];
          }
        }
      };
      _findAndChange(draft);
    });

    return program;
  }

  /**
   * 특정 서비스를 사용하는 프로그램을 호출하는 로직
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static getWorkflowUsingProgramList = (data, callbackFnc, errCallbackFnc) => {
    Api.post(
      this.requestMapping + "/getWorkflowUsingProgramList",
      data,
      callbackFnc,
      errCallbackFnc
    );
  };
}
export default ProgramService;
