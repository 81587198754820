import React, { Component } from "react";

import { StringUtils } from "components/common/utils/CommonUtils";
import Popup from "components/common/Popup";
import DataModelPopup from "page/popup/dataModel/DataModelPopup";
import Modal from "components/common/modal/UModal";
import { connect } from "react-redux";
import { AppContext } from "components/common/AppContextProvider";

class LayoutTemplate extends Component {
  constructor(props) {
    super(props);
    this.componentInfo = this.props.componentInfo;
    this.defaultValues = this.props.defaultValues || {};
    this.callbackFnc = this.props.callbackFnc;
    this.templateComponents = this.props.templateComponents;
    this.output = this.props.output || {};
  }
  static contextType = AppContext;
  createLayout = (event) => {
    event.preventDefault();
    this.callbackFnc.call(this, this.createLayoutData());
    Popup.close();
  };

  onChange = (event) => {
    const fieldValue =
      event.target.value === "" || event.target.value <= 0
        ? 1
        : event.target.value;
    this.changeValues(event, fieldValue);
  };

  onChangeCheckbox = (event) => {
    const { checked } = event.target;
    this.changeValues(event, checked);
  };

  changeValues = (event, fieldValue) => {
    const fieldId = StringUtils.defaultString(
      event.target.name,
      event.target.id
    );
    this.setState({
      inputValue: { ...this.state.inputValue, [fieldId]: fieldValue },
    });
  };
  /**
   * DataModel 선택 popup
   */
  openDataModelPopup = () => {
    const options = {
      style: {
        content: {
          width: "50%", //popup의 크기를 50% (default 60%)
          minWidth: "1000px",
        },
      },
    };
    Popup.open(
      <DataModelPopup
        workspace={this.context.workspace}
        title="Data Model 선택"
        defaultValue={StringUtils.defaultString(
          this.state.inputValue.dataModelNm
        )}
        callbackFnc={(data) => {
          if (data) {
            this.setState({
              inputValue: {
                ...this.state.inputValue,
                dataModelNm: data.dataModelNm,
              },
            });
          }
        }}
      />,
      options,
      "DataModelPopup"
    );
  };

  /**
   * Template render
   * @returns
   */
  renderTemplate = () => {
    return <React.Fragment>Default Template</React.Fragment>;
  };

  /**
   * renderFooter
   */
  renderFooter = (confirmCallback) => {
    return (
      <React.Fragment>
        <Modal.Footer.Button
          onClick={confirmCallback ? confirmCallback : this.createLayout}
        >
          확인
        </Modal.Footer.Button>
      </React.Fragment>
    );
  };
  render() {
    return this.renderTemplate();
  }
}
export default LayoutTemplate;
