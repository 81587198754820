import StringUtils from "components/common/utils/StringUtils";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DynamicApiTab from "components/builder/entity/flow/DynamicApiTab";
import EntityApiTab from "components/builder/entity/flow/EntityApiTab";
import ServiceApiTab from "components/builder/entity/flow/ServiceApiTab";

const EntityTablesTab = () => {
  const output = useSelector((state) => state.outputENT.output);

  const [dataModelType, setDataModelType] = useState("");

  useEffect(() => {
    const outputDataModel = output.dataModelType;
    if (["D", "S", "E"].indexOf(outputDataModel) > -1) {
      setDataModelType(outputDataModel);
    }
  }, [output]);

  if (StringUtils.equalsIgnoreCase(dataModelType, "D")) {
    return <DynamicApiTab />;
  } else if (StringUtils.equalsIgnoreCase(dataModelType, "S")) {
    return <ServiceApiTab />;
  } else if (StringUtils.equalsIgnoreCase(dataModelType, "E")) {
    return <EntityApiTab />;
  } else {
    return <>데이터 모델 타입이 정해지지 않았습니다.</>;
  }
};

export default EntityTablesTab;
