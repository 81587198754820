import { useCallback } from "react";
import { useStore, getStraightPath, getSmoothStepPath } from "reactflow";

import { getEdgeParams } from "../editor/render/WorkflowRenderUtils.js";

/**
 * 각 노드를 연결하는 선의 형태 지정
 * @param {*} param0
 * @returns
 */
function WorkflowConnectorEdge({
  id,
  source,
  target,
  markerEnd,
  style,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
}) {
  const sourceNode = useStore(
    useCallback((store) => store.nodeInternals.get(source), [source])
  );
  const targetNode = useStore(
    useCallback((store) => store.nodeInternals.get(target), [target])
  );

  const foreignObjectSize = 30;

  if (!sourceNode || !targetNode) {
    return null;
  }

  const [edgePath] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <>
      <path
        id={id}
        className="react-flow__edge-path"
        d={edgePath}
        strokeWidth={5}
        markerEnd={markerEnd}
        style={style}
      />
    </>
  );
}

export default WorkflowConnectorEdge;
