import React, { useState, useEffect, useMemo } from "react";
import produce from "immer";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import CodeMirror from "@uiw/react-codemirror";
import { json } from "@codemirror/lang-json";
import { Scrollbar } from "smooth-scrollbar-react";

import { JsonUtils } from "components/common/utils/CommonUtils";

const UIEditorSource = (props) => {
  const output = props.output;

  const [sourceViewOptions, setSourceViewOptions] = useState({
    containEditorAttr: false,
  });
  const [viewSource, setViewSource] = useState(JSON.stringify(output, null, 2));

  const changeViewSource = (value) => {
    if (
      sourceViewOptions.containEditorAttr &&
      JSON.stringify(output, null, 2) !== value
    ) {
      try {
        props.updateOutputSource(JSON.parse(value));
      } catch (e) {
        console.log("Json parsing Error");
      }
    }
  };

  useEffect(() => {
    if (sourceViewOptions.containEditorAttr) {
      setViewSource(JSON.stringify(output, null, 2));
    } else {
      let source = produce(output, (draft) => {
        JsonUtils.removeNode(draft, "editorAttr");
      });
      setViewSource(JSON.stringify(source, null, 2));
    }
  }, [output, sourceViewOptions.containEditorAttr]);

  return (
    <div className="edit-source">
      <div className="edit-source-options">
        <BootstrapSwitchButton
          checked={sourceViewOptions.containEditorAttr}
          size="sm"
          onstyle="primary"
          offstyle="dark"
          onlabel="편집 On"
          offlabel="편집 Off"
          width={120}
          onChange={(containEditorAttr, e) => {
            setSourceViewOptions({
              ...sourceViewOptions,
              containEditorAttr: containEditorAttr,
            });
          }}
        />
      </div>
      <CodeMirror
        value={viewSource}
        className="source-container"
        height="100%"
        extensions={[json(true)]}
        autoFocus={false}
        editable={sourceViewOptions.containEditorAttr}
        onChange={(value, viewUpdate) => changeViewSource(value, viewUpdate)}
      />
    </div>
  );
};

export default UIEditorSource;
