import Blockly from "blockly";

/**
 * Blockly icon class link : https://github.com/google/blockly/blob/develop/core/icons/icon.ts
 * /
/**
 * Blockly custom icon link: https://developers.google.com/blockly/guides/create-custom-blocks/icons/creating-custom-icons/basic-implementation
 */

/**
 * Utils category에 있는 Block에 사용할 icon 클래스 정의
 */
class BlockUtilsIcon extends Blockly.icons.Icon {
  constructor(sourceBlock) {
    super(sourceBlock);
  }
  getType() {
    return new Blockly.icons.IconType("utils_icon");
  }
  initView(pointerdownListener) {
    if (this.svgRoot) return; // Already initialized.

    // This adds the pointerdownListener to the svgRoot element.
    // If you do not call `super` you must do this yourself.
    super.initView(pointerdownListener);

    const svg = Blockly.utils.dom.createSvgElement(
      Blockly.utils.Svg.SVG,
      {
        width: "16px",
        height: "16px",
        fill: "#000000",
        viewBox: "0 0 376.846 376.846",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        "xml:space": "preserve",
      },
      this.svgRoot // Append to the svgRoot.
    );
    const g = Blockly.utils.dom.createSvgElement(Blockly.utils.Svg.G, {}, svg);
    Blockly.utils.dom.createSvgElement(
      Blockly.utils.Svg.PATH,
      {
        d: "M364.966,372.743c2.382-2.39,4.316-4.324,4.316-4.324l4.316-4.324   c2.382-2.398-1.691-13.453-9.104-24.695l-19.013-28.865c-7.413-11.242-19.127-21.565-26.174-23.044   c-7.047-1.488-12.762-2.691-12.762-2.691l-57.298-57.274L228.389,248.4l57.25,57.282l2.707,12.762   c1.488,7.039,11.819,18.769,23.061,26.174l28.881,19.037C351.529,371.06,362.584,375.133,364.966,372.743z",
        fill: "#586261",
      },
      g
    );
    Blockly.utils.dom.createSvgElement(
      Blockly.utils.Svg.PATH,
      {
        d: "M13.608,74.197l85.139,85.139l10.632,10.608c0.244,0.26,0.48,0.398,0.715,0.642l14.062-14.071   L23.728,56.086c-2.447-2.422-2.447-6.397,0-8.795c2.422-2.455,6.34-2.455,8.795,0l100.396,100.453l15.664-15.696L48.154,31.635   c-2.455-2.43-2.455-6.365,0-8.795c2.422-2.422,6.365-2.422,8.763,0l100.477,100.477l14.038-14.087   c-0.211-0.244-0.366-0.504-0.585-0.74l-11.161-11.177l-84.594-84.57c-16.972-17.005-44.52-16.981-61.485,0   C-3.389,29.709-3.389,57.224,13.608,74.197z",
        fill: "#586261",
      },
      g
    );
    Blockly.utils.dom.createSvgElement(
      Blockly.utils.Svg.PATH,
      {
        d: "M375.728,81.651c-1.024-13.428-12.892-16.038-22.41-6.519l-33.262,33.27   c-7.722,7.779-20.378,7.755-28.125,0l-21.549-21.524c-7.803-7.844-7.779-20.46-0.024-28.247l34.392-34.359   c9.527-9.519,7.112-21.817-6.259-23.41c-25.865-3.089-52.82,5.275-72.678,25.117c-15.127,15.152-23.573,34.473-25.361,54.258   c-0.715,7.999-0.341,16.07,1.097,24.004L16.965,288.84c-9.746,9.754-15.127,22.703-15.127,36.497   c0,13.762,5.381,26.694,15.103,36.432c20.11,20.102,52.819,20.102,72.962,0c0,0,132.683-132.666,186.413-186.461   c8.446,1.154,16.997,1.073,25.41-0.26c17.769-2.78,34.847-10.925,48.56-24.613C369.152,131.568,377.614,106.296,375.728,81.651z    M70.857,345.91c-10.551,10.494-27.523,10.494-38.042-0.024c-10.47-10.445-10.494-27.499,0.024-37.969   c10.494-10.47,27.466-10.445,37.985,0C81.295,318.387,81.295,335.441,70.857,345.91z",
        fill: "#586261",
      },
      g
    );
  }
  getSize() {
    return new Blockly.utils.Size(16, 16);
  }
  updateCollapsed() {
    if (!this.svgRoot) return;
    if (this.sourceBlock.isCollapsed()) {
      this.svgRoot.style.display = "block";
      return true;
    }
    if (Blockly.hasBubble(this)) {
      this.setBubbleVisible(false);
    }
  }
  dispose() {
    // Always call super!
    super.dispose();

    this.myBubble?.dispose();
    this.myOtherReference?.dispose();
  }
  saveState() {
    return this.state; // Some arbirtary JSON-compatible data.
  }
  loadState(state) {
    this.state = state;
  }
  onLocationChange(blockOrigin) {
    this.workspaceLocation = Blockly.utils.Coordinate.sum(
      blockOrigin,
      this.offsetInBlock
    );
  }
  isShownWhenCollapsed() {
    return true;
  }
}

Blockly.icons.registry.register(
  new Blockly.icons.IconType("utils_icon"),
  BlockUtilsIcon
);
