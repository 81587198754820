import Popup from "components/common/Popup.js";
import StringUtils from "components/common/utils/StringUtils";
import produce from "immer";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useStore, getSmoothStepPath, EdgeLabelRenderer } from "reactflow";
import EntityReduxHelper from "../../editor/helper/EntityReduxHelper";
import { getEdgeParams } from "../utils/utils";

function EntityRelationEdge({
  id,
  source,
  target,
  markerEnd,
  markerStart,
  style,
  data,
}) {
  const output = useSelector((state) => state.outputENT.output);
  const dispatch = useDispatch();

  const sourceNode = useStore(
    useCallback((store) => store.nodeInternals.get(source), [source])
  );
  const targetNode = useStore(
    useCallback((store) => store.nodeInternals.get(target), [target])
  );

  if (!sourceNode || !targetNode) {
    return null;
  }

  const { sx, sy, tx, ty, sourcePos, targetPos } = getEdgeParams(
    sourceNode,
    targetNode
  );

  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX: sx,
    sourceY: sy,
    sourcePosition: sourcePos,
    targetPosition: targetPos,
    targetX: tx,
    targetY: ty,
  });

  /**
   * 릴레이션 저장
   * @param {*} newRelation
   * @param {*} _entity
   */
  const onSaveRelation = (newRelation, _entity) => {
    //active component update
    const newComponent = produce(_entity, (draft) => {
      const updtIdx = draft.relation.findIndex((_relation) =>
        StringUtils.equalsIgnoreCase(
          _relation.targetEntity,
          newRelation.targetEntity
        )
      );
      draft.relation[updtIdx] = newRelation;
    });

    //Data Model update
    EntityReduxHelper.activateComponent(dispatch, newComponent);
    EntityReduxHelper.updateEntity(dispatch, output, newComponent);
  };

  /**
   * 릴레이션 삭제
   * @param {*} relation
   * @param {*} _entity
   */
  const onRemoveRelation = (relation, _entity) => {
    //active component update
    const newComponent = produce(_entity, (draft) => {
      const removeIdx = draft.relation.findIndex((_relation) =>
        StringUtils.equalsIgnoreCase(
          _relation.targetEntity,
          relation.targetEntity
        )
      );
      const newRelatiosn = [...draft.relation];
      newRelatiosn.splice(removeIdx, 1);
      draft.relation = newRelatiosn;

      //EAGER인 경우 참조된 virtual Column 삭제
      const deletedRelation = _entity.relation[removeIdx];
      if (StringUtils.equalsIgnoreCase(deletedRelation.fetch, "EAGER")) {
        const { targetTable, joinColumn } = deletedRelation;
        joinColumn.map((colInfo) => {
          const targetEntity = output.dataModelEntities.find((en) =>
            StringUtils.equalsIgnoreCase(en.tableNm, targetTable)
          );
          draft.dataModelEntityFields = _entity.dataModelEntityFields.filter(
            (field) => {
              if (
                field.refEntityId &&
                field.refEntityId ===
                  (targetEntity.entityId || targetEntity.compId)
              ) {
                return false;
              } else {
                return true;
              }
            }
          );
          return colInfo;
        });
      }
    });

    EntityReduxHelper.activateComponent(dispatch, newComponent);
    EntityReduxHelper.updateEntity(dispatch, output, newComponent);
  };

  return (
    <>
      <path
        id={id}
        className="react-flow__edge-path"
        d={edgePath}
        markerStart={markerStart}
        markerEnd={markerEnd}
        style={style}
      />
      {/* <EdgeLabelRenderer>
        <div
          style={{
            position: "absolute",
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            background: "white",
            borderRadius: 2,
            fontSize: `calc(25px / ${zoom})`,
            fontWeight: 700,
            cursor: "pointer",
            pointerEvents: "all",
            padding: "0 5px",
          }}
          onClick={onDetailRelation}
          className="nodrag nopan"
        >
          {data.text}
        </div>
      </EdgeLabelRenderer> */}
    </>
  );
}

export default EntityRelationEdge;
