import { Enums } from "components/builder/BuilderEnum";
import {
  initCommand,
  stackRedo,
  stackUndo,
} from "components/builder/ui/reducers/CommandAction";
import { updateWorkflow } from "components/builder/workflow/reducer/WorkflowAction";
import { workflowInitialState } from "components/builder/workflow/reducer/WorkflowReducer";
import ArrayUtils from "components/common/utils/ArrayUtils";
import JsonUtils from "components/common/utils/JsonUtils";
import StringUtils from "components/common/utils/StringUtils";
import produce from "immer";
import {
  getConnectorPosition,
  getInOutputEntity,
} from "../render/WorkflowRenderUtils";

class WorkflowReduxHelper {
  /**
   * 내부 워크플로우 업데이트
   * 해당 업데이트는 Redo 스택에 들어가도록 합니다.
   * Undo, Redo에 영향이 있습니다.
   * 특별한 케이스가 아닌이상 워크플로우의 수정 상태는 해당 로직을 타도록 되어있습니다.
   * @param {*} dispatch
   * @param {*} workflow
   */
  static _updateWorkflow(dispatch, workflow) {
    dispatch(updateWorkflow(workflow));
    dispatch(stackRedo(workflow));
  }

  /**
   * 워크플로우 업데이트
   * 외부에서 사용
   * @param {*} dispatch
   * @param {*} workflow
   * @param {*} prevWorkflow
   */
  static updateWorkflow(dispatch, workflow, prevWorkflow) {
    dispatch(stackUndo(prevWorkflow));
    this._updateWorkflow(dispatch, workflow);
  }

  /**
   * workflow Output 만 수정되는 경우 -> 코드 미러에서 사용
   * @param {*} dispatch
   * @param {*} workflow
   */
  static updateWorkflowOutput(dispatch, data, prevWorkflow) {
    dispatch(stackUndo(prevWorkflow));
    const workflow = produce(prevWorkflow, (draft) => {
      draft.output = data;
    });
    this._updateWorkflow(dispatch, workflow);
  }

  /**
   * 커넥터 추가
   * @param {*} dispatch
   * @param {*} data
   * @param {*} prevWorkflow
   */
  static addConnector(dispatch, data, prevWorkflow) {
    dispatch(stackUndo(prevWorkflow));
    const workflow = produce(prevWorkflow, (draft) => {
      draft.output.service.child.connector.push(data);
    });
    this._updateWorkflow(dispatch, workflow);
  }
  static addMemo(dispatch, data, prevWorkflow) {
    const workflow = produce(prevWorkflow, (draft) => {
      draft.serviceMemo.push(data);
    });
    this._updateWorkflow(dispatch, workflow);
  }
  /**
   * 프로세스 추가
   * @param {*} dispatch
   * @param {*} data
   * @param {*} prevWorkflow
   */
  static addProcess(dispatch, data, prevWorkflow) {
    dispatch(stackUndo(prevWorkflow));
    //한개도 없는 경우에는 자동으로 시작점으로 부터 선 연결함
    const isFirstNode =
      prevWorkflow.output.service.child.process.filter(
        (p) => !StringUtils.equalsIgnoreCase(p.type, "processEdge")
      ).length === 0;
    if (isFirstNode) {
      //시작노드 가져오기
      const startNode = prevWorkflow.output.service.child.process.find((p) =>
        StringUtils.equalsIgnoreCase(p.processType, "StartProcess")
      );

      const workflow = produce(prevWorkflow, (draft) => {
        draft.output.service.child.process.push(data);
        //시작 노드로 부터 커넥터 연결
        const connection = {
          source: startNode.compId,
          sourceHandle: "d",
          target: data.compId,
          targetHandle: "a",
        };
        const body = {
          processFrom: startNode.compId,
          processTo: data.compId,
          compId: StringUtils.getUuid(),
          type: Enums.WorkflowNodeType.CONNECTOR,
          propertyValue: {
            filter: "",
            connectorNm: "",
          },
        };
        const { position, edgeDetailInfo } = getConnectorPosition({
          sourceNode: startNode,
          targetNode: data,
          connection,
          connector: body,
        });
        body.position = position;
        body.edgeDetailInfo = edgeDetailInfo;
        draft.output.service.child.connector.push(body);
      });
      this._updateWorkflow(dispatch, workflow);
    } else {
      const workflow = produce(prevWorkflow, (draft) => {
        draft.output.service.child.process.push(data);
      });
      this._updateWorkflow(dispatch, workflow);
    }
  }
  /**
   * 신규 서비스 추가
   * @param {*} dispatch
   * @param {*} data
   * @param {*} prevWorkflow
   */
  static createNewService(dispatch, data, prevWorkflow) {
    dispatch(stackUndo(prevWorkflow));
    let workflow = { ...workflowInitialState };
    workflow = produce(workflow, (draft) => {
      draft.output.service.serviceId = data.serviceId;
      draft.output.service.serviceName = data.serviceName;
      draft.output.service.serviceType = data.serviceType;
      draft.serviceInfo.serviceId = data.serviceId;
      draft.serviceInfo.serviceName = data.serviceName;
      draft.serviceInfo.description = data.description;
      draft.serviceInfo.serviceType = data.serviceType;
    });
    this._updateWorkflow(dispatch, workflow);
  }
  /**
   * 커넥터 삭제
   * @param {*} dispatch
   * @param {*} data
   * @param {*} prevWorkflow
   */
  static deleteConnector(dispatch, data, prevWorkflow) {
    dispatch(stackUndo(prevWorkflow));
    const workflow = produce(prevWorkflow, (draft) => {
      JsonUtils.removeNode(draft, "compId", data);
    });
    this._updateWorkflow(dispatch, workflow);
  }
  /**
   * 메모 삭제
   * @param {*} dispatch
   * @param {*} data
   * @param {*} prevWorkflow
   */
  static deleteMemo(dispatch, data, prevWorkflow) {
    dispatch(stackUndo(prevWorkflow));
    const workflow = produce(prevWorkflow, (draft) => {
      const memoIndex = prevWorkflow.serviceMemo.findIndex(
        (_memo) => _memo.compId === data
      );
      if (memoIndex > -1) draft.serviceMemo.splice(memoIndex, 1);
    });
    this._updateWorkflow(dispatch, workflow);
  }
  /**
   * 프로세스 삭제
   * @param {*} dispatch
   * @param {*} data
   * @param {*} prevWorkflow
   */
  static deleteProcess(dispatch, data, prevWorkflow) {
    dispatch(stackUndo(prevWorkflow));
    // 번들링 내부 노드 삭제시 워크플로우 output에서도 삭제해줘야함
    const workflowCp = JSON.parse(JSON.stringify(prevWorkflow));
    workflowCp.output.service = produce(workflowCp.output.service, (draft) => {
      if (ArrayUtils.isArray(data)) {
        for (const compId of data) {
          JsonUtils.removeNode(draft, "compId", compId);
          JsonUtils.removeNode(draft, "processFrom", compId);
          JsonUtils.removeNode(draft, "processTo", compId);
        }
      } else {
        JsonUtils.removeNode(draft, "compId", data);
        JsonUtils.removeNode(draft, "processFrom", data);
        JsonUtils.removeNode(draft, "processTo", data);
      }
    });

    workflowCp.output.bundle = produce(workflowCp.output.bundle, (draft) => {
      if (ArrayUtils.isArray(data)) {
        for (const compId of data) {
          JsonUtils.removeNode(draft, "compId", compId);
          JsonUtils.removeNode(draft, "processFrom", compId);
          JsonUtils.removeNode(draft, "processTo", compId);
        }
      } else {
        JsonUtils.removeNode(draft, "compId", data);
        JsonUtils.removeNode(draft, "processFrom", data);
        JsonUtils.removeNode(draft, "processTo", data);
      }
    });

    this._updateWorkflow(dispatch, workflowCp);
  }
  /**
   * 커넥터 수정
   * @param {*} dispatch
   * @param {*} data
   * @param {*} prevWorkflow
   */
  // static updateConnector(dispatch, data, prevWorkflow) {
  //   dispatch(stackUndo(prevWorkflow));
  //   const workflow = produce(prevWorkflow, (draft) => {
  //     for (const connector of data) {
  //       const pIdx = draft.output.service.child.connector.findIndex(
  //         (c) => c.compId === connector.compId
  //       );
  //       if (pIdx > -1)
  //         draft.output.service.child.connector[pIdx] = { ...connector };
  //     }
  //   });
  //   this._updateWorkflow(dispatch, workflow);
  // }
  /**
   * 메모 수정
   * @param {*} dispatch
   * @param {*} data
   * @param {*} prevWorkflow
   */
  static updateMemo(dispatch, data, prevWorkflow) {
    dispatch(stackUndo(prevWorkflow));
    const workflow = produce(prevWorkflow, (draft) => {
      for (const newMemo of data) {
        const memoIndex = prevWorkflow.serviceMemo.findIndex(
          (_memo) => _memo.compId === newMemo.compId
        );
        draft.serviceMemo[memoIndex].title = newMemo.title || "";
        draft.serviceMemo[memoIndex].description = newMemo.description;
        draft.serviceMemo[memoIndex].position = newMemo.position;
      }
    });
    this._updateWorkflow(dispatch, workflow);
  }
  // static updateProcess(dispatch, data, prevWorkflow) {
  //   dispatch(stackUndo(prevWorkflow));
  //   const workflow = produce(prevWorkflow, (draft) => {
  //     for (const process of data) {
  //       const pIdx = draft.output.service.child.process.findIndex(
  //         (p) => p.compId === process.compId
  //       );
  //       if (pIdx > -1)
  //         draft.output.service.child.process[pIdx] = { ...process };
  //     }
  //   });
  //   this._updateWorkflow(dispatch, workflow);
  // }

  /**
   * 노드 업데이트
   * @param {*} dispatch
   * @param {*} data
   * @param {*} prevWorkflow
   */
  static updateNodes(dispatch, data, prevWorkflow) {
    dispatch(stackUndo(prevWorkflow));
    const workflow = this._updateNodeList(data, prevWorkflow);
    this._updateWorkflow(dispatch, workflow);
  }

  static _updateNodeList = (nodeList, prevWorkflow) => {
    //다중 정렬시 속도가 느려져서 workflow 순회는 한번만 하는 걸로 수정
    /**
     *  1. 노드 ID 배열 생성
     *  2. prevWorkflow 순회할때 key가 compId이면 노드 ID 배열과 대조
     *  3. 대조했을 때 해당하는 노드면 데이터 업데이트 하는 방향으로 진행
     */
    const nodeIdList = nodeList.map((n) => n.compId);
    const findObj = (object) => {
      if (object && typeof object === "object") {
        const _object = { ...object };
        if (_object["compId"] && nodeIdList.indexOf(_object["compId"]) > -1) {
          const node = nodeList[nodeIdList.indexOf(_object["compId"])];
          if (node) {
            Object.keys(node).map((key) => {
              if (key !== "compId" && node[key]) {
                _object[key] = node[key];
              } else if (object[key] && !node[key]) {
                delete _object[key];
              }
            });
          }
          return _object;
        } else {
          return produce(object, (draft) => {
            for (const k in draft) {
              draft[k] = findObj(draft[k]);
            }
          });
        }
      } else {
        return object;
      }
    };
    const workflow = produce(prevWorkflow, (draft) => {
      for (const k in draft) {
        if (k !== "serviceInfo") {
          //serviceInfo는 이전 작업물 저장이기 때문에 바꾸지 않음
          draft[k] = findObj(draft[k]);
        }
      }
    });

    return workflow;
  };

  /**
   * Iterator에 프로세스 노드 추가
   * @param {*} dispatch
   * @param {*} nodeInfo
   * @param {*} parentsIterator
   * @param {*} workflow
   */
  static addProcessInIterator(
    dispatch,
    nodeInfo,
    parentsIterator,
    prevWorkflow
  ) {
    dispatch(stackUndo(prevWorkflow));
    //이터레이터 찾기
    const newParentsIterator = produce(parentsIterator, (draft) => {
      draft.child.process.push(nodeInfo);
    });
    const workflow = produce(prevWorkflow, (draft) => {
      JsonUtils.overrideNode(
        draft,
        "compId",
        parentsIterator.compId,
        "child",
        newParentsIterator.child
      );
    });
    this._updateWorkflow(dispatch, workflow);
  }

  /**
   * 이터레이터의 자녀가 업데이트 되는 경우
   * @param {*} dispatch
   * @param {Array} nodeInfo
   * @param {Object} parentsIterator
   * @param {Object} prevWorkflow
   */
  static updateIteratorNode(dispatch, nodeInfo, parentsIterator, prevWorkflow) {
    dispatch(stackUndo(prevWorkflow));
    //이터레이터 찾기
    let newParentsIterator = { ...parentsIterator };
    for (const node of nodeInfo) {
      const { type } = node;
      newParentsIterator = produce(newParentsIterator, (draft) => {
        let pIdx = -1;
        if (
          StringUtils.includesIgnoreCase(type, [
            Enums.WorkflowNodeType.ITERATOR,
            Enums.WorkflowNodeType.PROCESS,
            Enums.WorkflowNodeType.CONDITION,
            Enums.WorkflowNodeType.SERVICE,
            Enums.WorkflowNodeType.CODE,
          ])
        ) {
          pIdx = parentsIterator.child.process.findIndex(
            (p) => p.compId === node.compId
          );
          if (pIdx > -1) draft.child.process[pIdx] = { ...node };
        } else if (type === Enums.WorkflowNodeType.CONNECTOR) {
          pIdx = parentsIterator.child.connector.findIndex(
            (p) => p.compId === node.compId
          );
          if (pIdx > -1) draft.child.connector[pIdx] = { ...node };
        }
      });
    }

    const newWorkflow = produce(prevWorkflow, (draft) => {
      JsonUtils.overrideNode(
        draft,
        "compId",
        newParentsIterator.compId,
        "child",
        newParentsIterator.child
      );
    });

    this._updateWorkflow(dispatch, newWorkflow);
  }

  /**
   * Iterator에 커넥터를 연결하는 경우
   * @param {*} dispatch
   * @param {*} connectorInfo
   * @param {*} parentsIterator
   * @param {*} prevWorkflow
   */
  static addIteratorConnector(
    dispatch,
    connectorInfo,
    parentsIterator,
    prevWorkflow
  ) {
    dispatch(stackUndo(prevWorkflow));
    const newParentsIterator = { ...parentsIterator };
    const compId = newParentsIterator.compId;
    const newChild = { ...newParentsIterator.child };
    newChild.connector = [...newChild.connector, connectorInfo];
    const newWorkflow = produce(prevWorkflow, (draft) => {
      JsonUtils.overrideNode(draft, "compId", compId, "child", newChild);
    });

    this._updateWorkflow(dispatch, newWorkflow);
  }

  /**
   * 워크플로우 중 내장된 서비스로 이동
   * @param {*} dispatch
   * @param {*} service
   * @param {*} prevWorkflow
   */
  static moveToNextService(dispatch, service, prevWorkflow) {
    dispatch(stackUndo(prevWorkflow));
    const newWorkflow = produce(prevWorkflow, (draft) => {
      const { serviceContent, serviceComment, viewport, ...otherData } =
        service;
      draft.output = serviceContent;
      draft.serviceComment = serviceComment;
      draft.serviceInfo = otherData;
      draft.serviceMemo = otherData.serviceMemo;
      draft.viewport = viewport;
      draft.prevService = [
        ...prevWorkflow.prevService,
        {
          serviceUid: prevWorkflow.serviceInfo.serviceUid,
          serviceName: prevWorkflow.serviceInfo.serviceName,
        },
      ];
    });
    dispatch(updateWorkflow(newWorkflow));
    dispatch(initCommand());
    // this._updateWorkflow(dispatch, newWorkflow);
  }

  /**
   * 이전 서비스로 이동
   * @param {*} dispatch
   * @param {*} service
   * @param {*} prevWorkflow
   */
  static moveToPrevService(dispatch, service, prevWorkflow) {
    dispatch(stackUndo(prevWorkflow));
    const { serviceComment, serviceContent, ...otherData } = service;
    const newWorkflow = produce(prevWorkflow, (draft) => {
      const serviceIdx = prevWorkflow.prevService.findIndex(
        (s) => s.serviceUid === service.serviceUid
      );
      draft.prevService = draft.prevService.slice(0, serviceIdx);
      draft.output = serviceContent;
      draft.serviceComment = serviceComment;
      draft.serviceMemo = otherData.serviceMemo;
      draft.serviceInfo = otherData;
    });
    dispatch(updateWorkflow(newWorkflow));
    dispatch(initCommand());
    // this._updateWorkflow(dispatch, newWorkflow);
  }

  /**
   * 번들 추가하는 메서드
   * @param {*} dispatch
   * @param {*} prevWorkflow
   * @param {*} bundle
   */
  static saveBundle = (
    dispatch,
    prevWorkflow,
    bundle,
    positionUpdatedNodeList
  ) => {
    dispatch(stackUndo(prevWorkflow));
    //번들 적용
    const newWorkflowState = produce(prevWorkflow, (draft) => {
      if (!draft.output.bundle) draft.output.bundle = [];
      const isExistIndex = draft.output.bundle.findIndex(
        (b) => b.compId === bundle.compId
      );
      if (isExistIndex > -1) {
        draft.output.bundle[isExistIndex] = bundle;
      } else {
        draft.output.bundle.push(bundle);
      }
    });
    //포지션 변경된 노드들 적용
    const workflow = this._updateNodeList(
      positionUpdatedNodeList,
      newWorkflowState
    );
    //리덕스 업데이트
    this._updateWorkflow(dispatch, workflow);
  };

  /**
   * 번들 삭제하는 메서드
   * @param {*} dispatch
   * @param {*} prevWorkflow
   * @param {*} bundle
   */
  static deleteBundle = (dispatch, prevWorkflow, bundle, data) => {
    dispatch(stackUndo(prevWorkflow));
    /**
     * 1. 번들제거
     * 2. 번들내 노드들에 포지션 부여
     */
    const bundleDeletedWorkflow = produce(prevWorkflow, (draft) => {
      const bundleIndex = draft.output.bundle.findIndex(
        (b) => b.compId === bundle.compId
      );
      if (bundleIndex > -1) {
        draft.output.bundle.splice(bundleIndex, 1);
      }
    });
    const workflow = this._updateNodeList(data, bundleDeletedWorkflow);
    this._updateWorkflow(dispatch, workflow);
  };
}

export default WorkflowReduxHelper;
