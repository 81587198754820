/* eslint-disable jsx-a11y/alt-text */
import Popup from "components/common/Popup";
import Modal from "components/common/modal/UModal";
import { useEffect, useState } from "react";
import { BsPencilSquare } from "react-icons/bs";
import styled from "styled-components";

import UModalJavascriptEditor from "components/common/code/UModalJavascriptEditor";
import UmodalTemplate from "components/common/modal/UModalTemplate";
import StringUtils from "components/common/utils/StringUtils";
import { useRef } from "react";
import { Form, Tab, Tabs } from "react-bootstrap";

const MesFunPopupStyle = styled.div`
  .edit-source {
    border: 1px solid #ddd;
  }
`;

/**
 * TreeEventPopup : Tree Click Event Popup
 *
 * 2024.01.03 create : 김민호
 *
 * @param {*} props
 * @returns value
 */
const TreeEventPopup = (props) => {
  const {
    title,
    callbackFnc,
    item,
    entityId,
    id,
    data,
    setRetItems,
    workspace,
    onClickEventBuilder,
    onChange,
  } = props;

  const [popItems, setPopItems] = useState({});
  const eventInfo = useRef("");

  const dataRef = useRef();
  const initPopup = () => {
    dataRef.current = item.eventFn;
  };

  // closePopup: value값을 전달하고 popup 닫기
  const closePopup = (e) => {
    let retValue = { ...popItems };
    retValue.eventFn = dataRef.current;
    const _closePopup = () => {
      callbackFnc.call(this, retValue);
      Popup.close();
    };

    _closePopup();
  };

  useEffect(() => {
    initPopup();
  }, [entityId, item]);

  return (
    <MesFunPopupStyle>
      <Modal>
        <Modal.Header title={title} />
        <Modal.Body>
          <UmodalTemplate>
            <Form.Label>Event Function</Form.Label>
            <Form.Group className="mb-3">
              <UModalJavascriptEditor
                height="420px"
                defaultValue={StringUtils.defaultString(item.eventFn)}
                eventInfo={props.getEventInfo(eventInfo)}
                onClickEventBuilder={onClickEventBuilder}
                onChange={(value) => {
                  dataRef.current = value;
                }}
                callbackFnc={callbackFnc}
              ></UModalJavascriptEditor>
            </Form.Group>
          </UmodalTemplate>
        </Modal.Body>
        <Modal.Footer>
          <Modal.Footer.Button
            type="button"
            className="btn btn-primary"
            onClick={closePopup}
          >
            <BsPencilSquare />
            &nbsp;확인
          </Modal.Footer.Button>
        </Modal.Footer>
      </Modal>
    </MesFunPopupStyle>
  );
};

export default TreeEventPopup;
