import Api from "components/common/Api";

class SystemService {
  static getEnvList(data, callbackFnc) {
    Api.post("/common/getSysEnvList", data, callbackFnc);
  }
  static getCodeList(data, callbackFnc) {
    Api.post("/common/getSysCodeList", data, callbackFnc);
  }
}
export default SystemService;
