import Blockly from "blockly";
import { createMinusField, createPlusField } from "./BlockPlusMinus";

/**
 * Blockly Mutator 공식 문서 link: https://developers.google.com/blockly/guides/create-custom-blocks/extensions#mutators
 */

/**
 * Blockly Plus Minus Mutator link: https://github.com/google/blockly-samples/blob/master/plugins/block-plus-minus/src/list_create.js
 */

const mapCreateMutator = {
  itemCount_: 1,

  mutationToDom: function () {
    const container = Blockly.utils.xml.createElement("mutation");

    this.mapData.forEach((element) => {
      const data = Blockly.utils.xml.createElement("data");
      data.setAttribute("key", element.key);
      container.appendChild(data);
    });
    return container;
  },

  domToMutation: function (xmlElement) {
    const keys = [];

    for (const childNode of xmlElement.childNodes) {
      if (childNode.nodeName.toLowerCase() === "data") {
        keys.push(childNode.getAttribute("key"));
      }
    }
    this.updateShape_(keys);
  },

  saveExtraState: function () {
    const state = Object.create(null);

    if (this.mapData.length) {
      state["data"] = [];
      this.mapData.forEach((data) => {
        state["data"].push({
          key: data.key,
        });
      });
    }
    state["delData"] = this.delData;
    state["itemCount"] = this.itemCount_;
    return state;
  },

  loadExtraState: function (state) {
    const keys = [];

    if (state["data"]) {
      for (let i = 0; i < state["data"].length; i++) {
        const param = state["data"][i];
        keys.push(param["key"]);
      }
    }
    this.updateShape_(keys, state["delData"]);
  },

  updateShape_: function (keys, delData) {
    const keysData = [];
    const valuesData = [];

    if (delData) {
      this.removePart_(delData);
    }
    for (let i = 0; i <= keys.length - 1; i++) {
      keysData.push(this.getFieldValue("keyData" + keys[i]));

      if (this.getInput("valueData" + keys[i])) {
        const connection = this.getInput("valueData" + keys[i]).connection;
        if (connection.targetConnection) {
          valuesData.push(connection.targetConnection.sourceBlock_);
        } else {
          valuesData.push(null);
        }
      } else {
        valuesData.push(null);
      }
      this.removePart_(keys[i]);
    }
    this.mapData = [];
    const length = keys.length;

    for (let i = 0; i < length; i++) {
      this.addPart_(keys[i], keysData[i], valuesData[i]);
    }
  },

  plus: function () {
    this.addPart_();
  },

  minus: function (keyId) {
    if (this.itemCount_ === 0) {
      return;
    }
    this.mapData.filter((data) => data.key !== keyId);

    this.removePart_(keyId);
  },

  addPart_: function (keyId = null, keyData = null, valueData = null) {
    if (keyId) {
      this.appendValueInput("valueData" + keyId)
        .appendField(createMinusField(), keyId)
        .appendField("key")
        .appendField(new Blockly.FieldTextInput(keyData), "keyData" + keyId)
        .appendField("value");

      if (valueData && !valueData.isInsertionMarker()) {
        let connection = this.getInput("valueData" + keyId).connection;
        connection.connect(valueData.outputConnection);
      }

      this.mapData.push({
        key: keyId,
      });
    } else {
      let max = 1;

      for (const key in this.mapData) {
        if (max < this.mapData[key].key) {
          max = Number(this.mapData[key].key);
        }
      }
      max += 1;

      this.appendValueInput("valueData" + max)
        .appendField(createMinusField(), `${max}`)
        .appendField("key")
        .appendField(new Blockly.FieldTextInput(), "keyData" + max)
        .appendField("value");

      this.mapData.push({
        key: `${max}`,
      });
    }
  },

  removePart_: function (keyId = null) {
    if (keyId) {
      if (this.removeInput("valueData" + keyId, true)) {
        this.mapData = this.mapData.filter((data) => data.key !== keyId);
        this.delData = keyId;
      }
    }
  },
};

// Plus image field를 해당 Block에 생성
const listCreateHelper = function () {
  this.mapData = [];
  this.getInput(this.inputList[0].name).insertFieldAt(
    0,
    createPlusField(),
    "PLUS"
  );
};

Blockly.Extensions.registerMutator(
  "map_control",
  mapCreateMutator,
  listCreateHelper
);
