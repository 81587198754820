import Blockly from "blockly";
import { javascriptGenerator } from "blockly/javascript";
import { updateEventWorkspace } from "components/builder/eventhandler/reducer/EventHandlerAction";
import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ObjectUtils from "components/common/utils/ObjectUtils";

import { Enums } from "components/builder/BuilderEnum";
import EventHandlerReduxHelper from "components/builder/eventhandler/editor/helper/EventHandlerReduxHelper";
import Message from "components/common/Message";
import StringUtils from "components/common/utils/StringUtils";
import EventhandlerService from "services/eventhandler/EventhandlerService";

import { AppContext } from "components/common/AppContextProvider";
import ArrayUtils from "components/common/utils/ArrayUtils";
import produce from "immer";
import { EventHandlerContext } from "page/eventhandler/EventHandlerBuilderMain";
import {
  applyExtension,
  restartExtension,
} from "../render/init/blockExtension/BlockApplyExtension";
import {
  childComboType,
  comboType,
  parentComboType,
} from "../render/init/blockExtension/BlockRegisterExtension";
import EventHandlerSearch from "./EventHandlerSearch";

function EventHandlerBlockly({
  workspace: eventWorkspace,
  isReload,
  variable,
  show,
  ...props
}) {
  //ref
  const blocklyDiv = useRef();
  const initialLoaded = useRef(false);
  const isBlockCreatedRef = useRef(false);

  //redux
  const dispatch = useDispatch();
  const eventHandler = useSelector((state) => state.eventHandler);
  const savedWorkspace = useSelector((state) => state.eventHandler.workspace);
  const workspace = useSelector((state) => state.workspace);
  const output = useSelector((state) => state.outputUI.output);

  //context
  const {
    selectedFncDtl,
    parameterInfo,
    blockInfo,
    setBlockInfo,
    blockAdditionalInfo,
  } = useContext(EventHandlerContext);
  const {
    eventBuilder: { blockListContainer },
  } = useContext(AppContext);
  //state
  const [toolbarList, setToolbarList] = useState([]);
  const [blockChange, setBlockChange] = useState();
  const [addBlock, setAddBlock] = useState();

  /**
   * 좌측의 선택된 카테고리가 달라지면 작동
   */
  useEffect(() => {
    if (!ObjectUtils.isEmpty(selectedFncDtl)) {
      BlocklyWorkspaceInit()
        .then(BlockInit)
        .then(getBlockList)
        .then(updateToolBox);
    } else {
      BlocklyWorkspaceInit().then(BlockInit);
    }
  }, [selectedFncDtl]);

  /**
   * Parameter가 존재하는 이벤트가 있다면 Parameter flyout 생성
   */
  useEffect(() => {
    makeParamToolbox();
    makeGlobalVariableToolbox();
  }, [parameterInfo]);

  /**
   * redux의 EventWorkspace가 화면에 load 될 때 블럭의 정보를 Context에 저장
   * @param {*} state
   */
  const setBlockInfoContext = (state) => {
    const blockStates = state["blocks"]["blocks"];

    let result = [];
    for (const state of blockStates) {
      result = [...result, ...blockInternal(state)];
    }
    return result;
  };

  const blockInternal = (state) => {
    let result = [];
    const info = { id: state.id };
    let lastBlockInfo;
    for (let i = 0; i < blockInfo.length; i++) {
      if (state.id === blockInfo[i].id) {
        lastBlockInfo = blockInfo[i];
      }
    }
    comboType.map((combo) => {
      if (state[combo]) {
        info[combo] = state[combo];
      }
    });
    lastBlockInfo ? result.push(lastBlockInfo) : result.push(info);
    result = [...result, ...childBlockToContext(state)];
    result = [...result, ...nextBlockToContext(state)];
    return result;
  };

  /**
   * 자식 블럭 정보를 context에 저장시키기
   * @param {*} state
   * @returns
   */
  const childBlockToContext = (state) => {
    let result = [];
    if (!state["inputs"]) {
      return [];
    }
    const keys = Object.keys(state["inputs"]);
    for (let i = 0; i < keys.length; i++) {
      result = [...result, ...blockInternal(state["inputs"][keys[i]]["block"])];
    }
    return result;
  };

  /**
   * 다음 블럭 정보를 context에 저장시키기
   * @param {*} state
   * @returns
   */
  const nextBlockToContext = (state) => {
    let result = [];
    if (!state["next"]) {
      return [];
    }
    result = [...result, ...blockInternal(state["next"]["block"])];
    return result;
  };

  /**
   * combo box의 gridId, formId가 변경될 때 호출
   */
  useEffect(() => {
    if (blockChange) {
      parentComboType.map((parentFieldName) => {
        if (blockChange.name === parentFieldName) {
          childComboType.map((childFieldName) => {
            const block = Blockly.getSelected();
            if (block && block.getField(childFieldName)) {
              applyExtension(
                output,
                childFieldName,
                block,
                blockInfo,
                blockAdditionalInfo,
                true
              );
            }
          });
        }
      });
    }
    setBlockChange();
  }, [blockChange, blockInfo, blockAdditionalInfo]);

  useEffect(() => {
    //코드 드랍 이벤트
    if (!ObjectUtils.isEmpty(blockAdditionalInfo)) {
      const onDropBlock = (event) => {
        if (
          event.type === Blockly.Events.BLOCK_MOVE &&
          isBlockCreatedRef.current
        ) {
          isBlockCreatedRef.current = false;
          const block = Blockly.getSelected();

          Blockly.getSelected().setCollapsed(false, true);

          // 새롭게 추가된 블럭에 자식 블럭을 추가
          const blockContents =
            eventWorkspace.current.options.languageTree.contents;

          for (const key in blockContents) {
            if (
              blockContents[key].type === block.type &&
              blockContents[key].loadChildBlocks
            ) {
              blockContents[key].loadChildBlocks.map((childBlock) => {
                let inputName;
                for (const key in childBlock) {
                  inputName = key;
                }

                if (!block.getInput(inputName).connection.targetBlock()) {
                  Blockly.serialization.workspaces.update(
                    childBlock[inputName],
                    eventWorkspace.current,
                    block,
                    inputName,
                    false
                  );
                }
              });

              break;
            }
          }

          setAddBlock(block);
        }
      };

      if (eventWorkspace.current) {
        eventWorkspace.current.addChangeListener(onDropBlock);
      }
    }
  }, [blockInfo, blockAdditionalInfo]);

  // block이 새롭게 추가되는 경우 실행
  useEffect(() => {
    // 사용자 정의 함수 또는 자주 쓰는 기능 블럭을 드래그 했을 때 Block 생성
    if (addBlock) {
      if (addBlock.dropUserFnc) {
        addBlock.dropUserFnc();
        addBlock.dropUserFnc = null;
      }

      const newBlockInfo = produce(blockInfo, (draft) => {
        const workspaceBlocks = eventWorkspace.current.getAllBlocks();
        const newInfos = [];
        for (let i = 0; i < workspaceBlocks.length; i++) {
          let newInfo = { id: workspaceBlocks[i].id };
          for (let j = 0; j < draft.length; j++) {
            if (draft[j].id === workspaceBlocks[i].id) {
              newInfo = draft[j];
              break;
            }
          }
          newInfos.push(newInfo);
        }
        return newInfos;
      });

      setBlockInfo(newBlockInfo);
      setAddBlock(null);
    }
  }, [addBlock, blockInfo]);

  /**
   * block이 새롭게 추가되는 경우, EventBuilder로 왔을 경우, block의 dropdodwn에 옵션을 추가할 경우 실행
   */
  useEffect(() => {
    if (eventWorkspace.current) {
      restartExtension(
        output,
        eventWorkspace,
        blockInfo,
        blockAdditionalInfo,
        true
      );
      setUpdateBlockDropdownFnc(eventWorkspace);
      setSaveBlockDropdownFnc(eventWorkspace);
    }
  }, [blockInfo, blockAdditionalInfo]);

  /**
   * dropdown Field 가 있을 경우 dropdownd에 item을 추가할 때 context 업데이트 하기
   * @param {*} eventWorkspace
   */

  const setUpdateBlockDropdownFnc = (eventWorkspace) => {
    if (!ArrayUtils.isEmpty(eventWorkspace.current.getAllBlocks())) {
      eventWorkspace.current.getAllBlocks().map((block) => {
        block.updateBlockDropdownContext = function (newOption, fieldName) {
          const newBlockInfo = produce(blockInfo, (draft) => {
            for (let i = 0; i < draft.length; i++) {
              if (draft[i].id === this.id) {
                if (draft[i][fieldName]) {
                  const additionalOption =
                    draft[i][fieldName].additionalOption &&
                    !ArrayUtils.isEmpty(draft[i][fieldName].additionalOption)
                      ? [...draft[i][fieldName].additionalOption, ...newOption]
                      : newOption;

                  draft[i][fieldName].additionalOption = additionalOption;
                  return;
                } else {
                  draft[i][fieldName] = {};
                  draft[i][fieldName].additionalOption = newOption;
                  return;
                }
              }
            }
          });
          setBlockInfo(newBlockInfo);
        };
      });
    }
  };

  /**
   * 화면의 Block들을 redux에 저장할 때 실행
   * @param {*} eventWorkspace
   */
  const setSaveBlockDropdownFnc = (eventWorkspace) => {
    if (!ArrayUtils.isEmpty(eventWorkspace.current.getAllBlocks())) {
      eventWorkspace.current.getAllBlocks().map((block) => {
        block.saveBlockDropdownContext = function (fieldName) {
          for (let i = 0; i < blockInfo.length; i++) {
            if (blockInfo[i].id === this.id) {
              return blockInfo[i][fieldName];
            }
          }
        };
      });
    }
  };

  /**
   * 워크스페이스 최초 설정
   * @returns {Promise}
   */
  const BlocklyWorkspaceInit = () => {
    return new Promise((resolve, reject) => {
      if (!eventWorkspace.current && !initialLoaded.current) {
        eventWorkspace.current = Blockly.inject(blocklyDiv.current, {
          toolbox: {
            kind: "flyoutToolbox",
            contents: [],
          },
          renderer: "ZELOS",
          theme: {
            blockStyles: {
              start: {
                hat: "cap",
              },
            },
            fontStyle: {
              weight: "bold",
            },
            startHats: "true",
          },
          grid: { spacing: 20, length: 1, colour: "#ccc", snap: true },
          trashcan: false,
          sounds: false,
          scrollbars: true,
          move: {
            drag: true,
            wheel: false,
          },
          zoom: {
            controls: false,
            wheel: true,
            pinch: true,
            startScale: 0.75,
            maxScale: 5,
            minScale: 0.2,
          },
        });

        setEventWorkspaceEvent(eventWorkspace);

        // Flyout position 지정 함수
        eventWorkspace.current.getFlyout().position = function () {
          if (!this.isVisible() || !this.targetWorkspace.isVisible()) {
            return;
          }
          if (this.width_ < 270) {
            this.width_ = 270;
          }
          const metricsManager = this.targetWorkspace.getMetricsManager();
          const targetWorkspaceViewMetrics = metricsManager.getViewMetrics();
          this.height_ = targetWorkspaceViewMetrics.height;

          const edgeWidth = this.width_ - this.CORNER_RADIUS;
          const edgeHeight =
            targetWorkspaceViewMetrics.height - 2 * this.CORNER_RADIUS;
          this.setBackgroundPath(edgeWidth, edgeHeight);

          const x = this.getX();
          const y = this.getY();

          let searchHeight = document
            .getElementsByClassName("blockly-ws-search")[0]
            .getAttribute("height");
          searchHeight = searchHeight.replace(/px/g, "");

          this.positionAt_(
            this.width_,
            this.height_ - searchHeight,
            x,
            y + searchHeight
          );
        };

        eventWorkspace.current.getFlyout().position();

        /**
         * Compute width of flyout.  toolbox.Position mat under each block.
         * For RTL: Lay out the blocks and buttons to be right-aligned.
         */
        eventWorkspace.current.getFlyout().reflowInternal_ = function () {
          this.workspace_.scale = this.getFlyoutScale();
          let flyoutWidth = 0;
          const blocks = this.workspace_.getTopBlocks(false);
          for (let i = 0, block; (block = blocks[i]); i++) {
            let width = block.getHeightWidth().width;
            if (block.outputConnection) {
              width -= this.tabWidth_;
            }
            flyoutWidth = Math.max(flyoutWidth, width);
          }
          for (let i = 0, button; (button = this.buttons_[i]); i++) {
            flyoutWidth = Math.max(flyoutWidth, button.width);
          }
          flyoutWidth += this.MARGIN * 1.5 + this.tabWidth_;
          flyoutWidth *= this.workspace_.scale;
          flyoutWidth += Blockly.Scrollbar.scrollbarThickness;

          if (this.width_ !== flyoutWidth) {
            for (let i = 0, block; (block = blocks[i]); i++) {
              if (this.RTL) {
                // With the flyoutWidth known, right-align the blocks.
                const oldX = block.getRelativeToSurfaceXY().x;
                let newX = flyoutWidth / this.workspace_.scale - this.MARGIN;
                if (!block.outputConnection) {
                  newX -= this.tabWidth_;
                }
                block.moveBy(newX - oldX, 0);
              }
              if (this.rectMap_.has(block)) {
                this.moveRectToBlock_(this.rectMap_.get(block), block);
              }
            }
            if (this.RTL) {
              // With the flyoutWidth known, right-align the buttons.
              for (let i = 0, button; (button = this.buttons_[i]); i++) {
                const y = button.getPosition().y;
                const x =
                  flyoutWidth / this.workspace_.scale -
                  button.width -
                  this.MARGIN -
                  this.tabWidth_;
                button.moveTo(x, y);
              }
            }

            this.width_ = flyoutWidth;
            this.position();
            this.targetWorkspace.recordDragTargets();
          }
        };
        // Flyout scale 1로 고정
        eventWorkspace.current.getFlyout().getFlyoutScale = function () {
          return 0.75;
        };
      }

      resolve(true);
    });
  };

  /**
   * EventWorkspace에 이벤트 리스너 추가
   * @param {*} eventWorkspace
   */
  const setEventWorkspaceEvent = (eventWorkspace) => {
    // 코드 업데이트
    const updateCode = (event) => {
      if (event.type === Blockly.Events.BLOCK_CREATE) {
        isBlockCreatedRef.current = true;
      }
      if (
        event.type === Blockly.Events.BLOCK_MOVE ||
        event.type === Blockly.Events.BLOCK_CHANGE ||
        event.type === Blockly.Events.BLOCK_DELETE
      ) {
        const state = Blockly.serialization.workspaces.save(
          eventWorkspace.current
        );
        blockToCode();
        if (output.page) {
          dispatch(updateEventWorkspace(state));
        }
        if (event.type === Blockly.Events.BLOCK_CHANGE) {
          setBlockChange(event);
        } else {
        }
      }
    };

    // 선택한 Block이 다른 Block의 뒤에 가려지지 않도록 하는 함수
    const blockSelect = (event) => {
      if (event.type === Blockly.Events.BLOCK_DRAG) {
        if (Blockly.getSelected() instanceof Blockly.BlockSvg) {
          const blockCanvas = eventWorkspace.current.getBlockCanvas();
          let itemBlock;
          for (const child of blockCanvas.children) {
            if (child.getAttribute("data-id") === Blockly.getSelected().id) {
              itemBlock = child;
              break;
            }
          }
          if (itemBlock) {
            blockCanvas.appendChild(itemBlock);
          }
        }
      }
    };

    eventWorkspace.current.addChangeListener(updateCode);
    eventWorkspace.current.addChangeListener(blockSelect);
  };

  const BlockInit = () => {
    //blockList 조회
    return new Promise((resolve, reject) => {
      /**
       * 리덕스에 있는 항목을 워크스페이스에 로드
       */
      const getStoreWorkspace = () => {
        // 저장된 화면 불러오기
        if (!ObjectUtils.isEmpty(savedWorkspace)) {
          Blockly.serialization.workspaces.load(
            savedWorkspace,
            eventWorkspace.current
          );
          const result = setBlockInfoContext(savedWorkspace);
          setBlockInfo(result);
          restartExtension(
            output,
            eventWorkspace,
            result,
            blockAdditionalInfo,
            true
          );
          blockToCode();
        }
      };

      resolve(blockListContainer);
      getStoreWorkspace();
    });
  };

  /**
   * Block을 Code를 변환하는 로직
   */
  const blockToCode = () => {
    const allVariables = eventWorkspace.current.getVariablesOfType("varType");
    let useBlocksId = [];
    let varCode = "";

    eventWorkspace.current.getAllBlocks(true).map((block) => {
      if (block.getVars().length > 0) {
        block.getVars().map((id) => {
          useBlocksId.push(id);
        });
      }
    });

    // 변수 블럭이 있을 경우, 변수 선언 코드를 넣어준다
    if (useBlocksId.length > 0) {
      varCode = "var ";

      for (let i = 0; i < allVariables.length; i++) {
        if (useBlocksId.includes(allVariables[i].id_)) {
          varCode += allVariables[i].name + ", ";
        }
      }

      varCode = varCode.slice(0, -2);
      varCode += ";\n\n";
    }

    const code = javascriptGenerator.workspaceToCode(eventWorkspace.current);
    const newCode = varCode + code;
    EventHandlerReduxHelper.updateEventOutput(dispatch, newCode, eventHandler);
  };

  /**
   * 블록 리스트 호출
   * @return Promise
   */
  const getBlockList = (list) => {
    return new Promise((resolve, reject) => {
      const thisFncDtlBlock = list.filter(
        (fnc) => fnc.eventFncDtl.fncDtlId === selectedFncDtl.fncDtlId
      );
      setToolbarList(thisFncDtlBlock);
      resolve(thisFncDtlBlock);
    });
  };

  /**
   * 브로콜리 좌측 블록리스트 상태 업데이트
   * @param {Array} list
   */
  const updateToolBox = (list) => {
    if (StringUtils.equalsIgnoreCase(selectedFncDtl.type, "favorite")) {
      //자주 쓰는 기능
      const { eventCd, targetType, programType, eventType } =
        eventHandler.componentProp;

      if (eventCd && targetType && programType && eventType) {
        EventhandlerService.getOftenUseBlockList(
          {
            eventCd,
            targetType,
            eventType,
          },
          (res) => {
            const responseData = res.data.map((data) => {
              const obj = { ...data };
              obj.blockOption = JSON.parse(data.blockOption);
              return obj;
            });

            let contents = [];

            responseData.map((data) => {
              const readOnlyJson = {
                type: data.blockName,
                message0: `${data.blockName} %1 %2`,
                args0: [
                  {
                    type: "input_dummy",
                    name: "dummy1",
                  },
                  {
                    type: "input_statement",
                    name: "statement1",
                  },
                ],
                colour: "#CEBAAD",
                previousStatement: null,
                nextStatement: null,
              };

              javascriptGenerator.forBlock[`${data.blockName}`] = function (
                block,
                generator
              ) {
                const statementCode = generator.statementToCode(
                  block,
                  "statement1"
                );
                const code = statementCode;
                return code;
              };

              Blockly.Blocks[`${data.blockName}`] = {
                init: function () {
                  this.jsonInit(readOnlyJson);
                },
                dropUserFnc: function () {
                  if (!this.execute) {
                    Blockly.serialization.workspaces.update(
                      data.blockOption,
                      eventWorkspace.current,
                      this,
                      "statement1",
                      false
                    );
                    this.execute = true;
                  }
                },
                saveExtraState: function () {
                  return {
                    execute: this.execute,
                  };
                },
                loadExtraState: function (state) {
                  this.execute = state["execute"];
                },
              };

              contents.push({
                kind: "block",
                type: `${data.blockName}`,
                collapsed: true,
                tooltip: data.blockDesc,
              });
            });

            eventWorkspace.current.updateToolbox({
              kind: "flyoutToolbox",
              contents: contents,
            });
          }
        );
      }
    } else if (StringUtils.equalsIgnoreCase(selectedFncDtl.type, "custom")) {
      const { eventCd, targetType, programType, eventType } =
        eventHandler.componentProp;

      if (eventCd && targetType && programType && eventType) {
        // 사용자 정의 함수
        EventhandlerService.getFncCustomList(
          {
            appReleaseId: workspace.appReleaseId,
            tenantId: workspace.tenantId,
            coCd: workspace.coCd,
            eventCd,
            targetType,
            programType,
            eventType,
            includeBlockYn: "Y",
          },
          (res) => {
            const responseData = res.data.map((data) => {
              const obj = { ...data };
              obj.eventWorkspace = JSON.parse(data.eventWorkspace);
              return obj;
            });
            //리턴 데이터 작업

            let contents = [];

            responseData.map((data) => {
              const readOnlyJson = {
                type: data.eventHandleNm,
                message0: `사용자 정의 ${data.eventHandleNm} %1 %2`,
                args0: [
                  {
                    type: "input_dummy",
                    name: "dummy1",
                  },
                  {
                    type: "input_statement",
                    name: "statement1",
                  },
                ],
                colour: "#CEBAAD",
                previousStatement: null,
                nextStatement: null,
              };

              javascriptGenerator.forBlock[
                `사용자 정의 ${data.eventHandleNm}`
              ] = function (block, generator) {
                const statementCode = generator.statementToCode(
                  block,
                  "statement1"
                );
                const code = statementCode;
                return code;
              };

              Blockly.Blocks[`사용자 정의 ${data.eventHandleNm}`] = {
                init: function () {
                  this.jsonInit(readOnlyJson);
                },
                dropUserFnc: function () {
                  if (!this.execute) {
                    Blockly.serialization.workspaces.update(
                      data.eventWorkspace,
                      eventWorkspace.current,
                      this,
                      "statement1",
                      false
                    );
                    this.execute = true;
                  }
                },
                saveExtraState: function () {
                  return {
                    execute: this.execute,
                  };
                },
                loadExtraState: function (state) {
                  this.execute = state["execute"];
                },
              };

              contents.push({
                kind: "block",
                type: `사용자 정의 ${data.eventHandleNm}`,
                collapsed: true,
                tooltip: data.eventHandleNm,
              });
            });

            eventWorkspace.current.updateToolbox({
              kind: "flyoutToolbox",
              contents: contents,
            });
          }
        );
      } else {
        Message.alert(
          "대상 컴포넌트가 지정되지 않았습니다.",
          Enums.MessageType.WARN
        );
      }
    } else {
      eventWorkspace.current.updateToolbox({
        kind: "flyoutToolbox",
        contents: list.map((block) => {
          const obj = {
            kind: "block",
            type: block.blockName,
            icons: JSON.parse(block.blockOption)?.icons,
            inputs: setChildBlock(JSON.parse(block.blockOption)?.childBlock),
            fields: JSON.parse(block.blockOption)?.fields,
            collapsed: JSON.parse(block.blockOption)?.icons ? true : false,
            loadChildBlocks: JSON.parse(block.blockOption)?.loadChildBlock,
          };
          return obj;
        }),
      });
    }
  };

  /**
   * 자식 Block 설정
   * @param {*} childBlock
   * @returns
   */
  const setChildBlock = (childBlock) => {
    if (!childBlock) {
      return;
    }
    for (const key in childBlock) {
      for (let i = 0; i < blockListContainer.length; i++) {
        const block = blockListContainer[i];

        if (block.blockName === childBlock[key].block.type) {
          childBlock[key].block.inputs = setChildBlock(
            JSON.parse(block.blockOption)?.childBlock
          );
          break;
        }
      }
    }
    return childBlock;
  };

  // Create Parameter Flyout
  const makeParamToolbox = () => {
    let contents = [];

    if (!ObjectUtils.isEmpty(parameterInfo)) {
      contents.push({
        kind: "label",
        text: "매개변수",
        "web-class": "blocklyLabelStyle",
      });
      parameterInfo.eventHandlerArgsList.map((arg) => {
        const obj = {
          kind: "block",
          type: "readOnlyTextBlock",
          fields: {
            readOnlyInput1: [arg.argsVariable, arg.argsDesc],
          },
        };
        contents.push(obj);
      });
    } else {
      return;
    }

    if (eventWorkspace.current.parameterFlyout) {
      eventWorkspace.current.parameterFlyout.show(contents);
      return;
    } else {
      // workspace에 parameterFlyout 추가 함수
      eventWorkspace.current.addParameterFlyout = function (tagName) {
        const workspaceOptions = new Blockly.Options({
          parentWorkspace: this,
          rtl: this.RTL,
          oneBasedIndex: this.options.oneBasedIndex,
          horizontalLayout: false,
          renderer: this.options.renderer,
          rendererOverrides: this.options.rendererOverrides,
          move: {
            scrollbars: true,
          },
        });

        // change Flyout Positon to Top
        this.options.toolboxPosition = Blockly.utils.toolbox.Position.TOP;
        workspaceOptions.toolboxPosition = this.options.toolboxPosition;

        const HorizontalFlyout = Blockly.registry.getClassFromOptions(
          Blockly.registry.Type.FLYOUTS_HORIZONTAL_TOOLBOX,
          this.options,
          true
        );
        this.parameterFlyout = new HorizontalFlyout(workspaceOptions);

        this.parameterFlyout.autoClose = false;
        this.parameterFlyout.getWorkspace().setVisible(true);

        return this.parameterFlyout.createDom(tagName);
      };
      const svg = document.getElementsByClassName("blocklySvg");

      // parameter flyout 생성
      const parameterFlyout = eventWorkspace.current.addParameterFlyout(
        Blockly.utils.Svg.SVG
      );

      Blockly.utils.dom.insertAfter(parameterFlyout, svg[0]);
    }

    const parameterFlyout = eventWorkspace.current.parameterFlyout;

    parameterFlyout.SCROLLBAR_MARGIN = -10;

    // parameter flyout init
    parameterFlyout.init(eventWorkspace.current);

    parameterFlyout.CORNER_RADIUS = 0;

    // parameter flyout position 설정
    parameterFlyout.position = function () {
      if (!this.isVisible() || !this.targetWorkspace.isVisible()) {
        return;
      }
      const metricsManager = this.targetWorkspace.getMetricsManager();
      const targetWorkspaceViewMetrics = metricsManager.getViewMetrics();
      this.width_ = targetWorkspaceViewMetrics.width / 2;

      const edgeWidth =
        targetWorkspaceViewMetrics.width - 2 * this.CORNER_RADIUS;
      const edgeHeight = this.height_ - this.CORNER_RADIUS;

      this.setBackgroundPath(edgeWidth, edgeHeight);

      const x = this.getX();
      const y = this.getY();

      let searchWidth = document
        .getElementsByClassName("blockly-ws-search")[0]
        .getAttribute("width");
      searchWidth = searchWidth.replace(/px/g, "");

      this.positionAt_(
        this.width_,
        this.height_,
        Number(x) + Number(searchWidth),
        y
      );
    };

    // parameter flyout scale
    parameterFlyout.getFlyoutScale = function () {
      return 0.75;
    };

    // parameter flyout 높이 설정
    parameterFlyout.reflowInternal_ = function () {
      this.workspace_.scale = this.getFlyoutScale();
      let flyoutHeight = 0;
      const blocks = this.workspace_.getTopBlocks(false);
      for (let i = 0, block; (block = blocks[i]); i++) {
        flyoutHeight = Math.max(
          flyoutHeight,
          block.getHeightWidth().height * parameterFlyout.getFlyoutScale()
        );
      }
      const buttons = this.buttons_;
      for (let i = 0, button; (button = buttons[i]); i++) {
        flyoutHeight = Math.max(flyoutHeight, button.height);
      }
      // flyoutHeight += this.MARGIN * 1.5;
      // flyoutHeight *= this.workspace_.scale;
      // flyoutHeight += Blockly.Scrollbar.scrollbarThickness;
      flyoutHeight = 35;

      if (this.height_ !== flyoutHeight) {
        for (let i = 0, block; (block = blocks[i]); i++) {
          if (this.rectMap_.has(block)) {
            this.moveRectToBlock_(this.rectMap_.get(block), block);
          }
        }

        this.height_ = flyoutHeight;
        this.position();
        this.targetWorkspace.resizeContents();
        this.targetWorkspace.recordDragTargets();
      }
    };

    // parameter flyout의 contents 위치 조정
    parameterFlyout.layout_ = function (contents, gaps) {
      this.workspace_.scale = this.targetWorkspace.scale;
      this.MARGIN = 3;
      const margin = this.MARGIN;
      let cursorX = margin + this.tabWidth_;
      const cursorY = margin;
      if (this.RTL) {
        contents = contents.reverse();
      }

      for (let i = 0, item; (item = contents[i]); i++) {
        if (item.type === "block") {
          const block = item.block;
          const allBlocks = block.getDescendants(false);
          for (let j = 0, child; (child = allBlocks[j]); j++) {
            // Mark blocks as being inside a flyout.  This is used to detect and
            // prevent the closure of the flyout if the user right-clicks on such
            // a block.
            child.isInFlyout = true;
          }
          const root = block.getSvgRoot();
          const blockHW = block.getHeightWidth();
          // Figure out where to place the block.
          const tab = block.outputConnection ? this.tabWidth_ : 0;
          let moveX;
          if (this.RTL) {
            moveX = cursorX + blockHW.width;
          } else {
            moveX = cursorX - tab;
          }

          block.moveBy(moveX, cursorY);

          const rect = this.createRect_(block, moveX, cursorY, blockHW, i);
          cursorX += blockHW.width + gaps[i];

          this.addBlockListeners_(root, block, rect);
        } else if (item.type === "button") {
          const button = item.button;
          this.initFlyoutButton_(button, cursorX, 11);
          cursorX += button.width + gaps[i];
        }
      }
    };

    parameterFlyout.setMetrics_ = function (xyRatio) {
      if (!this.isVisible()) {
        return;
      }

      const metricsManager = this.workspace_.getMetricsManager();
      const scrollMetrics = metricsManager.getScrollMetrics();
      const viewMetrics = metricsManager.getViewMetrics();
      const absoluteMetrics = metricsManager.getAbsoluteMetrics();

      if (typeof xyRatio.x === "number") {
        this.workspace_.scrollX = -(
          scrollMetrics.left +
          (scrollMetrics.width - viewMetrics.width) * xyRatio.x
        );
      }
      this.workspace_.translate(
        this.workspace_.scrollX + absoluteMetrics.left,
        this.workspace_.scrollY + absoluteMetrics.top
      );
    };

    parameterFlyout.svgGroup_.setAttribute("id", "parameterFlyout");
    parameterFlyout.svgBackground_.setAttribute(
      "id",
      "parameterFlyoutBackground"
    );

    // parameter flyout show
    eventWorkspace.current.parameterFlyout.show(contents);

    if (typeof parameterFlyout.scrollToStart === "function") {
      parameterFlyout.scrollToStart();
    }
  };

  // Create Global Variable Flyout
  const makeGlobalVariableToolbox = () => {
    let contents = [];

    if (!ObjectUtils.isEmpty(parameterInfo)) {
      contents.push({
        kind: "label",
        text: "전역변수",
        // "web-class": "myLabelStyle"
      });
      contents.push({
        kind: "block",
        type: "readOnlyTextBlock",
        fields: {
          readOnlyInput1: ["$param", "$param"],
        },
      });

      contents.push({
        kind: "block",
        type: "readOnlyTextBlock",
        fields: {
          readOnlyInput1: ["variable", "variable"],
        },
      });
    } else {
      return;
    }

    if (eventWorkspace.current.globalVariableFlyout) {
      eventWorkspace.current.globalVariableFlyout.show(contents);
      return;
    } else {
      // workspace에 전역변수 flyout 추가 함수
      eventWorkspace.current.addGlobalVariableFlyout = function (tagName) {
        const workspaceOptions = new Blockly.Options({
          parentWorkspace: this,
          rtl: this.RTL,
          oneBasedIndex: this.options.oneBasedIndex,
          horizontalLayout: false,
          renderer: this.options.renderer,
          rendererOverrides: this.options.rendererOverrides,
          move: {
            scrollbars: true,
          },
        });

        // change Flyout Positon to Top
        this.options.toolboxPosition = Blockly.utils.toolbox.Position.TOP;
        workspaceOptions.toolboxPosition = this.options.toolboxPosition;

        const HorizontalFlyout = Blockly.registry.getClassFromOptions(
          Blockly.registry.Type.FLYOUTS_HORIZONTAL_TOOLBOX,
          this.options,
          true
        );
        this.globalVariableFlyout = new HorizontalFlyout(workspaceOptions);

        this.globalVariableFlyout.autoClose = false;
        this.globalVariableFlyout.getWorkspace().setVisible(true);

        return this.globalVariableFlyout.createDom(tagName);
      };
      const svg = document.getElementsByClassName("blocklySvg");

      // 전역변수 flyout 생성
      const globalVariableFlyout =
        eventWorkspace.current.addGlobalVariableFlyout(Blockly.utils.Svg.SVG);

      Blockly.utils.dom.insertAfter(globalVariableFlyout, svg[0]);
    }

    const globalVariableFlyout = eventWorkspace.current.globalVariableFlyout;

    globalVariableFlyout.SCROLLBAR_MARGIN = -10;

    globalVariableFlyout.init(eventWorkspace.current);

    globalVariableFlyout.CORNER_RADIUS = 0;

    // 전역변수 flyout 위치 지정
    globalVariableFlyout.position = function () {
      if (!this.isVisible() || !this.targetWorkspace.isVisible()) {
        return;
      }

      const metricsManager = this.targetWorkspace.getMetricsManager();
      const targetWorkspaceViewMetrics = metricsManager.getViewMetrics();
      this.width_ = targetWorkspaceViewMetrics.width;

      const edgeWidth =
        targetWorkspaceViewMetrics.width - 2 * this.CORNER_RADIUS;
      const edgeHeight = this.height_ - this.CORNER_RADIUS;

      this.setBackgroundPath(edgeWidth, edgeHeight);

      let x = this.getX();
      const y = this.getY();

      let searchWidth = document
        .getElementsByClassName("blockly-ws-search")[0]
        .getAttribute("width");
      searchWidth = searchWidth.replace(/px/g, "");
      x =
        Number(x + searchWidth) +
        Number(this.targetWorkspace.parameterFlyout.width_);

      this.positionAt_(this.width_ / 2, this.height_, x, y);
    };

    // 전역변수 flyout scale
    globalVariableFlyout.getFlyoutScale = function () {
      return 0.75;
    };

    // 전역변수 flyout 높이 설정
    globalVariableFlyout.reflowInternal_ = function () {
      this.workspace_.scale = this.getFlyoutScale();
      let flyoutHeight = 0;
      const blocks = this.workspace_.getTopBlocks(false);
      for (let i = 0, block; (block = blocks[i]); i++) {
        flyoutHeight = Math.max(
          flyoutHeight,
          block.getHeightWidth().height * globalVariableFlyout.getFlyoutScale()
        );
      }
      const buttons = this.buttons_;
      for (let i = 0, button; (button = buttons[i]); i++) {
        flyoutHeight = Math.max(flyoutHeight, button.height);
      }
      // flyoutHeight += this.MARGIN * 1.5;
      // flyoutHeight *= this.workspace_.scale;
      // flyoutHeight += Blockly.Scrollbar.scrollbarThickness;
      flyoutHeight = 35;

      if (this.height_ !== flyoutHeight) {
        for (let i = 0, block; (block = blocks[i]); i++) {
          if (this.rectMap_.has(block)) {
            this.moveRectToBlock_(this.rectMap_.get(block), block);
          }
        }

        this.height_ = flyoutHeight;
        this.position();
        this.targetWorkspace.resizeContents();
        this.targetWorkspace.recordDragTargets();
      }
    };

    // 전역변수 flyout의 contents 위치 조정
    globalVariableFlyout.layout_ = function (contents, gaps) {
      this.workspace_.scale = this.targetWorkspace.scale;
      this.MARGIN = 3;
      const margin = this.MARGIN;
      let cursorX = margin + this.tabWidth_;
      const cursorY = margin;
      if (this.RTL) {
        contents = contents.reverse();
      }

      for (let i = 0, item; (item = contents[i]); i++) {
        if (item.type === "block") {
          const block = item.block;
          const allBlocks = block.getDescendants(false);
          for (let j = 0, child; (child = allBlocks[j]); j++) {
            // Mark blocks as being inside a flyout.  This is used to detect and
            // prevent the closure of the flyout if the user right-clicks on such
            // a block.
            child.isInFlyout = true;
          }
          const root = block.getSvgRoot();
          const blockHW = block.getHeightWidth();
          // Figure out where to place the block.
          const tab = block.outputConnection ? this.tabWidth_ : 0;
          let moveX;
          if (this.RTL) {
            moveX = cursorX + blockHW.width;
          } else {
            moveX = cursorX - tab;
          }

          block.moveBy(moveX, cursorY);

          const rect = this.createRect_(block, moveX, cursorY, blockHW, i);
          cursorX += blockHW.width + gaps[i];

          this.addBlockListeners_(root, block, rect);
        } else if (item.type === "button") {
          const button = item.button;
          this.initFlyoutButton_(button, cursorX, 11);
          cursorX += button.width + gaps[i];
        }
      }
    };

    globalVariableFlyout.svgGroup_.setAttribute("id", "globalVariableFlyout");

    globalVariableFlyout.svgBackground_.setAttribute(
      "id",
      "globalVariableFlyoutBackground"
    );

    // 전역 변수 flyout show
    eventWorkspace.current.globalVariableFlyout.show(contents);

    if (typeof globalVariableFlyout.scrollToStart === "function") {
      globalVariableFlyout.scrollToStart();
    }
  };

  return (
    <div
      id="blocklyDiv"
      ref={blocklyDiv}
      className={`blocklyDiv ${show ? "show" : "hidden"}`}
    >
      <EventHandlerSearch eventWorkspace={eventWorkspace} />
    </div>
  );
}

export default EventHandlerBlockly;
