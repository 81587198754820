import Blockly from "blockly";

/**
 * Deserializes the blocks defined by the given state into the given
 * workspace.
 *
 * @param state The state of the blocks to deserialize.
 * @param workspace The workspace to deserialize into.
 */
Blockly.serialization.blocks.BlockSerializer.prototype.load = function (
  state,
  workspace
) {
  const blockStates = state["blocks"];
  for (const state of blockStates) {
    Blockly.serialization.blocks.append(state, workspace, {
      recordUndo: Blockly.Events.getRecordUndo(),
    });
  }
};

/**
 * Loads the block represented by the given state into the given workspace.
 *
 * @param state The state of a block to deserialize into the workspace.
 * @param workspace The workspace to add the block to.
 * @param param1 recordUndo: If true, events triggered by this function will be
 *     undo-able by the user. False by default.
 * @returns The block that was just loaded.
 */
Blockly.serialization.blocks.append = function (
  state,
  workspace,
  { recordUndo = false } = {}
) {
  const block = appendInternal(state, workspace, { recordUndo });
  if (workspace.rendered) Blockly.renderManagement.triggerQueuedRenders();
  return block;
};
/**
 * Loads the block represented by the given state into the given workspace.
 * This is defined internally so that the extra parameters don't clutter our
 * external API.
 * But it is exported so that other places within Blockly can call it directly
 * with the extra parameters.
 *
 * @param state The state of a block to deserialize into the workspace.
 * @param workspace The workspace to add the block to.
 * @param param1 parentConnection: If provided, the system will attempt to
 *     connect the block to this connection after it is created. Undefined by
 *     default. isShadow: If true, the block will be set to a shadow block after
 *     it is created. False by default. recordUndo: If true, events triggered by
 *     this function will be undo-able by the user. False by default.
 * @returns The block that was just appended.
 * @internal
 */
export const appendInternal = (
  state,
  workspace,
  { parentConnection = undefined, isShadow = false, recordUndo = false } = {}
) => {
  const prevRecordUndo = Blockly.Events.getRecordUndo();
  Blockly.Events.setRecordUndo(recordUndo);
  const existingGroup = Blockly.Events.getGroup();
  if (!existingGroup) {
    Blockly.Events.setGroup(true);
  }
  Blockly.Events.disable();

  let block;
  try {
    block = appendPrivate(state, workspace, { parentConnection, isShadow });
  } finally {
    Blockly.Events.enable();
  }

  if (Blockly.Events.isEnabled()) {
    Blockly.Events.fire(
      new (Blockly.Events.get(Blockly.Events.BLOCK_CREATE))(block)
    );
  }
  Blockly.Events.setGroup(existingGroup);
  Blockly.Events.setRecordUndo(prevRecordUndo);

  // Adding connections to the connection db is expensive. This defers that
  // operation to decrease load time.
  if (workspace.rendered) {
    const blockSvg = block;
    setTimeout(() => {
      if (!blockSvg.disposed) {
        blockSvg.setConnectionTracking(true);
      }
    }, 1);
  }

  return block;
};

/**
 * Loads the block represented by the given state into the given workspace.
 * This is defined privately so that it can be called recursively without firing
 * eroneous events. Events (and other things we only want to occur on the top
 * block) are handled by appendInternal.
 *
 * @param state The state of a block to deserialize into the workspace.
 * @param workspace The workspace to add the block to.
 * @param param1 parentConnection: If provided, the system will attempt to
 *     connect the block to this connection after it is created. Undefined by
 *     default. isShadow: The block will be set to a shadow block after it is
 *     created. False by default.
 * @returns The block that was just appended.
 */
const appendPrivate = (
  state,
  workspace,
  { parentConnection = undefined, isShadow = false } = {}
) => {
  if (!state["type"]) {
    throw new Blockly.serialization.exceptions.MissingBlockType(state);
  }

  const block = workspace.newBlock(state["type"], state["id"]);
  block.setShadow(isShadow);
  loadCoords(block, state);
  loadAttributes(block, state);
  loadExtraState(block, state);
  tryToConnectParent(parentConnection, block, state);
  loadIcons(block, state);
  loadFields(block, state);
  loadInputBlocks(block, state);
  loadNextBlocks(block, state);
  initBlock(block, workspace.rendered);

  return block;
};
/**
 * Applies any coordinate information available on the state object to the
 * block.
 *
 * @param block The block to set the position of.
 * @param state The state object to reference.
 */
const loadCoords = (block, state) => {
  let x = state["x"] === undefined ? 0 : state["x"];
  const y = state["y"] === undefined ? 0 : state["y"];

  const workspace = block.workspace;
  x = workspace.RTL ? workspace.getWidth() - x : x;

  block.moveBy(x, y);
};

/**
 * Applies any attribute information available on the state object to the block.
 *
 * @param block The block to set the attributes of.
 * @param state The state object to reference.
 */
const loadAttributes = (block, state) => {
  if (state["collapsed"]) {
    block.setCollapsed(true);
  }
  if (state["deletable"] === false) {
    block.setDeletable(false);
  }
  if (state["movable"] === false) {
    block.setMovable(false);
  }
  if (state["editable"] === false) {
    block.setEditable(false);
  }
  if (state["enabled"] === false) {
    block.setEnabled(false);
  }
  if (state["inline"] !== undefined) {
    block.setInputsInline(state["inline"]);
  }
  if (state["data"] !== undefined) {
    block.data = state["data"];
  }
};

/**
 * Applies any extra state information available on the state object to the
 * block.
 *
 * @param block The block to set the extra state of.
 * @param state The state object to reference.
 */
const loadExtraState = (block, state) => {
  if (!state["extraState"]) {
    return;
  }
  if (block.loadExtraState) {
    block.loadExtraState(state["extraState"]);
  } else if (block.domToMutation) {
    block.domToMutation(Blockly.utils.xml.textToDom(state["extraState"]));
  }
};

/**
 * Attempts to connect the block to the parent connection, if it exists.
 *
 * @param parentConnection The parent connection to try to connect the block to.
 * @param child The block to try to connect to the parent.
 * @param state The state which defines the given block
 */
const tryToConnectParent = (parentConnection, child, state) => {
  if (!parentConnection) {
    return;
  }

  if (parentConnection.getSourceBlock().isShadow() && !child.isShadow()) {
    throw new Blockly.serialization.exceptions.RealChildOfShadow(state);
  }

  let connected = false;
  let childConnection;
  if (parentConnection.type === Blockly.inputTypes.VALUE) {
    childConnection = child.outputConnection;
    if (!childConnection) {
      throw new Blockly.serialization.exceptions.MissingConnection(
        "output",
        child,
        state
      );
    }
    connected = parentConnection.connect(childConnection);
  } else {
    // Statement type.
    childConnection = child.previousConnection;
    if (!childConnection) {
      throw new Blockly.serialization.exceptions.MissingConnection(
        "previous",
        child,
        state
      );
    }
    connected = parentConnection.connect(childConnection);
  }

  if (!connected) {
    const checker = child.workspace.connectionChecker;
    throw new Blockly.serialization.exceptions.BadConnectionCheck(
      checker.getErrorMessage(
        checker.canConnectWithReason(childConnection, parentConnection, false),
        childConnection,
        parentConnection
      ),
      parentConnection.type === Blockly.inputTypes.VALUE
        ? "output connection"
        : "previous connection",
      child,
      state
    );
  }
};

/**
 * Applies icon state to the icons on the block, based on the given state
 * object.
 *
 * @param block The block to set the icon state of.
 * @param state The state object to reference.
 */
const loadIcons = (block, state) => {
  if (!state["icons"]) return;

  const iconTypes = Object.keys(state["icons"]);
  for (const iconType of iconTypes) {
    const iconState = state["icons"][iconType];
    let icon = block.getIcon(iconType);
    if (!icon) {
      const constructor = Blockly.registry.getClass(
        Blockly.registry.Type.ICON,
        iconType,
        false
      );
      if (!constructor)
        throw new Blockly.serialization.exceptions.UnregisteredIcon(
          iconType,
          block,
          state
        );
      icon = new constructor(block);
      block.addIcon(icon);
    }
    if (Blockly.isSerializable(icon)) {
      icon.loadState(iconState);
      if (icon.loadPartCollapsedState) {
        icon.loadPartCollapsedState(state["icons"]["partCollapsed"]);
      }
    }
  }
};

/**
 * Applies any field information available on the state object to the block.
 *
 * @param block The block to set the field state of.
 * @param state The state object to reference.
 */
const loadFields = (block, state) => {
  if (!state["fields"]) {
    return;
  }
  const keys = Object.keys(state["fields"]);
  for (let i = 0; i < keys.length; i++) {
    const fieldName = keys[i];
    const fieldState = state["fields"][fieldName];
    const field = block.getField(fieldName);
    if (!field) {
      console.warn(
        `Ignoring non-existant field ${fieldName} in block ${block.type}`
      );
      continue;
    }
    field.loadState(fieldState);
  }
};

/**
 * Creates any child blocks (attached to inputs) defined by the given state
 * and attaches them to the given block.
 *
 * @param block The block to attach input blocks to.
 * @param state The state object to reference.
 */
const loadInputBlocks = (block, state) => {
  if (!state["inputs"]) {
    return;
  }
  const keys = Object.keys(state["inputs"]);
  for (let i = 0; i < keys.length; i++) {
    const inputName = keys[i];
    const input = block.getInput(inputName);
    if (!input || !input.connection) {
      throw new Blockly.serialization.exceptions.MissingConnection(
        inputName,
        block,
        state
      );
    }
    loadConnection(input.connection, state["inputs"][inputName]);
  }
};

/**
 * Creates any next blocks defined by the given state and attaches them to the
 * given block.
 *
 * @param block The block to attach next blocks to.
 * @param state The state object to reference.
 */
const loadNextBlocks = (block, state) => {
  if (!state["next"]) {
    return;
  }
  if (!block.nextConnection) {
    throw new Blockly.serialization.exceptions.MissingConnection(
      "next",
      block,
      state
    );
  }
  loadConnection(block.nextConnection, state["next"]);
};
/**
 * Applies the state defined by connectionState to the given connection, ie
 * assigns shadows and attaches child blocks.
 *
 * @param connection The connection to deserialize the connected blocks of.
 * @param connectionState The object containing the state of any connected
 *     shadow block, or any connected real block.
 */
const loadConnection = (connection, connectionState) => {
  if (connectionState["shadow"]) {
    connection.setShadowState(connectionState["shadow"]);
  }
  if (connectionState["block"]) {
    appendPrivate(
      connectionState["block"],
      connection.getSourceBlock().workspace,
      { parentConnection: connection }
    );
  }
};

// TODO(#5146): Remove this from the serialization system.
/**
 * Initializes the give block, eg init the model, inits the svg, renders, etc.
 *
 * @param block The block to initialize.
 * @param rendered Whether the block is a rendered or headless block.
 */
const initBlock = (block, rendered) => {
  if (rendered) {
    const blockSvg = block;
    // Adding connections to the connection db is expensive. This defers that
    // operation to decrease load time.
    blockSvg.setConnectionTracking(false);

    blockSvg.initSvg();
    blockSvg.queueRender();

    // fixes #6076 JSO deserialization doesn't
    // set .iconXY_ property so here it will be set
    for (const icon of blockSvg.getIcons()) {
      icon.onLocationChange(blockSvg.getRelativeToSurfaceXY());
    }
  } else {
    block.initModel();
  }
};

/**
 * field에 값을 set한다.
 * @param {*} block
 * @param {*} state
 */
const loadFieldState = (block, state) => {
  const keys = Object.keys(state["fields"]);
  for (let i = 0; i < keys.length; i++) {
    const fieldName = keys[i];
    const fieldState = state["fields"][fieldName];
    const field = block.getField(fieldName);
    if (!field) {
      console.warn(
        `Ignoring non-existant field ${fieldName} in block ${block.type}`
      );
      continue;
    }
    field.loadState(fieldState);
  }
};
