import UIComponent from "components/builder/ui/uiComponents/UIComponent";
import { PropertiesHeader } from "components/builder/ui/uiComponents/UIComponentStyle";
import { ComponentSavePopupButton } from "page/popup/ComponentSavePopup";

class MiscComponent extends UIComponent {
  constructor(props) {
    super(props);
    //불필요하면 constructor 지우세요.
  }

  /**
   * 해당 Component의 Title
   * @param {*} child
   * @returns
   */
  renderComponentTitle = (child) => {
    return (
      <PropertiesHeader>
        <div>
          {"기타 Components > "}
          {child}
        </div>
        <ComponentSavePopupButton
          getComponentCodeFromOutput={this.props.fn.getComponentCodeFromOutput}
          componentInfo={this.props.componentInfo}
        />
      </PropertiesHeader>
    );
  };
}

export default MiscComponent;
