import styled, { css } from "styled-components";
import * as Enums from "components/builder/BuilderEnum";
import { useEffect } from "react";
import { useState } from "react";
import StringUtils from "components/common/utils/StringUtils";
import { BsThreeDots } from "react-icons/bs";
import { InputPopupStyle } from "components/common/element/UInputPopup";

export const PropertiesHeader = styled.div`
  font-size: 14px;
  margin: 10px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PropertyLable = styled.div`
  width: 25%;

  ${({ requried }) => {
    return requried
      ? `:after {
      content: ' *';
    }`
      : null;
  }}
`;

export const PropertyValue = styled.div`
  width: 75%;
  padding: 4px 0px 4px 4px;
`;

export const PropertyFieldSet = styled.div`
  width: 100%;
`;
export const PropertyFieldSetContents = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

export const EntityEditorPanel = styled.div`
  ${({ programType, pagePropertyValue }) => {
    let reducedHeight = Enums.Style.HEADER_HEIGHT;
    return css`
      height: calc(100vh - ${reducedHeight}px - 4px - 30px);
    `;
  }}
`;

export const EntityTabProps = styled.div`
  width: 100%;
  height: 100%;
  padding: 15px;
`;

export const PropertySelect = styled.select`
  width: 100%;
  height: 25px;
  border-radius: 5px;
  padding: 5px 8px;

  ::before {
    content: "";
  }

  :disabled {
    background-color: lightgray;
  }
`;

export const EInputPopup = ({
  id,
  value,
  onChange,
  onClick,
  className,
  style,
  onBlur,
  disabled,
}) => {
  const [pValue, setpValue] = useState("");
  useEffect(() => {
    setpValue(value);
  }, [value]);

  return (
    <InputPopupStyle className={className} style={style}>
      <div className="input-container">
        <input
          type="text"
          id={id}
          value={pValue}
          className="form-control form-control-sm"
          onBlur={onBlur}
          onChange={onChange}
          disabled={disabled}
        />
        <span className="btn-span">
          <button
            className="btn btn-sm"
            onClick={onClick}
            formTarget={id}
            disabled={disabled}
          >
            <BsThreeDots />
          </button>
        </span>
      </div>
    </InputPopupStyle>
  );
};
