/*!
 * Builder Detect activation Component Reducer for react v.17
 *
 * 현재 Activation 되어있는 Component/Layout을 감지하기 이한 Reducer
 *
 *   Author: Bizentro
 *   Date: 2021-04
 */

import ActionType from "components/builder/BuilderActionType";
import produce from "immer";

const initialState = {
  component: {},
};

/**
 * UI Builder에서 component item을 active , deactive 하여 다른 component(프로그램)에서 active,deactive 된 component를 감지하여 re-redering토록함.
 * action type subfix는 ENT (API Builder : API, Report Builder : RPT)
 *
 * @param {*} state
 * @param {*} action
 * @returns
 */
export default function ActivedComponentReducer(state = initialState, action) {
  switch (action.type) {
    case ActionType.DATA_MODEL.ACTIVATE.ENTITY: {
      return {
        // ...state,
        // component: action.component,
        ...action.component,
      };
    }
    case ActionType.DATA_MODEL.ACTIVATE.PROPERTY_UPDATE: {
      if (Array.isArray(action.payload)) {
        return produce(state, (draft) => {
          action.payload.map((_state) => {
            draft[_state.id] = _state.value;
          });
        });
      } else {
        return produce(state, (draft) => {
          draft[action.payload.id] = action.payload.value;
        });
      }
    }
    case ActionType.DATA_MODEL.ACTIVATE.INIT: {
      return initialState;
    }
    default:
      return state;
  }
}
