import { Enums } from "components/builder/BuilderEnum";
import Message from "components/common/Message";
import ArrayUtils from "components/common/utils/ArrayUtils";
import StringUtils from "components/common/utils/StringUtils";
import User from "components/common/utils/UserUtils";
import React, { useEffect, useState } from "react";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import SettingService from "services/common/SettingService";
import {
  defaultShortKeySet,
  EditorShortKeyName,
  KeyCodeSet,
  TopNavShortKeyName,
} from "components/setting/section/shortKey/ShortKeyConfig";
import ShortKeyItem from "components/setting/section/shortKey/ShortKeyItem";

function Short() {
  const [keySet, setKeySet] = useState(defaultShortKeySet);

  useEffect(() => {
    const keyset = User.getConfiguration(
      Enums.ConfigurationDefine.SHORT_KEY_SET
    );
    if (keyset) setKeySet({ ...defaultShortKeySet, ...keyset });
  }, []);

  const onSetKey = (keyName, code) => {
    const newKeySet = { ...keySet };
    newKeySet[keyName] = code;
    setKeySet(newKeySet);
  };

  const onSaveKey = (e) => {
    e.preventDefault();
    let pass = true;
    for (let keyArr of Object.values(keySet)) {
      if (!ArrayUtils.isEmpty(keyArr)) {
        const dupCheck = Object.values(keySet).filter((ks) =>
          StringUtils.equalsIgnoreType(ks, keyArr)
        );
        if (dupCheck.length > 1) {
          pass = false;
          break;
        }
      }
    }
    if (!pass) {
      return Message.alert(
        "중복된 단축키가 있습니다. 단축키를 확인해주세요.",
        Enums.MessageType.ERROR
      );
    }
    const body = {
      configurationCode: Enums.ConfigurationDefine.SHORT_KEY_SET,
      configurationValue: JSON.stringify(keySet),
    };

    SettingService.saveUserConfig(body, (res) => {
      const key = res.data.configurationCode;
      const value = JSON.parse(res.data.configurationValue);
      User.overrideConfig(key, value);
      Message.alert("단축키가 설정되었습니다.", Enums.MessageType.SUCCESS);
    });
  };

  const onInitKey = (e) => {
    Message.confirm("지정된 키를 초기화 하시겠습니까?", () => {
      setKeySet(defaultShortKeySet);
    });
  };

  const ShortKeyInfo = (
    <Popover title="가능 단축키" style={{ border: "1px solid lightgray" }}>
      <div
        style={{
          width: "100%",
          background: "#3c3c3c",
          color: "white",
          padding: "5px 10px",
        }}
      >
        설정 가능 단축키
      </div>
      <div
        style={{
          width: "250px",
          padding: "3px",
          display: "flex",
          gap: "3px",
          overflow: "auto",
          flexWrap: "wrap",
        }}
      >
        {Object.values(KeyCodeSet).map((char) => (
          <div
            style={{
              borderRadius: "5px",
              background: "lightgray",
              padding: "3px",
              minWidth: "30px",
              height: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "2px outset lightgray",
            }}
            key={char}
          >
            {char}
          </div>
        ))}
      </div>
    </Popover>
  );
  return (
    <>
      <div className="setting-header">단축키 설정</div>
      <div className="setting-container shortkey">
        <span
          style={{ color: "#3c3c3caa", fontSize: "smaller", padding: "3px 0" }}
        >
          <OverlayTrigger
            trigger={"click"}
            placement="right"
            overlay={ShortKeyInfo}
          >
            <Button variant="outline-secondary" size="sm">
              설정 가능 단축키 보기
            </Button>
          </OverlayTrigger>
          <br />
          Ctrl 키는 시스템 이벤트와 겹치는 부분이 많아서 제외하였습니다.
          <br />
          브라우저 및 데스크탑 단축키와 겹쳐서 발생하는 이벤트에 주의해주세요.
          <br />
          예) ALT + F4 (종료) 등 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>

        <h4>빌더</h4>
        <div className="keyset">
          <div className="header">
            <div>기능명</div>
            <div>단축키</div>
          </div>

          {Object.keys(TopNavShortKeyName).map((key) => {
            return (
              <ShortKeyItem
                key={key}
                name={TopNavShortKeyName[key]}
                keyMappingName={key}
                keyCode={keySet[key] || defaultShortKeySet[key]}
                onSetKey={onSetKey}
              />
            );
          })}
        </div>
        <h4>에디터</h4>
        <div className="keyset">
          <div className="header">
            <div>기능명</div>
            <div>단축키</div>
          </div>
          {Object.keys(EditorShortKeyName).map((key) => {
            return (
              <ShortKeyItem
                key={key}
                name={EditorShortKeyName[key]}
                keyMappingName={key}
                keyCode={keySet[key] || defaultShortKeySet[key]}
                onSetKey={onSetKey}
              />
            );
          })}
        </div>
      </div>
      <div className="setting-button">
        <Button onClick={onSaveKey}>저장</Button>
        <Button variant="outline-success" onClick={onInitKey}>
          초기화
        </Button>
      </div>
    </>
  );
}

export default Short;
