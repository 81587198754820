import Api from "components/common/Api";
import SessionUtils from "components/common/utils/SessionUtils";
import User from "components/common/utils/UserUtils";

class ComponentService {
  static getComponentList(data, callbackFnc) {
    Api.get("/common/componentList", data, callbackFnc);
  }
  /**
   * 서비스 컴포넌트 호출
   * @param {*} data
   * @param {*} callbackFnc
   * @returns
   */
  static getServiceComponentList(data, callbackFnc) {
    if (!SessionUtils.LoggedCheck()) return false;
    Api.post("/common/getSvcComponentList", data, (res) => {
      res.data = this.generateFolderTree(res.data);
      callbackFnc(res);
    });
  }

  /**
   * 서비스 컴포넌트 저장
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static saveServiceComponent(data, callbackFnc, errCallbackFnc) {
    Api.post("/common/saveServiceComponent", data, callbackFnc, errCallbackFnc);
  }

  /**
   * 서비스 컴포넌트 삭제
   * @param {*} data
   * @param {*} callbackFnc
   */
  static deleteServiceComponent(data, callbackFnc) {
    Api.post("/common/deleteServiceComponent", data, callbackFnc);
  }
  /**
   * svcComponentMst 카테고리 추가
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static saveCategory(data, callbackFnc, errCallbackFnc) {
    Api.post("/common/saveCategory", data, callbackFnc, errCallbackFnc);
  }
  /**
   * 컴포넌트 발리데이션 목록 가져오는 함수
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static getValidationRuleList(data, callbackFnc, errCallbackFnc) {
    Api.post(
      "/common/getValidationRuleList",
      data,
      callbackFnc,
      errCallbackFnc
    );
  }

  /**
   * 컴포넌트 template 목록 가져오는 함수
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static getComponentTemplateList(data, callbackFnc, errCallbackFnc) {
    Api.post(
      "/common/getComponentTemplateList",
      data,
      callbackFnc,
      errCallbackFnc
    );
  }

  /**
   * 컴포넌트 template  가져오는 함수
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static getComponentTemplate(data, callbackFnc, errCallbackFnc) {
    Api.post("/common/getComponentTemplate", data, callbackFnc, errCallbackFnc);
  }

  /**
   * Button component event중 Grid용 event조회
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static selectGridButtonEvent(data, callbackFnc, errCallbackFnc) {
    data.componentClass = "form/Button";
    data.useYn = "Y";
    Api.post(
      "/common/selectGridButtonEvent",
      data,
      callbackFnc,
      errCallbackFnc
    );
  }

  /**
   * SVC MST 삭제하는 API
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static deleteSvcComponentMst(data, callbackFnc, errCallbackFnc) {
    Api.post(
      "/common/deleteSvcComponentMst",
      data,
      callbackFnc,
      errCallbackFnc
    );
  }

  /**
   * 서비스 컴포넌트를 계층식으로 바꾸는 함수
   * @param {Array} _componentList
   * @returns {Array}
   */
  static generateFolderTree = (_componentList) => {
    let TopLevelCategory = [..._componentList];

    /**
     *
     * @param {Array} _dtlList DTL 전체 리스트
     * @param {Integer} _parentsId DB에서 쓰이는 PK
     * @param {String} _parents 트리 부모
     * @returns
     */
    const generateDtlFolderTree = (_dtlList = [], _parentsId = 0, _parents) => {
      let DtlList = [];
      if (_dtlList.length > 0) {
        const files = [..._dtlList].filter(
          (dtl) => dtl.parentsId === _parentsId
        );
        files.map((file) => {
          const { svcComponentDtlId, componentClass } = file;
          file.treeNodeId = `p${file.parentsId}c${file.svcComponentDtlId}`;
          file.parents = _parents;
          file.treeOrder = [...(_parents.treeOrder || []), _parents.treeNodeId];
          if (componentClass?.indexOf("Folder") > -1) {
            file.child = generateDtlFolderTree(
              _dtlList,
              svcComponentDtlId,
              file
            );
          }
          return file;
        });
        return files;
      } else {
        return DtlList;
      }
    };

    const GeneratedTopLevel = TopLevelCategory.map((category) => {
      const newCategory = { ...category };
      newCategory.treeNodeId = `${category.svcComponentMstId}${category.moduleCd}`;
      newCategory.child = generateDtlFolderTree(
        category.serviceComponentDtl,
        0,
        newCategory
      );
      return newCategory;
    });
    return GeneratedTopLevel;
  };
}
export default ComponentService;
