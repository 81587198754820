import Blockly from "blockly";

/**
 * Blockly Mutator 공식 문서 link: https://developers.google.com/blockly/guides/create-custom-blocks/extensions#mutators
 */

/**
 *
 * @param {*} blockList DB (e_event_fnc_block_infO)의 list
 * @returns
 */
function BlockCallBackMutator(blockList) {
  if (Blockly.Extensions.isRegistered("callback_create")) {
    return;
  }
  if (Blockly.Extensions.isRegistered("param_create")) {
    return;
  }

  let parentBlock;

  /**
   * callback 함수를 가지는 부모 Block의 Mutator 정의
   */
  const callbackCreateMutator = {
    itemCount_: 0,

    mutationToDom: function () {
      const container = Blockly.utils.xml.createElement("mutation");
      container.setAttribute("items", this.itemCount_);
      return container;
    },

    domToMutation: function (xmlElement) {
      const targetCount = parseInt(xmlElement.getAttribute("items"), 10);
      this.updateShape_(targetCount);
    },

    saveExtraState: function () {
      const state = Object.create(null);

      state["itemCount"] = this.itemCount_;
      return state;
    },

    loadExtraState: function (state) {
      this.updateShape_(state["itemCount"]);
    },

    updateShape_: function (targetCount) {},
  };

  const listCreateHelper = function () {
    // 현재 Block에 parameter 속성값을 초기화 한다.
    for (let i = 0; i < blockList.length; i++) {
      if (blockList[i].blockName === this.type) {
        if (JSON.parse(blockList[i].blockOption)?.parameter) {
          this.parameter = JSON.parse(blockList[i].blockOption).parameter;

          if (JSON.parse(blockList[i].blockOption)?.returnType) {
            this.returnType = JSON.parse(blockList[i].blockOption).returnType;
          }
          parentBlock = this;
          break;
        }
      }
    }
  };

  Blockly.Extensions.registerMutator(
    "callback_create",
    callbackCreateMutator,
    listCreateHelper
  );

  /**
   * callback Block의 Mutator 정의
   */
  const paramCreateMutator = {
    itemCount_: 0,

    mutationToDom: function () {
      const container = Blockly.utils.xml.createElement("mutation");
      container.setAttribute("items", this.itemCount_);

      this.paramData.map((element) => {
        const data = Blockly.utils.xml.createElement("data");
        data.setAttribute("param", element);
        container.appendChild(data);
      });

      return container;
    },

    domToMutation: function (xmlElement) {
      const params = [];

      for (const childNode of xmlElement.childNodes) {
        if (childNode.nodeName.toLowerCase() === "data") {
          params.push(childNode.getAttribute("param"));
        }
      }

      this.updateShape_(params);
    },

    saveExtraState: function () {
      const state = Object.create(null);

      if (this.paramData.length) {
        state["paramData"] = [];
        this.paramData.map((data) => {
          state["paramData"].push(data);
        });
      }

      state["itemCount"] = this.itemCount_;
      state["returnType"] = this.returnType;

      return state;
    },

    loadExtraState: function (state) {
      this.updateShape_(state["itemCount"], state["paramData"]);
    },

    updateShape_: function (targetCount, paramData) {
      if (!paramData || paramData.length === 0) return;
      if (targetCount < paramData.length) {
        this.addField(paramData);
      }
    },

    addField: function (paramData) {
      this.inputList[0].appendField("parameter");

      for (let j = 0; j < paramData.length; j++) {
        const field = new Blockly.FieldTextInput(paramData[j]);

        // text field 가 readonly가 되도록 변경
        field.widgetCreate_ = function () {
          const block = this.getSourceBlock();
          if (!block) {
            throw new Blockly.UnattachedFieldError();
          }
          Blockly.Events.setGroup(true);
          const div = Blockly.WidgetDiv.getDiv();

          const clickTarget = this.getClickTarget_();
          if (!clickTarget) throw new Error("A click target has not been set.");
          Blockly.utils.dom.addClass(clickTarget, "editing");

          const htmlInput = document.createElement("input");
          htmlInput.className = "blocklyHtmlInput";
          // AnyDuringMigration because:  Argument of type 'boolean' is not assignable
          // to parameter of type 'string'.
          htmlInput.setAttribute("spellcheck", this.spellcheck_);
          const scale = this.workspace_.getScale();
          const fontSize =
            this.getConstants().FIELD_TEXT_FONTSIZE * scale + "pt";
          div.style.fontSize = fontSize;
          htmlInput.style.fontSize = fontSize;
          let borderRadius = Blockly.FieldTextInput.BORDERRADIUS * scale + "px";

          if (this.isFullBlockField && this.isFullBlockField()) {
            const bBox = this.getScaledBBox();

            // Override border radius.
            borderRadius = (bBox.bottom - bBox.top) / 2 + "px";
            // Pull stroke colour from the existing shadow block
            const strokeColour = block.getParent()
              ? block.getParent().style.colourTertiary
              : this.sourceBlock_.style.colourTertiary;
            htmlInput.style.border = 1 * scale + "px solid " + strokeColour;
            div.style.borderRadius = borderRadius;
            div.style.transition = "box-shadow 0.25s ease 0s";
            if (this.getConstants().FIELD_TEXTINPUT_BOX_SHADOW) {
              div.style.boxShadow =
                "rgba(255, 255, 255, 0.3) 0 0 0 " + 4 * scale + "px";
            }
          }
          htmlInput.style.borderRadius = borderRadius;

          div.appendChild(htmlInput);

          htmlInput.value = htmlInput.defaultValue = this.getEditorText_(
            this.value_
          );
          htmlInput.setAttribute(
            "data-untyped-default-value",
            String(this.value_)
          );
          htmlInput.setAttribute("readonly", true);

          this.resizeEditor_();

          this.bindInputEvents_(htmlInput);

          return htmlInput;
        };

        this.inputList[0].appendField(field, "parameter" + (j + 1));
        this.itemCount_++;
      }
      if (this.returnType) {
        this.appendDummyInput("return").appendField(
          "return " + this.returnType
        );
      }
    },
  };

  // 부모 Block의 parameter 속성값을 가져온다.
  const paramCreateHelper = function () {
    this.paramData = [];
    this.returnType = "";

    if (parentBlock && parentBlock.parameter) {
      parentBlock.parameter.map((param) => {
        this.paramData.push(param);
      });
    }
    if (parentBlock && parentBlock.returnType) {
      this.returnType = parentBlock.returnType;
    }
    // this.setMovable(false);
    this.updateShape_(this.itemCount_, this.paramData);
    parentBlock = null;
  };

  Blockly.Extensions.registerMutator(
    "param_create",
    paramCreateMutator,
    paramCreateHelper
  );
}

export default BlockCallBackMutator;
