import { Tooltip } from "@mui/material";
import React from "react";
import { BsCodeSlash, BsPuzzle, BsSignpost, BsStickies } from "react-icons/bs";
import { MdMiscellaneousServices } from "react-icons/md";
import { Enums } from "components/builder/BuilderEnum";
import { ImLoop2 } from "react-icons/im";

function WorkflowNodeList({ theme, ...props }) {
  const nodeList = [
    {
      icon: BsPuzzle,
      label: "Process",
      tooltip: "프로세스 생성",
      type: Enums.WorkflowNodeType.PROCESS,
    },
    {
      icon: MdMiscellaneousServices,
      label: "Service",
      tooltip: "다른 서비스 불러오기",
      type: Enums.WorkflowNodeType.SERVICE,
    },
    {
      icon: ImLoop2,
      label: "Iterator",
      tooltip: "반복 구문 추가",
      type: Enums.WorkflowNodeType.ITERATOR,
    },
    {
      icon: BsSignpost,
      label: "Condition",
      tooltip: "IF 조건절 추가",
      type: Enums.WorkflowNodeType.CONDITION,
    },
    {
      icon: BsCodeSlash,
      label: "Code",
      tooltip: "JAVA 코드 직접 입력",
      type: Enums.WorkflowNodeType.CODE,
    },
    {
      icon: BsStickies,
      label: "Memo",
      tooltip: "메모 추가",
      type: Enums.WorkflowNodeType.MEMO,
    },
  ];

  return (
    <div className={`workflow-node-list ${theme}`}>
      {nodeList.map((node, idx) => {
        return (
          <Tooltip title={node.tooltip} placement={"right"} key={node.type}>
            <>
              <DraggableEntityContents data={node} theme={theme} />
            </>
          </Tooltip>
        );
      })}
    </div>
  );
}

export default WorkflowNodeList;

export const DraggableEntityContents = ({ data, theme, ...props }) => {
  const onDragStart = (event) => {
    const StringData = JSON.stringify(data);
    event.dataTransfer.setData("application/reactflow", StringData);
    event.dataTransfer.effectAllowed = "move";
  };

  const DraggableIcon = data.icon ? data.icon : <></>;
  return (
    <div
      onDragStart={onDragStart}
      draggable
      className={`node ${theme} `}
      onDragCapture={(e) => false}
    >
      <div className="icon">
        <DraggableIcon style={{ fontSize: 18 }} className={`u-icon-Grid`} />
      </div>
      <div className="label">{data.label}</div>
    </div>
  );
};
