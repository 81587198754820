import Api from "components/common/Api";
import Message from "components/common/Message";
import * as Enums from "components/builder/BuilderEnum";
import User from "components/common/utils/UserUtils";
import Popup from "components/common/Popup";
import ConnectPopup from "page/popup/ConnectPopup";
import axios from "axios";
import StringUtils from "components/common/utils/StringUtils";
/**
<<<<<<< HEAD
 * 연결 설정 저장 및 연결
 * **/
class ConnectionService {
  static save(data, callbackFnc, errCallbackFnc) {
    Api.post("/common/saveConnection", data, callbackFnc, errCallbackFnc);
  }
  static getConnectionList(data, callbackFnc, errCallbackFnc) {
    Api.post("/common/getConnectionList", data, callbackFnc, errCallbackFnc);
  }
  static getAllConnectionList(data, callbackFnc, errCallbackFnc) {
    Api.post("/common/getAllConnectionList", data, callbackFnc, errCallbackFnc);
  }
  static test(data, callbackFnc, errCallbackFnc) {
    Api.post("/common/connectionTest", data, callbackFnc, errCallbackFnc);
  }
  static connect(data, callbackFnc, errCallbackFnc) {
    Api.post("/common/connect", data, callbackFnc, errCallbackFnc);
  }
  static getConnection(data, callbackFnc, errCallbackFnc) {
    Api.post("/common/getConnection", data, callbackFnc, errCallbackFnc);
  }
  static delete(data, callbackFnc, errCallbackFnc) {
    Api.post("/common/deleteConnection", data, callbackFnc, errCallbackFnc);
  }
  static patchSoftware(data, callbackFnc, errCallbackFnc) {
    Api.post("/common/patchSoftware", data, callbackFnc, errCallbackFnc);
  }

  static directConnect(data, callbackFnc, errCallbackFnc) {
    const { headers, url } = ConnectionService.setDirectDeployParam(data);
    const body = {
      userId: data.systemUserId,
      password: data.systemUserPw,
      tenantId: data.tenantId,
      coCd: data.coCd,
    };

    headers.moduleCd = "*";
    axios
      .post(`${url}api/daaf/builder/Authentication`, body, {
        headers,
      })
      .then((res) => {
        callbackFnc(res);
      })
      .catch((err) => {
        errCallbackFnc(err);
      });
  }

  static directConnectTest(data, callbackFnc, errCallbackFnc) {
    const { headers, url } = ConnectionService.setDirectDeployParam(data);
    const body = {
      userId: data.systemUserId,
      password: data.systemUserPw,
      tenantId: data.tenantId,
      coCd: data.coCd,
    };

    headers.moduleCd = "*";
    axios
      .post(`${url}api/daaf/builder/all/ConnectTest`, body, {
        headers,
      })
      .then((res) => {
        callbackFnc(res.data);
      })
      .catch((err) => {
        errCallbackFnc(err);
      });
  }

  /**
   *
   * @param {Object} response
   * @returns {boolean} message
   */
  static reponseValidator(response, tenantMstId) {
    const { data, isError, message, errType } = response;
    let text = "";
    let flag = false;
    if (!isError) {
      if (data) {
        flag = true;
        text = "연결 성공";
      } else if (data === null || data === undefined) {
        text =
          "데이터가 확인 되지 않습니다. 연결이 올바르지 않거나, 입력값을 확인해주세요.";
        flag = false;
      }
    } else if (message === "ConnectException: Connection refused: connect") {
      text = "인증 서버와의 연결이 확인 되지 않았습니다.";
      if (tenantMstId) {
        const con = { ...User.getConnection(tenantMstId) };
        con.expired = true;
        User.setConnection(tenantMstId, con);
      }
    } else if (errType === "Invalid") {
      //토큰이 유효하지 않을때
      text =
        message ||
        "토큰이 만료되었거나, 연결이 종료 되었습니다. 다시 연결해주세요.";
      flag = false;
    } else if (errType === "Unauthorized") {
      //토큰이 유효하지 않을때
      text =
        message ||
        "토큰이 만료되었거나, 연결이 종료 되었습니다. 다시 연결해주세요.";
      flag = false;
      if (tenantMstId) {
        const con = { ...User.getConnection(tenantMstId) };
        con.expired = true;
        User.setConnection(tenantMstId, con);
      }
    } else {
      flag = false;
      if (data) {
        switch (data.step) {
          case 1:
            text = "사용자 정보를 찾을 수 없습니다.";
            break;
          case 2:
            text = "비밀번호가 설정 되지 않았습니다.";
            break;
          case 3:
            text = message;
            break;
          case 4:
            text = "사용이 만료된 계정입니다.";
            break;
          case 5:
            text =
              "비밀번호가 만료되었습니다.<br/>시스템 비밀번호를 변경해주세요.";
            break;
          case 6:
            text =
              "최초 로그인한 계정입니다.<br/> 비밀번호 재등록 후 사용해주세요.";
            break;
          case 7:
            text =
              "로그인이 잠겼습니다. 비밀번호 초기화 및 재설정 후 사용해주세요.";
            break;
          case 19:
            text = "OTP key 발급이 필요한 시스템 계정입니다.";
            break;
          case 17:
            text = "사용자 정보를 찾을 수 없습니다.";
            break;
          case 0:
            text = "존재 하지 않는 사용자 입니다.";
            break;
          default:
            text = message;
            break;
        }
      } else {
        text = message;
      }
    }
    return { flag, text };
  }

  /**
   * 배포 서버 직접 연결 config 생성
   * @param {*} connectionInfo
   * @returns
   */
  static setDirectDeployParam(connectionInfo) {
    try {
      const { protocol, host, runtimeProtocol, runtimeHost } = connectionInfo;

      let url = protocol + "://" + host;
      if (process.env.NODE_ENV === "development") {
        // url = "/";
      }
      if (!url.endsWith("/")) {
        url += "/";
      }
      const headers = {};
      headers.language = "ko";
      if (connectionInfo.token) {
        headers.Authorization = "Bearer " + connectionInfo.token;
      }

      return { headers, url };
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * URL 주소에 Port 번호 적용
   * @param {*} urlPath
   */
  static setPortNumberToURL(urlPath) {
    let url = new URL(urlPath);
    if (!url.port) {
      if (StringUtils.equalsIgnoreCase(url.protocol, "http")) {
        url.port = 80;
      } else if (StringUtils.equalsIgnoreCase(url.protocol, "https")) {
        url.port = 443;
      }
    }

    return url.toString();
  }
}
export default ConnectionService;
