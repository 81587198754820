import ErdBuilder from "components/builder/erd/ErdBuilder";
import React from "react";
import { ReactFlowProvider } from "reactflow";
import "css/builder/erd.css";
function ErdBuilderMain() {
  return (
    <ReactFlowProvider>
      <ErdBuilder />
    </ReactFlowProvider>
  );
}

export default ErdBuilderMain;
