/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import { BsPencilSquare } from "react-icons/bs";
import Popup from "components/common/Popup";
import Modal from "components/common/modal/UModal";
import styled from "styled-components";
import CodeMirror from "@uiw/react-codemirror";
import { javascript } from "@codemirror/lang-javascript";

import StringUtils from "components/common/utils/StringUtils";
import UmodalTemplate from "components/common/modal/UModalTemplate";
import { Col, Form, Row } from "react-bootstrap";
import UInputPopup from "components/common/element/UInputPopup";
import ProgramListPopup from "page/popup/ProgramListPopup";
import IconPopup from "./IconPopup";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import USelectbox from "components/common/element/USelectbox";
import UModalJavascriptEditor from "components/common/code/UModalJavascriptEditor";
import { useRef } from "react";

const MesFunPopupStyle = styled.div`
  .edit-source {
    border: 1px solid #ddd;
  }
`;

/**
 * TabDetailConfigPopup : Tab 상세 설정 popup
 *
 * 2022.04.28: init: donghoon
 * 2024.01.02 : Code Mirror 기능 사용 유지 위한 state -> Ref로 관리 하도록 수정
 *
 * @param {*} props
 * @returns value
 */
const TabDetailConfigPopup = (props) => {
  const {
    title,
    callbackFnc,
    item,
    containerProps,
    workspace,
    setEventBuilder,
  } = props;

  const [popItems, setPopItems] = useState({});

  const [programId, setProgramId] = useState("");
  const [url, setUrl] = useState("");
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [icon, setIcon] = useState("");
  const [iconPosition, setIconPosition] = useState("");
  const [display, setDisplay] = useState("");
  // const [displayFnc, setDisplayFnc] = useState("");
  const displayFncRef = useRef("");

  const javascriptExt = [javascript(true)];
  const [extensions, setExtensions] = useState(javascriptExt);

  // closePopup: value값을 전달하고 popup 닫기
  const closePopup = (e) => {
    let retValue = { ...popItems };
    if (containerProps.tabType === "S") {
      retValue.programId = programId;
      retValue.url = url;
    } else {
      delete retValue.programId;
      delete retValue.url;
    }
    retValue.id = id;
    retValue.name = name;
    retValue.icon = icon;
    retValue.iconPosition = iconPosition;
    retValue.display = display;
    retValue.displayFnc = displayFncRef.current;

    callbackFnc.call(this, retValue);
    Popup.close();
  };
  const onChangeProgramId = (e) => {
    setProgramId(e.target.value.toUpperCase());
  };
  const onChangeUrl = (e) => {
    setUrl(e.target.value);
  };
  const onChangeId = (e) => {
    setId(e.target.value);
  };
  const onChangeName = (e) => {
    setName(e.target.value);
  };
  const onChangeIcon = (e) => {
    setIcon(e.target.value);
  };
  const onChangeIconPosition = (e) => {
    setIconPosition(e.target.value);
  };
  const onChangeDisplay = (e) => {
    setDisplay(e.target.value);
  };
  const onChangeDisplayFnc = (e) => {
    displayFncRef.current = e.target.value;
  };
  useEffect(() => {
    let initItem = { ...(item || {}) };

    setProgramId(initItem.programId);
    setUrl(initItem.url);
    setId(initItem.id);
    setName(initItem.name);
    setIcon(initItem.icon);
    setIconPosition(initItem.iconPosition);
    setDisplay(initItem.display);
    displayFncRef.current = initItem.displayFnc;
    setPopItems(initItem);
  }, [item]);

  const openProgramPopup = (e) => {
    const options = {
      effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
      style: {
        content: {
          width: "60%", //popup의 크기를 50% (default 60%)
          height: "750px",
        },
      },
    };

    Popup.open(
      <ProgramListPopup
        workspace={workspace}
        title="Tab 프로그램 불러오기"
        params={{
          programType: {
            readonly: true,
            value: "T",
          },
        }}
        callbackFnc={(data) => {
          setProgramId(data.programId);
          let el = document.getElementById("programId");
          if (el) {
            el.value = data.programId;
            el.dispatchEvent(new Event("input", { target: el, bubbles: true }));
          }
          Popup.close();
        }}
      />,
      options
    );
  };

  /**
   * Icon Popup Open
   * @param {*} e
   */
  const openPopupIcon = (e) => {
    const popOptions = {
      effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
      style: {
        content: {
          height: "80%",
        },
      },
    };

    Popup.open(
      <IconPopup
        title="Icon 조회"
        defaultValue={item.icon}
        callbackFnc={(icon) => {
          setIcon(icon);
          let el = document.getElementById("icon");
          el.value = icon;
          if (el) {
            el.value = icon;
            el.dispatchEvent(new Event("input", { target: el, bubbles: true }));
          }
        }}
      />,
      popOptions
    );
  };

  return (
    <MesFunPopupStyle>
      <Modal>
        <Modal.Header title={title} />
        <Modal.Body>
          <UmodalTemplate>
            <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Label>Tab ID</Form.Label>
                <input
                  type="text"
                  id="id"
                  defaultValue={StringUtils.defaultString(id)}
                  className="form-control form-control-sm"
                  onChange={onChangeId}
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Tab Title</Form.Label>
                <input
                  type="text"
                  id="name"
                  defaultValue={StringUtils.defaultString(name)}
                  className="form-control form-control-sm"
                  onChange={onChangeName}
                />
              </Form.Group>
            </Row>
            {containerProps.tabType === "S" ? (
              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label>프로그램 ID</Form.Label>
                  <UInputPopup
                    id="programId"
                    placeholder="프로그램 ID를 선택해주세요"
                    size="default"
                    defaultValue={StringUtils.defaultString(programId)}
                    onClick={openProgramPopup}
                    onChange={onChangeProgramId}
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>호출 URL</Form.Label>
                  <input
                    type="text"
                    id="url"
                    name="url"
                    placeholder="URL를 입력해주세요"
                    className="form-control"
                    value={StringUtils.defaultString(url)}
                    onChange={onChangeUrl}
                  />
                </Form.Group>
                <Form.Text id="programHelpBlock" muted>
                  프 로그램 ID 또는 호출 URL 입력해주세요
                </Form.Text>
              </Row>
            ) : (
              ""
            )}
            <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Label>아이콘</Form.Label>
                <UInputPopup
                  id="icon"
                  defaultValue={StringUtils.defaultString(item.icon, "")}
                  onChange={onChangeIcon}
                  onClick={openPopupIcon}
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>아이콘 위치</Form.Label>
                <USelectbox
                  id="iconPosition"
                  onChange={onChangeIconPosition}
                  defaultValue={StringUtils.defaultString(
                    iconPosition,
                    "start"
                  )}
                  items={[
                    { id: "top", text: "상단" },
                    { id: "start", text: "왼쪽" },
                    { id: "end", text: "오른쪽" },
                    { id: "bottom", text: "하단" },
                  ]}
                  options={{ matchCd: "id", matchNm: "text" }}
                />
              </Form.Group>
            </Row>
            <Form.Group className="mb-3">
              <Form.Label>Display</Form.Label>
              <Row>
                <Col>
                  <BootstrapSwitchButton
                    id="display"
                    checked={display !== false}
                    size="sm"
                    width={120}
                    onstyle="primary"
                    offstyle="dark"
                    onlabel="항상 Display"
                    offlabel="조건별 Display"
                    onChange={(value) =>
                      onChangeDisplay({
                        target: { id: "display", value: value },
                      })
                    }
                  />
                </Col>
              </Row>
            </Form.Group>
            {display === false ? (
              <Form.Group className="mb-3">
                <Form.Label>Display조건 사용자 Function</Form.Label>
                <UModalJavascriptEditor
                  height="200px"
                  defaultValue={StringUtils.defaultString(
                    displayFncRef.current
                  )}
                  onChange={(value, viewUpdate) => {
                    onChangeDisplayFnc({
                      target: { id: "displayFnc", value: value },
                    });
                  }}
                  eventInfo={props.getEventInfo()}
                  onClickEventBuilder={setEventBuilder}
                ></UModalJavascriptEditor>
                <Form.Text id="fncHelpBlock" muted>
                  활성화 (return true) , 비활성화 (return false)
                </Form.Text>
              </Form.Group>
            ) : (
              ""
            )}
          </UmodalTemplate>
        </Modal.Body>
        <Modal.Footer>
          <Modal.Footer.Button
            type="button"
            className="btn btn-primary"
            onClick={closePopup}
          >
            <BsPencilSquare />
            &nbsp;확인
          </Modal.Footer.Button>
        </Modal.Footer>
      </Modal>
    </MesFunPopupStyle>
  );
};

export default TabDetailConfigPopup;
