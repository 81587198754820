import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import { contentsNameContext } from "components/builder/entity/entityPropertiesTab/EntityField";
import Modal from "components/common/modal/UModal";
import UModalTemplate from "components/common/modal/UModalTemplate";
import Popup from "components/common/Popup";
import ObjectUtils from "components/common/utils/ObjectUtils";
import StringUtils from "components/common/utils/StringUtils";
import React, { useEffect, useState } from "react";
import {
  HiOutlineDocumentText,
  HiOutlineDocumentDuplicate,
} from "react-icons/hi";

function FieldComparePopup({
  entity,
  targetEntity,
  dataModelNm,
  onMerge: _onMerge,
  onOverwrite: _onOverwrite,
  relationKeep,
  ...props
}) {
  const [selectedField, setselectedField] = useState({});
  const [fieldList, setFieldList] = useState([]);
  const [entityFieldObject, setEntityFieldObject] = useState({}); //엔티티 필드값 비교를 위해서 사용하는 상태값
  const [isRelationKeep, setIsRelationKeep] = useState(relationKeep);
  useEffect(() => {
    fieldMerge();
    EntityFieldInit();
  }, []);

  const EntityFieldInit = () => {
    const obj = {};
    const fields = entity.dataModelEntityFields;
    fields.map((_field) => {
      obj[_field.columnNm] = { ..._field };
    });

    setEntityFieldObject(obj);
  };

  const fieldMerge = () => {
    const fields = [...entity.dataModelEntityFields];
    const targetFields = [...targetEntity.dataModelEntityFields];
    //타겟 필드를 돌면서 신규 필드 리스트에 있는 부분은 제외
    const _fieldList = targetFields.map((_field) => {
      const dupFieldsIndex = fields.findIndex(
        (f) => f.columnNm === _field.columnNm
      );
      if (dupFieldsIndex > -1) {
        fields.splice(dupFieldsIndex, 1);
      }
      return _field;
    });
    //신규 필드 리스트의 남은 항목에 newField : "Y" 속성을 넣어서 필드에 추가함

    if (fields.length > 0) {
      fields.map((_field) => {
        const obj = { ..._field };
        obj.newField = "Y";
        _fieldList.push(obj);

        return obj;
      });
    }
    setFieldList(_fieldList);
    setselectedField(_fieldList[0]);
  };

  const renderCompareBody = () => {
    if (ObjectUtils.isEmpty(selectedField)) return <></>;

    const valueDisplay = (_value) => {
      let ret = _value;
      if (_value === "Y") {
        ret = "예";
      } else if (_value === "N") {
        ret = "아니오";
      } else if (StringUtils.isEmpty(_value)) {
        ret = "없음";
      }
      return ret;
    };

    if (StringUtils.equalsIgnoreCase(selectedField.virtualYn, "Y")) {
      return (
        <>
          <div className="field-row">
            <div>{contentsNameContext["D"].columnNm()} </div>
            <div>{selectedField.columnNm}</div>
            <div></div>
          </div>
          <div className="field-row">
            <div>{contentsNameContext["D"].physFieldNm()} </div>
            <div>{selectedField.physFieldNm}</div>
            <div></div>
          </div>
          <div className="field-row">
            <div>{contentsNameContext["D"].logFieldNm()} </div>
            <div>{selectedField.logFieldNm}</div>
            <div></div>
          </div>
          <div className="field-row">
            <div>{contentsNameContext["D"].uiDisplayType()} </div>
            <div>{selectedField.uiDisplayType}</div>
            <div></div>
          </div>
          <div className="field-row">
            <div>{contentsNameContext["D"].alias()} </div>
            <div>{selectedField.alias}</div>
            <div></div>
          </div>
          <div className="field-row last">
            <div>{contentsNameContext["D"].formula()} </div>
            <div>{selectedField.formula}</div>
            <div></div>
          </div>
          <div className="info blink warning-text">
            덮어쓰기 시 가상 칼럼은 삭제됩니다.
          </div>
        </>
      );
    } else {
      return Object.keys(contentsNameContext["D"]).map((key) => {
        //비교하는 값이 다르면 텍스트 깜빡이게 함
        let equalType = StringUtils.equalsIgnoreType(
          selectedField[key],
          entityFieldObject[selectedField.columnNm][key]
        )
          ? true
          : false;
        // 한쪽은 null 다른 한쪽은 '' 으로 나오는 경우 동일 값으로 처리
        if (
          !equalType &&
          StringUtils.isEmpty(selectedField[key]) &&
          StringUtils.isEmpty(entityFieldObject[selectedField.columnNm][key])
        ) {
          equalType = true;
        }

        return (
          <div className="field-row">
            <div>{contentsNameContext["D"][key]()}</div>
            <div className={equalType ? "" : "blink warning-text"}>
              {selectedField.newField === "Y" ? (
                <></>
              ) : (
                valueDisplay(selectedField[key])
              )}
            </div>
            <div className={equalType ? "" : "blink warning-text"}>
              {valueDisplay(entityFieldObject[selectedField.columnNm][key])}
            </div>
          </div>
        );
      });
    }
  };

  const onSelectField = (e, field) => {
    if (e) e.preventDefault();
    setselectedField(field);
  };

  const onOverwrite = (e) => {
    if (_onOverwrite) _onOverwrite(e, isRelationKeep);
    Popup.close();
  };

  const onMerge = (e) => {
    if (_onMerge) _onMerge(e, isRelationKeep);
    Popup.close();
  };

  return (
    <Modal>
      <Modal.Header title={`${entity.tableNm} 필드 비교`} />
      <Modal.Body>
        <UModalTemplate className={"field-compare"}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <div>통합 필드 목록</div>
              <div className="field-list custom-scroll">
                {fieldList.map((field) => {
                  const selected = field.columnNm === selectedField.columnNm;
                  return (
                    <div
                      className={`field-item ${selected ? "selected" : ""}`}
                      key={field.columnNm}
                      onClick={(e) => onSelectField(e, field)}
                    >
                      {selected ? (
                        <HiOutlineDocumentDuplicate size={13} />
                      ) : (
                        <HiOutlineDocumentText size={13} />
                      )}

                      {field.columnNm}
                      {field.newField === "Y" ? (
                        <span className="blink warning-text">New</span>
                      ) : (
                        <></>
                      )}
                    </div>
                  );
                })}
              </div>
            </Grid>
            <Grid item xs={8}>
              <div className="compare-area">
                <div className="header field-row">
                  <div>칼럼명</div>
                  <div>현재 필드 속성</div>
                  <div>덮어 쓸 속성</div>
                </div>
                <div className="body custom-scroll">{renderCompareBody()}</div>
              </div>
            </Grid>
          </Grid>
        </UModalTemplate>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Button onClick={onMerge}>병합</Modal.Footer.Button>
        <Modal.Footer.Button variant="outline-primary" onClick={onOverwrite}>
          덮어쓰기
        </Modal.Footer.Button>
        <FormControlLabel
          control={
            <Checkbox
              id={"relationKeep"}
              checked={isRelationKeep}
              onChange={(e) => setIsRelationKeep(e.target.checked)}
              size="small"
            />
          }
          label={"릴레이션 설정 유지"}
        />
      </Modal.Footer>
    </Modal>
  );
}

export default FieldComparePopup;
