import ArrayUtils from "components/common/utils/ArrayUtils";
import ObjectUtils from "components/common/utils/ObjectUtils";
import StringUtils from "components/common/utils/StringUtils";
import React, { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { AiOutlineClose, AiOutlineSearch } from "react-icons/ai";
import { FaKeyboard, FaTrash } from "react-icons/fa";
import { VscActivateBreakpoints, VscVariableGroup } from "react-icons/vsc";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteBreakpoint,
  setBreakpoint,
  updateBreakpoint,
} from "../reducer/WorkflowDebugAction";
import produce from "immer";
import { stopEvent } from "../../ui/editor/handler/UIEditorEventHandler";
import Message from "components/common/Message";
import { Enums } from "../../BuilderEnum";
import WorkflowService from "services/workflow/WorkflowService";
import WorkflowReduxHelper from "../editor/helper/WorkflowReduxHelper";
import JsonUtils from "components/common/utils/JsonUtils";
import Popup from "components/common/Popup";
import SaveQuestPopup from "page/popup/workflow/SaveQuestPopup";
import User from "components/common/utils/UserUtils";
import LocalStorageService from "services/common/LocalService";

const TabKey = {
  BREAKPOINT: "breakpoint",
  VARIABLE: "variable",
  EXPRESSION: "expression",
};

function WorkflowDebugExpression({
  tab,
  variables,
  sendExpression,
  debugExprenssion,
  setTab,
  ...props
}) {
  const [param, setParam] = useState(variables);

  // const [selectedTab, setSelectedTab] = useState(tab);
  const [selectedVariable, setSelectedVariable] = useState("");
  const debug = useSelector((state) => state.workflowDebug);
  const [expressionInput, setExpressionInput] = useState("");
  const [selectedExpression, setSelectedExpression] = useState({});

  const [expressionList, setExpressionList] = useState([]);

  useEffect(() => {
    // setSelectedTab(tab);
    if (StringUtils.isEmpty(tab)) {
      setSelectedVariable("");
      setSelectedExpression({});
    }
  }, [tab]);
  useEffect(() => {
    setParam(variables || {});
  }, [variables]);

  useEffect(() => {
    //같은게 이미 있으면 덮어 쓰기
    if (!ObjectUtils.isEmpty(debugExprenssion)) {
      const isExist = expressionList.findIndex(
        (e) => e.expression === debugExprenssion.expression
      );

      if (isExist > -1) {
        setExpressionList(
          produce(expressionList, (draft) => {
            draft[isExist].result = debugExprenssion.result;
          })
        );
      } else {
        setExpressionList([...expressionList, debugExprenssion]);
      }
    }
  }, [debugExprenssion]);

  const getVariableType = useCallback((value) => {
    if (!value) {
      return JSON.stringify(value);
    } else if (typeof value === "number") {
      return "Number";
    } else if (typeof value === "string") {
      return "String";
    } else if (ArrayUtils.isArray(value)) {
      return "Array";
    } else if (ObjectUtils.isObject(value)) {
      return "Map";
    } else {
      return "object";
    }
  }, []);

  const getVariableValue = useCallback((value) => {
    if (!value) {
      return JSON.stringify(value);
    } else if (typeof value === "string") {
      return value;
    } else if (ArrayUtils.isArray(value)) {
      return `Array(${value.length})`;
    } else if (ObjectUtils.isObject(value)) {
      return `Map(${Object.keys(value).length})`;
    } else {
      return JSON.stringify(value);
    }
  }, []);

  const onSearchParam = () => {
    if (debug.inCommunication) {
      setExpressionInput("");
      sendExpression(expressionInput);
    }
  };

  const onDeleteExpression = (event, exp) => {
    const _e = expressionList.filter((e) => {
      return e.expression !== exp.expression;
    });
    setExpressionList(_e);
  };
  return (
    <div
      className={`debug-expression ${
        StringUtils.isEmpty(tab) ? "hidden" : "show"
      }`}
    >
      <div className="tab-wrapper">
        <div
          id={TabKey.BREAKPOINT}
          onClick={(e) => setTab(TabKey.BREAKPOINT)}
          className={`tab ${tab === TabKey.BREAKPOINT ? "selected" : ""}`}
        >
          <VscActivateBreakpoints /> Breakpoint
        </div>
        {props.isDebugging ? (
          <>
            <div
              id={TabKey.VARIABLE}
              onClick={(e) => setTab(TabKey.VARIABLE)}
              className={`tab ${tab === TabKey.VARIABLE ? "selected" : ""}`}
            >
              <VscVariableGroup /> Variable
            </div>
            <div
              id={TabKey.EXPRESSION}
              onClick={(e) => setTab(TabKey.EXPRESSION)}
              className={`tab ${tab === TabKey.EXPRESSION ? "selected" : ""}`}
            >
              <FaKeyboard /> Expression
            </div>
          </>
        ) : (
          <></>
        )}

        <div className="tab close" onClick={props.close}>
          <AiOutlineClose />
        </div>
      </div>

      {tab === TabKey.BREAKPOINT ? (
        <BreakPoints />
      ) : tab === TabKey.VARIABLE ? (
        <div className={TabKey.VARIABLE}>
          <div className="variable-list">
            <div className="variable-list-row header">
              <div className="variable-list-cell ">Name</div>
              <div className="variable-list-cell ">Value</div>
              <div className="variable-list-cell ">Type</div>
            </div>
            {Object.keys(param).map((key, index) => {
              return (
                <div
                  className={`variable-list-row contents ${
                    selectedVariable === key ? "selected" : ""
                  }`}
                  key={key}
                  onClick={(e) => setSelectedVariable(key)}
                >
                  <div className="variable-list-cell ">{key}</div>
                  <div className="variable-list-cell ">
                    {getVariableValue(param[key])}
                  </div>
                  <div className="variable-list-cell ">
                    {getVariableType(param[key])}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="variable-console">
            {StringUtils.isEmpty(selectedVariable) ? (
              "Variable을 선택하면 해당 변수의 값을 확인 할 수 있습니다."
            ) : (
              <textarea
                placeholder="Variable을 선택하면 해당 변수의 값을 확인 할 수 있습니다."
                value={JSON.stringify(param[selectedVariable], null, 2)}
                onChange={(e) => {}}
              />
            )}
          </div>
        </div>
      ) : tab === TabKey.EXPRESSION ? (
        <div className={TabKey.VARIABLE}>
          <div className="variable-list">
            <div className="variable-list-row header">
              <div className="variable-list-cell ">Name</div>
              <div className="variable-list-cell ">Value</div>
              <div className="variable-list-cell "></div>
            </div>
            {ArrayUtils.isEmpty(expressionList) ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "50px",
                }}
              >
                확인하고자 하는 표현식을 하단에서 입력해주세요
              </div>
            ) : (
              expressionList.map((e, index) => {
                return (
                  <div
                    className={`variable-list-row contents ${
                      selectedExpression === e ? "selected" : ""
                    }`}
                    key={e.expression}
                    onClick={(event) => {
                      setSelectedExpression(e);
                      setExpressionInput(e.expression);
                    }}
                  >
                    <div className="variable-list-cell ">{e.expression}</div>
                    <div className="variable-list-cell ">
                      {getVariableValue(e.result)}
                    </div>
                    <div className="variable-list-cell ">
                      <button
                        style={{ color: "tomato" }}
                        onClick={(event) => onDeleteExpression(event, e)}
                      >
                        <FaTrash />
                      </button>
                    </div>
                  </div>
                );
              })
            )}
          </div>
          <div className="variable-console">
            {ObjectUtils.isEmpty(selectedExpression) ? (
              <>
                Variable을 선택하면 해당 변수의 값을 확인 할 수 있습니다.
                <br />
                - 예시
                <br />
                <code>
                  {`
// Entity 유형이 Map 형태 일때 내부 항목을 가져오는 경우
// Entity 명 : order  찾고자 하는 항목 명 : id
    entity.get("id")

// Entity 유형이 배열 형태이고 이름이 orderList 일떄
    orderList
// 찾고자 하는 Entity 명이 orderList의 첫번째 오브젝트 일떄
    orderList.get(0)
// orderList의 5번째 오브젝트의 coCd항목을 가져 오는 경우
    orderList.get(4).get("coCd")
                    `}
                </code>
              </>
            ) : (
              <textarea
                placeholder="Variable을 선택하면 해당 변수의 값을 확인 할 수 있습니다."
                value={
                  JSON.stringify(selectedExpression.result)
                    ? JSON.stringify(selectedExpression.result, null, 2)
                    : "undefined"
                }
                onChange={(e) => {}}
              />
            )}
          </div>
          <div className="variable-input">
            <InputGroup>
              <Form.Control
                placeholder="표현식 입력"
                value={expressionInput}
                onChange={(e) => setExpressionInput(e.target.value)}
                disabled={!debug.inCommunication}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    onSearchParam();
                  }
                }}
              />
              <Button size="sm" onClick={onSearchParam}>
                <AiOutlineSearch />
              </Button>
            </InputGroup>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default WorkflowDebugExpression;

const BreakPoints = () => {
  const { breakpoint: breakpoints } = useSelector(
    (state) => state.workflowDebug
  );
  const workflow = useSelector((state) => state.workflow);
  const workspace = useSelector((state) => state.workspace);
  const [allChecked, setAllChecked] = useState(false);

  const [thisServicePoint, setThisServicePoint] = useState([]);
  const [otherPoints, setOtherPoints] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    setThisServicePoint(
      breakpoints.filter(
        (bp) => bp.serviceUid === workflow.serviceInfo.serviceUid
      )
    );
    setOtherPoints(
      breakpoints.filter(
        (bp) => bp.serviceUid !== workflow.serviceInfo.serviceUid
      )
    );
  }, [breakpoints, workflow]);

  /**
   * 브레이크 포인트 변경 이벤트
   * @param {*} e
   */
  const onChangeAll = (e) => {
    setAllChecked(e.target.checked);
    const newBreakPoint = produce(breakpoints, (draft) => {
      draft.map((bp) => {
        bp.check = e.target.checked;
      });
    });
    dispatch(setBreakpoint(newBreakPoint));
  };

  /**
   * 각 포인트 체크 이벤트
   * @param {*} e
   * @param {*} point
   */
  const onCheckPoint = (e, point) => {
    const newPoint = produce(point, (draft) => {
      draft.check = e.target.checked;
    });
    //모두가 체크 되었으면 전체 선택 체크
    const checkedList = breakpoints.filter((bp) => bp.check);
    if (e.target.checked) {
      if (
        breakpoints.length !== 0 &&
        checkedList.length + 1 === breakpoints.length
      ) {
        setAllChecked(true);
      }
    } else {
      setAllChecked(false);
    }
    dispatch(updateBreakpoint(newPoint));
  };

  const onMoveToService = (e, point) => {
    stopEvent(e);
    if (!workflow.serviceInfo.serviceUid)
      return Message.alert(
        "저장을 하신 후 이용할 수 있습니다.",
        Enums.MessageType.WARN
      );
    if (workflow.serviceInfo.serviceUid === point.serviceUid) return false;

    const moveNext = () => {
      WorkflowService.getService(point, (res) => {
        const serviceDetail = WorkflowService.setData(res.data);
        WorkflowReduxHelper.moveToNextService(
          dispatch,
          serviceDetail,
          workflow
        );
      });
    };
    WorkflowService.getService(
      { serviceUid: workflow.serviceInfo.serviceUid },
      (res) => {
        const prevService = WorkflowService.setData(res.data);
        //viewport는 다른경우가 많기 때문에 빼고 비교
        const prev = produce(prevService.serviceContent, (draft) => {
          JsonUtils.removeNode(draft, "viewport");
        });

        const next = produce(workflow.output, (draft) => {
          JsonUtils.removeNode(draft, "viewport");
        });

        const prevMemo = prevService.memo;
        const nextMemo = workflow.memo;
        if (
          JSON.stringify(prev) !== JSON.stringify(next) ||
          JSON.stringify(prevMemo) !== JSON.stringify(nextMemo)
        ) {
          /**
           * 저장 후 진행
           */
          const callback = () => {
            const body = {
              ...workflow.serviceInfo,
              serviceContent: workflow.output,
              serviceComment: workflow.serviceComment,
              serviceMemo: workflow.serviceMemo,
              useYn: "Y",
              commitComment: "",
              releaseCommentYn: "N",
              ...workspace,
            };
            WorkflowService.saveService(body, (res) => {
              WorkflowService.localStorageSave(body);
              Popup.close();
              moveNext();
            });
          };
          const showPopup = () => {
            Popup.open(
              <SaveQuestPopup callback={callback} closeCallback={moveNext} />,
              {
                effect: {
                  ...Popup.ScaleUp,
                  end: {
                    top: "30%",
                    opacity: 1,
                  },
                },
                style: { content: { width: "400px", top: "400px" } },
              }
            );
          };

          const autoSaveInfo = LocalStorageService.get(
            Enums.LocalStorageName.WORKFLOW_AUTOSAVE
          );
          if (autoSaveInfo) {
            if (
              autoSaveInfo.userId === User.getId() &&
              autoSaveInfo.autoSave === "Y"
            ) {
              //자동 저장
              callback();
            } else {
              LocalStorageService.remove(
                Enums.LocalStorageName.WORKFLOW_AUTOSAVE
              );
              showPopup();
            }
          } else {
            showPopup();
          }
        } else {
          moveNext();
        }
      }
    );
  };

  return (
    <div className={TabKey.VARIABLE}>
      <div className="variable-list" style={{ height: "100%" }}>
        <div className="breakpoint-list-row header ">
          <div className="variable-list-cell ">
            <Form.Check onChange={onChangeAll} checked={allChecked} />
          </div>
          <div className="variable-list-cell ">서비스 ID</div>
          <div className="variable-list-cell ">서비스 명</div>
          <div className="variable-list-cell ">프로세스 명</div>
        </div>
        {thisServicePoint.map((point) => {
          return (
            <div
              className="breakpoint-list-row "
              key={point.compId}
              onDoubleClick={(e) => onMoveToService(e, point)}
            >
              <div
                className="variable-list-cell "
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Form.Check
                  checked={point.check}
                  onChange={(e) => onCheckPoint(e, point)}
                />
              </div>
              <div className="variable-list-cell ">{point.serviceId}</div>
              <div className="variable-list-cell ">{point.serviceName}</div>
              <div className="variable-list-cell ">{point.processName}</div>
              <div className="variable-list-cell ">
                <button
                  style={{ color: "tomato" }}
                  onClick={(e) => dispatch(deleteBreakpoint(point))}
                >
                  <FaTrash />
                </button>
              </div>
            </div>
          );
        })}
        {otherPoints.map((point) => {
          return (
            <div
              className="breakpoint-list-row other"
              key={point.compId}
              onDoubleClick={(e) => onMoveToService(e, point)}
            >
              <div
                className="variable-list-cell "
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Form.Check
                  checked={point.check}
                  onChange={(e) => onCheckPoint(e, point)}
                />
              </div>
              <div className="variable-list-cell ">{point.serviceId}</div>
              <div className="variable-list-cell ">{point.serviceName}</div>
              <div className="variable-list-cell ">{point.processName}</div>
              <div className="variable-list-cell ">
                <button
                  style={{ color: "tomato" }}
                  onClick={(e) => dispatch(deleteBreakpoint(point))}
                >
                  <FaTrash />
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
