import { Autocomplete, TextField, Tooltip } from "@mui/material";
import { Enums } from "components/builder/BuilderEnum";
import Message from "components/common/Message";
import Popup from "components/common/Popup";
import Modal from "components/common/modal/UModal";
import UModalTemplate from "components/common/modal/UModalTemplate";
import ArrayUtils from "components/common/utils/ArrayUtils";
import ObjectUtils from "components/common/utils/ObjectUtils";
import StringUtils from "components/common/utils/StringUtils";
import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";

const TableRelationPopup = ({
  targetTable: _targetTable,
  sourceTable: _sourceTable,
  relation,
  newRelation,
  tableList: _tableList = [],
  refChangeable = true,
  ...props
}) => {
  const [sourceColumnOption, setSourceColumnOption] = useState([]);
  const [targetColumnOption, setTargetColumnOption] = useState([]);

  const sourceTable = _sourceTable;
  const tableListRef = useRef(_tableList);
  const [targetTable, setTargetTable] = useState({});
  const [tableList, setTableList] = useState([]);

  const [relationType, setRelationType] = useState("nonIdentifying");
  const [isIdentifying, setIsIdentifying] = useState(false);
  const [sourceColumn, setSourceColumn] = useState({});
  const [targetColumn, setTargetColumn] = useState({});
  const [joinColumn, setJoinColumn] = useState([]);

  useEffect(() => {
    //소스 테이블 설정
    const { propertyValue: sourcePropertyValue } = sourceTable;
    if (sourcePropertyValue.columnList) {
      setSourceColumnOption(sourcePropertyValue.columnList);
    }
    //타겟 테이블 설정
    if (_targetTable) {
      setTargetTable(_targetTable);
    }
    if (newRelation) {
      const identifyingJoinColumn = [];
      let sourcePkColumns = sourceColumnOption.filter((c) => c.pkYn === "Y");
      sourcePkColumns.map((pkc) => {
        identifyingJoinColumn.push({
          [pkc.physicalName]: pkc.physicalName,
        });
      });

      setJoinColumn(identifyingJoinColumn);
    } else if (relation) {
      setRelationType(relation.relationType);
      setJoinColumn(relation.joinColumns);
    }

    if (_tableList) {
      tableListRef.current = _tableList.filter(
        (t) => t.compId !== sourceTable.compId
      );
      setTableList(tableListRef.current);
    }
  }, []);

  useEffect(() => {
    if (!ObjectUtils.isEmpty(targetTable)) {
      const { propertyValue: targetPropertyValue } = targetTable;
      if (targetPropertyValue.columnList) {
        setTargetColumnOption(targetPropertyValue.columnList);
      }
      //테이블 전체 목록에서 타겟 테이블 검색 후
      if (tableList) {
        const tt = tableList.find(
          (t) =>
            t.propertyValue.physicalTableNm ===
            targetTable.propertyValue.physicalTableNm
        );
        if (tt) {
          setTargetTable(tt);
        }
      }
    }
  }, [targetTable]);

  useEffect(() => {
    if (relationType === "identifying") {
      setIsIdentifying(true);
    } else {
      setIsIdentifying(false);
    }
  }, [relationType]);

  /**
   * 각 컬럼 자동 조인
   * 컬럼명이 같으면 자동으로 조인한다.
   */
  const onAutoJoin = () => {
    let joinArr = [];
    for (const sc of sourceColumnOption) {
      const isJoin = targetColumnOption.find(
        (c) => c.physicalName === sc.physicalName
      );
      if (isJoin) {
        joinArr.push({ [sc.physicalName]: isJoin.physicalName });
      }
    }
    setJoinColumn(joinArr);
  };

  /**
   * 참조 추가
   */
  const onAddJoinColumn = () => {
    //기등록된 칼럼은 보이지 않도록
    const _n = [...joinColumn];

    const isSourceDup = joinColumn.find((c) => c[sourceColumn.physicalName])
      ? true
      : false;
    const isTargetDup = joinColumn.find((c) =>
      Object.values(c).find((s) => s === targetColumn.physicalName)
    )
      ? true
      : false;
    if (isSourceDup || isTargetDup) {
      return Message.alert(
        "이미 등록된 칼럼이 있습니다.",
        Enums.MessageType.WARN
      );
    }
    _n.push({
      [sourceColumn.physicalName]: targetColumn.physicalName,
    });
    setJoinColumn(_n);
  };

  /**
   * 관계 선택 함수
   * @param {*} relation
   */
  const onChangeRelation = (e) => {
    setRelationType(e.target.value);
    if (!ObjectUtils.isEmpty(targetTable) && e.target.value === "identifying") {
      autoTableRelationSet(e.target.value, targetTable);
    }
  };

  /**
   * 관계 삭제
   * @param {*} e
   * @param {*} idx
   */
  const onRemoveJoin = (e, idx) => {
    const newJoin = [...joinColumn];
    newJoin.splice(idx, 1);
    setJoinColumn(newJoin);
    e.preventDefault();
  };

  /**
   * 확인 버튼
   * @param {*} e
   */
  const onClickConfirm = (e) => {
    if (ArrayUtils.isEmpty(joinColumn)) {
      return Message.alert(
        "컬럼 관계가 이루어지지 않았습니다.",
        Enums.MessageType.WARN
      );
    }
    if (props.callback) {
      if (isIdentifying) {
        let sourcePkColumns = sourceColumnOption.filter((c) => c.pkYn === "Y");
        props.callback(relationType, joinColumn, {
          keyColumns: sourcePkColumns,
          targetTable,
        });
      } else {
        props.callback(relationType, joinColumn, {
          targetTable,
        });
      }
    }
  };

  const onChangeTable = (e, value) => {
    setTargetTable(value);
    if (relationType === "identifying" && !ObjectUtils.isEmpty(value)) {
      autoTableRelationSet(relationType, value);
    }
  };

  /**
   * 릴레이션 타입이 identifying 일때
   * 자동으로 JoinColumns 형성하는 로직
   * @param {*} relationType
   * @param {*} targetTable
   */
  const autoTableRelationSet = (relationType, targetTable) => {
    if (relationType === "identifying" && !ObjectUtils.isEmpty(targetTable)) {
      const identifyingJoinColumn = [];
      let sourcePkColumns = sourceColumnOption.filter((c) => c.pkYn === "Y");
      sourcePkColumns.map((pkc) => {
        identifyingJoinColumn.push({
          [pkc.physicalName]: pkc.physicalName,
        });
      });

      setJoinColumn(identifyingJoinColumn);
    }
  };

  return (
    <Modal>
      <Modal.Header title={"테이블 연결 설정"} />
      <Modal.Body>
        <UModalTemplate className={"erd-table-relation-wrapper"}>
          <Form.Group className="mt-3" as={Col} xs={12}>
            <Form.Label>연결 방식 선택</Form.Label>
            <Form.Select value={relationType} onChange={onChangeRelation}>
              <option value={"identifying"}>식별관계</option>
              <option value={"nonIdentifying"}>비식별관계</option>
            </Form.Select>
          </Form.Group>
          <Form.Group className="mt-3 mb-3" as={Col} xs={12}>
            <div>
              * 개체 A, B 사이의 관계에서 A 개체의 기본키가 B 개체의 &nbsp;
              {StringUtils.equalsIgnoreCase(relationType, "nonIdentifying") ? (
                <strong>
                  비기본키 영역에서{" "}
                  <span style={{ color: "tomato" }}>외래키</span>
                </strong>
              ) : (
                <strong>
                  외래키이면서 동시에{" "}
                  <span style={{ color: "tomato" }}>기본키</span>
                </strong>
              )}
              가 되는 관계
            </div>
          </Form.Group>
          {refChangeable && tableList && (
            <Form.Group>
              <Form.Label>참조 대상 설정</Form.Label>
              <Row>
                <Col xs={2} style={{ display: "flex", alignItems: "center" }}>
                  <Form.Label>참조 테이블</Form.Label>
                </Col>
                <Col>
                  <Autocomplete
                    options={tableList}
                    size="small"
                    onChange={onChangeTable}
                    isOptionEqualToValue={(option, value) =>
                      option.compId === value.compId
                    }
                    value={targetTable}
                    inputValue={
                      ObjectUtils.isEmpty(targetTable)
                        ? ""
                        : `${targetTable.propertyValue?.logicalTableNm} | ${targetTable.propertyValue?.physicalTableNm} `
                    }
                    getOptionLabel={(option) => {
                      return `${option.propertyValue?.logicalTableNm} | ${option.propertyValue?.physicalTableNm} `;
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="테이블명 입력" />
                    )}
                  />
                </Col>
              </Row>
            </Form.Group>
          )}

          <Form.Group className="mt-3 ㅡㅠ-3" as={Col} xs={12}>
            <Form.Label>Join Column</Form.Label>
            <div className="relation-table">
              <div className="relation-row relation-header">
                <div className="columnNm">
                  {sourceTable.propertyValue.physicalTableNm}.From
                </div>
                <div className="columnNm">
                  {targetTable.propertyValue?.physicalTableNm}.To
                </div>
                <div className="button" />
              </div>
              {joinColumn.map((_join, idx) => {
                const from = Object.keys(_join)[0];
                const to = _join[from];
                return (
                  <div className="relation-row" key={from}>
                    <div className="columnNm">
                      {sourceTable.propertyValue.physicalTableNm}.
                      <strong>{from}</strong>
                    </div>
                    <div className="columnNm">
                      {targetTable.propertyValue?.physicalTableNm}.
                      <strong>{to}</strong>
                    </div>
                    <div className="button">
                      {!isIdentifying && (
                        <Button
                          variant="outline-danger"
                          size="sm"
                          onClick={(e) => onRemoveJoin(e, idx)}
                        >
                          삭제
                        </Button>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </Form.Group>
          {!isIdentifying && !ObjectUtils.isEmpty(targetTable) && (
            <Col
              xs={12}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Tooltip
                title={
                  <>
                    Source필드와 Target 필드의 칼럼명이 동일하고
                    <br />
                    양쪽다 키값(PK) 인 경우 자동으로 연결합니다.
                  </>
                }
                placement="left"
              >
                <Button
                  onClick={onAutoJoin}
                  disabled={StringUtils.equalsIgnoreCase(
                    relationType,
                    "identifying"
                  )}
                >
                  필드 자동 조인
                </Button>
              </Tooltip>
            </Col>
          )}

          {!isIdentifying && (
            <Col xs={12}>
              <Form.Label>Join Column Choice </Form.Label>
              <Row className="gy-3 join-field-choice">
                <Col xs={3} className="join-entity-name">
                  <span>{sourceTable.propertyValue.physicalTableNm}</span>
                </Col>
                <Col xs={7}>
                  <FieldSearcher
                    onChange={setSourceColumn}
                    fieldList={sourceColumnOption}
                  />
                </Col>
                <Col xs={2} className="join-from">
                  에서
                </Col>
                {ObjectUtils.isEmpty(targetTable) ? (
                  <>
                    <Col xs={3} />
                    <Col xs={7}>
                      <span className="blink">참조 테이블을 선택 해주세요</span>
                    </Col>
                    <Col xs={3} />
                  </>
                ) : (
                  <>
                    <Col className="join-entity-name" xs={3}>
                      <span>{targetTable.propertyValue?.physicalTableNm}</span>
                    </Col>
                    <Col xs={7}>
                      <FieldSearcher
                        onChange={setTargetColumn}
                        fieldList={targetColumnOption}
                      />
                    </Col>
                    <Col xs={2} className="join-from">
                      <Button
                        variant="outline-primary"
                        onClick={onAddJoinColumn}
                      >
                        참조 추가
                      </Button>
                    </Col>
                  </>
                )}
              </Row>
            </Col>
          )}
        </UModalTemplate>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Button onClick={onClickConfirm}>확인</Modal.Footer.Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TableRelationPopup;

const FieldSearcher = ({ field, fieldList, onChange: _onChange, ...props }) => {
  const onChange = (e, value) => {
    if (_onChange) _onChange(value);
  };

  return (
    <Autocomplete
      disablePortal
      options={fieldList.map((field) => {
        let obj = { ...field };
        obj.label = `${field.logicalName} | ${field.physicalName} `;
        if (field.pkYn === "Y") {
          obj.label += `| 🔑 `;
        }
        if (field.pkYn === "N" && field.requiredYn === "Y") {
          obj.label += `| ⭐ `;
        }

        return obj;
      })}
      size="small"
      onChange={onChange}
      isOptionEqualToValue={(option, value) => option.compId === value.compId}
      renderInput={(params) => <TextField {...params} label="필드명 입력" />}
    />
  );
};
