import React, { useEffect } from "react";
import { useDrag } from "react-dnd";
import { RiDragDropFill } from "react-icons/ri";
import { getEmptyImage } from "react-dnd-html5-backend";
import * as icon from "components/common/icon/UIcons";

import * as Enums from "components/builder/BuilderEnum";
import StringUtils from "components/common/utils/StringUtils";

const RenderDraggableComponent = ({
  item,
  drag,
  isDragging,
  styles,
  ComIcon,
}) => {
  let DraggableIcon;
  if (!StringUtils.isEmpty(item.componentClass)) {
    DraggableIcon = icon[StringUtils.substringAfter(item.componentClass, "/")];
    if (!DraggableIcon) {
      DraggableIcon = RiDragDropFill;
    }
  } else {
    DraggableIcon = RiDragDropFill;
  }

  return (
    <div className="sidebar-item" ref={drag} style={styles}>
      <div className="fa-pr">
        {ComIcon ? (
          <ComIcon size={28} />
        ) : DraggableIcon === RiDragDropFill ? (
          <DraggableIcon size={28} />
        ) : (
          <DraggableIcon
            style={{ fontSize: 28 }}
            className={`u-icon-${StringUtils.substringAfter(
              item.componentClass,
              "/"
            )}`}
          />
        )}
      </div>
      {item.componentDtlNm}
    </div>
  );
};

/**
 * Drag가능한 Component
 * @param {*} item
 * @returns
 */
const DraggableComponent = ({ data, style }) => {
  var item = {
    component: data,
    type: Enums.ComponentType.SIDEBAR_ITEM,
  };
  const [{ isDragging }, drag, preview] = useDrag({
    item: item,
    type: item.type,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, []);

  return (
    <RenderDraggableComponent
      item={data}
      drag={drag}
      ComIcon={data.icon}
      styles={style}
    />
  );
};
export default DraggableComponent;

/**
 * Dragging 될때 화면에 그려지는 모양
 * @param {*} item
 * @returns
 */
export const DraggingComponent = ({ item }) => {
  /* useDragLayer 를 통해 Dragging 되는 item을 rendering할때 class는 적용되지 않기 때문에 css로 적용함 */
  const styles = {
    display: "flex",
    alignItems: "flex-start",
    alignContent: "flex-start",
    lineHeight: "1.5",
    backgroundColor: "#3c3c3c",
    color: "#fff",
    boxShadow:
      "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
    width: "180px",
    padding: "0.3rem",
  };
  return <RenderDraggableComponent item={item} styles={styles} />;
};
