import React, { Component } from "react";

import { connect } from "react-redux";
import BuilderHeader, {
  HeaderMapDispatchToProps,
  HeaderMapStateToProps,
} from "./BuilderHeader";
import LogoIcon from "images/icon/daaf_logo.png";
class BuilderHeaderComponent extends BuilderHeader {
  constructor(props) {
    super(props);
  }
}

const mapStateToProps = (state) => {
  return {
    workspace: state.workspace,
    searchConsole: state.searchConsole,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  HeaderMapStateToProps,
  HeaderMapDispatchToProps
)(BuilderHeaderComponent);
