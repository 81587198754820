import { Accordion, Button } from "react-bootstrap";
import React from "react";

import LayoutComponent from "components/builder/ui/uiComponents/layout/LayoutComponent";
import {
  InputGropuWithLabel,
  PropertyLable,
  PropertyValue,
  PropertyValueSet,
} from "components/builder/ui/uiComponents/UIComponentStyle";
import {
  StringUtils,
  ArrayUtils,
  JsonUtils,
} from "components/common/utils/CommonUtils";
import UIComponentSection from "components/builder/ui/editor/UIComponentSection";
import USelectbox from "components/common/element/USelectbox";
import { RiDeleteRow, RiInsertRowBottom } from "react-icons/ri";
import produce from "immer";
import { AppContext } from "components/common/AppContextProvider";
import UITemplateHelper from "components/builder/ui/editor/helper/UITemplateHelper";
import * as Enums from "components/builder/BuilderEnum";

class InputGroup extends LayoutComponent {
  constructor(props) {
    super(props);
    //불필요하면 constructor 지우세요.

    this.state = {
      ...this.state,
      childComp: [],
    };
  }
  static contextType = AppContext;

  //load후 실행
  componentDidMount() {
    if (this.props.event === "renderPropertiesPanel") {
      this.setState({ childComp: this.props.fn.getChild() });
    }
  }

  /**
   * element type 변경
   * @param {*} event
   * @param {*} element
   */
  onChangeComponentType = (event, element) => {
    this.props.fn.changeComponentType(event.target.value, element);
  };

  /**
   * element 추가
   * @param {*} element
   */
  onInsertCompClick = (element) => {
    this.props.fn.addComponent({}, "A", element);
  };

  /**
   * element 삭제
   * @param {*} element
   */
  onDeleteCompClick = (element) => {
    this.props.fn.deleteComponent(element);

    let currIndex = this.state.childComp.indexOf(element);
    const newChild = produce(this.state.childComp, (draft) => {
      draft.splice(currIndex, 1);
    });
    this.setState({ childComp: newChild });
  };

  /**
   * 신규 element
   */
  onNewCompClick = () => {
    const componentContext = this.context.component;
    /* template 용 표준 base components - e.g) page, form, row, col... */
    const templateComponents =
      UITemplateHelper.getTemplateComponents(componentContext);
    /* Template에 있는 Grid Components 불러오기 */
    const inputTemplate = UITemplateHelper.getInputTemplate(templateComponents);
    inputTemplate.propertyValue.layoutType = "N";
    this.props.fn.addComponent({}, "A", inputTemplate);
  };
  /**
   * Properties tab panel을 Redering
   * @returns
   */
  renderPropertiesPanel = () => {
    const childComp = this.props.fn.getChild();
    console.log(childComp.length);
    return (
      <React.Fragment>
        {/* Title */}
        {this.renderComponentTitle("Input Group")}
        <Accordion defaultActiveKey={[0, 1]} alwaysOpen>
          <Accordion.Item eventKey={0}>
            <Accordion.Header>기본정보</Accordion.Header>
            <Accordion.Body>
              <PropertyLable>ID</PropertyLable>
              <PropertyValue>
                <input
                  type="text"
                  id="id"
                  defaultValue={StringUtils.defaultString(
                    this.state.propertyValue.id
                  )}
                  className="form-control form-control-sm"
                  onBlur={this.onChange}
                />
              </PropertyValue>
              <PropertyLable className="w-full">Element List</PropertyLable>
              <PropertyValue className="w-full">
                {!ArrayUtils.isEmpty(this.state.childComp) ? (
                  this.state.childComp.map((element, index) => {
                    return (
                      <PropertyValueSet key={index}>
                        <USelectbox
                          id="object"
                          onChange={(e) =>
                            this.onChangeComponentType(e, element)
                          }
                          defaultValue={JsonUtils.defaultString(
                            element.editorAttr,
                            "componentClass"
                          )}
                          items={[
                            { id: "form/Input", text: "Input" },
                            { id: "form/Text", text: "Text" },
                            { id: "form/Button", text: "Button" },
                            { id: "form/Checkbox", text: "Checkbox" },
                            { id: "form/RadioButton", text: "Radio" },
                            {
                              id: "form/SingleDatePicker",
                              text: "SingleDatePicker",
                            },
                          ]}
                          options={{ matchCd: "id", matchNm: "text" }}
                        />
                        <Button
                          onClick={() => this.onInsertCompClick(element)}
                          size="sm"
                          variant={"outline-secondary"}
                          className="light-font-color"
                        >
                          <RiInsertRowBottom size="20" />
                        </Button>
                        <Button
                          onClick={() => this.onDeleteCompClick(element)}
                          size="sm"
                          variant={"outline-secondary"}
                          className="light-font-color"
                        >
                          <RiDeleteRow size="20" />
                        </Button>
                      </PropertyValueSet>
                    );
                  })
                ) : (
                  <Button
                    onClick={() => this.onNewCompClick()}
                    size="sm"
                    variant={"outline-secondary"}
                    className="light-font-color"
                  >
                    <RiDeleteRow size="20" /> 신규 추가
                  </Button>
                )}
              </PropertyValue>
            </Accordion.Body>
          </Accordion.Item>

          {/* Label property */}
          {this.renderLabelPanel("InputGroup", "InputGroup", 1, true)}

          {/* Style property */}
          {this.renderStylePanel("InputGroup", 2)}
        </Accordion>
      </React.Fragment>
    );
  };

  /**
   * Editor의 component를 Redering
   * << Layout editor props>>
   *   - compId - 현재 component의 고유 ID
   *   - componentInfo - drag & drop시 생성된 component object
   *   - style - dragging style이 포함된 style (사용자가 정의한 style은 각 component에서 적절히 적용해야함)
   *   - event="renderEditor" - 요청 구분
   * @returns
   */
  renderEditor = () => {
    /**
     * UIComponentSection props
     * -item
     * -style
     * -className
     */
    return (
      <UIComponentSection
        item={this.props.componentInfo}
        style={{
          ...this.props.style,
          ...this.getPropsStyle(this.props.componentInfo),
        }}
        className={`editor-base draggable editor-input-group ${this.getEditorClassName()}`}
        title="INPUT GROUP"
      >
        <InputGropuWithLabel
          propertyValue={this.props.componentInfo.propertyValue}
          defaultLabel="InputGroup"
        >
          {this.props.children}
        </InputGropuWithLabel>
      </UIComponentSection>
    );
  };
}
export default InputGroup;
