import ArrayUtils from "components/common/utils/ArrayUtils";
import StringUtils from "components/common/utils/StringUtils";
import User from "components/common/utils/UserUtils";
import moment from "moment/moment";
import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { BiArrowToLeft } from "react-icons/bi";
import { stopEvent } from "../ui/editor/handler/UIEditorEventHandler";
import { AiOutlineClose } from "react-icons/ai";
import { Enums, LocalStorageName } from "../BuilderEnum";
import { debounce } from "components/common/utils/InputUtils";
import Message from "components/common/Message";
import produce from "immer";
import { Scrollbar } from "smooth-scrollbar-react";
import { CircularProgress } from "@mui/material";
import LocalStorageService from "services/common/LocalService";

class BuilderSidebarContextMenu extends Component {
  constructor(props) {
    super(props);
    this.menu = props.menu;
    this.navigate = props.navigate;
    this.dispatch = props.dispatch;
    this.workspace = props.workspace;
    this.contextHandler = props.contextHandler;
    this.onSearchData = this.onSearchData.bind(this);
    this.onLoadData = this.onLoadData.bind(this);
    this.goToPage = this.goToPage.bind(this);
    this.getRecentMenuList = this.getRecentMenuList.bind(this);
    this.renderContextMenu = this.renderContextMenu.bind(this);
    this.renderTitle = this.renderTitle.bind(this);
    this.onLoadDataList = this.onLoadDataList.bind(this);
    this.getDataList = this.getDataList.bind(this);
    this.renderRecentMenuList = this.renderRecentMenuList.bind(this);
    this.renderMenuContents = this.renderMenuContents.bind(this);
    this._onLoadProgram = this._onLoadProgram.bind(this);
    this.state = {
      searchResultList: [],
      recentWorkList: [],
      dataList: [],
      dataLoading: false,
      searchTerm: "",
      isLoading: false,
    };
  }

  /**
   * 메뉴에서 타겟 선택시 실행되는 함수
   * 각 클래스에서 오버라이드
   */
  onLoadData() {}

  menuClose() {
    if (this.contextHandler) {
      this.contextHandler(false);
    }
  }

  /**
   * 검색 인풋에서 입력하는 경우
   * 메뉴 오픈 후 입력을 해야만 데이터를 로딩하게 되고, 입력이 없으면 DB 인아웃을 하지 않는다.
   * @param {*} event
   * @param {Array} searchKey
   */
  onSearchData(event, searchKey) {
    const value = event.target.value;
    this.setState(
      produce(this.state, (draft) => {
        draft.searchTerm = value;
      })
    );
    const debounceSearch = debounce((value) => {
      if (StringUtils.isEmpty(value)) {
        this.setState(
          produce(this.state, (draft) => {
            draft.searchResultList = [];
          })
        );
      } else {
        this.getDataList().then((list) => {
          const searchResultList = list
            .filter((program) =>
              searchKey.reduce(
                (ac, cu) =>
                  ac ||
                  String(program[cu])
                    .toLowerCase()
                    .includes(String(value).toLowerCase()),
                false
              )
            )
            .slice(0, 5);

          this.setState(
            produce(this.state, (draft) => {
              draft.searchResultList = searchResultList;
            })
          );
        });
      }
    }, 250);

    debounceSearch(value);
  }

  /**
   * 각 메뉴에서 필요한 검색 데이터 목록을 로드함
   * 검색어가 입력될때만 활성화
   * @returns {Promise}
   */
  getDataList() {
    return new Promise((resolve, reject) => {
      if (ArrayUtils.isEmpty(this.state.dataList)) {
        this.onLoadDataList()
          .then((dataList) => {
            this.setState({
              dataList: dataList,
              dataLoading: false,
            });
            resolve(dataList);
          })
          .catch((err) => {
            reject(err);
          });
      } else {
        resolve(this.state.dataList);
      }
    });
  }

  /**
   * 데이터 불러오는 함수
   * @returns {Promise}
   */
  onLoadDataList() {}

  /**
   * 날짜 역순으로 정렬
   * @param {Array} list
   * @returns
   */
  sortOrderByDate = (list) => {
    if (!ArrayUtils.isArray(list) || ArrayUtils.isEmpty(list)) {
      return [];
    }
    const newList = list.sort((a, b) => {
      if (moment(a.updtDt) > moment(b.updtDt)) {
        return -1;
      } else {
        return 1;
      }
    });
    return newList;
  };

  /**
   * 해당 페이지 이동
   * @param {String} url
   */
  goToPage(url) {
    this.navigate(url);
    this.menuClose();
  }

  /**
   * 메뉴 타이틀 생성
   * @param {*} Icon
   * @param {String} title
   * @returns
   */
  renderTitle(Icon, title) {
    return (
      <div className="title">
        <div>
          <Icon size={22} />
          <span>{title}</span>
        </div>
        <div onClick={(e) => this.contextHandler(false)}>
          <BiArrowToLeft size={20} />
        </div>
      </div>
    );
  }

  /**
   * 타이틀 아래에 있는 메뉴들 렌더링 하는 함수
   * @param {*} Icon
   * @param {*} title
   * @param {*} onClick
   * @returns
   */
  renderContextMenu(Icon, title, onClick) {
    const onClickMenu = (e) => {
      stopEvent(e);
      if (onClick) {
        onClick();
      }
    };
    return (
      <div className="context-menu" onClick={onClickMenu}>
        <Icon size={15} />
        <span>{title}</span>
      </div>
    );
  }

  /**
   * 로컬스토리지 항목을 조회하여 현재 workspace에 맞는 리스트만 반환한다.
   * @param {String} storageName
   * @returns {Array}
   */
  getRecentMenuList(storageName) {
    let workHistoryList = [];
    if (this.props.workspace) {
      const storageObject = LocalStorageService.get(storageName);
      if (storageObject) {
        if (storageObject.list && ArrayUtils.isArray(storageObject.list)) {
          const thisWorkspaceProgram = storageObject.list.filter((o) =>
            StringUtils.equalsIgnoreType(
              o.appReleaseId,
              this.props.workspace.appReleaseId
            )
          );
          workHistoryList = thisWorkspaceProgram;
        }
      }
    }
    this.setState({
      recentWorkList: workHistoryList,
    });
  }

  /**
   * 최근 작업 목록 삭제 함수
   * @param {*} e
   * @param {*} _storageName
   * @param {*} index
   */
  onDeleteWorkHistory(e, _storageName, index) {
    stopEvent(e);
    const info = LocalStorageService.get(_storageName);
    info.list.splice(index, 1);
    LocalStorageService.set(_storageName, JSON.stringify(info));
    this.getRecentMenuList(_storageName);
  }

  /**
   * 메뉴에서 선택 후 콜백까지
   * @param {*} e
   * @param {*} work
   * @param {*} cb
   */
  loadProgram(e, work, cb) {}

  _onLoadProgram(e, work) {
    this.setState(
      produce(this.state, (draft) => {
        draft.isLoading = true;
      })
    );
    this.onLoadProgram(e, work, () => {
      this.setState(
        produce(this.state, (draft) => {
          draft.isLoading = false;
        })
      );
    });
  }

  /**
   * 최근 작업 목록 렌더 함수
   * @param {*} _storageName
   * @returns
   */
  renderRecentMenuList(_storageName) {
    let uid = undefined;
    let name = undefined;
    switch (_storageName) {
      case Enums.LocalStorageName.PROGRAM_HISTORY:
        uid = "programUid";
        name = "programNm";
        break;
      case Enums.LocalStorageName.WORKFLOW_HISTORY:
        uid = "serviceUid";
        name = "serviceName";
        break;
      case Enums.LocalStorageName.DATAMODEL_HISTORY:
        uid = "dataModelId";
        name = "dataModelNm";
        break;
      case Enums.LocalStorageName.EVENT_HISTORY:
        uid = "eventUid";
        name = "eventName";
        break;
      case Enums.LocalStorageName.ERD_HISTORY:
        uid = "erdUid";
        name = "erdName";
        break;
      default:
        break;
    }
    if (!(uid && name)) {
      Message.alert(
        "최근 작업 목록에 오류가 있습니다.",
        Enums.MessageType.WARN
      );
      return <></>;
    }
    return this.state.recentWorkList.map((work, idx) => {
      return (
        <div
          className="work"
          key={work[uid]}
          onClick={(e) => {
            this._onLoadProgram(e, work);
          }}
        >
          <div>{work[name]}</div>
          <button
            onClick={(e) => this.onDeleteWorkHistory(e, _storageName, idx)}
          >
            <AiOutlineClose />
          </button>
        </div>
      );
    });
  }

  renderMenuContents() {
    return <></>;
  }

  render() {
    return (
      <Scrollbar
        className="custom-class"
        style={{ padding: "10px" }}
        plugins={{
          overscroll: {
            effect: "glow",
          },
        }}
      >
        {this.state.isLoading && (
          <div className="loading-wrapper">
            <CircularProgress color="inherit" size={25} />
          </div>
        )}

        {this.renderMenuContents()}
      </Scrollbar>
    );
  }
}

export default BuilderSidebarContextMenu;
