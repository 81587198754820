/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { BsPencilSquare } from "react-icons/bs";
import Popup from "components/common/Popup";
import Modal from "components/common/modal/UModal";
import styled from "styled-components";
import "bootstrap/dist/css/bootstrap.min.css";
import CodeMirror from "@uiw/react-codemirror";
import { javascript } from "@codemirror/lang-javascript";
import produce from "immer";
import { Button as BButton, Button } from "react-bootstrap";

import {
  PropertyLable,
  PropertyValue,
} from "components/builder/ui/uiComponents/UIComponentStyle";
import StringUtils from "components/common/utils/StringUtils";
import USelectbox from "components/common/element/USelectbox";
import UInputPopup from "components/common/element/UInputPopup";
import IconPopup from "./IconPopup";
import UElementList from "components/common/element/UElementList";
import ObjectUtils from "components/common/utils/ObjectUtils";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import ArrayUtils from "components/common/utils/ArrayUtils";
import DataModelService from "services/datamodel/DataModelService";
import ComponentService from "services/common/ComponentService";
import WorkflowListPopup from "page/popup/workflow/WorkflowListPopup";
import { Fragment } from "react";
import MessageListPopup from "./MessageListPopup";
import WorkflowDetailPopup from "./workflow/WorkflowDetailPopup";
import { Checkbox, FormControlLabel } from "@mui/material";
import Message from "components/common/Message";
import UModalJavascriptEditor from "components/common/code/UModalJavascriptEditor";
import { useRef } from "react";
import { Enums } from "components/builder/BuilderEnum";
import PopupHandleComponent from "./PopupHandleComponent";

const GridToolbarBtnPopupStyle = styled.div`
  .edit-source {
    border: 1px solid #ddd;
  }
  .w-55p {
    width: 55% !important;
  }
  .btn-group .btn-add,
  .btn-group .btn-remove {
    color: #333;
  }
  .table-bottom {
    border-bottom: 1px solid #c9d3dd;
  }
  .table-header {
    border-bottom: 2px solid #c9d3dd;
    border-top: 1px solid #dadada;
    height: 35px;
    align-items: center;
    background: #f5f5f5;
    margin-left: 0;
    margin-right: 0;
  }
`;

/**
 * GridToolbarBtnPopup : Message와 Function 입력 Popup
 *
 * 2022.05.09 : init: songe.park
 * 2024.01.02 : Code Mirror 기능 사용 유지 위한 state -> Ref로 관리 하도록 수정
 * @param {*} props
 * @returns value
 */
const GridToolbarBtnPopup = (props) => {
  const {
    title,
    callbackFnc,
    data,
    entityId,
    dataModelId,
    type,
    componentInfo,
    codeList,
    workspace,
    navigate,
    onClickEventBuilder,
  } = props;

  //codemirror
  const javascriptExt = [javascript(true)];
  const [extensions, setExtensions] = useState(javascriptExt);

  const [item, setItem] = useState(data);

  // const [eventFnc, setEventFnc] = useState(data["eventFnc"]);
  // const [beforeSubmit, setBefore] = useState(data["beforeSubmit"]);
  // const [afterSubmit, setAfter] = useState(data["afterSubmit"]);

  //관리방식 state -> Ref
  const eventFncRef = useRef(data["eventFnc"]);
  const beforeSubmitRef = useRef(data["beforeSubmit"]);
  const afterSubmitRef = useRef(data["afterSubmit"]);

  const [tnxHandleOption, setTnxHandleOption] = useState({});
  const [usrTnxHandleOption, setUsrTnxHandleOption] = useState({});
  const [dataBindingList, setDataBindingList] = useState([]);
  const [eventTypeItem, setEventTypeItem] = useState("");

  useEffect(() => {
    initPopup();
  }, []);

  const getEventInfo = (eventType) => {
    let eventWorkspace = {};
    if (item.eventWorkspace) {
      eventWorkspace = item.eventWorkspace || {};
    }
    return {
      eventWorkspace: eventWorkspace[eventType],
      compId: props.componentInfo.compId,
      eventType:
        Enums.EventHandlerEventType[eventType] ||
        Enums.EventHandlerEventType.USR_EVENT_FUNCTION,
      builderEventType: eventType,
      targetType: "grid",
      eventCategory: type,
    };
  };
  let items = {};
  const setRetItems = (id, value) => {
    console.log(id + " : " + value);
    items = { ...item, [id]: value };
    setItem(items);
  };

  const initPopup = () => {
    //기 설정 내용이 있을 경우
    if (!StringUtils.isEmpty(item.eventType)) {
      //Grid 표준 Toolbar Button 또는 Custom Button에 대한 설정
      if (type !== "event") {
        if (ArrayUtils.isEmpty(eventTypeItem)) {
          let param = {
            eventHandleCd: data.eventType,
          };
          ComponentService.selectGridButtonEvent(param, (res) => {
            setEventTypeItem(res.data);
          });
        }

        //Event 상세 설정
      } else {
        const eventItems = componentInfo.componentEvent;
        setEventTypeItem(
          eventItems.filter(
            (d) =>
              d["eventHandleCd"] === item.eventType && d.applyGridYn === "Y"
          )[0]
        );
      }

      //user Transaction && Procedure 호출일 경우
      if (item.eventType === "BTN_USR_TNX" && item.tnxHandleType === "P") {
        //user Transaction에 등록된 Option정보를 읽어온다.
        if (ObjectUtils.isEmpty(tnxHandleOption) && item.transaction) {
          let param = {
            dataModelUsrTnxId: item.transaction,
          };
          ComponentService.selectDataModelUsrTnx(param, (res) => {
            assignTnxHandleOption(res.data);
          });
        }
      }

      if (
        StringUtils.includes(data.eventType, [
          "GRID_LOAD",
          "GRID_ROW_DBCLICK_P",
        ])
      ) {
        selectDataBindingList(entityId);
      }
    }

    setElementReadonly("sizeCustom", data.size !== "custom");
  };

  const setElementReadonly = (id, isReadonly) => {
    //reset element value
    // let el = document.getElementById(id);
    // if (isReadonly) {
    //   el.setAttribute("readOnly", true);
    // } else {
    //   el.removeAttribute("readOnly");
    // }
  };

  const onChangeTransaction = (e) => {
    assignTnxHandleOption(e.target._data);

    let tnx = {
      tnxHandleType: e.target._data.tnxHandleType,
      [e.target.id]: e.target.value,
    };

    setItem({ ...item, ...tnx });
  };

  const assignTnxHandleOption = (data) => {
    //Stored procedure 호출
    if (data && data.tnxHandleType === "P") {
      // Argument에 Binding할 Field를 조회 한다.
      if (ArrayUtils.isEmpty(dataBindingList)) selectDataBindingList(entityId);

      try {
        let parseTnxHandleOption = JSON.parse(data.tnxHandleOption) || {};
        setTnxHandleOption(parseTnxHandleOption);
        convertUsrTnxHandleOption(parseTnxHandleOption);
      } catch (e) {
        setTnxHandleOption({});
        setUsrTnxHandleOption({});
        console.log("an occured Error during parsing Option JSON -" + e);
      }
    } else {
      setTnxHandleOption({});
      setUsrTnxHandleOption({});
    }
  };

  /**
   * User Transaction Option 정보를 기반으로 Builder에서 사용할수 있는 형태로 변환
   * @param {*} tnxHandleOption
   */
  const convertUsrTnxHandleOption = (tnxHandleOption) => {
    let newUsrTnxHandleOption = {};
    newUsrTnxHandleOption.procedureName = tnxHandleOption.procedureName;
    //db 기준으로
    let args = [];
    tnxHandleOption.arguments.map((arg, index) => {
      let newArgs = { ...arg };
      //사용자 설정 정보
      if (
        !ObjectUtils.isEmpty(item.tnxHandleOption) &&
        !ArrayUtils.isEmpty(item.tnxHandleOption.arguments) &&
        !ObjectUtils.isEmpty(item.tnxHandleOption.arguments[index])
      ) {
        newArgs.dataBinding = StringUtils.defaultString(
          item.tnxHandleOption.arguments[index].dataBinding
        );
        newArgs.defaultValue = StringUtils.defaultString(
          item.tnxHandleOption.arguments[index].defaultValue
        );
      } else {
        newArgs.dataBinding = "";
        newArgs.defaultValue = "";
      }
      args.push(newArgs);
    });
    newUsrTnxHandleOption.arguments = args;
    setUsrTnxHandleOption(newUsrTnxHandleOption);
  };

  const onChangeProcDataBinding = (e, arg, index) => {
    let newArg = { ...arg };
    //id를 setting해준다.
    newArg.dataBinding = StringUtils.substringAfter(e.target.value, ".");
    changeProcArgs(e, newArg, index);
  };

  const onChangeProcArgs = (e, arg, index) => {
    let newArg = { ...arg, [e.target.id]: e.target.value };
    changeProcArgs(e, newArg, index);
  };

  const changeProcArgs = (e, arg, index) => {
    const newArguments = produce(usrTnxHandleOption.arguments, (draft) => {
      draft[index] = { ...arg };
    });
    setUsrTnxHandleOption({
      ...usrTnxHandleOption,
      ...{ arguments: newArguments },
    });
  };

  const onBlur = (e) => {
    onBlurValue(e.target.id, e.target.value);
  };

  const onBlurValue = (pId, pValue) => {
    const newItems = produce(item, (draft) => {
      draft[pId] = pValue;
    });
    console.log(newItems);
    setItem(newItems);
  };

  /**
   * Icon Popup Open
   * @param {*} e
   */
  const openPopupIcon = (e) => {
    const popOptions = {
      effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
      style: {
        content: {
          height: "700px",
        },
      },
    };

    Popup.open(
      <IconPopup
        title="Icon 조회"
        defaultValue={item.icon}
        callbackFnc={(icon) => {
          // setIcon(icon);
          let el = document.getElementById("icon");
          el.value = icon;
          doInputChangeEvent(el, "input");
          onBlurValue("icon", icon);
        }}
      />,
      popOptions
    );
  };

  /**
   * Message List Popup
   * @param {*} e
   */
  const openMessagePopup = (pTarget, e) => {
    const popOptions = {
      effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
      style: {
        content: {
          height: "700px",
        },
      },
    };

    Popup.open(
      <MessageListPopup
        workspace={props.workspace}
        callbackFnc={(result) => {
          let el = document.getElementById(pTarget);
          el.value = result.msgCd;
          doInputChangeEvent(el, "input");
          onBlurValue(pTarget, result.msgCd);
        }}
      />,
      popOptions
    );
  };

  const doInputChangeEvent = (obj, event) => {
    let eventObj = new Event(event, { target: obj, bubbles: true });
    return obj ? obj.dispatchEvent(eventObj) : false;
  };

  /**
   * closePopup: value값을 전달하고 popup 닫기
   * @param {*} e
   */
  const closePopup = (e) => {
    if (callbackFnc) {
      let newItem = { ...item };
      //user Transaction && Procedure 호출일 경우
      if (item.eventType === "BTN_USR_TNX" && item.tnxHandleType === "P") {
        newItem.tnxHandleOption = usrTnxHandleOption;
      } else {
        if (item.eventType !== "BTN_USR_TNX") {
          newItem.transaction = null; //caller에서 지움
        }
        newItem.tnxHandleOption = null; //caller에서 지움

        if (item.eventType !== "BTN_WORKFLOW") {
          newItem.serviceName = null; //caller에서 지움
          newItem.serviceUid = null; //caller에서 지움
          newItem.submitDataType = null; //caller에서 지움
        }
      }
      callbackFnc.call(this, {
        ...newItem,
        eventFnc: eventFncRef.current,
        beforeSubmit: beforeSubmitRef.current,
        afterSubmit: afterSubmitRef.current,
      });
      Popup.close();
    } else {
      callbackFnc.call(this, {
        ...item,
        eventFnc: eventFncRef.current,
        beforeSubmit: beforeSubmitRef.current,
        afterSubmit: afterSubmitRef.current,
      });
      Popup.close();
    }
  };

  /**
   * Data Model Entity Field List 조회(Data Binidng)
   * @param {*} entityId
   */
  const selectDataBindingList = (entityId) => {
    if (!StringUtils.isEmpty(entityId)) {
      DataModelService.getDataBindingList({ entityId: entityId }, (res) => {
        setDataBindingList(
          res.data ? res.data : [{ id: "none", text: "[none] None Data" }]
        );
      });
    }
  };

  const onWorkflowSettingClick = (e) => {
    if (e) {
      e.preventDefault();
    }

    const callBack = (data) => {
      setItem({ ...item, ...data });
      Popup.close();
    };

    const remove = () => {
      Popup.close();
      setItem(
        produce(item, (draft) => {
          delete draft.serviceId;
          delete draft.serviceName;
          delete draft.serviceUid;
        })
      );
    };

    Popup.open(
      <WorkflowListPopup
        workspace={workspace.Info}
        callbackFnc={callBack}
        remove={item.serviceId && remove}
      />,
      {
        style: {
          content: {
            width: "55%",
          },
        },
      }
    );
  };

  /**
   * workflow 상세
   * @param {Event} e
   */
  const onWorkflowDetailClick = (e) => {
    if (e) {
      e.preventDefault();
    }

    const callBack = (data) => {
      // this.onChangePropertyValues(data);
      Popup.close();
    };

    Popup.open(
      <WorkflowDetailPopup
        serviceUid={item.serviceUid}
        callbackFnc={callBack}
        edit={props.edit}
        navigate={navigate}
      />,
      {
        style: {
          content: {
            width: "500px",
          },
        },
      }
    );
  };

  const onChangeSubmitDataType = (e) => {
    setItem({ ...item, ...{ submitDataType: e.target.value } });
  };

  const renderSubmitDataForm = (item, defaultValue) => {
    return (
      <div className="w-full">
        <PropertyLable className="float-left">전송 Data</PropertyLable>
        <PropertyValue className="float-left">
          <div className="mb-3">
            <Form.Check
              inline
              id="submitDataTypeChanged"
              label="변경된 Grid Data"
              name="submitDataType"
              value="changed"
              type="radio"
              checked={
                StringUtils.defaultString(item.submitDataType, defaultValue) ===
                "changed"
              }
              onChange={onChangeSubmitDataType}
            />
            <Form.Check
              inline
              id="submitDataTypeChecked"
              label="선택된 Grid Data"
              name="submitDataType"
              type="radio"
              value="checked"
              checked={
                StringUtils.defaultString(item.submitDataType, defaultValue) ===
                "checked"
              }
              onChange={onChangeSubmitDataType}
            />
          </div>
        </PropertyValue>
      </div>
    );
  };

  return (
    <GridToolbarBtnPopupStyle>
      <Modal>
        <Modal.Header title={title} />
        <Modal.Body className="popup-panel">
          {type !== "event" ? (
            <div className="w-full">
              <PropertyLable className="float-left" requried="true">
                ID
              </PropertyLable>
              <PropertyValue className="float-left">
                <input
                  type="text"
                  id="id"
                  defaultValue={StringUtils.defaultString(item.id)}
                  className="form-control form-control-sm"
                  onBlur={onBlur}
                  readOnly={type === "buttons"}
                />
              </PropertyValue>
              <PropertyLable className="float-left" requried="true">
                Label
              </PropertyLable>
              <PropertyValue className="float-left">
                <input
                  type="text"
                  id="name"
                  defaultValue={StringUtils.defaultString(item.name)}
                  className="form-control form-control-sm"
                  onBlur={onBlur}
                  readOnly={type === "buttons"}
                />
              </PropertyValue>
              <PropertyLable className="float-left" requried="true">
                Icon
              </PropertyLable>
              <PropertyValue className="float-left">
                <UInputPopup
                  id="icon"
                  defaultValue={StringUtils.defaultString(item.icon, "")}
                  onBlur={onBlur}
                  onClick={openPopupIcon}
                  readonlyTp={type === "buttons" ? "all" : ""}
                />
              </PropertyValue>
              <PropertyLable className="float-left">Event 유형</PropertyLable>
              <PropertyValue className="float-left">
                <USelectbox
                  type="search"
                  id="eventType"
                  defaultValue={StringUtils.defaultString(item.eventType)}
                  url="/common/eventCombo"
                  params={{
                    componentDtlId: "73",
                    applyGridYn: "Y",
                  }}
                  options={{
                    matchId: "id",
                    matchNm: "text",
                  }}
                  onChange={(e, item, event) => {
                    setEventTypeItem(item);
                    onBlur(e);
                  }}
                />
              </PropertyValue>
            </div>
          ) : data.eventType === "GRID_LOAD" ? (
            <div className="w-full">
              <UElementList
                label="Parameter 정의"
                isDisplay={true}
                bodyStyle={{ height: "120px" }}
                id="parameter"
                data={item.parameter}
                onBlur={onBlurValue}
                cols={[
                  {
                    label: "Key",
                    type: "select",
                    id: "key",
                    className: "w-40p",
                    settings: {
                      type: "search", //data Binding
                      items: dataBindingList,
                      options: {
                        matchId: "id",
                        matchNm: "text",
                      },
                      extIds: ["none"],
                    },
                  },
                  {
                    label: "Value",
                    type: "select",
                    id: "value",
                    className: "w-55p",
                    settings: {
                      type: "static",
                      items: codeList.getCodeList("Z0020"),
                    },
                  },
                ]}
              />
            </div>
          ) : data.eventType === "GRID_ROW_DBCLICK_P" ? (
            <div className="w-full">
              <PropertyLable className="float-left w-full">
                팝업 URL
              </PropertyLable>
              {/* <PropertyValue className="float-left w-30p">
                <USelectbox
                  type="common"
                  id="searchType"
                  defaultValue={StringUtils.defaultString(data.searchType)}
                  onChange={onBlur}
                  items={codeList.getCodeList("Z0027")}
                />
              </PropertyValue> */}
              <PropertyValue className="float-left w-full">
                <input
                  type="text"
                  id="programId"
                  defaultValue={StringUtils.defaultString(data.programId)}
                  className="form-control form-control-sm"
                  onBlur={onBlur}
                  onChange={(event) => {
                    event.target.value = event.target.value.toUpperCase();
                  }}
                />
              </PropertyValue>
              <PropertyLable className="float-left w-full">
                팝업 Title
              </PropertyLable>
              <PropertyValue className="float-left w-full">
                <input
                  type="text"
                  id="title"
                  defaultValue={StringUtils.defaultString(data.title)}
                  className="form-control form-control-sm"
                  onBlur={onBlur}
                />
              </PropertyValue>
              <PropertyLable className="float-left w-full">
                팝업창 크기
              </PropertyLable>
              <PropertyValue className="float-left w-30p">
                <USelectbox
                  type="common"
                  id="size"
                  defaultValue={StringUtils.defaultString(data.size)}
                  onChange={onBlur}
                  items={codeList.getCodeList("Z0021")}
                />
              </PropertyValue>
              <PropertyValue className="float-left w-70p">
                <input
                  type="text"
                  id="sizeCustom"
                  defaultValue={StringUtils.defaultString(data.sizeCustom)}
                  className="form-control form-control-sm"
                  onBlur={onBlur}
                  // {...(!StringUtils.equalsIgnoreCase(data.size, "custom")
                  //   ? { readOnly: true }
                  //   : {})}
                />
              </PropertyValue>

              <UElementList
                label="Return 값 매핑"
                isDisplay={true}
                bodyStyle={{ height: "120px" }}
                id="returnMapping"
                data={item.returnMapping}
                onBlur={onBlurValue}
                cols={[
                  {
                    label: "컬럼",
                    type: "select",
                    id: "column",
                    className: "w-40p",
                    settings: {
                      type: "search", //data Binding
                      items: dataBindingList,
                      options: {
                        matchId: "id",
                        matchNm: "text",
                      },
                    },
                  },
                  {
                    label: "Return 값",
                    type: "input",
                    id: "returnParam",
                    className: "w-55p",
                  },
                ]}
              />
            </div>
          ) : (
            ""
          )}

          {!ObjectUtils.isEmpty(eventTypeItem) &&
          eventTypeItem.eventHandleFncYn === "Y" ? (
            <div className="w-full">
              <PropertyLable className="w-full float-left">
                사용자 정의 Function
              </PropertyLable>
              <PropertyValue className="w-full float-left">
                <UModalJavascriptEditor
                  height="420px"
                  onClickEventBuilder={(e) =>
                    onClickEventBuilder("eventFnc", eventTypeItem)
                  }
                  eventInfo={props.getEventInfo("eventFnc", eventTypeItem)}
                  defaultValue={StringUtils.defaultString(eventFncRef.current)}
                  codeRef={eventFncRef}
                  onChange={(value, viewUpdate) => {
                    // setEventFnc(value);
                    eventFncRef.current = value;
                  }}
                ></UModalJavascriptEditor>
              </PropertyValue>
            </div>
          ) : (
            ""
          )}

          {item.eventType === "BTN_USR_TNX" ? (
            <React.Fragment>
              <div className="w-full">
                <PropertyLable requried="true" className="float-left">
                  Transaction
                </PropertyLable>
                <PropertyValue className="float-left">
                  <USelectbox
                    type="search"
                    id="transaction"
                    defaultValue={StringUtils.defaultString(item.transaction)}
                    onChange={onChangeTransaction}
                    url="/datamodel/getDataModelUsrTnxList"
                    params={{
                      dataModelId: dataModelId,
                    }}
                    options={{ matchId: "id", matchNm: "text" }}
                  />
                </PropertyValue>
              </div>
              {!ObjectUtils.isEmpty(usrTnxHandleOption) ? (
                <React.Fragment>
                  <div className="w-full">
                    <PropertyLable requried="true" className="float-left">
                      Stored Procedure명
                    </PropertyLable>
                    <PropertyValue className="float-left">
                      <input
                        type="text"
                        id="procedureName"
                        readOnly={true}
                        defaultValue={StringUtils.defaultString(
                          usrTnxHandleOption.procedureName
                        )}
                        className="form-control form-control-sm"
                      />
                    </PropertyValue>
                  </div>
                  {renderSubmitDataForm(item, "checked")}
                  <div className="w-full">
                    <PropertyValue className="float-left w-full">
                      <Form.Group className="mb-3 table-bottom">
                        <Col>
                          <Row className="mb-2 table-header">
                            <Col>Argument</Col>
                            <Col>Type</Col>
                            <Col>Mode</Col>
                            <Col>Data Binding</Col>
                            <Col>고정 값</Col>
                          </Row>
                          {!ArrayUtils.isEmpty(usrTnxHandleOption.arguments)
                            ? usrTnxHandleOption.arguments.map(
                                (argument, index) => {
                                  return (
                                    <Row
                                      key={index}
                                      className="pb-2"
                                      style={{ alignItems: "center" }}
                                    >
                                      <Col>
                                        <input
                                          type="text"
                                          id="name"
                                          className="form-control form-select-sm"
                                          defaultValue={argument.name}
                                          readOnly={true}
                                        />
                                      </Col>
                                      <Col>
                                        <input
                                          type="text"
                                          id="type"
                                          className="form-control form-select-sm"
                                          defaultValue={argument.type}
                                          readOnly={true}
                                        />
                                      </Col>
                                      <Col>
                                        <input
                                          type="text"
                                          id="mode"
                                          className="form-control form-select-sm"
                                          defaultValue={argument.mode}
                                          readOnly={true}
                                        />
                                      </Col>
                                      <Col>
                                        {argument.name !== "tenantId" &&
                                        argument.name !== "coCd" ? (
                                          <Form.Select
                                            size="sm"
                                            id="dataBinding"
                                            onChange={(e) =>
                                              onChangeProcDataBinding(
                                                e,
                                                argument,
                                                index
                                              )
                                            }
                                            value={StringUtils.defaultString(
                                              argument.dataBinding
                                            )}
                                          >
                                            <option value={""}>선택</option>
                                            {!ArrayUtils.isEmpty(
                                              dataBindingList
                                            )
                                              ? dataBindingList.map(
                                                  (option, optionIdx) => (
                                                    <option
                                                      key={optionIdx}
                                                      value={option.id}
                                                    >
                                                      {option.text}
                                                    </option>
                                                  )
                                                )
                                              : ""}
                                          </Form.Select>
                                        ) : (
                                          "N/A"
                                        )}
                                      </Col>
                                      <Col>
                                        {argument.name !== "tenantId" &&
                                        argument.name !== "coCd" &&
                                        argument.mode !== "OUT" ? (
                                          <input
                                            type="text"
                                            id="defaultValue"
                                            className="form-control form-select-sm"
                                            defaultValue={StringUtils.defaultString(
                                              argument.defaultValue
                                            )}
                                            onChange={(e) =>
                                              onChangeProcArgs(
                                                e,
                                                argument,
                                                index
                                              )
                                            }
                                          />
                                        ) : (
                                          ""
                                        )}
                                      </Col>
                                    </Row>
                                  );
                                }
                              )
                            : ""}
                        </Col>
                      </Form.Group>
                    </PropertyValue>
                  </div>
                </React.Fragment>
              ) : (
                ""
              )}
            </React.Fragment>
          ) : (
            ""
          )}
          {item.eventType === "BTN_WORKFLOW" ? (
            <Fragment>
              <div className="w-full">
                <PropertyLable requried="true" className="float-left">
                  Workflow
                </PropertyLable>
                <PropertyValue className="float-left">
                  <InputGroup>
                    <input
                      type="text"
                      id="serviceName"
                      value={StringUtils.defaultString(item.serviceName)}
                      className="form-control form-control-sm"
                      onChange={() => {}}
                      readOnly
                      aria-readonly
                      placeholder="Workflow 명"
                    />
                    <BButton
                      variant="outline-secondary"
                      size="sm"
                      onClick={onWorkflowSettingClick}
                    >
                      선택
                    </BButton>
                    {item?.serviceUid && (
                      <BButton
                        variant="outline-secondary"
                        size="sm"
                        onClick={onWorkflowDetailClick}
                      >
                        상세
                      </BButton>
                    )}
                  </InputGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="isSmartWFPostHandle"
                        checked={StringUtils.defaultString(
                          item.isSmartWFPostHandle,
                          false
                        )}
                        onChange={(e) =>
                          setItem({
                            ...item,
                            ...{ isSmartWFPostHandle: e.target.checked },
                          })
                        }
                        size="small"
                      />
                    }
                    label={
                      <span style={{ fontSize: "11px", fontWeight: "100" }}>
                        반환 Data를 Target에 자동 매핑
                      </span>
                    }
                  />
                </PropertyValue>
              </div>
              {renderSubmitDataForm(item, "changed")}
            </Fragment>
          ) : (
            ""
          )}
          {!ObjectUtils.isEmpty(eventTypeItem) &&
          eventTypeItem.preHandleMsgYn === "Y" ? (
            <div className="w-full">
              <PropertyLable className="float-left">
                전 처리 확인 메시지
              </PropertyLable>
              <PropertyValue className="float-left">
                <UInputPopup
                  id="beforeMsgCd"
                  defaultValue={StringUtils.defaultString(item.beforeMsgCd, "")}
                  onBlur={onBlur}
                  onClick={(e) => openMessagePopup("beforeMsgCd", e)}
                />
              </PropertyValue>
            </div>
          ) : (
            ""
          )}

          {item.eventType === "BTN_OPEN_POPUP" ? (
            <div>
              <PopupHandleComponent
                callbackFnc={(data) => {}}
                item={item}
                entityId={entityId}
                data={{
                  size: codeList.getCodeList("Z0021"),
                  position: codeList.getCodeList("Z0013"),
                }}
                setRetItems={setRetItems}
                workspace={props.workspace}
                onClickEventBuilder=""
                getEventInfo={getEventInfo}
              />
            </div>
          ) : (
            ""
          )}

          {!ObjectUtils.isEmpty(eventTypeItem) &&
          eventTypeItem.preHandleFncYn === "Y" ? (
            <div className="w-full">
              <PropertyLable className="float-left w-full">
                전 처리 사용자 Function
              </PropertyLable>
              <PropertyValue className="float-left w-full">
                <UModalJavascriptEditor
                  height={
                    eventTypeItem.postHandleFncYn === "Y" ? "260px" : "420px"
                  }
                  onClickEventBuilder={(e) =>
                    onClickEventBuilder("beforeSubmit", eventTypeItem)
                  }
                  eventInfo={props.getEventInfo("beforeSubmit", eventTypeItem)}
                  // defaultValue={StringUtils.defaultString(beforeSubmit)}
                  // onChange={(value, viewUpdate) => {
                  //   setBefore(value);
                  // }}
                  defaultValue={StringUtils.defaultString(
                    beforeSubmitRef.current
                  )}
                  onChange={(value, viewUpdate) => {
                    beforeSubmitRef.current = value;
                  }}
                ></UModalJavascriptEditor>
              </PropertyValue>
            </div>
          ) : (
            ""
          )}

          {!ObjectUtils.isEmpty(eventTypeItem) &&
          eventTypeItem.postHandleMsgYn === "Y" ? (
            <div className="w-full">
              <PropertyLable className="float-left">
                후 처리 확인 메시지
              </PropertyLable>
              <PropertyValue className="float-left">
                <UInputPopup
                  id="afterMsgCd"
                  defaultValue={StringUtils.defaultString(item.afterMsgCd, "")}
                  onBlur={onBlur}
                  onClick={(e) => openMessagePopup("afterMsgCd", e)}
                />
              </PropertyValue>
            </div>
          ) : (
            ""
          )}

          {!ObjectUtils.isEmpty(eventTypeItem) &&
          eventTypeItem.postHandleFncYn === "Y" ? (
            <div className="w-full">
              <PropertyLable className="float-left w-full">
                후 처리 사용자 Function
              </PropertyLable>
              <PropertyValue className="float-left w-full">
                <UModalJavascriptEditor
                  height={
                    eventTypeItem.preHandleFncYn === "Y" ? "260px" : "420px"
                  }
                  onClickEventBuilder={(e) =>
                    onClickEventBuilder("afterSubmit", eventTypeItem)
                  }
                  eventInfo={props.getEventInfo("afterSubmit", eventTypeItem)}
                  // defaultValue={StringUtils.defaultString(afterSubmit)}
                  // onChange={(value, viewUpdate) => {
                  //   setAfter(value);
                  // }}
                  defaultValue={StringUtils.defaultString(
                    afterSubmitRef.current
                  )}
                  onChange={(value, viewUpdate) => {
                    afterSubmitRef.current = value;
                  }}
                ></UModalJavascriptEditor>
              </PropertyValue>
            </div>
          ) : (
            ""
          )}
        </Modal.Body>
        <Modal.Footer>
          <Modal.Footer.Button onClick={closePopup}>
            <BsPencilSquare />
            &nbsp;확인
          </Modal.Footer.Button>
        </Modal.Footer>
      </Modal>
    </GridToolbarBtnPopupStyle>
  );
};

export default GridToolbarBtnPopup;
