import React from "react";
import { Accordion, ButtonGroup, InputGroup } from "react-bootstrap";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { Checkbox as MCheckbox } from "@mui/material";
import { Button as BButton } from "react-bootstrap";

import {
  ListWidget,
  PropertyLable,
  PropertyValue,
} from "components/builder/ui/uiComponents/UIComponentStyle";
import Grid from "components/builder/ui/uiComponents/grid/Grid";
import UIComponentSection from "components/builder/ui/editor/UIComponentSection";

import {
  ArrayUtils,
  JsonUtils,
  ObjectUtils,
  StringUtils,
} from "components/common/utils/CommonUtils";
import USelectbox from "components/common/element/USelectbox";
import UInputPopup from "components/common/element/UInputPopup";
import Popup from "components/common/Popup";

import ExtendPopup from "page/popup/ExtendPopup";
import UElementList from "components/common/element/UElementList";
import UTextarea from "components/common/element/UTextarea";
import produce from "immer";
import MajorListPopup from "page/popup/MajorListPopup";

import ValidationPopup from "page/popup/ValidationPopup";
import UPopover from "components/common/element/UPopover";
import { Enums } from "components/builder/BuilderEnum";
import SelectEntityPopup from "page/popup/SelectEntityPopup";
import { connect } from "react-redux";
import User from "components/common/utils/UserUtils";
import { AppContext } from "components/common/AppContextProvider";
import ComponentService from "services/common/ComponentService";
import UCodeMirrorButton from "components/common/element/UCodeMirrorButton";
import GridCellTemplateGuidePopup from "page/popup/GridCellTemplateGuidePopup";
import { BsArrowReturnRight } from "react-icons/bs";
import GridProgressCellTemplateGuidePopup from "page/popup/GridProgressCellTemplateGuidePopup";

class GridCell extends Grid {
  constructor(props) {
    super(props);

    this.openExtendPopup = this.openExtendPopup.bind(this);
    this.onChangeDataType = this.onChangeDataType.bind(this);
    this.onChangeUserDefindFormat = this.onChangeUserDefindFormat.bind(this);
    this.openMajorCdList = this.openMajorCdList.bind(this);
    this.openOptionsPopup = this.openOptionsPopup.bind(this);
    this.onChangecomboEntityFilter = this.onChangecomboEntityFilter.bind(this);
    this.openValidationPopup = this.openValidationPopup.bind(this);
    this.progressBarTemplate =
      '<div style="display: flex; height: 1.2rem; overflow: hidden; font-size: .95rem; background-color: #e9ecef; border-radius: 0.25rem;">' +
      '\n\t<div style="display: flex; flex-direction: column; justify-content: center; overflow: hidden; color: #fff; text-align: center; white-space: nowrap; background-color: #498ff7;"></div>\n</div>';

    this.timeLineTemplate =
      '<div style="display: flex; height: 1.2rem; overflow: hidden; font-size: .95rem; background-color: #e9ecef; border-radius: 1.25rem; position: relative;">' +
      '\n\t<div style="display: flex; flex-direction: column; justify-content: center; overflow: hidden; color: #fff; background-color: #498ff7; position: absolute; top: 0px; bottom: 0px;"></div>' +
      '\n\t<div style="z-index: 999; display: flex; align-items: center; color: #444; justify-content: center; padding: 0.3em;"></div>\n</div>';
  }

  static contextType = AppContext;

  /**
   * 유효성 검사 팝업 오픈
   * @param {*} e
   */
  openValidationPopup = (e) => {
    const id = e.currentTarget.formTarget;
    const options = {
      effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
      style: {
        content: {
          width: "45%",
        },
      },
    };
    let dataType = this.state.propertyValue.dataType;
    if (StringUtils.equalsIgnoreCase(dataType, "string")) {
      dataType = "text";
    } else if (String(dataType).includes("numeric")) {
      dataType = "number";
    }

    ComponentService.getValidationRuleList({}, (res) => {
      const valList = res.data.filter((cv) =>
        StringUtils.equalsIgnoreCase(cv.componentDtlType, dataType)
      );

      Popup.open(
        <ValidationPopup
          onSave={(validation) => this.setInputValue("input", id, validation)}
          appliedRules={this.state.propertyValue[id]}
          valRuleList={valList}
          gridValidaton={true}
        />,
        options
      );
    });
  };

  openExtendPopup = (e, title) => {
    const id = e.currentTarget.formTarget;
    const options = {
      keyDownEvent: false,
      effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
      style: {
        content: {
          minHeight: "200px",
        },
      },
    };
    Popup.open(
      <ExtendPopup
        title={title}
        fieldType="html"
        defaultValue={this.state.propertyValue[id]}
        isEventBuilder={false}
        callbackFnc={(val, props) => {
          //이벤트빌더 때문에 방식 변경
          this.setInputValue("input", id, val);
        }}
      />,
      options
    );
  };

  /**
   * on Change Data Type
   * @param {Event} event
   */
  onChangeDataType = (event) => {
    let optionId = event.target.id;
    let optionValue = event.target.value;
    if (
      StringUtils.defaultString(this.state.propertyValue[optionId]) !==
      optionValue
    ) {
      const newPropertyValue = produce(this.state.propertyValue, (draft) => {
        if (StringUtils.isEmpty(optionValue)) {
          delete draft[optionId];
          delete draft["comboType"];
          delete draft["comboItems"];
          delete draft["searchTp"];
          delete draft["url"];
          delete draft["majorCd"];
          delete draft["comboUrl"];
          delete draft["idColumn"];
          delete draft["textColumn"];
          delete draft["beforeComboCall"];
          delete draft["currency"];
        } else {
          draft[optionId] = optionValue;
          if (optionValue.indexOf("select") < 0) {
            delete draft["comboType"];
            delete draft["comboItems"];
            delete draft["searchTp"];
            delete draft["url"];
            delete draft["majorCd"];
            delete draft["comboUrl"];
            delete draft["idColumn"];
            delete draft["textColumn"];
            delete draft["beforeComboCall"];
          } else if (optionValue === "selectAutoComplate") {
            draft["comboType"] = "search";
          }

          if (optionValue.indexOf("numeric") < 0) {
            delete draft["currency"];
          }

          if (optionValue.indexOf("fileupload") !== -1) {
            draft["editType"] = "R";
          }
        }
      });

      this.setState(
        {
          ...this.state.propertyValue,
          propertyValue: newPropertyValue,
        },
        () => {
          //propertyValue.compId 로 target component node를 찾아 json정보를 update한다.
          this.props.fn.updateProperty(this.state.propertyValue);
        }
      );
    }
  };

  onChangeUserDefindFormat = (event) => {
    let optionId = event.target.id;
    let optionData = event.target._data;
    if (
      StringUtils.defaultString(this.state.propertyValue[optionId]) !==
      optionData
    ) {
      const newPropertyValue = produce(this.state.propertyValue, (draft) => {
        draft[optionId] = optionData;
      });

      this.setState(
        {
          ...this.state.propertyValue,
          propertyValue: newPropertyValue,
        },
        () => {
          //propertyValue.compId 로 target component node를 찾아 json정보를 update한다.
          this.props.fn.updateProperty(this.state.propertyValue);
        }
      );
    }
  };

  /**
   * Major 코드 List 조회 popup
   * @param {*} e
   */
  openMajorCdList = (e) => {
    const options = {
      effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
      style: {
        content: {
          width: "550px",
          height: "625px",
        },
      },
    };

    Popup.open(
      <MajorListPopup
        title="Major 코드 목록"
        workspace={this.props.workspace}
        callbackFnc={(popVal) => {
          this.setInputValue("input", "majorCd", popVal.majorCd);
        }}
      />,
      options
    );
  };

  /**
   * Data Type이 콤보일 때, Service Mapping Options Popup
   * @param {*} e
   * @param {*} exProps
   */
  openOptionsPopup = (e, exProps) => {
    const options = {
      keyDownEvent: false,
      effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
      style: {
        content: {
          minHeight: "200px",
        },
      },
    };

    Popup.open(
      <ExtendPopup
        title="Options 상세"
        fieldType="json"
        jsonValidation
        defaultValue={this.state.propertyValue.options}
        callbackFnc={(popVal) => {
          this.setInputValue("input", "options", popVal);
        }}
      />,
      options
    );
  };

  /**
   * Entity 상세 설정 팝업
   * @param {*} event
   */
  openPopupButton = (event) => {
    const options = {
      effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
      style: {
        content: {
          width: "50%",
          height: "calc(auto + 300px)",
          // height: "500px",
        },
      },
    };

    Popup.open(
      <SelectEntityPopup
        comboEntityId={this.state.propertyValue.comboEntityId}
        comboEntityNm={this.state.propertyValue.comboEntityNm}
        comboEntityFilter={this.state.propertyValue.comboEntityFilter}
        dataModelId={this.getDataModel()}
        operatorList={this.context.code.getCodeList("Z0026")}
        callbackFnc={(comboEntityId, comboEntityNm, comboEntityFilter) => {
          JsonUtils.cleanup(comboEntityFilter);
          this.setInputValue("input", "comboEntityNm", comboEntityNm);
          this.onChangePropertyValues({
            comboEntityId: !StringUtils.isEmpty(comboEntityId)
              ? Number(comboEntityId)
              : "",
            comboEntityNm: comboEntityNm,
            comboEntityFilter: comboEntityFilter,
          });
        }}
      />,
      options
    );
  };

  /**
   * Entity base Combo 일 경우  Entity 변경시 Filter 초기화 포함
   * @param {*} event
   */
  onChangecomboEntityFilter = (event) => {
    let changedPropertyValues = { [event.target.id]: event.target.value };
    changedPropertyValues.comboEntityFilter = [{}];
    this.onChangePropertyValues(changedPropertyValues);
  };

  onChangeDataBinding = (event) => {
    let changedPropertyValues = {};
    changedPropertyValues[event.target.id] = event.target.value;

    //사용자 정의 채번일 경우 채번설정을 활성화 시킨다.
    if (
      !ObjectUtils.isEmpty(event.target._data) &&
      (event.target._data.autoNumberingType === Enums.Numbering.STANDARD ||
        event.target._data.autoNumberingType === Enums.Numbering.USER_DEF)
    ) {
      let insertOption = JsonUtils.parseJson(event.target._data.insertOption);
      if (!ObjectUtils.isEmpty(insertOption)) {
        changedPropertyValues["insertOption"] = insertOption;
        changedPropertyValues["autoNumberingType"] =
          event.target._data.autoNumberingType;

        //첫번째 output을 해당 column으로 binding시킨다.
        let outputIndex = ArrayUtils.getIndex(
          insertOption.arguments,
          "mode",
          "OUT"
        );
        if (outputIndex > -1) {
          insertOption.arguments[outputIndex]["dataBinding"] =
            StringUtils.defaultString(event.target.value);
        }
      }
    }

    //numbering 관련 data 정리
    if (ObjectUtils.isEmpty(changedPropertyValues.insertOption)) {
      changedPropertyValues["insertOption"] = "";
      changedPropertyValues["autoNumberingType"] = "";
    }

    this.onChangePropertyValues(changedPropertyValues);
  };

  /**
   * UI 컴포넌트 오버라이드
   * Cell의 Render 함수들은 fucntion(){...}로 감싸줘야함
   * eventType -> userWrapFnc
   *
   * @override
   * @param {String} eventId
   * @param {String} popTitle
   * @param {Object} props
   * @returns
   */
  renderEventTextArea = (eventId, popTitle, props = {}) => {
    let eventWorkspacePath =
      props.eventWorkspacePath || this.state.propertyValue;

    let eventWorkspace = {};
    if (eventWorkspacePath) {
      eventWorkspace = eventWorkspacePath.eventWorkspace || {};
    }
    /**
     * Cell일때, Grid일때 ID 위치가 다름
     * Component Type 위치가 다름
     * */
    let compId = this.state.propertyValue.compId;
    let componentType = this.props.componentInfo.type;
    /**
     * originalOutput
     * render 함수의 경우 function(){...}으로 둘러쌓여있기 때문에 삭제 후 처리, 이벤트 워크스페이스가 있을때만
     * 개행문자로 처리함(\n)
     * 첫번째 function 라인, 마지막 } 괄호 삭제
     */
    let originalOutput = this.state.propertyValue[eventId] || "";
    if (
      StringUtils.equalsIgnoreCase(props.eventType, "userWrapFnc") &&
      !ObjectUtils.isEmpty(eventWorkspace[eventId])
    ) {
      let originalOutputArr = originalOutput.split("\n");
      //첫번쨰 function 삭제
      originalOutputArr.splice(0, 1);
      //마지막 } 부분 삭제
      originalOutputArr.splice(-1, 1);
      //재결합
      originalOutput = originalOutputArr.join("\n");
    }

    const getEventInfo = () => {
      return {
        eventWorkspace: eventWorkspace[eventId] || {},
        compId: compId,
        eventCd: props.eventCd,
        targetType: "grid",
        eventCategory: "etc",
        eventType:
          Enums.EventHandlerEventType[eventId] ||
          props.eventType ||
          Enums.EventHandlerEventType.USR_EVENT_FUNCTION,
        builderEventType: eventId,
        componentType,
        componentInfo: this.props.componentInfo,
        originalOutput: originalOutput,
        programType: this.props.output.page.propertyValue?.programType || "M",
      };
    };

    let defaultValue = null;
    if (
      eventId === "cellTemplate" &&
      StringUtils.isEmpty(this.state.propertyValue[eventId])
    ) {
      defaultValue =
        this.state.propertyValue.dataType === "progressBar"
          ? this.progressBarTemplate
          : this.state.propertyValue.dataType === "timeLineProgressBar"
          ? this.timeLineTemplate
          : null;
    }

    return (
      <UCodeMirrorButton
        popTitle={popTitle}
        propId={eventId}
        fieldType="javascript"
        eventWorkspace={eventWorkspace[eventId] || {}}
        getEventInfo={getEventInfo}
        onClickEventBuilder={() => {
          this.props.fn.onClickEventBuilder(getEventInfo());
        }}
        defaultValue={
          defaultValue === null
            ? StringUtils.defaultString(this.state.propertyValue[eventId])
            : defaultValue
        }
        onBlur={(e) =>
          this.onChangeEvent(e, this.props.componentInfo.propertyValue)
        }
        {...props}
      />
    );
  };

  /**
   * Properties tab panel을 Redering
   * @returns
   */
  renderPropertiesPanel = () => {
    const entityType = this.getGridEntityType();

    return (
      <React.Fragment>
        {/* Title */}
        {this.renderComponentTitle("Grid > Cell", false)}
        <Accordion defaultActiveKey={[0, 1, 2, 3]} alwaysOpen>
          <Accordion.Item eventKey={0}>
            <Accordion.Header>기본정보</Accordion.Header>
            <Accordion.Body>
              <PropertyLable>Data binding</PropertyLable>
              <PropertyValue>
                {ObjectUtils.isEmpty(
                  this.props.componentInfo.gridOptions.dataModelEntityId
                ) ? (
                  <input
                    type="text"
                    id="data"
                    defaultValue={StringUtils.defaultString(
                      this.state.propertyValue.data
                    )}
                    onChange={this.onChangeDataBinding}
                    className="form-control form-control-sm"
                  />
                ) : (
                  <USelectbox
                    type="entityField"
                    id="data"
                    defaultValue={StringUtils.defaultString(
                      this.state.propertyValue.data
                    )}
                    onChange={this.onChangeDataBinding}
                    entityId={
                      this.props.componentInfo.gridOptions.dataModelEntityId
                    }
                    options={{
                      matchId: "id",
                      matchNm: "text",
                      beforeChkFn: () => {
                        if (
                          StringUtils.isEmpty(
                            this.props.componentInfo.gridOptions
                              .dataModelEntityId
                          )
                        ) {
                          return false;
                        }
                        return true;
                      },
                    }}
                  />
                )}
              </PropertyValue>
              <PropertyLable>Data Type</PropertyLable>
              <PropertyValue>
                {/* <InputGroup> */}
                <USelectbox
                  type="common"
                  mstCd="Z0011"
                  id="dataType"
                  defaultValue={StringUtils.defaultString(
                    this.state.propertyValue.dataType
                  )}
                  onChange={this.onChangeDataType}
                  options={{
                    displayModify: "'[' + code[matchCd] + '] '+ code[matchNm]",
                  }}
                />
              </PropertyValue>
              {this.state.propertyValue.dataType === "timeLineProgressBar" && (
                <>
                  <PropertyLable>Start Date</PropertyLable>
                  <PropertyValue>
                    <USelectbox
                      type="entityField"
                      id="startDate"
                      defaultValue={StringUtils.defaultString(
                        this.state.propertyValue.startDate
                      )}
                      onChange={this.onChangeDataBinding}
                      entityId={
                        this.props.componentInfo.gridOptions.dataModelEntityId
                      }
                      options={{
                        matchId: "id",
                        matchNm: "text",
                        beforeChkFn: () => {
                          if (
                            StringUtils.isEmpty(
                              this.props.componentInfo.gridOptions
                                .dataModelEntityId
                            )
                          ) {
                            return false;
                          }
                          return true;
                        },
                      }}
                    />
                  </PropertyValue>
                  <PropertyLable>End Date</PropertyLable>
                  <PropertyValue>
                    <USelectbox
                      type="entityField"
                      id="endDate"
                      defaultValue={StringUtils.defaultString(
                        this.state.propertyValue.endDate
                      )}
                      onChange={this.onChangeDataBinding}
                      entityId={
                        this.props.componentInfo.gridOptions.dataModelEntityId
                      }
                      options={{
                        matchId: "id",
                        matchNm: "text",
                        beforeChkFn: () => {
                          if (
                            StringUtils.isEmpty(
                              this.props.componentInfo.gridOptions
                                .dataModelEntityId
                            )
                          ) {
                            return false;
                          }
                          return true;
                        },
                      }}
                    />
                  </PropertyValue>
                </>
              )}
              {(StringUtils.equalsIgnoreType(
                this.state.propertyValue.dataType,
                "numeric(0)"
              ) ||
                StringUtils.equalsIgnoreType(
                  this.state.propertyValue.dataType,
                  "numeric(1)"
                ) ||
                StringUtils.equalsIgnoreType(
                  this.state.propertyValue.dataType,
                  "numeric(2)"
                ) ||
                StringUtils.equalsIgnoreType(
                  this.state.propertyValue.dataType,
                  "numeric(3)"
                )) && (
                <>
                  <PropertyLable>
                    <BsArrowReturnRight /> Format
                  </PropertyLable>
                  <PropertyValue>
                    <USelectbox
                      type="static"
                      id="userDefindFormat"
                      items={
                        this.props.output.page.propertyValue.userDefindFormat
                      }
                      defaultValue={StringUtils.defaultString(
                        this.state.propertyValue.userDefindFormat
                          ? this.state.propertyValue.userDefindFormat.formatId
                          : ""
                      )}
                      onChange={this.onChangeUserDefindFormat}
                      options={{
                        matchCd: "formatId",
                        matchNm: "formatName",
                      }}
                    />
                  </PropertyValue>
                </>
              )}
              {!StringUtils.isEmpty(this.state.propertyValue.dataType) &&
              this.state.propertyValue.dataType.indexOf("numeric") !== -1 &&
              this.state.propertyValue.dataType !== "numeric(1)" &&
              this.props.workspace.appGroupCd === Enums.AppType.UNIERP ? (
                <>
                  <PropertyLable>Currency Type</PropertyLable>
                  <PropertyValue>
                    <USelectbox
                      type="static"
                      id="currency"
                      defaultValue={StringUtils.defaultString(
                        this.state.propertyValue.currency
                      )}
                      onChange={this.onChange}
                      items={this.context.format.list.filter((item) => {
                        const pageProperty =
                          this.props.output.page.propertyValue;
                        return (
                          item.numberType === "NUM" &&
                          item.moduleCd === pageProperty.moduleCd &&
                          item.formType === pageProperty.formType
                        );
                      })}
                      options={{
                        matchId: "currency",
                        matchNm: "currencyNm",
                        chooseText: `${
                          !User.getConnection(this.props.workspace.tenantMstId)
                            ?.token
                            ? "인증 서버 연결 필요"
                            : "선택"
                        }`,
                        displayModify:
                          "'[' + code[matchCd] + '] '+ code[matchNm]",
                      }}
                      readOnly={StringUtils.isEmpty(
                        this.state.propertyValue.dataType
                      )}
                    />
                  </PropertyValue>
                </>
              ) : (
                ""
              )}
              <PropertyLable>Data 집계</PropertyLable>
              <PropertyValue>
                <USelectbox
                  type="common"
                  mstCd="Z0012"
                  id="aggregate"
                  defaultValue={this.state.propertyValue.aggregate}
                  onChange={(e) =>
                    this.onChangePropertyValue(
                      "aggregate",
                      e.target.value ? Number(e.target.value) : null
                    )
                  }
                />
              </PropertyValue>
              <PropertyLable>Data 정렬</PropertyLable>
              <PropertyValue>
                <USelectbox
                  type="common"
                  mstCd="Z0013"
                  id="align"
                  defaultValue={StringUtils.defaultString(
                    this.state.propertyValue.align
                  )}
                  onChange={this.onChange}
                />
              </PropertyValue>
              <PropertyLable>Data 편집</PropertyLable>
              <PropertyValue>
                <USelectbox
                  type="common"
                  mstCd="Z0023"
                  id="editType"
                  defaultValue={StringUtils.defaultString(
                    this.state.propertyValue.editType
                  )}
                  onChange={this.onChange}
                  // disabled={
                  //   StringUtils.equalsIgnoreCase(entityType, "procedure") ||
                  //   StringUtils.equalsIgnoreCase(entityType, "function")
                  // }
                />
              </PropertyValue>
              {this.state.propertyValue.autoNumberingType ===
                Enums.Numbering.USER_DEF ||
              this.state.propertyValue.autoNumberingType ===
                Enums.Numbering.STANDARD ? (
                <React.Fragment>
                  <PropertyLable>자동 채번</PropertyLable>
                  <PropertyValue>
                    <InputGroup>
                      <input
                        type="text"
                        id="procedureName"
                        readOnly={true}
                        defaultValue={JsonUtils.defaultString(
                          this.state.propertyValue.insertOption,
                          "procedureName"
                        )}
                        className="form-control form-control-sm"
                      />
                      <BButton
                        variant="outline-secondary"
                        size="sm"
                        className="light-font-color"
                        onClick={(e) => {
                          this.onNumProcDetailClick(
                            e,
                            this.props.componentInfo.gridOptions
                              .dataModelEntityId
                          );
                        }}
                      >
                        프로시져 설정
                      </BButton>
                    </InputGroup>
                  </PropertyValue>
                </React.Fragment>
              ) : (
                ""
              )}
            </Accordion.Body>
          </Accordion.Item>

          {/* Service Mapping property -> type이 콤보일 경우 */}
          {!StringUtils.isEmpty(this.state.propertyValue.dataType) &&
          this.state.propertyValue.dataType.indexOf("select") !== -1 ? (
            <Accordion.Item eventKey={1}>
              <Accordion.Header>Service Mapping</Accordion.Header>
              <Accordion.Body>
                <React.Fragment>
                  <PropertyLable requried="true">Data 조회방법</PropertyLable>
                  <PropertyValue>
                    <USelectbox
                      id="searchTp"
                      type="common"
                      mstCd="Z0016"
                      defaultValue={StringUtils.defaultString(
                        this.state.propertyValue.searchTp
                      )}
                      onChange={(e) => {
                        let saveData = {
                          [e.target.id]: e.target.value,
                        };
                        if (
                          ["TABLE", "STATIC", "UNIERP"].indexOf(
                            e.target.value
                          ) !== -1
                        ) {
                          this.setInputValue("input", "idColumn", "id");
                          this.setInputValue("input", "textColumn", "text");
                          saveData.idColumn = "id";
                          saveData.textColumn = "text";
                        }

                        this.onChangePropertyValues(saveData);
                      }}
                    />
                  </PropertyValue>

                  {this.state.propertyValue.searchTp === "URL" ? (
                    <React.Fragment>
                      <PropertyLable>URL</PropertyLable>
                      <PropertyValue>
                        <input
                          type="text"
                          id="url"
                          defaultValue={StringUtils.defaultString(
                            this.state.propertyValue.url
                          )}
                          onBlur={this.onChange}
                          className="form-control form-control-sm"
                        />
                      </PropertyValue>
                    </React.Fragment>
                  ) : this.state.propertyValue.searchTp === "TABLE" ||
                    this.state.propertyValue.searchTp === "UNIERP" ? (
                    <React.Fragment>
                      <PropertyLable>Major 코드</PropertyLable>
                      <PropertyValue>
                        <UInputPopup
                          id="majorCd"
                          defaultValue={StringUtils.defaultString(
                            this.state.propertyValue.majorCd
                          )}
                          onClick={this.openMajorCdList}
                          onBlur={this.onChange}
                        />
                      </PropertyValue>
                      <PropertyLable>Major 유형</PropertyLable>
                      <PropertyValue>
                        <USelectbox
                          id="comboUrl"
                          onChange={this.onChange}
                          defaultValue={StringUtils.defaultString(
                            this.state.propertyValue.comboUrl,
                            "C"
                          )}
                          items={[
                            {
                              id: "/common/selectMinorComboList.do",
                              text: "공통 Major 조회",
                            },
                            {
                              id: "/common/selectMinorTenantComboList.do",
                              text: "Tenant Major 조회",
                            },
                          ]}
                          options={{ matchCd: "id", matchNm: "text" }}
                        />
                      </PropertyValue>
                    </React.Fragment>
                  ) : this.state.propertyValue.searchTp === "ENTITY" ? (
                    <React.Fragment>
                      <PropertyLable requried="true">Entity</PropertyLable>
                      <PropertyValue requried="true">
                        <ButtonGroup>
                          <input
                            type="text"
                            id="comboEntityNm"
                            defaultValue={StringUtils.defaultString(
                              this.state.propertyValue.comboEntityNm
                            )}
                            className="form-control form-control-sm"
                            readOnly={true}
                          />
                          <button
                            id="entityPopup"
                            onClick={this.openPopupButton}
                            className="btn btn-sm btn-outline-secondary light-font-color"
                            style={{ width: "100px" }}
                          >
                            Entity 설정
                          </button>
                        </ButtonGroup>
                      </PropertyValue>
                    </React.Fragment>
                  ) : this.state.propertyValue.searchTp === "WORKFLOW" ? (
                    <React.Fragment>
                      <PropertyLable requried="true">Workflow</PropertyLable>
                      <PropertyValue requried="true">
                        <InputGroup>
                          <input
                            type="text"
                            id="serviceName"
                            value={StringUtils.defaultString(
                              this.state.propertyValue.serviceName
                            )}
                            className="form-control form-control-sm"
                            onChange={() => {}}
                            onClick={this.onWorkflowSettingClick}
                            readOnly
                            aria-readonly
                            placeholder="Workflow 명"
                          />
                          <BButton
                            variant="outline-secondary"
                            size="sm"
                            className="light-font-color"
                            onClick={this.onWorkflowSettingClick}
                          >
                            선택
                          </BButton>
                          {this.state.propertyValue.serviceUid && (
                            <BButton
                              variant="outline-secondary"
                              size="sm"
                              className="light-font-color"
                              onClick={this.onWorkflowDetailClick}
                            >
                              상세
                            </BButton>
                          )}
                        </InputGroup>
                      </PropertyValue>
                    </React.Fragment>
                  ) : this.state.propertyValue.searchTp === "STATIC" ? (
                    <UElementList
                      requried="true"
                      label="Data Set"
                      isDisplay={true}
                      bodyStyle={{ maxHeight: "250px" }}
                      id="comboItems"
                      data={this.state.propertyValue.comboItems}
                      onBlur={this.onChangePropertyValue}
                      enableEmpty={true}
                      cols={[
                        {
                          label: "Value Field",
                          type: "input",
                          id: "code",
                          className: "w-30p",
                        },
                        {
                          label: "Text Field",
                          type: "input",
                          id: "text",
                          className: "w-60p",
                        },
                      ]}
                    />
                  ) : (
                    ""
                  )}
                  {this.renderComboPathBinding()}
                  <PropertyLable>Grid Display Data</PropertyLable>
                  <PropertyValue>
                    <InputGroup>
                      <input
                        type="text"
                        id="displayGridColumn"
                        defaultValue={StringUtils.defaultString(
                          this.state.propertyValue.displayGridColumn
                        )}
                        className="form-control form-control-sm"
                        onBlur={this.onChange}
                      />
                      <UPopover title="Grid에서 표시되는 Data 선택">
                        <ul>
                          <li>
                            ※ Binding되어있는 Data가 아닌 다른 Data를 Grid에
                            보여주고자 할때 사용됩니다.
                          </li>
                          <li>
                            ※ [selectAutoComplate] 콤보(조회형 자동완성) 또는
                            Multi-Column이 "Yes"일 때만 적용됩니다.
                          </li>
                        </ul>
                      </UPopover>
                    </InputGroup>
                  </PropertyValue>
                </React.Fragment>

                <React.Fragment>
                  <PropertyLable>
                    Options <small>(JSON)</small>
                  </PropertyLable>
                  <PropertyValue>
                    <UInputPopup
                      popTitle="Select Service Mapping Options 상세"
                      fieldType="json"
                      id="options"
                      defaultValue={StringUtils.defaultString(
                        this.state.propertyValue.options
                      )}
                      onBlur={this.onChange}
                      onClick={this.openOptionsPopup}
                    />
                  </PropertyValue>
                  {this.state.propertyValue.dataType !== "selectMultiItem" &&
                  this.state.propertyValue.searchTp !== "STATIC" ? (
                    <React.Fragment>
                      <PropertyLable>Multi-Column</PropertyLable>
                      <PropertyValue>
                        <BootstrapSwitchButton
                          id="isMuliColumn"
                          checked={StringUtils.defaultString(
                            this.state.propertyValue.isMuliColumn,
                            false
                          )}
                          size="sm"
                          onstyle="primary"
                          offstyle="dark"
                          onlabel="Yes"
                          offlabel="No"
                          onChange={(boolean) =>
                            this.onChange({
                              target: {
                                id: "isMuliColumn",
                                value: boolean,
                              },
                            })
                          }
                        />
                      </PropertyValue>
                      {this.state.propertyValue.isMuliColumn === true ? (
                        <React.Fragment>
                          <PropertyLable>
                            Multi-Column Template <small>(HTML)</small>
                          </PropertyLable>
                          <PropertyValue>
                            <UTextarea
                              textareaId="dropdownTemplate"
                              popTitle="Dropdown Template"
                              fieldType="html"
                              defaultValue={StringUtils.defaultString(
                                this.state.propertyValue.dropdownTemplate
                              )}
                              onBlur={this.onChange}
                            />
                          </PropertyValue>
                        </React.Fragment>
                      ) : (
                        ""
                      )}
                    </React.Fragment>
                  ) : (
                    ""
                  )}
                </React.Fragment>

                <PropertyLable>Combo 생성 전</PropertyLable>
                <PropertyValue>
                  <InputGroup>
                    {this.renderEventTextArea(
                      "beforeComboCall",
                      "Combo 생성 전 처리 사용자 정의",
                      {
                        fieldType: "javascript",
                        rows: 1,
                        eventCd: "combo.load",
                        onBlur: (e) =>
                          //                           this.onChangeEvent(e, this.state.propertyValue),
                          this.onChangeEvent(
                            e,
                            this.state.propertyValue.beforeComboCall
                          ),
                      }
                    )}
                    <UPopover title="사용 예시">
                      <ul>
                        <li>
                          <font color="red">
                            Combo가 화면에 그려지기 되기 전에 실행 되는 사용자
                            정의 함수 입니다.
                          </font>
                        </li>
                        <li>
                          <strong>▣ 예시1) Combo 조회 parameter 변경</strong>
                        </li>
                        <li>
                          <code>$comboParam.coCdSearch = variable.coCd;</code>
                        </li>
                      </ul>
                    </UPopover>
                  </InputGroup>
                </PropertyValue>
                <PropertyLable>Data 조회 후</PropertyLable>
                <PropertyValue>
                  <InputGroup>
                    {this.renderEventTextArea(
                      "afterComboCall",
                      "Combo Data 조회 후 처리 사용자 정의",
                      {
                        fieldType: "javascript",
                        rows: 1,
                        eventCd: "combo.load",
                        onBlur: (e) =>
                          this.onChangeEvent(
                            e,
                            this.state.propertyValue.beforeComboCall
                          ),
                      }
                    )}
                    <UPopover title="사용 예시">
                      <ul>
                        <li>
                          <font color="red">
                            Combo Data를 조회 한 후 실행 되는 사용자 정의 함수
                            입니다.
                          </font>
                          <br></br>
                          <font color="blud">$data</font> : 조회된 Combo Data
                        </li>
                      </ul>
                    </UPopover>
                  </InputGroup>
                </PropertyValue>
              </Accordion.Body>
            </Accordion.Item>
          ) : (
            ""
          )}

          {!StringUtils.isEmpty(this.state.propertyValue.dataType) &&
          (this.state.propertyValue.dataType.indexOf("checkbox") !== -1 ||
            this.state.propertyValue.dataType.indexOf("switch") !== -1) ? (
            <Accordion.Item eventKey={1}>
              <Accordion.Header>Checkbox Value 설정</Accordion.Header>
              <Accordion.Body>
                <PropertyLable>checked</PropertyLable>
                <PropertyValue>
                  <input
                    type="text"
                    id="checkedValue"
                    defaultValue={StringUtils.defaultString(
                      this.state.propertyValue.checkedValue
                    )}
                    placeholder="true"
                    className="form-control form-control-sm"
                    onBlur={this.onChange}
                  />
                </PropertyValue>
                <PropertyLable>UnChecked</PropertyLable>
                <PropertyValue>
                  <input
                    type="text"
                    id="unCheckedValue"
                    defaultValue={StringUtils.defaultString(
                      this.state.propertyValue.unCheckedValue
                    )}
                    placeholder="false"
                    className="form-control form-control-sm"
                    onBlur={this.onChange}
                  />
                </PropertyValue>
              </Accordion.Body>
            </Accordion.Item>
          ) : (
            ""
          )}

          {!StringUtils.isEmpty(this.state.propertyValue.dataType) &&
          this.state.propertyValue.dataType.indexOf("fileupload") !== -1 ? (
            <Accordion.Item eventKey={1}>
              <Accordion.Header>File Upload 설정</Accordion.Header>
              <Accordion.Body>
                <PropertyLable>표현 구분</PropertyLable>
                <PropertyValue>
                  <USelectbox
                    id="showType"
                    type="common"
                    mstCd="Z0038"
                    defaultValue={StringUtils.defaultString(
                      this.state.propertyValue.showType
                    )}
                    onChange={this.onChange}
                    options={{ isChoose: false }}
                  />
                </PropertyValue>
                <PropertyLable>Open Fn</PropertyLable>
                <PropertyValue>
                  <UTextarea
                    popTitle="Popup Open Fnuntion 상세"
                    textareaId="openFileFn"
                    defaultValue={StringUtils.defaultString(
                      this.state.propertyValue.openFileFn
                    )}
                    onBlur={this.onChange}
                  />
                </PropertyValue>
                <PropertyLable>Close Fn</PropertyLable>
                <PropertyValue>
                  <UTextarea
                    popTitle="popup Close Fnuntion 상세"
                    textareaId="closeFileFn"
                    defaultValue={StringUtils.defaultString(
                      this.state.propertyValue.closeFileFn
                    )}
                    onBlur={this.onChange}
                  />
                </PropertyValue>
              </Accordion.Body>
            </Accordion.Item>
          ) : (
            ""
          )}

          {/* Data Validation */}
          <Accordion.Item eventKey={2}>
            <Accordion.Header>Data Validation</Accordion.Header>
            <Accordion.Body>
              <PropertyLable>Required</PropertyLable>
              <PropertyValue>
                <BootstrapSwitchButton
                  id="required"
                  checked={StringUtils.defaultString(
                    this.state.propertyValue.required
                  )}
                  size="sm"
                  onstyle="primary"
                  offstyle="dark"
                  onlabel="Yes"
                  offlabel="No"
                  onChange={(value) =>
                    this.onChangePropertyValue("required", value)
                  }
                />
              </PropertyValue>
              <PropertyLable>Max Length</PropertyLable>
              <PropertyValue>
                <input
                  type="number"
                  id="maxlength"
                  defaultValue={StringUtils.defaultString(
                    this.state.propertyValue.maxlength
                  )}
                  className="form-control form-control-sm"
                  onBlur={(e) => {
                    let value = e.target.value;
                    this.onChangePropertyValue("maxlength", parseInt(value));
                  }}
                />
              </PropertyValue>
              <PropertyLable style={{ fontSize: "11.6px" }}>
                Validation Rule
              </PropertyLable>
              <PropertyValue>
                <UInputPopup
                  id="validationRules"
                  textReadonly
                  defaultValue={StringUtils.defaultString(
                    JSON.stringify(this.state.propertyValue.validationRules)
                  )}
                  onClick={this.openValidationPopup}
                  onBlur={(e) =>
                    this.onChange({
                      target: {
                        id: e.target.id,
                        value: JSON.parse(e.target.value),
                      },
                    })
                  }
                />
              </PropertyValue>
            </Accordion.Body>
          </Accordion.Item>

          {/* Cell Rendering */}
          <Accordion.Item eventKey={3}>
            <Accordion.Header>Cell Rendering</Accordion.Header>
            <Accordion.Body>
              <PropertyLable>Cell 병합</PropertyLable>
              <PropertyValue>
                <BootstrapSwitchButton
                  id="mergeable"
                  checked={StringUtils.defaultString(
                    StringUtils.equalsIgnoreCase(
                      this.state.propertyValue.mergeable,
                      1
                    )
                  )}
                  size="sm"
                  onstyle="primary"
                  offstyle="dark"
                  onlabel="Yes"
                  offlabel="No"
                  onChange={(value) =>
                    this.onChangePropertyValue("mergeable", value ? 1 : 0)
                  }
                />
              </PropertyValue>
              <PropertyLable>Multi-Lines</PropertyLable>
              <PropertyValue>
                <BootstrapSwitchButton
                  id="multiLine"
                  checked={StringUtils.defaultString(
                    StringUtils.equalsIgnoreCase(
                      this.state.propertyValue.multiLine,
                      1
                    )
                  )}
                  size="sm"
                  onstyle="primary"
                  offstyle="dark"
                  onlabel="Yes"
                  offlabel="No"
                  onChange={(value) =>
                    this.onChangePropertyValue("multiLine", value ? 1 : 0)
                  }
                />
              </PropertyValue>
              <div className="event-list-props">
                <div className="event-list-group">
                  <ListWidget
                    title="FormatItem"
                    desc="JavaScript를 이용해 조건에 따라 셀의 스타일이나 내용을 변경 할 수 있습니다."
                  >
                    {this.renderEventTextArea(
                      "render",
                      "Cell에 Data 및 표시 형식 변경",
                      {
                        fieldType: "javascript",
                        rows: 1,
                        eventCd: "grid.cell.fnc.render",
                        eventType: "userWrapFnc",
                        onBlur: (e) =>
                          this.onChangeEvent(e, this.state.propertyValue),
                      }
                    )}
                  </ListWidget>
                  <ListWidget
                    title="ItemFormatter"
                    desc="JavaScript를 이용해 셀의 스타일을 변경 할 수 있습니다."
                  >
                    {this.renderEventTextArea(
                      "createdCell",
                      "Cell 표시 형식 변경",
                      {
                        fieldType: "javascript",
                        rows: 1,
                        eventCd: "grid.cell.fnc.custom",
                        eventType: "userWrapFnc",
                        onBlur: (e) =>
                          this.onChangeEvent(
                            e,
                            this.state.propertyValue.editorAttr
                          ),
                      }
                    )}
                  </ListWidget>
                  <ListWidget
                    title="CellTemplate"
                    desc="HTML Tag를 이용해 셀의 스타일을 변경 할 수 있습니다."
                  >
                    {this.renderEventTextArea("cellTemplate", "Cell Template", {
                      fieldType: "template",
                      isEventBuilder: false,
                      rows: 1,
                      documentPage:
                        this.state.propertyValue.dataType === "progressBar" ||
                        this.state.propertyValue.dataType ===
                          "timeLineProgressBar"
                          ? GridProgressCellTemplateGuidePopup
                          : GridCellTemplateGuidePopup,
                      onBlur: (e) =>
                        this.onChangeEvent(
                          e,
                          this.state.propertyValue.editorAttr
                        ),
                    })}
                  </ListWidget>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </React.Fragment>
    );
  };

  /**
   * Editor의 component를 Redering
   * <<Grid editor props>>
   *   - compId - 현재 component의 고유 ID
   *   - componentInfo - drag & drop시 생성된 component object
   *   - style - dragging style이 포함된 style (사용자가 정의한 style은 각 component에서 적절히 적용해야함)
   *   - event="renderEditor" - 요청 구분
   * @returns
   */
  renderEditor = () => {
    //GridCell,GridHeader 은 this.state.propertyValue 대신 this.props.componentInfo.propertyValue를 사용해야함. (editorAttr 마찬가지)
    const propertyValue = this.props.componentInfo.propertyValue || {};
    const editorAttr = this.props.componentInfo.editorAttr || {};
    const gridOptions = this.props.componentInfo.gridOptions || {};
    let colStyle = this.props.style || {};

    if (gridOptions.rowHeight) {
      colStyle.height = gridOptions.rowHeight + "px";
    }
    return (
      <UIComponentSection
        item={this.props.componentInfo}
        style={colStyle}
        noneToolbar={true} //tollbar button 제거
        noneToolbarTitle={true} //toolbar title 제거
        className={`editor-base draggable editor-grid-column ${StringUtils.defaultString(
          editorAttr.className
        )}`}
      >
        {editorAttr.checkbox ? (
          <MCheckbox size="small" color="primary" defaultChecked={false} />
        ) : (
          ""
        )}
        {StringUtils.defaultString(propertyValue.name)}
      </UIComponentSection>
    );
  };
}

export default connect(
  (state) => {
    return {
      workspace: state.workspace,
    };
  },
  (dispatch) => {
    return {};
  }
)(GridCell);
