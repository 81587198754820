import Blockly from "blockly";
import { javascriptGenerator } from "blockly/javascript";

// Blockly create custom field link : https://developers.google.com/blockly/guides/create-custom-blocks/fields/customizing-fields/creating

// Blockly field github link : https://github.com/google/blockly/blob/develop/core/field.ts

class FieldReadOnlyInput extends Blockly.FieldTextInput {
  EDITABLE = false;

  constructor(value, validator, config) {
    super(value, validator, config);
    this.setValue(value);
  }

  doClassValidation_(newValue) {
    if (newValue === undefined) {
      return null;
    }
    this.options = newValue;
    if (Array.isArray(newValue)) {
      return newValue;
    } else {
      return null;
    }
  }

  static fromJson(options) {
    const text = Blockly.utils.parsing.replaceMessageReferences(options.text);
    // `this` might be a subclass of FieldTextInput if that class doesn't
    // override the static fromJson method.
    return new this(text, undefined, options);
  }

  createTextElement_() {
    this.textElement_ = Blockly.utils.dom.createSvgElement(
      Blockly.utils.Svg.TEXT,
      {
        class: "blocklyText",
      },
      this.fieldGroup_
    );
    if (this.getConstants().FIELD_TEXT_BASELINE_CENTER) {
      this.textElement_.setAttribute("dominant-baseline", "central");
    }
    this.textContent_ = document.createTextNode("");
    this.textElement_.appendChild(this.textContent_);
    this.fieldGroup_.style.fill = "white";
    this.textElement_.style.fill = "black";
  }

  getText_() {
    if (!this.options) {
      return null;
    }
    const option = this.options[1];
    if (typeof option === "object") {
      return option["alt"];
    }
    return option;
  }

  onMouseDown_(e) {
    if (!this.sourceBlock_ || this.sourceBlock_.isDeadOrDying()) {
      return;
    }
    const gesture = this.sourceBlock_.workspace.getGesture(e);
    if (gesture) {
      // gesture.setStartField(this);
    }
  }
}
if (
  !Blockly.registry.hasItem(
    new Blockly.registry.Type("field"),
    "field_readonly_input"
  )
) {
  Blockly.fieldRegistry.register("field_readonly_input", FieldReadOnlyInput);
}
javascriptGenerator.forBlock["readOnlyTextBlock"] = function (block) {
  const textValue = block.getFieldValue("readOnlyInput1");
  const code = textValue[0];
  return code;
};
const readOnlyJson = {
  type: "readOnlyTextBlock",
  message0: "%1",
  args0: [{ type: "field_readonly_input", name: "readOnlyInput1" }],
  output: null,
  colour: "#CEBAAD",
};
Blockly.Blocks["readOnlyTextBlock"] = {
  init: function () {
    this.jsonInit(readOnlyJson);
  },
};
