import Blockly from "blockly";

/**
 * Returns the extra state of the given block (either as XML or a JSO, depending
 * on the block's definition).
 * @param {!Blockly.BlockSvg} block The block to get the extra state of.
 * @returns {string} A stringified version of the extra state of the given
 *     block.
 */
const getExtraBlockState = function (block) {
  if (block.saveExtraState) {
    const state = block.saveExtraState();
    return state ? JSON.stringify(state) : "";
  } else if (block.mutationToDom) {
    const state = block.mutationToDom();
    return state ? Blockly.Xml.domToText(state) : "";
  }
  return "";
};

const plusImage =
  "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC" +
  "9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0Ij48cGF0aCBkPSJNMT" +
  "ggMTBoLTR2LTRjMC0xLjEwNC0uODk2LTItMi0ycy0yIC44OTYtMiAybC4wNzEgNGgtNC4wNz" +
  "FjLTEuMTA0IDAtMiAuODk2LTIgMnMuODk2IDIgMiAybDQuMDcxLS4wNzEtLjA3MSA0LjA3MW" +
  "MwIDEuMTA0Ljg5NiAyIDIgMnMyLS44OTYgMi0ydi00LjA3MWw0IC4wNzFjMS4xMDQgMCAyLS" +
  "44OTYgMi0ycy0uODk2LTItMi0yeiIgZmlsbD0id2hpdGUiIC8+PC9zdmc+Cg==";

/**
 * Plus image field
 * @param {*} args
 * @returns
 */
export const createPlusField = function (args = undefined) {
  const plus = new Blockly.FieldImage(
    plusImage,
    15,
    15,
    undefined,
    onPlusClick_
  );
  plus.args_ = args;
  return plus;
};

/**
 * Plus image field click 이벤트
 * @param {*} plusField
 * @returns
 */
const onPlusClick_ = function (plusField) {
  const block = plusField.getSourceBlock();

  if (block.isInFlyout) {
    return;
  }

  Blockly.Events.setGroup(true);
  const oldExtraState = getExtraBlockState(block);
  block.plus(plusField.args_);
  const newExtraState = getExtraBlockState(block);

  if (oldExtraState !== newExtraState) {
    Blockly.Events.fire(
      new Blockly.Events.BlockChange(
        block,
        "mutation",
        null,
        oldExtraState,
        newExtraState
      )
    );
  }
  Blockly.Events.setGroup(false);
};

const minusImage =
  "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAw" +
  "MC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0Ij48cGF0aCBkPS" +
  "JNMTggMTFoLTEyYy0xLjEwNCAwLTIgLjg5Ni0yIDJzLjg5NiAyIDIgMmgxMmMxLjEwNCAw" +
  "IDItLjg5NiAyLTJzLS44OTYtMi0yLTJ6IiBmaWxsPSJ3aGl0ZSIgLz48L3N2Zz4K";

/**
 * Minus image field
 * @param {*} args
 * @returns
 */
export const createMinusField = function (args) {
  const minus = new Blockly.FieldImage(
    minusImage,
    15,
    15,
    undefined,
    onMinusClick_
  );

  minus.args_ = args;
  return minus;
};

/**
 * Minus image field click 이벤트
 * @param {*} minusField
 * @returns
 */
const onMinusClick_ = function (minusField) {
  const block = minusField.getSourceBlock();

  if (block.isInFlyout) {
    return;
  }

  Blockly.Events.setGroup(true);
  const oldExtraState = getExtraBlockState(block);
  block.minus(minusField.name);
  const newExtraState = getExtraBlockState(block);

  if (oldExtraState !== newExtraState) {
    Blockly.Events.fire(
      new Blockly.Events.BlockChange(
        block,
        "mutation",
        null,
        oldExtraState,
        newExtraState
      )
    );
  }
  Blockly.Events.setGroup(false);
};
