import {
  DaafMessage,
  StringUtils,
} from "@alpha/com.bizentro.daaf.front.framework";
import ColorPicker from "components/builder/ui/ColorPicker";
import { WorkflowContext } from "page/workflow";
import { memo, useContext, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";

const WorkflowBundlingTab = memo(({ callback, ...props }) => {
  const {
    bundle: { bundleEndNode, bundleStartNode, setBundlingMode },
  } = useContext(WorkflowContext);
  const [groupNm, setGroupNm] = useState("");
  const [description, setDescription] = useState("");
  const [color, setColor] = useState("#FFC77D");

  const onClickBundle = () => {
    if (StringUtils.isEmpty(groupNm)) {
      return DaafMessage.alert("그룹 명을 입력하세요", "warning");
    }

    if (callback) {
      callback({ description, color, groupNm });
    }
  };

  //번들 컬러 선택
  const onChangeStyle = (e) => {
    setColor(e.target.value);
  };

  return (
    <div className="workflow-bundle-tab-wrapper">
      <div className="modal-header">
        <div className="modal-title">프로세스 그룹 설정</div>
        <button className="btn-close" onClick={(e) => setBundlingMode(false)} />
      </div>
      <Form.Group className="mt-3 mb-3">
        <Row>
          <Col xs={5}>
            <Form.Control
              value={bundleStartNode?.data?.process.propertyValue.processNm}
              size="sm"
              readOnly
              onChange={(e) => {}}
              placeholder="시작 프로세스를 선택"
            />
          </Col>
          <Col xs={1} className="hc vc">
            부터
          </Col>
          <Col xs={5}>
            {" "}
            <Form.Control
              value={bundleEndNode?.data?.process.propertyValue.processNm}
              size="sm"
              readOnly
              onChange={(e) => {}}
              placeholder={
                bundleStartNode?.data?.process.propertyValue.processNm
                  ? "그룹 마지막 프로세스를 선택"
                  : ""
              }
            />
          </Col>
          <Col xs={1} className="hc vc">
            까지
          </Col>
        </Row>
      </Form.Group>
      <Form.Group className="mb-3">
        <Row>
          <Col>
            <Form.Label className="required">그룹 명</Form.Label>
          </Col>
          <Col>
            <Form.Label>색상 선택</Form.Label>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Control
              value={groupNm}
              size="sm"
              placeholder="그룹 명 입력..."
              onChange={(e) => setGroupNm(e.target.value)}
              onKeyDown={(e) => {
                if (StringUtils.equalsIgnoreCase(e.key, "Enter")) {
                  onClickBundle();
                }
              }}
            />
          </Col>
          <Col>
            <ColorPicker
              id="color"
              defaultValue={color}
              onBlur={onChangeStyle}
            />
          </Col>
        </Row>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>그룹 설명</Form.Label>
        <Row>
          <Col>
            <Form.Control
              as="textarea"
              rows={2}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Col>
        </Row>
      </Form.Group>
      <div className="modal-footer">
        <Button onClick={onClickBundle} size="sm">
          그룹 설정
        </Button>

        <Button
          onClick={(e) => setBundlingMode(false)}
          size="sm"
          variant="outline-secondary"
        >
          닫기
        </Button>
      </div>
    </div>
  );
});

export default WorkflowBundlingTab;
