import Modal from "components/common/modal/UModal";
import UModalTemplate from "components/common/modal/UModalTemplate";
import React from "react";
import ProcessModal from "./process/ProcessModal";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { stopEvent } from "components/builder/ui/editor/handler/UIEditorEventHandler";
import StringUtils from "components/common/utils/StringUtils";
import Message from "components/common/Message";
import { Enums } from "components/builder/BuilderEnum";
import { AiOutlineEllipsis, AiOutlineQuestionCircle } from "react-icons/ai";
import produce from "immer";
import ProcessDataBindingPopup from "./ProcessDataBindingPopup";
import Popup from "components/common/Popup";
import { getEntityPropertyValue } from "components/builder/workflow/editor/render/WorkflowRenderUtils";
import IteratorOptionDescPopup from "./IteratorOptionDescPopup";

class IteratorPopup extends ProcessModal {
  constructor(props) {
    super(props);
    this.onOpenValuePopup = this.onOpenValuePopup.bind(this);
    this.callbackVariableEntityList =
      this.callbackVariableEntityList.bind(this);
    this.state = {
      processNm: "",
      option: "",
      iteratorVariable: "",
      iteratorNm: "",
      editorAttr: {
        referenceCompId: "",
      },
    };
  }

  componentDidMount() {
    if (this.props.processInfo) {
      this.setState(this.props.processInfo);
    }
  }

  validateCommonParam(e) {
    stopEvent(e);
    if (StringUtils.isEmpty(this.state.processNm)) {
      return Message.alert(
        "Iterator 명을 입력해주세요",
        Enums.MessageType.WARN
      );
    }
    if (this.onValidationCheck()) {
      this.onClickConfirm();
    }
  }

  /**
   *
   * @returns
   */
  renderProcessNm() {
    return (
      <Row className="mb-3">
        <Col xs={2} className="col-label">
          <Form.Label className="required">Iterator 명</Form.Label>
        </Col>
        <Col xs={10}>
          <Form.Control
            placeholder="Iterator 명을 입력해주세요."
            value={this.state.processNm}
            onChange={this.onChangeParam}
            id={"processNm"}
          />
        </Col>
      </Row>
    );
  }

  onValidationCheck() {
    if (StringUtils.isEmpty(this.state.option)) {
      return Message.alert("반복 조건을 입력해주세요.", Enums.MessageType.WARN);
    } else {
      return true;
    }
  }

  /**
   * Entity field에 value 항목의 값 입력 부분
   * popup 오픈
   * @param {*} e
   */
  onOpenValuePopup = (e, idx) => {
    const callbackFnc = (value) => {
      this.setState(
        produce(this.state, (draft) => {
          draft.option = value;
        })
      );
      Popup.close();
    };

    Popup.open(
      <ProcessDataBindingPopup
        workflow={this.props.workflow}
        nodes={this.props.nodes}
        edges={this.props.edges}
        callbackFnc={callbackFnc}
        compId={this.props.compId}
        getAccessibleEntityList={this.getAccessibleEntityList}
      />,
      {
        style: { content: { width: "800px" } },
      }
    );
  };

  callbackEntityList(entity) {
    this.setState(
      produce(this.state, (draft) => {
        // draft.iteratorVariable = entity.entityVariable;
        // draft.iteratorNm = entity.entityNm;
        draft.option = entity.entityVariable;
        draft.editorAttr.referenceCompId = entity.compId;
        if (entity.tableNm) {
          draft.editorAttr.tableNm = entity.tableNm;
        }
      })
    );
  }

  callbackVariableEntityList(entity) {
    this.setState(
      produce(this.state, (draft) => {
        draft.iteratorVariable = entity.entityVariable;
        draft.iteratorNm = entity.entityNm;
      })
    );
  }

  callbackDataBinding(e, idx, value) {
    console.log(value);
  }

  renderBody() {
    return (
      <>
        <Row className="mb-3">
          <Col xs={2} className="col-label">
            <Form.Label className="required">Iterator 조건</Form.Label>
          </Col>
          <Col xs={10}>
            <InputGroup>
              <Form.Control
                id={"option"}
                value={this.state.option}
                onChange={(e) => {
                  if (
                    !StringUtils.isEmpty(this.state.editorAttr.referenceCompId)
                  ) {
                    this.setState(
                      produce(this.state, (draft) => {
                        draft.option = e.target.value;
                        draft.editorAttr.referenceCompId = "";
                      })
                    );
                  } else {
                    this.setState(
                      produce(this.state, (draft) => {
                        draft.option = e.target.value;
                      })
                    );
                  }
                }}
                placeholder="엔티티 선택"
              />
              <Button
                variant="outline-secondary"
                size={"sm"}
                onClick={this.onOpenEntityList}
                // onClick={this.onOpenValuePopup}
                name={"option"}
              >
                <AiOutlineEllipsis />
              </Button>
              <Button
                variant="outline-secondary"
                size={"sm"}
                onClick={(e) => {
                  Popup.open(<IteratorOptionDescPopup />, {
                    style: { content: { width: "550px" } },
                  });
                }}
              >
                <AiOutlineQuestionCircle />
              </Button>
            </InputGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={2} className="col-label">
            <Form.Label className="required">Iterator 변수</Form.Label>
          </Col>
          <Col xs={5}>
            <InputGroup>
              <Form.Control
                id={"iteratorVariable"}
                value={this.state.iteratorVariable}
                onChange={this.onChangeParam}
              />
              <Button
                variant="outline-secondary"
                size={"sm"}
                onClick={(e) =>
                  this.onOpenEntityList(e, "iteratorVariable", {
                    callback: this.callbackVariableEntityList,
                  })
                }
                name={"iteratorVariable"}
              >
                <AiOutlineEllipsis />
              </Button>
            </InputGroup>
          </Col>
          <Col xs={5}>
            <Form.Control
              id={"iteratorNm"}
              value={this.state.iteratorNm}
              onChange={this.onChangeParam}
              placeholder=""
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={2} />
          <Col style={{ color: "#198754" }}>
            {`- 반복 index 지역변수 추가 (변수명 : @@+Iterator 변수명+index) ==>
              e.g., @@salesIndex`}
          </Col>
        </Row>
      </>
    );
  }
}

export default IteratorPopup;
