import React, { Component } from "react";
import BuilderHeader from "../BuilderHeader";
import BuilderToolbarButton from "../BuilderToolbarButton";
import { ImFileEmpty } from "react-icons/im";
import Popup from "components/common/Popup";
import NewErdPopup from "page/popup/erd/NewErdPopup";
import { shortKeyBind } from "components/setting/section/shortKey/ShortKeyConfig";
import { Enums } from "../BuilderEnum";
import { connect } from "react-redux";
import { createErd, editErd, updateErd } from "./reducers/ErdAction";
import ErdSavePopup from "page/popup/erd/ErdSavePopup";
import ErdService from "services/erd/ErdService";
import Message from "components/common/Message";
import ArrayUtils from "components/common/utils/ArrayUtils";

class ErdBuilderHeader extends BuilderHeader {
  constructor(props) {
    super(props);
    this.openNew = this.openNew.bind(this);
    this.openEdit = this.openEdit.bind(this);
    this.state = {
      newWorkflowDropdown: false,
      saveDropdown: false,
      exportDropdown: false,
    };
  }

  openNew = () => {
    const callback = (erdData) => {
      this.props.createErd(erdData);
    };
    Popup.open(
      <NewErdPopup callback={callback} erdInfo={this.props.output} />,
      {
        style: { content: { width: "550px" } },
      }
    );
  };

  openSave = () => {
    const callback = (erdData, cb) => {
      let isDupColumn = false;
      let tableNm = "";
      let columnNm = "";
      let areaNm = "";
      const areas = Object.keys(this.props.output.areas);
      //테이블 영역 순회
      for (const area of areas) {
        const { table: tableList } = this.props.output.areas[area];
        //각 테이블 마다 컬럼명이 겹쳐 있는지 확인
        for (const table of tableList) {
          if (!ArrayUtils.isEmpty(table.propertyValue.columnList)) {
            for (const col of table.propertyValue.columnList) {
              const dupColList = table.propertyValue.columnList.filter(
                (c) => c.physicalName === col.physicalName
              );
              if (dupColList > 1) {
                isDupColumn = true;
                columnNm = dupColList[0].physicalName;
                break;
              }
            }
          }
          if (isDupColumn) {
            tableNm = table.propertyValue.physicalTableNm;
            break;
          }
        }
        if (isDupColumn) {
          areaNm = this.props.output.areas[area].name;
          break;
        }
      }
      if (isDupColumn) {
        cb();
        return Message.alert(
          `ERD ${areaNm} 에서 ${tableNm}의 ${columnNm}컬럼이 중복되었습니다.`,
          Enums.MessageType.WARN
        );
      }

      const body = {
        ...this.props.workspace,
        ...erdData,
        erdContent: JSON.stringify(this.props.output),
      };

      ErdService.saveErd(
        body,
        (res) => {
          Popup.close();
          Message.alert("저장 되었습니다.", Enums.MessageType.SUCCESS);
          ErdService.localStorageSave(res.data);
          cb();
        },
        cb
      );
    };

    Popup.open(
      <ErdSavePopup
        callback={callback}
        workspace={this.props.workspace}
        erdInfo={this.props.erdInfo}
      />,
      {
        style: { content: { width: "550px" } },
      }
    );
  };

  mapKeyToHeader = (event) => {
    shortKeyBind(event, Enums.ShortKeyDefine.NEW_TEMP, this.openNew);
    shortKeyBind(event, Enums.ShortKeyDefine.SAVE, this.openSave);
  };

  openEdit = (event) => {
    const callback = (erdData) => {
      this.props.editErd(erdData);
    };

    Popup.open(
      <NewErdPopup
        erdInfo={this.props.erdInfo}
        areas={this.props.output.areas}
        callback={callback}
      />,
      {
        style: { content: { width: "550px" } },
      }
    );
  };

  renderHeader() {
    const newDropdownEvent = this.setDropdownEvent(
      "newWorkflowDropdown",
      "empty"
    );
    const saveDropdownEvent = this.setDropdownEvent("saveDropdown", "edit");
    return (
      <>
        <li>
          <BuilderToolbarButton
            icon={<ImFileEmpty size={18} />}
            text=" 신규 "
            caret={true}
            buttonId="empty"
            onClick={newDropdownEvent}
            style={this.toolbarStyle}
            iconOnly={this.state.mini}
            tooltipTitle={"신규 "}
          />
          {this.state.newWorkflowDropdown ? (
            <div
              className="toolbar-dropdown"
              ref={(element) => {
                this.dropdownMenu = element;
              }}
            >
              <ul>
                <li
                  className="dropdown-item"
                  role="button"
                  onClick={this.openNew}
                  style={{ width: "250px" }}
                >
                  새로운 ERD 생성
                  <span>Alt + N</span>
                </li>
              </ul>
            </div>
          ) : null}
        </li>
        <li>
          <BuilderToolbarButton
            style={this.toolbarStyle}
            buttonId="edit"
            onClick={saveDropdownEvent}
            iconOnly={this.state.mini}
            tooltipTitle={"편집"}
          />
          {this.state.saveDropdown ? (
            <div
              className="toolbar-dropdown"
              ref={(element) => {
                this.dropdownMenu = element;
              }}
            >
              <ul>
                <li
                  className="dropdown-item"
                  role="button"
                  onClick={this.openEdit}
                >
                  ERD 편집
                </li>
                <li
                  className="dropdown-item"
                  role="button"
                  onClick={this.openSave}
                  style={{ width: "200px" }}
                >
                  저장
                  <span>Alt + S</span>
                </li>
                <li
                  className="dropdown-item"
                  role="button"
                  onClick={(e) => this.openSave(e, true)}
                  style={{ width: "200px" }}
                >
                  다른 이름으로 저장
                </li>
              </ul>
            </div>
          ) : null}
        </li>
        {/* <li>
          <BuilderToolbarButton
            style={this.toolbarStyle}
            buttonId="export"
            onClick={exportDropdownEvent}
            iconOnly={this.state.mini}
            tooltipTitle={"내보내기"}
          />
          {this.state.exportDropdown ? (
            <div
              className="toolbar-dropdown"
              ref={(element) => {
                this.dropdownMenu = element;
              }}
            >
              <ul>
                <li
                  className="dropdown-item"
                  role="button"
                  onClick={this.openSave}
                  style={{ width: "200px" }}
                >
                  저장
                  <span>Alt + S</span>
                </li>
                <li
                  className="dropdown-item"
                  role="button"
                  onClick={(e) => this.openSave(e, true)}
                  style={{ width: "200px" }}
                >
                  다른 이름으로 저장
                </li>
              </ul>
            </div>
          ) : null}
        </li> */}
      </>
    );
  }
}

export default connect(
  (state) => {
    return {
      output: state.erd.output,
      erdInfo: state.erd.erdInfo,
      workspace: state.workspace,
      erd: state.erd,
    };
  },
  (dispatch) => {
    return {
      createErd: (data) => dispatch(createErd(data)),
      editErd: (data) => dispatch(editErd(data)),
    };
  }
)(ErdBuilderHeader);
