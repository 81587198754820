import { Enums } from "components/builder/BuilderEnum";
import UTextarea from "components/common/element/UTextarea";
import Message from "components/common/Message";
import Modal from "components/common/modal/UModal";
import UModalTemplate from "components/common/modal/UModalTemplate";
import ObjectUtils from "components/common/utils/ObjectUtils";
import StringUtils from "components/common/utils/StringUtils";
import produce from "immer";
import { useEffect, useState } from "react";
import { Col, Form, FormGroup, Row } from "react-bootstrap";

function ServiceSavePopup({
  saveAs,
  workflow,
  callbackFnc,
  workspace,
  ...props
}) {
  const [serviceInfo, setServiceInfo] = useState({
    description: "",
    serviceId: "",
    serviceName: "",
    serviceType: "S",
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!ObjectUtils.isEmpty(workflow.serviceInfo)) {
      setServiceInfo({
        ...workflow.serviceInfo,
        serviceId: saveAs ? "" : workflow.serviceInfo.serviceId,
        serviceUid: saveAs ? null : workflow.serviceInfo.serviceUid,
        saveAs: saveAs ? "Y" : "N",
      });
    }
  }, []);

  const onChangeProperty = (e) => {
    setServiceInfo({
      ...serviceInfo,
      [e.target.id]: String(e.target.value),
    });
  };

  const onSaveService = () => {
    if (StringUtils.isEmpty(serviceInfo.serviceId)) {
      return Message.alert("서비스 ID를 입력해주세요.", Enums.MessageType.INFO);
    }
    if (serviceInfo.serviceId.length > 100) {
      return Message.alert(
        "서비스 ID는 100자 이내로 입력해주세요.",
        Enums.MessageType.INFO
      );
    }
    if (StringUtils.isEmpty(serviceInfo.serviceName)) {
      return Message.alert("서비스 명을 입력해주세요.", Enums.MessageType.INFO);
    }
    if (StringUtils.isEmpty(serviceInfo.serviceType)) {
      return Message.alert(
        "서비스 유형을 입력해주세요.",
        Enums.MessageType.INFO
      );
    }

    //종료에 연결된 부분이 있는지 확인 후 없으면 리턴
    const endProcess = workflow.output.service.child.process.find(
      (p) =>
        StringUtils.equalsIgnoreCase(
          p.type,
          Enums.WorkflowNodeType.PROCESS_EDGE
        ) && StringUtils.equalsIgnoreCase(p.processType, "EndProcess")
    );
    const isConnectedToEnd = workflow.output.service.child.connector.find(
      (c) => c.processTo === endProcess.compId
    );

    const onSave = () => {
      setIsLoading(true);
      //아웃풋 내의 id, name, type 재조정
      const newOutput = produce(workflow.output, (draft) => {
        draft.service.compId = StringUtils.getUuid();
        draft.service.serviceId = serviceInfo.serviceId.trim();
        draft.service.serviceName = serviceInfo.serviceName.trim();
        draft.service.serviceType = serviceInfo.serviceType;
      });
      const body = {
        ...serviceInfo,
        serviceContent: newOutput,
        serviceComment: workflow.serviceComment,
        serviceMemo: workflow.serviceMemo,
        useYn: "Y",
        commitComment: "",
        releaseCommentYn: "N",
        deployDate: null,
        ...workspace,
      };

      if (callbackFnc) {
        callbackFnc(body, () => setIsLoading(false));
      }
    };

    if (!isConnectedToEnd) {
      Message.confirm(
        "종료 노드에 연결된 항목이 없습니다. \n 이대로 저장하시겠습니까?",
        () => {
          onSave();
        }
      );
    } else {
      onSave();
    }
  };

  return (
    <Modal>
      <Modal.Header title="서비스 저장" />
      <Modal.Body>
        <UModalTemplate>
          <Row>
            <Form.Group className="mb-3" as={Col}>
              <Form.Label>Application</Form.Label>
              <Form.Control disabled defaultValue={workspace.appNm} />
            </Form.Group>
            <Form.Group className="mb-3" as={Col}>
              <Form.Label>모듈</Form.Label>
              <Form.Control disabled defaultValue={workspace.moduleNm} />
            </Form.Group>
            <Form.Group className="mb-3" as={Col}>
              <Form.Label>버전</Form.Label>
              <Form.Control disabled defaultValue={workspace.version} />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} className="mb-3">
              <Form.Label>테넌트 ID</Form.Label>
              <Form.Control disabled defaultValue={workspace.tenantId} />
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>회사 코드</Form.Label>
              <Form.Control disabled defaultValue={workspace.coCd} />
            </Form.Group>
          </Row>
          <FormGroup>
            <Form.Label className="required">서비스 ID</Form.Label>
            <Form.Control
              className="mb-3"
              onChange={onChangeProperty}
              id={"serviceId"}
              readOnly={
                !(saveAs || StringUtils.isEmpty(serviceInfo.serviceUid))
              }
              value={serviceInfo.serviceId}
              placeholder="서비스 ID를 입력해주세요"
              maxLength={100}
            />
            <Form.Label className="required">서비스 명</Form.Label>
            <Form.Control
              className="mb-3"
              onChange={onChangeProperty}
              id={"serviceName"}
              value={serviceInfo.serviceName}
              placeholder="서비스 명을 입력해주세요"
              maxLength={100}
            />
            <Form.Label className="required">서비스 타입</Form.Label>
            <Form.Select
              className="mb-3"
              onChange={onChangeProperty}
              id={"serviceType"}
              value={serviceInfo.serviceType}
            >
              <option value="">선택</option>
              <option value="S">비지니스 플로우</option>
            </Form.Select>
            <Form.Label>서비스 설명</Form.Label>
            <UTextarea
              popTitle="서비스 설명"
              textareaId="description"
              onChange={onChangeProperty}
              defaultValue={serviceInfo.description}
              codeBox={false}
              rows={10}
            />
          </FormGroup>
        </UModalTemplate>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.ProgressButton
          doing={isLoading}
          doingText="저장 중"
          onClick={onSaveService}
        >
          저장
        </Modal.Footer.ProgressButton>
      </Modal.Footer>
    </Modal>
  );
}

export default ServiceSavePopup;
