import { json } from "@codemirror/lang-json";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  ButtonGroup,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import CodeMirror from "@uiw/react-codemirror";
import * as Enums from "components/builder/BuilderEnum";
import {
  updateEventOutput,
  updateEventWorkspace,
} from "components/builder/eventhandler/reducer/EventHandlerAction";
import {
  updateInformation,
  updateOutput,
} from "components/builder/ui/reducers/UIBuilderAction";
import Message from "components/common/Message";
import CommonUtils, { StringUtils } from "components/common/utils/CommonUtils";
import User from "components/common/utils/UserUtils";
import "moment/locale/ko";
import { useEffect, useState } from "react";
import { HiOutlineClipboardCopy } from "react-icons/hi";
import { MdExpandMore } from "react-icons/md";
import { RiFileCopyLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ProgramService from "services/ui/ProgramService";
import styled from "styled-components";

const AccordionHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 50px;
  gap: 5px;
`;
const AccordionInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
`;
const AccordionContentsWrapper = styled.div`
  width: 100%;
  max-height: 600px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
const EditorViewer = styled.div`
  max-height: 500px;
  width: 73vw;
  overflow-y: auto;
`;
const ControllButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

/**
 * 프로그램 이력 상세에서 각 컨텐츠 아코디언
 * 2024.01.02 : kiyoung.park 확장 여부로 각 컨텐츠 불러오는 방식으로 교체
 *          사유 : 이력 목록 호출시 데이터 과부하로 느려짐
 * @param {*} param0
 * @returns
 */
function ProgramHistoryAccordionContents({ program, onCompareHistory }) {
  const {
    commitComment,
    insertDt,
    templateShareYn,
    updtUserId,
    // programContent,
    programHistoryId,
    appApplyType,
  } = program;
  const { tenantMstId } = useSelector((state) => state.workspace);
  const [isExpanded, setIsExpanded] = useState(false);
  const [programContent, setProgramContent] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (StringUtils.isEmpty(programContent) && isExpanded) getProgramDetail();
  }, [isExpanded]);

  const getProgramDetail = () => {
    ProgramService.getProgramHistoryDetail({ programHistoryId }, (res) => {
      setProgramContent(res.data.programContent);
    });
  };

  /**
   * 열기 === 해당 파일 불러오기
   */
  const onCopyCodeToClipBoard = () => {
    Message.confirm(
      "해당 커밋을 불러오시겠습니까? 진행 중인 프로젝트가 있다면 사라지게 됩니다.",
      () => {
        let _new = { ...program };
        if (programContent !== "")
          _new.programContent = JSON.parse(programContent);
        dispatch(updateEventWorkspace({}));
        dispatch(updateEventOutput(""));
        dispatch(updateOutput(_new.programContent));
        dispatch(updateInformation(_new));
        navigate(Enums.BuilderPath.UI.MAIN + "/" + Enums.BuilderPath.UI.EDITOR);
      }
    );
  };

  /**
   * 복원 ==> 해당 파일을 최신버전으로 저장
   */
  const onCopyCodeToCurrentEditor = async () => {
    Message.confirm("해당 버전을 최신 버전으로 덮어 씌우시겠습니까?", () => {
      ProgramService.recoverProgram({ ...program }, (res) => {
        const body = {
          ...res.data,
          ...program,
          programId: program.programId,
          programContent,
          programType:
            JSON.parse(programContent).page.propertyValue.programType || "M",
          appApplyType: "N",
          useYn: "Y",
          committer: User.getMastRecId(),
          commitComment: `${CommonUtils.getDate(
            program.insertDt,
            "dateTime"
          )} 항목 복원`,
          tenantMstId,
        };
        ProgramService.saveProgram(body, () => {
          Message.alert("복원되었습니다.", Enums.MessageType.SUCCESS);
        });
      });
    });
  };

  return (
    <Accordion
      onChange={(e, expanded) => {
        setIsExpanded(expanded);
      }}
    >
      <AccordionSummary
        expandIcon={<MdExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <AccordionHeader>
          <span>{commitComment}</span>
          <AccordionInfo>
            <div>
              {updtUserId} / {CommonUtils.getTZTime(insertDt).fromNow()}에 저장
              되었습니다.
            </div>
            <div>
              <ButtonGroup
                variant="outlined"
                aria-label="outlined button group"
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={templateShareYn === "Y" ? true : false}
                      onClick={(e) => e.stopPropagation()}
                    />
                  }
                  label="템플릿 공유"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={appApplyType === "Y" ? true : false}
                      onClick={(e) => e.stopPropagation()}
                    />
                  }
                  label="앱 반영"
                />
              </ButtonGroup>
            </div>
          </AccordionInfo>
        </AccordionHeader>
      </AccordionSummary>
      <AccordionDetails>
        <AccordionContentsWrapper>
          <ControllButton>
            <div>저장 날짜 : {CommonUtils.getDate(insertDt, "dateTime")}</div>
            <ButtonGroup>
              <Button
                onClick={onCopyCodeToClipBoard}
                style={{ fontSize: "12px" }}
              >
                <RiFileCopyLine fontSize={"15px"} /> 열기
              </Button>
              <Button
                onClick={onCopyCodeToCurrentEditor}
                style={{ fontSize: "12px" }}
              >
                <HiOutlineClipboardCopy fontSize={"15px"} /> 복원
              </Button>
            </ButtonGroup>
          </ControllButton>
          <EditorViewer>
            {!StringUtils.isEmpty(programContent) && (
              <CodeMirror
                value={JSON.stringify(JSON.parse(programContent), null, 2)}
                className="source-container"
                height="500px"
                extensions={[json(true)]}
                autoFocus={false}
                editable={false}
              />
            )}
          </EditorViewer>
        </AccordionContentsWrapper>
      </AccordionDetails>
    </Accordion>
  );
}

export default ProgramHistoryAccordionContents;
