import React, { useCallback, useContext } from "react";
import { BsKey, BsStarFill } from "react-icons/bs";
import { FlowContext } from "../EntityFlowEditor";
import { useDispatch, useSelector } from "react-redux";
import EntityReduxHelper from "../../editor/helper/EntityReduxHelper";
import StringUtils from "components/common/utils/StringUtils";
import Message from "components/common/Message";
import { Enums } from "components/builder/BuilderEnum";
import ReactDOM from "react-dom";
import { Tooltip } from "@mui/material";
import { AiOutlineMinusCircle } from "react-icons/ai";
import { BiRightArrowCircle } from "react-icons/bi";
import { MdDragIndicator } from "react-icons/md";
import produce from "immer";

function EntityNodeField({ _entity, field, editable = true, ...props }) {
  const activedComponent = useSelector((state) => {
    return state.activedENTComponent;
  });
  const output = useSelector((state) => state.outputENT.output);

  const {
    entityRelation: { init: relationInit },
    handleTabSelect,
    eagerEntityIds,
  } = useContext(FlowContext);
  const dispatch = useDispatch();

  /**
   * 현재 선택한 노드인지 판단하는 메서드
   * @returns
   */
  const getActiveClass = () => {
    return StringUtils.equalsIgnoreType(activedComponent?.compId, field.compId);
  };

  /**
   * 필드 선택
   * @param {*} e
   */
  const onClickEntityField = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    relationInit();
    handleTabSelect("Properties");
    EntityReduxHelper.activateComponent(dispatch, field);
  };

  /**
   * 가상 칼럼 삭제
   * @param {*} e
   * @param {*} fieldCompId
   */
  const onDeleteColumn = (e, fieldCompId) => {
    e.stopPropagation();
    e.preventDefault();

    //삭제
    EntityReduxHelper.removeEntityField(dispatch, output, _entity, fieldCompId);
    //activeComp를 바로 앞꺼로 옮기기
  };

  const renderDynamic = (
    <>
      {eagerEntityIds[_entity.physEntityNm] ? (
        <div className="isDraggable">
          <MdDragIndicator />
        </div>
      ) : (
        <div />
      )}

      <div className="arrow">
        {StringUtils.equalsIgnoreCase(field.pkYn, "Y") && (
          <Tooltip title={"Primary key"} placement="top">
            <span>
              <BsKey size={20} color="yellow" />
            </span>
          </Tooltip>
        )}
        {StringUtils.equalsIgnoreCase(field.requiredYn, "Y") &&
          StringUtils.equalsIgnoreCase(field.pkYn, "N") && (
            <Tooltip title={"필수 입력"} placement="top">
              <span>
                <BsStarFill size={20} color="tomato" />
              </span>
            </Tooltip>
          )}
      </div>
      <div className="column-name">
        <div>{field.columnNm}</div>
        <div>
          {field.virtualYn === "Y" && editable && (
            <span
              className="removeBtn"
              onClick={(e) => onDeleteColumn(e, field.compId)}
            >
              <AiOutlineMinusCircle size={20} style={{ cursor: "pointer" }} />
            </span>
          )}
          {/* {field.refEntityId && (
            <BiRightArrowCircle size={20} style={{ color: "red" }} />
          )} */}
        </div>
      </div>
    </>
  );

  const renderService = (
    <>
      <div className="arrow">{field.columnNm}</div>
      <div className="column-name">{field.logFieldNm}</div>
      <div>
        <span
          className="removeBtn"
          onClick={(e) => onDeleteColumn(e, field.compId)}
        >
          <AiOutlineMinusCircle size={20} style={{ cursor: "pointer" }} />
        </span>
      </div>
    </>
  );
  const renderEntity = (
    <>
      <div className="arrow">{field.logFieldNm}</div>
      <div className="column-name">{field.physFieldNm}</div>
    </>
  );

  return (
    <DraggableFieldContents
      field={field}
      className={`nodrag field-row 
      ${
        StringUtils.equalsIgnoreCase("D", output.dataModelType)
          ? "dynamic"
          : StringUtils.equalsIgnoreCase("S", output.dataModelType)
          ? "service"
          : "entity"
      } 
      ${getActiveClass() ? "active" : ""}`}
      onClick={onClickEntityField}
      data-component-id={field.compId}
    >
      {StringUtils.equalsIgnoreCase(output.dataModelType, "D")
        ? renderDynamic
        : StringUtils.equalsIgnoreCase(output.dataModelType, "S")
        ? renderService
        : renderEntity}
    </DraggableFieldContents>
  );
}

export default EntityNodeField;

const DraggableFieldContents = ({ field, children, ...props }) => {
  const dispatch = useDispatch();
  const output = useSelector((state) => state.outputENT.output);

  const FindEntityIdAndNodeIndex = (compId) => {
    const { dataModelEntities } = output;
    const toEntity = dataModelEntities.find(
      (e) => e.dataModelEntityFields.findIndex((f) => f.compId === compId) > -1
    );
    const { compId: entityId, dataModelEntityFields } = toEntity;
    const toField = dataModelEntityFields.find((f) => f.compId === compId);
    const toFieldIndex = dataModelEntityFields.findIndex(
      (f) => f.compId === compId
    );
    return [toEntity, toField, toFieldIndex];
  };

  const moveItem = (from, to) => {
    const _output = { ...output };
    //to의 앞단계로 이동함
    //2. to 위치 위체 추가
    //2-1 to entity 확인,주입 대상 노드, 주입할 인덱스 확인
    const [toEntity, toNode, toNodeIndex] = FindEntityIdAndNodeIndex(to.compId);
    // 포뮬러 입력을 위한 from 엔티티 정보
    const [fromEntity, fromField, fromFieldIndex] = FindEntityIdAndNodeIndex(
      from.compId
    );

    // ※ 같은 엔티티안에서는 복사 하지 않음 -> 위치 이동
    if (
      StringUtils.equalsIgnoreType(
        toEntity.physEntityNm,
        fromEntity.physEntityNm
      )
    ) {
      if (
        StringUtils.equalsIgnoreCase(toEntity.entityType, "TABLE") ||
        StringUtils.equalsIgnoreCase(toEntity.entityType, "VIEW") ||
        StringUtils.equalsIgnoreCase(toEntity.entityType, "SYNONYM")
      ) {
        //같은 엔티티 안에서는 순서 변경
        const newFromEntity = produce(fromEntity, (draft) => {
          const moveTarget = draft.dataModelEntityFields.splice(
            fromFieldIndex,
            1
          );
          draft.dataModelEntityFields.splice(toNodeIndex, 0, moveTarget[0]);
        });
        return EntityReduxHelper.updateEntity(dispatch, _output, newFromEntity);
      } else {
        return Message.alert(
          "프로시져 및 함수형 엔티티는 수정 할 수 없습니다.",
          Enums.MessageType.INFO
        );
      }
    }

    // ※ 대상 엔티티에 EAGER로 연결된 엔티티만 가능
    const acceptIds = toEntity.relation.map((rel) => {
      if (StringUtils.equalsIgnoreCase(rel.fetch, "EAGER")) {
        return rel.targetEntity;
      }
    });
    if (!acceptIds.includes(fromEntity.physEntityNm))
      return Message.alert(
        "Fetch 타입 EAGER로 연결된 엔티티에 복사 할 수 있습니다.",
        Enums.MessageType.INFO
      );

    //가상 칼럼 생성
    const virtualColumn = {
      ...from,
      compId: StringUtils.getUuid(),
      entityId: null,
      entityFieldId: null,
      virtualYn: "Y",
      readonlyYn: "Y",
      insertableYn: "N",
      updatableYn: "N",
      requiredYn: "N",
      pkYn: "N",
      uniqueYn: "N",
      formula: `${fromEntity.physEntityNm}.${from.columnNm}`,
    };
    //인덱스에 추가
    const newDataModelEntityFields = [...toEntity.dataModelEntityFields];
    newDataModelEntityFields.splice(toNodeIndex, 0, virtualColumn);
    // 업데이트
    const updatedTargetEntity = {
      ...toEntity,
      dataModelEntityFields: newDataModelEntityFields,
    };
    EntityReduxHelper.updateEntity(dispatch, _output, updatedTargetEntity);
  };

  const onDragStart = useCallback((event) => {
    if (StringUtils.equalsIgnoreCase(output.dataModelType, "D")) {
      const StringData = JSON.stringify(field);
      event.dataTransfer.setData("application/reactflow", StringData);
      event.dataTransfer.effectAllowed = "move";
    } else {
      event.dataTransfer.effectAllowed = "none";
    }
  }, []);

  const onDragOver = (e) => {
    e.preventDefault();
    if (StringUtils.equalsIgnoreCase(output.dataModelType, "D")) {
      const thisNode = ReactDOM.findDOMNode(e.currentTarget);
      thisNode.style = "border-top : 3px solid lightgray";
    }
  };

  const onDrop = (e) => {
    if (StringUtils.equalsIgnoreCase(output.dataModelType, "D")) {
      const thisNode = ReactDOM.findDOMNode(e.currentTarget);
      thisNode.style = "border-top : 1px solid black";
      const toCompId = thisNode.getAttribute("data-component-id");
      //드랍 가능한 엔티티명 따로 분리
      if (toCompId) {
        const fromData = JSON.parse(
          e.dataTransfer.getData("application/reactflow")
        );
        moveItem(fromData, { compId: toCompId });
      }
    }
  };

  const onDragLeave = (e) => {
    const thisNode = ReactDOM.findDOMNode(e.currentTarget);
    thisNode.style.borderTop = null;
  };

  return (
    <div
      onDragStart={onDragStart}
      draggable
      {...props}
      onDragOver={onDragOver}
      onDrop={onDrop}
      onDragLeave={onDragLeave}
    >
      {children}
    </div>
  );
};
