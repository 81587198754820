import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";

/**
 * ERD에 등록된 테이블들을 한개 배열로 통합해서
 * 리턴시키는 HOOK
 * @returns
 */
function useErdTableListState() {
  const [tableList, setTableList] = useState([]);
  const [areaList, setAreaList] = useState([]);
  const erd = useSelector((state) => state.erd);
  const inDebounce = useRef();

  //뷰포트 기억할때 쓰는 함수
  const debounce = (func, delay) => {
    if (inDebounce.current) clearTimeout(inDebounce.current);
    inDebounce.current = setTimeout(() => func(), delay);
  };

  useEffect(() => {
    assembleTableList();
  }, [erd.output.areas]);

  /**
   * ERD 정보가 바뀔때 마다 각 영역별 테이블 정보를 전체 state로 지정함
   */
  const assembleTableList = () => {
    debounce(() => {
      const areas = erd.output.areas;
      let aList = [];
      let tList = [];
      for (const areaId in areas) {
        const { table, compId, name, type, alias } = areas[areaId];
        for (const t of table) {
          tList.push({
            ...t,
            area: areaId,
          });
        }
        aList.push({
          compId,
          name,
          type,
          alias,
        });
      }
      setTableList(tList);
      setAreaList(aList);
    }, 250);
  };

  return [tableList, areaList];
}

export default useErdTableListState;
