import Api from "components/common/Api";

class BoardService {
  static getBoardList(data, callbackFnc) {
    Api.get("/prototype/board", data, callbackFnc);
  }
  static createBoard(data, callbackFnc) {
    Api.post("/prototype/board", data, callbackFnc);
  }
}
export default BoardService;
