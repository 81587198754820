import { Tooltip } from "@mui/material";
import JsonUtils from "components/common/utils/JsonUtils";
import ObjectUtils from "components/common/utils/ObjectUtils";
import StringUtils from "components/common/utils/StringUtils";
import produce from "immer";
import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { Button, ButtonGroup, Form, ToggleButton } from "react-bootstrap";
import { BiBold, BiCut, BiFontColor, BiPaste } from "react-icons/bi";
import {
  FaAlignCenter,
  FaAlignJustify,
  FaAlignLeft,
  FaAlignRight,
  FaItalic,
  FaRedo,
  FaStrikethrough,
  FaUnderline,
  FaUndo,
} from "react-icons/fa";
import { RiFontSize } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { Enums } from "components/builder/BuilderEnum";
import { updateActivateProps } from "components/builder/ui/reducers/UIBuilderAction";
import * as Event from "components/builder/ui/editor/handler/UIEditorEventHandler";
import UIReduxHelper from "components/builder/ui/editor/helper/UIReduxHelper";
import { MdOutlineFormatColorFill } from "react-icons/md";
import styled from "styled-components";
import ColorPicker from "components/builder/ui/ColorPicker";
/**
 * 키보드 버튼 이벤트 관련한 컴포넌트
 * 실행취소, 다시실행 버튼
 * 잘라내기, 붙여넣기
 * @returns
 */
function CommandButtonComponent(props) {
  const { output } = useSelector((state) => state.outputUI);
  const { component } = useSelector((state) => state.activedUIComponent);
  const [propertyValue, setPropertyValue] = useState({});
  const dispatch = useDispatch();
  useEffect(() => {
    if (!ObjectUtils.isEmpty(component)) {
      setPropertyValue(component.propertyValue || {});
    }
  }, [component, output]);

  const isStyleUseComponent = () => {
    //스타일 사용하는 컴포넌트인지 판단
    switch (component.type || component.componentType) {
      case Enums.ComponentType.PAGE:
      case Enums.ComponentType.GRID:
      case Enums.ComponentType.GRID_COLUMN:
      case Enums.ComponentType.GRID_HEADER:
      case Enums.ComponentType.GRID_CELL:
        return false;
      default:
        return true;
    }
  };
  /**
   * text 사용하는 컴포넌트 인지 판단
   * 허용 스타일 Align & FontStyle, text-decoration
   * @returns
   */
  const isTextUseComponent = () => {
    return true;
    // switch (component.type || component.componentType) {
    //   case Enums.ComponentType.COLUMN:
    //   case Enums.ComponentType.ROW:
    //   case Enums.ComponentType.FORM:
    //   case Enums.ComponentType.CONTAINER:
    //     return false;
    //   default:
    //     return true;
    // }
  };
  /**
   * on Change style
   * @param {Event} event
   */
  const onChangeStyle = (event) => {
    const newStyle = {
      ...component.propertyValue.style,
      [event.target.id]: event.target.value,
    };
    if (StringUtils.isEmpty(event.target.value)) {
      delete newStyle[event.target.id];
    }
    onChangePropertyValue("style", newStyle);
  };

  /**
   * 정렬 변경시
   * - justifyContent 와 textAlign을 동시에 적용한다.
   * @param {Event} event
   */
  const onChangeAlignment = (value) => {
    const newStyle = {
      ...propertyValue.style,
      justifyContent: value,
    };
    if (component.componentClass.indexOf("form/") > -1) {
      newStyle.textAlign = value;
    }
    onChangePropertyValue("style", newStyle);
  };

  const onClickToggleButton = (key, btnValue) => {
    let value;
    if (StringUtils.equalsIgnoreType(getDefaultValue(key), btnValue)) {
      value = "";
    } else {
      value = btnValue;
    }
    onChangeStyle({ target: { id: key, value } });
  };

  const onChangePropertyValue = (propId, propValue) => {
    onChangePropertyValues({ [propId]: propValue });
  };

  const onChangePropertyValues = (propertyValues) => {
    let isChanged = false;
    const newPropertyValue = produce(propertyValue, (draft) => {
      Object.keys(propertyValues).forEach(function (key) {
        //변경여부를 판단하기위해
        if (StringUtils.defaultString(draft[key]) !== propertyValues[key]) {
          if (StringUtils.isEmpty(propertyValues[key])) {
            delete draft[key];
          } else {
            draft[key] = propertyValues[key];
          }
          isChanged = true;
        }
      });
    });
    if (isChanged === true) {
      updatePropertyValue(newPropertyValue);
    }
  };

  const updatePropertyValue = (newPropertyValue) => {
    setPropertyValue(newPropertyValue);
    updateProperty(newPropertyValue);
  };

  const updateProperty = (propertyValue) => {
    const compId = component.compId;
    const changedOutput = produce(output, (draft) => {
      const targetNode = JsonUtils.overrideNode(
        draft,
        "compId",
        compId,
        "propertyValue",
        propertyValue
      );
      if (ObjectUtils.isEmpty(targetNode)) {
        console.log("Could not find target node !!!!");
        return false;
      }
    });
    UIReduxHelper.updateOutput(changedOutput, dispatch);
    dispatch(
      updateActivateProps({
        ...component.propertyValue,
        ...propertyValue,
      })
    );
  };

  const getDefaultValue = (key, value) => {
    return JsonUtils.defaultString(propertyValue?.style, key, value);
  };

  return (
    <>
      {isStyleUseComponent() && (
        <>
          {isTextUseComponent() && (
            <>
              <ButtonGroup>
                <Tooltip title="글자 크기">
                  <div className="tool-tip-icon left" position={"left"}>
                    <RiFontSize size={14} />
                  </div>
                </Tooltip>
                <select
                  id="fontSize"
                  value={getDefaultValue("fontSize", "13px")}
                  onChange={onChangeStyle}
                >
                  <option value={"8px"}>8</option>
                  <option value={"9px"}>9</option>
                  <option value={"10px"}>10</option>
                  <option value={"11px"}>11</option>
                  <option value={"12px"}>12</option>
                  <option value={"13px"}>13</option>
                  <option value={"14px"}>14</option>
                  <option value={"16px"}>16</option>
                  <option value={"18px"}>18</option>
                  <option value={"20px"}>20</option>
                  <option value={"22px"}>22</option>
                  <option value={"24px"}>24</option>
                  <option value={"28px"}>28</option>
                  <option value={"36px"}>36</option>
                  <option value={"48px"}>48</option>
                </select>
                <Tooltip title="글자 굵기">
                  <div className="tool-tip-icon">
                    <BiBold size={14} />
                  </div>
                </Tooltip>
                <select
                  className="right"
                  id="fontWeight"
                  onChange={onChangeStyle}
                  value={getDefaultValue("fontWeight", "none")}
                >
                  <option value={"none"}>None</option>
                  <option value={"bold"}>Bold</option>
                  <option value={"100"}>100</option>
                  <option value={"100"}>100</option>
                  <option value={"200"}>200</option>
                  <option value={"300"}>300</option>
                  <option value={"400"}>400</option>
                  <option value={"500"}>500</option>
                  <option value={"600"}>600</option>
                  <option value={"700"}>700</option>
                  <option value={"800"}>800</option>
                  <option value={"900"}>900</option>
                </select>
              </ButtonGroup>
              <ButtonGroup>
                <AlignButton
                  value={getDefaultValue("justifyContent", "justify")}
                  Icon={FaAlignLeft}
                  onClick={onChangeAlignment}
                  direction={"left"}
                />
                <AlignButton
                  value={getDefaultValue("justifyContent", "justify")}
                  Icon={FaAlignCenter}
                  onClick={onChangeAlignment}
                  direction={"center"}
                />
                <AlignButton
                  value={getDefaultValue("justifyContent", "justify")}
                  Icon={FaAlignRight}
                  onClick={onChangeAlignment}
                  direction={"right"}
                />
                <AlignButton
                  value={getDefaultValue("justifyContent", "justify")}
                  Icon={FaAlignJustify}
                  onClick={onChangeAlignment}
                  direction={"justify"}
                />
              </ButtonGroup>
              <ButtonGroup>
                <Tooltip title="글자 기울기">
                  <CToggleButton
                    id="fontStyle"
                    type="checkbox"
                    variant="outline-dark"
                    size="sm"
                    checked={StringUtils.equalsIgnoreType(
                      getDefaultValue("fontStyle"),
                      "italic"
                    )}
                    value="italic"
                    onChange={() => onClickToggleButton("fontStyle", "italic")}
                  >
                    <FaItalic size={14} />
                  </CToggleButton>
                </Tooltip>
                <Tooltip title="밑줄">
                  <CToggleButton
                    id={`textDecoration-0`}
                    name="textDecoration"
                    type="checkbox"
                    variant="outline-dark"
                    size="sm"
                    value={"underline"}
                    checked={StringUtils.equalsIgnoreType(
                      getDefaultValue("textDecoration"),
                      "underline"
                    )}
                    onChange={() =>
                      onClickToggleButton("textDecoration", "underline")
                    }
                  >
                    <FaUnderline size={14} />
                  </CToggleButton>
                </Tooltip>
                <Tooltip title="취소선">
                  <CToggleButton
                    id={`textDecoration-1`}
                    name="textDecoration"
                    type="checkbox"
                    variant="outline-dark"
                    size="sm"
                    value={"line-through"}
                    checked={StringUtils.equalsIgnoreType(
                      getDefaultValue("textDecoration"),
                      "line-through"
                    )}
                    onChange={() =>
                      onClickToggleButton("textDecoration", "line-through")
                    }
                  >
                    <FaStrikethrough size={14} />
                  </CToggleButton>
                </Tooltip>
              </ButtonGroup>
            </>
          )}

          <ButtonGroup>
            <Tooltip title="글자 색깔">
              <div className="tool-tip-icon color left">
                <BiFontColor size={15} />
                <div className="edit-color-wrapper">
                  <ColorPicker
                    hideTextfield
                    defaultValue={getDefaultValue("color", "")}
                    id={"color"}
                    onBlur={onChangeStyle}
                  />
                </div>
              </div>
            </Tooltip>
            <Tooltip title="배경 색깔">
              <div className="tool-tip-icon right color">
                <MdOutlineFormatColorFill size={14} />
                <div className="edit-color-wrapper">
                  <ColorPicker
                    hideTextfield
                    defaultValue={getDefaultValue("backgroundColor", "")}
                    id={"backgroundColor"}
                    onBlur={onChangeStyle}
                  />
                </div>
              </div>
            </Tooltip>
          </ButtonGroup>
        </>
      )}
    </>
  );
}

export default CommandButtonComponent;

const CToggleButton = styled(ToggleButton)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AlignButton = ({ onClick, value, Icon, direction, ...props }) => {
  const onChangeAlignment = (e) => {
    if (onClick) {
      onClick(direction);
    }
  };
  const tooltipTitle = StringUtils.equalsIgnoreCase(direction, "left")
    ? "왼쪽"
    : StringUtils.equalsIgnoreCase(direction, "right")
    ? "오른쪽"
    : StringUtils.equalsIgnoreCase(direction, "center")
    ? "가운데"
    : "양쪽";

  return (
    <Tooltip title={`${tooltipTitle} 맞춤`}>
      <CToggleButton
        variant={"outline-dark"}
        size="sm"
        type="checkbox"
        name="justifyContent"
        value={direction}
        onClick={onChangeAlignment}
        checked={StringUtils.equalsIgnoreCase(value, direction)}
      >
        <Icon size={14} />
      </CToggleButton>
    </Tooltip>
  );
};
