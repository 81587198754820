import React from "react";
import BuilderSidebarContextMenu from "../contextMenu/BuilderSidebarContextMenu";
import {
  AiFillEdit,
  AiOutlineFunction,
  AiOutlineUnorderedList,
} from "react-icons/ai";
import { Enums } from "../BuilderEnum";

class EventHandlerMenu extends BuilderSidebarContextMenu {
  constructor(props) {
    super(props);
    this.state = {
      searchResultList: [],
      recentWorkList: [],
      dataList: [],
      dataLoading: false,
      searchTerm: "",
    };
    this.goToEventPage = this.goToEventPage.bind(this);
  }

  componentDidMount() {
    this.getRecentMenuList(Enums.LocalStorageName.EVENT_HISTORY);
  }

  onClickNewService() {
    this.props.createNewService(null, this.props.eventHandler);
    this.goToEventPage(Enums.BuilderPath.EVENT_HANDLER.BUILDER);
  }

  goToEventPage = (url) => {
    this.goToPage(Enums.BuilderPath.EVENT_HANDLER.MAIN + "/" + url);
  };

  renderMenuContents() {
    return (
      <div>
        {this.renderTitle(AiOutlineFunction, "Event Builder")}
        <div className="menu-list">
          {this.renderContextMenu(AiFillEdit, "이벤트 Editor", (e) =>
            this.goToEventPage(Enums.BuilderPath.EVENT_HANDLER.BUILDER)
          )}
          {this.renderContextMenu(AiOutlineUnorderedList, "이벤트 목록", (e) =>
            this.goToEventPage(Enums.BuilderPath.EVENT_HANDLER.LIST)
          )}
        </div>
        <hr />
      </div>
    );
  }
}

EventHandlerMenu.propTypes = {};

export default EventHandlerMenu;
