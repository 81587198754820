import Blockly from "blockly";
import { inlineInputWidth } from "../blockRender/BlockZelos";

/**
 * Block connection line 생성
 * https://groups.google.com/g/blockly/c/i-vV5hDd8Uk
 * InsertionMarketManager link : https://github.com/microsoft/pxt-blockly/blob/develop/core/insertion_marker_manager.js
 */

/**
 * Get any renderer specific CSS to inject when the renderer is initialized.
 *
 * @param selector CSS selector to use.
 * @returns Array of CSS strings.
 */
Blockly.zelos.ConstantProvider.prototype.getCSS_ = function (selector) {
  const css = [
    /* eslint-disable indent */
    // Text.
    `${selector} .blocklyText,`,
    `${selector} .blocklyFlyoutLabelText {`,
    `font: ${this.FIELD_TEXT_FONTWEIGHT} ${this.FIELD_TEXT_FONTSIZE}` +
      `pt ${this.FIELD_TEXT_FONTFAMILY};`,
    `}`,

    // Fields.
    `${selector} .blocklyText {`,
    `fill: #fff;`,
    `}`,
    `${selector} .blocklyNonEditableText>rect:not(.blocklyDropdownRect),`,
    `${selector} .blocklyEditableText>rect:not(.blocklyDropdownRect) {`,
    `fill: ${this.FIELD_BORDER_RECT_COLOUR};`,
    `}`,
    `${selector} .blocklyNonEditableText>text,`,
    `${selector} .blocklyEditableText>text,`,
    `${selector} .blocklyNonEditableText>g>text,`,
    `${selector} .blocklyEditableText>g>text {`,
    `fill: #575E75;`,
    `}`,

    // Flyout labels.
    `${selector} .blocklyFlyoutLabelText {`,
    `fill: #575E75;`,
    `}`,

    // Bubbles.
    `${selector} .blocklyText.blocklyBubbleText {`,
    `fill: #575E75;`,
    `}`,

    // Editable field hover.
    `${selector} .blocklyDraggable:not(.blocklyDisabled)`,
    ` .blocklyEditableText:not(.editing):hover>rect,`,
    `${selector} .blocklyDraggable:not(.blocklyDisabled)`,
    ` .blocklyEditableText:not(.editing):hover>.blocklyPath {`,
    `stroke: #fff;`,
    `stroke-width: 2;`,
    `}`,

    // Text field input.
    `${selector} .blocklyHtmlInput {`,
    `font-family: ${this.FIELD_TEXT_FONTFAMILY};`,
    `font-weight: ${this.FIELD_TEXT_FONTWEIGHT};`,
    `color: #575E75;`,
    `}`,

    // Dropdown field.
    `${selector} .blocklyDropdownText {`,
    `fill: #fff !important;`,
    `}`,

    // Widget and Dropdown Div
    `${selector}.blocklyWidgetDiv .goog-menuitem,`,
    `${selector}.blocklyDropDownDiv .goog-menuitem {`,
    `font-family: ${this.FIELD_TEXT_FONTFAMILY};`,
    `}`,
    `${selector}.blocklyDropDownDiv .goog-menuitem-content {`,
    `color: #fff;`,
    `}`,

    // Connection highlight.
    `${selector} .blocklyHighlightedConnectionPath {`,
    `stroke: ${this.SELECTED_GLOW_COLOUR};`,
    `}`,

    // Disabled outline paths.
    `${selector} .blocklyDisabled > .blocklyOutlinePath {`,
    `fill: url(#blocklyDisabledPattern${this.randomIdentifier})`,
    `}`,

    // Insertion marker.
    `${selector} .blocklyInsertionMarker>.blocklyPath {`,
    `fill-opacity: ${this.INSERTION_MARKER_OPACITY};`,
    `stroke: none;`,
    `}`,
  ];
  return css.concat([
    selector +
      " .blocklyConnectionIndicator, " +
      selector +
      " .blocklyInputConnectionIndicator {",
    "fill: #ff0000;",
    "fill-opacity: 0.9;",
    "stroke: #ffff00;",
    "stroke-width: 3px;",
    "}",
    selector + " .blocklyConnectionIndicator {",
    "display: none;",
    "}",
    selector +
      " .blocklyBlockDragSurface > g > .blocklyDraggable > .blocklyConnectionIndicator {",
    "display: block;",
    "}",
    selector + " .blocklyConnectionLine {",
    "stroke: #ffff00;",
    "stroke-width: 4px;",
    "}",
    selector + " .blocklyConnectionLine.hidden {",
    "display: none;",
    "}",

    // Flyout heading.
    selector +
      " .blocklyFlyoutHeading .blocklyFlyoutLabelText {" +
      "font-size: 1.5rem;",
    "}",
  ]);
};

/**
 * Sever all links from this object.
 * @package
 */
const CONNECTING_SNAP_RADIUS = 150;
const SNAP_RADIUS = 150;
const CONNECTION_INDICATOR_RADIUS = 9;

Blockly.InsertionMarkerManager.prototype.dispose = function () {
  this.availableConnections.length = 0;

  Blockly.Events.disable();
  try {
    if (this.firstMarker) {
      this.firstMarker.dispose();
    }
    if (this.lastMarker) {
      this.lastMarker.dispose();
    }
  } finally {
    Blockly.Events.enable();
  }
};

/**
 * Update the available connections for the top block. These connections can
 * change if a block is unplugged and the stack is healed.
 * @package
 */
Blockly.InsertionMarkerManager.prototype.updateAvailableConnections =
  function () {
    this.availableConnections = this.initAvailableConnections();
  };

/**
 * Return whether the block would be deleted if dropped immediately, based on
 * information from the most recent move event.
 * @return {boolean} True if the block would be deleted if dropped immediately.
 * @package
 */
Blockly.InsertionMarkerManager.prototype.wouldDeleteBlock = function () {
  return this.wouldDeleteBlock;
};

/**
 * Return whether the block would be connected if dropped immediately, based on
 * information from the most recent move event.
 * @return {boolean} True if the block would be connected if dropped
 *   immediately.
 * @package
 */
Blockly.InsertionMarkerManager.prototype.wouldConnectBlock = function () {
  return !!this.closestConnection_;
};

/**
 * Connect to the closest connection and render the results.
 * This should be called at the end of a drag.
 * @package
 */
Blockly.InsertionMarkerManager.prototype.applyConnections = function () {
  if (this.closestConnection_) {
    // Don't fire events for insertion markers.
    Blockly.Events.disable();
    this.hidePreview();
    Blockly.Events.enable();
    // Connect two blocks together.
    this.localConnection_.connect(this.closestConnection_);
    if (this.topBlock.rendered) {
      // Trigger a connection animation.
      // Determine which connection is inferior (lower in the source stack).
      var inferiorConnection = this.localConnection_.isSuperior()
        ? this.closestConnection_
        : this.localConnection_;
      Blockly.blockAnimations.connectionUiEffect(
        inferiorConnection.getSourceBlock()
      );
      // Bring the just-edited stack to the front.
      var rootBlock = this.topBlock.getRootBlock();
      rootBlock.bringToFront();
    }
  }
};

Blockly.InsertionMarkerManager.prototype.update = function (dxy, dragTarget) {
  const newCandidate = this.getCandidate(dxy);

  this.wouldDeleteBlock = this.shouldDelete(!!newCandidate, dragTarget);

  const shouldUpdate =
    this.wouldDeleteBlock || this.shouldUpdatePreviews(newCandidate, dxy);

  if (shouldUpdate) {
    // Don't fire events for insertion marker creation or movement.
    Blockly.Events.disable();
    this.maybeHidePreview(newCandidate);
    this.maybeShowPreview(newCandidate);
    Blockly.Events.enable();
  }
  this.updateConnectionLine(dxy);
};

/**
 * Create an insertion marker that represents the given block.
 * @param {!Blockly.BlockSvg} sourceBlock The block that the insertion marker
 *     will represent.
 * @return {!Blockly.BlockSvg} The insertion marker that represents the given
 *     block.
 * @private
 */
Blockly.InsertionMarkerManager.prototype.createMarkerBlock = function (
  sourceBlock
) {
  var imType = sourceBlock.type;

  Blockly.Events.disable();
  try {
    this.workspace.loadingEventsDisabled = true;
    var result = this.workspace.newBlock(imType);
    this.workspace.loadingEventsDisabled = false;
    result.setInsertionMarker(true);
    if (sourceBlock.mutationToDom) {
      var oldMutationDom = sourceBlock.mutationToDom();
      if (oldMutationDom) {
        result.domToMutation(oldMutationDom);
      }
    }
    // Copy field values from the other block.  These values may impact the
    // rendered size of the insertion marker.  Note that we do not care about
    // child blocks here.
    for (var i = 0; i < sourceBlock.inputList.length; i++) {
      var sourceInput = sourceBlock.inputList[i];
      if (
        sourceInput.name === Blockly.constants.COLLAPSED_INPUT_NAME ||
        sourceInput.isVisible()
      ) {
        // pxt-blockly
        continue; // Ignore the collapsed input.
      }
      var resultInput = result.inputList[i];
      if (sourceBlock.isCollapsed()) {
        continue;
      }
      if (!resultInput) {
        throw new Error(
          Blockly.InsertionMarkerManager.DUPLICATE_BLOCK_ERROR.replace(
            "%1",
            "an input"
          )
        );
      }
      for (var j = 0; j < sourceInput.fieldRow.length; j++) {
        var sourceField = sourceInput.fieldRow[j];
        var resultField = resultInput.fieldRow[j];
        if (sourceBlock.isCollapsed()) {
          continue;
        }
        if (!resultField) {
          throw new Error(
            Blockly.InsertionMarkerManager.DUPLICATE_BLOCK_ERROR.replace(
              "%1",
              "a field"
            )
          );
        }
        resultField.setValue(sourceField.getValue());
      }
    }

    result.setCollapsed(sourceBlock.isCollapsed());
    result.setInputsInline(sourceBlock.getInputsInline());

    result.initSvg();
    result.getSvgRoot().setAttribute("visibility", "hidden");
  } finally {
    Blockly.Events.enable();
  }

  return result;
};

/**
 * Populate the list of available connections on this block stack.  This should
 * only be called once, at the beginning of a drag.
 * If the stack has more than one block, this function will populate
 * lastOnStack_ and create the corresponding insertion marker.
 * @return {!Array<!Blockly.RenderedConnection>} A list of available
 *     connections.
 * @private
 */
Blockly.InsertionMarkerManager.prototype.initAvailableConnections =
  function () {
    var available = this.topBlock.getConnections_(false);
    // Also check the last connection on this stack
    var lastOnStack = this.topBlock.lastConnectionInStack(true);
    if (lastOnStack && lastOnStack !== this.topBlock.nextConnection) {
      available.push(lastOnStack);
      this.lastOnStack = lastOnStack;
      if (this.lastMarker) {
        Blockly.Events.disable();
        try {
          this.lastMarker.dispose();
        } finally {
          Blockly.Events.enable();
        }
      }
      this.lastMarker = this.createMarkerBlock(lastOnStack.getSourceBlock());
    }
    return available;
  };

/**
 * Whether the previews (insertion marker and replacement marker) should be
 * updated based on the closest candidate and the current drag distance.
 * @param {!Object} candidate An object containing a local connection, a closest
 *     connection, and a radius.  Returned by getCandidate_.
 * @param {!Blockly.utils.Coordinate} dxy Position relative to drag start,
 *     in workspace units.
 * @return {boolean} Whether the preview should be updated.
 * @private
 */
Blockly.InsertionMarkerManager.prototype.shouldUpdatePreviews = function (
  candidate,
  dxy
) {
  if (!candidate) return !!this.activeCandidate;
  var candidateLocal = candidate.local;
  var candidateClosest = candidate.closest;
  var radius = candidate.radius;

  // Found a connection!
  if (candidateLocal && candidateClosest) {
    // We're already showing an insertion marker.
    // Decide whether the new connection has higher priority.
    if (this.localConnection_ && this.closestConnection_) {
      // The connection was the same as the current connection.
      if (
        this.closestConnection_ === candidateClosest &&
        this.localConnection_ === candidateLocal
      ) {
        return false;
      }
      var xDiff = this.localConnection_.x + dxy.x - this.closestConnection_.x;
      var yDiff = this.localConnection_.y + dxy.y - this.closestConnection_.y;
      var curDistance = Math.sqrt(xDiff * xDiff + yDiff * yDiff);
      // Slightly prefer the existing preview over a new preview.
      return !(
        candidateClosest &&
        radius > curDistance - Blockly.CURRENT_CONNECTION_PREFERENCE
      );
    } else if (!this.localConnection_ && !this.closestConnection_) {
      // We weren't showing a preview before, but we should now.
      return true;
    } else {
      console.error(
        "Only one of localConnection_ and closestConnection_ was set."
      );
    }
  } else {
    // No connection found.
    // Only need to update if we were showing a preview before.
    return !!(this.localConnection_ && this.closestConnection_);
  }

  console.error(
    "Returning true from shouldUpdatePreviews, but it's not clear why."
  );
  return true;
};

/**
 * Find the nearest valid connection, which may be the same as the current
 * closest connection.
 * @param {!Blockly.utils.Coordinate} dxy Position relative to drag start,
 *     in workspace units.
 * @return {!Object} An object containing a local connection, a closest
 *     connection, and a radius.
 * @private
 */
Blockly.InsertionMarkerManager.prototype.getCandidate = function (dxy) {
  var radius = this.getStartRadius();
  var candidateClosest = null;
  var candidateLocal = null;

  for (var i = 0; i < this.availableConnections.length; i++) {
    var myConnection = this.availableConnections[i];
    var neighbour = myConnection.closest(radius, dxy);
    if (neighbour.connection) {
      candidateClosest = neighbour.connection;
      candidateLocal = myConnection;
      radius = neighbour.radius;
    }
  }
  return {
    closest: candidateClosest,
    local: candidateLocal,
    radius: radius,
  };
};

/**
 * Decide the radius at which to start searching for the closest connection.
 * @return {number} The radius at which to start the search for the closest
 *     connection.
 * @private
 */
Blockly.InsertionMarkerManager.prototype.getStartRadius = function () {
  // If there is already a connection highlighted,
  // increase the radius we check for making new connections.
  // Why? When a connection is highlighted, blocks move around when the insertion
  // marker is created, which could cause the connection became out of range.
  // By increasing radiusConnection when a connection already exists,
  // we never "lose" the connection from the offset.
  if (this.closestConnection_ && this.localConnection_) {
    return CONNECTING_SNAP_RADIUS;
  }

  return SNAP_RADIUS;
};

/**
 * Whether ending the drag would delete the block.
 * @param {!Object} candidate An object containing a local connection, a closest
 *    connection, and a radius.
 * @param {?Blockly.IDragTarget} dragTarget The drag target that the block is
 *     currently over.
 * @return {boolean} Whether dropping the block immediately would delete the
 *    block.
 * @private
 */
Blockly.InsertionMarkerManager.prototype.shouldDelete = function (
  candidate,
  dragTarget
) {
  if (dragTarget) {
    var componentManager = this.workspace.getComponentManager();
    var isDeleteArea = componentManager.hasCapability(
      dragTarget.id,
      Blockly.ComponentManager.Capability.DELETE_AREA
    );
    if (isDeleteArea) {
      return /** @type {!Blockly.IDeleteArea} */ (dragTarget).wouldDelete(
        this.topBlock,
        candidate && !!candidate.closest
      );
    }
  }
  return false;
};

/**
 * Show an insertion marker or replacement highlighting during a drag, if
 * needed.
 * At the beginning of this function, this.localConnection_ and
 * this.closestConnection_ should both be null.
 * @param {!Object} candidate An object containing a local connection, a closest
 *     connection, and a radius.
 * @private
 */
Blockly.InsertionMarkerManager.prototype.maybeShowPreview = function (
  candidate
) {
  // Nope, don't add a marker.
  if (this.wouldDeleteBlock) {
    return;
  }
  if (!candidate) return; // Nothing to connect to.
  var closest = candidate.closest;
  var local = candidate.local;

  // Nothing to connect to.
  if (!closest) {
    return;
  }

  // Something went wrong and we're trying to connect to an invalid connection.
  if (
    closest === this.closestConnection_ ||
    closest.getSourceBlock().isInsertionMarker()
  ) {
    console.log("Trying to connect to an insertion marker");
    return;
  }
  // Add an insertion marker or replacement marker.
  this.closestConnection_ = closest;
  this.localConnection_ = local;
  this.showPreview();
};

/**
 * A preview should be shown.  This function figures out if it should be a block
 * highlight or an insertion marker, and shows the appropriate one.
 * @private
 */
Blockly.InsertionMarkerManager.prototype.showPreview = function () {
  var closest = this.closestConnection_;
  var renderer = this.workspace.getRenderer();
  var method = renderer.getConnectionPreviewMethod(
    /** @type {!Blockly.RenderedConnection} */ (closest),
    /** @type {!Blockly.RenderedConnection} */ (this.localConnection_),
    this.topBlock
  );

  switch (method) {
    case Blockly.InsertionMarkerManager.PREVIEW_TYPE.INPUT_OUTLINE:
      this.showInsertionInputOutline();
      break;
    case Blockly.InsertionMarkerManager.PREVIEW_TYPE.INSERTION_MARKER:
      this.showInsertionMarker();
      break;
    case Blockly.InsertionMarkerManager.PREVIEW_TYPE.REPLACEMENT_FADE:
      this.showReplacementFade();
      break;
    default:
      break;
  }

  // Optionally highlight the actual connection, as a nod to previous behaviour.
  if (closest && renderer.shouldHighlightConnection(closest)) {
    closest.highlight();
  }
};

/**
 * pxt-blockly Create the SVG line element to render between two highlighted connections
 * @private
 */
Blockly.InsertionMarkerManager.prototype.createFirstConnectionLine =
  function () {
    if (!this.firstConnectionLine_) {
      this.firstConnectionLine_ = Blockly.utils.dom.createSvgElement(
        "line",
        {
          class: "blocklyConnectionLine",
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 0,
        },
        this.localConnection_.sourceBlock_.getSvgRoot()
      );

      // Create connection indicator for target/closes connection
      this.firstConnectionIndicator_ = Blockly.utils.dom.createSvgElement(
        "g",
        { class: "blocklyInputConnectionIndicator" },
        this.closestConnection_.sourceBlock_.getSvgRoot()
      );
      // this.firstCircle_ = Blockly.utils.dom.createSvgElement(
      //   "circle",
      //   { r: 6 },
      //   this.firstConnectionIndicator_
      // );
      var offset = this.closestConnection_.offsetInBlock;
      this.firstConnectionIndicator_.setAttribute(
        "transform",
        "translate(" + offset.x + "," + offset.y + ")"
      );
    }
  };

Blockly.InsertionMarkerManager.prototype.createSecondConnectionLine =
  function () {
    if (!this.secondConnectionLine_) {
      this.secondConnectionLine_ = Blockly.utils.dom.createSvgElement(
        "line",
        {
          class: "blocklyConnectionLine",
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 0,
        },
        this.localConnection_.sourceBlock_.getSvgRoot()
      );

      // Create connection indicator for target/closes connection
      this.secondConnectionIndicator_ = Blockly.utils.dom.createSvgElement(
        "g",
        { class: "blocklyInputConnectionIndicator" },
        this.closestConnection_.sourceBlock_.getSvgRoot()
      );
      // this.secondCircle_ = Blockly.utils.dom.createSvgElement(
      //   "circle",
      //   { r: 6 },
      //   this.secondConnectionIndicator_
      // );
      var offset = this.closestConnection_.offsetInBlock;
      this.secondConnectionIndicator_.setAttribute(
        "transform",
        "translate(" +
          Number(offset.x + inlineInputWidth) +
          "," +
          offset.y +
          ")"
      );
    }
  };

/**
 * pxt-blockly Update the position of the connection line element while block dragged
 * @private
 */
Blockly.InsertionMarkerManager.prototype.updateConnectionLine = function (dxy) {
  if (
    this.closestConnection_ &&
    this.localConnection_ &&
    this.firstConnectionLine_ &&
    this.secondConnectionLine_
  ) {
    var radius = CONNECTION_INDICATOR_RADIUS;
    var offset = this.localConnection_.offsetInBlock;

    var localBlockWidth = this.localConnection_.sourceBlock_.width;

    var x2 =
      this.closestConnection_.x - this.localConnection_.x - dxy.x + offset.x;
    var y2 =
      this.closestConnection_.y - this.localConnection_.y - dxy.y + offset.y;
    // Offset the line by the radius of the indicator to prevent overlap
    var atan = Math.atan2(y2 - offset.y, x2 - offset.x);

    var secondX2 =
      this.closestConnection_.x -
      this.localConnection_.x -
      dxy.x +
      offset.x +
      inlineInputWidth;

    var secondY2 =
      this.closestConnection_.y - this.localConnection_.y - dxy.y + offset.y;
    var secondAtan = Math.atan2(secondY2 - offset.y, secondX2 - offset.x);

    var len = Math.sqrt(
      Math.pow(x2 - offset.x, 2) + Math.pow(y2 - offset.y, 2)
    );
    // When the indicators are overlapping, we hide the line
    if (len < radius * 2 + 1) {
      Blockly.utils.dom.addClass(this.firstConnectionLine_, "hidden");
      Blockly.utils.dom.addClass(this.secondConnectionLine_, "hidden");
    } else {
      Blockly.utils.dom.removeClass(this.firstConnectionLine_, "hidden");
      Blockly.utils.dom.removeClass(this.secondConnectionLine_, "hidden");
      if (this.localConnection_.sourceBlock_.type === "callback") {
        Blockly.utils.dom.addClass(this.secondConnectionLine_, "hidden");
      }

      this.firstConnectionLine_.setAttribute(
        "x1",
        offset.x + Math.cos(atan) * radius
      );
      this.firstConnectionLine_.setAttribute(
        "y1",
        offset.y + Math.sin(atan) * radius
      );

      this.firstConnectionLine_.setAttribute(
        "x2",
        x2 - Math.cos(atan) * radius
      );
      this.firstConnectionLine_.setAttribute(
        "y2",
        y2 - Math.sin(atan) * radius
      );

      this.secondConnectionLine_.setAttribute(
        "x1",
        offset.x + Math.cos(secondAtan) * radius + localBlockWidth
      );
      this.secondConnectionLine_.setAttribute(
        "y1",
        offset.y + Math.sin(secondAtan) * radius
      );

      this.secondConnectionLine_.setAttribute(
        "x2",
        secondX2 - Math.cos(secondAtan) * radius
      );
      this.secondConnectionLine_.setAttribute(
        "y2",
        secondY2 - Math.sin(secondAtan) * radius
      );
    }
  }
};

/**
 * Hide the connection line element when unhighlighting
 * @private
 */
Blockly.InsertionMarkerManager.prototype.hideConnectionLine = function () {
  if (
    this.localConnection_ &&
    this.firstConnectionLine_ &&
    this.secondConnectionLine_
  ) {
    this.localConnection_.sourceBlock_
      .getSvgRoot()
      .removeChild(this.firstConnectionLine_);

    this.localConnection_.sourceBlock_
      .getSvgRoot()
      .removeChild(this.secondConnectionLine_);

    this.firstConnectionLine_ = null;
    this.secondConnectionLine_ = null;

    this.closestConnection_.sourceBlock_
      .getSvgRoot()
      .removeChild(this.firstConnectionIndicator_);

    this.closestConnection_.sourceBlock_
      .getSvgRoot()
      .removeChild(this.secondConnectionIndicator_);

    this.firstConnectionIndicator_ = null;
    this.secondConnectionIndicator_ = null;
  }
};

/**
 * Show an insertion marker or replacement highlighting during a drag, if
 * needed.
 * At the end of this function, this.localConnection_ and
 * this.closestConnection_ should both be null.
 * @param {!Object} candidate An object containing a local connection, a closest
 *     connection, and a radius.
 * @private
 */
Blockly.InsertionMarkerManager.prototype.maybeHidePreview = function (
  candidate
) {
  // If there's no new preview, remove the old one but don't bother deleting it.
  // We might need it later, and this saves disposing of it and recreating it.
  if (!candidate || !candidate.closest) {
    this.hidePreview();
  } else {
    // If there's a new preview and there was an preview before, and either
    // connection has changed, remove the old preview.
    var hadPreview = this.closestConnection_ && this.localConnection_;
    var closestChanged = this.closestConnection_ !== candidate.closest;
    var localChanged = this.localConnection_ !== candidate.local;

    // Also hide if we had a preview before but now we're going to delete instead.
    if (
      hadPreview &&
      (closestChanged || localChanged || this.wouldDeleteBlock)
    ) {
      this.hidePreview();
    }
  }

  // Either way, clear out old state.
  this.markerConnection_ = null;
  this.closestConnection_ = null;
  this.localConnection_ = null;
};

/**
 * A preview should be hidden.  This function figures out if it is a block
 *  highlight or an insertion marker, and hides the appropriate one.
 * @private
 */
Blockly.InsertionMarkerManager.prototype.hidePreview = function () {
  if (
    this.closestConnection_ &&
    this.closestConnection_.targetBlock() &&
    this.workspace
      .getRenderer()
      .shouldHighlightConnection(this.closestConnection_)
  ) {
    this.closestConnection_.unhighlight();
  }
  if (this.fadedBlock) {
    this.hideReplacementFade();
  } else if (this.highlightedBlock) {
    this.hideInsertionInputOutline();
  } else if (this.markerConnection) {
    this.hideInsertionMarker();
  }
};

/**
 * Shows an insertion marker connected to the appropriate blocks (based on
 * manager state).
 * @private
 */
Blockly.InsertionMarkerManager.prototype.showInsertionMarker = function () {
  var local = this.localConnection_;
  var closest = this.closestConnection_;

  var isLastInStack = this.lastOnStack && local === this.lastOnStack;
  var imBlock = isLastInStack ? this.lastMarker : this.firstMarker;
  var imConn = imBlock.getMatchingConnection(local.getSourceBlock(), local);

  if (imConn === this.markerConnection) {
    throw Error(
      "Made it to showInsertionMarker_ even though the marker isn't " +
        "changing"
    );
  }

  // Render disconnected from everything else so that we have a valid
  // connection location.
  imBlock.render();
  imBlock.rendered = true;
  imBlock.getSvgRoot().setAttribute("visibility", "visible");

  if (imConn && closest) {
    // Position so that the existing block doesn't move.
    imBlock.positionNearConnection(imConn, closest);
  }
  if (closest) {
    // Connect() also renders the insertion marker.
    imConn.connect(closest);
  }

  this.markerConnection = imConn;
};

/**
 * Disconnects and hides the current insertion marker. Should return the blocks
 * to their original state.
 * @private
 */
Blockly.InsertionMarkerManager.prototype.hideInsertionMarker = function () {
  if (!this.markerConnection) {
    console.log("No insertion marker connection to disconnect");
    return;
  }

  var imConn = this.markerConnection;
  var imBlock = imConn.getSourceBlock();
  var markerNext = imBlock.nextConnection;
  var markerPrev = imBlock.previousConnection;
  var markerOutput = imBlock.outputConnection;

  var isFirstInStatementStack =
    imConn === markerNext && !(markerPrev && markerPrev.targetConnection);

  var isFirstInOutputStack =
    imConn.type === Blockly.connectionTypes.INPUT_VALUE &&
    !(markerOutput && markerOutput.targetConnection);
  // The insertion marker is the first block in a stack.  Unplug won't do
  // anything in that case.  Instead, unplug the following block.
  if (isFirstInStatementStack || isFirstInOutputStack) {
    imConn.targetBlock().unplug(false);
  }
  // Inside of a C-block, first statement connection.
  else if (
    imConn.type === Blockly.connectionTypes.NEXT_STATEMENT &&
    imConn !== markerNext
  ) {
    var innerConnection = imConn.targetConnection;
    innerConnection.getSourceBlock().unplug(false);

    var previousBlockNextConnection = markerPrev
      ? markerPrev.targetConnection
      : null;

    imBlock.unplug(true);
    if (previousBlockNextConnection) {
      previousBlockNextConnection.connect(innerConnection);
    }
  } else {
    imBlock.unplug(true /* healStack */);
  }

  if (imConn.targetConnection) {
    throw Error(
      "markerConnection_ still connected at the end of " +
        "disconnectInsertionMarker"
    );
  }

  this.markerConnection = null;
  var svg = imBlock.getSvgRoot();
  if (svg) {
    svg.setAttribute("visibility", "hidden");
  }
};

/**
 * Shows an outline around the input the closest connection belongs to.
 * @private
 */
Blockly.InsertionMarkerManager.prototype.showInsertionInputOutline =
  function () {
    var closest = this.closestConnection_;
    //   const closest = activeCandidate.closest;
    this.highlightedBlock = closest.getSourceBlock();
    this.highlightedBlock.highlightShapeForInput(closest, true);
    this.createFirstConnectionLine(); // 연결선 생성
    this.createSecondConnectionLine();
  };

/**
 * Hides any visible input outlines.
 * @private
 */
Blockly.InsertionMarkerManager.prototype.hideInsertionInputOutline =
  function () {
    if (!this.highlightedBlock) return;

    this.highlightedBlock.highlightShapeForInput(
      this.closestConnection_,
      false
    );
    this.highlightedBlock = null;
    this.hideConnectionLine(); // 연결선 hide
  };

/**
 * Shows a replacement fade affect on the closest connection's target block
 * (the block that is currently connected to it).
 * @private
 */
Blockly.InsertionMarkerManager.prototype.showReplacementFade = function () {
  this.fadedBlock = this.closestConnection_.targetBlock();
  this.fadedBlock.fadeForReplacement(true);
  this.createFirstConnectionLine(); // 연결선 생성
  this.createSecondConnectionLine();
};

/**
 * Hides/Removes any visible fade affects.
 * @private
 */
Blockly.InsertionMarkerManager.prototype.hideReplacementFade = function () {
  if (!this.fadedBlock) return;

  this.fadedBlock.fadeForReplacement(false);
  this.fadedBlock = null;
  this.hideConnectionLine(); // pxt-blockly
};

/**
 * Get a list of the insertion markers that currently exist.  Drags have 0, 1,
 * or 2 insertion markers.
 * @return {!Array<!Blockly.BlockSvg>} A possibly empty list of insertion
 *     marker blocks.
 * @package
 */
Blockly.InsertionMarkerManager.prototype.getInsertionMarkers = function () {
  var result = [];
  if (this.firstMarker) {
    result.push(this.firstMarker);
  }
  if (this.lastMarker) {
    result.push(this.lastMarker);
  }
  return result;
};
