import React, { Component } from "react";
import BuilderSidebarContextMenu from "../contextMenu/BuilderSidebarContextMenu";
import { FaDrawPolygon } from "react-icons/fa";
import {
  AiFillEdit,
  AiOutlineFileAdd,
  AiOutlineUnorderedList,
} from "react-icons/ai";
import { Enums } from "../BuilderEnum";
import Popup from "components/common/Popup";
import NewErdPopup from "page/popup/erd/NewErdPopup";
import { connect } from "react-redux";
import { createErd, updateErd } from "./reducers/ErdAction";
import { stopEvent } from "../ui/editor/handler/UIEditorEventHandler";
import ErdService from "services/erd/ErdService";
import { initCommand } from "../ui/reducers/CommandAction";

class ErdMenu extends BuilderSidebarContextMenu {
  constructor(props) {
    super(props);
    this.onClickNewERD = this.onClickNewERD.bind(this);
    this.onLoadProgram = this.onLoadProgram.bind(this);
  }

  componentDidMount() {
    this.getRecentMenuList(Enums.LocalStorageName.ERD_HISTORY);
  }

  onClickNewERD() {
    const callback = (erdData) => {
      this.props.createErd(erdData);
      this.goToPage(
        Enums.BuilderPath.ERD.MAIN + "/" + Enums.BuilderPath.ERD.BUILDER
      );
    };
    Popup.open(
      <NewErdPopup callback={callback} erdInfo={this.props.output.erd} />,
      {
        style: { content: { width: "550px" } },
      }
    );
  }

  onLoadProgram(e, work, cb) {
    stopEvent(e);
    ErdService.getErd(
      work,
      (res) => {
        const { erdContent, erdMemo, ...otherData } = res.data;
        const output = JSON.parse(erdContent);
        const firstCategory = output.areas[Object.keys(output.areas)[0]];
        const data = {
          erdInfo: {
            ...otherData,
          },
          output: output,
          memo: JSON.parse(erdMemo) || [],
          activatedErd: {
            name: firstCategory.name,
            compId: firstCategory.compId,
          },
        };
        this.props.updateErd(data);
        this.goToPage(
          Enums.BuilderPath.ERD.MAIN + "/" + Enums.BuilderPath.ERD.BUILDER
        );
        if (cb) cb();
      },
      cb
    );
  }

  renderMenuContents() {
    return (
      <div>
        {this.renderTitle(FaDrawPolygon, "ERD Builder")}
        <div className="menu-list">
          {this.renderContextMenu(AiFillEdit, "ERD Editor", (e) =>
            this.goToPage(
              Enums.BuilderPath.ERD.MAIN + "/" + Enums.BuilderPath.ERD.BUILDER
            )
          )}
          {this.renderContextMenu(
            AiOutlineFileAdd,
            "신규 ERD 생성",
            this.onClickNewERD
          )}
          {this.renderContextMenu(AiOutlineUnorderedList, "ERD 목록", (e) =>
            this.goToPage(
              Enums.BuilderPath.ERD.MAIN + "/" + Enums.BuilderPath.ERD.LIST
            )
          )}
        </div>
        <hr />
        <div className="sub-title">최근 작업한 ERD</div>
        <div className="sub-list">
          {this.renderRecentMenuList(Enums.LocalStorageName.ERD_HISTORY)}
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => {
    return {
      output: state.erd.output,
      activatedErd: state.erd.activatedErd,
    };
  },
  (dispatch) => {
    return {
      createErd: (data) => dispatch(createErd(data)),
      updateErd: (data) => {
        dispatch(updateErd(data));
        dispatch(initCommand());
      },
    };
  }
)(ErdMenu);
