/* eslint-disable jsx-a11y/alt-text */
import React, { Fragment } from "react";

import Modal from "components/common/modal/UModal";

import UmodalTemplate from "components/common/modal/UModalTemplate";

const TemplateSyntaxGuide = (props) => {
  return (
    <Modal>
      <Modal.Header title={props.title} />
      <Modal.Body>
        <UmodalTemplate>
          <Fragment>
            <h2 id="if--else">if / else</h2>
            <p>if ~ else 사용법은 다음과 같다.</p>
            <div>
              <pre>
                <code>
                  &lt;!-- reqStatusCd 값이 'CHG_REV' 일경우 checkbox를
                  보여준다.--/&gt; <br></br>
                  &#123;&#123;if reqStatusCd == "CHG_REV" &#125;&#125;<br></br>
                  {"    "}
                  &lt;input type="radio"
                  id="radio_$&#123;$item.getCurrTmplRowIndex()&#125;"
                  name="radio" /&gt;
                  <br></br>
                  &#123;&#123;/if&#125;&#125;<br></br>
                </code>
              </pre>
            </div>
            <pre>
              <code>
                &lt;!-- messageCode 값이 empty 일경우 --/&gt; <br></br>
                &#123;&#123;if !$item.utils.isEmpty(messageCode) &#125;&#125;
                <br></br>
                {"    "}
                <span>&lt;li&gt; $&#123;messageCode&#125; &lt;/li&gt;</span>
                <br></br>
                &#123;&#123;else&#125;&#125;<br></br>
                {"    "}
                <span>&lt;li&gt; message code is empty &lt;/li&gt;</span>
                <br></br>
                &#123;&#123;/if&#125;&#125;<br></br>
              </code>
            </pre>
            <pre>
              <code>
                &lt;!-- todo 숫자에 따라 badge 색상 변경 --/&gt; <br></br>
                &lt;span class="badge" style=" <br></br>
                &#123;&#123;if toDoCount &gt; 150&#125;&#125; <br></br>
                {"    "}
                background-color: #dd4b39;
                <br></br>
                &#123;&#123;else toDoCount &gt; 100&#125;&#125;<br></br>
                {"    "}
                background-color: #ded9d9;
                <br></br>
                &#123;&#123;else toDoCount &gt; 50&#125;&#125; <br></br>
                {"    "}
                background-color: #00a65a;
                <br></br>
                &#123;&#123;else&#125;&#125; <br></br> {"    "}
                background-color: #0073b7;
                <br></br>
                &#123;&#123;/if&#125;&#125; <br></br>float:
                right;"&gt;$&#123;toDoCount&#125;&lt;/span&gt;
              </code>
            </pre>
            <h2 id="html">html</h2>
            <p>html 태그를 문자열로 출력한다.</p>
            <div>
              <pre>
                <code>
                  &lt;!-- HTML TAG를 적용해서 출력 --/&gt; <br></br>
                  &#123;&#123;html <span>&lt;li&gt;</span>outputHtml
                  <span>&lt;/li&gt;</span>&#125;&#125;
                </code>
              </pre>
            </div>
            <div>
              <pre>
                <code>
                  &lt;!-- image를 화면에 보여준다. --/&gt; <br></br>
                  &#123;&#123;if !$item.utils.isEmpty(fileId)&#125;&#125;
                  <br></br>
                  {"    "}
                  &#123;&#123;html
                  $item.utils.getImageTagById(fileId,&#123;noImageType :
                  'base64' ,noImage:'data:image/gif;
                  base64,R0lGODlhAQABAAAAACwAAAAAAQABAAA='&#125;)&#125;&#125;
                  <br></br>
                  &#123;#123;/if&#125;&#125;
                </code>
              </pre>
            </div>
            <h2 id="each">each</h2>
            <p>
              컬렌션의 모든 요소에 대해 반복문을 수행한다. <br></br>
            </p>
            <div>
              <pre>
                <code>
                  &#123;&#123;each(i,payment) paymentList&#125;&#125;<br></br>
                  {"    "}
                  <span>&lt;li&gt;</span>$&#123;i + 1&#125; 번째 데이터
                  <span>&lt;/li&gt;</span>
                  <br></br>
                  {"    "}
                  <span>&lt;li&gt;</span>$&#123;payment.payItemNm&#125;
                  <span>&lt;/li&gt;</span>
                  <br></br>
                  &#123;&#123;/each&#125;&#125;
                </code>
              </pre>
            </div>
            <h2 id="html">공통 및 유틸 함수</h2>
            <p>
              자주 사용하는 공통($item.xxx) 및 유틸함수($item.utils.xxxx)는
              다음과 같습니다.
            </p>
            <table className="doc-table">
              <thead>
                <tr>
                  <th style={{ width: "20%" }}>
                    <div>함수 명</div>
                  </th>
                  <th style={{ width: "20%" }}>
                    <div>매개변수</div>
                  </th>
                  <th>
                    <div>설명 &amp; Example</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div>getCurrTmplRowIndex</div>
                  </td>
                  <td>
                    <div>없음</div>
                  </td>
                  <td>
                    <div>
                      Data의 현재 Row index<br></br>
                      <code>
                        $&#123;$item.<b>getCurrTmplRowIndex</b>()&#125;
                      </code>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>defaultValue</div>
                  </td>
                  <td>
                    <div>
                      1.값<br></br>2.대체 값 (없을 경우 '')
                    </div>
                  </td>
                  <td>
                    <div>
                      - briefDesc값이 없으면 appDesc를 대신 보여준다. <br></br>
                      <code>
                        $&#123;$item.utils.<b>defaultValue</b>(briefDesc,
                        appDesc)&#125;
                      </code>
                      - briefDesc값이 없으면 ''(공백)을 보여준다.
                      <br></br>(Data에 따라 null 또는 undefined가 보여질 수 있기
                      때문에)
                      <br></br>
                      <code>
                        $&#123;$item.utils.<b>defaultValue</b>(briefDesc)&#125;
                      </code>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>setCurrentDateFormat</div>
                  </td>
                  <td>
                    <div>1.날짜 값</div>
                  </td>
                  <td>
                    <div>
                      - reqDate를 사용자의 날짜 포맷에 맞게 표기한다. <br></br>
                      <code>
                        $&#123;$item.utils.Date.<b>setCurrentDateFormat</b>
                        (reqDate)&#125;
                      </code>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>formatString</div>
                  </td>
                  <td>
                    <div>
                      1.숫자 값<br></br>2.소숫점 자릿수
                    </div>
                  </td>
                  <td>
                    <div>
                      - 숫자표기 <br></br>
                      <code>
                        $&#123;$item.utils.Number.<b>formatString</b>
                        (amount)&#125;
                      </code>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            {/* <br></br>※ 기타 유틸 함수는 사용자 가이드 ☆ 실행 환경-JavaScript
            함수 > Utils 를 확인하십시요. (작업예정)
            <br></br>※ 사용자 정의 함수 사용가이드 작성 예정 */}
          </Fragment>
        </UmodalTemplate>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default TemplateSyntaxGuide;
