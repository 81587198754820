import { setUserStyle } from "components/builder/ui/reducers/SettingAction";

class StyleUtils {
  static init = () => {
    let _standard = document.getElementById("standard");
    let _tenant = document.getElementById("tenant");
    let _co = document.getElementById("co");
    if (_co) _co.remove();
    if (_tenant) _tenant.remove();
    if (_standard) _standard.remove();
  };

  static ApplyStyle = (dispatch, style, script_id = "customStyle") => {
    if (dispatch) {
      dispatch(setUserStyle(style));
    }

    if (style.apply) {
      const StyleScriptId = script_id;
      const appliedStyle = document.getElementById(StyleScriptId);
      if (appliedStyle) {
        appliedStyle.remove();
      }
      const head = document.getElementsByTagName("head")[0];
      const styleEle = document.createElement("style");
      styleEle.id = StyleScriptId;
      styleEle.dataset.styleId = style.styleId;
      styleEle.textContent = style.styleCode;
      head.appendChild(styleEle);
    }
  };

  static ApplyDefaultStyle = (style, _styleScriptId) => {
    const StyleScriptId = _styleScriptId || "defaultStyle";
    const appliedStyle = document.getElementById(StyleScriptId);
    if (!appliedStyle) {
      const head = document.getElementsByTagName("head")[0];
      const styleEle = document.createElement("style");
      styleEle.id = StyleScriptId;
      styleEle.dataset.styleId = style.styleId;
      styleEle.textContent = style.styleCode;
      head.appendChild(styleEle);
    }
  };

  static ApplyOffStyle = (dispatch, script_id = "customStyle") => {
    const StyleScriptId = script_id;
    const appliedStyle = document.getElementById(StyleScriptId);
    if (appliedStyle) {
      appliedStyle.remove();
    }
    if (dispatch) {
      dispatch(setUserStyle({}));
    }
  };
}
export default StyleUtils;
