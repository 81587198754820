import Blockly from "blockly";
import { appendInternal } from "./BlockLoadSerialization";
/**
 * 사용자 정의 함수를 drag 했을 때 workspace update
 * @param {*} state
 * @param {*} workspace
 * @param {*} parentBlock
 * @param {*} recordUndo
 * @returns
 */
Blockly.serialization.workspaces.update = function (
  state,
  workspace,
  parentBlock,
  inputName,
  recordUndo = false
) {
  const serializerMap = Blockly.registry.getAllItems(
    Blockly.registry.Type.SERIALIZER,
    true
  );
  if (!serializerMap) {
    return;
  }

  const deserializers = Object.entries(serializerMap).sort(
    (a, b) => b[1].priority - a[1].priority
  );

  const prevRecordUndo = Blockly.Events.getRecordUndo();
  Blockly.Events.setRecordUndo(recordUndo);
  const existingGroup = Blockly.Events.getGroup();
  if (!existingGroup) {
    Blockly.Events.setGroup(true);
  }

  Blockly.utils.dom.startTextWidthCache();
  if (workspace instanceof Blockly.WorkspaceSvg) {
    workspace.setResizesEnabled(false);
  }

  // We want to trigger clearing in reverse priority order so plugins don't end
  // up missing dependencies.
  // for (const [, deserializer] of deserializers.reverse()) {
  //   // (deserializer)?.clear(workspace);
  // }

  // reverse() is destructive, so we have to re-reverse to correct the order.
  for (let [name, deserializer] of deserializers.reverse()) {
    name = name;
    const pluginState = state[name];
    if (pluginState) {
      deserializer.load = function (state, workspace, parentBlock) {
        const blockStates = state["blocks"];
        for (const state of blockStates) {
          Blockly.serialization.blocks.append_(state, workspace, inputName, {
            parentBlock: parentBlock,
            recordUndo: Blockly.Events.getRecordUndo(),
          });
        }
      };
      deserializer?.load(state[name], workspace, parentBlock);
    }
  }

  if (workspace instanceof Blockly.WorkspaceSvg) {
    workspace.setResizesEnabled(true);
  }
  Blockly.utils.dom.stopTextWidthCache();

  Blockly.Events.fire(
    new (Blockly.Events.get(Blockly.Events.FINISHED_LOADING))(workspace)
  );

  Blockly.Events.setGroup(existingGroup);
  Blockly.Events.setRecordUndo(prevRecordUndo);
};

/**
 * Loads the block represented by the given state into the given workspace.
 *
 * @param state The state of a block to deserialize into the workspace.
 * @param workspace The workspace to add the block to.
 * @param param1 recordUndo: If true, events triggered by this function will be
 *     undo-able by the user. False by default.
 * @returns The block that was just loaded.
 */
Blockly.serialization.blocks.append_ = function (
  state,
  workspace,
  inputName,
  { parentBlock = undefined, recordUndo = false } = {}
) {
  if (inputName) {
    const connection = parentBlock?.getInput(inputName).connection;
    const block = appendInternal(state, workspace, {
      parentConnection: connection,
      recordUndo: recordUndo,
    });
    if (workspace.rendered) Blockly.renderManagement.triggerQueuedRenders();
    return block;
  }
};
