/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useRef, useState } from "react";
import { BsPencilSquare } from "react-icons/bs";
import Popup from "components/common/Popup";
import Modal from "components/common/modal/UModal";
import "bootstrap/dist/css/bootstrap.min.css";
import CodeMirror from "@uiw/react-codemirror";
import { javascript } from "@codemirror/lang-javascript";
import {
  PropertyLable,
  PropertyValue,
} from "components/builder/ui/uiComponents/UIComponentStyle";
import StringUtils from "components/common/utils/StringUtils";
import USelectbox from "components/common/element/USelectbox";
import UModalJavascriptEditor from "components/common/code/UModalJavascriptEditor";

/**
 * WidgetToolbarBtnPopup : Message와 Function 입력 Popup
 *
 * 2022.05.09: init: songe.park
 * 2024.01.02 : Code Mirror 기능 사용 유지 위한 state -> Ref로 관리 하도록 수정
 * @param {*} props
 * @returns value
 */
const WidgetToolbarBtnPopup = (props) => {
  const { title, callbackFnc, data, eventWorkspace, setEventBuilder } = props;
  // const [item, setItem] = useState(data);
  const userFunctionRef = useRef(data.userFunction || "");
  const [eventType, setEventType] = useState("");
  //codemirror
  const javascriptExt = [javascript(true)];
  const [extensions, setExtensions] = useState(javascriptExt);

  useEffect(() => {
    if (StringUtils.isEmpty(data.userFunction)) {
      setEventType("S");
    } else {
      setEventType("U");
    }
  }, [data.userFunction]);

  /**
   * closePopup: value값을 전달하고 popup 닫기
   * @param {*} e
   */
  const closePopup = (e) => {
    if (callbackFnc) {
      let returnVal =
        eventType === "U" && !StringUtils.isEmpty(userFunctionRef.current)
          ? { userFunction: userFunctionRef.current }
          : {};
      callbackFnc.call(this, returnVal);
    }

    Popup.close();
  };

  return (
    <Modal>
      <Modal.Header title={title} />
      <Modal.Body className="popup-panel">
        <div className="w-full">
          <PropertyLable className="float-left">Event 유형</PropertyLable>
          <PropertyValue className="float-left">
            <USelectbox
              id="eventType"
              onChange={(e, item, event) => setEventType(item.id)}
              defaultValue={StringUtils.defaultString(eventType, "S")}
              items={[
                { id: "S", text: "표준" },
                { id: "U", text: "사용자 정의" },
              ]}
              options={{ matchCd: "id", matchNm: "text" }}
            />
          </PropertyValue>
        </div>

        {eventType === "U" ? (
          <div className="w-full">
            <PropertyLable className="w-full float-left">
              사용자 정의 Function
            </PropertyLable>
            <PropertyValue className="w-full float-left">
              <UModalJavascriptEditor
                height="420px"
                defaultValue={StringUtils.defaultString(
                  userFunctionRef.current
                )}
                eventWorkspace={eventWorkspace}
                eventInfo={props.getEventInfo()}
                onClickEventBuilder={setEventBuilder}
                onChange={(value, viewUpdate) => {
                  userFunctionRef.current = value;
                }}
              />
            </PropertyValue>
          </div>
        ) : (
          ""
        )}
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Button onClick={closePopup}>
          <BsPencilSquare />
          &nbsp;확인
        </Modal.Footer.Button>
      </Modal.Footer>
    </Modal>
  );
};

export default WidgetToolbarBtnPopup;
