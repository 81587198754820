import Blockly from "blockly";

/**
 * Blockly icon class link : https://github.com/google/blockly/blob/develop/core/icons/icon.ts
 * /
/**
 * Blockly custom icon link: https://developers.google.com/blockly/guides/create-custom-blocks/icons/creating-custom-icons/basic-implementation
 */

/**
 * Data API category에 있는 Block에 사용할 icon 클래스 정의
 */
class BlockDataApiIcon extends Blockly.icons.Icon {
  constructor(sourceBlock) {
    super(sourceBlock);
  }
  getType() {
    return new Blockly.icons.IconType("data_api_icon");
  }
  initView(pointerdownListener) {
    if (this.svgRoot) return; // Already initialized.

    // This adds the pointerdownListener to the svgRoot element.
    // If you do not call `super` you must do this yourself.
    super.initView(pointerdownListener);

    const svg = Blockly.utils.dom.createSvgElement(
      Blockly.utils.Svg.SVG,
      {
        width: "25px",
        height: "25px",
        fill: "white",
        "fill-opacity": "0.5",
      },
      this.svgRoot // Append to the svgRoot.
    );

    Blockly.utils.dom.createSvgElement(
      Blockly.utils.Svg.PATH,
      {
        d: "M21 7c0 2.21-4.03 4-9 4S3 9.21 3 7m18 0c0-2.21-4.03-4-9-4S3 4.79 3 7m18 0v5M3 7v5m9 4c-4.97 0-9-1.79-9-4m0 0v5c0 2.21 4.03 4 9 4m6-6v3m0 3v-3m0 0h3m-3 0h-3",
        stroke: "#1C274C",
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
        "stroke-width": "2",
      },
      svg
    );
  }
  getSize() {
    return new Blockly.utils.Size(25, 25);
  }
  updateCollapsed() {
    if (!this.svgRoot) return;
    if (this.sourceBlock.isCollapsed()) {
      this.svgRoot.style.display = "block";
      return true;
    }
    if (Blockly.hasBubble(this)) {
      this.setBubbleVisible(false);
    }
  }
  dispose() {
    // Always call super!
    super.dispose();

    this.myBubble?.dispose();
    this.myOtherReference?.dispose();
  }
  saveState() {
    return this.state; // Some arbirtary JSON-compatible data.
  }
  loadState(state) {
    this.state = state;
  }
  onLocationChange(blockOrigin) {
    this.workspaceLocation = Blockly.utils.Coordinate.sum(
      blockOrigin,
      this.offsetInBlock
    );
  }
  isShownWhenCollapsed() {
    return true;
  }
}

Blockly.icons.registry.register(
  new Blockly.icons.IconType("data_api_icon"),
  BlockDataApiIcon
);
