import Blockly from "blockly";
import { Enums } from "components/builder/BuilderEnum";
import BuilderHeader, {
  HeaderMapDispatchToProps,
  HeaderMapStateToProps,
} from "components/builder/BuilderHeader";
import BuilderToolbarButton from "components/builder/BuilderToolbarButton";
import { updateEventHandler } from "components/builder/eventhandler/reducer/EventHandlerAction";
import { AppContext } from "components/common/AppContextProvider";
import Popup from "components/common/Popup";
import StringUtils from "components/common/utils/StringUtils";
import User from "components/common/utils/UserUtils";
import { UserShortKey } from "components/setting/section/shortKey/ShortKeyConfig";
import AutoSaveQuestPopup from "page/popup/eventhandler/AutoSaveQuestPopup";
import EventhandlerSavePopup from "page/popup/eventhandler/EventHandlerSavePopup";
import { FaWpforms } from "react-icons/fa";
import { connect } from "react-redux";
import LocalStorageService from "services/common/LocalService";
import EventhandlerService from "services/eventhandler/EventhandlerService";
import UIReduxHelper from "../ui/editor/helper/UIReduxHelper";

class EventHandlerHeader extends BuilderHeader {
  constructor(props) {
    super(props);
    this.toolbarStyle = props.toolbarStyle || "menu";
    this.state = {
      saveDropdown: false,
    };
    this.goToBuilder = this.goToBuilder.bind(this);
    this.openSave = this.openSave.bind(this);
    this.saveEvent = this.saveEvent.bind(this);
  }

  static contextType = AppContext;

  componentDidMount() {
    this.headerInit();
  }

  goToBuilder = () => {
    const _goToBuilder = () =>
      this.props.navigate(
        Enums.BuilderPath.UI.MAIN + "/" + Enums.BuilderPath.UI.EDITOR
      );
    if (
      StringUtils.defaultString(
        this.props.eventHandler.componentProp.originalOutput?.replaceAll(
          "\n",
          ""
        ),
        ""
      ) !== this.props.eventHandler.output.replaceAll("\n", "")
    ) {
      const AutoSave = LocalStorageService.get(
        Enums.LocalStorageName.EVENT_AUTO_SAVE
      );
      if (
        AutoSave &&
        AutoSave.userId === User.getId() &&
        AutoSave.autoSave === "Y"
      ) {
        this.props.updateComponent();
        _goToBuilder();
      } else {
        Popup.open(
          <AutoSaveQuestPopup
            callback={() => {
              this.props.updateComponent();
              _goToBuilder();
              Popup.close();
            }}
            cancelCallback={(e) => {
              _goToBuilder();
              Popup.close();
            }}
          />,
          {
            style: { content: { width: "360px" } },
          }
        );
      }
    } else {
      this.props.navigate(
        Enums.BuilderPath.UI.MAIN + "/" + Enums.BuilderPath.UI.EDITOR
      );
    }
  };

  openSave = () => {
    const workspace = this.context.workspace.Info;

    Popup.open(
      <EventhandlerSavePopup
        callback={this.saveEvent}
        componentProp={this.props.eventHandler.componentProp}
        workspace={workspace}
      />,
      {
        style: { content: { width: "550px" } },
      }
    );
  };

  saveEvent = (data) => {
    const workspace = this.context.workspace.Info;
    const saveData = {
      ...data,
      ...workspace,
      useYn: "Y",
      eventWorkspace: JSON.stringify(
        Blockly.serialization.workspaces.save(
          this.props.blocklyWorkspaceRef.current
        )
      ),
    };
    EventhandlerService.saveFncCustom(saveData, (res) => {
      const { eventWorkspace: workspace, ...others } = res.data;
      this.props.updateEventHandler({
        ...others,
        workspace: JSON.parse(workspace),
      });
      Popup.close();
    });
  };

  renderHeader() {
    const saveDropdownEvent = this.setDropdownEvent("saveDropdown", "edit");
    return (
      <>
        <li>
          <BuilderToolbarButton
            id={"uiEditor"}
            icon={<FaWpforms size={18} />}
            text={" UI Editor"}
            style={this.toolbarStyle}
            iconOnly={this.state.iconMiniMode}
            onClick={this.goToBuilder}
          />
        </li>
        <li>
          <BuilderToolbarButton
            style={this.toolbarStyle}
            buttonId="edit"
            onClick={saveDropdownEvent}
            iconOnly={this.state.mini}
            tooltipTitle={"편집"}
          />
          {this.state.saveDropdown ? (
            <div
              className="toolbar-dropdown"
              ref={(element) => {
                this.dropdownMenu = element;
              }}
            >
              <ul>
                <li
                  className="dropdown-item"
                  role="button"
                  style={{ width: "250px" }}
                  onClick={this.props.updateComponent}
                >
                  Event 적용
                </li>
                <li
                  className="dropdown-item"
                  role="button"
                  onClick={(e) => {
                    this.props.updateComponent(e);
                    this.props.navigate(
                      Enums.BuilderPath.UI.MAIN +
                        "/" +
                        Enums.BuilderPath.UI.EDITOR
                    );
                  }}
                >
                  Event 적용 & UI Builder 이동
                </li>
                {this.renderDivider()}
                <li
                  className="dropdown-item"
                  role="button"
                  onClick={(e) => this.props.updateComponent(e, this.save)}
                >
                  UI Builder 저장
                </li>
                <li
                  className="dropdown-item"
                  role="button"
                  onClick={(e) => this.props.updateComponent(e, this.save)}
                >
                  UI Builder 저장 & UI Builder 이동
                </li>
                {this.renderDivider()}
                <li
                  className="dropdown-item"
                  role="button"
                  onClick={this.openSave}
                >
                  사용자 정의 블록에 저장
                </li>
              </ul>
            </div>
          ) : null}
        </li>
        <li>
          <BuilderToolbarButton
            style={this.toolbarStyle}
            buttonId={"deploy"}
            onClick={(e) => this.props.updateComponent(e, this.deploy)}
            iconOnly={this.state.mini}
            tooltipTitle={`배포 ${UserShortKey(Enums.ShortKeyDefine.DEPLOY)}`}
          />
        </li>
      </>
    );
  }
}

export default connect(
  (state) => ({
    eventHandler: state.eventHandler,
    output: state.outputUI.output,
    memo: state.outputUI.memo,
    information: state.outputUI.information,
    activeComponent: state.activedUIComponent.component,
    workspace: state.workspace,
    ...HeaderMapStateToProps(state),
  }),
  (dispatch) => ({
    updateInformation: (information) =>
      UIReduxHelper.updateInformation(dispatch, information),
    updateEventHandler: (object) => dispatch(updateEventHandler(object)),
    ...HeaderMapDispatchToProps(dispatch),
  })
)(EventHandlerHeader);
