import React, { useState, useEffect, useCallback, useContext } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useDispatch, useSelector } from "react-redux";
import { FaThList, FaCoins, FaLayerGroup } from "react-icons/fa";

import PropertiesTab from "components/builder/ui/PropertiesTab";
import ServiceComponentsTab from "components/builder/ui/ServiceComponentsTab";
import BasicComponentsTab from "components/builder/ui/BasicComponentsTab";
import UIEditor from "components/builder/ui/editor/UIEditor";
import UICustomDragLayer from "components/builder/ui/editor/UICustomDragLayer";
import { ObjectUtils, StringUtils } from "components/common/utils/CommonUtils";
import BuilderRightMenuPanel from "components/builder/BuilderRightMenuPanel";
import "css/builder/main.css";
import "css/builder/runtime.css";
import "css/builder/settings.css";
import { Enums } from "components/builder/BuilderEnum";
import { AppContext } from "components/common/AppContextProvider";
import { useNavigate } from "react-router-dom";
import UITree from "components/builder/ui/tree/UITree";

const UIBuilderMain = (props) => {
  const output = useSelector((state) => state.outputUI.output);
  const activedComponent = useSelector(
    (state) => state.activedUIComponent.component
  );
  const { isSidebarCollapsed } = useSelector((state) => state.menu);

  const [activedTabKey, setActivedTabKey] = useState("BasicComponents");
  const { component } = useContext(AppContext);

  const navigate = useNavigate();

  useEffect(() => {
    if (!ObjectUtils.isEmpty(activedComponent)) {
      handleTabSelect("Properties");
    }
  }, [activedComponent]);

  const handleTabSelect = (key) => {
    if (activedTabKey !== key) setActivedTabKey(key);
  };

  useEffect(() => {}, []);

  console.log("[Check re-rendering] ", "UIBuilderMain has been re-rendered!!!");
  return (
    <React.Fragment>
      <DndProvider backend={HTML5Backend}>
        {StringUtils.equalsIgnoreCase(
          window.location.pathname,
          Enums.BuilderPath.UI.MAIN + "/" + Enums.BuilderPath.UI.EDITOR
        ) &&
          output.page && <UITree />}

        <div
          id="editPanel"
          className={`edit-panel ${isSidebarCollapsed ? "isCollapse" : ""}`}
        >
          {!ObjectUtils.isEmpty(output) ? <UIEditor tabType="E" /> : ""}
        </div>
        <UICustomDragLayer />
        <BuilderRightMenuPanel>
          <Tabs
            fill
            activeKey={activedTabKey}
            id="controlled-tab"
            onSelect={handleTabSelect}
          >
            <Tab
              eventKey="Properties"
              title={
                <span>
                  <FaThList size="14" />
                  <span className="tab-name">Properties</span>
                </span>
              }
            >
              <PropertiesTab navigate={navigate} />
            </Tab>
            <Tab
              eventKey="ServiceComponents"
              title={
                <span>
                  <FaCoins size="14" />
                  <span className="tab-name">Service</span>
                </span>
              }
            >
              <ServiceComponentsTab />
            </Tab>
            <Tab
              eventKey="BasicComponents"
              title={
                <span>
                  <FaLayerGroup size="14" />
                  <span className="tab-name">Components</span>
                </span>
              }
            >
              <BasicComponentsTab />
            </Tab>
          </Tabs>
        </BuilderRightMenuPanel>
      </DndProvider>
    </React.Fragment>
  );
};
export default UIBuilderMain;
