import BootstrapSwitchButton from "bootstrap-switch-button-react";
import CodeMirror from "@uiw/react-codemirror";
import JsonUtils from "components/common/utils/JsonUtils";
import produce from "immer";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { json } from "@codemirror/lang-json";
import ErdReduxHelper from "../editor/helper/ErdReduxHelper";

function ErdCodeMirror() {
  const dispatch = useDispatch();

  const erd = useSelector((state) => state.erd);
  /**
   * 전체 보기의 경우 모든 Area를 보여주도록 하되, 수정 불가능 하도록 수정
   */
  const codeSpace = erd.activatedErd.compId
    ? erd.output.areas[erd.activatedErd.compId]
    : erd.output.areas;
  const codeText = useRef(JSON.stringify(codeSpace, null, 2));

  const [sourceViewOptions, setSourceViewOptions] = useState({
    containEditorAttr: false,
  });

  const changeViewSource = (value) => {
    if (
      sourceViewOptions.containEditorAttr &&
      JSON.stringify(codeSpace, null, 2) !== value
    ) {
      try {
        codeText.current = value;
        //변경점 적용
      } catch (e) {
        console.log("Json parsing Error");
      }
    }
  };

  useEffect(() => {
    return () => {
      ErdReduxHelper.updateErd(dispatch, JSON.parse(codeText.current), erd);
    };
  }, []);

  return (
    <div className="edit-source">
      {erd.activatedErd.type !== "all" && (
        <div className="edit-source-options">
          <BootstrapSwitchButton
            checked={sourceViewOptions.containEditorAttr}
            size="sm"
            onstyle="primary"
            offstyle="dark"
            onlabel="편집 On"
            offlabel="편집 Off"
            width={120}
            onChange={(containEditorAttr, e) => {
              setSourceViewOptions({
                ...sourceViewOptions,
                containEditorAttr: containEditorAttr,
              });
            }}
          />
        </div>
      )}

      <div className="codeMirror-area">
        <CodeMirror
          value={codeText.current}
          className="source-container"
          height="calc(100vh - 30px - 50px - 32px)"
          extensions={[json(true)]}
          autoFocus={false}
          editable={true}
          onChange={(value, viewUpdate) => changeViewSource(value, viewUpdate)}
        />
      </div>
    </div>
  );
}

export default ErdCodeMirror;
