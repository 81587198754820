import { Enums } from "components/builder/BuilderEnum";
import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Button, CardActions, TextField } from "@mui/material";
import StringUtils from "components/common/utils/StringUtils";
import EntityReduxHelper from "components/builder/entity/editor/helper/EntityReduxHelper";
import { AppContext } from "components/common/AppContextProvider";
import MuiConfig from "components/common/config/MuiConfig";
import { Button as RButton } from "react-bootstrap";
import ObjectUtils from "components/common/utils/ObjectUtils";

const EntityChoiceContainer = styled.div`
  background-color: #fff;
  width: 100%;
  height: calc(100vh - 50px);
  display: flex;
  justify-content: center;
  align-items: center;
`;
const CardList = styled.div`
  width: 1200px;
  display: flex;
  gap: 100px;
  max-width: 85%;
`;
const CardTypography = styled.div`
  font-size: 19px;
  font-weight: bold;
  height: 140px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: lightgray;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  &:hover {
    background-color: #0096cc;
    color: white;
  }
`;

const EntityDesc = styled.ul`
  list-style: none;
`;

const EntityDescLine = styled.li`
  padding: 6px;
`;
const CardDesc = styled.div`
  font-size: 0.875rem;
  color: gray;
  font-family: Noto Sans KR, sans-serif;
`;

function EntityChoiceMain() {
  const navigate = useNavigate();
  const output = useSelector((state) => state.outputENT.output);
  const workspace = useSelector((state) => state.workspace);
  const dispatch = useDispatch();
  const {
    dataModel: { setList },
    workspace: { openPopup },
  } = useContext(AppContext);

  const [isRender, setIsRender] = useState(false);

  useEffect(() => {
    if (StringUtils.includesIgnoreCase(output.dataModelType, ["S", "E", "D"])) {
      navigate(
        Enums.BuilderPath.ENTITY.MAIN + "/" + Enums.BuilderPath.ENTITY.CREATE
      );
    } else {
      setIsRender(true);
    }
    return () => {};
  }, []);

  const onMoveToCreate = (dataModelType) => {
    setList([]);
    const newDataModel = {
      dataModelNm: "",
      moduleCd: workspace.moduleCd,
      description: "",
      dataModelType: dataModelType,
      apiUrl: "",
      entity: "",
      compId: StringUtils.getUuid(),
      type: Enums.EntityComponentType.DATA_MODEL,
      dataModelEntities: [],
      useYn: "Y",
    };

    EntityReduxHelper.openNewModel(dispatch, newDataModel);
    navigate(
      Enums.BuilderPath.ENTITY.MAIN + "/" + Enums.BuilderPath.ENTITY.CREATE
    );
  };

  return isRender ? (
    <EntityChoiceContainer>
      <CardList style={{ position: "relative" }}>
        <Card
          sx={{
            maxWidth: 345,
            ...MuiConfig.card,
          }}
        >
          <CardTypography>Dynamic Data Model</CardTypography>
          <CardContent style={{ minHeight: "100px" }}>
            <Typography
              gutterBottom
              variant="h6"
              component="div"
              sx={{ ...MuiConfig.fontFamily }}
            >
              Data Model for Dynamic API
            </Typography>
            <CardDesc>
              <EntityDesc>
                <EntityDescLine>
                  Database Table을 통해 Data Model Entity를 생성하고 Entity간의
                  연결 및 세부 속성을 설정할 수 있습니다.
                </EntityDescLine>
                <EntityDescLine>
                  Data Model Entity를 기반으로 Dynamic Rest API
                  (조회,등록,수정,삭제) 가 자동 생성됩니다.
                </EntityDescLine>
              </EntityDesc>
            </CardDesc>
          </CardContent>
          <CardActions>
            <TextField
              value={workspace.moduleNm || ""}
              onChange={() => {}}
              label="모듈명"
              size="small"
              fullWidth
              inputProps={{ readOnly: true }}
            />
            <Button size="small" onClick={(e) => onMoveToCreate("D")}>
              Go
            </Button>
          </CardActions>
        </Card>
        <Card
          sx={{
            maxWidth: 345,
            ...MuiConfig.card,
          }}
        >
          <CardTypography style={{ backgroundColor: "" }}>
            Service Data Model
          </CardTypography>
          <CardContent style={{ minHeight: "100px" }}>
            <Typography
              gutterBottom
              variant="h6"
              component="div"
              sx={{ ...MuiConfig.fontFamily }}
            >
              Data Model for Service API
            </Typography>
            <CardDesc>
              <EntityDesc>
                <EntityDescLine>
                  기 존재 하는 REST API와의 연결하기 위한 Input / Output Data
                  Model을 정의합니다.
                </EntityDescLine>
                <EntityDescLine>
                  Data Model Entity는 호출되는 Service REST API의 입.출력
                  DTO(Data transfer Object) 역활을 합니다.
                </EntityDescLine>
              </EntityDesc>
            </CardDesc>
          </CardContent>
          <CardActions>
            <TextField
              value={workspace.moduleNm || ""}
              onChange={() => {}}
              label="모듈명"
              size="small"
              fullWidth
              inputProps={{ readOnly: true }}
            />
            <Button size="small" onClick={(e) => onMoveToCreate("S")}>
              Go
            </Button>
          </CardActions>
        </Card>
        <Card
          sx={{
            maxWidth: 345,
            ...MuiConfig.card,
          }}
        >
          <CardTypography style={{ backgroundColor: "" }}>
            JPA Entity Data Model
          </CardTypography>
          <CardContent style={{ minHeight: "100px" }}>
            <Typography
              gutterBottom
              variant="h6"
              component="div"
              sx={{ ...MuiConfig.fontFamily }}
            >
              Data Model for JPA Entity
            </Typography>
            <CardDesc>
              <EntityDesc>
                <EntityDescLine>
                  Runtime 환경에 기 개발된 JPA Entity를 기반으로 Data Model을
                  구성합니다.
                </EntityDescLine>
                <EntityDescLine>
                  Data Model Entity는 UI Builder 와 JPA Entity 사이에서 Data를
                  교환하는 DTO(Data transfer Object)역활을 합니다.
                </EntityDescLine>
              </EntityDesc>
            </CardDesc>
          </CardContent>
          <CardActions>
            <TextField
              value={workspace.moduleNm || ""}
              onChange={() => {}}
              label="모듈명"
              size="small"
              fullWidth
              inputProps={{ readOnly: true }}
            />
            <Button size="small" onClick={(e) => onMoveToCreate("E")}>
              Go
            </Button>
          </CardActions>
        </Card>
        {ObjectUtils.isEmpty(workspace) && (
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "110%",
              background: "#3c3c3ccc",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "white",
              fontSize: "larger",
              fontWeight: "bold",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            워크스페이스 설정이 필요합니다.
            <RButton size="sm" onClick={() => openPopup()}>
              팝업 열기
            </RButton>
          </div>
        )}
      </CardList>
    </EntityChoiceContainer>
  ) : (
    <></>
  );
}

export default EntityChoiceMain;
