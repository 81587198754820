import ArrayUtils from "components/common/utils/ArrayUtils";
import ObjectUtils from "components/common/utils/ObjectUtils";
import StringUtils from "components/common/utils/StringUtils";
import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { MarkerType } from "reactflow";

//DataModel Output이 변경될때마다 작동하는 CustomHook
/**
 *
 * @returns {[Object]}
 */

function useFlowRender(theme = "light") {
  const dataModelOutput = useSelector((state) => state.outputENT.output);
  const [Nodes, setNodes] = useState([]);
  const [Edges, setEdges] = useState([]);

  useEffect(() => {
    RenderNode();
  }, [dataModelOutput, theme]);

  const RenderNode = () => {
    if (
      !ObjectUtils.isEmpty(dataModelOutput) &&
      ArrayUtils.isArray(dataModelOutput.dataModelEntities)
    ) {
      let nodes = [];
      let edges = [];
      for (const entity of dataModelOutput.dataModelEntities) {
        const node = {
          //노드 정보
          id: entity.physEntityNm,
          type: "entity", //EntityNode.js 에 정의한 내용. EntityFlowEditor에 Custom Type으로 선언
          position: entity.position,
          data: {},
        };
        node.data.entity = entity; //실제 엔티티 정보
        nodes.push(node);
        const { relation: relations } = entity;

        if (ArrayUtils.isArray(relations) && relations.length > 0) {
          for (const relation of relations) {
            let edge = {
              // id: `edge-from-${from.entity.physEntityNm}-to-${entity.physEntityNm}`,
              id: StringUtils.getUuid(),
              target: relation.targetEntity,
              source: entity.physEntityNm,
              type: "floating",
              animated: true,
              data: {
                text: relation.fetch,
                relation: relation,
                entity: entity,
              },
              // markerEnd: "logo",
              // markerStart: "logo",
              markerEnd: {
                type: MarkerType.ArrowClosed,
                width: 8,
                height: 8,
                color: theme === "light" ? "dodgerblue" : "#60b9c4",
              },
              style: {
                strokeWidth: 4,
                stroke: theme === "light" ? "dodgerblue" : "#60b9c4",
              },
            };

            edges.push(edge);
          }
        }
      }

      setNodes(nodes);
      setEdges(edges);
    }
  };

  return [Nodes, Edges];
}

export default useFlowRender;
