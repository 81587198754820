import { memo } from "react";
import { useDispatch } from "react-redux";
import { Handle, Position, useNodes, useNodesState, useStore } from "reactflow";
import { useSelector } from "react-redux";
import ErdReduxHelper from "components/builder/erd/editor/helper/ErdReduxHelper";
import { ErdPopupSize } from "components/builder/erd/ErdBuilder";
import { stopEvent } from "components/builder/ui/editor/handler/UIEditorEventHandler";
import Popup from "components/common/Popup";
import TableRegisterPopup from "page/popup/erd/TableRegisterPopup";
import { AiOutlineApi, AiOutlineClose, AiOutlineEdit } from "react-icons/ai";
import TableRelationPopup from "page/popup/erd/TableRelationPopup";
import { useState } from "react";
import { useRef } from "react";
import CommonUtils, {
  JsonUtils,
  ObjectUtils,
} from "components/common/utils/CommonUtils";
import { Button } from "react-bootstrap";
import { CircularProgress, Tooltip } from "@mui/material";
import { BiNotepad } from "react-icons/bi";
import { RiDeleteBin6Line } from "react-icons/ri";
import produce from "immer";
import { MdUnfoldLess, MdUnfoldMore } from "react-icons/md";
import Message from "components/common/Message";
import TableRelationListPopup from "page/popup/erd/TableRelationListPopup";
import useErdTableListState from "../editor/render/useErdTableListState";
import useErdCallback from "../editor/render/useErdCallback";
import { Enums } from "components/builder/BuilderEnum";

export const ErdTableType = memo(({ data, id, selected, ...props }) => {
  const dispatch = useDispatch();
  const erd = useSelector((state) => state.erd);
  const [tableList, areaList] = useErdTableListState();
  const zoom = useStore((s) => s.transform[2]);
  const [isLoading, setIsLoading] = useState(false);
  const [relationCallback] = useErdCallback();
  //Memo
  const [isMemoExpand, setIsMemoExpand] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const memoRef = useRef();

  /**
   * 테이블 보기 상세(더블클릭)
   * @param {*} e
   */
  const onDetailTableConfig = (e) => {
    stopEvent(e);
    const callback = (info) => {
      ErdReduxHelper.updateNodes(dispatch, [info], erd);
      Popup.close();

      const prevNode = JsonUtils.findNode(
        erd.output.areas,
        "physicalTableNm",
        info.propertyValue.physicalTableNm
      );
      if (!ObjectUtils.isEmpty(prevNode)) {
        Message.alert("이미 등록된 테이블 입니다.", Enums.MessageType.WARN);
      } else {
        ErdReduxHelper.updateNodes(dispatch, [info], erd);
        Popup.close();
      }
    };
    Popup.open(<TableRegisterPopup tableInfo={data} callback={callback} />, {
      style: { content: { width: ErdPopupSize.Register } },
    });
  };

  /**
   * 메모 펼치기
   * @param {*} e
   */
  const onExpandNote = (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    setIsMemoExpand(!isMemoExpand);
  };

  /**
   * 테이블 삭제
   */
  const onClickRemove = () => {
    Message.confirm("테이블을 삭제하시겠습니까?", () => {
      ErdReduxHelper.deleteTable(dispatch, data, erd);
    });
  };

  /**
   * 관계 설정 하는 팝업 오픈
   */
  const onClickRelation = () => {
    /**
     * 리스트 팝업 콜백
     * @param {*} relationList
     */
    const listCallback = (relationList) => {
      const newTable = produce(data, (draft) => {
        draft.relation = relationList;
      });
      ErdReduxHelper.updateNodes(dispatch, [newTable], erd);
      Popup.close();
    };
    Popup.open(
      <TableRelationListPopup
        erd={erd}
        table={data}
        tableList={tableList}
        areaList={areaList}
        relationCallback={relationCallback}
        callback={listCallback}
      />,
      {
        style: { content: { width: "550px" } },
      }
    );
  };

  /**
   * 엔티티 접기
   * @param {*} e
   * @param {*} fold
   */
  const onClickFold = (e, fold) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    const newNode = produce(data, (draft) => {
      if (!draft.remark) draft.remark = {};
      draft.remark.fold = fold;
    });
  };

  const renderMemoComponent = () => {
    return (
      <div
        className={`entity-memo ${isMemoExpand ? "" : "hidden"}`}
        onClick={stopEvent}
      >
        <div className="header">
          <button
            onClick={(e) => {
              stopEvent(e);
              setIsMemoExpand(false);
            }}
          >
            <AiOutlineClose />
          </button>
        </div>
        <div className="description-wrapper">
          {isEditMode ? (
            <textarea
              className="description nodrag"
              defaultValue={data.memo?.description || ""}
              ref={memoRef}
              // onBlur={onBlurDescription}
              onClick={stopEvent}
              onScroll={stopEvent}
              onScrollCapture={stopEvent}
            />
          ) : (
            <div
              className="description"
              onClick={stopEvent}
              onDoubleClick={(e) => {
                setIsEditMode(true);
              }}
            >
              {data.memo?.description || ""}
            </div>
          )}
        </div>
        <div className="button-wrapper">
          <div className="memo-info">
            <span>{data.memo?.author || ""}</span>
            <span>{CommonUtils.getDate(data.memo?.time)}</span>
          </div>
          <div>
            {isEditMode ? (
              <>
                <Button
                  size={"sm"}
                  variant="outline-primary"
                  // onClick={onConfirmMemo}
                >
                  확인
                </Button>
                <Button
                  size={"sm"}
                  style={{ marginLeft: "3px" }}
                  variant="outline-danger"
                  onClick={(e) => setIsEditMode(false)}
                >
                  취소
                </Button>
              </>
            ) : (
              <Button
                size={"sm"}
                variant="outline-success"
                onClick={(e) => {
                  stopEvent(e);
                  setIsEditMode(true);
                }}
              >
                수정
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderToolbar = () => {
    return (
      <div className={`erd-toolbar ${false ? "mini" : ""}`}>
        <span />
        {renderMemoComponent()}
        <div className="side right">
          <Tooltip title="메모 작성" placement="left">
            <span onClick={onExpandNote}>
              <BiNotepad size={20 / zoom < 10 ? 15 : 20 / zoom} />
            </span>
          </Tooltip>
          {isLoading ? (
            <div>
              <CircularProgress size={20} />
            </div>
          ) : (
            <Tooltip title="엔티티 삭제" placement="top">
              <span onClick={onClickRemove}>
                <RiDeleteBin6Line size={20 / zoom < 10 ? 15 : 20 / zoom} />
              </span>
            </Tooltip>
          )}
          <Tooltip title="관계 설정" placement="top">
            <span onClick={onClickRelation}>
              <AiOutlineApi size={20 / zoom < 10 ? 15 : 20 / zoom} />
            </span>
          </Tooltip>
          {data.fold ? (
            <Tooltip title={"펼치기"} placement="right">
              <span onClick={(e) => onClickFold(e, data.fold ? false : true)}>
                <MdUnfoldMore size={20 / zoom < 10 ? 15 : 20 / zoom} />
              </span>
            </Tooltip>
          ) : (
            <Tooltip title={"접기"} placement="right">
              <span onClick={(e) => onClickFold(e, data.fold ? false : true)}>
                <MdUnfoldLess size={20 / zoom < 10 ? 15 : 20 / zoom} />
              </span>
            </Tooltip>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      {selected && renderToolbar()}
      <div
        className={`erd-table-wrapper ${selected ? "selected" : ""}`}
        onDoubleClick={onDetailTableConfig}
      >
        <div className="toolbar-button"></div>
        <div className="title">
          <div>{data.propertyValue.physicalTableNm}</div>
          <div>{data.propertyValue.logicalTableNm}</div>
        </div>
        <div className="header">
          <div className="cell physical-name">KEY</div>
          <div className="cell physical-name">물리명</div>
          <div className="cell logical-name">논리명</div>
          <div className="cell logical-name">데이터 타입</div>
        </div>
        <div className="body">
          {data.propertyValue.columnList.map((column, index) => {
            const isFk = column.relationFrom ? true : false;
            return (
              <div className="body-row" key={column.physicalName + index}>
                <div className="cell">{column.pkYn === "Y" ? "🔑" : ""}</div>
                <div className="cell">
                  {column.physicalName}
                  {isFk && ` (FK)`}
                </div>
                <div className="cell">{column.logicalName}</div>
                <div className="cell">{column.dataType}</div>
              </div>
            );
          })}
        </div>
        <span className="handle-wrapper">
          <Handle
            type="source"
            className="targetHandle"
            position={Position.Top}
            id="a"
          />
          <Handle
            type="source"
            className="targetHandle"
            position={Position.Left}
            id="b"
          />
          <Handle
            type="source"
            className="targetHandle"
            position={Position.Right}
            id="c"
          />

          <Handle
            type="source"
            className="targetHandle"
            position={Position.Bottom}
            id="d"
          />
        </span>
      </div>
    </>
  );
});

export const ErdAreaTemplateType = memo(({ data, id, selected, ...props }) => {
  const { name, alias } = data;
  return (
    <>
      <div>
        <div className="header">{`영역명 : ${name} (${alias}) `}</div>
      </div>
    </>
  );
});

/**
 * 커넥터 타입
 * @param {data:Object,id:String,selected:Boolean}
 */
export const ErdConnectorNodeType = memo(({ data: { ...connector } }) => {
  const dispatch = useDispatch();
  const erd = useSelector((state) => state.erd);

  const nodes = useNodes();

  /**
   * 커넥터 삭제
   */
  const onDeleteConnector = () => {
    ErdReduxHelper.deleteConnector(dispatch, connector, erd);
  };

  const onEditConnect = (e) => {
    const sourceTable = nodes.find((t) => t.id === connector.from);
    const targetTable = nodes.find((t) => t.id === connector.to);

    const { joinColumns } = connector;

    const callback = (relationType, joinColumns, props) => {
      const newConnector = { ...connector, relationType, joinColumns };
      ErdReduxHelper.updateNodes(dispatch, [newConnector], erd);
      Popup.close();
    };

    Popup.open(
      <TableRelationPopup
        callback={callback}
        sourceTable={sourceTable}
        targetTable={targetTable}
        joinColumnInfo={joinColumns}
      />,
      {
        style: { content: { width: "600px" } },
      }
    );
  };

  return (
    <div className={`erd-connector `}>
      <div className={`workflow-connector-node-wrapper no-property`}>
        <button onClick={onEditConnect}>
          <AiOutlineEdit size={20} color="green" />
        </button>
        <button onClick={onDeleteConnector}>
          <AiOutlineClose size={20} />
        </button>
      </div>

      <span className="handle-wrapper">
        <Handle
          type="source"
          className="targetHandle"
          position={Position.Top}
          id="a"
        />
        <Handle
          type="source"
          className="targetHandle"
          position={Position.Left}
          id="b"
        />
        <Handle
          type="source"
          className="targetHandle"
          position={Position.Right}
          id="c"
        />

        <Handle
          type="source"
          className="targetHandle"
          position={Position.Bottom}
          id="d"
        />
      </span>
    </div>
  );
});
