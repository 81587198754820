import React, { useRef } from "react";
import { useDrag } from "react-dnd";
import produce from "immer";

import UIEditorDNDGridContainer from "components/builder/ui/editor/UIEditorDNDContainer";
import * as Enums from "components/builder/BuilderEnum";
import {
  ArrayUtils,
  ObjectUtils,
  StringUtils,
  NumberUtils,
} from "components/common/utils/CommonUtils";
import UIEditorGridColumn from "components/builder/ui/editor/render/UIEditorGridColumn";
import Grid from "components/builder/ui/uiComponents/grid/Grid";
import GridHeader from "components/builder/ui/uiComponents/grid/GridHeader";
import GridCell from "components/builder/ui/uiComponents/grid/GridCell";
import { useNavigate } from "react-router-dom";

const style = {};
const UIEditorGrid = (props) => {
  const { data, handleDrop, path, templateComponents, rootLocation } = props;
  const ref = useRef(null);
  const navigate = useNavigate();

  const item = produce(data, (draft) => {
    draft.path = path;
    draft.rootLocation = rootLocation;
  });
  const [{ isDragging }, drag] = useDrag({
    item: item,
    type: item.type,
    //canDrag: canDrag
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(ref);

  const DNDContainer = props.dndContainer
    ? props.dndContainer
    : UIEditorDNDGridContainer;

  let gridColumns =
    !ObjectUtils.isEmpty(item.propertyValue) &&
    !ObjectUtils.isEmpty(item.propertyValue.gridOptions) &&
    !ArrayUtils.isEmpty(item.propertyValue.gridOptions.columns)
      ? item.propertyValue.gridOptions.columns
      : [];

  let gridOptions =
    !ObjectUtils.isEmpty(item.propertyValue) &&
    !ObjectUtils.isEmpty(item.propertyValue.gridOptions)
      ? item.propertyValue.gridOptions
      : {};

  /**
   * wild character with 에 대한 계산
   * @param {*} width
   * @returns
   */
  const getWildNumber = (width) => {
    let wildNumber = 1;
    let colWildWidth = StringUtils.substringBefore(width, "*");
    if (NumberUtils.isNumeric(colWildWidth, "5")) {
      if (Number(colWildWidth) > 5) {
        wildNumber = 5;
      } else {
        wildNumber = Number(colWildWidth);
      }
    }
    return wildNumber;
  };

  // wildcharater(*)에 대한 width를 계산한다.
  let wildWidth = 0;
  if (!ArrayUtils.isEmpty(gridColumns)) {
    //width가 '*'인 column count
    //분할 가능한 width
    let wildWidthCnt = 0;
    let divisibleWidth = 100;
    gridColumns.map((column, index) => {
      if (column.visible !== false) {
        if (StringUtils.lastChar(column.width) === "*") {
          if (column.width.length === 1) {
            wildWidthCnt++;
          } else {
            wildWidthCnt = wildWidthCnt + getWildNumber(column.width);
          }
        } else if (StringUtils.lastChar(column.width) === "%") {
          let width = StringUtils.substringBefore(column.width, "%");
          if (
            NumberUtils.isNumeric(width, "5") &&
            Number(width) < 100 &&
            Number(width) < divisibleWidth
          ) {
            divisibleWidth = divisibleWidth - Number(width);
          } else {
            wildWidthCnt++;
          }
        }
      }
    });
    if (divisibleWidth > 0 && wildWidthCnt > 0) {
      wildWidth = divisibleWidth / wildWidthCnt;
    }
  }

  return (
    <div ref={ref} className="whole-wrap">
      <Grid
        style={{ ...style, opacity }}
        componentInfo={item}
        event="renderEditor"
        navigate={navigate}
      >
        <div className="editor-grid-wrap pass-focus">
          {!StringUtils.isEmpty(gridOptions.showHeader) &&
          gridOptions.showHeader > 1 ? (
            <React.Fragment>
              <div
                style={{ width: "40px", minWidth: "40px" }}
                className={"editor-grid-columns"}
              >
                <GridHeader
                  componentInfo={{
                    gridOptions: gridOptions,
                    editorAttr: {
                      className: "grid-row-header",
                      headerType: "colvis",
                    },
                    propertyValue: {
                      colvis: gridOptions.colvis,
                    },
                  }}
                  event="renderEditor"
                />
                <GridCell
                  componentInfo={{
                    gridOptions: gridOptions,
                    propertyValue: {
                      name: `${gridOptions.numbering ? 1 : ""}`,
                    },
                    editorAttr: { className: "grid-row-header" },
                  }}
                  style={{ justifyContent: "center" }}
                  event="renderEditor"
                />
              </div>
            </React.Fragment>
          ) : (
            ""
          )}

          {StringUtils.isEmpty(gridOptions.selectionType) ||
          gridOptions.selectionType === "N" ? (
            ""
          ) : (
            <React.Fragment>
              <div
                style={{ width: "40px", minWidth: "40px" }}
                className={"editor-grid-columns"}
              >
                <GridHeader
                  componentInfo={{
                    gridOptions: gridOptions,
                    editorAttr: {
                      checkbox: true,
                      className: "grid-row-header",
                    },
                  }}
                  event="renderEditor"
                />
                <GridCell
                  componentInfo={{
                    gridOptions: gridOptions,
                    editorAttr: {
                      checkbox: true,
                      className: "grid-row-header",
                    },
                  }}
                  style={{ justifyContent: "center" }}
                  event="renderEditor"
                />
              </div>
            </React.Fragment>
          )}

          {!ArrayUtils.isEmpty(gridColumns)
            ? gridColumns.map((column, index) => {
                const currentPath = `${path}-${index}`;
                let columnData = { propertyValue: column };
                columnData.type = Enums.ComponentType.GRID_COLUMN;
                columnData.name = column.name;
                let columnWidth;
                let columnMinWidth;

                if (column.visible === false) {
                  columnWidth = "80px";
                  columnMinWidth = "80px";
                } else {
                  if (StringUtils.lastChar(column.width) === "*") {
                    columnWidth = getWildNumber(column.width) * wildWidth + "%";
                    columnMinWidth =
                      "calc( 100px * " + getWildNumber(column.width) + ")";
                    // columnMinWidth =
                    //   "calc( 1000px * " +
                    //   getWildNumber(column.width) * wildWidth * 0.01 +
                    //   ")";
                  } else {
                    columnWidth = column.width + "px";
                    columnMinWidth = column.width + "px";
                  }
                }

                let columnStyle = {
                  width: columnWidth,
                  minWidth: columnMinWidth,
                };
                return (
                  <React.Fragment key={index}>
                    <DNDContainer
                      data={{
                        location: Enums.ComponentType.GRID,
                        rootLocation: rootLocation,
                        path: currentPath,
                        childrenCount: gridColumns.length,
                      }}
                      onDrop={handleDrop}
                      templateComponents={templateComponents}
                      className="horizontal-drag"
                    />
                    <UIEditorGridColumn
                      className={`editor-grid-columns draggable ${
                        index === gridColumns.length - 1 ? "last-column" : ""
                      }`}
                      style={columnStyle}
                      data={columnData}
                      path={currentPath}
                      rootLocation={rootLocation}
                      gridOptions={gridOptions}
                    />
                  </React.Fragment>
                );
              })
            : ""}
          <DNDContainer
            data={{
              location: Enums.ComponentType.GRID,
              rootLocation: rootLocation,
              path: `${path}-${gridColumns.length}`,
              childrenCount: gridColumns.length,
            }}
            onDrop={handleDrop}
            templateComponents={templateComponents}
            className={`horizontal-drag ${
              ArrayUtils.isEmpty(gridColumns) ? "horizontal-drag-full" : ""
            }`}
          />
        </div>
      </Grid>
    </div>
  );
};
export default React.memo(UIEditorGrid);
