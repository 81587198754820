import { Enums } from "components/builder/BuilderEnum";
import Message from "components/common/Message";
import Modal from "components/common/modal/UModal";
import UModalTemplate from "components/common/modal/UModalTemplate";
import Popup from "components/common/Popup";
import StringUtils from "components/common/utils/StringUtils";
import React from "react";
import { useRef } from "react";
import { useState } from "react";
import { Form } from "react-bootstrap";
import DataModelService from "services/datamodel/DataModelService";

function DataModelParameterInputPopup({
  entity: {
    procedureName = "",
    parameterList: _parameterList = [],
    entityType,
    physEntityNm: _physEntityNm,
    logEntityNm: _logEntityNm,
  },
  onOk,
  connection,
  output,
  ...props
}) {
  const [isLoading, setIsLoading] = useState(false);
  const physEntityNmRef = useRef();
  const logEntityNmRef = useRef();
  const [parameterList, setParameterList] = useState(
    _parameterList
      ? _parameterList.map((param) => ({
          parameterName: param.parameterName,
          parameterType: param.parameterType,
          parameterValue: "",
          parameterOrder: param.parameterOrder,
        }))
      : []
  );

  const onChangeParameter = (e) => {
    const id = e.target.id;
    const v = e.target.value;
    const newParameterInput = [...parameterList];
    const isExistIdx = newParameterInput.findIndex(
      (param) => param.parameterName === id
    );
    let value = v;

    //데이터 타입이 날짜 일때 숫자만 넣으면 문자로 바꿔줘야함
    if (
      StringUtils.equalsIgnoreCase(
        newParameterInput[isExistIdx].parameterType,
        "datetime"
      ) &&
      typeof value === "number"
    ) {
      value = value.toString();
    }

    if (isExistIdx > -1) {
      newParameterInput[isExistIdx].parameterValue = value;
    }
    setParameterList(newParameterInput);
  };

  const onClickConfirm = () => {
    const physEntityNm = physEntityNmRef.current.value;
    const logEntityNm = logEntityNmRef.current.value;
    if (StringUtils.isEmpty(physEntityNm)) {
      return Message.alert("물리 명을 입력해주세요.", Enums.MessageType.INFO);
    }
    if (StringUtils.isEmpty(logEntityNm)) {
      return Message.alert("논리 명을 입력해주세요.", Enums.MessageType.INFO);
    }

    setIsLoading(true);
    const body = {
      ...connection,
      accessToken: connection.token,
      entityType,
      procedureName,
      parameterList,
    };
    DataModelService.getProcedureEntity(
      body,
      (res) => {
        setIsLoading(false);
        if (res.data) {
          const resultProcedureEntity = res.data;
          resultProcedureEntity.parameterList = parameterList;
          resultProcedureEntity.physEntityNm = physEntityNm;
          resultProcedureEntity.logEntityNm = logEntityNm;
          if (onOk) {
            onOk(res.data);
            Popup.close();
          }
        }
      },
      (err) => {
        setIsLoading(false);
      }
    );
  };
  return (
    <Modal>
      <Modal.Header title={`${procedureName} 엔티티 설정`} />
      <Modal.Body>
        <UModalTemplate className={"parameter_input_popup_body"}>
          <Form.Group className="mt-3 ">
            <Form.Label>
              {StringUtils.equalsIgnoreCase("procedure", entityType)
                ? "프로시져"
                : "함수"}{" "}
              명
            </Form.Label>
            <Form.Control defaultValue={procedureName} readOnly disabled />
          </Form.Group>
          <Form.Group className="mt-3">
            <Form.Label className="required">물리 명</Form.Label>
            <Form.Control defaultValue={_physEntityNm} ref={physEntityNmRef} />
          </Form.Group>
          <Form.Group className="mt-3">
            <Form.Label className="required">논리 명</Form.Label>
            <Form.Control defaultValue={_logEntityNm} ref={logEntityNmRef} />
          </Form.Group>
          <Form.Group className="mt-3">
            <Form.Label>파라미터 입력</Form.Label>
            <div className="parameter_list_wrapper">
              <div className="parameter_row">
                <div className="parameter_col">파라미터 명</div>
                <div className="parameter_col">데이터 타입</div>
                <div className="parameter_col">입력 최대 길이</div>
                <div className="parameter_col">사용자 입력</div>
              </div>
              {_parameterList.map((parameter, idx) => {
                const inputLength =
                  parameter.parameterType.indexOf("char") === -1
                    ? parameter.numericLength
                    : parameter.characterLength;

                const _parameterType = String(
                  parameter.parameterType
                ).toLowerCase();
                const IsNumberType =
                  _parameterType.indexOf("int") > -1 ||
                  _parameterType.indexOf("number") > -1 ||
                  _parameterType.indexOf("long") > -1 ||
                  _parameterType.indexOf("double") > -1 ||
                  _parameterType.indexOf("float") > -1;

                return (
                  <div className="parameter_row" key={parameter.parameterName}>
                    <div className="parameter_col">
                      {parameter.parameterName}
                    </div>
                    <div className="parameter_col">
                      {parameter.parameterType}
                    </div>
                    <div className="parameter_col">{inputLength}</div>
                    <div className="parameter_col">
                      <input
                        id={parameter.parameterName}
                        onChange={onChangeParameter}
                        value={parameterList[idx].parameterValue}
                        type={IsNumberType ? "number" : "text"}
                        maxLength={inputLength}
                        placeholder={"입력.."}
                        onKeyDown={(e) => e.keyCode === 13 && onClickConfirm()}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </Form.Group>

          <div style={{ color: "tomato" }}>
            ※ 입력된 파라미터는 엔티티 구성을 위한 참고 용도 입니다.
          </div>
          <div style={{ color: "tomato" }}>
            ※ 파라미터를 입력하지 않는 경우에는 공백("") 으로 입력됩니다.
          </div>
          <div style={{ color: "tomato" }}>
            ※ NULL 값을 입력하고 싶은 경우에는 NULL 을 직접 입력하여야 합니다.
            (따옴표 제외)
          </div>
          {/* {_parameterList.findIndex(
            (parameter) => parameter.parameterName === "tenantId"
          ) === -1 &&
            parameterList.findIndex(
              (parameter) => parameter.parameterName === "coCd"
            ) === -1 && (
              <div>
                <span className="blink" style={{ color: "tomato" }}>
                  ※ 프로시저의 파라미터 구성에는 tenantId 와 coCd가 반드시
                  포함되어있어야 합니다.
                </span>
              </div>
            )} */}
        </UModalTemplate>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.ProgressButton
          doing={isLoading}
          doingText="불러오는 중"
          onClick={onClickConfirm}
        >
          실행
        </Modal.Footer.ProgressButton>
        {/* {_parameterList.findIndex(
          (parameter) => parameter.parameterName === "tenantId"
        ) > -1 &&
        _parameterList.findIndex(
          (parameter) => parameter.parameterName === "coCd"
        ) > -1 ? (
          <Modal.Footer.ProgressButton
            doing={isLoading}
            doingText="불러오는 중"
            onClick={onClickConfirm}
          >
            실행
          </Modal.Footer.ProgressButton>
        ) : (
          <Modal.Footer.Button variant="danger">생성 불가</Modal.Footer.Button>
        )} */}
      </Modal.Footer>
    </Modal>
  );
}

export default DataModelParameterInputPopup;
