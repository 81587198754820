import { Autocomplete, Grid, TextField, Tooltip } from "@mui/material";
import { Enums } from "components/builder/BuilderEnum";
import USelectbox from "components/common/element/USelectbox";
import Message from "components/common/Message";
import Modal from "components/common/modal/UModal";
import UModalTemplate from "components/common/modal/UModalTemplate";
import Popup from "components/common/Popup";
import ArrayUtils from "components/common/utils/ArrayUtils";
import ObjectUtils from "components/common/utils/ObjectUtils";
import StringUtils from "components/common/utils/StringUtils";
import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";

function RelationSetPopup({
  sourceEntity,
  targetEntity,
  fetchType,
  onSaveRelation,
  onRemoveRelation: _onRemoveRelation,
  relation = {},
  ...props
}) {
  const [type, setType] = useState(relation.type || "oneToMany");
  const [fetch, setFetch] = useState(relation.fetch || fetchType);
  const [cascade, setCascade] = useState(relation.cascade || "ALL");
  const [joinType, setJoinType] = useState(
    relation.joinType ||
      (StringUtils.equalsIgnoreCase(fetchType, "EAGER") ? "INNER" : null)
  );
  const [joinColumn, setJoinColumn] = useState(relation.joinColumn || []);
  // const virtualReleation = useRef(relation.virtualReleation || {});

  const [virtualRelation, setVirtualRelation] = useState(
    relation.virtualRelation || {}
  );

  const [optionType, setOptionType] = useState([]);
  const [optionFetch, setOptionFetch] = useState([]);
  const [optionCascade, setOptionCascade] = useState([]);
  const [optionJoinType, setOptionJoinType] = useState([]);

  const [sourceField, setSourceField] = useState({});
  const [targetField, setTargetField] = useState({});
  const [isCollapseColumnChoice, setisCollapseColumnChoice] = useState(true);

  useEffect(() => {
    getRelationConfig();
  }, []);

  const getRelationConfig = () => {
    setOptionType([
      { value: "oneToMany", text: "One To Many" },
      { value: "manyToOne", text: "Many To One" },
      { value: "manyToMany", text: "Many To Many" },
    ]);
    setOptionFetch([
      { value: "LAZY", text: "LAZY" },
      { value: "EAGER", text: "EAGER" },
    ]);
    setOptionCascade([
      { value: "ALL", text: "ALL" },
      { value: "PERSIST", text: "PERSIST" },
      { value: "REMOVE", text: "REMOVE" },
    ]);
    setOptionJoinType([
      { value: "INNER", text: "INNER" },
      { value: "LEFT_INNER", text: "LEFT INNER" },
      { value: "RIGHT_INNER", text: "RIGHT INNER" },
      { value: "OUTER", text: "OUTER" },
      { value: "LEFT_OUTER", text: "LEFT OUTER" },
      { value: "RIGHT_OUTER", text: "RIGHT OUTER" },
    ]);
  };

  /**
   * Fetch change 이벤트
   * @param {*} e
   */
  const onChangeFetch = (e) => {
    const { value } = e.target;

    setFetch(value);
    if (value === "LAZY") {
      setJoinType("");
    } else if (value === "EAGER") {
      setJoinType("INNER");
    }
  };

  /**
   * 조인 단일 삭제
   * @param {*} e
   * @param {*} idx
   */
  const onRemoveJoin = (e, idx) => {
    const newJoin = [...joinColumn];
    newJoin.splice(idx, 1);
    setJoinColumn(newJoin);
    e.preventDefault();
  };

  /**
   * 자동 조인
   */
  const onAutoJoin = () => {
    const newJoinColumn = [...joinColumn];
    const newVirtual = { ...virtualRelation };
    const sourceFields = sourceEntity.dataModelEntityFields;
    const targetFields = targetEntity.dataModelEntityFields;

    const sourceKeyFields = sourceFields.filter((field) => field.pkYn === "Y");
    const targetKeyFields = targetFields.filter((field) => field.pkYn === "Y");

    for (const source of sourceKeyFields) {
      let targetExist = targetKeyFields.find((field) =>
        StringUtils.equalsIgnoreCase(field.columnNm, source.columnNm)
      );
      if (targetExist) {
        const isDup = newJoinColumn.find((join) => join[source.columnNm]);
        if (!isDup) {
          newJoinColumn.push({ [source.columnNm]: targetExist.columnNm });
          newVirtual[source.columnNm] = targetExist.columnNm;
        }
      }
    }

    setJoinColumn(newJoinColumn);
    setVirtualRelation(newVirtual);
  };

  const onAddJoinColumn = (e) => {
    if (e) e.preventDefault();
    if (ObjectUtils.isEmpty(sourceField))
      return Message.alert("참조 필드를 선택해주세요.", Enums.MessageType.INFO);
    if (ObjectUtils.isEmpty(targetField))
      return Message.alert("대상 필드를 선택해주세요.", Enums.MessageType.INFO);

    const newJoinColumn = [...joinColumn];
    const newVirtualRelation = { ...virtualRelation };
    const isDup = newJoinColumn.findIndex(
      (field) => field[sourceField.columnNm]
    );
    if (isDup > -1) {
      newJoinColumn.splice(isDup, 1);
    }
    newJoinColumn.push({ [sourceField.columnNm]: targetField.columnNm });
    newVirtualRelation[sourceField.columnNm] = targetField.columnNm;
    setJoinColumn(newJoinColumn);
    setVirtualRelation(newVirtualRelation);
  };

  const onOk = (e) => {
    if (StringUtils.isEmpty(type))
      return Message.alert("TYPE 를 설정해주세요.", Enums.MessageType.INFO);

    if (StringUtils.isEmpty(fetch))
      return Message.alert("Fetch 를 설정해주세요.", Enums.MessageType.INFO);
    if (StringUtils.isEmpty(cascade))
      return Message.alert("Cascade 를 설정해주세요.", Enums.MessageType.INFO);
    if (fetch === "EAGER" && StringUtils.isEmpty(joinType))
      return Message.alert(
        "JOIN TYPE 를 설정해주세요.",
        Enums.MessageType.INFO
      );
    if (ArrayUtils.isEmpty(joinColumn))
      return Message.alert(
        "조인 필드를 추가 해주세요.",
        Enums.MessageType.INFO
      );

    const newRelation = {
      ...relation,
      type,
      fetch,
      cascade,
      joinType,
      joinColumn,
      virtualRelation,
      targetEntity: targetEntity.physEntityNm,
      targetTable: targetEntity.tableNm,
    };
    if (fetch === "LAZY") {
      delete newRelation.joinType;
    }
    if (onSaveRelation) {
      onSaveRelation(newRelation);
    }
    Popup.close();
  };

  /**
   * 참조삭제
   */
  const onRemoveRelation = () => {
    Message.confirm("해당 참조를 삭제하시겠습니까?", () => {
      if (_onRemoveRelation) _onRemoveRelation();
      Popup.close();
    });
  };

  return (
    <Modal>
      <Modal.Header title="관계 설정" />
      <Modal.Body>
        <UModalTemplate>
          <Form as={Row}>
            {/* <Col xs={6}>
              <Form.Group>
                <Form.Label className="required">TYPE</Form.Label>
                <USelectbox
                  id="type"
                  onChange={(e) => setType(e.target.value)}
                  defaultValue={type}
                  items={optionType}
                  options={{ matchCd: "value", matchNm: "text" }}
                />
              </Form.Group>
            </Col> */}
            <Col xs={6}>
              <Form.Label className="required">Fetch</Form.Label>
              <USelectbox
                id="fetch"
                onChange={onChangeFetch}
                defaultValue={fetch}
                items={optionFetch}
                options={{ matchCd: "value", matchNm: "text" }}
              />
            </Col>
            {/* <Col xs={6}>
              <Form.Label className="required">Cascade</Form.Label>
              <USelectbox
                id="cascade"
                onChange={(e) => setCascade(e.target.value)}
                defaultValue={cascade}
                items={optionCascade}
                options={{ matchCd: "value", matchNm: "text" }}
              />
            </Col> */}
            <Col xs={6}>
              {fetch === "EAGER" && (
                <>
                  <Form.Label className="required">Join Type</Form.Label>
                  <USelectbox
                    id="fetch"
                    onChange={(e) => setJoinType(e.target.value)}
                    defaultValue={joinType}
                    items={optionJoinType}
                    options={{ matchCd: "value", matchNm: "text" }}
                  />
                </>
              )}
            </Col>
            <Form.Group className="mt-3" as={Col} xs={12}>
              <Form.Label>Join Field</Form.Label>
              <div className="relation-table">
                <div className="relation-row relation-header">
                  <div className="columnNm">From</div>
                  <div className="columnNm">To</div>
                  <div className="button" />
                </div>
                {joinColumn.map((_join, idx) => {
                  const from = Object.keys(_join)[0];
                  const to = _join[from];
                  return (
                    <div className="relation-row" key={from}>
                      <div className="columnNm">
                        {sourceEntity.physEntityNm}.<strong>{from}</strong>
                      </div>
                      <div className="columnNm">
                        {targetEntity.physEntityNm}.<strong>{to}</strong>
                      </div>
                      <div className="button">
                        {virtualRelation[from] === to && (
                          <Button
                            variant="outline-danger"
                            size="sm"
                            onClick={(e) => onRemoveJoin(e, idx)}
                          >
                            삭제
                          </Button>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </Form.Group>
            <Col
              xs={12}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Tooltip
                title={
                  <>
                    Source필드와 Target 필드의 칼럼명이 동일하고
                    <br />
                    양쪽다 키값(PK) 인 경우 자동으로 연결합니다.
                  </>
                }
                placement="left"
              >
                <Button onClick={onAutoJoin}>필드 자동 조인</Button>
              </Tooltip>
            </Col>
            <Col xs={12}>
              <Form.Label>
                Join Field Choice{" "}
                <span
                  style={{ cursor: "pointer" }}
                  onClick={(e) =>
                    setisCollapseColumnChoice(!isCollapseColumnChoice)
                  }
                >
                  {isCollapseColumnChoice ? "▲" : "▼"}
                </span>
              </Form.Label>
              {isCollapseColumnChoice ? (
                <Row className="gy-3 join-field-choice">
                  <Col xs={3} className="join-entity-name">
                    <span>{sourceEntity.physEntityNm}</span>
                  </Col>
                  <Col xs={7}>
                    <FieldSearcher
                      onChange={setSourceField}
                      fieldList={sourceEntity.dataModelEntityFields}
                    />
                  </Col>
                  <Col xs={2} className="join-from">
                    에서
                  </Col>
                  <Col className="join-entity-name" xs={3}>
                    <span>{targetEntity.physEntityNm}</span>
                  </Col>
                  <Col xs={7}>
                    <FieldSearcher
                      onChange={setTargetField}
                      fieldList={targetEntity.dataModelEntityFields}
                    />
                  </Col>
                  <Col xs={2} className="join-from">
                    <Button variant="outline-primary" onClick={onAddJoinColumn}>
                      참조 추가
                    </Button>
                  </Col>
                </Row>
              ) : (
                <></>
              )}
            </Col>
          </Form>
        </UModalTemplate>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Button onClick={onOk}>확인</Modal.Footer.Button>
        {!ObjectUtils.isEmpty(relation) && (
          <Modal.Footer.Button
            side="left"
            variant="danger"
            onClick={onRemoveRelation}
          >
            참조 삭제
          </Modal.Footer.Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default RelationSetPopup;

const FieldSearcher = ({ field, fieldList, onChange: _onChange, ...props }) => {
  const onChange = (e, value) => {
    if (_onChange) _onChange(value);
  };

  return (
    <Autocomplete
      disablePortal
      options={fieldList.map((field) => {
        let obj = { ...field };
        obj.label = `${field.columnNm} `;
        if (
          field.logFieldNm &&
          !StringUtils.equalsIgnoreCase(field.columnNm, field.logFieldNm)
        ) {
          obj.label += `| ${field.physFieldNm} `;
        }
        if (field.pkYn === "Y") {
          obj.label += `| 🔑 `;
        }
        if (field.pkYn === "N" && field.requiredYn === "Y") {
          obj.label += `| ⭐ `;
        }

        return obj;
      })}
      size="small"
      onChange={onChange}
      isOptionEqualToValue={(option, value) => option.compId === value.compId}
      renderInput={(params) => <TextField {...params} label="필드명 입력" />}
    />
  );
};
