import StringUtils from "components/common/utils/StringUtils";
import ObjectUtils from "components/common/utils/ObjectUtils";
import JsonUtils from "components/common/utils/JsonUtils";
import * as Enums from "components/builder/BuilderEnum";

class NamingUtils {
  /**
   * ID 생성 규칙이 있는 Component들
   * @returns
   */
  static getCompIdRules = () => {
    return {
      [Enums.ComponentType.FORM]: NamingUtils.getFormId,
      [Enums.ComponentType.GRID]: NamingUtils.getComponentGenId,
      [Enums.ComponentType.TAB_CONTAINER]: NamingUtils.getComponentGenId,
      [Enums.ComponentType.STEP_CONTAINER]: NamingUtils.getComponentGenId,
    };
  };

  /**
   * ID 생성 규칙이 있는 Component인지 여부
   * @param {*} componentType
   * @returns
   */
  static hasIdNamingRule = (componentType) => {
    return NamingUtils.getCompIdRules()[componentType] ? true : false;
  };

  /**
   * ID 생성 규칙이 있는 Component인지 여부
   * @param {*} componentType
   * @param {*} page
   * @param {*} compPropertyValue
   * @param {*} ....
   * @returns
   */
  static getComponentId = (...args) => {
    //argument 1,2,3 필수
    let componentType = args[0];
    let page = args[1];
    //let compPropertyValue = args[2];

    if (
      ObjectUtils.isEmpty(page.propertyValue) ||
      StringUtils.isEmpty(page.propertyValue.programId)
    ) {
      return "";
    }
    if (NamingUtils.hasIdNamingRule(componentType)) {
      return NamingUtils.getCompIdRules()[componentType].apply(this, args);
    }
  };

  /**
   * Form Id 자동 생성
   * @param {Map} page
   * @param {String} propertyValue
   * @returns
   */
  static getFormId = (componentType, page, propertyValue) => {
    let formType = propertyValue.formType;
    let formId,
      templateFormId =
        page.propertyValue.programId +
        (formType === Enums.FormType.SEARCH ? "SrForm" : "Form");

    let tryCnt = 0;
    do {
      let count = this.getFormNodeCount(page, formType, 1) + tryCnt++;
      formId = count === 1 ? templateFormId : templateFormId + count;
    } while (!ObjectUtils.isEmpty(JsonUtils.findNodeById(page, formId)));

    return formId;
  };

  /**
   * Form Node 검색
   * @param {*} node
   * @param {*} formType Form 유형 (검색Form, 저장 Form)
   * @param {*} count
   * @returns
   */
  static getFormNodeCount = (node, formType, count) => {
    if (
      node.type === Enums.ComponentType.FORM &&
      node.propertyValue &&
      node.propertyValue.formType === formType
    ) {
      count++;
    }
    if (node.child) {
      node.child.map(
        (child, index) =>
          (count = this.getFormNodeCount(child, formType, count))
      );
    }
    return count;
  };

  /**
   * Component Id 자동 생성
   * @param {String} componentType
   * @param {Map} page
   * @param {String} propertyValue
   * @param {String} subfix
   * @returns
   */
  static getComponentGenId = (componentType, page, propertyValue, subfix) => {
    let newGentId,
      templateGenId = page.propertyValue.programId + subfix;
    let tryCnt = 0;
    do {
      let count = this.getNodeCountByType(page, componentType, 1) + tryCnt++;
      newGentId = count === 1 ? templateGenId : templateGenId + count;
    } while (!ObjectUtils.isEmpty(JsonUtils.findNodeById(page, newGentId)));

    return newGentId;
  };

  /**
   * Form Node 검색
   * @param {*} node
   * @param {*} componentType component 유형
   * @param {*} count
   * @returns
   */
  static getNodeCountByType = (node, componentType, count) => {
    if (node.type === componentType) {
      count++;
    }
    if (node.child) {
      node.child.map(
        (child, index) =>
          (count = this.getNodeCountByType(child, componentType, count))
      );
    }
    return count;
  };

  /**
   * output 데이터에서 element ID를 찾아 새로운 program Id Naming Rule로 업데이트
   * 기존 아이디와 새로운 아이디를 맵 Key:value로 하여 리턴
   * @param {*} output
   */
  static updateElementIdAndGetPrevData = (
    output,
    prevProgramId,
    newProgramId,
    prevElementIdObject = {}
  ) => {
    for (const k in output) {
      if (k === "type" && output.compId) {
        //type와 compId가 있으면 Component라고 판단
        if (this.hasIdNamingRule(output[k])) {
          //그리드는 다른위치
          if (output[k] === Enums.ComponentType.GRID) {
            //그리드 일때
            if (
              StringUtils.indexOf(
                output.propertyValue.gridOptions.gridId,
                prevProgramId
              ) > -1
            ) {
              const prevId = output.propertyValue.gridOptions.gridId;
              const newId = String(
                output.propertyValue.gridOptions.gridId
              ).replace(prevProgramId, newProgramId);
              prevElementIdObject[prevId] = newId;
              output.propertyValue.gridOptions.gridId = newId;
            }
          } else {
            //나머지
            if (
              StringUtils.indexOf(output.propertyValue.id, prevProgramId) > -1
            ) {
              const prevId = output.propertyValue.id;
              const newId = String(output.propertyValue.id).replace(
                prevProgramId,
                newProgramId
              );
              prevElementIdObject[prevId] = newId;
              output.propertyValue.id = newId;
            }
          }
        }
      } else if (ObjectUtils.isObject(output[k])) {
        this.updateElementIdAndGetPrevData(
          output[k],
          prevProgramId,
          newProgramId,
          prevElementIdObject
        );
      }
    }

    return [output, prevElementIdObject];
  };

  /**
   * output의 value 중 string을 검색하여 기존 아이디를 신규아이디로 전환한다.
   * 기존 아이디와 새로운 아이디를 맵 Key:value 로 받는다..
   * @param {*} idObject
   */
  static replaceOldIdToNewId(output, idObject) {
    for (const k in output) {
      if (typeof output[k] === "string") {
        for (const oldId in idObject) {
          if (String(output[k]).indexOf(oldId) > -1) {
            output[k] = String(output[k]).replaceAll(oldId, idObject[oldId]);
          }
        }
      } else {
        this.replaceOldIdToNewId(output[k], idObject);
      }
    }
  }
}

export default NamingUtils;
