/*!
 * Builder Drag And Drop Helper for react v.17
 *
 * Builder의 Drag & Drop 관련 Helper Class
 *
 *   Author: Bizentro
 *   Date: 2021-04
 */

import * as Enums from "components/builder/BuilderEnum";
import {
  StringUtils,
  ArrayUtils,
  JsonUtils,
} from "components/common/utils/CommonUtils";
import UITemplateHelper from "components/builder/ui/editor/helper/UITemplateHelper";

class UIDndHelper {
  /**
   * 신규 Column Component 생성
   * @param {*} baseColComponent
   * @param {Array} child
   * @returns
   */
  static getColStructure(baseColComponent, child) {
    return {
      compId: StringUtils.getUuid(),
      type: Enums.ComponentType.COLUMN,
      baseCompId: baseColComponent.componentDtlId,
      propertyValue: baseColComponent.propertyValue,
      viewerAttr: JsonUtils.parseJson(baseColComponent.viewerAttr),
      child: ArrayUtils.isEmpty() ? (!child ? [] : [child]) : child,
    };
  }

  /**
   * 신규 Row Component 생성
   * @param {*} baseRowComponent
   * @param {Array} child
   * @returns
   */
  static getRowStructure(baseRowComponent, child) {
    return {
      compId: StringUtils.getUuid(),
      type: Enums.ComponentType.ROW,
      baseCompId: baseRowComponent.componentDtlId,
      propertyValue: baseRowComponent.propertyValue,
      viewerAttr: JsonUtils.parseJson(baseRowComponent.viewerAttr),
      child: ArrayUtils.isEmpty() ? (!child ? [] : [child]) : child,
    };
  }
  // a little function to help us with reordering the result
  static reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed); // inserting task in new index

    return result;
  };

  static remove = (arr, index) => [
    // part of the array before the specified index
    ...arr.slice(0, index),
    // part of the array after the specified index
    ...arr.slice(index + 1),
  ];

  static insert = (arr, index, newItem) => [
    // part of the array before the specified index
    ...arr.slice(0, index),
    // inserted item
    newItem,
    // part of the array after the specified index
    ...arr.slice(index),
  ];

  static reorderChildren = (child, splitDropZonePath, splitItemPath) => {
    if (splitDropZonePath.length === 1) {
      const dropZoneIndex = Number(splitDropZonePath[0]);
      const itemIndex = Number(splitItemPath[0]);
      return UIDndHelper.reorder(
        ArrayUtils.isEmpty(child) ? [] : child,
        itemIndex,
        dropZoneIndex
      );
    }

    const updatedChildren = [...child];

    const curIndex = Number(splitDropZonePath.slice(0, 1));

    // Update the specific node's child
    const splitDropZoneChildrenPath = splitDropZonePath.slice(1);
    const splitItemChildrenPath = splitItemPath.slice(1);
    const nodeChildren = updatedChildren[curIndex];
    updatedChildren[curIndex] = {
      ...nodeChildren,
      child: UIDndHelper.reorderChildren(
        ArrayUtils.isEmpty(nodeChildren.child) ? [] : nodeChildren.child,
        splitDropZoneChildrenPath,
        splitItemChildrenPath
      ),
    };

    return updatedChildren;
  };

  static reorderGridColumn = (child, splitDropZonePath, splitItemPath) => {
    if (splitDropZonePath.length === 1) {
      const dropZoneIndex = Number(splitDropZonePath[0]);
      const itemIndex = Number(splitItemPath[0]);
      return UIDndHelper.reorder(
        ArrayUtils.isEmpty(child) ? [] : child,
        itemIndex,
        dropZoneIndex
      );
    }

    const updatedChildren = [...child];

    const curIndex = Number(splitDropZonePath.slice(0, 1));

    // Update the specific node's child
    const splitDropZoneChildrenPath = splitDropZonePath.slice(1);
    const splitItemChildrenPath = splitItemPath.slice(1);
    const nodeChildren = updatedChildren[curIndex];
    updatedChildren[curIndex] = {
      ...nodeChildren,
      child: UIDndHelper.reorderGridColumn(
        ArrayUtils.isEmpty(nodeChildren.child) ? [] : nodeChildren.child,
        splitDropZoneChildrenPath,
        splitItemChildrenPath
      ),
    };

    return updatedChildren;
  };

  /**
   * child중 특정 child를 제거한다.
   * @param {*} child
   * @param {*} splitItemPath
   * @returns
   */
  static removeChildFromChildren = (child, splitItemPath) => {
    //1level의 child 변경일 경우 child 제거
    if (splitItemPath.length === 1) {
      const itemIndex = Number(splitItemPath[0]);
      return UIDndHelper.remove(
        ArrayUtils.isEmpty(child) ? [] : child,
        itemIndex
      );
    }

    //2level 이하의 child 변경시 제거 대상 child를 가진 node를 찾아 child 삭제후 update
    const updatedChildren = [...child];

    const curIndex = Number(splitItemPath.slice(0, 1));

    // Update the specific node's child
    const splitItemChildrenPath = splitItemPath.slice(1);
    const nodeChildren = updatedChildren[curIndex];
    updatedChildren[curIndex] = {
      ...nodeChildren,
      child: UIDndHelper.removeChildFromChildren(
        nodeChildren.child,
        splitItemChildrenPath
      ),
    };

    return updatedChildren;
  };

  static addChildToChildren = (child, splitDropZonePath, item) => {
    if (splitDropZonePath.length === 1) {
      const dropZoneIndex = Number(splitDropZonePath[0]);
      return UIDndHelper.insert(
        ArrayUtils.isEmpty(child) ? [] : child,
        dropZoneIndex,
        item
      );
    }

    const updatedChildren = [...child];

    let curIndex = Number(splitDropZonePath.slice(0, 1));
    if (updatedChildren.length <= curIndex) curIndex--;
    // Update the specific node's child
    const splitItemChildrenPath = splitDropZonePath.slice(1);
    const nodeChildren = updatedChildren[curIndex];
    updatedChildren[curIndex] = {
      ...nodeChildren,
      child: UIDndHelper.addChildToChildren(
        ArrayUtils.isEmpty(nodeChildren.child) ? [] : nodeChildren.child,
        splitItemChildrenPath,
        item
      ),
    };

    return updatedChildren;
  };

  /**
   * 동일 level에서의 이동
   * @param {*} location
   * @param {*} layout
   * @param {*} splitDropZonePath
   * @param {*} splitItemPath
   * @returns
   */
  static moveWithinParent = (
    location,
    layout,
    splitDropZonePath,
    splitItemPath
  ) => {
    return UIDndHelper.reorderChildren(
      layout,
      splitDropZonePath,
      splitItemPath
    );
  };

  /**
   * Column 추가
   * @param {*} layout
   * @param {*} baseColComponent
   * @returns
   */
  static handleAddColumDataToRow = (layout, baseColComponent) => {
    const layoutCopy = [...layout];
    const colStructure = UIDndHelper.getColStructure(baseColComponent);
    return layoutCopy.map((row) => {
      if (row.child.length === 0) {
        row.child = [colStructure];
      }
      return row;
    });
  };

  /**
   * 다른 계층으로 이동시켰을 경우
   * @param {*} layout
   * @param {*} layout
   * @param {*} splitDropZonePath
   * @param {*} splitItemPath
   * @param {*} item
   * @param {*} templateComponents
   * @returns
   */
  static moveDifferentParent = (
    location,
    layout,
    splitDropZonePath,
    splitItemPath,
    item,
    templateComponents
  ) => {
    let newLayoutStructure;
    const baseRowComponent = UITemplateHelper.getTemplate(
      templateComponents,
      Enums.ComponentType.ROW
    );
    const baseColComponent = UITemplateHelper.getTemplate(
      templateComponents,
      Enums.ComponentType.COLUMN
    );
    let isWidgetComponent = item.editorAttr
      ? StringUtils.beginWith(item.editorAttr.componentClass, "widget/")
      : false;

    //switch (splitDropZonePath.length) {
    switch (location) {
      //page,form node에 drag & drop 했을 경우
      case "page":
      case "form": {
        // Column이 이동되었을 경우 Row 생성후 삽입
        if (item.type === Enums.ComponentType.COLUMN) {
          newLayoutStructure = UIDndHelper.getRowStructure(
            baseRowComponent,
            item
          );
          // Component가 이동 되었을 경우 Row > Column 생성후 삽입
        } else if (
          !isWidgetComponent &&
          item.type === Enums.ComponentType.COMPONENT
        ) {
          newLayoutStructure = UIDndHelper.getRowStructure(
            baseRowComponent,
            UIDndHelper.getColStructure(baseColComponent, item)
          );
        } else {
          newLayoutStructure = item;
        }
        break;
      }
      //2 level (Row)로 이동했을 경우
      case "row": {
        // Component가 이동 되었을 경우 Column 생성후 삽입
        if (!isWidgetComponent && item.type === Enums.ComponentType.COMPONENT) {
          newLayoutStructure = UIDndHelper.getColStructure(
            baseColComponent,
            item
          );

          //Column 또는 container 일 경우
        } else {
          newLayoutStructure = item;
        }

        break;
      }
      //3 level 이후에 이동했을 경우
      default: {
        newLayoutStructure = item;
      }
    }

    let updatedLayout = layout;
    //source node의 child를 찾아 제거 후 update
    updatedLayout = UIDndHelper.removeChildFromChildren(
      updatedLayout,
      splitItemPath
    );
    /*
    updatedLayout = UIDndHelper.handleAddColumDataToRow(
      updatedLayout,
      baseColComponent
    );
    */
    //target node의 child에 추가
    updatedLayout = UIDndHelper.addChildToChildren(
      updatedLayout,
      splitDropZonePath,
      newLayoutStructure
    );

    return updatedLayout;
  };
  /**
   * 동일 level에서의 이동
   * @param {*} location
   * @param {*} layout
   * @param {*} splitDropZonePath
   * @param {*} splitItemPath
   * @returns
   */
  static moveGridColumn = (
    location,
    layout,
    splitDropZonePath,
    splitItemPath
  ) => {
    return UIDndHelper.reorderGridColumn(
      layout,
      splitDropZonePath,
      splitItemPath
    );
  };

  /**
   * Sidebar에서 Editor로 component 추가
   * @param {*} location
   * @param {*} layout children nodes
   * @param {*} splitDropZonePath
   * @param {*} item
   * @param {*} templateComponents
   * @returns
   */
  static addComponentIntoEditor = (
    location,
    layout,
    splitDropZonePath,
    item,
    templateComponents
  ) => {
    let newLayoutStructure;
    const baseRowComponent = UITemplateHelper.getTemplate(
      templateComponents,
      Enums.ComponentType.ROW
    );
    const baseColComponent = UITemplateHelper.getTemplate(
      templateComponents,
      Enums.ComponentType.COLUMN
    );

    switch (location) {
      //page,form node에 drag & drop 했을 경우
      case Enums.ComponentType.PAGE:
      case Enums.ComponentType.FORM: {
        //row와 column을 추가해서 생성한다.
        //row 추가
        if (
          item.type === Enums.ComponentType.COLUMN ||
          item.type === Enums.ComponentType.COMPONENT
        ) {
          newLayoutStructure = UIDndHelper.getRowStructure(baseRowComponent);

          //drag & drop item 이 column 일경우
          if (item.type === Enums.ComponentType.COLUMN) {
            newLayoutStructure.child = [item];

            //drag & drop item이 component인경우
          } else {
            const colLayoutStructure = UIDndHelper.getColStructure(
              baseColComponent,
              item
            );
            newLayoutStructure.child = [colLayoutStructure];
          }
        } else {
          newLayoutStructure = item;
        }
        break;
      }
      case Enums.ComponentType.ROW: {
        if (item.type === Enums.ComponentType.COMPONENT) {
          //row node에 drag & drop 했을 경우 column을 추가해서 생성한다.
          newLayoutStructure = UIDndHelper.getColStructure(
            baseColComponent,
            item
          );
        } else {
          newLayoutStructure = item;
        }
        break;
      }
      default: {
        newLayoutStructure = item;
      }
    }

    return UIDndHelper.addChildToChildren(
      layout,
      splitDropZonePath,
      newLayoutStructure
    );
  };

  static handleRemoveItemFromLayout = (layout, splitItemPath) => {
    return UIDndHelper.removeChildFromChildren(layout, splitItemPath);
  };
}
export default UIDndHelper;
