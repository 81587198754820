import React, { memo, useCallback, useContext, useState } from "react";
import { Handle, MarkerType, Position, useStore } from "reactflow";
import { FlowContext } from "../EntityFlowEditor";
import * as Event from "components/builder/entity/editor/handler/EntityEditorEventHandler";
import { useDispatch, useSelector } from "react-redux";
import StringUtils from "components/common/utils/StringUtils";
import { TableToolbar } from "components/builder/entity/flow/render/TableToolbar";
import EntityNodeField from "./EntityNodeField";
import EntityReduxHelper from "../../editor/helper/EntityReduxHelper";
import DataModelAddRowPopup from "page/popup/dataModel/DataModelAddRowPopup";
import Popup from "components/common/Popup";
import { Tooltip } from "@mui/material";
import RelationSetPopup from "page/popup/dataModel/RelationSetPopup";
import produce from "immer";
import { RiFileCopy2Line } from "react-icons/ri";
import { BsTable } from "react-icons/bs";
import { MdPreview } from "react-icons/md";
import { AiOutlineFunction } from "react-icons/ai";
import { HiOutlineDatabase } from "react-icons/hi";

const zoomSelector = (s) => s.transform[2];

const EntityNode = memo(({ data, isConnectable }) => {
  const {
    handleTabSelect,
    onRelationModeClick,
    relationMode,
    eagerEntityIds,
    entityRelation: { sourceEntity, init: relationInit },
  } = useContext(FlowContext);
  const dispatch = useDispatch();
  const activedComponent = useSelector((state) => {
    return state.activedENTComponent;
  });
  const output = useSelector((state) => {
    return state.outputENT.output;
  });
  const [isRelationHover, setIsRelationHover] = useState(false);

  const zoom = useStore(zoomSelector);
  const showContent = zoom >= 0.4;
  // const showContent = true;

  const {
    tableNm = "",
    physEntityNm = "",
    logEntityNm = "",
    dataModelEntityFields = [],
    serviceInout = "",
    entityType = "TABLE",
  } = data.entity;

  const isTable = StringUtils.includesIgnoreCase(entityType, [
    "TABLE",
    "SYNONYM",
    "VIEW",
  ]);

  /**
   * 엔티티 클릭
   * @param {*} e
   */
  const onClickEntity = (e) => {
    if (e) e.stopPropagation();
    relationInit();
    handleTabSelect("Properties");
    Event.HandleEntityClick(e, dispatch, activedComponent, data.entity);
  };

  /**
   * 현재 선택한 노드인지 판단하는 메서드
   * @returns
   */
  const getActiveClass = () => {
    return StringUtils.equalsIgnoreType(
      activedComponent?.physEntityNm,
      data.entity.physEntityNm
    );
  };

  /**
   * 엔티티 삭제
   * @param {*} e
   */
  const removeEntity = (e) => {
    e.stopPropagation();
    e.preventDefault();
    EntityReduxHelper.removeEntity(dispatch, output, activedComponent);
    handleTabSelect("Tables");
  };

  /**
   * 가상 칼럼 추가
   * @param {*} e
   */
  const onOpenRowAddPopup = (e) => {
    const options = {
      effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
      style: {
        content: {
          width: "30%", //popup의 크기를 50% (default 60%)
        },
      },
    };
    Popup.open(
      <DataModelAddRowPopup
        onOk={(virtualField) =>
          EntityReduxHelper.addVirtualField(
            dispatch,
            output,
            virtualField,
            activedComponent
          )
        }
        entity={output.dataModelEntities.find(
          (en) => en.physEntityNm === activedComponent.physEntityNm
        )}
        dataModelType={output.dataModelType}
      />,
      options
    );
    if (e) e.preventDefault();
  };

  const onClickRelation = (e) => {
    if (e) e.stopPropagation();
    onRelationModeClick(data.entity.physEntityNm, e);
  };

  const onMouseOver = (e) => {
    if (
      relationMode &&
      !isRelationHover &&
      sourceEntity.physEntityNm !== data.entity.physEntityNm
    ) {
      setIsRelationHover(true);
    }
  };
  const onMouseLeave = (e) => {
    if (
      relationMode &&
      isRelationHover &&
      sourceEntity.physEntityNm !== data.entity.physEntityNm
    ) {
      setIsRelationHover(false);
    }
  };

  const setRelationPopup = (e, fetchType) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    const { entity } = data;
    const { relation: relationList } = sourceEntity;
    const targetRelation = relationList.find(
      (_rel) => _rel.targetEntity === entity.physEntityNm
    );

    /**
     * 참조 삭제
     * @param {*} relation
     */
    const onRemoveRelation = (relation) => {
      //active component update
      const newComponent = produce(activedComponent, (draft) => {
        const removeIdx = draft.relation.findIndex((_relation) =>
          StringUtils.equalsIgnoreCase(
            _relation.targetEntity,
            relation.targetEntity
          )
        );
        const newRelatiosn = [...draft.relation];
        newRelatiosn.splice(removeIdx, 1);

        draft.relation = newRelatiosn;
      });
      EntityReduxHelper.activateComponent(dispatch, newComponent);
      EntityReduxHelper.updateEntity(dispatch, output, newComponent);
    };

    /**
     * 릴레이션 저장
     * @param {*} newRelation
     * @param {*} _entity
     */
    const onSaveRelation = (newRelation, _entity) => {
      //active component update
      const newComponent = produce(_entity, (draft) => {
        if (targetRelation) {
          const idx = relationList.findIndex(
            (_rel) => _rel.targetEntity === entity.physEntityNm
          );
          draft.relation[idx] = newRelation;
        } else {
          draft.relation.push(newRelation);
        }
      });
      //Data Model update
      EntityReduxHelper.activateComponent(dispatch, newComponent);
      EntityReduxHelper.updateEntity(dispatch, output, newComponent);
    };

    const options = {
      effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
      style: {
        content: {
          width: "30%",
        },
      },
    };
    onRelationModeClick();
    setIsRelationHover(false);
    Popup.open(
      <RelationSetPopup
        relation={targetRelation}
        sourceEntity={sourceEntity}
        targetEntity={entity}
        onSaveRelation={(newRelation) => {
          onSaveRelation(newRelation, sourceEntity);
        }}
        fetchType={fetchType}
        onRemoveRelation={() => onRemoveRelation(targetRelation)}
      />,
      options
    );
  };

  /**
   * 각 엔티티 노드 타이틀랜더
   */
  const renderTitle = StringUtils.equalsIgnoreCase(
    output.dataModelType,
    "D"
  ) ? (
    <>
      <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        {StringUtils.equalsIgnoreCase(entityType, "TABLE") ? (
          <BsTable size={15} />
        ) : StringUtils.equalsIgnoreCase(entityType, "VIEW") ? (
          <MdPreview size={45} />
        ) : StringUtils.equalsIgnoreCase(entityType, "PROCEDURE") ? (
          <HiOutlineDatabase size={20} />
        ) : StringUtils.equalsIgnoreCase(entityType, "FUNCTION") ? (
          <AiOutlineFunction size={15} />
        ) : StringUtils.equalsIgnoreCase(entityType, "SYNONYM") ? (
          <BsTable size={15} color="lime" />
        ) : (
          <></>
        )}
        <span>{physEntityNm}</span>
      </div>
      <div>{tableNm}</div>
    </>
  ) : StringUtils.equalsIgnoreCase(output.dataModelType, "S") ? (
    <>
      <div>{physEntityNm}</div>
      <div>{serviceInout}</div>
    </>
  ) : (
    <>
      <div>{physEntityNm}</div>
      <div>{logEntityNm}</div>
    </>
  );

  const renderHeader = StringUtils.equalsIgnoreCase(
    output.dataModelType,
    "D"
  ) ? (
    <>
      {eagerEntityIds[data.entity.physEntityNm] ? (
        <div className="isDraggable">
          <RiFileCopy2Line />
        </div>
      ) : (
        <></>
      )}
      <div className="status">상태</div>
      <div className="column-name">칼럼 명</div>
    </>
  ) : StringUtils.equalsIgnoreCase(output.dataModelType, "S") ? (
    <>
      <div className="status">ID</div>
      <div className="column-name">논리 명</div>
    </>
  ) : (
    <>
      <div className="status">논리명</div>
      <div className="column-name">물리 명</div>
    </>
  );

  const renderEntity = (
    <div
      className={`entity-table ${getActiveClass() ? "active" : ""}`}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
    >
      {getActiveClass() ? (
        <TableToolbar
          removeTable={removeEntity}
          openAddRowPopup={
            isTable && !StringUtils.equalsIgnoreCase(output.dataModelType, "E")
              ? onOpenRowAddPopup
              : null
          }
          onClickRelation={
            isTable && StringUtils.equalsIgnoreCase(output.dataModelType, "D")
              ? onClickRelation
              : null
          }
          entity={data.entity}
          mini={!showContent}
        />
      ) : (
        <></>
      )}
      {isRelationHover ? (
        <div
          className={`hover-panel  ${
            data.entity.remark?.fold ? "fold" : ""
          } nodrag`}
          onClick={(e) => e.stopPropagation()}
        >
          {isTable && (
            <div onClick={(e) => setRelationPopup(e, "LAZY")}>참조 설정</div>
          )}
        </div>
      ) : (
        <></>
      )}

      <div className="title" onClick={onClickEntity}>
        {renderTitle}
      </div>
      {!data.entity.remark?.fold && (
        <div className="entity-field-body">
          <div
            className={`header ${
              StringUtils.equalsIgnoreCase("D", output.dataModelType)
                ? "dynamic"
                : StringUtils.equalsIgnoreCase("S", output.dataModelType)
                ? "service"
                : "entity"
            }`}
            onClick={onClickEntity}
          >
            {renderHeader}
          </div>
          <div className="field-list" data-entity-component-id={physEntityNm}>
            {dataModelEntityFields.map((field) => {
              return (
                <EntityNodeField
                  key={field.compId}
                  _entity={data.entity}
                  field={field}
                  editable={isTable}
                />
              );
            })}
          </div>
        </div>
      )}
    </div>
  );

  if (true) {
    return (
      <>
        {zoom <= 0.7 ? (
          <Tooltip
            title={`${physEntityNm} [${tableNm}]`}
            placement="left-start"
            componentsProps={{
              tooltip: {
                style: {
                  fontSize: `${
                    10 / zoom > 20 ? "20px" : `calc(10px / ${zoom})`
                  }`,
                },
              },
            }}
          >
            {renderEntity}
          </Tooltip>
        ) : (
          renderEntity
        )}

        <Handle type="target" position={Position.Left} />
        <Handle type="source" position={Position.Right} />
      </>
    );
  } else {
    return (
      <>
        <div
          className={`entity-table mini ${getActiveClass() ? "active" : ""}`}
          onClick={onClickEntity}
        >
          {getActiveClass() ? (
            <TableToolbar
              removeTable={() => {}}
              openAddRowPopup={() => {}}
              entity={activedComponent}
              mini={!showContent}
            />
          ) : (
            <></>
          )}
          <div>{physEntityNm}</div>
          <div>{tableNm}</div>
        </div>
        <Handle type="target" position={Position.Left} />
        <Handle type="source" position={Position.Right} />
      </>
    );
  }
});

export default EntityNode;
