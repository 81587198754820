import ActionType from "components/builder/BuilderActionType";
import { Enums } from "components/builder/BuilderEnum";
import ArrayUtils from "components/common/utils/ArrayUtils";
import ObjectUtils from "components/common/utils/ObjectUtils";
import StringUtils from "components/common/utils/StringUtils";
import User from "components/common/utils/UserUtils";
import produce from "immer";

export const workflowInitialState = {
  prevService: [], //이전 수행한 서비스 목록 -> 서비스 ID만 넣을 예정
  output: {
    service: {
      compId: StringUtils.getUuid(),
      serviceId: "",
      serviceName: "",
      serviceType: "",
      viewport: { x: 0, y: 0, zoom: 0.75 },
      child: {
        process: [
          {
            compId: StringUtils.getUuid(),
            processType: "StartProcess",
            type: Enums.WorkflowNodeType.PROCESS_EDGE,
            position: { x: 280, y: 30 },
            propertyValue: {
              processNm: "시작",
            },
          },
          {
            compId: StringUtils.getUuid(),
            processType: "EndProcess",
            type: Enums.WorkflowNodeType.PROCESS_EDGE,
            position: { x: 2200, y: 1000 },
            propertyValue: {
              processNm: "종료",
            },
          },
        ],
        //process 안에 Iteration이 들어갈예정
        connector: [],
      },
    },
    bundle: [],
  },
  serviceComment: {
    process: [],
    connector: [],
  },
  serviceMemo: [],
  serviceInfo: {
    serviceId: "",
    serviceName: "",
    description: "",
    serviceType: "",
  },
  CSFileInfo: {},
  CSFileViewMode: false,
  CSFileLogViewMode: false,
};

export default function WorkflowReducer(state = workflowInitialState, action) {
  switch (action.type) {
    case ActionType.WORKFLOW.INIT:
      return workflowInitialState;
    case ActionType.WORKFLOW.CREATE:
      const serviceInfo = action.payload;
      return produce(state, (draft) => {
        draft.prevService = workflowInitialState.prevService;
        draft.service = workflowInitialState.service;
        draft.serviceInfo.serviceId = serviceInfo.serviceId;
        draft.serviceInfo.serviceName = serviceInfo.serviceName;
        draft.serviceInfo.description = serviceInfo.description;
        draft.serviceInfo.serviceType = serviceInfo.serviceType;
        draft.output.service.serviceId = serviceInfo.serviceId;
        draft.output.service.serviceName = serviceInfo.serviceName;
        draft.output.service.serviceType = serviceInfo.serviceType;
      });
    case ActionType.WORKFLOW.PROCESS.ADD:
      const newProcess = action.payload;
      return produce(state, (draft) => {
        draft.output.service.child.process.push(newProcess);
      });
    case ActionType.WORKFLOW.PROCESS.DELETE:
      const deleteProcessId = action.payload;
      return produce(state, (draft) => {
        const pIdx = state.output.service.child.process.findIndex(
          (_proc) => _proc.compId === deleteProcessId
        );
        if (pIdx > -1) {
          draft.service.process.splice(pIdx, 1);
          //커넥터도 삭제
          draft.output.service.child.connector =
            draft.output.service.child.connector.filter(
              (c) =>
                c.processFrom !== deleteProcessId &&
                c.processTo !== deleteProcessId
            );
        }
      });
    case ActionType.WORKFLOW.PROCESS.UPDATE:
      return produce(state, (draft) => {
        for (const process of action.payload) {
          const pIdx = draft.output.service.child.process.findIndex(
            (p) => p.compId === process.compId
          );
          if (pIdx > -1)
            draft.output.service.child.process[pIdx] = { ...process };
        }
      });
    case ActionType.WORKFLOW.CONNECTOR.ADD:
      return produce(state, (draft) => {
        draft.output.service.child.connector.push(action.payload);
      });
    case ActionType.WORKFLOW.CONNECTOR.DELETE:
      return produce(state, (draft) => {
        const conIdx = draft.output.service.child.connector.findIndex(
          (c) => c.compId === action.payload
        );
        if (conIdx > -1) draft.output.service.child.connector.splice(conIdx, 1);
      });
    case ActionType.WORKFLOW.CONNECTOR.UPDATE:
      return produce(state, (draft) => {
        for (const connector of action.payload) {
          const pIdx = draft.output.service.child.connector.findIndex(
            (c) => c.compId === connector.compId
          );
          if (pIdx > -1)
            draft.output.service.child.connector[pIdx] = { ...connector };
        }
      });
    case ActionType.WORKFLOW.UPDATE:
      return action.payload;
    case ActionType.WORKFLOW.UPDATE_OUTPUT:
      return produce(state, (draft) => {
        draft.output = action.payload;
      });
    case ActionType.WORKFLOW.UPDATE_SERVICE:
      return produce(state, (draft) => {
        let {
          serviceContent,
          serviceComment,
          prevService,
          serviceMemo,
          ...otherData
        } = action.payload;
        if (prevService) {
          draft.prevService = prevService;
        } else {
          draft.prevService = [];
        }
        draft.output = serviceContent;
        draft.serviceComment = serviceComment || { process: [], connector: [] };
        draft.serviceMemo = serviceMemo ? serviceMemo : [];
        draft.serviceInfo = {
          ...otherData,
          serviceMemo,
          serviceContent,
          convertLog: otherData.convertLog
            ? ObjectUtils.isObject(otherData.convertLog)
              ? otherData.convertLog
              : JSON.parse(otherData.convertLog)
            : null,
        };
        draft.CSFileInfo = {};
        draft.CSFileViewMode = false;
        draft.CSFileLogViewMode = false;
      });
    case ActionType.WORKFLOW.UPDATE_VIEWPORT:
      return produce(state, (draft) => {
        draft.output.service.viewport = {
          ...state.viewport,
          ...action.payload,
        };
      });
    case ActionType.WORKFLOW.SET_CS_FILE_INFO:
      return produce(state, (draft) => {
        draft.CSFileInfo = action.payload;
        draft.CSFileViewMode = true;
        if (!ArrayUtils.isEmpty(action.payload.serviceList)) {
          const firstService = action.payload.serviceList[0];
          draft.output = firstService.serviceContent;
          draft.serviceMemo = firstService.serviceMemo || [];
          draft.serviceComment = firstService.serviceComment;
          draft.serviceInfo = firstService.serviceInfo;
        }
      });
    case ActionType.WORKFLOW.SET_CS_SERVICE:
      return produce(state, (draft) => {
        const service = action.payload;
        draft.output = service.serviceContent;
        draft.serviceMemo = service.serviceMemo || [];
        draft.serviceComment = service.serviceComment;
        draft.serviceInfo = service.serviceInfo;
      });
    case ActionType.WORKFLOW.UPDATE_CS_FILE_INFO:
      break;
    case ActionType.WORKFLOW.SET_LOG_VIEW_MODE:
      return produce(state, (draft) => {
        draft.CSFileLogViewMode = action.payload;
      });
    case ActionType.WORKFLOW.LOG_STATE_CHANGE:
      //LogState 바뀔때 마다 CS 파일 정보안에 있는 service 데이터들도 업데이트 함
      const outputServiceId = state.serviceInfo.serviceId;

      return produce(state, (draft) => {
        if (!ArrayUtils.isEmpty(draft.serviceInfo.convertLog)) {
          draft.serviceInfo.convertLog[action.payload.index].state =
            action.payload.state;
          draft.serviceInfo.convertLog[action.payload.index].updtUserId =
            User.getId();

          //파일 없이 WF만 단독으로 봐서 State를 바꿀수 있으니 조건 처리
          if (!ObjectUtils.isEmpty(draft.CSFileInfo) && draft.CSFileViewMode) {
            const serviceIndex = draft.CSFileInfo.serviceList.findIndex(
              (csService) => csService.serviceInfo.serviceId === outputServiceId
            );

            if (serviceIndex > -1 && action.payload.index > -1) {
              const logChangedConverLog = {
                ...draft.CSFileInfo.serviceList[serviceIndex].serviceInfo
                  .convertLog[action.payload.index],
                state: action.payload.state,
                updtUserId: User.getId(),
              };
              draft.CSFileInfo.serviceList[serviceIndex].serviceInfo.convertLog[
                action.payload.index
              ] = logChangedConverLog;
            }
          }
        }
      });

    case ActionType.WORKFLOW.UPDATE_CS_SERVICE:
      //output이 바뀔때 마다 CS 파일 정보안에 있는 service 데이터들도 업데이트 함

      return produce(state, (draft) => {
        const outputServiceId = state.serviceInfo.serviceId;

        const serviceIndex = draft.CSFileInfo.serviceList.findIndex(
          (csService) => csService.serviceInfo.serviceId === outputServiceId
        );
        if (serviceIndex > -1) {
          draft.CSFileInfo.serviceList[serviceIndex].serviceContent =
            state.output;
        }
      });
    case ActionType.WORKFLOW.UPDATE_BUNDLE:
      //특정 번들 내용이 업데이트 된경우
      return produce(state, (draft) => {
        const bundle = action.payload;
        const bundleIndex = draft.output.bundle.findIndex(
          (b) => b.compId === bundle.compId
        );
        if (bundleIndex > -1) {
          draft.output.bundle[bundleIndex] = bundle;
        }
      });

    default:
      return state;
  }
}
