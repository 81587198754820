import {
  stackRedo,
  stackUndo,
} from "components/builder/ui/reducers/CommandAction";
import { updateEventOutput } from "components/builder/eventhandler/reducer/EventHandlerAction";
import produce from "immer";

class EventHandlerReduxHelper {
  /**
   * 내부 이벤트핸들러 업데이트
   * 해당 업데이트는 Redo 스택에 들어가도록 합니다.
   * Undo, Redo에 영향이 있습니다.
   * 특별한 케이스가 아닌이상 이벤트핸들러의 수정 상태는 해당 로직을 타도록 되어있습니다.
   * @param {*} dispatch
   * @param {*} eventHandler
   */
  static _updateEventOutput(dispatch, eventHandler) {
    dispatch(updateEventOutput(eventHandler));
    dispatch(stackRedo(eventHandler));
  }

  /**
   * 이벤트핸들러 업데이트
   * 외부에서 사용
   * @param {*} dispatch
   * @param {*} eventHandler
   * @param {*} prevEventHandler
   */
  static updateEventOutput(dispatch, eventHandler, prevEventHandler) {
    dispatch(stackUndo(prevEventHandler));
    this._updateEventOutput(dispatch, eventHandler);
  }
}

export default EventHandlerReduxHelper;
