import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Background,
  ControlButton,
  Controls,
  MarkerType,
  MiniMap,
  ReactFlow,
  useEdgesState,
  useNodesState,
  useReactFlow,
  useStoreApi,
} from "reactflow";

import EntityNode from "./render/EntityNode.js";
import { useDispatch, useSelector } from "react-redux";
import * as Event from "components/builder/entity/editor/handler/EntityEditorEventHandler";
import EntityReduxHelper from "../editor/helper/EntityReduxHelper.js";
import useFlowRender from "./utils/useFlowRender.js";
import ArrayUtils from "components/common/utils/ArrayUtils.js";
import produce from "immer";
import EntityRelationEdge from "./render/EntityRelationEdge.js";
import Message from "components/common/Message.js";
import { AppContext } from "components/common/AppContextProvider.js";
import StringUtils from "components/common/utils/StringUtils.js";
import { Enums } from "components/builder/BuilderEnum.js";
import EntityAddPopup from "page/popup/dataModel/EntityAddPopup.js";
import Popup from "components/common/Popup.js";
import ObjectUtils from "components/common/utils/ObjectUtils.js";
import { useNavigate } from "react-router-dom";
import ServiceEntityAddPopup from "page/popup/ServiceEntityAddPopup.js";
import TrackingNode from "./render/TrackingNode.js";
import { Button, ButtonGroup, ToggleButton } from "react-bootstrap";
import EntityCommandButton from "../editor/EntityCommandButton.js";
import EntityCodeMirror from "../editor/EntityCodeMirror.js";
import { MdOutlineNightlightRound, MdOutlineWbSunny } from "react-icons/md";
import User from "components/common/utils/UserUtils.js";
import DataModelParameterInputPopup from "page/popup/dataModel/DataModelParameterInputPopup.js";
import DataModelService from "services/datamodel/DataModelService.js";
import { CircularProgress } from "@mui/material";
import LocalStorageService from "services/common/LocalService.js";

const edgeTypes = {
  floating: EntityRelationEdge,
};

const nodeTypes = {
  entity: EntityNode, // 일반 엔티티 노드
  tracking: TrackingNode, // 릴레이션 연결할때 마우스에 붙어있는 노드(투명)
};

const DUP_FLAG = {
  MERGE: "MERGE",
  MERGE_REL: "MERGE_REL",
  OVERWRITE: "OVERWRITE",
  OVERWRITE_REL: "OVERWRITE_REL",
  NEW: "NEW",
  NONE: "NONE",
};

export const FlowContext = createContext({
  entityRelation: {
    sourceEntity: {},
    setSourceEntity: () => {},
    init: () => {},
  },
  handleTabSelect: () => {},
  edges: [],
  setEdges: () => {},
  eagerEntityIds: {},
  onRelationModeClick: () => {},
  relationMode: false,
  setRelationMode: () => {},
});

function EntityFlowEditor({ ...props }) {
  const { isSidebarCollapsed } = useSelector((state) => state.menu);
  const activedComponent = useSelector((state) => {
    return state.activedENTComponent;
  });
  const statOutput = useSelector((state) => state.outputENT.output);
  const {
    dataModel: { list: dataModelList },
    workspace: { Info },
  } = useContext(AppContext);
  const dispatch = useDispatch();

  const reactFlowWrapper = useRef(null);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [isFieldLoading, setIsFieldLoading] = useState(false);
  const userTheme = LocalStorageService.get(
    Enums.LocalStorageName.EDITOR_THEME
  );
  const [editorTheme, setEditorTheme] = useState(
    userTheme
      ? userTheme.userId === User.getId()
        ? userTheme.theme
        : "light"
      : "light"
  );

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  /* for relation Setting */
  const [sourceEntity, setSourceEntity] = useState();
  const [eagerEntityIds, setEagerEntityIds] = useState({});

  /*for edit tab*/
  const [tabType, setTabType] = useState("E");

  //엔티티 연결할때 사용
  const [relationMode, setRelationMode] = useState(false);

  //hook
  const [FlowNode, FlowEdge] = useFlowRender(editorTheme);
  const { setCenter } = useReactFlow();
  const store = useStoreApi();
  //ref
  const nodeChangeRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    if (ObjectUtils.isEmpty(statOutput)) {
      const newDm = {
        dataModelNm: "",
        moduleCd: Info.moduleCd,
        description: "",
        dataModelType: "D",
        apiUrl: "",
        entity: "",
        compId: StringUtils.getUuid(),
        type: Enums.EntityComponentType.DATA_MODEL,
        dataModelEntities: [],
        useYn: "Y",
      };
      EntityReduxHelper.openNewModel(dispatch, newDm);
    }

    InitEagerEntityIds();
  }, [statOutput]);

  useEffect(() => {
    setNodes(FlowNode);
    setEdges(FlowEdge);
  }, [FlowNode, FlowEdge]);

  /**
   * EAGER 연결된 엔티티 물리명을 확인
   * 각 엔티티 노드가 EAGER 연결되어있는지 확인하는 기준
   * @returns
   */
  const InitEagerEntityIds = () => {
    const eagerIds = {};

    const { dataModelEntities, dataModelType } = statOutput;
    if (!StringUtils.equalsIgnoreCase(dataModelType, "D")) return false;

    for (const entity of dataModelEntities) {
      const { relation: relations } = entity;
      for (const relation of relations) {
        const { fetch, targetEntity } = relation;

        if (StringUtils.equalsIgnoreCase(fetch, "EAGER")) {
          if (eagerIds[targetEntity]) {
            eagerIds[targetEntity].push(entity.physEntityNm);
          } else {
            eagerIds[targetEntity] = [entity.physEntityNm];
          }
        }
      }
    }
    return setEagerEntityIds(eagerIds);
  };

  /**
   * Flow Panel 위에서 드래그 되었을때 이벤트
   */
  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  /**
   * 엔티티 중복확인 및 후처리 작업 모음
   */
  const ENTITY_DUP_TASK = {
    /**
     * 엔티티 필드 덮어씌움
     * @param {Object} prevEntity 이전에 있던 엔티티
     * @param {object} entity 엔티티
     * @returns
     */
    overwriteFields: async (prevEntity, entity) => {
      /**
       * 일반 테이블(엔티티)들은 DB 호출 시간 때문에 테이블 명과 칼럼목록을 따로 들고 오는데,
       * Function, Procedure는 호출 방식이 달라서, 애초에 다들고 있다고 보면 된다.
       * 그래서 Procedure나 Function으로 가져오는 경우에는 필드가 이미 충족되어있고, 그때는 getField를 할필요가 없기 떄문에
       * 아래와 같이 수정하였음
       */
      if (ArrayUtils.isEmpty(entity.dataModelEntityFields)) {
        entity = await getEntityFieldList(entity);
      }
      const dataModelEntityFields = [...entity.dataModelEntityFields].map(
        (_field) => {
          const prevField = [...prevEntity.dataModelEntityFields].find(
            (paramField) =>
              StringUtils.equalsIgnoreType(paramField.columnNm, _field.columnNm)
          );
          if (prevField) {
            return {
              ..._field,
              compId: prevField.compId,
              type: prevField.type,
            };
          } else {
            return {
              ..._field,
              compId: StringUtils.getUuid(),
              type: Enums.EntityComponentType.ENTITY_FIELD,
            };
          }
        }
      );
      return dataModelEntityFields;
    },
    /**
     * 엔티티 덮어쓰기
     * @param {Array} _dupEntity 중복 엔티티 목록
     * @param {*} _targetEntity 덮어쓸 엔티티
     * @param {*} relationDelete
     * @returns
     */
    overwriteEntity: async (
      dupEntities,
      _targetEntity,
      relationDelete = false
    ) => {
      let result = [];
      for (const dupEntity of dupEntities) {
        const overwritedField = await ENTITY_DUP_TASK.overwriteFields(
          dupEntity,
          _targetEntity
        );
        result.push(
          ENTITY_DUP_TASK.createEntity(
            dupEntity,
            [...overwritedField],
            relationDelete
          )
        );
      }

      return result;
    },

    /**
     * 엔티티 병합
     * @param {Array} dupEntities
     * @param {*} _targetEntity
     * @param {*} relationDelete
     * @returns
     */
    mergeEntity: async (dupEntities, _targetEntity, relationDelete = false) => {
      let result = [];
      for (const dupEntity of dupEntities) {
        const virtualColumns = dupEntity.dataModelEntityFields.filter(
          (field) => field.virtualYn === "Y"
        );
        const overwritedField = await ENTITY_DUP_TASK.overwriteFields(
          dupEntity,
          _targetEntity
        );
        result.push(
          ENTITY_DUP_TASK.createEntity(
            dupEntity,
            [...overwritedField, ...virtualColumns],
            relationDelete
          )
        );
      }
      return result;
    },
    /**
     * 타겟 엔티티에 신규 필드를 입히는 작업 ( 중복 코드 정리용도 )
     * 관계설정을 유지 여부에 따라 후속 작업도 추가
     * @param {*} entity
     * @param {Array} newField
     * @param {Boolean} relationDelete
     * @returns
     */
    createEntity: (entity, newField, relationDelete) => {
      const saveEntity = produce(entity, (draft) => {
        draft.dataModelEntityFields = [...newField];
        if (relationDelete) {
          draft.relation = [];
          EntityReduxHelper.deleteRelationTargetEntity(
            dispatch,
            statOutput,
            draft.physEntityNm
          );
        }
      });
      return saveEntity;
    },

    [DUP_FLAG.OVERWRITE]: (dupEntity, parseData) =>
      ENTITY_DUP_TASK.overwriteEntity(dupEntity, parseData, true),
    [DUP_FLAG.OVERWRITE_REL]: (dupEntity, parseData) =>
      ENTITY_DUP_TASK.overwriteEntity(dupEntity, parseData, false),
    [DUP_FLAG.MERGE]: (dupEntity, parseData) =>
      ENTITY_DUP_TASK.mergeEntity(dupEntity, parseData, true),
    [DUP_FLAG.MERGE_REL]: (dupEntity, parseData) =>
      ENTITY_DUP_TASK.mergeEntity(dupEntity, parseData, false),
  };

  /**
   * 필드 목록 가져오는 로직,
   * 서버IO가 일어나기 때문에 Promise로 선언
   * @param {*} entityData  Entity
   * @returns {Promise} _data
   */
  const getEntityFieldList = (entityData) => {
    const apiParam = { ...entityData };
    if (
      StringUtils.equalsIgnoreCase(entityData.entityType, "SYNONYM") &&
      !ObjectUtils.isEmpty(entityData.remark) &&
      !StringUtils.isEmpty(entityData.remark.originalTableNm)
    ) {
      //synonym의 경우 원본테이블과 Synonym이 다를수도 있어서 필드(컬럼)를 가져올때는 원본테이블 명을 파라미터로 하여
      //필드 정보를 가져올수 있도록 한다. 실제 프로그램에서 동작할때는 tableNm(실제 시노님 명)으로 실행되기 때문에
      //필드 정보를 호출할때만 테이블 명을 덮어 쓴다.
      apiParam.tableNm = entityData.remark.originalTableNm;
    }
    setIsFieldLoading(true);
    return new Promise((resolve, reject) => {
      getFieldList(
        apiParam,
        (res) => {
          setIsFieldLoading(false);
          entityData = produce(entityData, (draft) => {
            draft.dataModelEntityFields = res.data.dataModelEntityFields.map(
              (field) => {
                field.type = Enums.EntityComponentType.ENTITY_FIELD;
                field.compId = StringUtils.getUuid();
                return field;
              }
            );
            draft.relation = res.data.relation;
          });

          resolve(entityData);
        },
        (err) => {
          Message.alert();
          setIsFieldLoading(false);
          reject(err);
        }
      );
    });
  };

  /**
   * 드랍 이벤트
   */
  const onDrop = useCallback(
    async (event) => {
      event.preventDefault();
      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const entityData = event.dataTransfer.getData("application/reactflow");

      // check if the dropped element is valid
      if (!entityData) return false; //데이터가 없는 경우
      const parseData = JSON.parse(entityData);
      if (
        !StringUtils.equalsIgnoreCase(
          parseData.type,
          Enums.EntityComponentType.ENTITY
        )
      )
        //데이터 타입이 엔티티가 아닌경우
        return false;

      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });
      parseData.position = position;
      //다이나믹 , 엔티티
      if (
        StringUtils.equalsIgnoreCase(statOutput.dataModelType, "D") ||
        StringUtils.equalsIgnoreCase(statOutput.dataModelType, "E")
      ) {
        if (
          StringUtils.equalsIgnoreCase(parseData.entityType, "TABLE") ||
          StringUtils.equalsIgnoreCase(parseData.entityType, "VIEW") ||
          StringUtils.equalsIgnoreCase(parseData.entityType, "SYNONYM")
        ) {
          //중복된 경우 액션 선택
          const { result: dupResult, dupEntity } = await checkDupEntity(
            parseData
          );
          if (
            StringUtils.equalsIgnoreCase(DUP_FLAG.OVERWRITE, dupResult) || //덮어쓰기
            StringUtils.equalsIgnoreCase(DUP_FLAG.OVERWRITE_REL, dupResult) || //덮어쓰기 후 연결 유지
            StringUtils.equalsIgnoreCase(DUP_FLAG.MERGE, dupResult) || //병합
            StringUtils.equalsIgnoreCase(DUP_FLAG.MERGE_REL, dupResult) //병합 후 연결 유지
          ) {
            const targetEntity = await ENTITY_DUP_TASK[dupResult](
              dupEntity,
              parseData
            );
            EntityReduxHelper.updateEntities(
              dispatch,
              statOutput,
              targetEntity
            );
          } else if (StringUtils.equalsIgnoreCase(DUP_FLAG.NEW, dupResult)) {
            //신규
            const onOk = async (newName) => {
              const newEntity = produce(parseData, (draft) => {
                draft.physEntityNm = newName;
              });
              Popup.close();
              const _data = await getEntityFieldList(newEntity);
              const WillRenderEntities = await checkEntityRelation(_data);
              EntityReduxHelper.addNewEntities(
                dispatch,
                statOutput,
                WillRenderEntities
              );
            };
            const options = {
              effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
              style: {
                content: {
                  width: "30%",
                },
              },
            };
            Popup.open(
              <EntityAddPopup
                output={statOutput}
                onOk={onOk}
                entity={parseData}
              />,
              options
            );
          } else if (StringUtils.equalsIgnoreCase(DUP_FLAG.NONE, dupResult)) {
            //중복 아님
            if (StringUtils.equalsIgnoreCase(statOutput.dataModelType, "D")) {
              //필드 목록 가져옴
              const _data = await getEntityFieldList(parseData);
              const WillRenderEntities = await checkEntityRelation(_data);
              EntityReduxHelper.addNewEntities(
                dispatch,
                statOutput,
                WillRenderEntities
              );
            } else if (
              StringUtils.equalsIgnoreCase(statOutput.dataModelType, "E")
            ) {
              const WillRenderEntities = await checkEntityRelation(parseData);
              EntityReduxHelper.addNewEntities(
                dispatch,
                statOutput,
                WillRenderEntities
              );
            }
          }
        } else if (
          StringUtils.equalsIgnoreCase(parseData.entityType, "function") ||
          StringUtils.equalsIgnoreCase(parseData.entityType, "procedure")
        ) {
          onDropProcedureEntity(parseData);
        }

        //서비스
      } else if (StringUtils.equalsIgnoreCase(statOutput.dataModelType, "S")) {
        //인풋 타입은 한개씩만
        const existInputcheck = statOutput.dataModelEntities.find((entity) =>
          StringUtils.equalsIgnoreCase(entity.serviceInout, "input")
        );
        if (StringUtils.equalsIgnoreCase(parseData.key, "output")) {
          // 아웃풋 드랍시 인풋 여부 체크
          if (!existInputcheck) {
            return Message.alert(
              "Input Entity를 먼저 추가해주세요",
              Enums.MessageType.INFO
            );
          }
        } else if (!ArrayUtils.isEmpty(statOutput.dataModelEntities)) {
          if (StringUtils.equalsIgnoreCase(parseData.key, "input")) {
            if (existInputcheck) {
              return Message.alert(
                "Input Entity는 1개만 가능합니다.",
                Enums.MessageType.INFO
              );
            }
          }
        }

        const options = {
          effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
          style: {
            content: {
              width: "30%",
            },
          },
        };
        Popup.open(
          <ServiceEntityAddPopup
            onOk={(inputData) => onServiceEntitySet(parseData, inputData)}
            entityType={parseData.serviceInout}
            dataModelEntities={statOutput.dataModelEntities}
          />,
          options
        );
      }
    },
    [reactFlowInstance, statOutput, dataModelList]
  );

  /**
   * 프로시져 || 함수형 엔티티
   * @param {*} entity
   */
  const onDropProcedureEntity = (entity) => {
    const onOk = async (data) => {
      //물리명 중복 검색
      const dataModelEntities = statOutput.dataModelEntities;
      const isDupNm = dataModelEntities.find((_en) =>
        StringUtils.equalsIgnoreCase(_en.physEntityNm, data.physEntityNm)
      );
      if (isDupNm) {
        const { result: dupResult, dupEntity } = await checkDupEntity(data);
        const targetEntity = await ENTITY_DUP_TASK[dupResult](dupEntity, data);
        EntityReduxHelper.updateEntities(dispatch, statOutput, targetEntity);
      } else {
        data.position = entity.position;
        data.type = entity.type;
        data.compId = entity.compId;
        const dataModelEntityFields = data.dataModelEntityFields.map(
          (_field) => {
            const field = { ..._field };
            field.type = Enums.EntityComponentType.ENTITY_FIELD;
            field.compId = StringUtils.getUuid();
            return field;
          }
        );
        data.dataModelEntityFields = [...dataModelEntityFields];
        EntityReduxHelper.addNewEntities(dispatch, statOutput, [data]);
      }
    };

    //프로시져 || 펑션 엔티티
    const options = {
      effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
      style: {
        content: {
          width: "40%",
        },
      },
    };

    Popup.open(
      <DataModelParameterInputPopup
        entity={entity}
        connection={User.getConnection(Info.tenantMstId)}
        onOk={onOk}
        output={statOutput}
      />,
      options
    );
  };

  /**
   * 서비스 엔티티 생성 메서드
   * @param {Object} prevData Drop 시 엔티티 데이터
   * @param {Object} inputData Input 내용이 들어간 데이터
   * @returns
   */
  const onServiceEntitySet = (prevData, inputData) => {
    const serviceEntity = { ...prevData, ...inputData };
    if (StringUtils.equalsIgnoreCase(serviceEntity.serviceInout, "input")) {
      //key가 인풋일경우 기존 아웃풋을 확인해서 릴레이션 추가
      const outputEntities = statOutput.dataModelEntities.filter((entity) =>
        StringUtils.equalsIgnoreCase(entity.serviceInout, "output")
      );
      if (!ArrayUtils.isEmpty(outputEntities)) {
        serviceEntity.relation = outputEntities.map((entity) => ({
          targetTable: entity.tableNm,
          targetEntity: entity.tableNm,
        }));
      }
      EntityReduxHelper.addNewEntity(dispatch, statOutput, serviceEntity);
    } else if (
      StringUtils.equalsIgnoreCase(serviceEntity.serviceInout, "output")
    ) {
      const inputEntity = statOutput.dataModelEntities.find((entity) =>
        StringUtils.equalsIgnoreCase(entity.serviceInout, "input")
      );
      if (inputEntity) {
        //key가 아웃풋일경우 input의 릴레션에 추가 연결
        let newOutput = EntityReduxHelper.addRelationFromInputEntity(
          dispatch,
          statOutput,
          serviceEntity.tableNm,
          serviceEntity.physEntityNm
        );
        EntityReduxHelper.addNewEntity(dispatch, newOutput, serviceEntity);
      } else {
        return Message.alert(
          "Input Entity를 먼저 설정 해주세요",
          Enums.MessageType.WARN
        );
      }
    }
  };

  /**
   * 중복된 엔티티를 놓을 경우 활동 선택
   * @param {Object} _entity
   * @returns {Promise} 리턴시키는 항목
   */
  const checkDupEntity = (_entity) => {
    return new Promise((resolve, reject) => {
      const dupEntity = statOutput.dataModelEntities.filter((_dm) =>
        StringUtils.equalsIgnoreCase(_dm.tableNm, _entity.tableNm)
      );
      if (!ArrayUtils.isEmpty(dupEntity)) {
        Message.multiConfirm(
          "기존에 정의된 데이터 모델이 있습니다. 아래에서 활동을 선택해주세요",
          [
            {
              text: "덮어쓰기 (Overwrite)",
              cb: () =>
                Message.confirm(
                  {
                    title: "덮어쓰기",
                    desc: "기존 칼럼을 새로운 칼럼으로 덮어씌우시겠습니까?\n 릴레이션 항목이 초기화 됩니다.",
                  },
                  () => resolve({ result: DUP_FLAG.OVERWRITE, dupEntity })
                ),
            },
            {
              text: "덮어쓰기 & 연결 유지",
              cb: () =>
                Message.confirm(
                  {
                    title: "덮어쓰기",
                    desc: "기존 칼럼을 새로운 칼럼으로 덮어씌우시겠습니까?",
                  },
                  () => resolve({ result: DUP_FLAG.OVERWRITE_REL, dupEntity })
                ),
            },
            {
              text: "병합 (Merge)",
              cb: () =>
                Message.confirm(
                  {
                    title: "병합",
                    desc: "임의로 정의된 가상칼럼을 제외한 칼럼을 덮어씌우시겠습니까?\n 릴레이션이 초기화 됩니다.",
                  },
                  () => resolve({ result: DUP_FLAG.MERGE, dupEntity })
                ),
            },
            {
              text: "병합 & 연결 유지",
              cb: () =>
                Message.confirm(
                  {
                    title: "병합",
                    desc: "임의로 정의된 가상칼럼을 제외한 칼럼을 덮어씌우시겠습니까?",
                  },
                  () => resolve({ result: DUP_FLAG.MERGE_REL, dupEntity })
                ),
            },
            {
              text: "신규",
              cb: () => resolve({ result: DUP_FLAG.NEW, dupEntity }),
            },
          ],
          () => false
        );
      } else {
        resolve({ result: DUP_FLAG.NONE });
      }
    });
  };

  /**
   * 엔티티와 관계 있는 엔티티를 전부 가져옴
   * @param {Object} _entity
   */
  const checkEntityRelation = (_entity) => {
    const relatedTables = [_entity];
    return new Promise(async (resolve, reject) => {
      if (_entity?.relation && _entity?.relation.length > 0) {
        Message.confirm(
          "관계된 테이블이 있습니다. 모두 호출 하시겠습니까?\n(이미 데이터 모델에 있으면 기존에 있는 Entity와 연결됩니다.)",
          async () => {
            setIsFieldLoading(true);
            const findRelatedTables = (table) => {
              return new Promise((resolve, reject) => {
                //엔티티를 순회하면서 관계된 테이블 뽑아냄
                for (const _rel of table.relation) {
                  const _relatedTable = dataModelList.find((_dm) => {
                    return StringUtils.equalsIgnoreCase(
                      _dm.tableNm,
                      _rel.targetTable
                    );
                  });
                  const dpModel = statOutput.dataModelEntities.find((_dme) =>
                    StringUtils.equalsIgnoreCase(_dme.tableNm, _rel.targetTable)
                  );
                  const willRenderModel = relatedTables.find((_dme) =>
                    StringUtils.equalsIgnoreCase(_dme.tableNm, _rel.targetTable)
                  );
                  if (_relatedTable && !dpModel && !willRenderModel) {
                    let _dataModelEntityFields = [];
                    let _relation = [];
                    getFieldList(
                      { tableNm: _rel.targetTable },
                      async (res) => {
                        for (const field of res.data.dataModelEntityFields) {
                          const newField = {
                            ...field,
                            type: Enums.EntityComponentType.ENTITY_FIELD,
                            compId: StringUtils.getUuid(),
                          };
                          _dataModelEntityFields.push(newField);
                        }
                        _relation = res.data.relation;

                        const relatedTable = produce(_relatedTable, (draft) => {
                          draft.position = {
                            x: _entity.position.x + 500,
                            y: _entity.position.y + 300,
                          };
                          draft.type = Enums.EntityComponentType.ENTITY;
                          draft.dataModelEntityFields = _dataModelEntityFields;
                          draft.relation = _relation;
                          draft.compId = StringUtils.getUuid();
                        });
                        relatedTables.push(relatedTable);
                        if (relatedTable && relatedTable.relation?.length > 0) {
                          resolve(await findRelatedTables(relatedTable));
                        } else {
                          resolve(true);
                        }
                      },
                      (err) => {
                        reject(err);
                      }
                    );
                  } else {
                    resolve(true);
                  }
                }
              });
            };
            await findRelatedTables(_entity)
              .then(() => {
                setIsFieldLoading(false);
                resolve(relatedTables);
              })
              .catch(() => {
                setIsFieldLoading(false);
              });
          },
          () => {
            const noRelationTable = produce(relatedTables, (draft) => {
              for (const _entity of draft) {
                _entity.relation = [];
              }
            });
            resolve(noRelationTable);
          }
        );
      } else {
        resolve(relatedTables);
      }
    });
  };

  const getFieldList = (table, successCallback, errCallback) => {
    const connection = User.getConnection(Info.tenantMstId);
    const _method = (cb, data) => {
      cb(data, successCallback, errCallback);
    };
    const body = {
      tenantId: Info.tenantId,
      coCd: Info.coCd,
      moduleCd: Info.moduleCd,
      tableName: table.tableNm,
      baseDbName: table.baseDbName,
      entityType: table.entityType,
    };
    if (connection.connectionType === "direct") {
      body.connectionInfo = connection;
      _method(DataModelService.getDirectDataModelFieldListOnly, body);
    } else {
      body.accessToken = connection.token;
      body.host = connection.host;
      body.protocol = connection.protocol;
      body.tableNm = table.tableNm;
      DataModelService.getDataModelFieldListOnly(
        body,
        (res) => successCallback(res),
        errCallback
      );
    }
  };

  /**
   * 패널 클릭 이벤트
   * @param {*} e
   */
  const onClickPanel = (e) => {
    if (relationMode) {
      onRelationModeClick();
    } else {
      props.handleTabSelect("Properties");
      Event.handlePageClick(e, dispatch, activedComponent, statOutput);
    }
  };

  /**
   * 노드 위치 이동시 포지션값 적용
   * @param {[Object]} nodeChange
   */
  const onNodeCustomChange = (nodeChange) => {
    /*
    NodeChange : {[
    type : {
        "dimension" : 첫 드롭 시 또는 Zoom에 따른 노드 변화시
        "select" : 선택 
        "position" : 위치 이동
      }
    ]
    }
    */
    if (!ArrayUtils.isEmpty(nodeChange) && nodeChange[0].type === "position") {
      if (nodeChange.length === 1 && nodeChange[0].id === "track") {
        onNodesChange(nodeChange);
      } else if (nodeChange[0].dragging === true) {
        nodeChangeRef.current = nodeChange;
        onNodesChange(nodeChange);
      } else if (nodeChange[0].dragging === false) {
        if (nodeChangeRef.current) {
          const changedEntities = nodeChangeRef.current.map((_nodeInfo) => {
            const { id: entityId, position } = _nodeInfo;
            const changedEntity = statOutput.dataModelEntities.find(
              (e) => e.physEntityNm === entityId
            );
            const result = produce(changedEntity, (draft) => {
              draft.position = position;
            });
            return result;
          });
          EntityReduxHelper.updateEntities(
            dispatch,
            statOutput,
            changedEntities
          );
          nodeChangeRef.current = null;
        }
      }
    } else {
      onNodesChange(nodeChange);
    }
  };

  /**
   * 플로우 패널위에서 마우스 무브 이벤트
   * 릴레이션 연결할때만 사용하기 때문에 RelationMode에 따라 변경
   * @param {event}
   */
  const onFlowMouseMove = useCallback(
    (event) => {
      if (relationMode) {
        const reactFlowBounds =
          reactFlowWrapper.current.getBoundingClientRect();
        const position = reactFlowInstance.project({
          x: event.clientX - reactFlowBounds.left + 8, //마우스 무빙시 트래킹노드위에 커서가 올라가면 제대로 선택안되기 때문에 위치상 오차배치
          y: event.clientY - reactFlowBounds.top,
        });
        const TrackNodeChange = {
          dragging: true,
          id: "track",
          position: position,
          positionAbsolute: position,
          type: "position",
        };
        onNodesChange([TrackNodeChange]);
      }
    },
    [reactFlowInstance, relationMode]
  );

  /**
   * 트래킹 노드 생성
   * 릴레이션 엣지 생성
   * @param {String} entityId
   */
  const onRelationModeClick = (entityId, event) => {
    let newNode = [...nodes];
    const TrackingNodeIdx = newNode.findIndex((e) => e.id === "track");
    let newEdge = [...edges];
    const TrackingEdgeIdx = newEdge.findIndex((e) => e.id === "trackingEdge");

    if (!relationMode) {
      if (event) console.log(event.clientX, event.clientY);

      // 1. 마우스 트래킹 하는 노드 생성
      const TrackingNode = {
        id: "track",
        type: "tracking",
        position: { x: 0, y: 0 },
      };
      //최초 위치 보정값
      const correctionValue = {
        x: event.clientX,
        y: event.clientY,
      };
      const source = nodes.find((node) => node.id === entityId);
      setSourceEntity(source.data.entity);
      // 1-1 소스 엔티티 위치 파악
      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      TrackingNode.position = reactFlowInstance.project({
        x: reactFlowBounds.left + correctionValue.x,
        y: reactFlowBounds.top + correctionValue.y,
      });
      //기존에 있으면 업데이트 없으면 추가
      if (TrackingNodeIdx > -1) {
        newNode.splice(TrackingNodeIdx, 0, TrackingNode);
      } else {
        newNode.push(TrackingNode);
      }
      // 2. 소스 노드에서 마우스 트래킹 노드(invisible)를 타겟으로 하는 엣지생성
      const relationEdge = {
        id: `trackingEdge`,
        target: "track",
        source: entityId,
        type: "floating",
        markerEnd: {
          type: MarkerType.Arrow,
          width: 5,
          height: 5,
          color: "#60b9c4",
        },
        style: {
          strokeWidth: 2,
          stroke: "#60b9c4",
        },
      };
      // 이미 연결 작업중인지 확인
      if (TrackingEdgeIdx > -1) {
        newEdge.splice(TrackingEdgeIdx, 0, relationEdge);
      } else {
        newEdge.push(relationEdge);
      }
    } else {
      // 릴레이션 모드 종료
      // 트래킹 엣지 삭제
      // 트래킹 노드 삭제
      setSourceEntity(null);
      if (TrackingEdgeIdx > -1)
        newEdge = newEdge.filter((edge) => edge.id !== "trackingEdge");
      if (TrackingNodeIdx > -1)
        newNode = newNode.filter((node) => node.id !== "track");
    }
    setRelationMode(!relationMode);
    setNodes(newNode);
    setEdges(newEdge);
  };

  /**
   * 미니맵 또는 엔티티 목록에서 노드 선택시 해당 노드를 중앙으로 포커스 이동
   * @param {*} e
   * @param {*} _node
   */
  const onNodeClick = (e, _node) => {
    if (e) e.stopPropagation();
    const { nodeInternals } = store.getState(); // 캔버스 위에 그려진 노드 정보 전체 호출
    const nodes = Array.from(nodeInternals).map(([, node]) => node);

    if (nodes.length > 0) {
      const targetNode = nodes.find((node) => node.id === _node.id);

      const x = targetNode.position.x + targetNode.width / 2;
      const y = targetNode.position.y + targetNode.height * 0.2;
      const zoom = 0.8;

      setCenter(x, y, { zoom, duration: 1000 });
    }

    if (_node.data.entity) {
      const nodeChanger = [{ id: _node.id, type: "select", selected: true }];
      if (
        StringUtils.equalsIgnoreCase(
          activedComponent.type,
          Enums.EntityComponentType.ENTITY
        )
      ) {
        nodeChanger.push({
          id: activedComponent.physEntityNm,
          type: "select",
          selected: false,
        });
      }
      onNodesChange(nodeChanger);
      EntityReduxHelper.activateComponent(dispatch, _node.data.entity);
    }
  };

  /**
   * 테마 변경
   * @param {*} e
   * @param {*} theme
   */
  const onChangeTheme = (e, theme) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    setEditorTheme(theme);
    LocalStorageService.set(Enums.LocalStorageName.EDITOR_THEME, {
      userId: User.getId(),
      theme,
    });
  };

  const keyDownAction = (e) => {
    if (StringUtils.equalsIgnoreCase(e.key, "delete")) {
      EntityReduxHelper.removeEntity(dispatch, statOutput, activedComponent);
    }
  };

  const contextValue = {
    entityRelation: {
      sourceEntity,
      setSourceEntity,
      init: () => {
        setRelationMode(false);
        setSourceEntity();
      },
    },
    handleTabSelect: (tab) => props.handleTabSelect(tab),
    edges,
    setEdges,
    eagerEntityIds: eagerEntityIds,
    onRelationModeClick,
    relationMode,
    setRelationMode,
  };

  return (
    <FlowContext.Provider value={contextValue}>
      <div
        className={`reactflow-wrapper floatingedges ${
          isSidebarCollapsed ? "sidebar-collapse" : ""
        } ${editorTheme ?? "light"}`}
        ref={reactFlowWrapper}
      >
        <div className="command-button-wrapper">
          <EntityCommandButton
            tabType={tabType}
            setTabType={setTabType}
            navigate={navigate}
          />
        </div>
        {StringUtils.equalsIgnoreCase(tabType, "e") ? (
          <div className="flow-editor-wrapper">
            {isFieldLoading && (
              <div className="editor-loading-wrapper">
                <div className="data-load-box">
                  <CircularProgress color="inherit" size={13} />
                  &nbsp;&nbsp;&nbsp; 데이터를 불러오는 중입니다.
                </div>
              </div>
            )}
            <ReactFlow
              nodes={nodes}
              nodeTypes={nodeTypes}
              edges={edges}
              onNodesChange={onNodeCustomChange}
              onEdgesChange={onEdgesChange}
              // onEdgeClick={(e, edge) => console.log("Edge Clicked : ", edge)}
              onInit={setReactFlowInstance}
              onDrop={onDrop}
              onDragOver={onDragOver}
              edgeTypes={edgeTypes}
              maxZoom={5}
              minZoom={0.2}
              zoomOnDoubleClick
              onClick={onClickPanel}
              onMouseMove={onFlowMouseMove}
              snapToGrid
              onKeyDown={keyDownAction}
            >
              <Controls
                position={"top-left"}
                style={{
                  top: 0,
                  display: "flex",
                  outline: "1px solid gray",
                  borderRadius: "5px",
                  boxShadow: "1px 1px 3px black",
                }}
              >
                {editorTheme === "light" ? (
                  <ControlButton
                    title="어둡게"
                    onClick={(e) => onChangeTheme(e, "dark")}
                  >
                    <MdOutlineNightlightRound />
                  </ControlButton>
                ) : (
                  <ControlButton
                    title="밝게"
                    onClick={(e) => onChangeTheme(e, "light")}
                  >
                    <MdOutlineWbSunny />
                  </ControlButton>
                )}
              </Controls>
              <Background
                style={{
                  background: editorTheme === "light" ? "white" : "#282828",
                }}
              />
              <MiniMap
                zoomable
                pannable
                style={{
                  outline: "1px solid gray",
                  borderRadius: "5px",
                  boxShadow: "1px 1px 3px black",
                  top: 35,
                }}
                nodeColor={"#3c3c3c"}
                onNodeClick={onNodeClick}
                maskColor={"#D3D3D3cc"}
                position={"top-left"}
              />
              {/* 엔티티 목록 */}
              <EntityListAsFlowWidget
                FlowNode={FlowNode}
                onNodeClick={onNodeClick}
              />
            </ReactFlow>
          </div>
        ) : (
          <div className="entity-code-wrapper">
            <EntityCodeMirror />
          </div>
        )}
      </div>
    </FlowContext.Provider>
  );
}

export default EntityFlowEditor;

const EntityListAsFlowWidget = ({ FlowNode, onNodeClick, ...props }) => {
  /*엔티티 목록 접기 */
  const [isListCollapse, setIsListCollapse] = useState(true);
  return (
    <div
      className={`flow-entity-list ${isListCollapse ? "flow-collapse" : ""}`}
      style={{ top: 200, left: 0 }}
    >
      <div className="header">
        <span
          onClick={(e) => {
            e.stopPropagation();
            setIsListCollapse(false);
          }}
        >
          엔티티 목록
        </span>{" "}
        {isListCollapse ? (
          <span
            onClick={(e) => {
              e.stopPropagation();
              setIsListCollapse(false);
            }}
          >
            ▼
          </span>
        ) : (
          <span
            onClick={(e) => {
              e.stopPropagation();
              setIsListCollapse(true);
            }}
          >
            ▲
          </span>
        )}
      </div>
      {!isListCollapse && (
        <div className="body-list">
          {FlowNode?.map((node, index) => {
            const {
              data: {
                entity: { physEntityNm, tableNm },
              },
            } = node;
            return (
              <div
                className="node"
                key={physEntityNm}
                onClick={(e) => onNodeClick(e, node)}
              >
                <span>{physEntityNm}</span>
                {/* <span>{tableNm}</span> */}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
