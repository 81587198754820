/*!
 * Entity Builder Redux Action for react v.17
 *
 * 아래의 Redux에서 관리되는 정보에 대한 Action
 *   - 현재 Activation 되어있는 Component/Layout에 대한 감지
 *   - 현재 작업중인 Builder output에 대한 정보
 *
 *   Author: Bizentro
 *   Date: 2021-04
 */

import ActionType from "components/builder/BuilderActionType";

/**
 * 선택된 또는 신규 추가된 Component를 활성화 시킨다.
 * @param {*} component
 * @returns
 */
export const activateComponent = (component) => {
  return { type: ActionType.DATA_MODEL.ACTIVATE.ENTITY, component };
};

export const activateComponentProperty = (props) => {
  return {
    type: ActionType.DATA_MODEL.ACTIVATE.PROPERTY_UPDATE,
    payload: props,
  };
};

/**
 * DataModel 목록에서 모델을 불러오거나 할때 렌더링 데이터 모델을 업데이트 한다.
 * @param {*} dataModel
 * @returns
 */
export const updateDataModel = (dataModel) => {
  return { type: ActionType.DATA_MODEL.UPDATE, dataModel };
};

export const initDataModel = () => {
  return {
    type: ActionType.DATA_MODEL.INIT,
  };
};

export const initActivedEntity = () => {
  return {
    type: ActionType.DATA_MODEL.ACTIVATE.INIT,
  };
};
