import { Enums } from "components/builder/BuilderEnum";
import Modal from "components/common/modal/UModal";
import UModalTemplate from "components/common/modal/UModalTemplate";
import JsonUtils from "components/common/utils/JsonUtils";
import ObjectUtils from "components/common/utils/ObjectUtils";
import StringUtils from "components/common/utils/StringUtils";
import produce from "immer";
import React, { useEffect } from "react";
import { Button, Col, Form, FormGroup, InputGroup, Row } from "react-bootstrap";
import ProcessModal from "./process/ProcessModal";
import {
  getEntityPropertyValue,
  getSourceEntity,
} from "components/builder/workflow/editor/render/WorkflowRenderUtils";
import { Tooltip } from "@mui/material";

const PARAM_TYPE = {
  MAP: "Map",
  LIST: "List",
  HTTP_SERVELT_REQUEST: "HttpServeltRequest",
};

const DEFAULT_INPUT_SET = [
  {
    paramVariable: "request",
    paramType: PARAM_TYPE.HTTP_SERVELT_REQUEST,
  },
  {
    paramVariable: "requestParam",
    paramType: PARAM_TYPE.MAP,
  },
];

class ConnectorPopup extends ProcessModal {
  constructor(props) {
    super(props);
    this.getInputType = this.getInputType.bind(this);
    this.onClickParam = this.onClickParam.bind(this);
    this.onChangeValue = this.onChangeValue.bind(this);
    this.filterRef = React.createRef();
    this.state = {
      connector: {
        connectorNm: "",
        filter: "",
        elseYn: false,
      },
      inputRefList: DEFAULT_INPUT_SET,
      targetProcessType: "",
      sourceProcessType: "",
    };
  }

  componentDidMount() {
    //source id를 process 찾고 그의 부모에 해당하는 노드를 찾을것
    let entityList = [...this.state.inputRefList];
    const sourceEntityList = getSourceEntity(
      this.props.sourceCompId,
      this.props.workflow
    );
    entityList = [...entityList, ...this.getInputType(sourceEntityList)];
    //타겟 타입 확인
    const target = JsonUtils.findNode(
      this.props.workflow,
      "compId",
      this.props.targetCompId
    );
    //Source 타입 확인
    const source = JsonUtils.findNode(
      this.props.workflow,
      "compId",
      this.props.sourceCompId
    );
    this.setState(
      produce(this.state, (draft) => {
        draft.targetProcessType = target.processType;
        draft.sourceProcessType = source.processType;
        draft.inputRefList = entityList;
        if (this.props.connectionData) {
          draft.connector = { elseYn: false, ...this.props.connectionData };
        }
      })
    );
  }

  /**
   * 이전에 연결된 소스 엔티티를 Input Param으로 전환
   * @returns
   */
  getInputType = (sourceList) => {
    const result = [];
    for (const process of sourceList) {
      if (StringUtils.includes(process.processType, this.returnNodeTypeList)) {
        const _entityPropertyValues = getEntityPropertyValue(process);
        for (const _entity of _entityPropertyValues) {
          const obj = {};
          obj.paramName = _entity.entityNm;
          obj.paramVariable = _entity.entityVariable;
          obj.paramType = PARAM_TYPE.MAP;
          if (_entity.parameterType) {
            if (_entity.parameterType === "multi") {
              obj.paramType = PARAM_TYPE.LIST;
            }
          }

          switch (_entity.processType) {
            case Enums.WorkflowProcessType.SELECT_ENTITY:
              if (
                !StringUtils.equalsIgnoreType(_entity.maxDataCount, 1) &&
                !_entity.filterOption.primaryFilter
              )
                obj.paramType = PARAM_TYPE.LIST;
              break;
            case Enums.WorkflowProcessType.DATA_AGGREGATION:
              if (_entity.groupColumnList.length > 0)
                obj.paramType = PARAM_TYPE.LIST;
              break;
            case Enums.WorkflowProcessType.SELECT_ENTITY_BY_QUERY:
              obj.paramType = PARAM_TYPE.LIST;
              break;
            case Enums.WorkflowProcessType.CALL_STORED_PROCEDURE:
              if (_entity.returnType === "multi")
                obj.paramType = PARAM_TYPE.LIST;
              break;
            default:
              break;
          }
          if (
            !ObjectUtils.isEmpty(obj) &&
            !result.find((r) => r.paramVariable === obj.paramVariable)
          ) {
            result.push(obj);
          }
        }
      }
    }
    return result;
  };

  /**
   * Param Input 클릭
   * @param {*} param
   */
  onClickParam = (param) => {
    if (StringUtils.isEmpty(this.state.connector.filter)) {
      this.setState(
        produce(this.state, (draft) => {
          draft.connector.filter = param.paramVariable;
        })
      );
    } else {
      const splited = this.state.connector.filter.split(".");
      splited.push(param.paramVariable);
      this.setState(
        produce(this.state, (draft) => {
          draft.connector.filter = splited.join(".");
        })
      );
    }
  };

  onChangeValue = (e) => {
    this.setState(
      produce(this.state, (draft) => {
        draft.connector[e.target.id] = e.target.value;
      })
    );
  };

  renderProcessNm() {
    return (
      <Row className="mb-3">
        <Col xs={2}>
          <Form.Label>Connector 명</Form.Label>
        </Col>
        <Col xs={10}>
          <Form.Control
            placeholder="커넥터 명을 입력해주세요"
            value={this.state.connector.connectorNm}
            onChange={this.onChangeValue}
            id={"connectorNm"}
          />
        </Col>
      </Row>
    );
  }

  validateCommonParam() {
    if (this.onValidationCheck()) {
      this.onClickConfirm();
    }
  }

  onValidationCheck() {
    return true;
  }

  onClickConfirm() {
    if (this.props.callbackFnc) {
      this.props.callbackFnc(this.state.connector);
    }
  }

  renderBody() {
    return (
      <>
        <FormGroup className="mb-1">
          <Form.Label>Input</Form.Label>
        </FormGroup>
        <div className="connector-input-wrapper mb-3">
          {this.state.inputRefList.map((item) => {
            const disabled = StringUtils.includes(item.paramVariable, [
              "request",
              "requestParam",
            ]);
            return (
              <div
                className={`connector-input ${disabled ? "disabled" : ""}`}
                key={item.paramVariable}
                onClick={(e) => this.onClickParam(item)}
              >
                <div className="param-name">{item.paramName}</div>
                <div className="param-name">{item.paramVariable}</div>
                <div className="param-type">{item.paramType}</div>
              </div>
            );
          })}
        </div>

        <FormGroup className="mb-3">
          <Row>
            <Form.Label as={Col} xs={1}>
              조건
            </Form.Label>
            <Col xs={3}>
              <Tooltip
                title={
                  "여러 커넥터(조건)에 해당하는 경우가 없을 때 마지막으로 실행되는 커넥터"
                }
                placement="right"
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    userSelect: "none",
                  }}
                >
                  <Form.Check
                    id="elseYn"
                    checked={this.state.connector.elseYn}
                    onChange={(e) => {
                      this.setState(
                        produce(this.state, (draft) => {
                          draft.connector.elseYn = e.target.checked;
                          draft.connector.filter = "";
                        })
                      );
                    }}
                  />
                  <label htmlFor="elseYn">맨 마지막으로 실행 (else)</label>
                </div>
              </Tooltip>
            </Col>
          </Row>

          <Form.Control
            value={this.state.connector.filter}
            id="filter"
            onChange={this.onChangeValue}
            ref={this.filterRef}
            disabled={this.state.connector.elseYn}
          />
        </FormGroup>
        {StringUtils.equalsIgnoreCase(
          this.state.targetProcessType,
          "ErrorHandle"
        ) && (
          <ErrorValidationInput
            param={this.state.connector}
            onChangeValue={this.onChangeValue}
          />
        )}
      </>
    );
  }
  /**
   * 모달 렌더링 부분
   * @returns
   */
  renderModal() {
    return (
      <Modal>
        <Modal.Header title={`Connector`} />
        <Modal.Body>
          <UModalTemplate className={"workflow-modal-template connector-popup"}>
            {this.renderProcessNm()}
            {this.renderBody()}
          </UModalTemplate>
        </Modal.Body>
        <Modal.Footer>{this.renderFooter()}</Modal.Footer>
      </Modal>
    );
  }
}

export default ConnectorPopup;

const ErrorValidationInput = ({ param, onChangeValue, ...props }) => {
  return (
    <>
      <FormGroup className="mb-3">
        <Form.Label>에러 코드</Form.Label>
        <Form.Control
          value={param.errCode}
          id="errCode"
          onChange={onChangeValue}
          placeholder="에러코드를 입력해주세요."
        />
      </FormGroup>
      <FormGroup className="mb-3">
        <Form.Label>에러 메세지</Form.Label>
        <Form.Control
          value={param.message}
          id="message"
          onChange={onChangeValue}
          placeholder="에러 메세지를 입력해주세요."
        />
      </FormGroup>
    </>
  );
};
