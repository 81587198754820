import React, { useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import StringUtils from "../utils/StringUtils";
import ArrayUtils from "../utils/ArrayUtils";
import ObjectUtils from "../utils/ObjectUtils";
import USelectbox from "./USelectbox";
import * as Fa from "react-icons/fa";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import UInputPopup from "./UInputPopup";
import { RxDragHandleDots2 } from "react-icons/rx";

const UElementItem = (props) => {
  const { elItem, index, onChange, cols, moveItem } = props;

  const [{ isDragging }, drag] = useDrag(() => ({
    type: "elType",
    item: { elItem, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item, monitor) => {},
  }));

  const [{ isOver }, drop] = useDrop({
    accept: "elType",
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
    hover: (item) => {},
    drop: (item) => {
      if (item.index !== index) {
        moveItem(item.index, index);
      }
    },
  });

  return (
    <div
      className="w-full el-column"
      key={index}
      ref={(node) => drag(drop(node))}
      style={{
        opacity: isOver ? "0.3" : "1",
        cursor: "move",
        visibility: isDragging && "hidden",
      }}
    >
      <RxDragHandleDots2
        size={17}
        style={{ margin: "6px -7px 6px -5px" }}
      ></RxDragHandleDots2>
      <div className="all-chk propertyValue" style={{ cursor: "move" }}>
        <input
          type="checkbox"
          id={"isChecked_" + index}
          name="chkbox"
          checked={StringUtils.defaultString(elItem["isChecked"], false)}
          onChange={onChange}
          style={{ cursor: "pointer" }}
        />
      </div>
      {!ArrayUtils.isEmpty(cols)
        ? cols.map((col, colIndex) => {
            let isReadonly = col.readonly;

            if (
              !ObjectUtils.isEmpty(isReadonly) &&
              typeof isReadonly === "function"
            ) {
              isReadonly = isReadonly.call(this, elItem, index);
            }

            if (col.type === "input") {
              return (
                <div
                  className={`propertyValue ${
                    col.className ? col.className : ""
                  }`}
                  key={StringUtils.getUuid()}
                  style={col.style}
                >
                  <input
                    type="text"
                    id={col.id + "_" + index}
                    defaultValue={StringUtils.defaultString(elItem[col.id])}
                    className="form-control form-control-sm"
                    onBlur={onChange}
                    placeholder={col.placeholder}
                    readOnly={StringUtils.defaultString(isReadonly, false)}
                  />
                </div>
              );
            } else if (col.type === "select") {
              return (
                <div
                  className={`propertyValue ${
                    col.className ? col.className : ""
                  }`}
                  key={StringUtils.getUuid()}
                  style={col.style}
                >
                  <USelectbox
                    id={col.id + "_" + index}
                    defaultValue={StringUtils.defaultString(elItem[col.id])}
                    onChange={onChange}
                    disabled={StringUtils.defaultString(isReadonly, false)}
                    {...col.settings}
                  />
                </div>
              );
            } else if (col.type === "button") {
              //icon Tag 불러오기
              let IconTag = !StringUtils.isEmpty(col.icon)
                ? col.icon
                : Fa[StringUtils.convertKebabToPascal(elItem["icon"])];

              let buttonStyle = {};
              if (typeof col.settings.style === "function") {
                buttonStyle = {
                  ...col.settings.style.call(this, elItem, index),
                };
              } else if (typeof col.settings.style === "object") {
                buttonStyle = { ...col.settings.style };
              }

              let isShowLabel =
                StringUtils.isEmpty(col.isShowLabel) || col.isShowLabel;
              return (
                <div
                  className={`propertyValue btn-span ${
                    col.className ? col.className : ""
                  }`}
                  key={StringUtils.getUuid()}
                  style={col.style}
                >
                  <button
                    id={col.id + "_" + index}
                    onClick={col.settings.onClick}
                    style={{ ...buttonStyle }}
                    className="btn btn-sm w-full el-list-btn"
                    data-item={JSON.stringify(elItem)}
                  >
                    {col.isShowIcon ? (
                      IconTag ? (
                        isShowLabel ? (
                          <IconTag className="mr-5" />
                        ) : (
                          <IconTag />
                        )
                      ) : isShowLabel ? (
                        <span className="mr-5 w-10" />
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                    {isShowLabel ? col.label : ""}
                  </button>
                </div>
              );
            } else if (col.type === "switch") {
              return (
                <div
                  className={`propertyValue ${
                    col.className ? col.className : ""
                  }`}
                  key={StringUtils.getUuid()}
                  style={col.style}
                >
                  <BootstrapSwitchButton
                    id={col.id + "_" + index}
                    checked={StringUtils.defaultString(
                      elItem[col.id],
                      elItem[col.default]
                    )}
                    size="sm"
                    onstyle="primary"
                    offstyle="dark"
                    onlabel={StringUtils.defaultString(col.onlabel, "Yes")}
                    offlabel={StringUtils.defaultString(col.offlabel, "No")}
                    onChange={(val) => {
                      onChange({
                        target: {
                          id: col.id + "_" + index,
                          value: val,
                        },
                      });
                    }}
                  />
                </div>
              );
            } else if (col.type === "popup") {
              return (
                <div
                  className={`propertyValue ${
                    col.className ? col.className : ""
                  }`}
                  key={StringUtils.getUuid()}
                  style={col.style}
                >
                  <UInputPopup
                    id={col.id + "_" + index}
                    defaultValue={StringUtils.defaultString(elItem[col.id])}
                    onClick={col.settings.onClick}
                    onBlur={onChange}
                    placeholder={col.settings.ArrayUtilsplaceholder}
                    readonlyTp={col.settings.readonlyTp}
                  />
                </div>
              );
            }
          })
        : ""}
    </div>
  );
};

export default UElementItem;
