import { Checkbox, FormControlLabel } from "@mui/material";
import { Enums } from "components/builder/BuilderEnum";
import { stopEvent } from "components/builder/ui/editor/handler/UIEditorEventHandler";
import Popup from "components/common/Popup";
import Modal from "components/common/modal/UModal";
import User from "components/common/utils/UserUtils";
import { useState } from "react";
import LocalStorageService from "services/common/LocalService";

function SaveQuestPopup({ callback, closeCallback, ...props }) {
  const [autoSave, setAutoSave] = useState("N");

  const onClickAutoSave = (e) => {
    e.stopPropagation();
    setAutoSave(e.target.checked ? "Y" : "N");
  };

  const onClickConfirm = (e) => {
    stopEvent(e);
    if (autoSave === "Y") {
      const userId = User.getId();
      const body = {
        userId,
        autoSave,
      };
      LocalStorageService.set(Enums.LocalStorageName.WORKFLOW_AUTOSAVE, body);
    }
    if (callback) {
      callback();
    }
  };

  const onClickConfirmWithoutSave = () => {
    if (closeCallback) {
      closeCallback();
    }
    Popup.close();
  };

  return (
    <Modal>
      <Modal.Header />
      <Modal.Body>
        <p>저장하지 않은 내용은 자동 저장 됩니다.</p>
        <p>진행하시겠습니까?</p>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={autoSave === "Y" ? true : false}
                onClick={onClickAutoSave}
              />
            }
            label="다시 묻지 않기"
          />
        </div>
      </Modal.Body>
      <Modal.Footer closeText="아니오">
        <Modal.Footer.Button onClick={onClickConfirm}>예</Modal.Footer.Button>
        <Modal.Footer.Button
          onClick={onClickConfirmWithoutSave}
          variant="outline-success"
        >
          저장하지 않고 진행
        </Modal.Footer.Button>
      </Modal.Footer>
    </Modal>
  );
}

export default SaveQuestPopup;
