import { Accordion } from "react-bootstrap";
import React from "react";

import LayoutComponent from "components/builder/ui/uiComponents/layout/LayoutComponent";
import {
  PropertyLable,
  PropertyValue,
} from "components/builder/ui/uiComponents/UIComponentStyle";
import { StringUtils, ObjectUtils } from "components/common/utils/CommonUtils";
import UIComponentSection from "components/builder/ui/editor/UIComponentSection";

class Container extends LayoutComponent {
  constructor(props) {
    super(props);
    //불필요하면 constructor 지우세요.
  }

  /**
   * Properties tab panel을 Redering
   * @returns
   */
  renderPropertiesPanel = () => {
    return (
      <React.Fragment>
        {/* Title */}
        {this.renderComponentTitle("Container")}
        <Accordion defaultActiveKey={[0, 1]} alwaysOpen>
          <Accordion.Item eventKey={0}>
            <Accordion.Header>기본정보</Accordion.Header>
            <Accordion.Body>
              <PropertyLable>ID</PropertyLable>
              <PropertyValue>
                <input
                  type="text"
                  id="id"
                  defaultValue={StringUtils.defaultString(
                    this.state.propertyValue.id
                  )}
                  className="form-control form-control-sm"
                  onBlur={this.onChange}
                  readOnly={this.state.propertyValue.isLoginTmp}
                />
              </PropertyValue>
            </Accordion.Body>
          </Accordion.Item>

          {/* Style property */}
          {this.renderStylePanel("Container", 1)}
        </Accordion>
      </React.Fragment>
    );
  };

  /**
   * Editor의 component를 Redering
   * << Layout editor props>>
   *   - compId - 현재 component의 고유 ID
   *   - componentInfo - drag & drop시 생성된 component object
   *   - style - dragging style이 포함된 style (사용자가 정의한 style은 각 component에서 적절히 적용해야함)
   *   - event="renderEditor" - 요청 구분
   * @returns
   */
  renderEditor = () => {
    /**
     * UIComponentSection props
     * -item
     * -style
     * -className
     */
    return (
      <UIComponentSection
        item={this.props.componentInfo}
        style={{
          ...this.props.style,
          ...this.getPropsStyle(this.props.componentInfo),
        }}
        className={`editor-container ${this.getEditorClassName()} ${
          StringUtils.isEmpty(this.state.propertyValue.className)
            ? ""
            : this.state.propertyValue.className
        }`}
      >
        {this.props.children}
      </UIComponentSection>
    );
  };
}
export default Container;
