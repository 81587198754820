import WijmoGrid from "components/common/element/WijmoGrid";
import CommonUtils from "components/common/utils/CommonUtils";
import {
  AdminSettingButton,
  AdminSettingContents,
} from "components/setting/config/AdminSettingMain";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import SystemService from "services/common/SystemService";
import styled from "styled-components";

const EnvBox = styled(AdminSettingContents)``;

const columns = [
  {
    field: "envName",
    headerName: "환경 명",
    width: 150,
  },
  {
    field: "envType",
    headerName: "환경 타입",
    width: 150,
  },
  {
    field: "tenantId",
    headerName: "테넌트 ID",
    width: 150,
  },
  {
    field: "coCd",
    headerName: "회사 코드",
    width: 150,
  },
  {
    field: "applicationKind",
    headerName: "앱 종류",
    width: 150,
  },
  {
    field: "applicationUrl",
    headerName: "앱 URL",
    width: 150,
  },

  {
    field: "datasourceName",
    headerName: "데이터 소스 명",
    width: 150,
  },
  {
    field: "dbHost",
    headerName: "DB 호스트",
    width: 150,
  },
  {
    field: "dbName",
    headerName: "DB 명",
    width: 150,
  },
  {
    field: "dbPasswordIntYn",
    headerName: "DB 패스워트 숫자 여부",
    width: 200,
  },
  {
    field: "dbType",
    headerName: "DB 타입",
    width: 150,
  },
  {
    field: "dbUserName",
    headerName: "DB User Name",
    width: 150,
  },
  {
    field: "dbDesc",
    headerName: "DB 설명",
    width: 150,
  },
  {
    field: "ipAddr",
    headerName: "IP 주소",
    width: 150,
  },
  {
    field: "jdbcConnOption",
    headerName: "JDBC 연결 옵션",
    width: 150,
  },
  {
    field: "updtUserId",
    headerName: "최종 수정자",
    width: 150,
  },
  {
    field: "updtDt",
    headerName: "최종 수정일",
    width: 200,
    renderCell: (params) => `${CommonUtils.getDate(params.updtDt, "datetime")}`,
    // valueGetter: (params) =>
    //   `${CommonUtils.getDate(params.row.updtDt, "datetime")}`,
  },
];

function SystemEnvManage() {
  const [envList, setEnvList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageSize, setPageSize] = useState(20);
  useEffect(() => {
    getEnvList();
    return () => {
      setEnvList([]);
    };
  }, []);

  const getEnvList = () => {
    setIsLoading(true);
    SystemService.getEnvList({}, (res) => {
      setIsLoading(false);
      setEnvList(res.data);
    });
  };

  return (
    <>
      <EnvBox>
        <WijmoGrid
          isLoading={isLoading}
          columns={columns}
          rows={envList}
          pageSize={pageSize}
          rowsPerPageOptions={[pageSize]}
          style={{ height: "70vh" }}
        />
      </EnvBox>
      <AdminSettingButton>
        <Button>추가</Button>
        <Button>삭제</Button>
        <Button>확인</Button>
      </AdminSettingButton>
    </>
  );
}

export default SystemEnvManage;
