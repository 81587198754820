import React from "react";
import {
  ImFileEmpty,
  ImFolderOpen,
  ImDisplay,
  ImDownload,
} from "react-icons/im";
import {
  FaRegTrashAlt,
  FaRegCopy,
  FaCodeBranch,
  FaUserAlt,
  FaCogs,
  FaBook,
  FaEdit,
  FaSearch,
} from "react-icons/fa";
import { VscEditorLayout } from "react-icons/vsc";
import { MdCastConnected, MdHelpCenter } from "react-icons/md";

import { StringUtils } from "components/common/utils/CommonUtils";
import User from "components/common/utils/UserUtils";
import { connect } from "react-redux";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { Tooltip } from "./BuilderHeader";

class BuilderToolbarButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.onButtonClick = this.onButtonClick.bind(this);
  }
  HEADER_TOOLBAR_BUTTONS = {
    new: {
      icon: <ImFileEmpty size="18" />,
      text: " 신규화면",
      caret: true,
    },
    open: {
      icon: <ImFolderOpen size="18" />,
      text: " 불러오기",
      caret: false,
    },
    preview: {
      icon: <ImDisplay size="18" />,
      text: " 미리보기",
      caret: false,
    },
    editor: {
      icon: <VscEditorLayout size="18" />,
      text: " UI 에디터",
      caret: false,
    },
    download: {
      icon: <ImDownload size="18" />,
      text: " 다운로드",
      caret: false,
    },
    edit: {
      icon: <FaEdit size="18" />,
      text: " 편집",
      caret: true,
    },
    clone: {
      icon: <FaRegCopy size="18" />,
      text: " 복사",
      caret: false,
    },
    remove: {
      icon: <FaRegTrashAlt size="18" />,
      text: " 삭제",
      caret: false,
    },
    connect: {
      icon: <FaCodeBranch size="18" />,
      icon_connected: <MdCastConnected size={"18"} />,
      text: " 연결 설정",
      text_expired: " 연결 만료",
      text_connected: " 연결 됨",
      caret: true,
    },
    deploy: {
      icon: <AiOutlineCloudUpload size="18" />,
      text: ` 프로그램 배포`,
      caret: false,
    },
    user: {
      icon: <FaUserAlt size="18" />,
      text: ` ${this.props.text}`,
      caret: true,
    },
    setting: {
      icon: <FaCogs size="18" />,
      text: ` 환경설정`,
      caret: false,
    },
    document: {
      icon: <FaBook size="18" />,
      text: ` 사용 가이드`,
      caret: false,
    },
    search: {
      icon: <FaSearch size="18" />,
      text: ` 통합 검색`,
      caret: false,
    },
  };

  /**
   * 버튼에 Props 데이터가 있으면 해당 부분 우선 적용
   * @returns
   */
  getButtonProps = () => {
    const ButtonObj =
      { ...this.HEADER_TOOLBAR_BUTTONS[this.props.buttonId] } || {};
    if (this.props.icon !== undefined) ButtonObj.icon = this.props.icon;
    if (this.props.text !== undefined)
      ButtonObj.text = StringUtils.defaultString(this.props.text, "undefined");
    if (this.props.caret !== undefined) ButtonObj.caret = this.props.caret;

    //우측 나브바 아이콘만 노출할때
    if (this.props.iconOnly) {
      ButtonObj.text = "";
    }
    return ButtonObj;
  };

  /**
   * 버튼 클릭 이벤트
   */
  onButtonClick = (e) => {
    if (StringUtils.indexOf(this.props.className, "disabled") > -1)
      return false;
    else this.props.onClick(e);
  };

  /**
   * 툴바 렌더링
   * @returns
   */
  renderToolbar = () => {
    const buttonProps = this.getButtonProps();
    if (this.props.style === "button") {
      if (this.props.buttonId === "divider") return <div className="divider" />;
      else
        return (
          <button
            className={`btn btn-app ${StringUtils.defaultString(
              this.props.className
            )} `}
            data-button-id={this.props.buttonId}
            onClick={this.onButtonClick}
          >
            {StringUtils.defaultString(buttonProps.icon)}
            {buttonProps.text}
          </button>
        );
    } else if (this.props.style === "menu") {
      if (this.props.buttonId === "divider") return "";
      else
        return (
          <div
            className={`nav-button ${
              buttonProps.caret ? "caret" : ""
            } ${StringUtils.defaultString(this.props.className)} ${
              this.props.connected ? "connected" : ""
            }
            ${
              User.getConnection(this.props.workspace.tenantMstId, "expired")
                ? "expired"
                : ""
            }
            `}
            data-button-id={this.props.buttonId}
            onClick={this.onButtonClick}
          >
            {this.props.connected ? (
              User.getConnection(
                this.props.workspace.tenantMstId,
                "expired"
              ) ? (
                <>
                  {StringUtils.defaultString(buttonProps.icon_connected)}
                  {StringUtils.defaultString(
                    buttonProps.text_expired,
                    "undefined"
                  )}
                </>
              ) : (
                <>
                  {StringUtils.defaultString(buttonProps.icon_connected)}
                  {StringUtils.defaultString(
                    ` ${User.getConnection(
                      this.props.workspace.tenantMstId,
                      "connectionNm"
                    )} ${buttonProps.text_connected}`,
                    "undefined"
                  )}
                </>
              )
            ) : (
              <>
                {StringUtils.defaultString(buttonProps.icon)}
                {buttonProps.text}
              </>
            )}
          </div>
        );
    }
  };

  render() {
    if (StringUtils.isEmpty(this.props.tooltipTitle)) {
      return (
        <div className="link" role="button">
          {this.renderToolbar()}
        </div>
      );
    } else {
      return (
        <Tooltip
          title={this.props.tooltipTitle}
          placement="bottom"
          mini={this.props.mini}
        >
          <div className="link" role="button">
            {this.renderToolbar()}
          </div>
        </Tooltip>
      );
    }
  }
}

export default connect(
  (state) => {
    return {
      workspace: state.workspace,
    };
  },
  (dispatch) => {
    return {};
  }
)(BuilderToolbarButton);
