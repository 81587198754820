import React, { Fragment } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { FaCode } from "react-icons/fa";
import UModalCodeEditor from "components/common/code/UModalCodeEditor";
import { StringUtils } from "components/common/utils/CommonUtils";
import { BsJournalCode } from "react-icons/bs";

class UModalMiscCodeEditor extends UModalCodeEditor {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      formattedValue: this.props.defaultValue,
    };
    this.renderApiButton = this.renderApiButton.bind(this);
  }

  /**
   * 최초 로드시
   */
  componentDidMount = () => {
    console.log("componentDidMount[Class]");
    this.initialize();
    this.extensions = this.EDITOR_EXTENSIONS[this.props.fieldType];
    //this.setState({ formattedValue: this.props.defaultValue });
    console.log("componentDidMount[Class]");
  };

  componentDidUpdate(prevProps, prevState) {
    console.log("componentDidUpdate[Class]");
    //this.setState({ formattedValue: this.props.defaultValue });
  }

  /**
   * get Code value
   * @override
   * @returns
   */
  getCodeValue = () => {
    let codeValue = this.state.formattedValue;
    if (this.props.fieldType === "json" && !StringUtils.isEmpty(codeValue)) {
      if (typeof codeValue === "string") {
        try {
          codeValue = JSON.stringify(JSON.parse(codeValue), null, 2);
        } catch (e) {
          // if (this.props.jsonValidation === true) {
          //   codeValue =
          //     "########### Json 형식 오류  - " +
          //     e +
          //     "########### \n\n" +
          //     codeValue;
          // }
        }
      } else {
        codeValue = JSON.stringify(codeValue, null, 2);
      }
    }
    return codeValue;
  };
  /**
   * API 문서 연결 버튼
   * @override
   */
  renderApiButton = () => {
    if (this.props.documentPage) {
      return (
        <Fragment>
          <BsJournalCode
            size={19}
            className="editor-btn"
            title="사용 가이드"
            onClick={() => this.clickDocumentDetail(this.props.documentPage)}
          ></BsJournalCode>
        </Fragment>
      );
    }
  };

  render() {
    return (
      <div
        className={`event-editor code-editor ${this.state.editorTheme} ${
          this.state.isFullScreen ? "fullscreen" : ""
        } ${this.state.editorTheme ? this.state.editorTheme : ""} ${
          this.state.openDocument ? "open-event-detail" : ""
        }`}
      >
        <Tabs fill activeKey="eventCode" id="javascript-editor-tab">
          <Tab
            eventKey="eventCode"
            title={
              <span>
                <FaCode size="16" />
                <span className="tab-name"> Code</span>
              </span>
            }
          >
            {/* code mirror */}
            {this.renderCodeMirror()}
          </Tab>
        </Tabs>
        {/* editor toolbar buttons */}
        {this.renderToolbarButtons()}
      </div>
    );
  }
}

export default React.memo(UModalMiscCodeEditor);
