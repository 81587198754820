import StringUtils from "components/common/utils/StringUtils";
import * as icon from "components/common/icon/UIcons";
import { RiDragDropFill } from "react-icons/ri";
import { Enums } from "components/builder/BuilderEnum";

export const getLabel = (comp) => {
  const componentClass = comp.editorAttr?.componentClass;
  let DraggableIcon;
  if (!StringUtils.isEmpty(componentClass)) {
    DraggableIcon = icon[StringUtils.substringAfter(componentClass, "/")];
    if (!DraggableIcon) {
      DraggableIcon = RiDragDropFill;
    }
  } else {
    DraggableIcon = RiDragDropFill;
  }

  if (DraggableIcon === RiDragDropFill) {
    return (
      <>
        <DraggableIcon size={15} />
        {"  "}
        {renderTreeName(comp)}
      </>
    );
  } else {
    return (
      <>
        <DraggableIcon
          size={15}
          className={`u-icon-${StringUtils.substringAfter(
            componentClass,
            "/"
          )}`}
        />
        {"  "}
        {renderTreeName(comp)}
      </>
    );
  }
};

export const renderTreeName = (_component) => {
  if (
    StringUtils.equalsIgnoreCase(_component.type, Enums.ComponentType.COMPONENT)
  ) {
    return `${
      _component.propertyValue.label ||
      _component.propertyValue.formLabel ||
      _component.propertyValue.text ||
      _component.viewerAttr.className //위젯 컴포넌트
    }(${_component.viewerAttr.object})`;
  } else if (
    StringUtils.equalsIgnoreCase(_component.type, Enums.ComponentType.GRID)
  ) {
    return `${
      _component.propertyValue.gridOptions.title || _component.type
    }(GRID)`;
  } else if (
    StringUtils.equalsIgnoreCase(_component.type, Enums.ComponentType.FORM)
  ) {
    return `${_component.type} - ${_component.propertyValue.id || "NO ID"}`;
  } else if (
    StringUtils.equalsIgnoreCase(
      _component.type,
      Enums.ComponentType.WIDGET_CONTAINER
    )
  ) {
    //위젯
    return `WIDGET-${_component.viewerAttr.widgetType}`;
  } else {
    return _component.type;
  }
};

/**
 * 입력된 컴포넌트 아이디를 엔드포인트로 하는 노드트리 아이디 배열 반환
 * @param {output.page} outputPage
 * @param {String} compId
 * @returns
 */
export const generateNodeTreeIds = (outputPage, compId) => {
  let result = [];
  result.push(outputPage.compId);

  const roopChild = (childComp, parents) => {
    let ids = [...parents];
    ids.push(childComp.compId);
    if (StringUtils.equalsIgnoreCase(childComp.compId, compId)) {
      result = [...ids];
      return ids;
    } else if (
      StringUtils.equalsIgnoreCase(childComp.type, Enums.ComponentType.GRID)
    ) {
      return childComp.propertyValue.gridOptions.columns.map((child) => {
        return roopChild(child, ids);
      });
    } else if (childComp.child && childComp.child.length > 0) {
      return childComp.child.map((child) => {
        return roopChild(child, ids);
      });
    }
  };

  if (outputPage.child.length > 0)
    outputPage.child.map((child) => roopChild(child, result));

  return result;
};

/**
 * 페이지에서 노드를 찾으면서 과정에서 발견한 CompId를 리턴함
 * @param {*} page
 * @param {*} compId
 * @returns
 */
export const FindNodeFromPage = (page, compId) => {
  let result = {};
  let idList = [];
  const find = (_list, ids) => {
    _list.map((c) => {
      let newList = [...ids];
      if (c.compId === compId) {
        newList.push(c.compId);
        result = c;
        idList = newList;
        return c;
      } else if (c.child?.length > 0) {
        newList.push(c.compId);
        return find(c.child, newList);
      }
    });
  };
  find(page.child, [page.compId]);
  return [result, idList];
};
