import React from "react";
import StringUtils from "components/common/utils/StringUtils";

/**
 * Dragging 될때 화면에 그려지는 모양
 * @param {*} item
 * @returns
 */
export const GridColumn = ({ item }) => {
  /* useDragLayer 를 통해 Dragging 되는 item을 rendering할때 class는 적용되지 않기 때문에 css로 적용함 */
  const styles = {
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    borderTop: "2px solid #a18038",
    borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
    borderCollapse: "collapse",
    whiteSpace: "nowrap",
    backgroundColor: "#fff",
    cursor: "move",
    justifyContent: "space-around",
    padding: "3px 6px 3px 6px",
    minHeight: "40px",
    width: "180px",
    boxShadow:
      "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
  };
  return (
    <div style={styles}>
      {item.propertyValue
        ? StringUtils.defaultString(item.propertyValue.title)
        : ""}
    </div>
  );
};
