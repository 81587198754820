import { InputLabel, Select, TextField } from "@mui/material";
import * as Enums from "components/builder/BuilderEnum";
import {
  activateComponent,
  updateDataModel,
} from "components/builder/entity/reducers/EntityBuilderAction";
import { setDataModelSearchTerm } from "components/builder/ui/reducers/MenuAction";
import { AppContext } from "components/common/AppContextProvider";
import Message from "components/common/Message";
import Popup from "components/common/Popup";
import MuiConfig from "components/common/config/MuiConfig";
import MSelectbox from "components/common/element/MSelectbox";
import WijmoGrid from "components/common/element/WijmoGrid";
import CommonUtils, {
  ArrayUtils,
  ObjectUtils,
  StringUtils,
} from "components/common/utils/CommonUtils";
import User from "components/common/utils/UserUtils";
import PageTemplate from "page/common/PageTemplate";
import UnremovableEntityProgramListPopup from "page/popup/UnremovableEntityProgramListPopup";
import UserTransactionPopup from "page/popup/dataModel/UserTransactionPopup";
import { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { BiEditAlt } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import { RiDeleteBinLine } from "react-icons/ri";
import { RxOpenInNewWindow } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CodeService from "services/common/CodeService";
import LocalStorageService from "services/common/LocalService";
import DataModelService from "services/datamodel/DataModelService";
import ProgramService from "services/ui/ProgramService";

//DB에서 불러온 데이터 모델을 수정가는한 용도로 바꾸는 메서드
export const setDataModelEditable = (dataModel) => {
  const removeAuthorInfo = (object) => {
    const {
      insertUserId,
      insertDt,
      updtUserId,
      updtDt,
      userId,
      ...objectInfo
    } = object;

    return objectInfo;
  };
  dataModel = removeAuthorInfo(dataModel);
  const { dataModelEntities } = dataModel;

  dataModel.compId = StringUtils.getUuid();
  dataModel.type = Enums.EntityComponentType.DATA_MODEL;

  if (dataModelEntities?.length > 0) {
    const compIdGeneratedEntities = dataModelEntities.map((entity) => {
      const newEn = { ...removeAuthorInfo(entity) };
      newEn.compId = StringUtils.getUuid();
      newEn.type = Enums.EntityComponentType.ENTITY;
      newEn.position = newEn.position ? JSON.parse(newEn.position) : {};
      newEn.relation = newEn.relation ? JSON.parse(newEn.relation) : [];
      newEn.orderby = newEn.orderby ? JSON.parse(newEn.orderby) : [];
      newEn.whereJoinTable = newEn.whereJoinTable
        ? JSON.parse(newEn.whereJoinTable)
        : [];
      newEn.parameterList = newEn.parameterList
        ? JSON.parse(newEn.parameterList)
        : [];
      newEn.remark = newEn.remark ? JSON.parse(newEn.remark) : {};

      if (newEn.dataModelEntityFields?.length > 0) {
        newEn.dataModelEntityFields = newEn.dataModelEntityFields.map(
          (field) => {
            field = { ...removeAuthorInfo(field) };
            field.compId = StringUtils.getUuid();
            field.type = Enums.EntityComponentType.ENTITY_FIELD;
            field.fieldOption = field.fieldOption
              ? JSON.parse(field.fieldOption)
              : {};
            return field;
          }
        );
      }
      return newEn;
    });
    dataModel.dataModelEntities = compIdGeneratedEntities;
  }

  return dataModel;
};

const DataModelList = (props) => {
  // const {
  //   dataModel: { setList, setSearchTerm, searchTerm },
  // } = useContext(AppContext);
  const dataModelSearchTerm = useSelector(
    (state) => state.menu.dataModelSearchTerm
  );
  const [dataModelList, setDataModelList] = useState([]);
  const [dataModelNm, setDataModelNm] = useState(
    dataModelSearchTerm.dataModelNm || ""
  );
  const [description, setDescription] = useState(
    dataModelSearchTerm.description || ""
  );
  const [dataModelType, setDataModelType] = useState(
    dataModelSearchTerm.dataModelType || ""
  );
  const [dataModelId, setDataModelId] = useState(
    dataModelSearchTerm.dataModelId || null
  );
  const [pageSize, setPageSize] = useState(20);
  const naviagate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const [dataModelTypeList, setDataModelTypeList] = useState([]);
  const workspace = useSelector((state) => state.workspace);
  const {
    workspace: { openPopup },
  } = useContext(AppContext);

  const breadcrum = [
    {
      name: "데이터 모델 목록",
      url: Enums.BuilderPath.ENTITY_LIST,
      active: true,
    },
  ];

  const columns = [
    {
      field: "tenantId",
      headerName: "테넌트 ID",
      align: "center",
      width: 120,
    },

    {
      field: "coCd",
      headerName: "회사 코드",
      align: "center",
      width: 120,
    },
    {
      field: "dataModelId",
      headerName: "데이터 모델 UID",
      align: "center",
      width: 150,
    },
    {
      field: "dataModelNm",
      headerName: "데이터 모델 명",
      width: "*",
    },
    {
      field: "description",
      headerName: "데이터 모델 설명",
      width: "*",
    },
    {
      field: "dataModelTypeNm",
      headerName: "데이터 모델 유형",
      width: 120,
      renderCell: (param) => {
        const type = dataModelTypeList.find(
          (t) => t.id === param.dataModelType
        );
        return type?.text;
      },
    },
    {
      field: "componentClass",
      headerName: "사용자 Transaction",
      width: 200,
      align: "center",
      renderCell: (params) => {
        const buttonStyle = {
          width: "75%",
        };
        return (
          <Button
            size="sm"
            style={buttonStyle}
            onClick={(e) => onOpenUserTnxPopup(params)}
          >
            {ArrayUtils.isEmpty(params.dataModelUsrTnxs)
              ? "등록"
              : `(${params.dataModelUsrTnxs.length}) 편집`}
          </Button>
        );
      },
    },

    {
      field: "updtUserId",
      headerName: "최종 수정자",
      width: 150,
    },
    {
      field: "updtDt",
      headerName: "최종 수정일자",
      width: 150,
      align: "center",
      renderCell: (params) => CommonUtils.getDate(params.updtDt, "datetime"),
    },
    {
      field: "dataModelEdit",
      headerName: "편집",
      headerAlign: "center",
      width: 80,
      align: "center",
      renderCell: (params) => {
        return (
          <Button
            size="sm"
            variant="outline-success"
            onClick={() => onEditDataModel(params)}
          >
            <BiEditAlt />
          </Button>
        );
      },
    },
    {
      field: "dataModelDelete",
      headerName: "삭제",
      headerAlign: "center",
      width: 80,
      align: "center",
      renderCell: (params) => {
        return (
          <Button
            size="sm"
            variant="outline-danger"
            onClick={() => onDeleteDataModel(params)}
          >
            <RiDeleteBinLine />
          </Button>
        );
      },
    },
    {
      field: "dataModelDploy",
      headerName: "배포",
      headerAlign: "center",
      width: 80,
      align: "center",
      renderCell: (params) => {
        return (
          <Button
            size="sm"
            variant="outline-primary"
            onClick={() => onDeployDataModel(params)}
          >
            <AiOutlineCloudUpload />
          </Button>
        );
      },
    },
    {
      field: "newTab",
      headerName: "새 탭",
      headerAlign: "center",
      width: 80,
      align: "center",
      renderCell: (params) => {
        return (
          <Button
            size="sm"
            variant="outline-secondary"
            onClick={() => onOpenNewTab(params)}
          >
            <RxOpenInNewWindow />
          </Button>
        );
      },
    },
  ];

  useEffect(() => {
    if (!ObjectUtils.isEmpty(dataModelSearchTerm)) {
      setDataModelNm(dataModelSearchTerm.dataModelNm);
      setDescription(dataModelSearchTerm.description);
      setDataModelType(dataModelSearchTerm.dataModelType);
    }
    getDataModelList(dataModelSearchTerm);
    return () => {
      setDataModelList([]);
    };
  }, [dataModelSearchTerm]);

  useEffect(() => {
    let comboParams = { codeMstCd: "Z0014" };
    CodeService.getCodeCombo(comboParams, (res) => {
      setDataModelTypeList(res.data);
    });
    return () => {
      setDataModelTypeList([]);
    };
  }, []);

  /**
   * 데이터 모델을 사용중인 프로그램 목록을 보여주는 팝업
   * @param {*} programList
   */
  const openEntityProgramListPopup = (programList) => {
    //팝업창 열기
    const options = {
      effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
      style: {
        content: {
          width: "50%", //popup의 크기를 50% (default 60%)
        },
      },
    };

    Popup.open(
      <UnremovableEntityProgramListPopup programList={programList} />,
      options
    );
  };

  const onDeleteDataModel = (dataModel) => {
    // return Message.alert("개발 중입니다.", Enums.MessageType.INFO);
    Message.confirm("해당 데이터 모델을 삭제 하시겠습니까?", () => {
      //사용중인 프로그램 목록 먼저 확인
      const body = {
        dataModelId: dataModel.dataModelId,
        appReleaseId: workspace.appReleaseId,
      };
      ProgramService.getDataModelUsingProgramList(body, (res) => {
        if (res.data.length > 0) {
          //삭제시 사용중임 프로그램 목록 확인2
          openEntityProgramListPopup(res.data);
        } else {
          DataModelService.deleteDataModel(dataModel, (res) => {
            const { data } = res;
            Message.alert(
              data.message,
              data.isSuccess === "Y"
                ? Enums.MessageType.SUCCESS
                : Enums.MessageType.ERROR
            );
            if (data.isSuccess === "Y") {
              const newList = [...dataModelList];
              newList.splice(
                newList.findIndex(
                  (_dataModel) =>
                    _dataModel.dataModelId === dataModel.dataModelId
                ),
                1
              );
              setDataModelList(newList);
            }
          });
        }
      });
    });
  };

  /**
   * 데이터 모델 편집
   * @param {*} _dataModel
   */
  const onEditDataModel = (_dataModel) => {
    setIsLoading(true);
    DataModelService.getDataModel(
      {
        dataModelId: _dataModel.dataModelId,
      },
      (res) => {
        const dataModel = setDataModelEditable(res.data);
        dispatch(updateDataModel(dataModel));
        dispatch(activateComponent(dataModel));
        // setList([]);
        setIsLoading(false);
        naviagate(
          Enums.BuilderPath.ENTITY.MAIN + "/" + Enums.BuilderPath.ENTITY.CREATE
        );
      },
      () => setIsLoading(false)
    );
  };

  /**
   * 데이터 모델 배포
   * @param {*} _dataModel
   * @returns
   */
  const onDeployDataModel = (_dataModel) => {
    const deployConnection = User.getConnection(workspace.tenantMstId);
    if (!deployConnection?.token) {
      return Message.alert(
        "인증 서버 토큰이 확인 되지 않았습니다.\n 다시 연결 해주세요.",
        Enums.MessageType.ERROR
      );
    }
    //userId가 deploy서버 connectionID랑 param 명이 같기에 별도로 분리
    const { userId, ...connectionInfo } = deployConnection;
    const body = {
      ...connectionInfo,
      connectionUserId: userId,
      dataModelId: _dataModel.dataModelId,
      quickDeploy: "Y",
      description: "빠른 배포",
    };
    const _dpCb = () => {
      setIsLoading(false);
    };
    if (connectionInfo.connectionType === "proxy") {
      Message.confirm("해당 데이터 모델을 배포 하시겠습니까?", () => {
        setIsLoading(true);
        DataModelService.deployDataModel(
          body,
          (res) => {
            if (!res.isError) {
              Message.alert(
                "데이터 모델을 배포 하였습니다.",
                Enums.MessageType.SUCCESS
              );
              _dpCb();
            } else {
              Message.alert(res.message, Enums.MessageType.ERROR);
              _dpCb();
            }
          },
          () => {
            _dpCb();
          }
        );
      });
    } else if (connectionInfo.connectionType === "direct") {
      Message.confirm("해당 데이터 모델을 배포 하시겠습니까?", () => {
        setIsLoading(true);
        DataModelService.getDataModel(
          { dataModelId: body.dataModelId },
          (res) => {
            if (!res.isError) {
              let _dBody = { ...res.data };
              _dBody.description = "빠른 배포";
              _dBody.userId = User.getId();
              DataModelService.getUsrTnxList(
                { dataModelId: body.dataModelId },
                (tnxRes) => {
                  _dBody.dataModelUsrTnxs = tnxRes.data;
                  _dBody.resourceDm = JSON.stringify({ dataModel: [_dBody] });
                  _dBody.connectionInfo = connectionInfo;
                  DataModelService.deployDirectDataModel(
                    _dBody,
                    (res) => {
                      Message.alert(
                        "데이터 모델을 배포 하였습니다.",
                        Enums.MessageType.SUCCESS
                      );
                      _dpCb();
                    },
                    _dpCb
                  );
                },
                _dpCb
              );
            }
          },
          _dpCb()
        );
      });
    }
  };

  /**
   * 팝업에서 처리한 것을 업데이트 해줌
   */
  const updateRowData = (dataModel) => {
    const newDataModelList = [...dataModelList];
    const index = newDataModelList.findIndex(
      (_dm) => _dm.dataModelId === dataModel.dataModelId
    );
    newDataModelList[index] = dataModel;

    setDataModelList(newDataModelList);
  };

  const onOpenUserTnxPopup = (dataModel) => {
    const options = {
      effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
      style: {
        content: {
          width: "60%", //popup의 크기를 50% (default 60%)
        },
      },
    };
    Popup.open(
      <UserTransactionPopup
        title={`${dataModel.dataModelNm} `}
        dataModel={dataModel}
        updateRowData={updateRowData}
      />,
      options
    );
  };

  /**
   * 검색
   * @param {*} e
   */
  const onSearch = (e) => {
    if (e) e.preventDefault();
    const body = {
      dataModelNm: dataModelNm,
      dataModelType: dataModelType,
      description: description,
      dataModelId: dataModelId,
    };
    dispatch(setDataModelSearchTerm(body));
    getDataModelList(body);
  };

  /**
   * 데이터 모델 목록 호출
   * @param {*} _body
   */
  const getDataModelList = (_body = {}) => {
    setIsLoading(true);
    const body = {
      appId: workspace.appId,
      moduleCd: workspace.moduleCd,
      appReleaseId: workspace.appReleaseId,
      tenantId: workspace.tenantId,
      coCd: workspace.coCd,
      dataModelId: _body.dataModelId ? _body.dataModelId : dataModelId,
      dataModelNm: _body.dataModelNm ? _body.dataModelNm : dataModelNm,
      dataModelType: _body.dataModelType
        ? _body.dataModelType === "ALL"
          ? ""
          : _body.dataModelType
        : dataModelType,
      description: _body.description ? _body.description : description,
    };
    DataModelService.getDataModelListWithUsrTnx(body, (res) => {
      const { data } = res;
      const list = ProgramService.filterByTenantIdAndCoCd(
        data,
        workspace.tenantId,
        workspace.coCd,
        "dataModelNm"
      );
      setIsLoading(false);
      setDataModelList(list);
    });
  };

  /**
   * 키다운 이벤트
   * @param {*} e
   */
  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      onSearch();
    }
  };

  const onChangeType = (e) => {
    if (e) e.preventDefault();
    setDataModelType(e.target.value);
  };

  const onOpenNewTab = (datamodel) => {
    LocalStorageService.set(Enums.LocalStorageName.WORKSPACE, {
      userId: User.getId(),
      workspace: workspace,
    });
    const url = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;
    window.open(
      `${url}/newTabRedirect/datamodel/${datamodel.dataModelId}`,
      "_blank"
    );
  };

  return (
    <PageTemplate breadcrum={breadcrum}>
      <PageTemplate.Box boxClass="mb-0">
        {ObjectUtils.isEmpty(workspace) ? (
          <div className="workspace-empty-alert">
            <div className="alert-msg">워크 스페이스 설정이 필요 합니다.</div>
            <Button onClick={() => openPopup()}>팝업 열기</Button>
          </div>
        ) : (
          <>
            <Form>
              <Row className="pb-3 pt-3">
                <Col xs={2}>
                  <TextField
                    size={"small"}
                    label="데이터 모델 UID"
                    placeholder="데이터 UID 검색..."
                    value={dataModelId}
                    fullWidth={true}
                    onChange={(e) => setDataModelId(e.currentTarget.value)}
                    onKeyDown={onKeyDown}
                    type="number"
                  />
                </Col>
                <Col xs={2}>
                  <TextField
                    size={"small"}
                    label="데이터 모델 명"
                    placeholder="데이터 모델 명 검색..."
                    value={dataModelNm}
                    fullWidth={true}
                    onChange={(e) => setDataModelNm(e.currentTarget.value)}
                    onKeyDown={onKeyDown}
                  />
                </Col>
                <Col xs={2}>
                  <TextField
                    size={"small"}
                    label="데이터 모델 설명"
                    placeholder="데이터 모델 설명 검색..."
                    value={description}
                    fullWidth={true}
                    onChange={(e) => setDescription(e.currentTarget.value)}
                    onKeyDown={onKeyDown}
                  />
                </Col>
                <Col xs={2}>
                  <MSelectbox
                    size="small"
                    fullWidth={true}
                    data={[
                      { id: "ALL", text: "전체" },
                      ...dataModelTypeList.map((type) => ({
                        id: type.id,
                        text: type.text,
                      })),
                    ]}
                  >
                    <InputLabel id="module-label">데이터 모델 유형</InputLabel>
                    <Select
                      name="dataModelType"
                      labelId="module-label"
                      label="Data Model 유형"
                      fullWidth
                      color="primary"
                      value={dataModelType}
                      onChange={onChangeType}
                    ></Select>
                  </MSelectbox>
                </Col>
                <Col xs={1}>
                  <Button variant="primary" onClick={onSearch}>
                    검색 <BsSearch size="14" />
                  </Button>
                </Col>
              </Row>
            </Form>
            <Row style={{ height: "calc(100% - 70px)" }}>
              <Col xs={12}>
                <WijmoGrid
                  isLoading={isLoading}
                  columns={columns}
                  rows={dataModelList}
                  onRowDoubleClick={(params) => onEditDataModel(params)}
                  getRowId={(row) => row.dataModelId}
                  {...MuiConfig.grid.options}
                  rowsPerPageOptions={[pageSize]}
                  pageSize={pageSize}
                  style={{ height: `calc(70vh + 35px)` }}
                />
              </Col>
            </Row>
          </>
        )}
      </PageTemplate.Box>
    </PageTemplate>
  );
};

export default DataModelList;
