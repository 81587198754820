import ActionType from "components/builder/BuilderActionType";

export const setWorkspace = (info) => {
  return {
    type: ActionType.WORKSPACE.SET,
    payload: info,
  };
};
export const initWorkspace = () => {
  return {
    type: ActionType.WORKSPACE.INIT,
  };
};
