/*!
 * Builder TemplateHandler for react v.17
 *
 * Bizentro Layout Template을 구성하기 위한 Handler
 * Side component panel에서 Template을 Drag & Drop 할때 실행됨
 *
 *   Author: Bizentro
 *   Date: 2021-04
 */

import {
  StringUtils,
  ObjectUtils,
  JsonUtils,
} from "components/common/utils/CommonUtils";
import Popup from "components/common/Popup";

import { lazy, Suspense } from "react";

/**
 * Layout의 설정에 따라 Editor에 각 Component를 구성한다.
 * @param {*} pOutput
 * @param {*} pItem
 * @param {*} pOptions
 * @param {*} pTemplateComponents
 * @param {*} pCallbackFnc
 */
const UITemplateHandler = (
  pDropZone,
  pOutput,
  pItem,
  pOptions,
  pTemplateComponents,
  pCallbackFnc
) => {
  const dropZone = pDropZone;
  const output = pOutput;
  const item = pItem;
  const templateComponents = pTemplateComponents;
  const options = pOptions;

  /**
   * Caller의 callback function 호출
   * @param {*} data
   */
  const callbackTemplateFnc = (data) => {
    if (typeof pCallbackFnc === "function") {
      pCallbackFnc.call(this, data);
    }
  };

  /**
   * Caller의 callback function 호출
   * @param {*} data
   */
  const callbackGridFnc = (data) => {
    data.propertyValue.gridOptions.columns.forEach((element) => {
      if (StringUtils.includes(element.dataType, ["numeric", "number"])) {
        element.className = "header-right";
      } else {
        element.className = "header-left";
      }
    });

    if (typeof pCallbackFnc === "function") {
      pCallbackFnc.call(this, data);
    }
  };

  /**
   * 모든 Template 설정 popup 오픈
   */
  const openPopup = () => {
    if (!ObjectUtils.isEmpty(options.popupOptions)) {
      const popupOptions = options.popupOptions;
      const defaultOptions = { style: { content: {} } };
      if (!StringUtils.isEmpty(popupOptions.width)) {
        defaultOptions.style.content.width = popupOptions.width;
      }
      if (!StringUtils.isEmpty(popupOptions.height)) {
        defaultOptions.style.content.height = popupOptions.height;
      }

      const LayoutPopup = lazy(() =>
        import(
          "components/builder/ui/uiComponents/" + item.component.componentClass
        )
      );

      Popup.open(
        <Suspense fallback={<div></div>}>
          <LayoutPopup
            componentInfo={item.component}
            defaultValues={options.defaultValues}
            templateComponents={templateComponents}
            dropZone={dropZone}
            output={output}
            event="layout"
            callbackFnc={callbackTemplateFnc}
          />
        </Suspense>,
        defaultOptions,
        "TemplateSettingsPopup"
      );
    }
  };

  /**
   * Grid component 설정 popup 오픈
   * @param {String} source
   */
  const openGridPopup = (source) => {
    let dataModelId = JsonUtils.findDataModel(output, output.page.compId, true);
    // if (StringUtils.isEmpty(dataModelId)) {
    //   Message.alert(
    //     "Page에서 데이터 모델을 선택해주세요.",
    //     Enums.MessageType.ERROR
    //   );
    //   return false;
    // }

    let defaultOptions = { style: { content: {} } };
    if (
      !ObjectUtils.isEmpty(options.popupOptions) &&
      !StringUtils.isEmpty(options.popupOptions.width)
    ) {
      defaultOptions.style.content.width = options.popupOptions.width;
    }

    const LayoutPopup = lazy(() =>
      import("components/builder/ui/uiComponents/template/GridLayoutTemplate")
    );

    Popup.open(
      <Suspense fallback={<div></div>}>
        <LayoutPopup
          componentInfo={item.component}
          defaultValues={options.defaultValues}
          templateComponents={templateComponents}
          output={output}
          event={source}
          dropZone={dropZone}
          callbackFnc={callbackGridFnc}
          dataModelId={dataModelId}
        />
      </Suspense>,
      defaultOptions,
      "TemplateSettingsPopup"
    );
  };
  switch (item.component.componentClass) {
    case "template/SearchFormLayoutTemplate": //검색 Form layout template
    case "template/FormLayoutTemplate": //Form layout template
    case "template/InputGroupTemplate": //InputGroupTemplate layout template
    case "template/TabTemplate": //TabTemplate layout template
    case "template/StepWizardTemplate": //StepWizardTemplate layout template
    case "layout/Table": //Form layout template
    case "widget/NumericMetricTemplate": //Form layout template
    case "widget/FormWidgetTemplate": //form Widget
    case "widget/TextWidgetTemplate": //Text Widget
    case "widget/ListWidgetTemplate": //List Widget
    case "widget/chart/AMChart3WidgetTemplate": //Chart Widget
      openPopup();
      break;
    case "template/GridLayoutTemplate": //Grid layout template
      openGridPopup("template");
      break;
    case "grid/Grid": //Grid 단독 component template
      openGridPopup("component");
      break;
    default:
    //nothing
  }
};

export default UITemplateHandler;
