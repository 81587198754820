import StringUtils from "components/common/utils/StringUtils";
import ObjectUtils from "components/common/utils/ObjectUtils";
import ArrayUtils from "components/common/utils/ArrayUtils";
import JsonUtils from "components/common/utils/JsonUtils";
import NumberUtils from "components/common/utils/NumberUtils";
import SessionUtils from "components/common/utils/SessionUtils";
import { createPortal } from "react-dom";
import moment from "moment";
import Message from "../Message";
import { Enums } from "components/builder/BuilderEnum";
class CommonUtils {
  static optionalPortal = (styles, element) => {
    if (styles.position === "fixed") {
      return createPortal(
        element,
        document.getElementById("builder-draggable")
      );
    }
    return element;
  };

  /**
   *
   * @param {Date || moment} dateTime
   * @returns moment()
   */
  static getTZTime(_dateTime) {
    const dateTime = new Date(_dateTime);
    const currentTzOffset = new Date().getTimezoneOffset();
    // const currentTzTime = moment(dateTime).add(currentTzOffset, "m");
    return moment(dateTime);
  }

  static getDate(_dateTime, dateType = "date") {
    if (!_dateTime) return null;
    const nowDate = moment(this.getTZTime(_dateTime));

    if (dateType.toLocaleLowerCase() === "date") {
      return moment(nowDate).format("YYYY-MM-DD");
    } else if (dateType.toLocaleLowerCase() === "time") {
      return moment(nowDate).format("HH:mm:ss");
    } else if (dateType.toLocaleLowerCase() === "datetime") {
      return moment(nowDate).format("YYYY-MM-DD HH:mm:ss");
    }
  }

  /**
   * 칼럼에 입력하는 항목
   *  - 첫 글자는 영어만 가능
   *  - 그 뒤에는 영어 또는 숫자
   *  - 특수 문자 불가
   * @param {String} str
   */
  static CheckOnlyEng(str) {
    if (str.length === 1) {
      let reg_exp = new RegExp("^[a-zA-Z]");
      let match = reg_exp.exec(str);
      if (!match) {
        Message.alert("첫 글자는 영어만 가능합니다.", Enums.MessageType.WARN);
        return "";
      } else {
        return str;
      }
    } else if (str.length > 1) {
      let reg_exp = new RegExp("^[a-zA-Z][a-z|A-Z|0-9|]+$");
      let match = reg_exp.exec(str);
      if (!match) {
        Message.alert(
          "입력 형식이 맞지 않습니다.\n첫 글자는 영어만, 그 외에는 영어 또는 숫자만 사용가능합니다.",
          Enums.MessageType.WARN
        );
        return str.slice(0, str.length - 1);
      } else {
        return str;
      }
    } else {
      return str;
    }
  }

  /**
   * App에서 ConfigCd 값을 가져오는 로직
   * App 필수
   * @param {*} app
   * @param {*} configCd
   * @returns
   */
  static getAppConfig = (app, configCd) => {
    if (!app) return null;
    if (!configCd) return app.config;
    const config = app.config.find((config) =>
      StringUtils.equalsIgnoreCase(config.configCd, configCd)
    );
    if (config) {
      return config.configValue;
    } else {
      return null;
    }
  };
}

export {
  StringUtils,
  ObjectUtils,
  ArrayUtils,
  JsonUtils,
  NumberUtils,
  SessionUtils,
};

export default CommonUtils;
