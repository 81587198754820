import React from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";

import * as Enums from "components/builder/BuilderEnum";

/* test용 board component */
import BoardList from "page/prototype/BoardList";
import BoardForm from "page/prototype/BoardForm";
import ContextExample from "page/prototype/ContextExample";

import UIBuilderMain from "page/builder/UIBuilderMain";
import UserMain from "page/user/UserMain";
import EntityModelList from "page/dataModel/DataModelList";

import EntityBuilderMain from "page/dataModel/EntityBuilderMain";
import EntityChoiceMain from "page/dataModel/EntityChoiceMain";
import Setting from "page/setting/Setting";
import UserList from "page/user/UserList";
import UserDetail from "page/user/UserDetail";
import EntityPatchMain from "page/dataModel/EntityPatchMain";
import DataModelHistory from "page/dataModel/DataModelHistory";
import DataModelHistoryDetail from "page/dataModel/DataModelHistoryDetail";
import WorkflowMain from "page/workflow/WorkflowMain";
import EventHandlerList from "page/eventhandler/EventHandlerList";
import ProgramList from "page/builder/ProgramList";
import WorkflowList from "page/workflow/WorkflowList";
import WorkflowHistory from "page/workflow/WorkflowHistory";
import WorkflowHistoryDetail from "page/workflow/WorkflowHistoryDetail";
import MyDetail from "page/user/MyDetail";
import { useDispatch, useSelector } from "react-redux";
import UIReduxHelper from "./ui/editor/helper/UIReduxHelper";

import EventHandlerBuilderMain from "page/eventhandler/EventHandlerBuilderMain";
import EventHandler from "page/eventhandler";
import Workflow from "page/workflow";
import DataModel from "page/dataModel";
import UIBuilder from "page/builder";
import UserPage from "page/user";
import Erd from "page/erd";
import ErdBuilderMain from "page/erd/ErdBuilderMain";
import ErdList from "page/erd/ErdList";
import ProgramHistoryDetail from "page/builder/ProgramHistoryDetail";
import ProgramHistoryMain from "page/builder/ProgramHistoryMain";
import RedirectNewTab from "page/common/RedirectNewTab";
import AdminSettingPage from "page/adminSetting";
import AdminSettingMain from "components/setting/config/AdminSettingMain";
import ProgramHistoryCompare from "page/builder/ProgramHistoryCompare";

const BuilderContent = (props) => {
  const dispatch = useDispatch();
  const workspace = useSelector((state) => state.workspace);
  const navigate = useNavigate();
  const searchConsole = useSelector((state) => state.searchConsole);
  /**
   * 로그아욳
   * @returns
   */
  const logout = () => UIReduxHelper.initializeAllRedux(dispatch);
  /**
   * 워크스페이스 설정
   * @param {*} workspace
   * @returns
   */
  const setWorkspace = (workspace) =>
    UIReduxHelper.setWorkspace(dispatch, workspace);

  /**
   * 헤더에 추가되는 공통 프로퍼티
   */
  const headerProps = {
    applyLoader: true,
    navigate,
    workspace,
    searchConsole,
    logout,
    setWorkspace,
  };

  return (
    <div className="content-wrapper">
      {/* TO-DO : router로 props 값에 따라 화면에 Display되는 화면 component 분기
       *  - BuilderEditor : 좌측 첫번째 메뉴인 UI Builder 클릭시
       *  - BoardList, BoardForm : Test용 화면
       */}
      <Routes>
        {/* Root */}
        <Route
          path={"/"}
          element={<Navigate replace to={Enums.BuilderPath.UI.MAIN} />}
        />
        {/* USER */}
        <Route
          path={Enums.BuilderPath.USER.MAIN}
          element={<UserPage header={headerProps} />}
        >
          <Route path={Enums.BuilderPath.USER.MAIN} element={<UserMain />} />
          <Route path={Enums.BuilderPath.USER.LIST} element={<UserList />} />
          <Route
            path={Enums.BuilderPath.USER.DETAIL}
            element={<UserDetail />}
          />
          <Route path={Enums.BuilderPath.USER.MY_INFO} element={<MyDetail />} />
        </Route>

        {/* UI */}
        <Route
          path={Enums.BuilderPath.UI.MAIN}
          element={<UIBuilder header={headerProps} />}
        >
          <Route
            path={Enums.BuilderPath.UI.EDITOR}
            element={<UIBuilderMain />}
          />
          <Route
            path={Enums.BuilderPath.UI.HISTORY_DETAIL}
            element={<ProgramHistoryDetail />}
          />
          <Route
            path={Enums.BuilderPath.UI.HISTORY}
            element={<ProgramHistoryMain />}
          />
          <Route
            path={Enums.BuilderPath.UI.HISTORY_COMPARE}
            element={<ProgramHistoryCompare />}
          />
          <Route path={Enums.BuilderPath.UI.LIST} element={<ProgramList />} />
        </Route>
        {/* DataModel : Entity */}
        <Route
          path={Enums.BuilderPath.ENTITY.MAIN}
          element={<DataModel header={headerProps} />}
        >
          <Route
            path={Enums.BuilderPath.ENTITY.CREATE}
            element={<EntityBuilderMain />}
          />
          <Route
            path={Enums.BuilderPath.ENTITY.LIST}
            element={<EntityModelList />}
          />
          <Route
            path={Enums.BuilderPath.ENTITY.EDITOR}
            element={<EntityBuilderMain />}
          />
          <Route
            path={Enums.BuilderPath.ENTITY.CHOICE}
            element={<EntityBuilderMain />}
          />
          <Route
            path={Enums.BuilderPath.ENTITY.PATCH}
            element={<EntityPatchMain />}
          />
          <Route
            path={Enums.BuilderPath.ENTITY.HISTORY}
            element={<DataModelHistory />}
          />
          <Route
            path={Enums.BuilderPath.ENTITY.HISTORY_DETAIL}
            element={<DataModelHistoryDetail />}
          />
        </Route>
        {/* WORKFLOW */}
        <Route
          path={Enums.BuilderPath.WORKFLOW.MAIN}
          element={<Workflow header={headerProps} />}
        >
          <Route
            path={Enums.BuilderPath.WORKFLOW.BUILDER}
            element={<WorkflowMain />}
          />
          <Route
            path={Enums.BuilderPath.WORKFLOW.LIST}
            element={<WorkflowList />}
          />
          <Route
            path={Enums.BuilderPath.WORKFLOW.HISTORY}
            element={<WorkflowHistory />}
          />

          <Route
            path={Enums.BuilderPath.WORKFLOW.HISTORY_DETAIL}
            element={<WorkflowHistoryDetail />}
          />
        </Route>

        {/* Event Handler  */}
        <Route
          path={Enums.BuilderPath.EVENT_HANDLER.MAIN}
          element={<EventHandler header={headerProps} />}
        >
          <Route
            path={Enums.BuilderPath.EVENT_HANDLER.BUILDER}
            element={<EventHandlerBuilderMain header={headerProps} />}
          />
          <Route
            path={Enums.BuilderPath.EVENT_HANDLER.LIST}
            element={<EventHandlerList />}
          />
        </Route>
        {/* Erd Handler  */}
        <Route
          path={Enums.BuilderPath.ERD.MAIN}
          element={<Erd header={headerProps} />}
        >
          <Route
            path={Enums.BuilderPath.ERD.BUILDER}
            element={<ErdBuilderMain header={headerProps} />}
          />
          <Route
            path={Enums.BuilderPath.ERD.LIST}
            element={<ErdList header={headerProps} />}
          />
        </Route>

        <Route
          path={Enums.BuilderPath.SETTING}
          element={<Setting header={headerProps} />}
        />
        <Route
          path={Enums.BuilderPath.ADMIN.MAIN}
          element={<AdminSettingPage header={headerProps} />}
        >
          <Route
            path={Enums.BuilderPath.ADMIN.SETTING}
            element={<AdminSettingMain header={headerProps} />}
          ></Route>
        </Route>
        <Route
          path="/newTabRedirect/:menu/:uid"
          element={<RedirectNewTab header={headerProps} />}
        />
        <Route path="/guide/board" element={<BoardList />} />

        <Route path="/guide/ctx" element={<ContextExample />} />
        <Route path="/guide/create-board" element={<BoardForm />} />
      </Routes>
    </div>
  );
};

export default BuilderContent;
