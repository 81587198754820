import { CircularProgress } from "@mui/material";
import User from "components/common/utils/UserUtils";
import React, { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { AiOutlineSync } from "react-icons/ai";
import DataModelService from "services/datamodel/DataModelService";
import {
  PropertiesHeader,
  PropertyLable,
  PropertyValue,
} from "components/builder/entity/entityComponents/EntityComponentStyle";
import StringUtils from "components/common/utils/StringUtils";
import { useSelector } from "react-redux";
import { Enums } from "components/builder/BuilderEnum";
import Message from "components/common/Message";
import { AppContext } from "components/common/AppContextProvider";
import { DraggableEntityContents } from "./DynamicApiTab";

function EntityApiTab() {
  const {
    dataModel: { list, setList, setType, type },
  } = useContext(AppContext);
  const { tenantMstId, appId, moduleCd } = useSelector(
    (state) => state.workspace
  );
  const [isLoading, setIsLoading] = useState(false);
  const [searchTableNm, setSearchTableNm] = useState("");
  const [DPEntityList, setDPEntityList] = useState([]);

  useEffect(() => {
    if (StringUtils.equalsIgnoreCase(type, "E")) {
      getEntityList({});
    } else {
      getEntityList({ refresh: true });
    }
  }, []);

  const { dataModelEntities } = useSelector((state) => state.outputENT.output);

  useEffect(() => {
    if (dataModelEntities && dataModelEntities.length > 0) {
      const filtered = list.filter(
        (table) =>
          dataModelEntities.findIndex((_dtModel) => {
            return StringUtils.equalsIgnoreCase(
              _dtModel.tableNm,
              table.tableNm
            );
          }) === -1 &&
          table.tableNm.toLowerCase().indexOf(searchTableNm.toLowerCase()) > -1
      );
      setDPEntityList(filtered);
    } else {
      setDPEntityList(list);
    }
  }, [dataModelEntities, searchTableNm, list]);

  const getEntityList = ({ refresh }) => {
    setIsLoading(true);
    const connection = User.getConnection(tenantMstId);
    if (connection?.expired) {
      setIsLoading(false);
      return Message.alert("만료된 토큰입니다.", Enums.MessageType.ERROR);
    }
    if (!connection?.token) {
      setIsLoading(false);
      return Message.alert(
        "인증 서버 토큰이 확인 되지 않았습니다.\n 다시 연결 해주세요.",
        Enums.MessageType.ERROR
      );
    }

    const body = {
      accessToken: connection.token,
      ...connection,
      searchModule: "",
      appId,
      moduleCd,
      refresh: refresh ? "Y" : "N",
    };

    DataModelService.getEntityList(
      body,
      (res) => {
        setIsLoading(false);
        setList(res.data || []);
        setType("E");
        setDPEntityList(res.data);
      },
      (err) => {
        setIsLoading(false);
      }
    );
  };

  const onChangeTableNm = (e) => {
    setSearchTableNm(e.currentTarget.value);
  };

  return (
    <div className="table-list">
      {/* Tables tab 소스가 들어가는 부분 */}
      <PropertiesHeader>
        엔티티 목록
        <Button
          variant="outline-secondary"
          size="sm"
          className="light-font-color"
          onClick={() => getEntityList({ refresh: true })}
          disabled={isLoading}
        >
          {isLoading ? (
            <>
              <CircularProgress color="inherit" size={13} />
              {"  "}데이터 호출 중
            </>
          ) : (
            <>
              <AiOutlineSync color="inherit" size={13} />
              {"  "}데이터 싱크
            </>
          )}
        </Button>
      </PropertiesHeader>
      <div className="table-filter">
        <PropertyLable
          style={{
            textAlign: "center",
          }}
        >
          엔티티 명
        </PropertyLable>
        <PropertyValue>
          <input
            type="text"
            id="programName"
            className="form-control form-control-sm"
            placeholder="엔티티 명 입력"
            value={searchTableNm}
            onChange={onChangeTableNm}
          />
        </PropertyValue>
      </div>
      {DPEntityList.map((table, dtlIdx) => {
        return <DraggableEntityContents key={table.tableNm} data={table} />;
      })}
    </div>
  );
}

export default EntityApiTab;
