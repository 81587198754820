import Modal from "components/common/modal/UModal";
import UModalTemplate from "components/common/modal/UModalTemplate";
import React from "react";

function ConnectTypeDescPopup() {
  return (
    <Modal>
      <Modal.Header title="연결 유형 설명" />
      <Modal.Body>
        <UModalTemplate>
          <p>
            <strong>1. DAAF Proxy 배포</strong>
            <br />
            DAAF API 서버를 통해 대상 서버에 연결하고 배포 합니다.
          </p>
          <p>
            <strong>2. 직접 연결 배포</strong>
            <br />
            DAAF API 서버를 통하지 않고 직접 대상 서버에 연결 하고 배포합니다.
          </p>
          <p>
            ※{" "}
            <font color="red">
              배포 서버의 "DAAF Framework 자동 patch" 기능이 실행 되지 않기
              때문에 배포 서버가 VPN을 통해 접속 해야하는 상황일 경우에만 사용
              할 것을 권고합니다.
            </font>
            <br />
            <br />※{" "}
            <font color="blue">
              직접 연결을 사용 하기 위해서는 아래와 같은 조건을 만족해야 합니다.
            </font>
            <br />
            1) 배포 서버가 https 이거나
            <br />
            2) http인 경우에는 chrome 설정에서 배포 서버가 안전하지 않은
            콘텐츠(http)로 추가 되어야 합니다.
            <br />
          </p>
          <p>
            <span style={{ color: "tomato" }}>*</span> chrome 안전하지 않은
            콘텐츠(http) 허용 설정 방법
            <br />
            <strong>
              {`. 설정 > 개인 정보 보호 및 보안 > 사이트 설정 > 추가 콘텐츠 설정 >
              안전하지 않은 콘텐츠`}
            </strong>
            <br />
            . 안전하지 않은 콘텐츠 설정 화면 에서 <br />
            &emsp;- 안전하지 않은 콘텐츠 표시가 허용되지 않음 의 [추가 ]버튼
            클릭
            <br />
            &emsp;- 사이트 : https://daaf.bizentro.net:443 입력
            <br />
            . 등록된 사이트 클릭
            <br />
            &emsp;- 안전하지 않은 콘텐츠 - 허용 확인
            <br />
          </p>
        </UModalTemplate>
      </Modal.Body>
    </Modal>
  );
}

export default ConnectTypeDescPopup;
