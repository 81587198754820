import { BsStickies } from "react-icons/bs";
import { Enums } from "../BuilderEnum";
import { CiViewTable } from "react-icons/ci";
import { Tooltip } from "@mui/material";

function ErdNodeList({ theme, ...props }) {
  const nodeList = [
    {
      icon: CiViewTable,
      label: "Table",
      tooltip: "Table 생성",
      type: Enums.ErdType.TABLE,
    },
    {
      icon: BsStickies,
      label: "Memo",
      tooltip: "메모 추가",
      type: Enums.ErdType.MEMO,
    },
  ];

  return (
    <div className={`workflow-node-list ${theme}`}>
      {nodeList.map((node, idx) => {
        return (
          <Tooltip title={node.tooltip} placement={"right"} key={node.type}>
            <>
              <DraggableEntityContents data={node} theme={theme} />
            </>
          </Tooltip>
        );
      })}
    </div>
  );
}

export default ErdNodeList;

export const DraggableEntityContents = ({ data, theme, ...props }) => {
  const onDragStart = (event) => {
    const StringData = JSON.stringify(data);
    event.dataTransfer.setData("application/reactflow", StringData);
    event.dataTransfer.effectAllowed = "move";
  };

  const DraggableIcon = data.icon ? data.icon : <></>;
  return (
    <div
      onDragStart={onDragStart}
      draggable
      className={`node ${theme} `}
      onDragCapture={(e) => false}
    >
      <div className="icon">
        <DraggableIcon style={{ fontSize: 18 }} className={`u-icon-Grid`} />
      </div>
      <div className="label">{data.label}</div>
    </div>
  );
};
