import React, { useContext } from "react";
import { useNavigate, useParams, useRoutes } from "react-router-dom";
import ContentsLoading from "./ContentsLoading";
import { Enums } from "components/builder/BuilderEnum";
import BuilderHeaderComponent from "components/builder/BuilderHeaderComponent";
import PageTemplate from "./PageTemplate";
import { useEffect } from "react";
import ProgramService from "services/ui/ProgramService";
import { useDispatch } from "react-redux";
import { AppContext } from "components/common/AppContextProvider";
import UIReduxHelper from "components/builder/ui/editor/helper/UIReduxHelper";
import { setMemo } from "components/builder/ui/reducers/UIBuilderAction";
import SessionUtils from "components/common/utils/SessionUtils";
import User from "components/common/utils/UserUtils";
import { useSelector } from "react-redux";
import StringUtils from "components/common/utils/StringUtils";
import LocalStorageService from "services/common/LocalService";
import ComponentService from "services/datamodel/DataModelService";
import { setDataModelEditable } from "page/dataModel/DataModelList";
import {
  activateComponent,
  updateDataModel,
} from "components/builder/entity/reducers/EntityBuilderAction";
import WorkflowService from "services/workflow/WorkflowService";
import { updateService } from "components/builder/workflow/reducer/WorkflowAction";

function RedirectNewTab({ header, ...props }) {
  const path = useParams();
  const userInfo = window.localStorage.getItem(
    Enums.LocalStorageName.AUTOMATION
  );
  const dispatch = useDispatch();
  const { component } = useContext(AppContext);
  const navigate = useNavigate();
  const workspace = useSelector((state) => state.workspace);

  useEffect(() => {
    SessionUtils.set(userInfo);
    const { menu, uid } = path;
    getData(menu, uid);
  }, []);

  const windowClose = () => {
    alert("접근할 수 없는 페이지 입니다. 윈도우를 종료 합니다.");
    window.close();
  };

  const getData = (menu, uid) => {
    //Locastorage 삭제
    LocalStorageService.remove(Enums.LocalStorageName.AUTOMATION);
    LocalStorageService.remove(Enums.LocalStorageName.WORKSPACE);
    switch (menu) {
      case "program":
        ProgramService.getProgram(
          { programUid: uid },
          (res) => {
            //테넌트와 호출한 프로그램 비교
            if (
              StringUtils.equalsIgnoreCase(
                res.data.appReleaseId,
                workspace.appReleaseId
              ) &&
              res.data.tenantId === workspace.tenantId &&
              res.data.coCd === workspace.coCd
            ) {
              const { programContent, dataModelList, ...information } =
                res.data;
              const componentInfo = component.getPageComponent(); //Page Component
              const { memo, ...output } = JSON.parse(programContent);
              UIReduxHelper.loadTemplate(
                dispatch,
                componentInfo,
                JSON.stringify(output)
              );
              information.programContent = JSON.parse(programContent);
              UIReduxHelper.updateInformation(dispatch, information);
              if (memo) dispatch(setMemo(memo));
              else dispatch(setMemo([]));
              navigate(
                Enums.BuilderPath.UI.MAIN + "/" + Enums.BuilderPath.UI.EDITOR
              );
            } else {
              windowClose();
            }
          },
          windowClose
        );

        break;
      case "datamodel":
        ComponentService.getDataModel(
          { dataModelId: uid },
          (res) => {
            if (
              StringUtils.equalsIgnoreCase(
                res.data.appReleaseId,
                workspace.appReleaseId
              ) &&
              res.data.tenantId === workspace.tenantId &&
              res.data.coCd === workspace.coCd
            ) {
              const dataModel = setDataModelEditable(res.data);
              dispatch(updateDataModel(dataModel));
              dispatch(activateComponent(dataModel));
              // setList([]);
              navigate(
                Enums.BuilderPath.ENTITY.MAIN +
                  "/" +
                  Enums.BuilderPath.ENTITY.CREATE
              );
            } else {
              windowClose();
            }
          },
          windowClose
        );

        break;
      case "workflow":
        WorkflowService.getService({ serviceUid: uid }, (res) => {
          const serviceDetail = WorkflowService.setData(res.data);
          dispatch(updateService(serviceDetail));
          navigate(
            Enums.BuilderPath.WORKFLOW.MAIN +
              "/" +
              Enums.BuilderPath.WORKFLOW.BUILDER
          );
        });
        break;

      default:
        window.close();
    }
  };

  return (
    <>
      <BuilderHeaderComponent {...header} />
      <PageTemplate>
        <ContentsLoading show={true} />
      </PageTemplate>
    </>
  );
}

export default RedirectNewTab;
