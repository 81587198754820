import StringUtils from "components/common/utils/StringUtils";
import User from "components/common/utils/UserUtils";

class LocalStorageService {
  /**
   * 오브젝트를 암호화 하여 저장
   * @param {*} LocalStorageKey
   * @param {*} obj
   */
  static set(LocalStorageKey, obj) {
    window.localStorage.setItem(LocalStorageKey, StringUtils.encJson(obj));
  }

  /**
   * 스토리지 삭제
   * @param {*} LocalStorageKey
   */
  static remove(LocalStorageKey) {
    window.localStorage.removeItem(LocalStorageKey);
  }

  /**
   * 데이터 가져오기
   * 가져올때 사용자아이디와 저장된 아이디가 다르면 null 리턴 및 기존 데이터 초기화
   * @param {*} LocalStorageKey
   * @returns
   */
  static get(LocalStorageKey) {
    let returnData = null;
    const data = window.localStorage.getItem(LocalStorageKey);
    const userId = User.getId();
    if (data) {
      const beforeDecrypt = data;
      const dec = StringUtils.decData(beforeDecrypt);
      if (dec) {
        try {
          if (dec.userId === userId) {
            returnData = { ...dec };
          } else {
            window.localStorage.removeItem(LocalStorageKey);
          }
        } catch (error) {
          window.localStorage.removeItem(LocalStorageKey);
          returnData = null;
        }
      }
    }

    return returnData;
  }
}

export default LocalStorageService;
