import Blockly from "blockly";

/**
 * Blockly BlockSvg link : https://github.com/google/blockly/blob/develop/core/block_svg.ts
 */

/**
 * Set this block's warning text.
 *
 * @param text The text, or null to delete.
 * @param id An optional ID for the warning text to be able to maintain
 *     multiple warnings.
 */
Blockly.BlockSvg.prototype.setWarningText = function (text, id) {
  if (!id) {
    // Kill all previous pending processes, this edit supersedes them all.
    for (const timeout of this.warningTextDb.values()) {
      clearTimeout(timeout);
    }
    this.warningTextDb.clear();
  } else if (this.warningTextDb.has(id)) {
    // Only queue up the latest change.  Kill any earlier pending process.
    clearTimeout(this.warningTextDb.get(id));
    this.warningTextDb.delete(id);
  }
  if (this.workspace.isDragging()) {
    // Don't change the warning text during a drag.
    // Wait until the drag finishes.
    this.warningTextDb.set(
      id,
      setTimeout(() => {
        if (!this.isDeadOrDying()) {
          this.warningTextDb.delete(id);
          this.setWarningText(text, id);
        }
      }, 100)
    );
    return;
  }
  if (this.isInFlyout) {
    text = null;
  }

  const icon = this.getIcon(Blockly.icons.WarningIcon.TYPE);
  if (text) {
    // Bubble up to add a warning on top-most collapsed block.
    // TODO(#6020): This warning is never removed.
    // let parent = this.getSurroundParent();
    // let collapsedParent = null;
    // while (parent) {
    //   if (parent.isCollapsed()) {
    //     collapsedParent = parent;
    //   }
    //   parent = parent.getSurroundParent();
    // }
    // if (collapsedParent) {
    //   collapsedParent.setWarningText(
    //     Blockly.Msg["COLLAPSED_WARNINGS_WARNING"],
    //     Blockly.BlockSvg.COLLAPSED_WARNING_ID
    //   );
    // }

    if (icon) {
      icon.addMessage(text, id);
    } else {
      this.addIcon(new Blockly.icons.WarningIcon(this).addMessage(text, id));
    }
  } else if (icon) {
    // Dispose all warnings if no ID is given.
    if (!id) {
      this.removeIcon(Blockly.icons.WarningIcon.TYPE);
    } else {
      // Remove just this warning id's message.
      icon.addMessage("", id);
      // Then remove the entire icon if there is no longer any text.
      if (!icon.getText()) this.removeIcon(Blockly.icons.WarningIcon.TYPE);
    }
  }
};
