class ArrayUtils {
  /**
   * isEmpty
   * @param {Array} array
   * @returns
   */
  static isEmpty = (array) => {
    return !(array instanceof Array && array.length > 0);
  };

  /*
   * isArray
   * @param {Array} array
   * @returns
   */
  static isArray = (array) => {
    return array instanceof Array;
  };

  /**
   * reorder
   * @param {Array} list
   * @param {Integer} startIndex
   * @param {Integer} endIndex
   * @returns
   */
  static reorder = (list, startIndex, endIndex, isDeep) => {
    const result = Array.from(list);
    if (isDeep) {
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
    } else {
      let tmp = result[endIndex];
      result[endIndex] = result[startIndex];
      result[startIndex] = tmp;
    }
    return result;
  };

  /**
   * finding the array index of an object
   * @param {*} array
   * @param {*} prop
   * @param {*} value
   * @returns
   */
  static getIndex(array, prop, value) {
    for (var i = 0; i < array.length; i++) {
      if (array[i][prop] === value) {
        return i;
      }
    }
    return -1;
  }

  /**
   * 배열에서 해당 인덱스의 값을 가져옴
   * IsNull 처리
   * @param {*} array
   * @param {*} index
   * @returns
   */
  static get(array, index) {
    if (this.isArray(array)) {
      return array[index];
    } else {
      return null;
    }
  }

  /**
   * 배열에서 가장 앞에 있는 항목을 가져온다.
   * IsNull 처리
   * @param {*} array
   * @returns
   */
  static poll(array) {
    if (this.isArray(array)) {
      return array.splice(0, 1)[0];
    } else {
      return null;
    }
  }
}

export default ArrayUtils;
