import Blockly from "blockly";
import { createPlusField, createMinusField } from "./BlockPlusMinus";

const valueControl = "valueControl";
let labelValue;

/**
 * Blockly Mutator 공식 문서 link: https://developers.google.com/blockly/guides/create-custom-blocks/extensions#mutators
 */

/**
 * Blockly Plus Minus Mutator link: https://github.com/google/blockly-samples/blob/master/plugins/block-plus-minus/src/list_create.js
 */

/**
 * input_value의 개수를 조절하는 Mutator
 */
const inputValueCreate = {
  itemCount_: 1,

  mutationToDom: function () {
    const container = Blockly.utils.xml.createElement("mutation");

    this.arrayData.forEach((element) => {
      const data = Blockly.utils.xml.createElement("data");
      data.setAttribute("key", element.key);
      container.appendChild(data);
    });
    container.setAttribute("items", this.itemCount_);

    return container;
  },

  domToMutation: function (xmlElement) {
    const keys = [];

    for (const childNode of xmlElement.childNodes) {
      if (childNode.nodeName.toLowerCase() === "data") {
        keys.push(childNode.getAttribute("key"));
      }
    }
    this.updateShape_(keys);
  },

  saveExtraState: function () {
    const state = Object.create(null);

    if (this.arrayData.length) {
      state["data"] = [];
      this.arrayData.forEach((data) => {
        state["data"].push({
          key: data.key,
        });
      });
    }
    state["delData"] = this.delData;
    state["itemCount"] = this.itemCount_;
    return state;
  },

  loadExtraState: function (state) {
    const keys = [];

    if (state["data"]) {
      for (let i = 0; i < state["data"].length; i++) {
        const param = state["data"][i];
        keys.push(param["key"]);
      }
    }
    this.updateShape_(keys, state["delData"]);
  },

  updateShape_: function (keys, delData) {
    if (delData) {
      this.removePart_(delData);
    }
    for (let i = 0; i <= keys.length - 1; i++) {
      this.removePart_(keys[i]);
    }
    this.arrayData = [];
    const length = keys.length;

    for (let i = 0; i < length; i++) {
      this.addPart_(keys[i]);
    }
  },

  plus: function () {
    this.addPart_();
  },

  minus: function (keyId) {
    if (this.itemCount_ === 0) {
      return;
    }
    this.arrayData.filter((data) => data.key !== keyId);

    this.removePart_(keyId);
  },

  addPart_: function (keyId = null) {
    if (keyId) {
      this.appendValueInput(valueControl + keyId)
        .appendField(createMinusField(), keyId)
        .appendField(labelValue);
      this.arrayData.push({
        key: keyId,
      });
    } else {
      let max = 1;

      for (const key in this.arrayData) {
        if (max < this.arrayData[key].key) {
          max = Number(this.arrayData[key].key);
        }
      }
      max += 1;

      this.appendValueInput(valueControl + max)
        .appendField(createMinusField(), `${max}`)
        .appendField(labelValue);

      this.arrayData.push({
        key: `${max}`,
      });
    }
    this.resortInput();
  },

  resortInput: function () {
    let newInputList = [];
    let firstIndex;

    for (let i = 0; i < this.inputList.length; i++) {
      const input = this.inputList[i];

      if (input.name) {
        if (input.name === valueControl) {
          firstIndex = i;
          newInputList.push(input);
          break;
        } else {
          newInputList.push(input);
        }
      }
    }

    for (let i = firstIndex + 1; i < this.inputList.length; i++) {
      const input = this.inputList[i];

      if (input.name) {
        if (input.name.includes(valueControl)) {
          newInputList.push(input);
        }
      }
    }

    for (let i = firstIndex + 1; i < this.inputList.length; i++) {
      const input = this.inputList[i];

      if (input.name || input.name === "") {
        if (!input.name.includes(valueControl) || input.name === "") {
          newInputList.push(input);
        }
      }
    }

    this.inputList = newInputList;
  },

  removePart_: function (keyId = null) {
    if (keyId) {
      if (this.removeInput(valueControl + keyId, true)) {
        this.arrayData = this.arrayData.filter((data) => data.key !== keyId);
        this.delData = keyId;
      }
    }
  },
};

// Plus image field를 해당 Block에 생성
const listCreateHelper = function () {
  this.arrayData = [];

  this.getInput(valueControl).fieldRow.map((field) => {
    if (field.value_) {
      labelValue = field.value_;
    }
  });
  this.getInput(valueControl).insertFieldAt(0, createPlusField(), "PLUS");
};

Blockly.Extensions.registerMutator(
  "input_value_control",
  inputValueCreate,
  listCreateHelper
);
