import Blockly from "blockly";

// Vertical Flyout corner_radius 설정
Blockly.VerticalFlyout.prototype.CORNER_RADIUS = 0;

// Vertical Flyout position 설정 함수
Blockly.VerticalFlyout.prototype.position = function () {
  this.CORNER_RADIUS = 0;
  if (!this.isVisible() || !this.targetWorkspace.isVisible()) {
    return;
  }
  const metricsManager = this.targetWorkspace.getMetricsManager();
  const targetWorkspaceViewMetrics = metricsManager.getViewMetrics();
  this.height_ = targetWorkspaceViewMetrics.height;

  const edgeWidth = this.width_ - this.CORNER_RADIUS;
  const edgeHeight = targetWorkspaceViewMetrics.height - 2 * this.CORNER_RADIUS;
  this.setBackgroundPath(edgeWidth, edgeHeight);

  const x = this.getX();
  const y = this.getY();

  this.positionAt_(this.width_, this.height_, x, y);
};
