import React, { useRef, Suspense, lazy } from "react";

import { useDrag } from "react-dnd";
import produce from "immer";
import { useSelector, useDispatch } from "react-redux";
import { useContext } from "react";
import { AppContext } from "components/common/AppContextProvider";
import * as actions from "components/builder/ui/reducers/UIBuilderAction";
import UIComponentSection from "components/builder/ui/editor/UIComponentSection";
import * as Event from "components/builder/ui/editor/handler/UIEditorEventHandler";

import {
  StringUtils,
  ObjectUtils,
  JsonUtils,
} from "components/common/utils/CommonUtils";
import UIReduxHelper from "components/builder/ui/editor/helper/UIReduxHelper";
import { generateNodeTreeIds } from "../../tree/treeUtils";

const UIEditorComponent = ({ data, path, rootLocation, style }) => {
  const ref = useRef(null);
  const activedComponent = useSelector(
    (state) => state.activedUIComponent.component
  );
  const components = useContext(AppContext).component.getComponentList("B");
  const output = useSelector((state) => state.outputUI.output);
  const dispatch = useDispatch();

  const item = produce(data, (draft) => {
    draft.path = path;
    draft.rootLocation = rootLocation;
  });
  const [{ isDragging }, drag] = useDrag({
    item: item,
    type: item.type,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(ref);

  const editorAttr = ObjectUtils.isEmpty(item.editorAttr)
    ? {}
    : item.editorAttr;

  /**
   * Onclick event
   * @param {Event} e
   * @returns
   */
  const onClick = (e) => {
    const nodeIds = generateNodeTreeIds(output.page, item.compId);
    dispatch(actions.setBuilderTreeNodeIds(nodeIds));
    Event.clickComponent(e, item, components, activedComponent, dispatch);
  };

  /**
   * Editor에서 Property속성 변경시 propertyValue 값 update
   * @param {*} propertyValue
   */
  const updateProperty = (propertyValue) => {
    const compId = item.compId;
    const changedOutput = produce(output, (draft) => {
      const targetNode = JsonUtils.overrideNode(
        draft,
        "compId",
        compId,
        "propertyValue",
        propertyValue
      );
      if (ObjectUtils.isEmpty(targetNode)) {
        console.log("Could not find target node !!!!");
        return false;
      }
    });
    UIReduxHelper.updateOutput(changedOutput, dispatch);
    dispatch(
      actions.updateActivateProps({
        ...activedComponent.propertyValue,
        ...propertyValue,
      })
    );
  };

  /**
   * render component items
   * @returns
   */
  const rederComponentItem = () => {
    if (!StringUtils.isEmpty(editorAttr.componentClass)) {
      const DraggableComponent = lazy(() =>
        import(
          "components/builder/ui/uiComponents/" + editorAttr.componentClass
        )
      );

      return (
        <Suspense fallback={<div></div>}>
          <DraggableComponent
            componentInfo={item}
            event="renderEditor"
            isFocus={activedComponent.compId === item.compId ? true : false}
            onClick={onClick}
            fn={{ updateProperty: updateProperty }}
          />
        </Suspense>
      );
    } else {
      return <div>editor_attr의 componentClass를 확인해주세요 !!</div>;
    }
  };
  return (
    <div className="editor-component-wrap" style={{ ...style }} ref={ref}>
      <UIComponentSection
        item={item}
        style={{ opacity }}
        className="editor-component editor-base draggable"
        focusDelegation={true}
        title={editorAttr.title}
      >
        {rederComponentItem()}
      </UIComponentSection>
    </div>
  );
};

export default React.memo(UIEditorComponent);
