import WijmoGrid from "components/common/element/WijmoGrid";
import Modal from "components/common/modal/UModal";
import UModalTemplate from "components/common/modal/UModalTemplate";
import CommonUtils from "components/common/utils/CommonUtils";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Form } from "react-bootstrap";
import ProgramService from "services/ui/ProgramService";
import WorkflowService from "services/workflow/WorkflowService";

const WorkflowReferencePopup = ({ serviceInfo, ...props }) => {
  const [usingProgramList, setUsingProgramList] = useState([]);
  const [usingServiceList, setUsingServiceList] = useState([]);

  useEffect(() => {
    getProgramList();
    getServiceList();
  }, []);

  const getProgramList = () => {
    // "serviceUid": 314
    ProgramService.getWorkflowUsingProgramList(
      {
        serviceUid: serviceInfo.serviceUid,
        appReleaseId: serviceInfo.appReleaseId,
      },
      (res) => {
        setUsingProgramList(res.data);
      }
    );
  };

  const getServiceList = () => {
    // "serviceUid": 380
    WorkflowService.getWorkflowUsingService(
      {
        serviceUid: serviceInfo.serviceUid,
        appReleaseId: serviceInfo.appReleaseId,
      },
      (res) => {
        setUsingServiceList(res.data);
      }
    );
  };

  const programGridColumn = [
    { field: "programId", headerName: "프로그램 ID", width: 150 },
    {
      field: "programNm",
      headerName: "프로그램 명",
      width: "*",
    },

    {
      field: "updtUserId",
      headerName: "최종 수정자",
      width: 150,
    },
    {
      field: "updtDt",
      headerName: "최종 수정일",
      width: 150,
      renderCell: (params) =>
        `${CommonUtils.getDate(params.updtDt, "datetime")}`,
    },
  ];

  const workflowGridColumn = [
    {
      field: "serviceUid",
      headerName: "서비스 UID",
      width: 120,
    },
    {
      field: "serviceId",
      headerName: "서비스 ID",
      width: 150,
    },
    {
      field: "serviceName",
      headerName: "서비스 명",
      width: "*",
    },
    {
      field: "updtUserId",
      headerName: "최종 수정자",
      width: 130,
    },
    {
      field: "updtDt",
      headerName: "최종 수정일",
      width: 150,
      renderCell: (params) =>
        `${CommonUtils.getDate(params.updtDt, "datetime")}`,
    },
  ];

  return (
    <Modal>
      <Modal.Header title="참조 된 프로그램 목록" />
      <Modal.Body>
        <UModalTemplate>
          <Form.Label>Workflow 실행으로 참조되는 프로그램 목록</Form.Label>
          <WijmoGrid
            columns={programGridColumn}
            rows={usingProgramList}
            style={{ height: "300px" }}
            headersVisibility={"Column"}
          />
          <Form.Label>Service로 참조되고 있는 Workflow 목록</Form.Label>
          <WijmoGrid
            columns={workflowGridColumn}
            rows={usingServiceList}
            style={{ height: "300px" }}
            headersVisibility={"Column"}
          />
        </UModalTemplate>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Button>확인</Modal.Footer.Button>
      </Modal.Footer>
    </Modal>
  );
};

export default WorkflowReferencePopup;
