import { Enums } from "components/builder/BuilderEnum";
import { AppContext } from "components/common/AppContextProvider";
import Message from "components/common/Message";
import Popup from "components/common/Popup";
import WijmoGrid from "components/common/element/WijmoGrid";
import CommonUtils from "components/common/utils/CommonUtils";
import ObjectUtils from "components/common/utils/ObjectUtils";
import StringUtils from "components/common/utils/StringUtils";
import User from "components/common/utils/UserUtils";
import NewWorkspacePopup from "page/popup/workspace/NewWorkspacePopup";
import { useContext, useState } from "react";
import { Button } from "react-bootstrap";
import AppService from "services/common/AppService";

function WorkspaceManage() {
  /**
   * selectType
   * 1. app
   * 2. module
   * 3. version
   * 4. tenant & coCd
   */
  const [selectedApp, setSelectedApp] = useState({});
  const [selectedModule, setSelectedModule] = useState({});
  const [selectedVersion, setSelectedVersion] = useState({});
  const [selectedTenant, setSelectedTenant] = useState({});

  //list
  const [moduleList, setModuleList] = useState([]);
  const [versionList, setVersionList] = useState([]);
  const [tenantList, setTenantList] = useState([]);

  const {
    application: { availableList: applicationList },
  } = useContext(AppContext);

  const appColumns = [
    {
      field: "appGroupNm",
      headerName: "앱 종류",
      width: 150,
    },
    {
      field: "appNm",
      headerName: "앱 이름",
      width: "*",
    },
  ];
  const moduleColumns = [
    {
      field: "moduleCd",
      headerName: "모듈 코드",
      width: "*",
    },
    {
      field: "moduleNm",
      headerName: "모듈 명",
      width: "*",
    },
  ];
  const versionColumns = [
    {
      field: "version",
      headerName: "버전",
      width: "*",
    },
    {
      field: "releaseNotes",
      headerName: "배포 날짜",
      width: "*",
    },
    {
      field: "releaseDate",
      headerName: "배포 날짜",
      width: "*",
      renderCell: (param) => {
        return CommonUtils.getDate(param.releaseDate);
      },
    },
  ];

  const tenantColumns = [
    {
      field: "tenantId",
      headerName: "테넌트 ID",
      width: "*",
    },
    {
      field: "tenantNm",
      headerName: "테넌트 명",
      width: "*",
    },
    {
      field: "coCd",
      headerName: "회사 코드",
      width: "*",
    },
    {
      field: "coNm",
      headerName: "회사 명",
      width: "*",
    },
    {
      field: "remark",
      headerName: "비고",
      width: 100,
      renderCell: (param) => {
        if (param.remark) {
          return (
            <Button onClick={(e) => onAddTenant(param)}>테넌트 추가</Button>
          );
        } else {
          return (
            <Button variant="danger" onClick={(e) => deleteTenant(param)}>
              삭제
            </Button>
          );
        }
      },
    },
  ];

  const deleteTenant = (param) => {
    Message.confirm("해당 Tenant를 삭제 하시겠습니까?", () => {
      AppService.deleteTenant({ tenantMstId: param.tenantMstId }, (res) => {
        Message.alert(res.message, Enums.MessageType.SUCCESS);
        AppService.getTenantMstList(
          {
            appId: selectedVersion.appId,
            moduleCd: selectedVersion.moduleCd,
            appReleaseId: selectedVersion.appReleaseId,
          },
          (res) => {
            const _List = [...res.data];
            _List.push({ remark: true });
            setTenantList(_List);
          }
        );
      });
    });
  };

  const onAddTenant = () => {
    const body = {
      appId: selectedApp.appId,
      moduleCd: selectedModule.moduleCd,
      appReleaseId: selectedVersion.appReleaseId,
    };

    const callback = () => {
      AppService.getTenantMstList(
        {
          appId: body.appId,
          moduleCd: body.moduleCd,
          appReleaseId: body.appReleaseId,
        },
        (res) => {
          const _List = [...res.data];
          _List.push({ remark: true });
          setTenantList(_List);
        }
      );
    };
    const options = {
      effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
      style: {
        content: {
          width: "500px", //popup의 크기를 50% (default 60%)
        },
      },
    };

    Popup.open(
      <NewWorkspacePopup
        appId={body.appId}
        appList={applicationList}
        moduleCd={body.moduleCd}
        releaseId={body.appReleaseId}
        releaseList={versionList}
        callback={callback}
      />,
      options
    );
  };

  /**
   * App Select Change 이벤트
   * @param {*} data
   */
  const onChangeAppId = (data) => {
    setSelectedApp(data);
    const { appId } = data;
    const appAccessible = CommonUtils.getAppConfig(data, "appAccessibleYn");
    const authType = User.getAuthType(appId);
    AppService.getAppModuleList({ appId }, (moduleRes) => {
      const _moduleList = moduleRes.data;
      if (StringUtils.equalsIgnoreCase(appAccessible, "Y")) {
        setModuleList(_moduleList);
      } else {
        let myAuthModule = [];
        if (
          StringUtils.equalsIgnoreCase("S", authType) ||
          StringUtils.equalsIgnoreCase("AM", authType)
        ) {
          myAuthModule = _moduleList;
        } else {
          const availableModuleCodes = User.selectAvailableModuleCds(appId);
          myAuthModule = _moduleList.filter((_m) => {
            return availableModuleCodes.find(
              (_a) => _a.moduleCd === _m.moduleCd
            );
          });
        }
        setModuleList(myAuthModule);
      }
    });
  };

  /**
   * Module Select Change 이벤트
   * @param {*} data
   */
  const onChangeModule = (data) => {
    setSelectedModule(data);
    AppService.getReleaseList(
      {
        appId: data.appId,
        moduleCd: data.moduleCd,
      },
      (res) => {
        setVersionList(res.data);
      }
    );
  };

  /**
   * Version Select 이벤트
   * @param {*} data
   */
  const onChangeVersion = (data) => {
    setSelectedVersion(data);
    AppService.getTenantMstList(
      {
        appId: data.appId,
        moduleCd: data.moduleCd,
        appReleaseId: data.appReleaseId,
      },
      (res) => {
        const _List = [...res.data];
        _List.push({ remark: true });
        setTenantList(_List);
      }
    );
  };

  /**
   * 선택 초기화
   */
  const onInit = () => {
    setSelectedApp({});
    setSelectedModule({});
    setSelectedTenant({});
    setSelectedVersion({});
  };

  return (
    <>
      <div className="setting-header">Workspace 관리</div>
      <div className="setting-container">
        <div className="vertical-wrapper">
          <VerticalAccordian
            selected={ObjectUtils.isEmpty(selectedApp)}
            title={"Application"}
            chosen={!ObjectUtils.isEmpty(selectedApp)}
          >
            {ObjectUtils.isEmpty(selectedApp) ? (
              <WijmoGrid
                columns={appColumns}
                rows={applicationList}
                onRowDoubleClick={onChangeAppId}
                style={{ height: "100%" }}
                headersVisibility={"Column"}
              />
            ) : (
              <div
                className="desc"
                style={{
                  writingMode: "vertical-lr",
                  textOrientation: "upright",
                  letterSpacing: "5px",
                }}
                onClick={onInit}
              >
                ▶{"  "}
                {selectedApp.appNm}
              </div>
            )}
          </VerticalAccordian>
          <VerticalAccordian
            title={"Module"}
            selected={
              !ObjectUtils.isEmpty(selectedApp) &&
              ObjectUtils.isEmpty(selectedModule)
            }
            chosen={
              !ObjectUtils.isEmpty(selectedApp) &&
              !ObjectUtils.isEmpty(selectedModule)
            }
          >
            {!ObjectUtils.isEmpty(selectedApp) &&
            ObjectUtils.isEmpty(selectedModule) ? (
              <WijmoGrid
                columns={moduleColumns}
                rows={moduleList}
                onRowDoubleClick={onChangeModule}
                headersVisibility={"Column"}
                style={{ height: "100%" }}
              />
            ) : (
              <div
                className="desc"
                style={{
                  writingMode: "vertical-lr",
                  textOrientation: "upright",
                  letterSpacing: "5px",
                }}
                onClick={(e) => {
                  if (ObjectUtils.isEmpty(selectedApp))
                    return Message.alert(
                      "Application을 선택해주세요",
                      Enums.MessageType.WARN
                    );
                  else {
                    setSelectedModule({});
                    setSelectedTenant({});
                    setSelectedVersion({});
                  }
                }}
              >
                {ObjectUtils.isEmpty(selectedModule)
                  ? "Module"
                  : `▶  ${selectedModule.moduleNm}`}
              </div>
            )}
          </VerticalAccordian>
          <VerticalAccordian
            title={"Version"}
            selected={
              !ObjectUtils.isEmpty(selectedApp) &&
              !ObjectUtils.isEmpty(selectedModule) &&
              ObjectUtils.isEmpty(selectedVersion)
            }
            chosen={
              !ObjectUtils.isEmpty(selectedApp) &&
              !ObjectUtils.isEmpty(selectedModule) &&
              !ObjectUtils.isEmpty(selectedVersion)
            }
          >
            {!ObjectUtils.isEmpty(selectedApp) &&
            !ObjectUtils.isEmpty(selectedModule) &&
            ObjectUtils.isEmpty(selectedVersion) ? (
              <WijmoGrid
                columns={versionColumns}
                rows={versionList}
                onRowDoubleClick={onChangeVersion}
                headersVisibility={"Column"}
                style={{ height: "100%" }}
              />
            ) : (
              <div
                className="desc"
                style={{
                  writingMode: "vertical-lr",
                  textOrientation: "upright",
                  letterSpacing: "5px",
                }}
                onClick={(e) => {
                  if (ObjectUtils.isEmpty(selectedVersion)) {
                    return Message.alert(
                      "Module을 선택해주세요",
                      Enums.MessageType.WARN
                    );
                  } else {
                    setSelectedTenant({});
                    setSelectedVersion({});
                  }
                }}
              >
                {ObjectUtils.isEmpty(selectedVersion)
                  ? "Version"
                  : `▶  ${selectedVersion.version}`}
              </div>
            )}
          </VerticalAccordian>

          <VerticalAccordian
            title={"Tenant"}
            selected={
              !ObjectUtils.isEmpty(selectedApp) &&
              !ObjectUtils.isEmpty(selectedModule) &&
              !ObjectUtils.isEmpty(selectedVersion)
            }
          >
            {!ObjectUtils.isEmpty(selectedApp) &&
            !ObjectUtils.isEmpty(selectedModule) &&
            !ObjectUtils.isEmpty(selectedVersion) ? (
              <WijmoGrid
                columns={tenantColumns}
                rows={tenantList}
                headersVisibility={"Column"}
                style={{ height: "100%" }}
              />
            ) : (
              <div
                className="desc"
                style={{
                  writingMode: "vertical-lr",
                  textOrientation: "upright",
                  letterSpacing: "5px",
                }}
              >
                Tenant & Company Code
              </div>
            )}
          </VerticalAccordian>
        </div>
        <span>※ 상세 테넌트를 확인하고자 하는 항목을 선택 ( 더블클릭 )</span>
        <span>
          선택된 항목은 접힌 상태를 다시 클릭하면 재선택 할 수 있습니다.
        </span>
        <span>테넌트 단계 까지 진입 후 삭제</span>
      </div>
    </>
  );
}

export default WorkspaceManage;

const VerticalAccordian = ({
  title,
  selected,
  style,
  contentsStyle,
  chosen,
  ...props
}) => {
  return (
    <div
      className={`vertical-accordian ${selected ? "selected" : ""} `}
      style={style}
    >
      <div className="theme-title">{title}</div>
      <div
        className={`contents ${chosen ? "chosen" : ""}`}
        style={contentsStyle}
      >
        {props.children}
      </div>
    </div>
  );
};
