import Blockly from "blockly";

/**
 * Blockly icon class link : https://github.com/google/blockly/blob/develop/core/icons/icon.ts
 * /
/**
 * Blockly custom icon link: https://developers.google.com/blockly/guides/create-custom-blocks/icons/creating-custom-icons/basic-implementation
 */

/**
 * 콤보 category에 있는 Block에 사용할 icon 클래스 정의
 */
class BlockComboIcon extends Blockly.icons.Icon {
  constructor(sourceBlock) {
    super(sourceBlock);
  }
  getType() {
    return new Blockly.icons.IconType("combo_icon");
  }
  initView(pointerdownListener) {
    if (this.svgRoot) return; // Already initialized.

    // This adds the pointerdownListener to the svgRoot element.
    // If you do not call `super` you must do this yourself.
    super.initView(pointerdownListener);

    const svg = Blockly.utils.dom.createSvgElement(
      Blockly.utils.Svg.SVG,
      {
        width: "30px",
        height: "30px",
        fill: "#1C274C",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 14 14",
        role: "img",
        focusable: "false",
        "aria-hidden": "true",
      },
      this.svgRoot // Append to the svgRoot.
    );

    Blockly.utils.dom.createSvgElement(
      Blockly.utils.Svg.PATH,
      {
        d: "m 1.0000123,6.99975 0,-4.7134 5.8088699,0 c 4.1431298,0 5.8462998,0.017 5.9393898,0.06 0.0718,0.033 0.15728,0.1182 0.189991,0.19 0.0823,0.1807 0.0823,3.6053 0,3.786 -0.105991,0.2326 -0.2305,0.2494 -1.84448,0.2494 l -1.5239708,0 0,2.571 0,2.5709 -4.2849,0 -4.2849,0 0,-4.7135 z m 7.71282,1.714 0,-2.1425 -3.4279201,0 -3.42792,0 0,2.1425 0,2.1424 3.42792,0 3.4279201,0 0,-2.1424 z m -5.99886,0.857 0,-0.4285 2.5709399,0 2.57094,0 0,0.4285 0,0.4284 -2.57094,0 -2.5709399,0 0,-0.4284 z m 0,-1.714 0,-0.4285 2.5709399,0 2.57094,0 0,0.4285 0,0.4285 -2.57094,0 -2.5709399,0 0,-0.4285 z m 5.99886,-3.4279 0,-1.2855 -3.4279201,0 -3.42792,0 0,1.2855 0,1.2854 3.42792,0 3.4279201,0 0,-1.2854 z m 2.2910807,1.0846 c 0.192459,-0.1687 1.35108,-1.6577 1.35108,-1.7364 0,-0.1911 -0.1034,-0.2052 -1.49971,-0.2052 -1.3963208,0 -1.4997208,0.014 -1.4997208,0.2052 0,0.042 0.27994,0.4291 0.62208,0.8597 0.6085808,0.766 0.7934798,0.9704 0.8776398,0.9704 0.0229,0 0.0898,-0.042 0.148631,-0.094 z",
      },
      svg
    );
  }
  getSize() {
    return new Blockly.utils.Size(30, 30);
  }
  updateCollapsed() {
    if (!this.svgRoot) return;
    if (this.sourceBlock.isCollapsed()) {
      this.svgRoot.style.display = "block";
      return true;
    }
    if (Blockly.hasBubble(this)) {
      this.setBubbleVisible(false);
    }
  }
  dispose() {
    // Always call super!
    super.dispose();

    this.myBubble?.dispose();
    this.myOtherReference?.dispose();
  }
  saveState() {
    return this.state; // Some arbirtary JSON-compatible data.
  }
  loadState(state) {
    this.state = state;
  }
  onLocationChange(blockOrigin) {
    this.workspaceLocation = Blockly.utils.Coordinate.sum(
      blockOrigin,
      this.offsetInBlock
    );
  }
  isShownWhenCollapsed() {
    return true;
  }
}

Blockly.icons.registry.register(
  new Blockly.icons.IconType("combo_icon"),
  BlockComboIcon
);
