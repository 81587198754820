/*!
 * Builder Template Helper for react v.17
 *
 * Builder에서 사용하는 Layout/Component의 표준 Template을 제공한다.
 *
 *   Author: Bizentro
 *   Date: 2021-04
 */

import * as ReactDOMServer from "react-dom/server";
import * as Enums from "components/builder/BuilderEnum";
import * as Fa from "react-icons/fa";
import {
  ArrayUtils,
  StringUtils,
  JsonUtils,
  ObjectUtils,
} from "components/common/utils/CommonUtils";
import { Fragment } from "react";

class UITemplateHelper {
  /**
   * Get template component
   * : layout 구성을 위해 표준이 되는 main component를 지정한다.
   * : Editor에서 Template 을 통해 layout을 생성 하기 위해서 광범위하게 사용됨.
   *  - page - page component
   *  - form - form component
   *  - row - row component
   *  - col - column component
   *  - grid - grid component
   *  - input input component
   *  - button button component
   * @param context
   * @returns baseComponent
   */
  static getTemplateComponents = (componentContext) => {
    let templateComponents = {};
    //componentMSt
    componentContext.components.forEach((component) => {
      //service component는 pass
      if (component.componentGroupCd === "S") return;

      //componentDtl
      if (!ArrayUtils.isEmpty(component.componentDtl)) {
        component.componentDtl.forEach((componentDtl, dtlIndex) => {
          //layout 이거나 이미 add 된경우 pass
          if (
            componentDtl.componentType === Enums.ComponentType.LAYOUT ||
            !StringUtils.isEmpty(
              templateComponents[
                componentDtl.componentType + "/" + componentDtl.componentClass
              ]
            ) ||
            !StringUtils.isEmpty(templateComponents[componentDtl.componentType])
          )
            return;

          //template
          Enums.TemplateList.some((tmpl, index) => {
            //add 대상일 경우
            if (
              componentDtl.componentType === tmpl.type &&
              (StringUtils.isEmpty(tmpl.class) ||
                tmpl.class === componentDtl.componentClass)
            ) {
              let templateKey = StringUtils.isEmpty(tmpl.class)
                ? componentDtl.componentType
                : componentDtl.componentType + "/" + tmpl.class;
              templateComponents[templateKey] = componentDtl;
              return true;
            }
          });
        });
      }
    });
    return templateComponents;
  };

  /**
   * Page template component
   * @param {*} templateComponents
   * @returns
   */
  static getPageTemplate = (templateComponents) => {
    return UITemplateHelper.getNewTemplateComponent(
      Enums.ComponentType.PAGE,
      UITemplateHelper.getTemplate(templateComponents, Enums.ComponentType.PAGE)
    );
  };

  /**
   * Row template component
   * @param {*} templateComponents
   * @returns
   */
  static getRowTemplate = (templateComponents) => {
    return UITemplateHelper.getNewTemplateComponent(
      Enums.ComponentType.ROW,
      UITemplateHelper.getTemplate(templateComponents, Enums.ComponentType.ROW)
    );
  };

  /**
   * Column template component
   * @param {*} templateComponents
   * @returns
   */
  static getColTemplate = (templateComponents) => {
    return UITemplateHelper.getNewTemplateComponent(
      Enums.ComponentType.COLUMN,
      UITemplateHelper.getTemplate(
        templateComponents,
        Enums.ComponentType.COLUMN
      )
    );
  };

  /**
   * Form template component
   * @param {*} templateComponents
   * @returns
   */
  static getFormTemplate = (templateComponents) => {
    return UITemplateHelper.getNewTemplateComponent(
      Enums.ComponentType.FORM,
      UITemplateHelper.getTemplate(templateComponents, Enums.ComponentType.FORM)
    );
  };

  /**
   * Grid template component
   * @param {*} templateComponents
   * @returns
   */
  static getGridTemplate = (templateComponents) => {
    return UITemplateHelper.getNewTemplateComponent(
      Enums.ComponentType.GRID,
      UITemplateHelper.getTemplate(templateComponents, Enums.ComponentType.GRID)
    );
  };

  /**
   * Input template component
   * @param {*} templateComponents
   * @returns
   */
  static getInputTemplate = (templateComponents) => {
    return UITemplateHelper.getNewTemplateComponent(
      "input",
      UITemplateHelper.getTemplate(
        templateComponents,
        Enums.ComponentType.COMPONENT + "/form/Input"
      )
    );
  };

  /**
   * Checkbox template component
   * @param {*} templateComponents
   * @returns
   */
  static getCheckboxTemplate = (templateComponents) => {
    return UITemplateHelper.getNewTemplateComponent(
      "text",
      UITemplateHelper.getTemplate(
        templateComponents,
        Enums.ComponentType.COMPONENT + "/form/Checkbox"
      )
    );
  };

  /**
   * Select template component
   * @param {*} templateComponents
   * @returns
   */
  static getSelectTemplate = (templateComponents) => {
    return UITemplateHelper.getNewTemplateComponent(
      "select",
      UITemplateHelper.getTemplate(
        templateComponents,
        Enums.ComponentType.COMPONENT + "/form/Select"
      )
    );
  };

  /**
   * Text template component
   * @param {*} templateComponents
   * @returns
   */
  static getTextTemplate = (templateComponents) => {
    return UITemplateHelper.getNewTemplateComponent(
      "text",
      UITemplateHelper.getTemplate(
        templateComponents,
        Enums.ComponentType.COMPONENT + "/form/Text"
      )
    );
  };

  /**
   * Button template component
   * @param {*} templateComponents
   * @returns
   */
  static getButtonTemplate = (templateComponents) => {
    return UITemplateHelper.getNewTemplateComponent(
      "button",
      UITemplateHelper.getTemplate(
        templateComponents,
        Enums.ComponentType.COMPONENT + "/form/Button"
      )
    );
  };

  /**
   * Image template component
   * @param {*} templateComponents
   * @returns
   */
  static getImageTemplate = (templateComponents) => {
    return UITemplateHelper.getNewTemplateComponent(
      "image",
      UITemplateHelper.getTemplate(
        templateComponents,
        Enums.ComponentType.COMPONENT + "/form/Image"
      )
    );
  };

  /**
   * Link template component
   * @param {*} templateComponents
   * @returns
   */
  static getHyperlinkTemplate = (templateComponents) => {
    return UITemplateHelper.getNewTemplateComponent(
      "hyperlink",
      UITemplateHelper.getTemplate(
        templateComponents,
        Enums.ComponentType.COMPONENT + "/misc/Hyperlink"
      )
    );
  };

  /**
   * Heading template component
   * @param {*} templateComponents
   * @returns
   */
  static getHeadingTemplate = (templateComponents) => {
    return UITemplateHelper.getNewTemplateComponent(
      "heading",
      UITemplateHelper.getTemplate(
        templateComponents,
        Enums.ComponentType.COMPONENT + "/misc/Heading"
      )
    );
  };

  /**
   * Heading template component
   * @param {*} templateComponents
   * @returns
   */
  static getHtmlTagTemplate = (templateComponents) => {
    return UITemplateHelper.getNewTemplateComponent(
      "html",
      UITemplateHelper.getTemplate(
        templateComponents,
        Enums.ComponentType.COMPONENT + "/misc/HtmlTag"
      )
    );
  };

  /**
   * Container template component
   * @param {*} templateComponents
   * @returns
   */
  static getContainerTemplate = (templateComponents) => {
    return UITemplateHelper.getNewTemplateComponent(
      Enums.ComponentType.CONTAINER,
      UITemplateHelper.getTemplate(
        templateComponents,
        Enums.ComponentType.CONTAINER + "/layout/Container"
      )
    );
  };

  /**
   * Block template component
   * @param {*} templateComponents
   * @returns
   */
  static getBlockTemplate = (templateComponents) => {
    return UITemplateHelper.getNewTemplateComponent(
      "block",
      UITemplateHelper.getTemplate(
        templateComponents,
        Enums.ComponentType.CONTAINER + "/layout/Block"
      )
    );
  };

  /**
   * InputGroup template component
   * @param {*} templateComponents
   * @returns
   */
  static getInputGroupTemplate = (templateComponents) => {
    return UITemplateHelper.getNewTemplateComponent(
      "inputGroup",
      UITemplateHelper.getTemplate(
        templateComponents,
        Enums.ComponentType.CONTAINER + "/layout/InputGroup"
      )
    );
  };

  /**
   * tab container template component
   * @param {*} templateComponents
   * @returns
   */
  static getTabContainerTemplate = (templateComponents) => {
    return UITemplateHelper.getNewTemplateComponent(
      Enums.ComponentType.TAB_CONTAINER,
      UITemplateHelper.getTemplate(
        templateComponents,
        Enums.ComponentType.TAB_CONTAINER
      )
    );
  };

  /**
   * Step container template component
   * @param {*} templateComponents
   * @returns
   */
  static getStepContainerTemplate = (templateComponents) => {
    return UITemplateHelper.getNewTemplateComponent(
      Enums.ComponentType.STEP_CONTAINER,
      UITemplateHelper.getTemplate(
        templateComponents,
        Enums.ComponentType.STEP_CONTAINER
      )
    );
  };

  /**
   * Step wizard Design template
   * @param {*} stepDesignType
   */
  static getStepWizardTemplate = (stepDesignType) => {
    let stepTemplate = "";
    let css = "";
    //simple
    if (stepDesignType === "S") {
      stepTemplate =
        '<span class="no-step">{step}</span>' +
        '<div class="cont-step">' +
        '<h2 class="tit-step" data-lang="{step}stepTitle">{title}</h2>' +
        "</div>";
      css = "step-simple";
    } else {
      stepTemplate =
        '<span class="no-step">{step}</span>' +
        '<div class="cont-step">' +
        '<h2 class="tit-step" data-lang="{step}stepTitle">{title}</h2>' +
        '<p class="txt-step" data-lang="{step}stepDescription">{description}</p>' +
        "</div>";
    }

    return {
      stepTemplate: stepTemplate,
      css: css,
    };
  };

  /**
   * tab  template component
   * @returns
   */
  static getTabTemplate = (templateComponents) => {
    let tabContainer = this.getTabContainerTemplate(templateComponents);
    const defaultTabOption = { ...tabContainer.propertyValue.defaultTab };

    let newComp = { ...defaultTabOption };
    newComp.compId = "tab-" + StringUtils.getUuid();
    newComp.type = Enums.ComponentType.TAB;
    newComp.propertyValue = newComp.propertyValue || {};
    newComp.editorAttr = newComp.editorAttr || {};
    newComp.viewerAttr = newComp.viewerAttr || {};
    newComp.child = [];
    return newComp;
  };

  /**
   * widget container template
   * @returns
   */
  static getWidgetContainerTemplate = (templateComponents) => {
    return UITemplateHelper.getNewTemplateComponent(
      Enums.ComponentType.WIDGET_CONTAINER,
      UITemplateHelper.getTemplate(
        templateComponents,
        Enums.ComponentType.WIDGET_CONTAINER
      )
    );
  };

  /**
   * AMCHARTS3  template component
   * @returns
   */
  static getAMChart3Component = (templateComponents) => {
    return UITemplateHelper.getNewTemplateComponent(
      "chart",
      UITemplateHelper.getTemplate(
        templateComponents,
        Enums.ComponentType.COMPONENT + "/widget/chart/AMChart3Component"
      )
    );
  };

  /**
   * widget container template
   * @returns
   */
  static getLoginTemplate = (templateComponents) => {
    return UITemplateHelper.getNewTemplateComponent(
      Enums.ComponentType.LOGIN_TEMPLATE,
      UITemplateHelper.getTemplate(
        templateComponents,
        Enums.ComponentType.LOGIN_TEMPLATE
      )
    );
  };

  /**
   * template component
   * @param {*} templateComponents
   * @returns
   */
  static getTemplate = (templateComponents, componentType) => {
    return templateComponents[componentType];
  };

  /**
   * get new template component
   * @param {*} prefix
   * @param {*} baseComponent
   * @returns
   */
  static getNewTemplateComponent = (prefix, baseComponent) => {
    let newComp = {};
    //form
    newComp.compId = prefix + "-" + StringUtils.getUuid();
    newComp.type = baseComponent.componentType;
    newComp.baseCompId = baseComponent.componentDtlId;
    newComp.propertyValue = JsonUtils.parseJson(baseComponent.defaultProperty);
    newComp.editorAttr = JsonUtils.parseJson(baseComponent.editorAttr);
    newComp.viewerAttr = JsonUtils.parseJson(baseComponent.viewerAttr);

    if (baseComponent.componentType !== Enums.ComponentType.COMPONENT) {
      newComp.child = [];
    }

    return newComp;
  };

  /**
   * 우측 버튼 Group style
   * @returns
   */
  static getDefaultRightButtonGroupStyle = () => {
    return {
      display: "flex",
      alignContent: "center",
      justifyContent: "flex-end",
      alignItems: "center",
      height: "100%",
    };
  };

  /**
   * 좌측 벼튼 Group Style
   * @returns
   */
  static getDefaultLeftButtonGroupStyle = () => {
    return {
      display: "flex",
      alignContent: "center",
      justifyContent: "flex-start",
      alignItems: "center",
      height: "100%",
    };
  };

  /**
   * default 검색 Button Style
   * @returns
   */
  static getSearchBtnPropertyValue = () => {
    return {
      id: "btnSearch",
      label: "조회",
      icon: "fa-search",
      btnColor: "btn-search",
      eventType: "BTN_SCH",
    };
  };

  /**
   * default 닫기 Button Style
   * @returns
   */
  static getCloseBtnPropertyValue = () => {
    return {
      id: "btnClose",
      label: "닫기",
      btnColor: "btn-form",
    };
  };

  /**
   * Popup footer Template
   * @param {*} baseComponent
   * @param {*} popupOptions
   */
  static getPopupFooterTemplate = (context, popupOptions) => {
    const templateComponents = UITemplateHelper.getTemplateComponents(
      context.component
    );

    // //row
    // let newFooterRow = UITemplateHelper.getRowTemplate(templateComponents);
    // newFooterRow.propertyValue.style = {
    //   maxHeight: Enums.Style.FOOTER_HEIGHT - 1 + "px",
    //   minHeight: Enums.Style.FOOTER_HEIGHT - 1 + "px",
    // };
    let childCol = [];

    //left col
    let leftCol = UITemplateHelper.getColTemplate(templateComponents);
    leftCol.propertyValue.style = {
      ...{
        minHeight: Enums.Style.FOOTER_HEIGHT - 3 + "px",
        height: Enums.Style.FOOTER_HEIGHT - 3 + "px",
      },
    };
    //left button block
    let leftBlock = UITemplateHelper.getBlockTemplate(templateComponents);
    leftBlock.propertyValue.style = {
      ...UITemplateHelper.getDefaultLeftButtonGroupStyle(),
    };
    leftCol.child.push(leftBlock);
    //newFooterRow.child.push(leftCol);
    childCol.push(leftCol);

    //right col
    let rightCol = UITemplateHelper.getColTemplate(templateComponents);
    //right button block
    let rightBlock = UITemplateHelper.getBlockTemplate(templateComponents);
    rightBlock.propertyValue.style = {
      ...UITemplateHelper.getDefaultRightButtonGroupStyle(),
    };
    rightCol.child.push(rightBlock);
    //newFooterRow.child.push(rightCol);
    childCol.push(rightCol);

    rightCol.propertyValue.style = {
      ...{
        minHeight: Enums.Style.FOOTER_HEIGHT - 3 + "px",
        height: Enums.Style.FOOTER_HEIGHT - 3 + "px",
      },
    };

    //닫기 버튼이 표시되어야 하는경우
    if (popupOptions.footerCloseBtn !== false) {
      let closeButton = UITemplateHelper.getButtonTemplate(templateComponents);
      closeButton.propertyValue = {
        ...UITemplateHelper.getCloseBtnPropertyValue(),
      };
      rightBlock.child.push(closeButton);
    }

    return childCol; //newFooterRow;
  };
  /**
   * component class로 component 세부 정보를 추출한다.
   * @param {*} context
   * @param {*} newComponentClass
   */
  static getComponentInfo = (context, newComponentClass) => {
    let newComponent;
    context.component.getComponentList("B").some((component, index) => {
      if (newComponentClass === component.componentClass) {
        newComponent = component;
        return true;
      }
    });

    return newComponent;
  };

  /**
   * get widget template html
   * @param {*} templateProps
   * @returns
   */
  static getWidgetTemplateHtml = (templateProps) => {
    let bodyHtml = templateProps.bodyTemplate;
    if (
      StringUtils.indexOf(bodyHtml, "{icon}") > -1 &&
      !ObjectUtils.isEmpty(templateProps.bodyIcon)
    ) {
      let IconTag =
        Fa[StringUtils.convertKebabToPascal(templateProps.bodyIcon.id)];
      if (IconTag) {
        //size,color,title
        let iconSvg = ReactDOMServer.renderToString(
          <IconTag {...templateProps.bodyIcon} />
        );
        bodyHtml = bodyHtml.replaceAll("{icon}", iconSvg);
      }
    }
    return bodyHtml;
  };

  /**
   * widgetbox의 style
   * @param {*} boxStyle
   * @param {*} templateProps
   * @returns
   */
  static getWidgetBoxStyle = (boxStyle, templateProps) => {
    if (StringUtils.defaultString(templateProps.line, "none") !== "none") {
      boxStyle.border =
        templateProps.line + "px solid " + templateProps.lineColor;
    }

    if (!StringUtils.isEmpty(templateProps.boxShadow)) {
      boxStyle.boxShadow = templateProps.boxShadow;
    }

    if (
      StringUtils.defaultString(templateProps.borderRadius, "none") !== "none"
    ) {
      boxStyle.borderRadius = templateProps.borderRadius + "px";
    }

    if (StringUtils.defaultString(templateProps.topLine, "none") !== "none") {
      boxStyle.borderTop =
        templateProps.topLine + "px solid " + templateProps.toplineColor;
    }
    return boxStyle;
  };
  /**
   * Widget Template을 화면에 render한다.
   * @param {*} boxStyle
   * @param {*} templateProps
   * @returns
   */
  static renderWidgetTemplate = (templateProps, otherProps, children) => {
    let boxbodyStyle = otherProps ? otherProps.boxbodyStyle : {} || {};
    return (
      <Fragment>
        {templateProps.header === true ? (
          <div className="widget-header">
            <div className="widget-title">{templateProps.title}</div>
            {!ArrayUtils.isEmpty(templateProps.buttons) ? (
              <div className="widget-tools">
                {templateProps.buttons.map((template, index) => {
                  const IconTag =
                    Fa[StringUtils.convertKebabToPascal(template.icon)];
                  return (
                    <button type="button" className="btn" key={index}>
                      {IconTag ? <IconTag /> : ""}
                    </button>
                  );
                })}
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
        <div className="widget-body" style={{ ...boxbodyStyle }}>
          {!StringUtils.isEmpty(templateProps.bodyTemplate) ? (
            <div className="widget-body" style={{ ...boxbodyStyle }}>
              <div
                className="widget-template"
                dangerouslySetInnerHTML={{
                  __html: this.getWidgetTemplateHtml(templateProps),
                }}
              ></div>
              {children}
            </div>
          ) : (
            <Fragment>{children}</Fragment>
          )}
        </div>
      </Fragment>
    );
  };
}

export default UITemplateHelper;
