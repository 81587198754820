import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React from "react";
import { ToastContainer } from "react-toastify";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import "react-toastify/dist/ReactToastify.css";

import "css/common/main.css";
import "css/example.css";
import "css/common/component.css";
import "css/common/theme/skin-dark.css";
import "reactflow/dist/style.css";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File
import "@grapecity/wijmo.styles/wijmo.css"; //Import Wijmo Grid Css
import "css/common/wijmo.css";

/* Builder layout */
import BuilderLayout from "components/builder/BuilderLayout";
import UnissoLogin from "page/user/UnissoLogin";
import Login from "page/user/Login";
import * as Enums from "components/builder/BuilderEnum";
function App() {
  return (
    <React.Fragment>
      <Router>
        <Routes>
          {/* TO-DO : 인증되지 않은 사용자는 login 화면으로 이동하는 로직 추가 예정  */}
          {/* page는 모두 함수형으로 작성할것, class형 hook 지원을 위한 HOC wrapper class는 더이상 사용하지 않음.  */}
          <Route path="/*" element={<BuilderLayout />} />
          <Route path="/unite/builder/checkSSO" element={<UnissoLogin />} />
          <Route path={Enums.BuilderPath.LOGIN} element={<Login />} />
        </Routes>
      </Router>
      <ToastContainer />
    </React.Fragment>
  );
}

export default App;
