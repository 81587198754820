import ActionType from "components/builder/BuilderActionType";
import ArrayUtils from "components/common/utils/ArrayUtils";
import produce from "immer";

const initialState = {
  erdInfo: {
    erdId: "",
    erdName: "",
    dbType: "",
    erdType: "single",
  },
  output: {
    areas: {
      // erdTest:{
      //   compId: StringUtils.getUuid(),
      //   viewport: { x: 0, y: 0, zoom: 0.75 },
      //   child: {
      //     table: [],
      //   },
      // }
    },
  },
  activatedErd: {},
};

export default function ErdReducer(state = initialState, action) {
  switch (action.type) {
    case ActionType.ERD.INIT:
      return initialState;
    case ActionType.ERD.UPDATE:
      return action.payload;
    case ActionType.ERD.CREATE:
      const { erdId, erdName, dbType, erdType, areaList, description } =
        action.payload;

      return produce(state, (draft) => {
        draft.erdInfo.erdId = erdId;
        draft.erdInfo.erdName = erdName;
        draft.erdInfo.description = description;
        draft.erdInfo.dbType = dbType;
        draft.erdInfo.erdType = erdType;
        if (ArrayUtils.isArray(areaList)) {
          areaList.map((area, index) => {
            //첫번째 ERD를 기본 선택항목으로 지정
            if (index === 0) draft.activatedErd = area;
            draft.output.areas[area.compId] = {
              ...area,
              viewport: { x: 0, y: 0, zoom: 0.75 },
              table: [],
            };
          });
        }
      });
    case ActionType.ERD.SELECT:
      return produce(state, (draft) => {
        draft.activatedErd = action.payload;
      });
    case ActionType.ERD.UPDATE_VIEWPORT:
      const { viewport, areaId } = action.payload;
      return produce(state, (draft) => {
        if (!draft.output.areas[areaId].viewport)
          draft.output.areas[areaId].viewport = {};
        draft.output.areas[areaId].viewport = viewport;
      });
    case ActionType.ERD.EDIT:
      return produce(state, (draft) => {
        draft.erdInfo.erdId = action.payload.erdId;
        draft.erdInfo.erdName = action.payload.erdName;
        draft.erdInfo.description = action.payload.description;
        draft.erdInfo.dbType = action.payload.dbType;
        draft.erdInfo.erdType = action.payload.erdType;
        if (ArrayUtils.isArray(action.payload.areaList)) {
          action.payload.areaList.map((area, index) => {
            draft.output.areas[area.compId] = {
              viewport: { x: 0, y: 0, zoom: 0.75 },
              table: [],
              ...area,
              ...draft.output.areas[area.compId],
            };
          });
        }
      });
    default:
      return state;
  }
}
