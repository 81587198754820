import Api from "components/common/Api";

class SettingService {
  static saveStyle(data, callbackFnc) {
    Api.post("/common/saveStyle", data, callbackFnc);
  }

  static getStyleList(data, callbackFnc) {
    Api.post("/common/getStyleList", data, callbackFnc);
  }
  static deleteStyle(data, callbackFnc) {
    Api.post("/common/deleteStyle", data, callbackFnc);
  }
  static getStyle(data, callbackFnc) {
    Api.post("/common/getStyle", data, callbackFnc);
  }
  static saveUserConfig(data, callbackFnc) {
    Api.post("/common/saveUserConfig", data, callbackFnc);
  }
  static getUserConfig(data, callbackFnc) {
    Api.post("/common/getUserConfig", data, callbackFnc);
  }
  static deleteUserConfig(data, callbackFnc) {
    Api.post("/common/deleteUserConfig", data, callbackFnc);
  }
  static getDefaultStyle(data, callbackFnc) {
    Api.post("/common/getDefaultStyle", data, callbackFnc);
  }

  static getBuidlerDefaultStyle(data, callbackFnc) {
    Api.post("/common/getBuidlerDefaultStyle", data, callbackFnc);
  }
}
export default SettingService;
