import Modal from "components/common/modal/UModal";
import UModalTemplate from "components/common/modal/UModalTemplate";
import React from "react";

function IteratorOptionDescPopup() {
  return (
    <Modal>
      <Modal.Header title="Iterator 조건 설명" />
      <Modal.Body>
        <UModalTemplate>
          <p>Iterator 조건</p>
          <p>- 3 가지에 대한 처리가 가능하다.</p>
          <p>
            <strong>
              1) List (배열, Multiple-Data) 일 경우 - List 수만큼 반복한다.
            </strong>
            <br />
            <span>
              - Iterator 변수 의 값은 List 의 Row Data (Map - Single-Data) 이다{" "}
            </span>
          </p>
          <p>
            <strong>
              2) Map (Single-Data) - 데이타의 Field 만큼 반복한다.
            </strong>
            <br />
            <span>
              - Iterator 변수 의 값은 Map 의 Field ID & Field Value 이다.
              <br />
              ("Iterator 변수".getKey(), "Iterator 변수". getValue())
            </span>
          </p>
          <p>
            <strong>3) 숫자 - 0부터 "Iterator 조건" 횟수 만큰 반복한다.</strong>
            <br />
            <span>- Iterator 변수 의 값은 반복 index이다.</span>
          </p>
        </UModalTemplate>
      </Modal.Body>
    </Modal>
  );
}

export default IteratorOptionDescPopup;
