import Blockly from "blockly";
import { createMinusField, createPlusField } from "./BlockPlusMinus";

/**
 * Blockly Mutator 공식 문서 link: https://developers.google.com/blockly/guides/create-custom-blocks/extensions#mutators
 */

/**
 * Blockly Plus Minus Mutator link: https://github.com/google/blockly-samples/blob/master/plugins/block-plus-minus/src/list_create.js
 */

const arrayCreateMutator = {
  itemCount_: 1,

  mutationToDom: function () {
    const container = Blockly.utils.xml.createElement("mutation");

    this.arrayData.forEach((element) => {
      const data = Blockly.utils.xml.createElement("data");
      data.setAttribute("key", element.key);
      container.appendChild(data);
    });
    return container;
  },

  domToMutation: function (xmlElement) {
    const keys = [];

    for (const childNode of xmlElement.childNodes) {
      if (childNode.nodeName.toLowerCase() === "data") {
        keys.push(childNode.getAttribute("key"));
      }
    }
    this.updateShape_(keys);
  },

  saveExtraState: function () {
    const state = Object.create(null);

    if (this.arrayData.length) {
      state["data"] = [];
      this.arrayData.forEach((data) => {
        state["data"].push({
          key: data.key,
        });
      });
    }
    state["delData"] = this.delData;
    state["itemCount"] = this.itemCount_;
    return state;
  },

  loadExtraState: function (state) {
    const keys = [];

    if (state["data"]) {
      for (let i = 0; i < state["data"].length; i++) {
        const param = state["data"][i];
        keys.push(param["key"]);
      }
    }
    this.updateShape_(keys, state["delData"]);
  },

  updateShape_: function (keys, delData) {
    const valuesData = [];

    if (delData) {
      this.removePart_(delData);
    }
    for (let i = 0; i <= keys.length - 1; i++) {
      valuesData.push(this.getFieldValue("valueData" + keys[i]));
      this.removePart_(keys[i]);
    }
    this.arrayData = [];
    const length = keys.length;

    for (let i = 0; i < length; i++) {
      this.addPart_(keys[i], valuesData[i]);
    }
  },

  plus: function () {
    this.addPart_();
  },

  minus: function (keyId) {
    if (this.itemCount_ === 0) {
      return;
    }
    this.arrayData.filter((data) => data.key !== keyId);

    this.removePart_(keyId);
  },

  addPart_: function (keyId = null, valueData = null) {
    if (keyId) {
      this.appendDummyInput("key" + keyId)
        .appendField(createMinusField(), keyId)
        .appendField(
          new Blockly.FieldTextInput(valueData),
          "valueData" + keyId
        );

      this.arrayData.push({
        key: keyId,
      });
    } else {
      this.itemCount_++;

      this.appendDummyInput("key" + this.itemCount_)
        .appendField(createMinusField(), `${this.itemCount_}`)
        .appendField(
          new Blockly.FieldTextInput(),
          "valueData" + this.itemCount_
        );

      this.arrayData.push({
        key: `${this.itemCount_}`,
      });
    }
  },

  removePart_: function (keyId = null) {
    if (keyId) {
      if (this.removeInput("key" + keyId, true)) {
        this.arrayData = this.arrayData.filter((data) => data.key !== keyId);
        this.delData = keyId;
      }
    }
  },
};

// Plus image field를 해당 Block에 생성
const listCreateHelper = function () {
  this.arrayData = [];
  this.getInput(this.inputList[0].name).insertFieldAt(
    0,
    createPlusField(),
    "PLUS"
  );
};

Blockly.Extensions.registerMutator(
  "array_control",
  arrayCreateMutator,
  listCreateHelper
);
