import React, { useEffect, useState } from "react";
import CodeMirror from "@uiw/react-codemirror";
import { json } from "@codemirror/lang-json";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import produce from "immer";
import JsonUtils from "components/common/utils/JsonUtils";
import { useDispatch, useSelector } from "react-redux";
import WorkflowReduxHelper from "components/builder/workflow/editor/helper/WorkflowReduxHelper";
import { useRef } from "react";

function WorkflowCodeMirror() {
  const dispatch = useDispatch();

  const workflow = useSelector((state) => state.workflow);

  const [sourceViewOptions, setSourceViewOptions] = useState({
    containEditorAttr: false,
  });

  const codeText = useRef(JSON.stringify(workflow.output, null, 2));

  // const [viewSource, setViewSource] = useState(
  //   JSON.stringify(workflow.output, null, 2)
  // );

  const changeViewSource = (value) => {
    if (
      sourceViewOptions.containEditorAttr &&
      JSON.stringify(workflow.output, null, 2) !== value
    ) {
      try {
        codeText.current = value;
      } catch (e) {
        console.log("Json parsing Error");
      }
    }
  };

  useEffect(() => {
    if (sourceViewOptions.containEditorAttr) {
      codeText.current = JSON.stringify(workflow.output, null, 2);
    }
    return () => {
      WorkflowReduxHelper.updateWorkflowOutput(
        dispatch,
        JSON.parse(codeText.current),
        workflow
      );
    };
  }, []);

  return (
    <div className="edit-source">
      <div className="edit-source-options">
        <BootstrapSwitchButton
          checked={sourceViewOptions.containEditorAttr}
          size="sm"
          onstyle="primary"
          offstyle="dark"
          onlabel="편집 On"
          offlabel="편집 Off"
          width={120}
          onChange={(containEditorAttr, e) => {
            setSourceViewOptions({
              ...sourceViewOptions,
              containEditorAttr: containEditorAttr,
            });
          }}
        />
      </div>
      <div className="codeMirror-area">
        <CodeMirror
          value={codeText.current}
          className="source-container"
          height="calc(100vh - 30px - 50px - 32px)"
          extensions={[json(true)]}
          autoFocus={false}
          editable={sourceViewOptions.containEditorAttr}
          onChange={(value, viewUpdate) => changeViewSource(value, viewUpdate)}
        />
      </div>
    </div>
  );
}

export default WorkflowCodeMirror;
