import React, { Fragment, PureComponent } from "react";
import StringUtils from "components/common/utils/StringUtils";
import ReactDOM from "react-dom";
import prefix from "react-prefixr";
import Draggable from "react-draggable";

let modals = [];
const modalNodePreix = "_Modal_";
export const defaultStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1040,
    overflow: "hidden",
    perspective: 1300,
    backgroundColor: "rgba(0, 0, 0, 0.3)",
  },

  content: {
    position: "fixed",
    margin: "0% auto",
    top: 0,
    width: "60%",
    border: "1px solid rgba(0, 0, 0, .2)",
    background: "#fff",
    overflow: "auto",
    resize: "horizontal",
    borderRadius: "2px",
    outline: "none",
    boxShadow: "0 5px 10px rgba(0, 0, 0, .3)",
    maxHeight: "calc(100vh - 40px - 4px)" /*40px : modal 위치, 4px : space */,
    zIndex: 1041,
    left: 0,
    right: 0,
  },
};
class UModalManager extends PureComponent {
  constructor(props) {
    super(props);
    this.closeTimer = null;
    this.state = {
      isOpen: false,
      keyDownEvent: true,
    };
  }

  defaultTransition = {
    property: "all",
    duration: 300,
    timingfunction: "linear",
  };

  getTransitionDuration = () => {
    let effect = this.props.effect;

    if (!effect.transition) {
      return this.defaultTransition.duration;
    }
    return effect.transition.duration || this.defaultTransition.duration;
  };

  /**
   * 팝업창 주변 클릭시
   */
  close = () => {
    if (!this.props.onRequestClose || this.props.onRequestClose()) {
      UModalManager.close();
    }
  };

  handleKeyDown = (event) => {
    if (this.state.keyDownEvent) {
      if (event.keyCode === 27 /*esc*/) UModalManager.close();
    }
  };

  stopPropagation = (e) => {
    return e.stopPropagation();
  };

  componentDidMount = () => {
    if (modals.length === 0) {
      console.log(
        "[Developer Error] 소스 Rebuild로 Modal 관련 상태값이 변경 되었으니 새로고침 해주세요.!!"
      );
      return false;
    }
    modals[modals.length - 1].mananger = this;
    if (this.props.keyDownEvent !== undefined) {
      this.setState({ keyDownEvent: this.props.keyDownEvent });
    }
    const _this = this;
    setTimeout(function () {
      return _this.setState({ isOpen: true }, function () {
        this.refs.content.focus();
        this.refs.content.addEventListener("keydown", this.handleKeyDown);
      });
    }, 0);
  };

  /**
   * 닫기 버튼 클릭시
   * @param {*} callback
   */
  onClose = (callback) => {
    const transitionTimeMS = this.getTransitionDuration();

    this.setState({ isOpen: false }, function () {
      this.refs.content.removeEventListener("keydown", this.handleKey);
      this.closeTimer = setTimeout(callback, transitionTimeMS);

      if (typeof this.props.onCloseCallback === "function") {
        this.props.onCloseCallback.call(this);
      }
    });
  };
  componentWillUnmount = () => {
    clearTimeout(this.closeTimer);
  };

  render() {
    let transition = {
      ...this.defaultTransition,
      ...this.props.effect.transition,
    };

    let overlayTransitionStyle = {
      transition: "opacity " + transition.duration / 1000 + "s linear",
      opacity: this.state.isOpen ? 1 : 0,
    };

    let contentsTransitionStyle = {
      transition:
        transition.property +
        " " +
        transition.duration / 1000 +
        "s" +
        " " +
        transition.timingfunction,
    };

    //overlay
    const overlay = {
      ...defaultStyles.overlay,
      ...this.props.style.overlay,
      ...overlayTransitionStyle,
    };

    //content
    const content = {
      ...defaultStyles.content,
      ...this.props.style.content,
      ...contentsTransitionStyle,
      ...(this.state.isOpen ? this.props.effect.end : this.props.effect.begin),
    };
    return (
      <Fragment>
        <div
          ref="overlay"
          tabIndex="1"
          style={prefix(overlay)}
          onClick={this.close.bind(this)}
        ></div>
        <DragableModal {...this.props}>
          <div
            ref="content"
            tabIndex="2"
            style={prefix(content)}
            onClick={this.stopPropagation.bind(this)}
          >
            {this.props.element}
          </div>
        </DragableModal>
      </Fragment>
    );
  }
}

const renderModal = () => {
  if (modals.length === 0) return;

  let modalRoot = document.getElementById("_modalRoot");
  if (!modalRoot) {
    modalRoot = document.createElement("div");
    modalRoot.setAttribute("id", "_modalRoot");
    document.body.appendChild(modalRoot);
  }

  var modal = modals[modals.length - 1]; //modals.shift();
  let modalNode;
  modalNode = document.createElement("div");
  modalNode.setAttribute("id", modal.id);
  modalRoot.appendChild(modalNode);

  ReactDOM.render(modal.component, modalNode);
};

const open = (programId, component) => {
  let id =
    modalNodePreix +
    StringUtils.defaultString(programId, StringUtils.getUuid());
  modals.push({ id: id, component: component });
  renderModal();
};
const close = (programId) => {
  if (modals.length === 0) return;
  let modalNode;
  let modal;
  let targetIndex;

  //program id가 없을 경우 가장 마지막 popup을 close한다.
  if (StringUtils.isEmpty(programId)) {
    targetIndex = modals.length - 1;
    modal = modals[targetIndex];
    modalNode = document.getElementById(modal.id);
  } else {
    modalNode = document.getElementById(modalNodePreix + programId);
    modals.some((modalObj, index) => {
      if (modalObj.id === modalNodePreix + programId) {
        targetIndex = index;
        modal = modalObj;
        return true;
      }
    });
  }
  if (!modalNode || !modal) return;

  modals[targetIndex].mananger.onClose &&
    modals[targetIndex].mananger.onClose(function () {
      ReactDOM.unmountComponentAtNode(modalNode);
      modalNode.remove();
    });
  modals.splice(targetIndex, 1);
};
export default Object.assign(UModalManager, {
  open,
  close,
});

/** children 속성
    position: fixed;
    inset: 0px;
    top: 40px;
    z-index: 1042;
    height: max-content;
 */
class DragableModal extends PureComponent {
  constructor(props) {
    super(props);

    this.disableDragging = this.props.disableDragging === true;
    this.draggingX = this.props.draggingX || 25;
    this.draggingY = this.props.draggingY || 25;
  }

  render() {
    //dragable disabled
    if (this.disableDragging === true) {
      return this.props.children;
    } else {
      return (
        <Draggable
          handle=".modal-header"
          grid={[this.draggingX, this.draggingY]}
        >
          {this.props.children}
        </Draggable>
      );
    }
  }
}
