import Blockly from "blockly";
import { BlockCallbackInput } from "../blockInput/BlockCallbackInput";

// Blockly zelos github link : https://github.com/google/blockly/tree/develop/core/renderers/zelos

const HexagonalType = 1;
const RoundedType = 2;
const HexagonalTypeWidth = 5;
const RoundedTypeWidth = 30;
export let inlineInputWidth = 48;

// field_input의 default 값 설정
Blockly.FieldTextInput.prototype.DEFAULT_VALUE = "     ";

/**
 * Modify the given row to add the given amount of padding around its fields.
 * The exact location of the padding is based on the alignment property of the
 * last input in the field.
 * @param {Blockly.blockRendering.Row} row The row to add padding to.
 * @param {number} missingSpace How much padding to add.
 * @protected
 */
Blockly.zelos.RenderInfo.prototype.addAlignmentPadding_ = function (
  row,
  missingSpace
) {
  if (this.rightAlignedDummyInputs.get(row)) {
    let alignmentDivider;
    for (let i = 0; i < row.elements.length; i++) {
      const elem = row.elements[i];
      if (Blockly.blockRendering.Types.isSpacer(elem)) {
        alignmentDivider = elem;
      }
      if (
        Blockly.blockRendering.Types.isField(elem) &&
        elem instanceof Blockly.Field &&
        elem.parentInput === this.rightAlignedDummyInputs.get(row)
      ) {
        break;
      }
    }
    if (alignmentDivider) {
      alignmentDivider.width += missingSpace;
      row.width += missingSpace;
      return;
    }
  }
  var firstSpacer = row.getFirstSpacer();
  var lastSpacer = row.getLastSpacer();

  // Block이 Collapsed 상태일 때 길이 조정
  if (this.isCollapsed) {
    missingSpace = 250;
    if (this.block_.outputConnection) {
      missingSpace = 280;
    }
    if (this.block_.type === "callback") {
      missingSpace = 100;
    }
  }

  // row 정렬
  if (
    row instanceof Blockly.blockRendering.SpacerRow ||
    row instanceof Blockly.blockRendering.InputRow
  ) {
    if (!row.endsWithElemSpacer() || row.align === -1) {
      row.align = Blockly.ALIGN_RIGHT;
    }

    for (let i = 0; i < row.elements.length; i++) {
      if (row.elements[i].icon) {
        row.align = Blockly.ALIGN_LEFT;
        break;
      }
    }
  }
  if (row.hasExternalInput || row.hasStatement) {
    row.widthWithConnectedBlocks += missingSpace;
  }

  // Decide where the extra padding goes.
  if (row.align === Blockly.ALIGN_LEFT && lastSpacer) {
    // Add padding to the end of the row.
    lastSpacer.width += missingSpace;
  } else if (row.align === Blockly.ALIGN_CENTRE && firstSpacer && lastSpacer) {
    // Split the padding between the beginning and end of the row.
    firstSpacer.width += missingSpace / 2;
    lastSpacer.width += missingSpace / 2;
  } else if (row.align === Blockly.ALIGN_RIGHT && firstSpacer) {
    // Add padding at the beginning of the row.
    firstSpacer.width += missingSpace;
  } else if (lastSpacer) {
    // Default to left-aligning.
    lastSpacer.width += missingSpace;
  } else {
    return;
  }
  row.width += missingSpace;
};

/**
 * Finalize the output connection info.  In particular, set the height of the
 * output connection to match that of the block.  For the right side, add a
 * right connection shape element and have it match the dimensions of the
 * output connection.
 */
Blockly.zelos.RenderInfo.prototype.finalizeOutputConnection_ = function () {
  // Dynamic output connections depend on the height of the block.
  if (!this.outputConnection || !this.outputConnection.isDynamicShape) {
    return;
  }

  const outputConnectionShape = this.outputConnection.shape;
  if (
    !("isDynamic" in outputConnectionShape && outputConnectionShape.isDynamic)
  ) {
    return;
  }

  let yCursor = 0;
  // Determine the block height.
  for (let i = 0; i < this.rows.length; i++) {
    const row = this.rows[i];

    row.yPos = yCursor;
    yCursor += row.height;
  }

  this.height = yCursor;

  // Adjust the height of the output connection.
  const blockHeight = this.bottomRow.hasNextConnection
    ? this.height - this.bottomRow.descenderHeight
    : this.height;
  const connectionHeight = outputConnectionShape.height(blockHeight);
  const connectionWidth = outputConnectionShape.width(blockHeight);

  this.outputConnection.height = connectionHeight;
  this.outputConnection.width = connectionWidth;
  this.outputConnection.startX = connectionWidth;
  this.outputConnection.connectionOffsetY =
    outputConnectionShape.connectionOffsetY(connectionHeight);

  // output이 callback인 block일 때
  if (
    this.outputConnection &&
    Array.isArray(this.outputConnection.connectionModel.check) &&
    this.outputConnection.connectionModel.check.includes("callback")
  ) {
    if (!this.block_.collapsed_) {
      this.outputConnection.connectionOffsetX =
        outputConnectionShape.connectionOffsetX(connectionWidth - 30);
    } else {
      this.outputConnection.connectionOffsetX =
        outputConnectionShape.connectionOffsetX(connectionWidth);
    }
  } else {
    this.outputConnection.connectionOffsetX =
      outputConnectionShape.connectionOffsetX(connectionWidth);
  }

  // Add the right connection measurable.
  // Don't add it if we have a value-to-statement or a value-to-stack block.
  let rightConnectionWidth = 0;
  if (!this.hasStatementInput && !this.bottomRow.hasNextConnection) {
    rightConnectionWidth = connectionWidth;
    this.rightSide.height = connectionHeight;
    this.rightSide.width = rightConnectionWidth;
    this.rightSide.centerline = connectionHeight / 2;
    this.rightSide.xPos = this.width + rightConnectionWidth;
  }
  this.startX = connectionWidth;
  this.width += connectionWidth + rightConnectionWidth;
  this.widthWithChildren += connectionWidth + rightConnectionWidth;
};

/**
 * Create rows of Measurable objects representing all renderable parts of the
 * block.
 */
Blockly.zelos.RenderInfo.prototype.createRows_ = function () {
  this.populateTopRow_();
  this.rows.push(this.topRow);
  let activeRow = new Blockly.blockRendering.InputRow(this.constants_);
  this.inputRows.push(activeRow);

  // Icons always go on the first row, before anything else.
  const icons = this.block_.getIcons();
  for (let i = 0, icon; (icon = icons[i]); i++) {
    const iconInfo = new Blockly.blockRendering.Icon(this.constants_, icon);

    // part_collapsed_icon 이면 추가 안함
    if (iconInfo.icon.getType().name === "part_collapsed_icon") {
      continue;
    } else if (!this.isCollapsed || icon.isShownWhenCollapsed()) {
      activeRow.elements.push(iconInfo);
    }
  }

  let lastInput = undefined;
  // Loop across all of the inputs on the block, creating objects for anything
  // that needs to be rendered and breaking the block up into visual rows.
  for (let i = 0, input; (input = this.block_.inputList[i]); i++) {
    if (!input.isVisible()) {
      continue;
    }
    if (this.shouldStartNewRow_(input, lastInput)) {
      // Finish this row and create a new one.
      this.rows.push(activeRow);
      activeRow = new Blockly.blockRendering.InputRow(this.constants_);
      this.inputRows.push(activeRow);
    }

    // part_collapsed_icon을 추가
    const icons = this.block_.getIcons();
    for (let j = 0, icon; (icon = icons[j]); j++) {
      const iconInfo = new Blockly.blockRendering.Icon(this.constants_, icon);
      if (
        iconInfo.icon.getType().name === "part_collapsed_icon" &&
        i === Number(iconInfo.icon.state)
      ) {
        activeRow.elements.push(iconInfo);
      }
    }

    // All of the fields in an input go on the same row.
    for (let j = 0, field; (field = input.fieldRow[j]); j++) {
      activeRow.elements.push(
        new Blockly.blockRendering.Field(this.constants_, field, input)
      );
    }
    this.addInput_(input, activeRow);
    lastInput = input;
  }

  if (this.isCollapsed) {
    activeRow.hasJaggedEdge = true;
    activeRow.elements.push(
      new Blockly.blockRendering.JaggedEdge(this.constants_)
    );
  }

  if (activeRow.elements.length || activeRow.hasDummyInput) {
    this.rows.push(activeRow);
  }
  this.populateBottomRow_();
  this.rows.push(this.bottomRow);
};

/**
 * Add an input element to the active row, if needed, and record the type of
 * the input on the row.
 *
 * @param input The input to record information about.
 * @param activeRow The row that is currently being populated.
 */
Blockly.zelos.RenderInfo.prototype.addInput_ = function (input, activeRow) {
  if (
    input instanceof Blockly.inputs.DummyInput &&
    activeRow.hasDummyInput &&
    activeRow.align === Blockly.inputs.Align.LEFT &&
    input.align === Blockly.inputs.Align.RIGHT
  ) {
    this.rightAlignedDummyInputs.set(activeRow, input);
  } else if (input instanceof Blockly.inputs.StatementInput) {
    // Handle statements without next connections correctly.
    activeRow.elements.push(
      new Blockly.blockRendering.StatementInput(this.constants_, input)
    );
    activeRow.hasStatement = true;

    if (activeRow.align === null) {
      activeRow.align = input.align;
    }
    return;
  }
  if (this.isInline && input instanceof Blockly.inputs.ValueInput) {
    const newInlineInput = new Blockly.blockRendering.InlineInput(
      this.constants_,
      input
    );
    activeRow.elements.push(newInlineInput);
    activeRow.hasInlineInput = true;
  }
  // callback을 연결하는 input일 때
  else if (input instanceof BlockCallbackInput) {
    input.getSourceBlock().setOutputShape(true);

    const newInput = new Blockly.blockRendering.InlineInput(
      this.constants_,
      input
    );
    newInput.hasExternalInput = true;
    newInput.height = 32;
    newInput.width = 0;
    activeRow.elements.push(newInput);
    activeRow.hasExternalInput = true;
  } else if (input instanceof Blockly.inputs.StatementInput) {
    activeRow.elements.push(
      new Blockly.blockRendering.StatementInput(this.constants_, input)
    );
    activeRow.hasStatement = true;
  } else if (input instanceof Blockly.inputs.ValueInput) {
    activeRow.elements.push(
      new Blockly.blockRendering.ExternalValueInput(this.constants_, input)
    );
    activeRow.hasExternalInput = true;
  } else if (input instanceof Blockly.inputs.DummyInput) {
    // Dummy and end-row inputs have no visual representation, but the
    // information is still important.
    activeRow.minHeight = Math.max(
      activeRow.minHeight,
      input.getSourceBlock() && input.getSourceBlock().isShadow()
        ? this.constants_.DUMMY_INPUT_SHADOW_MIN_HEIGHT
        : this.constants_.DUMMY_INPUT_MIN_HEIGHT
    );
    activeRow.hasDummyInput = true;
  }
  if (activeRow.align === null) {
    activeRow.align = input.align;
  }
};

/**
 * Zelos Block 그리는 함수
 */
Blockly.zelos.Drawer.prototype.draw = function () {
  const pathObject = this.block_.pathObject;
  pathObject.beginDrawing();
  this.drawOutline_();
  this.drawInternals_();

  pathObject.setPath(this.outlinePath_ + "\n" + this.inlinePath_);

  if (this.info_.RTL) {
    pathObject.flipRTL();
  }
  this.recordSizeOnBlock_();
  if (this.info_.outputConnection) {
    // Store the output connection shape type for parent blocks to use during
    // rendering.
    pathObject.outputShapeType = this.info_.outputConnection.shape.type;
  }
  pathObject.endDrawing();
};

/**
 * Zelos block 바깥 라인 그리는 함수
 */
Blockly.zelos.Drawer.prototype.drawOutline_ = function () {
  // callback을 연결하는 block일 때 true
  let hasExternalInput = false;
  this.info_.inputRows.map((inputRow) => {
    if (inputRow.hasExternalInput) {
      hasExternalInput = true;
    }
  });
  if (
    this.info_.outputConnection &&
    this.info_.outputConnection.isDynamicShape &&
    !this.info_.hasStatementInput &&
    !this.info_.bottomRow.hasNextConnection &&
    !hasExternalInput
  ) {
    this.drawFlatTop_();
    this.drawRightDynamicConnection_();
    this.drawFlatBottom_();
    this.drawLeftDynamicConnection_();
  } else {
    this.drawTop_();
    for (let r = 1; r < this.info_.rows.length - 1; r++) {
      const row = this.info_.rows[r];
      if (row.hasJaggedEdge) {
        this.drawJaggedEdge_(row);
      } else if (row.hasStatement) {
        this.drawStatementInput_(row);
      } else if (row.hasExternalInput) {
        this.drawValueInput_(row);
      } else {
        this.drawRightSideRow_(row);
      }
    }
    this.drawBottom_();
    this.drawLeft_();
  }
};

/** Add steps to draw a flat top row. */
Blockly.zelos.Drawer.prototype.drawFlatTop_ = function () {
  const topRow = this.info_.topRow;
  this.positionPreviousConnection_();

  this.outlinePath_ += Blockly.utils.svgPaths.moveBy(
    topRow.xPos,
    this.info_.startY
  );
  this.outlinePath_ += Blockly.utils.svgPaths.lineOnAxis("h", topRow.width);
};

/**
 * Add steps to draw the left side of an output with a dynamic connection.
 */
Blockly.zelos.Drawer.prototype.drawLeftDynamicConnection_ = function () {
  if (!this.info_.outputConnection) {
    throw new Error(
      `Cannot draw the output connection of a block that doesn't have one`
    );
  }
  this.positionOutputConnection_();

  // output이 callback인 block일 때
  if (
    this.info_.outputConnection &&
    Array.isArray(this.info_.outputConnection.connectionModel.check) &&
    this.info_.outputConnection.connectionModel.check.includes("callback")
  ) {
    const height = this.info_.outputConnection.height;

    if (this.block_.collapsed_) {
      this.outlinePath_ += ` v ${
        -height / 2 + 16
      } h ${-20} v ${-16} v ${-16} h ${20} v ${-height / 2 + 16}`;
    } else {
      this.outlinePath_ += ` v ${
        -height / 2 + 16
      } h ${-20} v ${-16} v ${-16} h ${20} v ${-height / 2 + 16}`;
    }
  } else {
    this.outlinePath_ += this.info_.outputConnection.shape.pathUp(
      this.info_.outputConnection.height
    );
  }

  // Close off the path.  This draws a vertical line up to the start of the
  // block's path, which may be either a rounded or a sharp corner.
  this.outlinePath_ += "z";
};

Blockly.zelos.Drawer.prototype.drawTop_ = function () {
  const topRow = this.info_.topRow;
  const elements = topRow.elements;

  this.positionPreviousConnection_();
  this.outlinePath_ += Blockly.utils.svgPaths.moveBy(
    topRow.xPos,
    this.info_.startY
  );

  // callback을 연결하는 block일 때 true
  let hasExternalInput = false;
  this.info_.inputRows.map((inputRow) => {
    if (inputRow.hasExternalInput) {
      hasExternalInput = true;
    }
  });

  for (let i = 0, elem; (elem = elements[i]); i++) {
    if (Blockly.blockRendering.Types.isLeftRoundedCorner(elem)) {
      this.outlinePath_ += this.constants_.OUTSIDE_CORNERS.topLeft;
    } else if (Blockly.blockRendering.Types.isRightRoundedCorner(elem)) {
      this.outlinePath_ += this.constants_.OUTSIDE_CORNERS.topRight;
    } else if (
      Blockly.blockRendering.Types.isPreviousConnection(elem) &&
      elem instanceof Blockly.blockRendering.Connection
    ) {
      this.outlinePath_ += elem.shape.pathLeft;
    } else if (Blockly.blockRendering.Types.isHat(elem)) {
      this.outlinePath_ += this.constants_.START_HAT.path;
    } else if (Blockly.blockRendering.Types.isSpacer(elem)) {
      this.outlinePath_ += Blockly.utils.svgPaths.lineOnAxis("h", elem.width);
    }
  }

  // block이 callback을 연결하고 output이 있을 때
  if (
    this.info_.outputConnection &&
    this.info_.outputConnection.isDynamicShape &&
    hasExternalInput
  ) {
    // output 모양이 Hexagonal 모양일 때
    if (this.info_.outputConnection.shape.type === HexagonalType) {
      this.outlinePath_ += `h ${HexagonalTypeWidth}`;
    }
    // output 모양이 Rounded 모양일 떄
    else if (this.info_.outputConnection.shape.type === RoundedType) {
      this.outlinePath_ += `h ${RoundedTypeWidth}`;
    }
  }

  // No branch for a square corner, because it's a no-op.
  this.outlinePath_ += Blockly.utils.svgPaths.lineOnAxis("v", topRow.height);
};

/**
 * Add steps for the bottom edge of a block, possibly including a notch
 * for the next connection.
 */
Blockly.zelos.Drawer.prototype.drawBottom_ = function () {
  const bottomRow = this.info_.bottomRow;
  const elems = bottomRow.elements;

  this.positionNextConnection_();

  let rightCornerYOffset = 0;
  let outlinePath = "";

  // callback을 연결하는 block일 때 true
  let hasExternalInput = false;
  this.info_.inputRows.map((inputRow) => {
    if (inputRow.hasExternalInput) {
      hasExternalInput = true;
    }
  });

  for (let i = elems.length - 1, elem; (elem = elems[i]); i--) {
    if (
      Blockly.blockRendering.Types.isNextConnection(elem) &&
      elem instanceof Blockly.blockRendering.Connection
    ) {
      outlinePath += elem.shape.pathRight;
    } else if (Blockly.blockRendering.Types.isLeftSquareCorner(elem)) {
      outlinePath += Blockly.utils.svgPaths.lineOnAxis("H", bottomRow.xPos);
    } else if (Blockly.blockRendering.Types.isLeftRoundedCorner(elem)) {
      outlinePath += this.constants_.OUTSIDE_CORNERS.bottomLeft;
    } else if (Blockly.blockRendering.Types.isRightRoundedCorner(elem)) {
      // callback을 연결하지 않는 block일 때
      if (!elem.hasExternalInput) {
        outlinePath += this.constants_.OUTSIDE_CORNERS.bottomRight;
        rightCornerYOffset = this.constants_.OUTSIDE_CORNERS.rightHeight;
      }
    } else if (Blockly.blockRendering.Types.isSpacer(elem)) {
      outlinePath += Blockly.utils.svgPaths.lineOnAxis("h", elem.width * -1);
    }
  }

  this.outlinePath_ += Blockly.utils.svgPaths.lineOnAxis(
    "V",
    bottomRow.baseline - rightCornerYOffset
  );

  // Block이 callback을 연결하고 output이 있을 때
  if (
    this.info_.outputConnection &&
    this.info_.outputConnection.isDynamicShape &&
    hasExternalInput
  ) {
    // output 모양이 Hexagonal 모양일 때
    if (this.info_.outputConnection.shape.type === HexagonalType) {
      this.outlinePath_ += `h ${-HexagonalTypeWidth}`;
    }
    // output 모양이 Rounded 모양일 때
    else if (this.info_.outputConnection.shape.type === RoundedType) {
      this.outlinePath_ += `h ${-RoundedTypeWidth}`;
    }
  }

  this.outlinePath_ += outlinePath;
};

/**
 * Add steps for the left side of the block, which may include an output
 * connection
 */
Blockly.zelos.Drawer.prototype.drawLeft_ = function () {
  if (
    this.info_.outputConnection &&
    this.info_.outputConnection.isDynamicShape
  ) {
    this.drawLeftDynamicConnection_();
  } else {
    const outputConnection = this.info_.outputConnection;
    this.positionOutputConnection_();

    if (outputConnection) {
      const tabBottom =
        outputConnection.connectionOffsetY + outputConnection.height;
      const pathUp = outputConnection.shape.isDynamic
        ? outputConnection.shape.pathUp(outputConnection.height)
        : outputConnection.shape.pathUp;

      // Draw a line up to the bottom of the tab.

      this.outlinePath_ +=
        Blockly.utils.svgPaths.lineOnAxis("V", tabBottom) + pathUp;
    }
    // Close off the path.  This draws a vertical line up to the start of the
    // block's path, which may be either a rounded or a sharp corner.
    this.outlinePath_ += "z";
  }
};

// Blockly.zelos.Drawer.prototype.drawRightSideRow_ = function (row) {
//   if (row.height <= 0) {
//     return;
//   }

//   if (Blockly.blockRendering.Types.isSpacer(row)) {
//     const spacerRow = row;
//     const precedesStatement = spacerRow.precedesStatement;
//     const followsStatement = spacerRow.followsStatement;
//     if (precedesStatement || followsStatement) {
//       const insideCorners = this.constants_.INSIDE_CORNERS;
//       const cornerHeight = insideCorners.rightHeight;
//       const remainingHeight =
//         spacerRow.height - (precedesStatement ? cornerHeight : 0);
//       const bottomRightPath = followsStatement
//         ? insideCorners.pathBottomRight
//         : "";
//       const verticalPath =
//         remainingHeight > 0
//           ? Blockly.utils.svgPaths.lineOnAxis(
//               "V",
//               spacerRow.yPos + remainingHeight
//             )
//           : "";
//       const topRightPath = precedesStatement ? insideCorners.pathTopRight : "";
//       // Put all of the partial paths together.
//       this.outlinePath_ += bottomRightPath + verticalPath + topRightPath;
//       return;
//     }
//   }
//   this.outlinePath_ += Blockly.utils.svgPaths.lineOnAxis(
//     "V",
//     row.yPos + row.height
//   );
// };

/**
 * Add steps for an external value input, rendered as a notch in the side
 * of the block.
 *
 * @param row The row that this input belongs to.
 */
Blockly.zelos.Drawer.prototype.drawValueInput_ = function (row) {
  const input = row.getLastInput();
  this.positionExternalValueConnection_(row);

  let r = input.height / 2;

  this.outlinePath_ += `h ${r} v ${r * 2} h ${r * -1}`;
};

/**
 * Draw the internals of the block: inline inputs, fields, and icons.  These
 * do not depend on the outer path for placement.
 */
Blockly.zelos.Drawer.prototype.drawInternals_ = function () {
  for (let i = 0, row; (row = this.info_.rows[i]); i++) {
    for (let j = 0, elem; (elem = row.elements[j]); j++) {
      if (Blockly.blockRendering.Types.isInlineInput(elem)) {
        if (!elem.hasExternalInput) {
          this.drawInlineInput_(elem);
        }
      } else if (
        Blockly.blockRendering.Types.isIcon(elem) ||
        Blockly.blockRendering.Types.isField(elem)
      ) {
        this.layoutField_(elem);
      }
    }
  }
};

/**
 * Position the connection on an external value input, taking into account
 * RTL and the small gap between the parent block and child block which lets
 * the parent block's dark path show through.
 *
 * @param row The row that the connection is on.
 */
Blockly.zelos.Drawer.prototype.positionExternalValueConnection_ = function (
  row
) {
  const input = row.getLastInput();
  if (input && input.connectionModel) {
    let connX = row.xPos + row.width;
    if (this.info_.RTL) {
      connX *= -1;
    }

    if (
      this.info_.outputConnection &&
      this.info_.outputConnection.isDynamicShape
    ) {
      // output 모양이 Hexagonal 모양일 때
      if (this.info_.outputConnection.shape.type === HexagonalType) {
        input.connectionModel.setOffsetInBlock(
          connX + input.height / 4,
          row.yPos + input.height / 2
        );
      }
      // output 모양이 Rounded 모양일 때
      else if (this.info_.outputConnection.shape.type === RoundedType) {
        input.connectionModel.setOffsetInBlock(
          connX + input.height,
          row.yPos + input.height / 2
        );
      }
    } else {
      input.connectionModel.setOffsetInBlock(
        connX + input.height / 4,
        row.yPos + input.height / 2
      );
    }
  }
};
