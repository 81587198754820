/**
 * TO-DO 추후 theme 확장을 위해 theme provider 생성
 * @param {} children
 * @returns
 */
const AppThemeProvider = ({ children }) => {
  const appTheme = "skin-dark fixed";

  return <div className={appTheme}>{children}</div>;
};

export default AppThemeProvider;
