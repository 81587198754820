import { stopEvent } from "components/builder/ui/editor/handler/UIEditorEventHandler";
import ObjectUtils from "components/common/utils/ObjectUtils";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const { useState } = require("react");

const TableAreaListAsFlowWidget = ({
  onNodeClick: _onNodeClick,
  style = {},
  ...props
}) => {
  /*목록 접기 */
  const [isListCollapse, setIsListCollapse] = useState(true);
  const [list, setList] = useState([]);
  const erd = useSelector((state) => state.erd);
  const {
    output: { areas },
  } = erd;

  useEffect(() => {
    if (!ObjectUtils.isEmpty(areas)) {
      let cList = [];
      for (const k in areas) {
        cList.push({
          compId: areas[k].compId,
          name: areas[k].name,
        });
      }
      setList(cList);
    }
  }, [areas]);

  /**
   * 노드 선택
   * @param {*} e
   * @param {*} node
   */
  const onNodeClick = (e, node) => {
    setIsListCollapse(true);
    if (_onNodeClick) {
      _onNodeClick(e, node);
    }
  };
  return (
    <div
      className={`flow-entity-list ${isListCollapse ? "flow-collapse" : ""}`}
      style={style}
    >
      <div className="header">
        <span
          onClick={(e) => {
            e.stopPropagation();
            setIsListCollapse(false);
          }}
        >
          {!ObjectUtils.isEmpty(erd.activatedErd)
            ? erd.activatedErd.name
            : "테이블 영역 목록"}
        </span>{" "}
        {isListCollapse ? (
          <span
            onClick={(e) => {
              e.stopPropagation();
              setIsListCollapse(false);
            }}
          >
            ▼
          </span>
        ) : (
          <span
            onClick={(e) => {
              e.stopPropagation();
              setIsListCollapse(true);
            }}
          >
            ▲
          </span>
        )}
      </div>
      {!isListCollapse && (
        <div className="body-list">
          {list.length > 1 && (
            <div
              className="node"
              key={"all"}
              onClick={(e) =>
                onNodeClick(e, { type: "all", name: "전체 보기" })
              }
            >
              <span>전체 보기</span>
            </div>
          )}
          {list?.map((area, index) => {
            return (
              <div
                className="node"
                key={area.compId}
                onClick={(e) => onNodeClick(e, area)}
              >
                <span>{area.name}</span>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default TableAreaListAsFlowWidget;
