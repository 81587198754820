import Popup from "components/common/Popup";
import Modal from "components/common/modal/UModal";
import UModalTemplate from "components/common/modal/UModalTemplate";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import TableRelationPopup from "./TableRelationPopup";
import produce from "immer";

function TableRelationListPopup({
  table,
  erd,
  tableList,
  areaList,
  relationCallback,
  ...props
}) {
  const [relation, setRelation] = useState([]);
  const [toRelation, setToRelation] = useState([]);

  useEffect(() => {
    setRelation(table.relation);
    getToRelation();
  }, []);

  /**
   * ERD에서 현재 테이블을 바라보는 관계를 가져옴
   */
  const getToRelation = () => {
    let toTableList = [];
    const thisTableNm = table.propertyValue.physicalTableNm;
    for (const areaId in erd.output.areas) {
      let fromTables = erd.output.areas[areaId].table.filter((t) =>
        t.relation.find((r) => r.target === thisTableNm)
      );
      const areaInfo = {
        name: erd.output.areas[areaId].name,
        type: erd.output.areas[areaId].type,
        alias: erd.output.areas[areaId].alias,
      };
      fromTables = fromTables.map((t) => {
        const obj = { ...t };
        obj.areaInfo = areaInfo;
        return obj;
      });
      toTableList = toTableList.concat(fromTables);
    }
    setToRelation(toTableList);
  };

  /**
   * 관계(FROM) 설정
   * @param {*} relation
   */
  const onClickRelationEdit = (type = "edit", relation = [], index) => {
    const sourceTable = { ...table };

    const callback = (relationType, joinColumns, props) => {
      const rel = {
        relationType,
        joinColumns,
        target: props.targetTable.propertyValue.physicalTableNm,
      };
      setRelation(
        produce(relation, (draft) => {
          if (!index) {
            // 기존에 등록된 target 관계면 이전꺼 수정
            const prevRelIndex = relation.findIndex(
              (r) => r.target === rel.target
            );
            if (prevRelIndex > -1) {
              draft[prevRelIndex] = rel;
            } else {
              draft.push(rel);
            }
          } else {
            draft[index] = rel;
          }
        })
      );
      Popup.close();
    };

    const props =
      type === "edit"
        ? {
            targetTable: tableList.find((t) => t.compId === relation.target),
            relation,
          }
        : {};
    Popup.open(
      <TableRelationPopup
        callback={callback}
        sourceTable={sourceTable}
        tableList={tableList}
        areaList={areaList}
        {...props}
      />,
      {
        style: { content: { width: "600px" } },
      }
    );
  };

  /**
   * 관계 삭제
   * @param {*} relation
   */
  const onClickRelationRemove = (r, index) => {
    const nr = [...relation];
    nr.splice(index, 1);
    setRelation(nr);
  };

  /**
   * 확인 버튼
   */
  const onClickConfirm = () => {
    if (props.callback) props.callback(relation);
  };

  return (
    <Modal>
      <Modal.Header title={"테이블 관계 목록"} />
      <Modal.Body>
        <UModalTemplate className={"relation-list-popup"}>
          <Form.Group className="mb-3">
            <Form.Label>
              Table " {table.propertyValue.physicalTableNm} " 가 참조하는
              테이블&nbsp;(FROM)
            </Form.Label>
            <Row>
              <Col className="relation-add-button-col mb-3">
                <Button
                  size="sm"
                  onClick={(e) => onClickRelationEdit("add", relation)}
                >
                  참조 추가
                </Button>
              </Col>
            </Row>

            <Row className="relation-list-wrapper">
              <Col>
                <Row className="header">
                  <Col>참조 대상 테이블 (TO) </Col>
                  <Col>영역</Col>
                  <Col>설정</Col>
                </Row>
                <Row className="body">
                  <Col>
                    {relation.length > 0 ? (
                      relation.map((r, index) => {
                        return (
                          <Row key={index} className="row">
                            <Col>{r.target}</Col>
                            <Col></Col>
                            <Col className="cell-btn">
                              <Button
                                size="sm"
                                variant="outline-success"
                                onClick={(e) =>
                                  onClickRelationEdit("edit", r, index)
                                }
                              >
                                수정
                              </Button>
                              <Button
                                size="sm"
                                variant="outline-danger"
                                onClick={(e) => onClickRelationRemove(r, index)}
                              >
                                삭제
                              </Button>
                            </Col>
                          </Row>
                        );
                      })
                    ) : (
                      <div className="empty">연결된 관계가 없습니다.</div>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>
              Table " {table.propertyValue.physicalTableNm} " 가 참조되는
              테이블&nbsp;(TO)
            </Form.Label>

            <Row className="relation-list-wrapper">
              <Col>
                <Row className="header">
                  <Col>관계 테이블 (From) </Col>
                  <Col>영역</Col>
                  <Col>설정</Col>
                </Row>
                <Row className="body">
                  <Col>
                    {toRelation.length > 0 ? (
                      toRelation.map((r, index) => {
                        return (
                          <Row key={index} className="row">
                            <Col>{r.propertyValue.physicalTableNm}</Col>
                            <Col>{`${r.areaInfo.name} (${r.areaInfo.alias})`}</Col>
                            <Col className="cell-btn">
                              {/* <Button size="sm">수정</Button> */}
                            </Col>
                          </Row>
                        );
                      })
                    ) : (
                      <div className="empty">연결된 관계가 없습니다.</div>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form.Group>
        </UModalTemplate>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Button onClick={onClickConfirm}>확인</Modal.Footer.Button>
      </Modal.Footer>
    </Modal>
  );
}

export default TableRelationListPopup;
