import Blockly from "blockly";

/**
 * Blockly icon class link : https://github.com/google/blockly/blob/develop/core/icons/icon.ts
 * /
/**
 * Blockly custom icon link: https://developers.google.com/blockly/guides/create-custom-blocks/icons/creating-custom-icons/basic-implementation
 */

/**
 * Blockly CommentIcon을 상속받아 custom
 * 해당 Block의 tooltip을 보여줌
 */
class BlockTooltipIcon extends Blockly.icons.CommentIcon {
  constructor(sourceBlock) {
    super(sourceBlock);
  }

  getType() {
    return new Blockly.icons.IconType("tooltip_icon");
  }
  loadState(state) {
    this.state = state;
  }
  saveState() {
    return this.state;
  }
  getSize() {
    return new Blockly.utils.Size(16, 16);
  }
  onClick() {
    this.setBubbleVisible(!this.bubbleIsVisible());
  }

  setBubbleVisible(visible) {
    if (visible && (this.textBubble || this.textInputBubble)) return;
    if (!visible && !(this.textBubble || this.textInputBubble)) return;

    this.bubbleVisiblity = visible;

    if (!this.sourceBlock.rendered || this.sourceBlock.isInFlyout) return;

    if (visible) {
      if (this.sourceBlock.isEditable()) {
        this.showEditableBubble();
      } else {
        this.showNonEditableBubble();
      }
      this.applyColour();
    } else {
      this.hideBubble();
    }

    Blockly.Events.fire(
      new (Blockly.Events.get(Blockly.Events.BUBBLE_OPEN))(
        this.sourceBlock,
        visible,
        "comment"
      )
    );
  }
  applyColour() {
    super.applyColour();
    const colour = this.sourceBlock.style.colourPrimary;
    this.textInputBubble?.setColour(colour);
    this.textBubble?.setColour(colour);
  }

  showEditableBubble() {
    this.textInputBubble = new TextInputBubble(
      this.sourceBlock.workspace,
      this.getAnchorLocation(),
      this.getBubbleOwnerRect()
    );

    this.bubbleSize.width = 800;
    this.bubbleSize.height = 200;
    this.textInputBubble.setText(this.sourceBlock.tooltip);
    this.textInputBubble.setSize(this.bubbleSize, true);
    this.textInputBubble.addTextChangeListener(() => this.onTextChange());
    this.textInputBubble.addSizeChangeListener(() => this.onSizeChange());
  }

  showNonEditableBubble() {
    this.textBubble = new TextBubble(
      "textBubble",
      this.sourceBlock.workspace,
      this.getAnchorLocation(),
      this.getBubbleOwnerRect()
    );
  }

  getAnchorLocation() {
    const size = this.getSize();
    const midIcon = new Blockly.utils.Coordinate(
      size.width / 2,
      size.height / 2
    );
    return Blockly.utils.Coordinate.sum(this.workspaceLocation, midIcon);
  }

  getOwnerRect() {
    const bbox = this.sourceBlock.getSvgRoot().getBBox();
    return new Blockly.utils.Rect(
      bbox.y,
      bbox.y + bbox.height,
      bbox.x,
      bbox.x + bbox.width
    );
  }
  onLocationChange(blockOrigin) {
    super.onLocationChange(blockOrigin);
    this.myBubble?.setAnchorLocation(this.getAnchorLocation());
  }
}

class TextBubble extends Blockly.bubbles.TextBubble {
  constructor(text, workspace, anchor, ownerRect) {
    super(text, workspace, anchor, ownerRect);
    this.paragraph = this.stringToSvg(text, this.contentContainer);
    this.updateBubbleSize();
  }
}

class TextInputBubble extends Blockly.bubbles.TextInputBubble {
  constructor(workspace, anchor, ownerRect) {
    super(workspace, anchor, ownerRect);
    this.setSize(this.DEFAULT_SIZE, true);
  }
  createEditor(container) {
    const inputRoot = Blockly.utils.dom.createSvgElement(
      Blockly.utils.Svg.FOREIGNOBJECT,
      {
        x: Blockly.bubbles.Bubble.BORDER_WIDTH,
        y: Blockly.bubbles.Bubble.BORDER_WIDTH,
      },
      container
    );

    const body = document.createElementNS(Blockly.utils.dom.HTML_NS, "body");
    body.setAttribute("xmlns", Blockly.utils.dom.HTML_NS);
    body.className = "blocklyMinimalBody";

    const textArea = document.createElementNS(
      Blockly.utils.dom.HTML_NS,
      "textarea"
    );
    textArea.className = "blocklyCommentTextarea";
    textArea.readOnly = true;
    textArea.defaultValue = "test";
    textArea.setAttribute("dir", this.workspace.RTL ? "RTL" : "LTR");

    body.appendChild(textArea);
    inputRoot.appendChild(body);

    this.bindTextAreaEvents(textArea);
    setTimeout(() => {
      textArea.focus();
    }, 0);

    return { inputRoot, textArea };
  }
}
Blockly.icons.registry.register(
  new Blockly.icons.IconType("tooltip_icon"),
  BlockTooltipIcon
);

export default BlockTooltipIcon;
