import { Tab, Tabs } from "@mui/material";
import { Enums } from "components/builder/BuilderEnum";
import { stopEvent } from "components/builder/ui/editor/handler/UIEditorEventHandler";
import {
  getEntityPropertyValue,
  isWhoColumnField,
} from "components/builder/workflow/editor/render/WorkflowRenderUtils";
import Message from "components/common/Message";
import Popup from "components/common/Popup";
import JSONBuilder from "components/common/element/JSONBuilder";
import Modal from "components/common/modal/UModal";
import UModalTemplate from "components/common/modal/UModalTemplate";
import ArrayUtils from "components/common/utils/ArrayUtils";
import ObjectUtils from "components/common/utils/ObjectUtils";
import StringUtils from "components/common/utils/StringUtils";
import produce from "immer";
import React, { createContext, useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { AiOutlineCheck, AiOutlineSearch } from "react-icons/ai";

const TabKey = {
  BUILDER: "builder",
  EDITOR: "editor",
};

const flowContext = createContext({
  nodes: [],
  setInputData: () => {},
  inputData: {},
});

function RunWorkflowPopup({ callbackFnc, nodes, debugParameter, ...props }) {
  const [inputData, setInputData] = useState(debugParameter);
  const [selectedTab, setSelectedTab] = useState(TabKey.BUILDER);
  const onClickConfirm = () => {
    //키가 공백인 부분은 에러 표시
    let flag = true;

    const check = (obj) => {
      const keyCheck = (key) => {
        if (StringUtils.isEmpty(key) || key === "_tempKey") {
          flag = false;
          Message.alert(
            "비어있는 키가 있습니다. 키를 확인해주세요.",
            Enums.MessageType.WARN
          );
        }
      };

      if (ArrayUtils.isArray(obj)) {
        for (const item of obj) {
          for (const key of item) {
            keyCheck(key);
          }
        }
      } else if (ObjectUtils.isObject(obj)) {
        for (const key in obj) {
          keyCheck(key);
        }
      }
    };
    if (!ObjectUtils.isEmpty(inputData)) {
      check(inputData);
    }
    if (flag && callbackFnc) {
      callbackFnc(inputData);
    }
  };

  const onOpenEntityList = (e) => {
    const _callbackFnc = (data) => {
      let _inputData = undefined;

      if (typeof data === "string") {
        _inputData = { ...inputData, [data]: "" };
      } else if (data.entityFieldList) {
        for (const _fieldId in data.entityFieldList) {
          if (isWhoColumnField(_fieldId)) {
            delete data.entityFieldList[_fieldId];
          }
        }
        _inputData = { ...inputData, ...data.entityFieldList };
      } else {
        _inputData = { ...inputData, ...data };
      }

      setInputData(_inputData);
    };

    Popup.open(<EntityListPopup nodes={nodes} callbackFnc={_callbackFnc} />, {
      style: { content: { width: "800px" } },
    });
  };

  const BuilderConfig = {
    key: BuilderKey,
  };

  return (
    <Modal>
      <Modal.Header title="Workflow 실행 - Input Data 입력" />
      <flowContext.Provider
        value={{
          nodes: nodes,
          setInputData,
          inputData,
        }}
      >
        <Modal.Body>
          <UModalTemplate>
            <Tabs
              value={selectedTab}
              onChange={(e, value) => {
                setSelectedTab(value);
              }}
            >
              <Tab
                label={"JSON Builder"}
                value={TabKey.BUILDER}
                style={{ fontWeight: "bold", fontSize: "15px" }}
              />

              <Tab
                label={"JSON Editor"}
                value={TabKey.EDITOR}
                style={{ fontWeight: "bold", fontSize: "15px" }}
              />
            </Tabs>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button onClick={onOpenEntityList}>Entity 선택</Button>
            </div>

            <JSONBuilder
              viewMode={selectedTab}
              value={inputData}
              onChange={setInputData}
              isViewModeChangeButton={false}
              builderConfig={BuilderConfig}
            />
          </UModalTemplate>
        </Modal.Body>
      </flowContext.Provider>
      <Modal.Footer>
        <Modal.Footer.Button onClick={onClickConfirm}>실행</Modal.Footer.Button>
      </Modal.Footer>
    </Modal>
  );
}

export default RunWorkflowPopup;

const EntityListPopup = ({ nodes, callbackFnc, ...props }) => {
  const [dEntityList, setDEntityList] = useState([]);
  const [selectedEntity, setSelectedEntity] = useState({});
  const [selectedField, setSelectedField] = useState({});

  useEffect(() => {
    if (ArrayUtils.isArray(nodes)) {
      const _dList = nodes.filter(
        (n) =>
          n.data.process?.processType ===
          Enums.WorkflowProcessType.ENTITY_DEFINITION
      );
      setDEntityList(_dList.map((d) => d.data.process));
    } else {
      Message.alert("노드가 확인되지 않습니다.", Enums.MessageType.WARN);
    }
  }, []);

  const onDoubleClickEntity = (e, entity) => {
    stopEvent(e);
    const result = {};
    result.entityVariable = entity.entityVariable;
    result.entityFieldList = {};
    if (callbackFnc) {
      entity.entityFieldList.map((f) => {
        result.entityFieldList[f.fieldId] = "";
      });

      callbackFnc(result);
    }
    Popup.close();
  };

  const onDoubleClickField = (e, field) => {
    if (callbackFnc) {
      callbackFnc(field.fieldId);
    }
    Popup.close();
  };

  return (
    <Modal>
      <Modal.Header title={"Entity Definition 목록"} />
      <Modal.Body>
        <UModalTemplate>
          <div
            className="data-model-entity-select-wrapper"
            style={{ marginTop: "25px" }}
          >
            <div className="data-model-entity-list-wrapper ">
              <div className="list data-binding" style={{ width: "350px" }}>
                <div className="header">
                  <div>프로세스 명</div>
                  <div>Entity 변수 명</div>
                  <div>선택</div>
                </div>
                <div className="body">
                  {dEntityList.map((e) => {
                    const _entityList = getEntityPropertyValue(e);
                    return _entityList.map((entity, idx) => {
                      const name = entity.processNm;
                      return (
                        <div
                          className={`dm-row ${
                            StringUtils.equalsIgnoreType(
                              selectedEntity.entityVariable,
                              entity.entityVariable
                            ) &&
                            StringUtils.equalsIgnoreType(
                              selectedEntity.processNm,
                              name
                            )
                              ? "selected"
                              : ""
                          }`}
                          key={entity.entityVariable}
                          onClick={(e) => setSelectedEntity(entity)}
                          onDoubleClick={(e) => onDoubleClickEntity(e, entity)}
                        >
                          <div className="cell">{name}</div>
                          <div className="cell">{entity.entityVariable}</div>
                          <div
                            className="cell"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Button
                              variant="outline-success"
                              size="sm"
                              onClick={(e) => onDoubleClickEntity(e, entity)}
                            >
                              <AiOutlineCheck />
                            </Button>
                          </div>
                        </div>
                      );
                    });
                  })}
                </div>
              </div>
              <div className="list" style={{ width: "350px" }}>
                <div className="header">
                  <div>Field ID</div>
                  <div>칼럼 명</div>
                </div>
                <div className="body">
                  {selectedEntity.entityFieldList ? (
                    selectedEntity.entityFieldList.map((f) => {
                      if (f.columnNm || f.fieldId)
                        return (
                          <div
                            className={`dm-row ${
                              StringUtils.equalsIgnoreType(
                                selectedField.fieldId,
                                f.fieldId
                              )
                                ? "selected"
                                : ""
                            }`}
                            id={f.fieldId}
                            // onClick={(e) => onChangeField(f)}
                            onDoubleClick={(e) => onDoubleClickField(e, f)}
                            key={f.fieldId}
                          >
                            <div className="cell">{f.fieldId}</div>
                            <div className="cell">{f.columnNm}</div>
                          </div>
                        );
                    })
                  ) : (
                    <div className="empty">
                      <div>선택된 엔티티가 목록형 엔티티 이거나</div>
                      <div>필드를 가져올 수 있는 형태가 아닙니다.</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </UModalTemplate>
      </Modal.Body>
      <Modal.Footer />
    </Modal>
  );
};

const BuilderKey = ({ prevKey, changeObject, jsonDataSet, ...props }) => {
  const { nodes, setInputData, inputData } = useContext(flowContext);
  const onClickEntityList = () => {
    const _callbackFnc = (data) => {
      if (typeof data === "string") {
        if (changeObject) {
          changeObject({ target: { id: "key", value: data } }, jsonDataSet);
        }
      } else {
        if (StringUtils.isEmpty(props.value)) {
          return Message.alert("키값이 필요합니다.", Enums.MessageType.WARN);
        }
        let _efList = [];

        for (const _fieldId in data.entityFieldList) {
          if (!isWhoColumnField(_fieldId)) {
            _efList.push({
              compId: StringUtils.getUuid(),
              key: _fieldId,
              value: "",
              type: "string",
            });
          }
        }
        changeObject(
          { target: { id: "value", value: _efList } },
          jsonDataSet,
          "object"
        );
      }
    };
    Popup.open(<EntityListPopup nodes={nodes} callbackFnc={_callbackFnc} />, {
      style: { content: { width: "800px" } },
    });
  };

  return (
    <InputGroup>
      <Form.Control {...props} />
      <Button size="sm" onClick={onClickEntityList} variant="outline-dark">
        <AiOutlineSearch />
      </Button>
    </InputGroup>
  );
};
