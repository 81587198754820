import { Enums } from "components/builder/BuilderEnum";
import Message from "components/common/Message";
import ObjectUtils from "components/common/utils/ObjectUtils";
import StringUtils from "components/common/utils/StringUtils";
import React, { createContext } from "react";
import { useState } from "react";
import styled from "styled-components";
import Contents from "components/setting/composition/Contents";
import General from "components/setting/section/general/General";
import Short from "components/setting/section/shortKey/Short";
import StyleCustom from "components/setting/section/style/StyleCustom";
import StyleList from "components/setting/section/style/StyleList";
import Tree from "components/setting/composition/Tree";
import ConnectionSetting from "components/setting/section/connection/ConnectionSetting";
import ConnectionList from "components/setting/section/connection/ConnectionList";
import TenantManage from "components/setting/section/tenantManage/TenantManage";
import AppRelease from "components/setting/section/appRelease/AppRelease";
import AppModuleManange from "./section/appRelease/AppModuleManange";
import BuilderStyle from "./section/style/BuilderStyle";
import WorkspaceManage from "./section/tenantManage/WorkspaceManage";

/**
 * 설정 좌측 메뉴 목록
 * auth가 있는 경우에는 해당 권한의 사용자만 노출된다.
 */
const TreeMenus = [
  {
    key: "general",
    title: "General",
    component: General,
    child: [
      {
        key: "shortKey",
        title: "단축키 설정",
        component: Short,
      },
    ],
  },
  {
    key: "style",
    title: "Style",
    component: StyleList,
    child: [
      {
        key: "styleList",
        title: "Style 목록",
        component: StyleList,
      },

      {
        key: "styleCustom",
        title: "Custom Style 등록",
        component: StyleCustom,
      },
      {
        key: "BuilderStyleCustom",
        title: "Builder Style 등록",
        component: BuilderStyle,
        auth: ["S"],
      },
    ],
  },
  {
    key: "connection",
    title: "Connection",
    component: ConnectionSetting,
    child: [
      {
        key: "connectionList",
        title: "Connection 목록",
        component: ConnectionList,
      },
    ],
  },
  {
    key: "tenant_manage",
    title: "Tenant 관리",
    component: WorkspaceManage,
    child: [
      {
        key: "workspace_manage",
        title: "Tenant 추가 & 삭제",
        component: WorkspaceManage,
      },
      {
        key: "tenant_module_manage",
        title: "Tenant 별 Module 관리",
        component: TenantManage,
      },
    ],
  },

  {
    key: "app_release",
    title: "Application 관리",
    auth: ["S", "AM"],
    child: [
      {
        key: "app_release",
        title: "Application 버전 관리",
        auth: ["S", "AM"],
        component: AppRelease,
      },
      {
        key: "module_mst",
        title: "Application 모듈 관리",
        auth: ["S", "AM"],
        component: AppModuleManange,
      },
    ],
    component: AppRelease,
  },
];

export const SettingContext = createContext({
  TreeMenus: [],
  selectedMenu: {},
  setSelectedMenu: () => {},
  setMenu: () => {},
});

function SettingMenu(props) {
  const [selectedMenu, setSelectedMenu] = useState(TreeMenus[0]);

  /**
   * 메뉴 세팅 함수
   * @param {String} menuKey
   * @returns setSelectedMenu
   */
  const setMenu = (menuKey) => {
    let menu = {};
    const findTree = (list) => {
      list.map((tree) => {
        if (StringUtils.equalsIgnoreType(tree.key, menuKey)) {
          menu = tree;
        } else if (tree.child) {
          findTree(tree.child);
        }
      });
    };
    try {
      if (StringUtils.isEmpty(menuKey))
        throw new Error("메뉴의 키값을 입력하세요");
      findTree(TreeMenus);
      if (ObjectUtils.isEmpty(menu))
        throw new Error("해당 키의 메뉴가 없습니다.");
      setSelectedMenu(menu);
    } catch (error) {
      return Message.alert(error.message, Enums.MessageType.ERROR);
    }
  };

  const contextValue = {
    TreeMenus,
    selectedMenu,
    setSelectedMenu,
    setMenu,
  };

  return (
    <SettingContext.Provider value={contextValue}>
      <div className="setting-box">
        <Tree />
        <Contents />
      </div>
    </SettingContext.Provider>
  );
}

export default SettingMenu;
