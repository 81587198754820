import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { Enums } from "components/builder/BuilderEnum";
import Message from "components/common/Message";
import Modal from "components/common/modal/UModal";
import UModalTemplate from "components/common/modal/UModalTemplate";
import Popup from "components/common/Popup";
import JsonUtils from "components/common/utils/JsonUtils";
import ObjectUtils from "components/common/utils/ObjectUtils";
import StringUtils from "components/common/utils/StringUtils";
import React, { useState } from "react";
import { useRef } from "react";
import { Col, Form, Row } from "react-bootstrap";
import AppService from "services/common/AppService";
import ComponentService from "services/datamodel/DataModelService";
import ProgramService from "services/ui/ProgramService";
import WorkflowService from "services/workflow/WorkflowService";

function WorkflowExportPopup({ workflow, appList, ...props }) {
  const [isLoading, setIsLoading] = useState(false);
  const [appId, setAppId] = useState("");
  const [moduleCd, setModuleCd] = useState("");
  const [appReleaseId, setAppReleaseId] = useState("");
  const [moduleList, setModuleList] = useState([]);
  const [appReleaseList, setAppReleaseList] = useState([]);

  const serviceIdRef = useRef();

  const onChangeAppId = (e) => {
    const value = e.target.value;
    setAppId(value);
    setAppReleaseList([]);
    AppService.getAppModuleList({ appId: value }, (res) => {
      setModuleList(res.data);
      if (res.data.length > 0) {
        onChangeModuleCd(
          { target: { value: res.data[0].moduleCd } },
          { appId: value }
        );
      }
    });
  };

  const onChangeModuleCd = (e, _body) => {
    const value = e.target.value;
    setModuleCd(value);
    AppService.getReleaseList(
      { appId: _body ? _body.appId : appId, moduleCd: value },
      (res) => {
        setAppReleaseList(res.data);
        if (res.data.length > 0) {
          setAppReleaseId(res.data[0].appReleaseId);
        }
      }
    );
  };

  const onExport = () => {
    //내보내기 to 정보
    const succeedTarget = {
      appId: appId,
      moduleCd: moduleCd,
      appReleaseId: appReleaseId,
    };
    setIsLoading(true);
    succeedWorkflow(succeedTarget).then(
      () => {
        setIsLoading(false);
        Popup.close();
      },
      () => {
        setIsLoading(false);
      }
    );
  };

  /**
   * @param {Object} to
   * @returns {Promise} 복사한 데이터 모델 목록
   */
  const succeedWorkflow = (to) => {
    return new Promise((resolve, reject) => {
      WorkflowService.succeedWorkflow(
        {
          serviceUidList: [workflow.serviceUid],
          to,
          serviceId: serviceIdRef.current.value,
        },
        (res) => {
          if (!res.isError) {
            const { reuse } = res.data;
            if (reuse)
              Message.alert(
                "해당 위치에 동일한 Service ID로 저장된 워크플로우가 이미 있기 때문에 배포할 수 없습니다.",
                Enums.MessageType.WARN
              );
            else
              Message.alert(
                "워크플로우를 배포 하였습니다.",
                Enums.MessageType.SUCCESS
              );
            resolve(true);
          } else {
            reject(res.message);
          }
        },
        (err) => reject(err)
      );
    });
  };

  return (
    <Modal>
      <Modal.Header title={`${workflow.serviceName} 내보내기`} />
      <Modal.Body>
        <UModalTemplate>
          <Form.Group className="mb-3">
            <Form.Label className="required">대상 Application 선택</Form.Label>
            <Form.Select value={appId} onChange={onChangeAppId}>
              <option value="">선택</option>
              {appList.map((app) => {
                return <option value={app.appId}>{app.appNm}</option>;
              })}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="required">대상 모듈 선택</Form.Label>
            <Form.Select value={moduleCd} onChange={onChangeModuleCd}>
              {moduleList.length === 0 ? (
                <option value={""}>Application을 선택해주세요.</option>
              ) : (
                moduleList.map((module) => {
                  return (
                    <option value={module.moduleCd}>{module.moduleNm}</option>
                  );
                })
              )}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="required">대상 버전 선택</Form.Label>
            <Form.Select onChange={(e) => setAppReleaseId(e.target.value)}>
              {appReleaseList.length === 0 ? (
                <option value={""}>모듈을 선택해주세요.</option>
              ) : (
                appReleaseList.map((release) => {
                  return (
                    <option value={release.appReleaseId}>
                      {release.version}
                    </option>
                  );
                })
              )}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="required">저장될 워크플로우 ID</Form.Label>
            <Form.Control
              defaultValue={workflow.serviceId}
              ref={serviceIdRef}
            />
          </Form.Group>
        </UModalTemplate>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.ProgressButton doing={isLoading} onClick={onExport}>
          내보내기
        </Modal.Footer.ProgressButton>
      </Modal.Footer>
    </Modal>
  );
}

export default WorkflowExportPopup;
