/*!
 * Builder Builder output Reducer for react v.17
 *
 *  현재 작업중인 Builder output에 대한 Reducer
 *
 *   Author: Bizentro
 *   Date: 2021-04
 */
import ActionType from "components/builder/BuilderActionType";
import StringUtils from "components/common/utils/StringUtils";
import produce from "immer";

const initialState = {
  output: {},
};

/**
 * UI Builder output을 reducer state로 관리
 * action type subfix는 UI (API Builder : API, Report Builder : RPT)
 *
 * @param {*} state
 * @param {*} action
 * @returns
 */
export default function UIBuilderOutputReducer(state = initialState, action) {
  switch (action.type) {
    case ActionType.DATA_MODEL.UPDATE: {
      return produce(state, (draft) => {
        // draft.output = action.output;
        draft.output = action.dataModel;
      });
    }
    case ActionType.DATA_MODEL.INIT: {
      return initialState;
    }

    default:
      return state;
  }
}
