import { Tooltip } from "@mui/material";
import { Enums } from "components/builder/BuilderEnum";
import { stopEvent } from "components/builder/ui/editor/handler/UIEditorEventHandler";
import WorkflowReduxHelper from "components/builder/workflow/editor/helper/WorkflowReduxHelper";
import {
  addBreakpoint,
  deleteBreakpoint,
} from "components/builder/workflow/reducer/WorkflowDebugAction";
import { AppContext } from "components/common/AppContextProvider";
import Message from "components/common/Message";
import Popup from "components/common/Popup";
import {
  ArrayUtils,
  JsonUtils,
  ObjectUtils,
  StringUtils,
} from "components/common/utils/CommonUtils";
import User from "components/common/utils/UserUtils";
import produce from "immer";
import CodePopup from "page/popup/workflow/CodePopup";
import ConditionPopup from "page/popup/workflow/ConditionPopup";
import ConnectorConditionPopup from "page/popup/workflow/ConnectorConditionPopup";
import ConnectorPopup from "page/popup/workflow/ConnectorPopup";
import ConnectorValidationPopup from "page/popup/workflow/ConnectorValidationPopup";
import IteratorPopup from "page/popup/workflow/IteratorPopup";
import LoopControlKeyword from "page/popup/workflow/process/LoopControlKeyword";
import SaveQuestPopup from "page/popup/workflow/SaveQuestPopup";
import ServiceModal from "page/popup/workflow/ServiceModal";
import {
  lazy,
  memo,
  Suspense,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Button, Form } from "react-bootstrap";
import { AiOutlineClose, AiOutlineEdit } from "react-icons/ai";
import {
  BsCodeSlash,
  BsFillPinFill,
  BsPinAngle,
  BsPuzzle,
} from "react-icons/bs";
import { ImLoop2 } from "react-icons/im";
import {
  MdCheck,
  MdError,
  MdMiscellaneousServices,
  MdOutlineCancel,
} from "react-icons/md";
import { TbArrowFork } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { Handle, NodeResizer, Position, useEdges, useNodes } from "reactflow";
import LocalStorageService from "services/common/LocalService";
import WorkflowService from "services/workflow/WorkflowService";
import useWorkflowDropEvent, {
  FLOW_DROP_TYPE,
} from "../editor/render/useWorkflowDropEvent";
import { Connector_popup_width, ModalWidth } from "../WorkflowBuilder";
import { FaRegObjectGroup, FaRegWindowMinimize } from "react-icons/fa";
import { FiMaximize, FiMinimize } from "react-icons/fi";
import { updateBundle } from "../reducer/WorkflowAction";

/**
 * WorkflowBuilder에서 사용하는 각 유형별 노드의 형태를 지정하는 파일입니다.
 * 프로세스 노드 : ProcessNodeType
 * 서비스 노드 : ServiceNodeType
 * 커넥터 : ConnectorNodeType
 * 코드 : CodeNodeType
 * 이터레이터 : IteratorNodeType
 *
 * 각 노드타입의 공통 부분이 수정될 때 전부 적용해줘야 하기 때문에
 * CommonNodeType을 만들어서 적용하는게 좋음... 시간만 있으면 하는데 쉽지 않음
 */

/**
 * 메모 타입
 */
export const MemoNodeType = memo(({ data, id, selected }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const descriptionRef = useRef();
  const titleRef = useRef();
  const dispatch = useDispatch();
  const workflow = useSelector((state) => state.workflow);
  const [titleEditMode, setTitleEditMode] = useState(false);

  /**
   * 내용 수정
   * @param {*} e
   */
  const onBlurDescription = (e) => {
    const description = descriptionRef.current.value;
    setIsEditMode(false);
    const newMemo = [{ ...data.memo, description }];
    WorkflowReduxHelper.updateMemo(dispatch, newMemo, workflow);
  };

  useEffect(() => {
    if (isEditMode && descriptionRef.current) {
      descriptionRef.current.focus();
    }
  }, [isEditMode]);

  /**
   * 메모 삭제
   */
  const onDeleteMemo = () => {
    WorkflowReduxHelper.deleteMemo(dispatch, id, workflow);
  };

  /**
   * 메모 타이틀 수정
   * @param {*} e
   */
  const onBlurTitle = (e) => {
    const title = titleRef.current.value;
    setTitleEditMode(false);
    const newMemo = [{ ...data.memo, title }];
    WorkflowReduxHelper.updateMemo(dispatch, newMemo, workflow);
  };

  return (
    <>
      <NodeResizer
        color="dodgeblue"
        isVisible={selected}
        minWidth={200}
        minHeight={150}
        handleStyle={{
          width: "12px",
          height: "12px",
          borderRadius: "100%",
          zIndex: 1,
        }}
      />
      <div className="workflow-memo nowheel ">
        <div className="header">
          {titleEditMode ? (
            <>
              <div className="title">
                <Form.Control
                  defaultValue={data.memo.title}
                  ref={titleRef}
                  onBlur={onBlurTitle}
                />
              </div>
              <div className="button-area">
                <button onClick={onBlurTitle}>
                  <MdCheck color="lime" />
                </button>
                <button onClick={(e) => setTitleEditMode(false)}>
                  <MdOutlineCancel color="tomato" />
                </button>
              </div>
            </>
          ) : (
            <>
              <div className="title">{data.memo.title}</div>
              <div className="button-area">
                <button onClick={(e) => setTitleEditMode(true)}>
                  <AiOutlineEdit color="lime" />
                </button>
                <button onClick={onDeleteMemo}>
                  <AiOutlineClose />
                </button>
              </div>
            </>
          )}
        </div>
        <div className="description-wrapper">
          {isEditMode ? (
            <>
              <textarea
                className="description nodrag"
                defaultValue={data.memo.description}
                ref={descriptionRef}
                onBlur={onBlurDescription}
                onClick={stopEvent}
                onScroll={stopEvent}
                onScrollCapture={stopEvent}
              />
              <div className="button-area">
                <Button
                  size="sm"
                  variant="outline-success"
                  onClick={onBlurDescription}
                >
                  확인
                </Button>
                <Button
                  size="sm"
                  variant="outline-danger"
                  onClick={(e) => {
                    stopEvent(e);
                    setIsEditMode(false);
                  }}
                >
                  취소
                </Button>
              </div>
            </>
          ) : (
            <div
              className="description"
              onClick={stopEvent}
              onDoubleClick={(e) => setIsEditMode(true)}
            >
              {data.memo.description}
            </div>
          )}
        </div>
      </div>
    </>
  );
});

/**
 * 프로세스 노드 타입
 * @param {data:Object,id:String,selected:Boolean}
 */
export const ProcessNodeType = memo(
  ({ data: { process, comment, isBundling }, id, selected, ...props }) => {
    const dispatch = useDispatch();
    const workflow = useSelector((state) => state.workflow);
    const {
      breakpoint: breakpoints,
      trace,
      isDebugging,
      process: debugProcess,
      breakpointType,
      inCommunication,
    } = useSelector((state) => state.workflowDebug);
    const workspace = useSelector((state) => state.workspace);
    const [isComment, setIsComment] = useState(false);
    const {
      code: { getCodeList },
    } = useContext(AppContext);
    const [isChild, parentNodeId, parentNode] = useIsIteratorChild(id);
    const nodes = useNodes();
    const edges = useEdges();
    const [isInvalid, setIsInvalid] = useState(false);
    const [isTracing, setIsTracing] = useState(false); // 디버깅 중 트레이싱 되는지
    const [isBreakPoint, setIsBreakPoint] = useState(false); //브레이크 포인트에 걸린 노드인지

    // const isTarget = connectionNodeId && connectionNodeId !== id;
    // const targetHandleStyle = { zIndex: isTarget ? 3 : 1 };
    useEffect(() => {
      /**
       * 참조중인 노드가 삭제되거나 compID를 찾을 수 없는 경우 isInvalid를 활성화 한다.
       */
      const referenceCompIdList = findReferCompId(process);
      if (referenceCompIdList.length > 0) {
        for (const compId of referenceCompIdList) {
          const refNode = JsonUtils.findNode(
            workflow.output.service,
            "compId",
            compId
          );
          if (ObjectUtils.isEmpty(refNode)) {
            setIsInvalid(true);
            break;
          } else {
            setIsInvalid(false);
          }
        }
      } else {
        setIsInvalid(false);
      }
    }, [workflow.output.service]);

    useEffect(() => {
      if (!isDebugging) {
        setIsTracing(false);
        setIsBreakPoint(false);
      } else {
        if (isDebugging && ArrayUtils.isArray(trace)) {
          const _isTracing = trace.find((t) => t.compId === id);
          if (_isTracing) setIsTracing(true);
          else setIsTracing(false);
        }
        if (isDebugging && !ObjectUtils.isEmpty(debugProcess)) {
          if (debugProcess.compId === id) {
            setIsBreakPoint(true);
          } else {
            setIsBreakPoint(false);
          }
        }
      }
    }, [isDebugging, trace, debugProcess]);

    /**
     * 참조중인 노드 아이디 목록 찾기
     * @param {*} object
     * @returns
     */
    const findReferCompId = (object) => {
      const idList = [];

      const _find = (_obj) => {
        for (const key in _obj) {
          if (typeof _obj[key] === "object") {
            _find(_obj[key]);
          } else if (key === "referenceCompId") {
            if (_obj[key]) {
              idList.push(_obj[key]);
            }
          }
        }
      };
      _find(object);
      return idList;
    };

    useEffect(() => {
      let parentComment = false;
      if (parentNodeId) {
        parentComment = ObjectUtils.isEmpty(
          JsonUtils.findNode(workflow.serviceComment, "compId", parentNodeId)
        )
          ? false
          : true;
      }
      if (comment || parentComment) {
        setIsComment(true);
      } else {
        setIsComment(false);
      }
    }, [comment]);

    /**
     * 프로세스 수정 로직
     * 브레이크 포인트 설정, 노드 수정 함수에서 사용
     * @param {*} _body
     */
    const updateProcess = (_body) => {
      if (isChild) {
        WorkflowReduxHelper.updateIteratorNode(
          dispatch,
          [_body],
          parentNode,
          workflow
        );
      } else {
        WorkflowReduxHelper.updateNodes(dispatch, [_body], workflow);
      }
    };

    /**
     * 노드 수정
     * @param {*} e
     */
    const onOpenProcessPopup = (e) => {
      const callbackFnc = (callbackData) => {
        const body = {
          ...process,
          propertyValue: callbackData,
        };
        updateProcess(body);

        Popup.close();
      };

      const ProcessDetailPopup = lazy(() =>
        import("page/popup/workflow/process/" + process.processType)
      );
      Popup.open(
        <Suspense fallback={<div></div>}>
          <ProcessDetailPopup
            parentNodeId={parentNodeId}
            workspace={workspace}
            // connectionPopupOpen={connectionPopupOpen}
            callbackFnc={callbackFnc}
            operatorList={getCodeList("Z0026")}
            workflow={workflow.output}
            processInfo={process.propertyValue}
            processType={process.processType}
            nodes={nodes}
            edges={edges}
            compId={id}
          />
        </Suspense>,
        {
          style: {
            content: {
              width: ModalWidth[process.processType] || ModalWidth.other,
            },
          },
        }
      );
    };

    /**
     * 프로세스 노드 삭제
     * @param {*} e
     */
    const onDeleteProcess = (e) => {
      stopEvent(e);
      WorkflowReduxHelper.deleteProcess(dispatch, process.compId, workflow);
    };

    /**
     * 브레이크 포인트 설정
     * @param {*} e
     */
    const setBreakpoint = (e) => {
      stopEvent(e);
      dispatch(
        addBreakpoint({
          compId: process.compId,
          type: Enums.WorkflowNodeType.PROCESS,
          processName: process.propertyValue.processNm,
          processType: process.processType,
          serviceUid: workflow.serviceInfo.serviceUid,
          serviceId: workflow.serviceInfo.serviceId,
          serviceName: workflow.serviceInfo.serviceName,
        })
      );
    };

    /**
     * 브레이크 포인트 삭제
     * @param {*} e
     */
    const removeBreakpoint = (e) => {
      stopEvent(e);
      dispatch(
        deleteBreakpoint({
          compId: process.compId,
        })
      );
    };

    /**
     * Node Header 부분
     * @returns
     */
    const renderHeader = () => {
      return (
        <div className="header">
          <div className="title">
            <span>
              {StringUtils.equalsIgnoreCase(
                process.processType,
                Enums.WorkflowProcessType.LOOP_CONTROL_KEYWORD
              ) ? (
                <TbArrowFork size={22} />
              ) : (
                <BsPuzzle size={22} />
              )}
            </span>
            <span
              className="name"
              style={{ maxWidth: isInvalid ? "160px" : "200px" }}
            >
              {process.propertyValue.processNm}
            </span>
          </div>

          <div className="control-button">
            <Tooltip title="수정" placement="top">
              <button
                style={{ color: "limegreen" }}
                onClick={onOpenProcessPopup}
              >
                <AiOutlineEdit size={20} />
              </button>
            </Tooltip>
            {isInvalid && (
              <Tooltip
                placement="top"
                title={
                  <span style={{ fontSize: "12px" }}>
                    참조대상이 확인되지 않습니다. <br />
                    <span style={{ fontWeight: "bold" }}>
                      Input Entity 또는 Output Entity를 다시 지정
                      <br />
                    </span>
                    해주세요.
                  </span>
                }
              >
                <button className="blink">
                  <MdError size={25} color="tomato" />
                </button>
              </Tooltip>
            )}
            <Tooltip title="Break Point" placement="top">
              {breakpoints.find((bp) => bp.compId === process.compId) ? (
                <button onClick={removeBreakpoint}>
                  <BsFillPinFill size={20} />
                </button>
              ) : (
                <button onClick={setBreakpoint}>
                  <BsPinAngle size={20} />
                </button>
              )}
            </Tooltip>
            <button onClick={onDeleteProcess}>
              <AiOutlineClose size={20} />
            </button>
          </div>
        </div>
      );
    };

    /**
     * Render Body 부분
     */
    const renderBody = () => {
      if (
        StringUtils.equalsIgnoreCase(
          process.processType,
          Enums.WorkflowProcessType.LOOP_CONTROL_KEYWORD
        )
      ) {
        return (
          <div
            className={`workflow-process-node ${Enums.WorkflowProcessType.LOOP_CONTROL_KEYWORD}`}
            onDoubleClick={onOpenProcessPopup}
          >
            <div className="process-type ">
              Loop Control - {process.propertyValue.loopControlKeyword}
            </div>
          </div>
        );
      } else {
        return (
          <div
            className="workflow-process-node"
            onDoubleClick={onOpenProcessPopup}
          >
            <div className="process-type">Process - {process.processType}</div>
            {StringUtils.equalsIgnoreCase(
              process.processType,
              Enums.WorkflowProcessType.ENTITY_DEFINITION
            ) ? (
              <>
                <div>Entity 명 - {process.propertyValue.entityNm}</div>
              </>
            ) : (
              <></>
            )}
          </div>
        );
      }
    };

    return (
      <>
        {inCommunication && isBreakPoint ? (
          <div className={`debug-info ${breakpointType}`}>
            <div>실행 전</div>
            <div>실행 후</div>
          </div>
        ) : (
          <></>
        )}

        <div
          className={`workflow-node ${isChild ? `${parentNodeId}_child` : ""} ${
            isComment && ` comment`
          } ${isBundling ? " bundling" : ""}`}
        >
          <div
            className={`workflow-process-node-wrapper ${
              selected ? " selected " : ""
            }
           ${process.processType} 
           ${isTracing ? " traced " : ""} 
           ${debugProcess?.compId === id ? " border-blink " : ""} 
           
          `}
          >
            {isComment && (
              <div className="node-comment" onDoubleClick={onOpenProcessPopup}>
                주석
              </div>
            )}

            {renderHeader()}
            {renderBody()}
          </div>
          <span className="handle-wrapper">
            <Handle
              type="source"
              className="targetHandle"
              position={Position.Top}
              id="a"
            />
            <Handle
              type="source"
              className="targetHandle"
              position={Position.Left}
              id="b"
            />
            <Handle
              type="source"
              className="targetHandle"
              position={Position.Right}
              id="c"
            />

            <Handle
              type="source"
              className="targetHandle"
              position={Position.Bottom}
              id="d"
            />
          </span>
        </div>
      </>
    );
  }
);

/**
 * 커넥터 타입
 * @param {data:Object,id:String,selected:Boolean}
 */
export const ConnectorNodeType = memo(
  ({ data: { connector, comment, isBundling }, selected, id }) => {
    const dispatch = useDispatch();
    const workflow = useSelector((state) => state.workflow);
    const [isChild, parentNodeId] = useIsIteratorChild(id);
    const nodes = useNodes();
    const edges = useEdges();
    /**
     * 커넥터 삭제
     */
    const onDeleteConnector = () => {
      WorkflowReduxHelper.deleteConnector(dispatch, id, workflow);
    };

    /**
     * 커넥터 수정
     * PropertyValue가 없는 경우는 반응하지 않음
     * @param {*} e
     */
    const onDoubleClick = (e) => {
      if (!connector.propertyValue) return false;
      const callbackFnc = (connectorInfo) => {
        Popup.close();
        const body = {
          ...connector,
          propertyValue: connectorInfo,
        };
        WorkflowReduxHelper.updateNodes(dispatch, [body], workflow);
      };

      const conditionCallbackFnc = (connectorInfo) => {
        Popup.close();
        const body = {
          ...connector,
          propertyValue: connectorInfo,
        };

        const otherConnector = workflow.output.service.child.connector.filter(
          (item) =>
            item.processFrom === connector.processFrom &&
            item.compId !== connector.compId
        )[0];

        const body2 = {
          ...otherConnector,
          propertyValue: {
            filter: !connectorInfo.filter,
            connectorNm: connectorInfo.filter ? "FALSE" : "TRUE",
          },
        };

        // WorkflowReduxHelper.updateNodes(dispatch, [body], workflow);
        WorkflowReduxHelper.updateNodes(dispatch, [body2, body], workflow);
      };

      //소스가 Validation 인지 확인
      const sourceNode = JsonUtils.findNode(
        workflow.output,
        "compId",
        connector.processFrom
      );
      const isFromValidation = StringUtils.equalsIgnoreCase(
        sourceNode.processType,
        "EntityValidation"
      );
      if (sourceNode.type === Enums.WorkflowNodeType.CONDITION) {
        Popup.open(
          <ConnectorConditionPopup
            callbackFnc={conditionCallbackFnc}
            workflow={workflow.output}
          />,
          {
            effect: Popup.ScaleUp,
            style: {
              content: {
                width: Connector_popup_width.EntityValidation,
              },
            },
          }
        );
      } else if (isFromValidation) {
        Popup.open(
          <ConnectorValidationPopup
            callbackFnc={callbackFnc}
            workflow={workflow.output}
          />,
          {
            effect: Popup.ScaleUp,
            style: {
              content: {
                width: Connector_popup_width.EntityValidation,
              },
            },
          }
        );
      } else {
        Popup.open(
          <ConnectorPopup
            callbackFnc={callbackFnc}
            connectionData={connector.propertyValue}
            sourceCompId={connector.processFrom}
            targetCompId={connector.processTo}
            workflow={workflow.output}
            nodes={nodes}
            edges={edges}
            compId={connector.processFrom}
            connector={true}
          />,
          {
            effect: Popup.ScaleUp,
            style: {
              content: {
                width: Connector_popup_width.other,
              },
            },
          }
        );
      }
    };

    return (
      <div
        className={`workflow-node ${isChild ? `${parentNodeId}_child` : ""} ${
          comment && ` comment`
        } ${isBundling ? " bundling" : ""}`}
      >
        {connector.propertyValue.elseYn && (
          <span className="workflow-connector-else">else</span>
        )}
        {connector.propertyValue.connectorNm ? (
          <>
            <div
              className={`workflow-connector-node-wrapper ${
                selected ? "selected" : ""
              } `}
              onDoubleClick={onDoubleClick}
            >
              {comment && (
                <div className="node-comment" onDoubleClick={onDoubleClick}>
                  주석
                </div>
              )}
              <div>{connector.propertyValue.connectorNm}</div>
              <button onClick={onDeleteConnector}>
                <AiOutlineClose size={20} />
              </button>
            </div>
          </>
        ) : (
          <div
            className={`workflow-connector-node-wrapper no-property ${
              selected ? "selected" : ""
            } `}
            onDoubleClick={onDoubleClick}
          >
            {comment && (
              <div className="node-comment" onDoubleClick={onDoubleClick}>
                주석
              </div>
            )}
            <button onClick={onDoubleClick}>
              <AiOutlineEdit size={20} color="green" />
            </button>
            <button onClick={onDeleteConnector}>
              <AiOutlineClose size={20} />
            </button>
          </div>
        )}

        <span className="handle-wrapper">
          <Handle
            type="source"
            className="targetHandle"
            position={Position.Top}
            id="a"
          />
          <Handle
            type="source"
            className="targetHandle"
            position={Position.Left}
            id="b"
          />
          <Handle
            type="source"
            className="targetHandle"
            position={Position.Right}
            id="c"
          />

          <Handle
            type="source"
            className="targetHandle"
            position={Position.Bottom}
            id="d"
          />
        </span>
      </div>
    );
  }
);

/**
 * 프로세스 엣지 타입 (시작, 종료)
 * @param {data:Object,id:String}
 */
export const processEdgeType = memo(({ data: { process }, id }) => {
  const dispatch = useDispatch();
  // const connectionNodeId = useStore(connectionNodeIdSelector);
  const workflow = useSelector((state) => state.workflow);
  const { isDebugging, trace, inCommunication } = useSelector(
    (state) => state.workflowDebug
  );
  const workspace = useSelector((state) => state.workspace);
  const nodes = useNodes();
  const edges = useEdges();
  const [isTracing, setIsTracing] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);

  useEffect(() => {
    if (isDebugging && ArrayUtils.isArray(trace)) {
      const _isTracing = trace.find((t) => t.compId === id);
      if (_isTracing) setIsTracing(true);
      else setIsTracing(false);
    } else {
      setIsTracing(false);
    }
  }, [isDebugging, trace]);

  useEffect(() => {
    validateReturnData();
  }, [nodes]);

  /**
   * 종료 프로세스의 경우
   * 엔티티들이 중간에 삭제되었는지 안되었느지 확인해야 한다.
   * 유효하지 않은 JSON return 데이터 확인 기능 추가
   */
  const validateReturnData = () => {
    if (process.processType === "EndProcess") {
      // returnObject validate 필요
      const { returnObject = [] } = process.propertyValue;
      const rObjMap = {};
      returnObject.forEach((rObj) => {
        rObjMap[rObj.entityVariable] = false;
      });

      const checkedProcessIdList = [];
      const find = (process) => {
        const connectors = nodes.filter(
          (n) =>
            n.type === Enums.WorkflowNodeType.CONNECTOR &&
            n.data.connector.processTo === process.id
        );
        checkedProcessIdList.push(process.id);
        if (ArrayUtils.isEmpty(connectors)) {
          return false;
        }
        connectors.forEach((con) => {
          const nextProcess = nodes.find(
            (node) => con.data.connector.processFrom === node.id
          );
          //outputEntity
          if (nextProcess?.data.process.propertyValue?.outputEntity) {
            rObjMap[
              nextProcess?.data.process.propertyValue.outputEntity.entityVariable
            ] = true;
          }
          //entityDefinition
          if (nextProcess?.data.process.propertyValue?.entityVariable) {
            rObjMap[
              nextProcess.data.process.propertyValue?.entityVariable
            ] = true;
          }
          //restApiConnector
          if (nextProcess?.data.process.propertyValue?.entityVariable) {
            rObjMap[
              nextProcess.data.process.propertyValue?.entityVariable
            ] = true;
          }
          if (nextProcess?.data.process.propertyValue?.bodyEntity) {
            rObjMap[nextProcess.data.process.propertyValue?.bodyEntity] = true;
          }
          if (nextProcess?.data.process.propertyValue?.outputHeaderEntity) {
            rObjMap[
              nextProcess.data.process.propertyValue?.outputHeaderEntity.entityVariable
            ] = true;
          }
          if (nextProcess?.data.process.propertyValue?.responseCode) {
            rObjMap[
              nextProcess.data.process.propertyValue?.responseCode.entityVariable
            ] = true;
          }

          if (nextProcess) {
            if (checkedProcessIdList.indexOf(nextProcess.id) === -1)
              return find(nextProcess);
          } else {
            return false;
          }
        });
      };

      find({ id: process.compId });
      //return Object가 전부 true가 아니면 invalid 표시한다.
      if (Object.keys(rObjMap).every((key) => rObjMap[key])) {
        setIsInvalid(false);
      } else {
        setIsInvalid(true);
      }
    }
  };

  const onOpenProcessPopup = (e) => {
    const callbackFnc = (callbackData) => {
      const body = {
        ...process,
        propertyValue: callbackData,
      };
      WorkflowReduxHelper.updateNodes(dispatch, [body], workflow);
      Popup.close();
    };

    const ProcessDetailPopup = lazy(() =>
      import("page/popup/workflow/process/" + process.processType)
    );
    if (ProcessDetailPopup) {
      Popup.open(
        <Suspense fallback={<div></div>}>
          <ProcessDetailPopup
            workspace={workspace}
            // connectionPopupOpen={connectionPopupOpen}
            callbackFnc={callbackFnc}
            workflow={workflow.output}
            processInfo={process.propertyValue}
            processType={process.processType}
            nodes={nodes}
            edges={edges}
            compId={id}
          />
        </Suspense>,
        {
          style: {
            content: {
              width: "50%",
            },
          },
        }
      );
    }
  };

  const renderPanel = (Component) => {
    if (isInvalid) {
      return (
        <Tooltip placement="top" title={"Return Object를 재정의 해주세요."}>
          {Component}
        </Tooltip>
      );
    } else {
      return Component;
    }
  };

  return (
    <div className={`workflow-node `}>
      {renderPanel(
        <div
          className={`workflow-process-edge-wrapper ${
            isTracing ? " traced " : ""
          } ${isInvalid ? "invalid" : ""}`}
          onDoubleClick={onOpenProcessPopup}
        >
          {process.propertyValue.processNm}
        </div>
      )}

      <span className="handle-wrapper">
        <Handle
          type="source"
          className="targetHandle"
          position={Position.Top}
          id="a"
        />
        <Handle
          type="source"
          className="targetHandle"
          position={Position.Left}
          id="b"
        />
        <Handle
          type="source"
          className="targetHandle"
          position={Position.Right}
          id="c"
        />

        <Handle
          type="source"
          className="targetHandle"
          position={Position.Bottom}
          id="d"
        />
      </span>
    </div>
  );
});

/**
 * 이터레이터 노드
 * @param {data:Object,id:String,selected:Boolean}
 */
export const IteratorNodeType = memo(
  ({
    data: { process, theme, comment, isBundling },
    id,
    selected,
    ...args
  }) => {
    const dispatch = useDispatch();
    const workflow = useSelector((state) => state.workflow);
    const [isDragOver, setIsDragOver] = useState(false);
    const [isComment, setIsComment] = useState(false);
    const iteratorRef = useRef();
    const {
      onDropIterator,
      onDropProcess,
      onDropService,
      onDropCondition,
      onDropCode,
    } = useWorkflowDropEvent(FLOW_DROP_TYPE.ITERATOR);
    const [isChild, parentNodeId] = useIsIteratorChild(id);
    const [isInvalid, setIsInvalid] = useState(false);
    const nodes = useNodes();
    const edges = useEdges();

    useEffect(() => {
      //이터레이터가 주석일때 자식도 주석인지 확인
      let parentComment = false;
      if (parentNodeId) {
        const _p = nodes.find((n) => n.id === parentNodeId);
        parentComment = _p.data.comment ? true : false;
      }
      if (comment || parentComment) {
        const children = document.getElementsByClassName(`${id}_child`);
        for (const child of children) {
          child.style.opacity = 0.3;
        }
        setIsComment(true);
      } else {
        const children = document.getElementsByClassName(`${id}_child`);
        for (const child of children) {
          child.style.opacity = 1;
        }
        setIsComment(false);
      }
    }, [comment]);

    useEffect(() => {
      /**
       * 참조중인 노드가 삭제되거나 compID를 찾을 수 없는 경우 isInvalid를 활성화 한다.
       */
      const referenceCompId = process.propertyValue.editorAttr?.referenceCompId;
      if (!StringUtils.isEmpty(referenceCompId)) {
        const refNode = JsonUtils.findNode(
          workflow.output.service,
          "compId",
          referenceCompId
        );
        if (ObjectUtils.isEmpty(refNode)) {
          setIsInvalid(true);
        }
      } else {
        setIsInvalid(false);
      }
    }, [nodes]);

    /**
     * 프로세스 삭제
     * @param {*} e
     */
    const onDeleteProcess = (e) => {
      stopEvent(e);
      //주석에 있는 프로세스도 확인
      const commentChild = workflow.serviceComment.process.filter(
        (s) => s.parentNode === process.compId
      );
      const childIds = commentChild.map((c) => c.compId);
      WorkflowReduxHelper.deleteProcess(
        dispatch,
        [process.compId, ...childIds],
        workflow
      );
    };

    /**
     * 프로세스 상세 보기 및 수정
     */
    const onDoubleClick = () => {
      const callbackFnc = (data) => {
        const nodeInfo = {
          ...process,
          propertyValue: {
            ...data,
          },
        };
        Popup.close();
        WorkflowReduxHelper.updateNodes(dispatch, [nodeInfo], workflow);
      };
      Popup.open(
        <IteratorPopup
          callbackFnc={callbackFnc}
          workflow={workflow.output}
          processType={process.processType}
          processInfo={process.propertyValue}
          nodes={nodes}
          edges={edges}
          parentNodeId={parentNodeId}
          compId={id}
        />,
        {
          style: { content: { width: "50%" } },
        }
      );
    };

    /**
     * Iterator 안에 프로세스 드랍
     * @param {*} event
     * @returns
     */
    const onDrop = (event) => {
      if (isComment) {
        event.preventDefault();
        event.stopPropagation();
        return Message.alert(
          "주석 내부에는 프로세스를 놓을 수 없습니다.",
          Enums.MessageType.ERROR
        );
      }

      const reactFlowBounds = iteratorRef.current.getBoundingClientRect();
      const position = {
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      };

      const _NodeData = event.dataTransfer.getData("application/reactflow");
      if (!_NodeData) return false; //데이터가 없는 경우
      const NodeData = JSON.parse(_NodeData);
      const { type } = NodeData;

      if (
        !StringUtils.equalsIgnoreCase(Enums.WorkflowNodeType.MEMO, type) &&
        event
      ) {
        //메모를 드랍할때는 WorkflowBuilder의 onDropMemo에 이벤트버블링이 되도록 함
        event.preventDefault();
        event.stopPropagation();
      }

      if (StringUtils.equalsIgnoreCase(Enums.WorkflowNodeType.PROCESS, type)) {
        onDropProcess(position, { iterator: process, nodes, edges });
      } else if (
        StringUtils.equalsIgnoreCase(Enums.WorkflowNodeType.SERVICE, type)
      ) {
        onDropService(position, { iterator: process, nodes, edges });
      } else if (
        StringUtils.equalsIgnoreCase(Enums.WorkflowNodeType.ITERATOR, type)
      ) {
        //이터레이터가 2중첩 이상인지 확인
        const grandParentNode = nodes.find((n) => n.id === parentNodeId);
        if (grandParentNode && nodes.find((n) => n.id === grandParentNode.id)) {
          Message.alert(
            "Iterator는 최대 2중첩까지만 가능합니다.",
            Enums.MessageType.WARN
          );
        } else {
          onDropIterator(position, { iterator: process, nodes, edges });
        }
      } else if (
        StringUtils.equalsIgnoreCase(Enums.WorkflowNodeType.CONDITION, type)
      ) {
        onDropCondition(position, { iterator: process, nodes, edges });
      } else if (
        StringUtils.equalsIgnoreCase(Enums.WorkflowNodeType.CODE, type)
      ) {
        onDropCode(position, { iterator: process, nodes, edges });
      }
      onDragLeave();
    };

    /**
     * 드래그가 Iterator 안에 들어간 경우
     * 내부에 속안 컴포넌트들의 스타일을 강제적으로 변환함
     * @param {*} e
     */
    const onDragEnter = (e) => {
      if (e) stopEvent(e);
      if (isComment) return false;
      setIsDragOver(true);
      const children = document.getElementsByClassName(`${id}_child`);
      for (const child of children) {
        child.style.opacity = 0.3;
      }
    };
    /**
     * 드래그가 빠져나간 경우
     * @param {*} e
     */
    const onDragLeave = (e) => {
      if (e) stopEvent(e);
      if (isComment) return false;
      setIsDragOver(false);
      const children = document.getElementsByClassName(`${id}_child`);
      for (const child of children) {
        child.style.opacity = 1;
      }
    };

    /**
     * 지시자 버튼 클릭
     * debugger, break 설정
     * @param {*} e
     */
    const onClickIndicator = (event) => {
      const callbackFnc = (processInfo) => {
        //지시자는 각 이터레이터에서 x : 80, Y :80 포지션에 추가한다.
        const position = {
          x: 80,
          y: 80,
        };
        const nodeInfo = {
          position,
          compId: StringUtils.getUuid(),
          type: "process",
          processType: Enums.WorkflowProcessType.LOOP_CONTROL_KEYWORD,
          propertyValue: {
            ...processInfo,
          },
        };

        nodeInfo.parentNode = process.compId;
        //이터레이터 추가
        WorkflowReduxHelper.addProcessInIterator(
          dispatch,
          nodeInfo,
          process,
          workflow
        );
        Popup.close();
      };

      Popup.open(<LoopControlKeyword callbackFnc={callbackFnc} />, {
        style: { content: { width: "800px" } },
      });
    };

    return (
      <>
        <NodeResizer
          color="dodgeblue"
          isVisible={selected}
          minWidth={200}
          minHeight={150}
          handleStyle={{
            width: "15px",
            height: "15px",
            borderRadius: "100%",
            zIndex: 1,
          }}
        />

        <div
          className={`iterator-wrapper ${isDragOver ? "over" : ""} ${theme} ${
            isChild ? `${parentNodeId}_child` : ""
          } ${selected ? "selected" : ""} ${comment && ` comment`} ${
            isBundling ? " bundling" : ""
          }`}
          onDoubleClick={onDoubleClick}
          onDrop={onDrop}
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
          onDragEnd={onDragLeave}
          ref={iteratorRef}
        >
          {isComment && (
            <div className="node-comment" onDoubleClick={onDoubleClick}>
              주석
            </div>
          )}
          {isDragOver ? (
            <>Iterator에 프로세스 추가</>
          ) : (
            <>
              <div className="header">
                <div className="title">
                  <span>
                    <ImLoop2 size={18} />
                  </span>
                  <span>
                    {" "}
                    {process.propertyValue.processNm}
                    <Tooltip title="수정">
                      <button
                        style={{ color: "limegreen" }}
                        onClick={onDoubleClick}
                      >
                        <AiOutlineEdit size={20} />
                      </button>
                    </Tooltip>
                  </span>
                </div>

                <div>
                  {isInvalid && (
                    <Tooltip
                      placement="top"
                      title={
                        <span style={{ fontSize: "12px" }}>
                          참조대상이 확인되지 않습니다. <br />
                          <span style={{ fontWeight: "bold" }}>
                            Input Entity 또는 Output Entity를 다시 지정
                            <br />
                          </span>
                          해주세요.
                        </span>
                      }
                    >
                      <button className="blink">
                        <MdError size={25} color="tomato" />
                      </button>
                    </Tooltip>
                  )}
                  <button className="indicator" onClick={onClickIndicator}>
                    + 반복문 제어
                  </button>
                  <button onClick={onDeleteProcess}>
                    <AiOutlineClose size={20} />
                  </button>
                </div>
              </div>
              <div className="option">
                반복 조건 : {process.propertyValue.option}
              </div>
            </>
          )}
          <span className="handle-wrapper">
            <Handle
              type="source"
              className="targetHandle"
              position={Position.Top}
              id="a"
            />
            <Handle
              type="source"
              className="targetHandle"
              position={Position.Left}
              id="b"
            />
            <Handle
              type="source"
              className="targetHandle"
              position={Position.Right}
              id="c"
            />

            <Handle
              type="source"
              className="targetHandle"
              position={Position.Bottom}
              id="d"
            />
          </span>
        </div>
      </>
    );
  }
);

/**
 *  Condition (IF 문) 노드
 */
export const ConditionNodeType = memo(
  ({ data: { process, comment, isBundling }, id, selected, ...props }) => {
    const dispatch = useDispatch();
    const workflow = useSelector((state) => state.workflow);
    const workspace = useSelector((state) => state.workspace);
    const {
      breakpoint: breakpoints,
      trace,
      isDebugging,
      process: debugProcess,
      breakpointType,
      inCommunication,
    } = useSelector((state) => state.workflowDebug);

    const [isComment, setIsComment] = useState(false);
    const [isChild, parentNodeId, parentNode] = useIsIteratorChild(id);
    const [isTracing, setIsTracing] = useState(false); // 디버깅 중 트레이싱 되는지
    const [isBreakPoint, setIsBreakPoint] = useState(false); //브레이크 포인트에 걸린 노드인지
    const nodes = useNodes();
    const edges = useEdges();

    // const isTarget = connectionNodeId && connectionNodeId !== id;
    // const targetHandleStyle = { zIndex: isTarget ? 3 : 1 };
    useEffect(() => {
      let parentComment = false;
      if (parentNodeId) {
        parentComment = JsonUtils.findNode(
          workflow.serviceComment,
          "compId",
          parentNodeId
        )
          ? true
          : false;
      }
      if (comment || parentComment) {
        setIsComment(true);
      } else {
        setIsComment(false);
      }
    }, [comment]);

    useEffect(() => {
      if (!isDebugging) {
        setIsTracing(false);
        setIsBreakPoint(false);
      } else {
        if (isDebugging && ArrayUtils.isArray(trace)) {
          const _isTracing = trace.find((t) => t.compId === id);
          if (_isTracing) setIsTracing(true);
          else setIsTracing(false);
        }
        if (isDebugging && !ObjectUtils.isEmpty(debugProcess)) {
          if (debugProcess.compId === id) {
            setIsBreakPoint(true);
          } else {
            setIsBreakPoint(false);
          }
        }
      }
    }, [isDebugging, trace, debugProcess]);

    const onOpenProcessPopup = (e) => {
      const callbackFnc = (callbackData) => {
        const body = {
          ...process,
          propertyValue: callbackData,
        };
        if (isChild) {
          WorkflowReduxHelper.updateIteratorNode(
            dispatch,
            [body],
            parentNode,
            workflow
          );
        } else {
          WorkflowReduxHelper.updateNodes(dispatch, [body], workflow);
        }

        Popup.close();
      };
      Popup.open(
        <ConditionPopup
          parentNodeId={parentNodeId}
          workspace={workspace}
          callbackFnc={callbackFnc}
          workflow={workflow.output}
          processInfo={process.propertyValue}
          processType={process.processType}
          nodes={nodes}
          edges={edges}
          compId={id}
        />,
        {
          keyDownEvent: false,
          style: {
            content: {
              width: ModalWidth[process.processType] || ModalWidth.other,
            },
          },
        }
      );
    };

    /**
     * 브레이크 포인트 삭제
     * @param {*} e
     */
    const removeBreakpoint = (e) => {
      stopEvent(e);
      dispatch(
        deleteBreakpoint({
          compId: process.compId,
        })
      );
    };

    /**
     * 프로세스 삭제
     * @param {*} e
     */
    const onDeleteProcess = (e) => {
      stopEvent(e);
      WorkflowReduxHelper.deleteProcess(dispatch, process.compId, workflow);
    };

    /**
     * 브레이크 포인트 설정
     * @param {*} e
     */

    const setBreakpoint = (e) => {
      stopEvent(e);
      dispatch(
        addBreakpoint({
          compId: process.compId,
          type: Enums.WorkflowNodeType.CONDITION,
          processName: process.propertyValue.processNm,
          processType: process.processType,
          serviceUid: workflow.serviceInfo.serviceUid,
          serviceId: workflow.serviceInfo.serviceId,
          serviceName: workflow.serviceInfo.serviceName,
        })
      );
    };

    const renderForDiamond = () => {
      return (
        <div>
          {inCommunication && isBreakPoint ? (
            <div className={`debug-info ${breakpointType} `}>
              <div style={{ fontSize: "13px", fontWeight: "normal" }}>
                실행 전
              </div>
              <div style={{ fontSize: "13px", fontWeight: "normal" }}>
                실행 후
              </div>
            </div>
          ) : (
            <></>
          )}
          <div>
            <Tooltip title="Break Point" placement="top">
              {breakpoints.find((bp) => bp.compId === process.compId) ? (
                <button
                  className={`workflow-condition-btn ${
                    isBreakPoint || isTracing
                      ? " condition-breakpoint-btn "
                      : ""
                  } `}
                  onClick={removeBreakpoint}
                >
                  <BsFillPinFill size={20} />
                </button>
              ) : (
                <button
                  className={`workflow-condition-btn ${
                    isBreakPoint || isTracing
                      ? " condition-breakpoint-btn "
                      : ""
                  } `}
                  onClick={setBreakpoint}
                >
                  <BsPinAngle size={20} />
                </button>
              )}
            </Tooltip>
            <button
              className={`workflow-condition-btn ${
                isBreakPoint || isTracing ? " condition-breakpoint-btn " : ""
              }`}
              onClick={onDeleteProcess}
            >
              <AiOutlineClose size={20} />
            </button>
          </div>

          <div className="name" style={{ maxWidth: "200px" }}>
            {process.propertyValue.processNm}
          </div>
        </div>
      );
    };

    return (
      <div
        className={`workflow-node ${isBundling ? " bundling" : ""} `}
        style={{ position: "relative" }}
      >
        <div
          // className={`workflow-process-node-wrapper
          className={`workflow-condition-node nodrag
            ${selected ? " selected " : ""}
            ${isBreakPoint || isTracing ? " condition-breakpoint " : ""}
       ${process.processType} 
       
      `}
        >
          {/* {renderHeader()} */}
          {/* {renderBody()} */}
        </div>
        <div onDoubleClick={onOpenProcessPopup} className="condition-content">
          {renderForDiamond()}
        </div>
        <span className="handle-wrapper">
          <Handle
            type="source"
            className="targetHandle"
            position={Position.Top}
            id="a"
          />
          <Handle
            type="source"
            className="targetHandle"
            position={Position.Left}
            id="b"
          />
          <Handle
            type="source"
            className="targetHandle"
            position={Position.Right}
            id="c"
          />

          <Handle
            type="source"
            className="targetHandle"
            position={Position.Bottom}
            id="d"
          />
        </span>
      </div>
    );
  }
);

/**
 * 서비스 노드 타입
 * @param {data:Object,id:String,selected:Boolean}
 */
export const ServiceNodeType = memo(
  ({ data: { process, comment, isBundling }, id, selected, ...args }) => {
    const dispatch = useDispatch();
    const workflow = useSelector((state) => state.workflow);
    const workspace = useSelector((state) => state.workspace);
    const {
      breakpoint: breakpoints,
      trace,
      isDebugging,
      process: debugProcess,
      breakpointType,
      inCommunication,
    } = useSelector((state) => state.workflowDebug);

    const [isChild, parentNodeId] = useIsIteratorChild(id);
    const [isComment, setIsComment] = useState(false);
    const [isTracing, setIsTracing] = useState(false); // 디버깅 중 트레이싱 되는지
    const [isBreakPoint, setIsBreakPoint] = useState(false); //브레이크 포인트에 걸린 노드인지

    const nodes = useNodes();
    const edges = useEdges();

    useEffect(() => {
      let parentComment = false;
      if (parentNodeId) {
        parentComment = JsonUtils.findNode(
          workflow.serviceComment,
          "compId",
          parentNodeId
        )
          ? true
          : false;
      }
      if (comment || parentComment) {
        setIsComment(true);
      } else {
        setIsComment(false);
      }
    }, [comment]);

    useEffect(() => {
      if (!isDebugging) {
        setIsTracing(false);
        setIsBreakPoint(false);
      } else {
        if (isDebugging && ArrayUtils.isArray(trace)) {
          const _isTracing = trace.find((t) => t.compId === id);
          if (_isTracing) setIsTracing(true);
          else setIsTracing(false);
        }
        if (isDebugging && !ObjectUtils.isEmpty(debugProcess)) {
          if (debugProcess.compId === id) {
            setIsBreakPoint(true);
          } else {
            setIsBreakPoint(false);
          }
        }
      }
    }, [isDebugging, trace, debugProcess]);

    /**
     * 워크플로우(서비스) 목록 호출
     * @param {*} e
     */
    const onOpenWorkflowList = (e) => {
      const callbackFnc = (data) => {
        const body = {
          ...process,
          propertyValue: { ...data },
        };
        WorkflowReduxHelper.updateNodes(dispatch, [body], workflow);
        Popup.close();
      };

      Popup.open(
        <ServiceModal
          callbackFnc={callbackFnc}
          workspace={workspace}
          workflow={workflow}
          processType={"service"}
          processInfo={process.propertyValue}
          nodes={nodes}
          edges={edges}
          compId={id}
        />,
        {
          style: {
            content: {
              width: "55%",
            },
          },
        }
      );
    };

    /**
     * 프로세스 삭제
     * @param {*} e
     */
    const onDeleteProcess = (e) => {
      stopEvent(e);
      WorkflowReduxHelper.deleteProcess(dispatch, process.compId, workflow);
    };

    /**
     * 해당 서비스로 이동
     * @param {*} e
     * @returns
     */
    const onMoveToService = (e) => {
      stopEvent(e);
      if (!workflow.serviceInfo.serviceUid)
        return Message.alert(
          "저장을 하신 후 이용할 수 있습니다.",
          Enums.MessageType.WARN
        );
      const moveNext = () => {
        WorkflowService.getService(process.propertyValue, (res) => {
          if (!res.data)
            return Message.alert(
              "서비스를 찾을 수 없습니다.",
              Enums.MessageType.ERROR
            );
          const serviceDetail = WorkflowService.setData(res.data);
          WorkflowReduxHelper.moveToNextService(
            dispatch,
            serviceDetail,
            workflow
          );
        });
      };
      WorkflowService.getService(
        { serviceUid: workflow.serviceInfo.serviceUid },
        (res) => {
          const prevService = WorkflowService.setData(res.data);
          //viewport는 다른경우가 많기 때문에 빼고 비교
          const prev = produce(prevService.serviceContent, (draft) => {
            JsonUtils.removeNode(draft, "viewport");
          });

          const next = produce(workflow.output, (draft) => {
            JsonUtils.removeNode(draft, "viewport");
          });

          const prevMemo = prevService.memo;
          const nextMemo = workflow.memo;
          if (
            JSON.stringify(prev) !== JSON.stringify(next) ||
            JSON.stringify(prevMemo) !== JSON.stringify(nextMemo)
          ) {
            /**
             * 저장 후 진행
             */
            const callback = () => {
              const body = {
                ...workflow.serviceInfo,
                serviceContent: workflow.output,
                serviceComment: workflow.serviceComment,
                serviceMemo: workflow.serviceMemo,
                useYn: "Y",
                commitComment: "",
                releaseCommentYn: "N",
                ...workspace,
              };
              WorkflowService.saveService(body, (res) => {
                WorkflowService.localStorageSave(body);
                Popup.close();
                moveNext();
              });
            };
            const showPopup = () => {
              Popup.open(
                <SaveQuestPopup callback={callback} closeCallback={moveNext} />,
                {
                  effect: {
                    ...Popup.ScaleUp,
                    end: {
                      top: "30%",
                      opacity: 1,
                    },
                  },
                  style: { content: { width: "400px", top: "400px" } },
                }
              );
            };

            const autoSaveInfo = LocalStorageService.get(
              Enums.LocalStorageName.WORKFLOW_AUTOSAVE
            );
            if (autoSaveInfo) {
              if (
                autoSaveInfo.userId === User.getId() &&
                autoSaveInfo.autoSave === "Y"
              ) {
                //자동 저장
                callback();
              } else {
                LocalStorageService.remove(
                  Enums.LocalStorageName.WORKFLOW_AUTOSAVE
                );
                showPopup();
              }
            } else {
              showPopup();
            }
          } else {
            moveNext();
          }
        }
      );
    };

    /**
     * 브레이크 포인트 삭제
     * @param {*} e
     */
    const removeBreakpoint = (e) => {
      stopEvent(e);
      dispatch(
        deleteBreakpoint({
          compId: process.compId,
        })
      );
    };

    /**
     * 브레이크 포인트 설정
     * @param {*} e
     */
    const setBreakpoint = (e) => {
      stopEvent(e);
      dispatch(
        addBreakpoint({
          compId: process.compId,
          type: Enums.WorkflowNodeType.SERVICE,
          processName: process.propertyValue.processNm,
          processType: process.processType,
          serviceUid: workflow.serviceInfo.serviceUid,
          serviceId: workflow.serviceInfo.serviceId,
          serviceName: workflow.serviceInfo.serviceName,
        })
      );
    };

    return (
      <>
        {inCommunication && isBreakPoint ? (
          <div className={`debug-info ${breakpointType}`}>
            <div>실행 전</div>
            <div>실행 후</div>
          </div>
        ) : (
          <></>
        )}
        <div
          className={`workflow-node ${isChild ? `${parentNodeId}_child` : ""} ${
            comment && ` comment`
          } ${isBundling ? " bundling" : ""}`}
        >
          <div
            className={`workflow-process-node-wrapper ${
              selected ? "selected" : ""
            } 
          ${isTracing ? " traced " : ""} 
          ${debugProcess?.compId === id ? " border-blink " : ""} 
          service-node`}
          >
            {isComment && <div className="node-comment">주석</div>}

            <div className="header">
              <div className="title">
                {process.propertyValue.serviceUid ? (
                  <span>
                    <MdMiscellaneousServices size={22} />
                  </span>
                ) : (
                  <button className="blink">
                    <MdError size={25} color="tomato" />
                  </button>
                )}

                <span className="name" style={{ maxWidth: "180px" }}>
                  {process.propertyValue.serviceName}
                </span>
              </div>

              <div className="control-button">
                <Tooltip title="수정">
                  <button
                    style={{ color: "limegreen" }}
                    onClick={onOpenWorkflowList}
                  >
                    <AiOutlineEdit size={20} />
                  </button>
                </Tooltip>
              </div>

              <div>
                <Tooltip title="Break Point" placement="top">
                  {breakpoints.find((bp) => bp.compId === process.compId) ? (
                    <button onClick={removeBreakpoint}>
                      <BsFillPinFill size={20} />
                    </button>
                  ) : (
                    <button onClick={setBreakpoint}>
                      <BsPinAngle size={20} />
                    </button>
                  )}
                </Tooltip>
                <button onClick={onDeleteProcess}>
                  <AiOutlineClose size={20} />
                </button>
              </div>
            </div>

            <div
              className="workflow-process-node "
              onDoubleClick={onOpenWorkflowList}
            >
              <div className="service-border">
                <div>
                  <div>[모듈명] :</div>
                  <div>
                    [서비스 ID] :{" "}
                    <strong>{process.propertyValue.serviceId}</strong>
                  </div>
                </div>
                <div>
                  <Button
                    variant="success"
                    className="service-edit-button"
                    onClick={onMoveToService}
                    size="sm"
                  >
                    {`GO`}
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <span className="handle-wrapper">
            <Handle
              type="source"
              className="targetHandle"
              position={Position.Top}
              id="a"
            />
            <Handle
              type="source"
              className="targetHandle"
              position={Position.Left}
              id="b"
            />
            <Handle
              type="source"
              className="targetHandle"
              position={Position.Right}
              id="c"
            />

            <Handle
              type="source"
              className="targetHandle"
              position={Position.Bottom}
              id="d"
            />
          </span>
        </div>
      </>
    );
  }
);

/**
 * Code 타입
 * @param {data:Object,id:String,selected:Boolean}
 */
export const CodeNodeType = memo(
  ({ data: { process, comment, isBundling }, id, selected, ...props }) => {
    const dispatch = useDispatch();
    const workflow = useSelector((state) => state.workflow);
    const workspace = useSelector((state) => state.workspace);
    const {
      breakpoint: breakpoints,
      trace,
      isDebugging,
      process: debugProcess,
      breakpointType,
      inCommunication,
    } = useSelector((state) => state.workflowDebug);

    const [isComment, setIsComment] = useState(false);
    const [isChild, parentNodeId, parentNode] = useIsIteratorChild(id);
    const [isTracing, setIsTracing] = useState(false); // 디버깅 중 트레이싱 되는지
    const [isBreakPoint, setIsBreakPoint] = useState(false); //브레이크 포인트에 걸린 노드인지
    const nodes = useNodes();
    const edges = useEdges();

    // const isTarget = connectionNodeId && connectionNodeId !== id;
    // const targetHandleStyle = { zIndex: isTarget ? 3 : 1 };
    useEffect(() => {
      let parentComment = false;
      if (parentNodeId) {
        parentComment = JsonUtils.findNode(
          workflow.serviceComment,
          "compId",
          parentNodeId
        )
          ? true
          : false;
      }
      if (comment || parentComment) {
        setIsComment(true);
      } else {
        setIsComment(false);
      }
    }, [comment]);

    useEffect(() => {
      if (!isDebugging) {
        setIsTracing(false);
        setIsBreakPoint(false);
      } else {
        if (isDebugging && ArrayUtils.isArray(trace)) {
          const _isTracing = trace.find((t) => t.compId === id);
          if (_isTracing) setIsTracing(true);
          else setIsTracing(false);
        }
        if (isDebugging && !ObjectUtils.isEmpty(debugProcess)) {
          if (debugProcess.compId === id) {
            setIsBreakPoint(true);
          } else {
            setIsBreakPoint(false);
          }
        }
      }
    }, [isDebugging, trace, debugProcess]);

    const onOpenProcessPopup = (e) => {
      const callbackFnc = (callbackData) => {
        const body = {
          ...process,
          propertyValue: callbackData,
        };
        if (isChild) {
          WorkflowReduxHelper.updateIteratorNode(
            dispatch,
            [body],
            parentNode,
            workflow
          );
        } else {
          WorkflowReduxHelper.updateNodes(dispatch, [body], workflow);
        }

        Popup.close();
      };
      Popup.open(
        <CodePopup
          parentNodeId={parentNodeId}
          workspace={workspace}
          callbackFnc={callbackFnc}
          workflow={workflow.output}
          processInfo={process.propertyValue}
          processType={process.processType}
          nodes={nodes}
          edges={edges}
          compId={id}
        />,
        {
          keyDownEvent: false,
          style: {
            content: {
              width: ModalWidth[process.processType] || ModalWidth.other,
            },
          },
        }
      );
    };

    const onDeleteProcess = (e) => {
      stopEvent(e);
      WorkflowReduxHelper.deleteProcess(dispatch, process.compId, workflow);
    };

    return (
      <>
        {inCommunication && isBreakPoint ? (
          <div className={`debug-info ${breakpointType}`}>
            <div>실행 전</div>
            <div>실행 후</div>
          </div>
        ) : (
          <></>
        )}
        <div
          className={`workflow-node ${isChild ? `${parentNodeId}_child` : ""} ${
            comment && ` comment`
          } ${isBundling ? " bundling" : ""}`}
        >
          <div
            className={`workflow-process-node-wrapper ${
              selected ? "selected" : ""
            }
            ${isTracing ? " traced " : ""} 
            ${debugProcess?.compId === id ? " border-blink " : ""} 
            `}
          >
            {isComment && (
              <div className="node-comment" onDoubleClick={onOpenProcessPopup}>
                주석
              </div>
            )}
            <div className="header">
              <div className="title">
                <span>
                  <BsCodeSlash size={22} />
                </span>
                <span className="name"> {process.propertyValue.processNm}</span>
                <Tooltip title="수정">
                  <button
                    style={{ color: "limegreen" }}
                    onClick={onOpenProcessPopup}
                  >
                    <AiOutlineEdit size={20} />
                  </button>
                </Tooltip>
              </div>

              <div>
                <button onClick={onDeleteProcess}>
                  <AiOutlineClose size={20} />
                </button>
              </div>
            </div>
            <div
              className="workflow-process-node"
              onDoubleClick={onOpenProcessPopup}
            >
              <div className="code-node-wrapper">
                <div className="code-type">
                  {String(process.propertyValue.codeType).toUpperCase()}
                </div>
                <Button
                  variant="primary"
                  className="service-edit-button"
                  onClick={onOpenProcessPopup}
                  size="sm"
                >
                  {`[ 코드 상세 보기 ]`}
                </Button>
              </div>
              {/* 코드 설명 같은거 넣을수도 있음 */}
              {/* <div className="process-type">Process - {process.processType}</div> */}
            </div>
          </div>
          <span className="handle-wrapper">
            <Handle
              type="source"
              className="targetHandle"
              position={Position.Top}
              id="a"
            />
            <Handle
              type="source"
              className="targetHandle"
              position={Position.Left}
              id="b"
            />
            <Handle
              type="source"
              className="targetHandle"
              position={Position.Right}
              id="c"
            />

            <Handle
              type="source"
              className="targetHandle"
              position={Position.Bottom}
              id="d"
            />
          </span>
        </div>
      </>
    );
  }
);

/**
 * 노드 묶은 타입
 */
export const BundleNodeType = memo(
  ({ data: { process, isBundling }, id, selected, ...props }) => {
    const nodes = useNodes();
    const dispatch = useDispatch();
    const [isChild, parentNodeId] = useIsIteratorChild(id);
    const workflow = useSelector((state) => state.workflow);

    /**
     * 번들 삭제
     */
    const onDeleteBundle = (e) => {
      stopEvent(e);
      /**
       * 1. 번들의 포지션을 가져온다.
       * 2. 내부 프로세스들의 포지션에 번들의 포지션 (x,y) 만큼 더해서 업데이트 한다.
       */
      const updatedNodeList = [];
      const bundlePosition = process.position;

      process.propertyValue.nodeList.forEach((node) => {
        const bundleChildNode = JsonUtils.findNode(
          workflow.output,
          "compId",
          node.compId
        );
        const positionUpdatedData = produce(bundleChildNode, (draft) => {
          draft.position = {
            x: draft.position.x + bundlePosition.x,
            y: draft.position.y + bundlePosition.y,
          };
        });
        updatedNodeList.push(positionUpdatedData);
      });

      WorkflowReduxHelper.deleteBundle(
        dispatch,
        workflow,
        process,
        updatedNodeList
      );
    };

    //최소화 시키는 로직
    const onClickMini = (e) => {
      stopEvent(e);
      /**
       * 번들내 시작노드와 마지막 노드는 프로세스이다.
       * 1. 번들내 시작노드 구하기
       * 2. 번들내 마지막 노드 구하기
       * 3. 시작노드의 이전의 커넥터 ID를 번들의 프로퍼티 정보에 connectFrom에 넣는다.
       * 4. 마지막 노드의 커넥터 ID를 가져와서 동일하게 connectTo에 넣는다.
       */

      //번들내 시작노드 구하기
      const nodeList = nodes.filter(
        (node) =>
          process.propertyValue.nodeList.findIndex(
            (n) => n.compId === node.id
          ) > -1
      );
      //번들 내 프로세스 목록
      const processListInBundle = nodeList.filter(
        (n) => n.type !== Enums.WorkflowNodeType.CONNECTOR
      );
      // 번들 내 커넥터 목록
      const connectorListInBundle = nodeList.filter(
        (n) => n.type === Enums.WorkflowNodeType.CONNECTOR
      );
      //번들 내부에 노드를 향하지만, 번들 내부에 없는 커넥터들을 구해야함
      const connectorFrom = [];
      const connectorTo = [];

      nodes.forEach((node) => {
        //커넥터 이지만 번들에 없는 커넥터 중 번들 내부를 향하는 노드를 검색함
        /**
         * 전체 커넥터에서 탐색 이유
         * 1. 무조건 번들의 시작으로만 커넥터가 들어오지 않음.
         * 2. 번들 내외부를 재귀적으로 in/out 하는 경우 번들 중간 또는 마지막으로 다시 돌아올 수 도있기때문
         */
        if (
          node.type === Enums.WorkflowNodeType.CONNECTOR &&
          !connectorListInBundle.find((cb) => cb.id === node.id)
        ) {
          //번들 내부로 향하는 커넥터
          const isInFromConnectorInBundle = processListInBundle.find(
            (process) => process.id === node.data.connector.processTo
          );
          if (isInFromConnectorInBundle) {
            connectorFrom.push(node.id);
          }
          //번들에서 외부로 향하는 커넥터
          const isInToConnectorInBundle = processListInBundle.find(
            (process) => process.id === node.data.connector.processFrom
          );
          if (isInToConnectorInBundle) {
            connectorTo.push(node.id);
          }
        }
      });
      //리덕스 업데이트
      const bundleProcess = produce(process, (draft) => {
        draft.propertyValue.connectorFrom = connectorFrom;
        draft.propertyValue.connectorTo = connectorTo;
        draft.propertyValue.expand = false;
      });
      dispatch(updateBundle(bundleProcess));
    };

    //최대화 시키는 로직
    const onClickExpand = (e) => {
      stopEvent(e);
      const newBundle = produce(process, (draft) => {
        draft.propertyValue.expand = true;
      });
      dispatch(updateBundle(newBundle));
    };

    if (process.propertyValue.expand) {
      return (
        <>
          <div
            className={`workflow-bundle-node-wrapper ${
              selected ? "selected" : ""
            }`}
            style={{ borderColor: process.propertyValue.headerColor }}
          >
            <div
              className="header"
              style={{ background: process.propertyValue.headerColor }}
            >
              <div className="title">
                <span>
                  <FaRegObjectGroup size={22} />
                </span>
                <span className="name"> {process.propertyValue.groupNm}</span>
              </div>

              <div>
                <Tooltip title="접기">
                  <button onClick={onClickMini}>
                    <FiMinimize size={20} />
                  </button>
                </Tooltip>
                <button onClick={onDeleteBundle}>
                  <AiOutlineClose size={20} />
                </button>
              </div>
            </div>
            <span className="handle-wrapper">
              <Handle
                type="source"
                className="targetHandle"
                position={Position.Top}
                id="a"
              />
              <Handle
                type="source"
                className="targetHandle"
                position={Position.Left}
                id="b"
              />
              <Handle
                type="source"
                className="targetHandle"
                position={Position.Right}
                id="c"
              />

              <Handle
                type="source"
                className="targetHandle"
                position={Position.Bottom}
                id="d"
              />
            </span>
          </div>
        </>
      );
    } else {
      return (
        <div
          className={`workflow-node ${isChild ? `${parentNodeId}_child` : ""} 
          ${isBundling ? " bundling" : ""}`}
        >
          <div
            className={`workflow-process-node-wrapper bundle ${
              selected ? " selected " : ""
            }
           ${process.processType} 
          `}
            style={{ borderColor: process.propertyValue.headerColor }}
          >
            <div
              className="header"
              style={{ background: process.propertyValue.headerColor }}
            >
              <div className="title">
                <span>
                  <FaRegObjectGroup />
                </span>
                <span className="name" style={{ width: "200px" }}>
                  {process.propertyValue.groupNm}
                </span>
              </div>
              <div className="control-button">
                <Tooltip title="펼치기">
                  <button onClick={onClickExpand}>
                    <FiMaximize size={20} />
                  </button>
                </Tooltip>
                <button onClick={onDeleteBundle}>
                  <AiOutlineClose size={20} />
                </button>
              </div>
            </div>
            <div
              className="workflow-process-node"
              onDoubleClick={onClickExpand}
            >
              {process.propertyValue.description}
            </div>
          </div>
          <span className="handle-wrapper" style={{ visibility: "hidden" }}>
            <Handle
              type="source"
              className="targetHandle"
              position={Position.Top}
              id="a"
            />
            <Handle
              type="source"
              className="targetHandle"
              position={Position.Left}
              id="b"
            />
            <Handle
              type="source"
              className="targetHandle"
              position={Position.Right}
              id="c"
            />

            <Handle
              type="source"
              className="targetHandle"
              position={Position.Bottom}
              id="d"
            />
          </span>
        </div>
      );
    }
  }
);

const useIsIteratorChild = (id) => {
  const nodes = useNodes();
  const [parentNode, setParentNode] = useState({});
  const [parentNodeId, setParentNodeId] = useState("");
  const [isChild, setIsChild] = useState(false);

  useEffect(() => {
    const _isChild = getIsChild() ? true : false;

    setIsChild(_isChild);
    if (_isChild) {
      setParentNodeId(getIsChild());
      setParentNode(getParentNode());
    }
  }, [id, nodes]);

  const getIsChild = () => {
    const thisNode = nodes.find((n) => n.id === id);
    return thisNode.parentNode;
  };

  const getParentNode = () => {
    const thisNode = nodes.find((n) => n.id === getIsChild());
    return thisNode.data.process;
  };

  return [isChild, parentNodeId, parentNode];
};
