import Blockly from "blockly";

/**
 * Blockly icon class link : https://github.com/google/blockly/blob/develop/core/icons/icon.ts
 * /
/**
 * Blockly custom icon link: https://developers.google.com/blockly/guides/create-custom-blocks/icons/creating-custom-icons/basic-implementation
 */

/**
 * 팝업 category에 있는 Block에 사용할 icon 클래스 정의
 */
class BlockPopupIcon extends Blockly.icons.Icon {
  constructor(sourceBlock) {
    super(sourceBlock);
  }
  getType() {
    return new Blockly.icons.IconType("popup_icon");
  }
  initView(pointerdownListener) {
    if (this.svgRoot) return; // Already initialized.

    // This adds the pointerdownListener to the svgRoot element.
    // If you do not call `super` you must do this yourself.
    super.initView(pointerdownListener);

    const svg = Blockly.utils.dom.createSvgElement(
      Blockly.utils.Svg.SVG,
      {
        width: "20px",
        height: "20px",
        fill: "white",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 24 24",
        "fill-opacity": "0.5",
      },
      this.svgRoot // Append to the svgRoot.
    );
    Blockly.utils.dom.createSvgElement(
      Blockly.utils.Svg.PATH,
      {
        fill: "white",
        d: "M5 8v7h14V8zm13 6H6V9h12zm-7-8H5V5h6zm7-1h1v1h-1zm-6 17.207L15.207 18H20.5a1.502 1.502 0 0 0 1.5-1.5v-13A1.502 1.502 0 0 0 20.5 2h-17A1.502 1.502 0 0 0 2 3.5v13A1.502 1.502 0 0 0 3.5 18h5.293zm9-5.707a.5.5 0 0 1-.5.5h-5.788L12 20.558 9.288 17H3.5a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 .5-.5h17a.5.5 0 0 1 .5.5z",
        stroke: "#1C274C",
        "stroke-width": "1.5",
        "stroke-linecap": "round",
      },
      svg
    );
    Blockly.utils.dom.createSvgElement(
      Blockly.utils.Svg.PATH,
      {
        d: "M0 0h24v24H0z",
        fill: "none",
      },
      svg
    );
  }
  getSize() {
    return new Blockly.utils.Size(20, 20);
  }
  updateCollapsed() {
    if (!this.svgRoot) return;
    if (this.sourceBlock.isCollapsed()) {
      this.svgRoot.style.display = "block";
      return true;
    }
    if (Blockly.hasBubble(this)) {
      this.setBubbleVisible(false);
    }
  }
  dispose() {
    // Always call super!
    super.dispose();

    this.myBubble?.dispose();
    this.myOtherReference?.dispose();
  }
  saveState() {
    return this.state; // Some arbirtary JSON-compatible data.
  }
  loadState(state) {
    this.state = state;
  }
  onLocationChange(blockOrigin) {
    this.workspaceLocation = Blockly.utils.Coordinate.sum(
      blockOrigin,
      this.offsetInBlock
    );
  }
  isShownWhenCollapsed() {
    return true;
  }
}

Blockly.icons.registry.register(
  new Blockly.icons.IconType("popup_icon"),
  BlockPopupIcon
);
