import Api from "components/common/Api";

class EventhandlerService {
  static requestMapping = "/eventhandler";

  /**
   * Function Master 목록 child 포함 호출
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errorCallback
   */
  static getFncMstList(data, callbackFnc, errorCallback) {
    Api.post(
      this.requestMapping + "/getFncMstList",
      data,
      callbackFnc,
      errorCallback
    );
  }

  /**
   * FNC DTL ID별 Block 목록 호출
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errorCallback
   */
  static getBlockList(data, callbackFnc, errorCallback) {
    Api.post(
      this.requestMapping + "/getBlockList",
      data,
      callbackFnc,
      errorCallback
    );
  }

  /**
   * 자주 사용하는 기능 Block 목록 호출
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errorCallback
   */
  static getOftenUseBlockList(data, callbackFnc, errorCallback) {
    Api.post(
      this.requestMapping + "/getOftenUseBlockList",
      data,
      callbackFnc,
      errorCallback
    );
  }

  /**
   * 핸들러 정보 호출
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errorCallback
   */
  static getEventHandlerInfo(data, callbackFnc, errorCallback) {
    Api.post(
      this.requestMapping + "/getEventHandlerInfo",
      data,
      callbackFnc,
      errorCallback
    );
  }

  /**
   * 사용자 정의 함수 저장
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errorCallback
   */
  static saveFncCustom(data, callbackFnc, errorCallback) {
    Api.post(
      this.requestMapping + "/saveFncCustom",
      data,
      callbackFnc,
      errorCallback
    );
  }

  /**
   * 컴포넌트 이벤트 목록 호출
   * 저장 팝업에서 분류할때 사용
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errorCallback
   */
  static getEventComponentList(data, callbackFnc, errorCallback) {
    Api.post("/common/getEventComponentList", data, callbackFnc, errorCallback);
  }

  /**
   * 사용자 정의 함수 목록 호출
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errorCallback
   */
  static getFncCustomList(data, callbackFnc, errorCallback) {
    Api.post(
      this.requestMapping + "/getFncCustomList",
      data,
      callbackFnc,
      errorCallback
    );
  }
}

export default EventhandlerService;
