import ActionType from "components/builder/BuilderActionType";
import produce from "immer";

const initialState = {
  style: {
    moduleCd: null,
    styleId: null,
    styleNm: "",
    styleCode: null,
    apply: false,
  },
};

export default function SettingReducer(state = initialState, action) {
  switch (action.type) {
    case ActionType.SETTING.STYLE.SET:
      return produce(state, (draft) => {
        draft.style.styleId = action.payload.styleId;
        draft.style.moduleCd = action.payload.moduleCd;
        draft.style.styleNm = action.payload.styleNm;
        draft.style.styleCode = action.payload.styleCode;
        if (!draft.style.apply) {
          draft.style.apply = action.payload.apply ? true : false;
        } else if (Object.keys(action.payload).length === 0) {
          draft.style.apply = false;
        }
      });
    case ActionType.SETTING.INIT:
      return initialState;
    default:
      return state;
  }
}
