import Api from "components/common/Api";

class CodeService {
  static getCodeList(data, callbackFnc) {
    Api.get("/common/codeList", data, callbackFnc);
  }

  static getCodeCombo(data, callbackFnc) {
    Api.post("/common/codeCombo", data, callbackFnc);
  }

  static getMajorList(data, callbackFnc, errCallbackFnc) {
    Api.post("/common/getMajorList", data, callbackFnc, errCallbackFnc);
  }

  static getMessageList(data, callbackFnc, errCallbackFnc) {
    Api.post("/common/getMessageList", data, callbackFnc, errCallbackFnc);
  }

  static getNumberFormatList(data, callbackFnc, errCallbackFnc) {
    Api.post("/common/getNumberFormatList", data, callbackFnc, errCallbackFnc);
  }

  static getWijmoLicense(data, callbackFnc) {
    Api.post("/common/getWijmoLicense", data, callbackFnc);
  }
}
export default CodeService;
