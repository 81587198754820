import { Enums } from "components/builder/BuilderEnum";
import {
  getEntityPropertyValue,
  getServiceEntityDefinition,
} from "components/builder/workflow/editor/render/WorkflowRenderUtils";
import Message from "components/common/Message";
import WijmoGrid, { onKeyDownEvent } from "components/common/element/WijmoGrid";
import Modal from "components/common/modal/UModal";
import UModalTemplate from "components/common/modal/UModalTemplate";
import ObjectUtils from "components/common/utils/ObjectUtils";
import StringUtils from "components/common/utils/StringUtils";
import { useEffect, useRef, useState } from "react";

/**
 * 에디터에 그려진 프로세스의 목록을 확인함
 * @returns
 */
function WorkFlowProcessListPopup({
  workflow,
  callbackFnc,
  fieldRequired,
  processTypes,
  isSetEntity,
  compId,
  nodes,
  edges,
  includeSelf,
  getAccessibleEntityList,
  showAll,
  ...props
}) {
  const [entityList, _setEntityList] = useState([]);
  const [selectedEntity, _setSelectedEntity] = useState({});
  const selectedEntityRef = useRef({});
  const entityListRef = useRef([]);

  const setEntityList = (data) => {
    _setEntityList(data);
    entityListRef.current = data;
  };
  const setSelectedEntity = (data) => {
    _setSelectedEntity(data);
    selectedEntityRef.current = data;
  };

  useEffect(() => {
    let _entityList = getAccessibleEntityList(
      processTypes,
      includeSelf,
      showAll
    );
    if (fieldRequired) {
      if (StringUtils.equalsIgnoreCase(fieldRequired, "pkYn")) {
        _entityList = _entityList.filter((e) => {
          if (e.propertyValue.entityFieldList) {
            return e.propertyValue.entityFieldList.find((f) =>
              StringUtils.equalsIgnoreCase(f.option.pkYn, "Y")
            );
          } else if (e.processType === Enums.WorkflowNodeType.ITERATOR) {
            //이터레이터인 경우 참조 def를 검색한다.
            const _refCompId = e.propertyValue.editorAttr?.referenceCompId;
            if (_refCompId) {
              //참조대상
              const _refTarget = nodes.find((n) => n.id === _refCompId);
              if (_refTarget.data.process.propertyValue.entityFieldList) {
                return _refTarget.data.process.propertyValue.entityFieldList.find(
                  (f) => StringUtils.equalsIgnoreCase(f.option.pkYn, "Y")
                );
              }
            }
          } else {
            return true;
          }
        });
      } else if (StringUtils.equalsIgnoreCase(fieldRequired, "cudChar")) {
        _entityList = _entityList.filter((e) => {
          if (e.propertyValue.entityFieldList) {
            return e.propertyValue.entityFieldList.find((f) =>
              StringUtils.equalsIgnoreCase(f.fieldId, "cudChar")
            );
          } else if (e.processType === Enums.WorkflowNodeType.ITERATOR) {
            const _refCompId = e.propertyValue.editorAttr?.referenceCompId;
            if (_refCompId) {
              //참조대상
              const _refTarget = nodes.find((n) => n.id === _refCompId);
              if (_refTarget.data.process.propertyValue.entityFieldList) {
                return _refTarget.data.process.propertyValue.entityFieldList.find(
                  (f) => StringUtils.equalsIgnoreCase(f.fieldId, "cudChar")
                );
              } else return false;
            }
          } else {
            return true;
          }
        });
      }
    }
    const pValueList = _entityList.map((entity) =>
      getEntityPropertyValue(entity)
    );
    setEntityList(pValueList.flat());

    // window.addEventListener("keydown", keyDown);
    // return () => {
    //   window.removeEventListener("keydown", keyDown);
    // };
  }, []);

  const columns = [
    {
      field: "processNm",
      headerName: "프로세스 명",
      width: "*",
      renderCell: (param) => {
        if (param.processType === Enums.WorkflowNodeType.SERVICE) {
          return `[서비스] ${param.processNm}`;
        } else {
          return param.processNm;
        }
      },
    },
    {
      field: "entityNm",
      headerName: "엔티티 명",
      width: 140,
    },
    {
      field: "entityVariable",
      headerName: "Entity 변수",
      width: 140,
    },
  ];

  const onRowClick = (param, e) => {
    setSelectedEntity(param);
  };

  const onRowDoubleClick = (param, e) => {
    if (!callbackFnc) return false;
    // compId,propertyValue: { entityVariable, entityNm, tableNm, entityFieldList },
    let retData = { ...param };
    // callbackFnc(retData);
    if (param.processType === Enums.WorkflowNodeType.SERVICE) {
      getServiceEntityDefinition(param.serviceUid, param.serviceEntityVariable)
        .then((data) => {
          retData = {
            ...data,
            entityVariable: param.entityVariable,
            entityNm: param.entityNm,
          };
          callbackFnc(retData);
        })
        .catch(() => Message.alert("서비스 내용 호출 중 오류가 발생했습니다."));
    } else if (param.processType === Enums.WorkflowNodeType.ITERATOR) {
      if (retData.editorAttr) {
        retData = {
          ...retData,
          ...retData.editorAttr,
        };
      }
      callbackFnc(retData);
    } else {
      callbackFnc(retData);
    }
  };

  const onConfirm = () => {
    if (ObjectUtils.isEmpty(selectedEntity)) {
      Message.alert("엔티티를 선택해주세요.");
    } else {
      if (callbackFnc) {
        callbackFnc(selectedEntity);
      }
    }
  };

  /**
   * 선택이 한박자 늦게되는 오류가 있어서 일반 주석으로 보류
   */
  // const keyDown = (e) =>
  //   onKeyDownEvent(
  //     e,
  //     selectedEntityRef,
  //     entityListRef,
  //     setSelectedEntity,
  //     callbackFnc,
  //     (obj) => obj.compId
  //   );

  return (
    <Modal>
      <Modal.Header title="프로세스 엔티티 목록" />
      <Modal.Body>
        <UModalTemplate className={"process-entity-list-wrapper"}>
          <div className="process-list">
            <WijmoGrid
              columns={columns}
              rows={entityList}
              headersVisibility={"Column"}
              onRowClick={onRowClick}
              onRowDoubleClick={onRowDoubleClick}
              selectedItem={selectedEntity}
              getId={(obj) => obj.compId}
            />
          </div>
        </UModalTemplate>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Button onClick={onConfirm}>확인</Modal.Footer.Button>
      </Modal.Footer>
    </Modal>
  );
}

export default WorkFlowProcessListPopup;
