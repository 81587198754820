import { Enums } from "components/builder/BuilderEnum";
import Api from "components/common/Api";
import User from "components/common/utils/UserUtils";
import StringUtils from "components/common/utils/StringUtils";

class DataModelService {
  // connect 후 this 가 초기화가 되는바람에 에러가 발생함
  // 이유를 모르겠음. 그래서 Enums에 만들어서 import 하는 방식으로 변경
  // static requestMapping = "/datamodel"

  /**
   * 데이터 모델 목록
   * @param {*} data
   * @param {*} callbackFnc
   */
  static getDataModelList(data, callbackFnc) {
    Api.post(Enums.apiPrefix.DATA_MODEL + "/dataModelList", data, callbackFnc);
  }

  /**
   * 선택한 데이터 모델의 엔티티 목록 조회
   * @param {*} data
   * @param {*} callbackFnc
   */
  static getDataModelEntityList(data, callbackFnc) {
    Api.post(
      Enums.apiPrefix.DATA_MODEL + "/getDataModelEntityList",
      data,
      callbackFnc
    );
  }

  static getDataBindingList(data, callbackFnc, errCallbackFnc) {
    Api.post(
      Enums.apiPrefix.DATA_MODEL + "/getDataBindingList",
      data,
      callbackFnc,
      errCallbackFnc
    );
  }

  /**
   * 지정한 엔티티의 필드 목록 조회 ( 그리드 칼럼바인딩 에서 사용 )
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static getDataModelEntityFieldList(data, callbackFnc, errCallbackFnc) {
    Api.post(
      Enums.apiPrefix.DATA_MODEL + "/getDataModelEntityFieldList",
      data,
      callbackFnc,
      errCallbackFnc
    );
  }

  /**
   * 선택한 데이터 모델의 엔티티의 필드 리스트의 상세 정보 전체 조회
   * workflow EntityDefinition에서 사용
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static getDataModelEntityFieldDtailList(data, callbackFnc, errCallbackFnc) {
    Api.post(
      Enums.apiPrefix.DATA_MODEL + "/getDataModelEntityFieldDtailList",
      data,
      callbackFnc,
      errCallbackFnc
    );
  }
  /**
   * dataModel column data 필드와 함께 조회
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static getDataModelEntityWithFieldList(data, callbackFnc, errCallbackFnc) {
    Api.post(
      Enums.apiPrefix.DATA_MODEL + "/getDataModelEntityWithFieldList",
      data,
      callbackFnc,
      errCallbackFnc
    );
  }

  /**
   * 데이터 모델 목록용 리스트 호출
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static getDataModelListWithUsrTnx(data, callbackFnc, errCallbackFnc) {
    Api.post(
      Enums.apiPrefix.DATA_MODEL + "/dataModelListWithUsrTnx",
      data,
      callbackFnc,
      errCallbackFnc
    );
  }

  static getUsrTnxList(data, callbackFnc, errCallbackFnc) {
    Api.post(
      Enums.apiPrefix.DATA_MODEL + "/getUsrTnxList",
      data,
      callbackFnc,
      errCallbackFnc
    );
  }

  /**
   * 사용자 트랜잭션 삭제
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static deleteUsrTnx(data, callbackFnc, errCallbackFnc) {
    Api.post(
      Enums.apiPrefix.DATA_MODEL + "/deleteUsrTnx",
      data,
      callbackFnc,
      errCallbackFnc
    );
  }

  /**
   * 사용자 트랜잭션 입력
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static saveUsrTnx(data, callbackFnc, errCallbackFnc) {
    Api.post(
      Enums.apiPrefix.DATA_MODEL + "/saveUsrTnx",
      data,
      callbackFnc,
      errCallbackFnc
    );
  }

  /**
   * 데이터모델 정보 호출
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static async getDataModel(data, callbackFnc, errCallbackFnc) {
    await Api.post(
      Enums.apiPrefix.DATA_MODEL + "/getDataModel",
      data,
      callbackFnc,
      errCallbackFnc
    );
  }

  /**
   * 데이터 모델 Cloud 테이블 목록 호출
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static getTableList(data, callbackFnc, errCallbackFnc) {
    data = {
      ...data,
      availableModuleCds: User.selectAvailableModuleCdsOnly(),
    };
    Api.post(
      Enums.apiPrefix.DATA_MODEL + "/getDatalModelTableList",
      data,
      callbackFnc,
      errCallbackFnc
    );
  }

  /**
   * 데이터 모델 Cloud 테이블의 칼럼 호출
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static getDataModelFieldListOnly(data, callbackFnc, errCallbackFnc) {
    Api.post(
      Enums.apiPrefix.DATA_MODEL + "/getDataModelFieldListOnly",
      data,
      callbackFnc,
      errCallbackFnc
    );
  }

  /**
   * 데이터 모델 Cloud Procedure의 파라미터 목록 호출
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static getProcedureParameterListOnly(data, callbackFnc, errCallbackFnc) {
    Api.post(
      Enums.apiPrefix.DATA_MODEL + "/getProcedureParameterList",
      data,
      callbackFnc,
      errCallbackFnc
    );
  }

  /**
   * 엔티티 목록
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static getEntityList(data, callbackFnc, errCallbackFnc) {
    Api.post(
      Enums.apiPrefix.DATA_MODEL + "/getEntityList",
      data,
      callbackFnc,
      errCallbackFnc
    );
  }

  /**
   * 단일 엔티티 조회
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static getDataModelEntity(data, callbackFnc, errCallbackFnc) {
    Api.post(
      Enums.apiPrefix.DATA_MODEL + "/getDataModelEntity",
      data,
      callbackFnc,
      errCallbackFnc
    );
  }

  /**
   * 데이터 모델 저장
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static saveDataModel(data, callbackFnc, errCallbackFnc) {
    Api.post(
      Enums.apiPrefix.DATA_MODEL + "/saveDataModel",
      data,
      callbackFnc,
      errCallbackFnc
    );
  }

  /**
   * 데이터 모델 배포
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static deployDataModel(data, callbackFnc, errCallbackFnc) {
    Api.post(
      Enums.apiPrefix.DATA_MODEL + "/deployDataModel",
      data,
      callbackFnc,
      errCallbackFnc
    );
  }

  /**
   * 데이터 모델 직접 배포
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static deployDirectDataModel(data, callbackFnc, errCallbackFnc) {
    const _modulCd = data.moduleCd === "*" ? "all" : data.moduleCd;
    Api.directPost(
      `/${_modulCd}/DataModelDeploy`,
      data,
      callbackFnc,
      errCallbackFnc
    );
  }

  /**
   * 데이터 모델 삭제
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static deleteDataModel(data, callbackFnc, errCallbackFnc) {
    Api.post(
      Enums.apiPrefix.DATA_MODEL + "/deleteDataModel",
      data,
      callbackFnc,
      errCallbackFnc
    );
  }
  /**
   * 데이터 모델 목록 삭제
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static deleteDataModelList(data, callbackFnc, errCallbackFnc) {
    Api.post(
      Enums.apiPrefix.DATA_MODEL + "/deleteDataModelList",
      data,
      callbackFnc,
      errCallbackFnc
    );
  }

  /**
   * 예약어 목록 호출
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static getReservedWordList(data, callbackFnc, errCallbackFnc) {
    Api.post(
      Enums.apiPrefix.DATA_MODEL + "/getReservedWordList",
      data,
      callbackFnc,
      errCallbackFnc
    );
  }

  /**
   * 기존 APP버전에서 신규 APP 버전으로 데이터 모델 목록 복사
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static succeedDataModelList(data, callbackFnc, errCallbackFnc) {
    Api.post(
      Enums.apiPrefix.DATA_MODEL + "/succeedDataModelList",
      data,
      callbackFnc,
      errCallbackFnc
    );
  }

  /**
   * 기존 APP버전에서 신규 APP 버전으로 데이터 모델 복사
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static succeedDataModel(data, callbackFnc, errCallbackFnc) {
    Api.post(
      Enums.apiPrefix.DATA_MODEL + "/succeedDataModel",
      data,
      callbackFnc,
      errCallbackFnc
    );
  }

  /**
   * 엔티티 명으로 데이터 모델 목록 호출
   * 패치메뉴에서 사용
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static getDataModelListByEntityNm(data, callbackFnc, errCallbackFnc) {
    Api.post(
      Enums.apiPrefix.DATA_MODEL + "/getDataModelListByEntityNm",
      data,
      callbackFnc,
      errCallbackFnc
    );
  }

  /**
   * 엔티티 목록 저장
   * 엔티티 패치에서 사용
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static updateDataModelEntities(data, callbackFnc, errCallbackFnc) {
    Api.post(
      Enums.apiPrefix.DATA_MODEL + "/updateDataModelEntities",
      data,
      callbackFnc,
      errCallbackFnc
    );
  }

  /**
   * 프로시져 엔티티 호출
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static getProcedureEntity(data, callbackFnc, errCallbackFnc) {
    Api.post(
      Enums.apiPrefix.DATA_MODEL + "/getProcedureEntity",
      data,
      callbackFnc,
      errCallbackFnc
    );
  }

  /**
   * 데이터 모델 이력 호출
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static getDataModelHistoryMstList(data, callbackFnc, errCallbackFnc) {
    Api.post(
      Enums.apiPrefix.DATA_MODEL + "/getDataModelHistoryMstList",
      data,
      callbackFnc,
      errCallbackFnc
    );
  }

  /**
   * 데이터 모델 이력 상세
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static getDataModelHistoryList(data, callbackFnc, errCallbackFnc) {
    Api.post(
      Enums.apiPrefix.DATA_MODEL + "/getDataModelHistoryList",
      data,
      callbackFnc,
      errCallbackFnc
    );
  }

  /**
   * 프로시져 목록 호출
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static getProcedureList(data, callbackFnc, errCallbackFnc) {
    Api.post(
      Enums.apiPrefix.DATA_MODEL + "/getProcedureList",
      data,
      callbackFnc,
      errCallbackFnc
    );
  }

  /**
   * 데이터 모델 목록 및 이력 삭제
   * 버전 삭제시 사용
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static deleteByAppReleaseId(data, callbackFnc, errCallbackFnc) {
    Api.post(
      Enums.apiPrefix.DATA_MODEL + "/deleteDataModelByAppReleaseId",
      data,
      callbackFnc,
      errCallbackFnc
    );
  }

  /**
   * 프로시져 목록 직접 연결 호출
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static getDirectProcedureList(data, callbackFnc, errCallbackFnc) {
    const _modulCd = data.moduleCd === "*" ? "all" : data.moduleCd;
    Api.directPost(
      `/${_modulCd}/GetProcedureList`,
      data,
      callbackFnc,
      errCallbackFnc
    );
  }

  /**
   * 데이터 모델 Cloud 테이블 목록 직접 연결 호출
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static getDirectTableList(data, callbackFnc, errCallbackFnc) {
    const _modulCd = data.moduleCd === "*" ? "all" : data.moduleCd;
    Api.directPost(
      `/${_modulCd}/GetTableList`,
      data,
      callbackFnc,
      errCallbackFnc
    );
  }

  /**
   * 데이터 모델 Cloud 테이블의 칼럼 호출
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static getDirectDataModelFieldListOnly(data, callbackFnc, errCallbackFnc) {
    const _modulCd = data.moduleCd === "*" ? "all" : data.moduleCd;
    Api.directPost(
      `/${_modulCd}/getTableColumnList`,
      data,
      callbackFnc,
      errCallbackFnc
    );
  }

  /**
   * 데이터 모델 Cloud Procedure의 파라미터 호출
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static getDirectProcedureParameterListOnly(
    data,
    callbackFnc,
    errCallbackFnc
  ) {
    const _modulCd = data.moduleCd === "*" ? "all" : data.moduleCd;
    Api.directPost(
      `/${_modulCd}/getProcedureParameterList`,
      data,
      callbackFnc,
      errCallbackFnc
    );
  }

  static getDataModelHistory(data, callbackFnc, errCallbackFnc) {
    Api.post(
      Enums.apiPrefix.DATA_MODEL + "/getDataModelHistory",
      data,
      callbackFnc,
      errCallbackFnc
    );
  }

  /**
   * User Transaction 세부 옵션 조회
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static selectDataModelUsrTnx(data, callbackFnc, errCallbackFnc) {
    Api.post(
      Enums.apiPrefix.DATA_MODEL + "/getDataModelUsrTnx",
      data,
      callbackFnc,
      errCallbackFnc
    );
  }

  /**
   * 불러온 서비스를 rendering 가능한 구조로 변경
   * @param {*} dataModel
   * @returns
   */
  static setData = (dataModel) => {
    const removeAuthorInfo = (object) => {
      const {
        insertUserId,
        insertDt,
        updtUserId,
        updtDt,
        userId,
        ...objectInfo
      } = object;

      return objectInfo;
    };
    dataModel = removeAuthorInfo(dataModel);
    const { dataModelEntities } = dataModel;

    dataModel.compId = StringUtils.getUuid();
    dataModel.type = Enums.EntityComponentType.DATA_MODEL;

    if (dataModelEntities?.length > 0) {
      const compIdGeneratedEntities = dataModelEntities.map((entity) => {
        const newEn = { ...removeAuthorInfo(entity) };
        newEn.compId = StringUtils.getUuid();
        newEn.type = Enums.EntityComponentType.ENTITY;
        newEn.position = newEn.position ? JSON.parse(newEn.position) : {};
        newEn.relation = newEn.relation ? JSON.parse(newEn.relation) : [];
        newEn.orderby = newEn.orderby ? JSON.parse(newEn.orderby) : [];
        newEn.whereJoinTable = newEn.whereJoinTable
          ? JSON.parse(newEn.whereJoinTable)
          : [];
        newEn.parameterList = newEn.parameterList
          ? JSON.parse(newEn.parameterList)
          : [];
        newEn.remark = newEn.remark ? JSON.parse(newEn.remark) : {};

        if (newEn.dataModelEntityFields?.length > 0) {
          newEn.dataModelEntityFields = newEn.dataModelEntityFields.map(
            (field) => {
              field = { ...removeAuthorInfo(field) };
              field.compId = StringUtils.getUuid();
              field.type = Enums.EntityComponentType.ENTITY_FIELD;
              field.fieldOption = field.fieldOption
                ? JSON.parse(field.fieldOption)
                : {};
              return field;
            }
          );
        }
        return newEn;
      });
      dataModel.dataModelEntities = compIdGeneratedEntities;
    }

    return dataModel;
  };
}
export default DataModelService;
