import React from "react";

function UModalTemplate({ className, style, ...props }) {
  return (
    <div style={style} className={`modal-template ${className || ""}`}>
      {props.children}
    </div>
  );
}

export default UModalTemplate;
