import Modal from "components/common/modal/UModal";
import UModalTemplate from "components/common/modal/UModalTemplate";
import StringUtils from "components/common/utils/StringUtils";
import React from "react";
import { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";

function CodeReferencePopup({ codeType, ...props }) {
  const [selectedTab, setSelectedTab] = useState(codeType || "java");

  return (
    <Modal>
      <Modal.Header title="Workflow 코드 작성 가이드" />
      <Modal.Body>
        <UModalTemplate>
          <h4>1. 코드 유형별 예제</h4>
          <p>
            코드의 실행 결과가 Output Object에 할당 되며 코드 유형 별로 결과를
            반환받는 방법은 아래와 같습니다.
          </p>
          <Row className="mb-3">
            <Col xs={2} style={{ display: "flex", alignItems: "center" }}>
              <Form.Label>코드 유형 선택</Form.Label>
            </Col>
            <Col>
              <Form.Select
                value={selectedTab}
                onChange={(e) => setSelectedTab(e.target.value)}
                id={"codeType"}
              >
                <option value={"java"}>Java Expression Language</option>
                <option value={"batch"}>Window Batch Command</option>
                <option value={"shell"}>Linux/Unix Shell Script</option>
              </Form.Select>
            </Col>
          </Row>
          {StringUtils.equalsIgnoreCase(selectedTab, "java") ? (
            <>
              <p>마지막 문장이 실행된 결과가 "Output Object"에 할당됩니다.</p>
              <Form.Label>예제 - 코드</Form.Label>
              <code>
                let message = resultMessage.get("message");
                <br />
                out.println(resultMessage);
                <br />
                {`if(message == null || message.isEmpty()){
    message = [...];
    out.println("초기화 : "+message);
}`}
                <br />
                message.add(callProcedure.get("message"));
                <br />
                out.println("추가후 :"+message); <br />
                ## resultMessage entity의 message field값에 message가 계속
                추가된다. <br />
                resultMessage["message"] = message;
                <br />
                out.println(resultMessage); <br />
                <br />
                ## 마지막 문장의 값이 return 되고 "Output Object"에 assign된다.{" "}
                <br />
                resultMessage;
              </code>
              <Form.Label>예제 - 반환 값 (Output Object)</Form.Label>
              <code>
                # Output Object에 할당되는 값입니다.
                <br />
                resultMessage;
              </code>
              <br />
              <h4>2. 코드의 Jexl 표현식</h4>
              <p>
                코드가 실행 되기전에 일부 코드문자를 Jexl 표현식을 통해 동적으로
                변경 할 수 있습니다.
              </p>
              <p>Expression : {`{\`Jexl 표현식\`}`}</p>
              <code>
                {`//orderEntity.get("qty") * orderEntity.get("unitPrice") 실행 결과가 replace 됩니다. `}
                <br />
                {`//let amount = 2500;`} <br />
                let amount ={" "}
                {`{\`orderEntity.get("qty") * orderEntity.get("unitPrice")\`}`};
              </code>
              <h4>3. Code 참조 내용</h4>
              <Form.Label>Math</Form.Label>
              <code>
                {`// 두 수 중 큰 값 = 10`}
                <br />
                {`Math.max(5, 10);`}
                <br />
                {`// 반올림 메소드`}
                <br />
                {`Math.round(5.7);`}
              </code>
              <div>
                <Button
                  side="left"
                  onClick={(e) => {
                    window.open(
                      "https://www.w3schools.com/java/java_math.asp",
                      "_blank"
                    );
                  }}
                >
                  Java Math Methods
                </Button>
              </div>
              <br />
              <Form.Label>Integer</Form.Label>
              <code>
                {`// 자료형 Int의 최대 값`}
                <br />
                {`Integer.MAX_VALUE();`}
                <br />
                {`// int 자료형 -> String 자료형으로`}
                <br />
                {`var intToString = 10;`}
                <br />
                {`Integer.toString(intToString);`}
              </code>
              <div>
                <Button
                  side="left"
                  onClick={(e) => {
                    window.open(
                      "https://learn.microsoft.com/ko-kr/dotnet/api/java.lang.integer?view=net-android-34.0",
                      "_blank"
                    );
                  }}
                >
                  Java Integer Methods
                </Button>
              </div>
              <br />
              <Form.Label>String</Form.Label>
              <code>
                {`var firstName = "John"`}
                <br />
                {`var lastName = "Doe"`}
                <br />
                {`// firstName.concat(lastName) = John Doe`}
                <br />
                {`// string1.concat(string2) : string1에 string2를 복사하여 이어붙히는 함수`}
                <br />
                {`var name = firstName.concat(lastName);`}
              </code>
              <div>
                <Button
                  side="left"
                  onClick={(e) => {
                    window.open(
                      "https://www.w3schools.com/java/java_ref_string.asp",
                      "_blank"
                    );
                  }}
                >
                  Java String Methods
                </Button>
              </div>
            </>
          ) : StringUtils.equalsIgnoreCase(selectedTab, "batch") ? (
            <>
              <p>
                Batch 실행 시 출력되는 모든 내용이 "Output Object"에 할당됩니다.
              </p>
              <Form.Label>예제 - 코드</Form.Label>
              <code>
                ## 입력된 명령어 중 출력 내용은 모두 합되어 리턴됩니다.
                <br />
                ## 예시1 폴더 위치 이동 cd C:\
                <br />
                <br />
                ## 예시2 출력물이 있는 명령어 입력 ipconfig
                <br />
                <br />
                ## 예시 3 출력물이 있는 명령어 입력 netstat -ano
                <br />
              </code>
              <Form.Label>예제 - 반환 값 (Output Object)</Form.Label>
              <code>
                {`무선 LAN 어댑터 Wi-Fi:
미디어 상태 . . . . . . . . : 미디어 연결 끊김
연결별 DNS 접미사. . . . :
무선 LAN 어댑터 로컬 영역 연결* 1:
미디어 상태 . . . . . . . . : 미디어 연결 끊김 
...(이하생략)

활성 연결

프로토콜 로컬 주소 외부 주소 상태 PID

TCP 0.0.0.0:135 0.0.0.0:0 LISTENING 1012
TCP 0.0.0.0:445 0.0.0.0:0 LISTENING 4
TCP 0.0.0.0:3000 0.0.0.0:0 LISTENING 12532
            
          ...(이하생략)
                `}
              </code>
            </>
          ) : (
            <></>
          )}
        </UModalTemplate>
      </Modal.Body>
      <Modal.Footer>
        {StringUtils.equalsIgnoreCase(selectedTab, "java") ? (
          <Modal.Footer.Button
            side="left"
            onClick={(e) => {
              window.open(
                "https://commons.apache.org/proper/commons-jexl/reference/syntax.html",
                "_blank"
              );
            }}
          >
            JEXL 공식 사이트
          </Modal.Footer.Button>
        ) : (
          <></>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default CodeReferencePopup;
