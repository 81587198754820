/* eslint-disable jsx-a11y/alt-text */
import React, { Fragment } from "react";

import Modal from "components/common/modal/UModal";

import UmodalTemplate from "components/common/modal/UModalTemplate";

const ComboEntityValueSettingGuide = (props) => {
  return (
    <Modal>
      <Modal.Header title={props.title} />
      <Modal.Body>
        <UmodalTemplate>
          <Fragment>
            <h4 id="if--else">콤보(자동완성) 검색값</h4>
            <p>
              "값" 입력란이 공백일 경우 사용자가 Combo에 입력한 값이 조회 조건에
              적용됩니다.
            </p>
            <h4 id="if--else">고정 값 지정</h4>
            <p>
              "값" 입력란에 고정된 문자/숫자를 입력하시면 Combo 조회 조건에
              적용됩니다.
            </p>
            <h4 id="html">Element의 입력된 값 적용</h4>
            <p>
              특정 element의 입력된 값을 Combo 조회 조건에 적용하는 예제는
              다음과 같습니다.<br></br>
              <font color="red">※ 주의</font> : Combo는 다른 Element보다 먼저
              생성 되기 때문에 Combo Data 최초 조회시 다른 elment 값은 Empty가
              될수 있습니다. 따라서 이 기능은 최초 화면 로딩 후 Combo Data를
              조회 하지 않는 <font color="red">자동완성 콤보</font> 에만 적용
              하십시요.
            </p>
            <div>
              <pre>
                <code>
                  SearchForm.$("#roleId") /* SearchForm 내에 있는 roleId
                  element값을 적용한다. */
                </code>
                <code>
                  page.$("#roleId") /* Page내에 있는 roleId element값을
                  적용한다. */
                </code>
              </pre>
            </div>
            <h4 id="html">
              Grid의 특정 Column의 값을 적용 (Grid 자동완성 콤보)
            </h4>
            <p>
              Grid의 특정 Column의 값을 Combo 조회 조건에 적용하는 예제는 다음과
              같습니다.<br></br>
              <font color="red">※ 주의</font> : 이 기능은 최초 화면 (Grid) 로딩
              후 Combo Data를 조회 하지 않는{" "}
              <font color="red">자동완성 콤보</font> 에만 적용 가능합니다.
            </p>
            <div>
              <pre>
                <code>
                  /* Grid column "usrRoleNm" 값을 적용 한다. */<br></br> &#123;
                  "colName" : "usrRoleNm"&#125;
                </code>
              </pre>
            </div>
            <h4 id="each">javascript로 동적 값 할당</h4>
            <p>
              javascript를 통해 특정 값을 Combo 조회 조건에 적용하는 예제는
              다음과 같습니다.
            </p>
            <div>
              <pre>
                <code>(new Date()).getDate(); /* 오늘 일자 */</code>
                <code>
                  page.getParam("roleId") /* Parameter로 넘어온 roleId 값을
                  적용한다. */
                </code>
                <code>
                  function()&#123;<br></br>
                  {"    "}/* roleId 입력값이 ADMIN일경우 'Y' 값 그외 'N'*/
                  <br></br>
                  {"    "}if(SearchForm.$("#roleId") === "ADMIN")&#123;
                  <br></br>
                  {"        "}return "Y"; <br></br>
                  {"    "}&#125;else&#123;
                  <br></br>
                  {"        "}return "N"; <br></br>
                  {"    "}&#125;<br></br>&#125;
                </code>
              </pre>
            </div>
            <h4 id="html">사용자 정보 적용</h4>
            <p>
              다음 사용자 정보는 Framework에서 제공하는 공통 정보이며 각
              Application에서 사용자 Session 정보를 추가 정의 할 수 있습니다.
            </p>
            <div>
              <table className="doc-table" style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th style={{ width: "20%" }}>
                      <div>값명</div>
                    </th>
                    <th>
                      <div>값 설명 &amp; Example</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div>variable.coCd</div>
                    </td>
                    <td>
                      <div>사용자 회사코드</div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div>variable.userId</div>
                    </td>
                    <td>
                      <div>사용자 ID</div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div>variable.userName</div>
                    </td>
                    <td>
                      <div>사용자 명</div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div>variable.language</div>
                    </td>
                    <td>
                      <div>사용자 언어</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Fragment>
        </UmodalTemplate>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default ComboEntityValueSettingGuide;
