import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { Enums } from "components/builder/BuilderEnum";
import Message from "components/common/Message";
import Modal from "components/common/modal/UModal";
import UModalTemplate from "components/common/modal/UModalTemplate";
import Popup from "components/common/Popup";
import StringUtils from "components/common/utils/StringUtils";

import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import AppService from "services/common/AppService";

function AppModuleRegisterPopup({
  appList,
  selectedAppId,
  callBack,
  module,
  ...props
}) {
  const [appId, setAppId] = useState(selectedAppId || "");
  const [moduleCd, setModuleCd] = useState("");
  const [moduleNm, setModuleNm] = useState("");
  const [moduleOptionIn, setModuleOptionIn] = useState("*");
  const [moduleOptionNotIn, setModuleOptionNotIn] = useState("");
  const [isSharedYn, setIsSharedYn] = useState("N");

  useEffect(() => {
    if (module) {
      const mOption = JSON.parse(module.moduleOption);
      setModuleCd(module.moduleCd);
      setModuleNm(module.moduleNm);
      setModuleOptionIn(mOption.in.join(","));
      setModuleOptionNotIn(mOption.notIn.join(","));
      setIsSharedYn(module.isSharedYn);
      setAppId(module.appId);
    }
  }, [module]);

  const onSaveAppModule = (e) => {
    if (e) e.preventDefault();

    if (StringUtils.isEmpty(appId))
      return Message.alert(
        "Application을 선택해주세요.",
        Enums.MessageType.INFO
      );
    if (StringUtils.isEmpty(moduleCd))
      return Message.alert("모듈 코드를 입력해주세요.", Enums.MessageType.INFO);
    if (StringUtils.isEmpty(moduleNm))
      return Message.alert("모듈명을 입력해주세요.", Enums.MessageType.INFO);
    let _in = moduleOptionIn;
    let _notIn = moduleOptionNotIn;
    if (StringUtils.isEmpty(_in)) {
      _in = "*";
    }
    let moduleOption = {
      in: _in.split(","),
      notIn: _notIn.split(","),
    };

    const body = {
      appId,
      moduleCd,
      moduleNm,
      moduleOption: JSON.stringify(moduleOption),
      isSharedYn,
    };

    AppService.saveAppModule(body, (res) => {
      Message.alert(res.message, Enums.MessageType.SUCCESS);
      Popup.close();
      if (callBack) callBack();
    });
  };

  return (
    <Modal>
      <Modal.Header title="신규 모듈 등록" />
      <Modal.Body>
        <UModalTemplate>
          <Form.Group className="mb-3">
            <Form.Label className="required">Application 선택</Form.Label>
            <Form.Select
              value={appId}
              onChange={(e) => setAppId(e.currentTarget.value)}
            >
              <option value="">Application 선택</option>
              {appList.map((app) => {
                return (
                  <option value={app.appId} key={app.appId}>
                    {app.appNm}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="required">모듈 코드</Form.Label>
            <Form.Control
              value={moduleCd}
              onChange={(e) => setModuleCd(e.currentTarget.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="required">모듈 명</Form.Label>
            <Form.Control
              value={moduleNm}
              onChange={(e) => setModuleNm(e.currentTarget.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="required">
              데이터 모델 검색 조건 Prefix 설정{" "}
            </Form.Label>
            <Row className="gy-1">
              <Col xs={3}>
                <Form.Control disabled defaultValue={"포함"} />
              </Col>
              <Col xs={9}>
                <Form.Control
                  value={moduleOptionIn}
                  onChange={(e) => setModuleOptionIn(e.currentTarget.value)}
                  placeholder={"Z,P,H ... / * 입력시 전체 조회 "}
                />
              </Col>
              <Col xs={3}>
                <Form.Control disabled defaultValue={"불포함"} />
              </Col>
              <Col xs={9}>
                <Form.Control
                  value={moduleOptionNotIn}
                  onChange={(e) => setModuleOptionNotIn(e.currentTarget.value)}
                  placeholder={"Z,P,H ... "}
                />
              </Col>
            </Row>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="required">공유 모듈 여부</Form.Label>
            <Row className="gy-1">
              <Col xs={8}></Col>
              <Col xs={4}>
                <BootstrapSwitchButton
                  size="sm"
                  onstyle="primary"
                  offstyle="dark"
                  onlabel="예"
                  offlabel="아니오"
                  width={"80"}
                  checked={isSharedYn === "Y" ? true : false}
                  id={"dataLimit"}
                  onChange={(flag) => {
                    setIsSharedYn(flag ? "Y" : "N");
                  }}
                />
              </Col>
            </Row>
          </Form.Group>
        </UModalTemplate>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Button onClick={onSaveAppModule}>
          저장
        </Modal.Footer.Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AppModuleRegisterPopup;
