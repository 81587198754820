import ActionType from "components/builder/BuilderActionType";

/**
 * 이벤트핸들러 초기화
 * @returns
 */
export const initEventhandler = () => {
  return {
    type: ActionType.EVENT_HANDLER.INIT,
  };
};

/**
 * 이벤트핸들러 화면 업데이트
 * @param {*} workspace
 * @returns
 */
export const updateEventWorkspace = (workspace) => {
  return {
    type: ActionType.EVENT_HANDLER.WORKSPACE.UPDATE,
    payload: workspace,
  };
};

/**
 * 이벤트핸들러 전체 업데이트
 * @param {*} 이벤트 핸들러 오브젝트
 * @returns
 */
export const updateEventHandler = (object) => {
  return {
    type: ActionType.EVENT_HANDLER.UPDATE_HANDLER,
    payload: object,
  };
};

/**
 * 이벤트핸들러 아웃풋 (메서드 코드) 업데이트
 * @param {*} eventhandler
 * @returns
 */
export const updateEventOutput = (output) => {
  return {
    type: ActionType.EVENT_HANDLER.UPDATE_OUTPUT,
    payload: output,
  };
};

/**
 * 이벤트핸들러 변수 업데이트
 * @param {*}
 * @returns
 */
export const updateEventVariables = (newVar) => {
  return {
    type: ActionType.EVENT_HANDLER.UPDATE_VARIABLES,
    payload: newVar,
  };
};
/**
 * 이벤트핸들러 event workspace 경로 업데이트
 * @param {*}
 * @returns
 */
export const updateEventWorkspacePath = (newCompInfo) => {
  return {
    type: ActionType.EVENT_HANDLER.UPDATE_COMPONENT_PROP,
    payload: newCompInfo,
  };
};

/**
 * 이벤트 워크스페이스 최초 이닛
 * @returns
 */
export const initEventHandler = () => {
  return {
    type: ActionType.EVENT_HANDLER.WORKSPACE.INIT,
  };
};

/**
 * 브로콜리가 최초 세팅이 되었는지 여부
 * 이거 안하면 화면 불러올때마다 초기화함
 * @param {*} flag
 * @returns
 */
export const setIsInitEventWorkspace = (flag) => {
  return {
    type: ActionType.EVENT_HANDLER.WORKSPACE.SET_IS_INIT,
    payload: flag,
  };
};
