import Api from "components/common/Api";
/**
 * 사용자 로그인 , 회원가입, 저장 및 조회
 * **/
class UserService {
  static login(data, callbackFnc) {
    Api.post("/common/login", data, callbackFnc);
  }
  static register(data, callbackFnc) {
    Api.post("/common/register", data, callbackFnc);
  }
  static checkDuplicate(data, callbackFnc) {
    Api.post("/common/checkDuplicate", data, callbackFnc);
  }
  static logout(data, callbackFnc) {
    Api.get("/common/logout", data, callbackFnc);
  }
  static getUserList(data, callbackFnc) {
    const body = {
      ...data,
      exceptSuper: data.exceptSuper ? data.exceptSuper : "Y",
    };
    Api.post("/common/getUserList", body, callbackFnc);
  }
  static getAllUserList(data, callbackFnc) {
    const body = {
      ...data,
      exceptSuper: data.exceptSuper ? data.exceptSuper : "Y",
    };
    Api.post("/common/getAllUserList", body, callbackFnc);
  }
  static getUser(data, callbackFnc) {
    Api.post("/common/getUser", data, callbackFnc);
  }
  static update(data, callbackFnc) {
    Api.post("/common/updateUser", data, callbackFnc);
  }
  static delete(data, callbackFnc) {
    Api.post("/common/deleteUser", data, callbackFnc);
  }
  static approvalUser(data, callbackFnc) {
    Api.post("/common/approvalUser", data, callbackFnc);
  }
  static updateList(data, callbackFnc) {
    Api.post("/common/updateUserList", data, callbackFnc);
  }
  static SSOLogin(data, callbackFnc, errCallbackFnc) {
    Api.post("/common/ssoLogin", data, callbackFnc);
  }

  static initalPassword(data, callbackFnc, errCallbackFnc) {
    Api.post("/common/initalPassword", data, callbackFnc);
  }
}
export default UserService;
