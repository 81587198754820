import React from "react";
import { Tabs, Tab } from "react-bootstrap";
import styled from "styled-components";

import Modal from "components/common/modal/UModal";
import PopupPreHandleConfigPopup from "./PopupPreHandleConfigPopup";
import PopupPostHandleConfigPopup from "./PopupPostHandleConfigPopup";

const PopupStyle = styled.div`
  .edit-source {
    border: 1px solid #ddd;
  }
  .tab-pane .modal-header {
    display: none;
  }
  .modal-body:not(.tab-pane .modal-body) {
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
  }

  .nav-item button:not(.active),
  .nav-item button:not(.active):focus {
    color: #333;
  }

  .nav-item button:hover {
    background: #f7f7f7;
  }
`;

/**
 * PopupOpenConfigPopup : Popup 전처리
 *
 * 2022.04.28: init: songe.park
 *
 * @param {*} props
 * @returns value
 */
const PopupHandleConfigPopup = (props) => {
  const {
    title,
    id,
    callbackFnc,
    item,
    entityId,
    data,
    workspace,
    onClickEventBuilder,
  } = props;

  let items = {};

  const setRetItems = (id, value) => {
    items = { ...items, [id]: value };
  };

  const closePopup = (backItems) => {
    callbackFnc.call(this, backItems);
  };

  return (
    <PopupStyle>
      <Modal>
        <Modal.Header title={title} />
        <Modal.Body>
          <Tabs justify defaultActiveKey="tab-pre" id="toolbar-tabs">
            <Tab eventKey="tab-pre" title="전 처리" id="tab-pre">
              <PopupPreHandleConfigPopup
                workspace={workspace}
                fieldType="json"
                item={item}
                data={data}
                entityId={entityId}
                callbackFnc={closePopup}
                setRetItems={setRetItems}
                onClickEventBuilder={onClickEventBuilder}
                getEventInfo={props.getEventInfo}
              />
            </Tab>
            <Tab eventKey="tab-post" title="후 처리" id="tab-post">
              <PopupPostHandleConfigPopup
                fieldType="json"
                item={item}
                type={id}
                data={data}
                entityId={entityId}
                callbackFnc={closePopup}
                setRetItems={setRetItems}
                onClickEventBuilder={onClickEventBuilder}
                getEventInfo={props.getEventInfo}
              />
            </Tab>
          </Tabs>
        </Modal.Body>
      </Modal>
    </PopupStyle>
  );
};

export default PopupHandleConfigPopup;
