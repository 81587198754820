import { useRef, useEffect } from "react";

function useInterval(callback, delay, play) {
  const savedCallback = useRef();
  const intervalId = useRef("");
  // Remember the latest function.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      if (play) {
        intervalId.current = setInterval(tick, delay);
      } else {
        clearTimeout(intervalId.current);
      }

      return () => clearInterval(intervalId.current);
    }
  }, [delay, play]);
}

export default useInterval;
