import React, { useContext, useEffect, useState } from "react";
import {
  PropertiesHeader,
  PropertyLable,
  PropertyValue,
} from "components/builder/entity/entityComponents/EntityComponentStyle";
import { debounce } from "components/common/utils/InputUtils";
import { useSelector } from "react-redux";
import { Accordion, Button, Overlay, Popover } from "react-bootstrap";
import ComponentService from "services/datamodel/DataModelService";
import User from "components/common/utils/UserUtils";
import Message from "components/common/Message";
import { Enums } from "components/builder/BuilderEnum";
import { CircularProgress, Tooltip } from "@mui/material";
import { AiOutlineFunction, AiOutlineSync } from "react-icons/ai";
import ObjectUtils from "components/common/utils/ObjectUtils";
import { AppContext } from "components/common/AppContextProvider";
import * as icon from "components/common/icon/UIcons";
import StringUtils from "components/common/utils/StringUtils";
import { BsTable } from "react-icons/bs";
import { MdPreview } from "react-icons/md";
import { HiOutlineDatabase } from "react-icons/hi";
import produce from "immer";

function DynamicApiTab() {
  const { dataModelEntities } = useSelector((state) => state.outputENT.output);
  const {
    dataModel: { list, setList, setType, type },
    // connection: { Info: connection },
  } = useContext(AppContext);
  const workspace = useSelector((state) => state.workspace);

  const [DPTableList, setDPTableList] = useState({});
  const [searchTableNm, setSearchTableNm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [helperPopup, setHelperPopup] = useState({
    popoverShow: false,
    popoverTarget: null,
    popoverContents: <></>,
  });

  useEffect(() => {
    if (!ObjectUtils.isEmpty(workspace)) {
      getDataModelList();
    }
  }, [workspace]);

  useEffect(() => {
    if (dataModelEntities && dataModelEntities.length > 0) {
      const filtered = list.filter(
        (table) =>
          table.tableNm.toLowerCase().indexOf(searchTableNm.toLowerCase()) > -1
      );

      categorizeList(filtered);
    } else {
      categorizeList(list);
    }
  }, [dataModelEntities]);

  const onHelperClick = (e, popoverContents) => {
    if (helperPopup.popoverShow && helperPopup.popoverTarget === e.target) {
      setHelperPopup({
        popoverShow: false,
        popoverTarget: e.target,
        popoverContents: <></>,
      });
    } else {
      setHelperPopup({
        popoverShow: true,
        popoverTarget: e.target,
        popoverContents,
      });
    }
  };
  /**
   * 테이블 , 뷰, 시노님 카테고리 분류
   * @param {Array} list
   */
  const categorizeList = (list) => {
    const tableList = list.filter((t) =>
      StringUtils.equalsIgnoreCase(t.entityType, "TABLE")
    );
    const viewList = list.filter((t) =>
      StringUtils.equalsIgnoreCase(t.entityType, "VIEW")
    );
    const synonymList = list.filter((t) =>
      StringUtils.equalsIgnoreCase(t.entityType, "SYNONYM")
    );
    const procedureList = list.filter((t) =>
      StringUtils.equalsIgnoreCase(t.entityType, "PROCEDURE")
    );
    const functionList = list.filter((t) =>
      StringUtils.equalsIgnoreCase(t.entityType, "FUNCTION")
    );
    setDPTableList({
      TABLE: tableList,
      VIEW: viewList,
      SYNONYM: synonymList,
      PROCEDURE: procedureList,
      FUNCTION: functionList,
    });
  };

  const getDataModelList = () => {
    const connection = User.getConnection(workspace.tenantMstId);
    if (!connection?.token) return false;
    getList({ refresh: false });
  };

  const debounceType = debounce((value) => {
    //테이블 필터링
    const newList = [...list].filter(
      (_table) => _table.tableNm.toLowerCase().indexOf(value.toLowerCase()) > -1
    );
    categorizeList(newList);
  }, 300);

  const onChangeTableNm = (e) => {
    setSearchTableNm(e.currentTarget.value);
    debounceType(e.currentTarget.value);
  };

  const getList = ({ refresh }) => {
    const connection = User.getConnection(workspace.tenantMstId);
    if (connection?.expired)
      return Message.alert("만료된 토큰입니다.", Enums.MessageType.ERROR);
    if (!connection?.token) {
      return Message.alert(
        "인증 서버 토큰이 확인 되지 않았습니다.\n 다시 연결 해주세요.",
        Enums.MessageType.ERROR
      );
    }
    setIsLoading(true);

    const _method = (callback, data) => {
      callback(
        data,
        (res) => {
          if (!res.isError) {
            categorizeList(res.data || []);
            setList(res.data || []);
            setType("D");
            setIsLoading(false);
          }
        },
        (err) => {
          setIsLoading(false);
        }
      );
    };

    if (connection.connectionType === "direct") {
      const body = {
        connectionInfo: connection,
        moduleCd: workspace.moduleCd,
        searchModule: JSON.stringify(workspace.moduleOption),
        coCd: workspace.coCd,
        tenantId: workspace.tenantId,
      };
      _method(ComponentService.getDirectTableList, body);
    } else {
      const body = {
        accessToken: connection.token,
        ...connection,
        moduleCd: workspace.moduleCd,
        appId: workspace.appId,
        refresh: refresh ? "Y" : "N",
      };
      _method(ComponentService.getTableList, body);
    }
  };

  return (
    <div className="table-list">
      {/* Tables tab 소스가 들어가는 부분 */}
      <PropertiesHeader>
        <div>
          테이블 목록 &nbsp;
          <Button
            variant="outline-secondary"
            size="sm"
            className="light-font-color"
            onMouseLeave={(e) => {
              setHelperPopup(
                produce(helperPopup, (draft) => {
                  draft.popoverShow = false;
                  draft.popoverTarget = e.target;
                })
              );
            }}
            onMouseOver={(e) => {
              setHelperPopup({
                popoverShow: true,
                popoverTarget: e.target,
                popoverContents: (
                  <ul>
                    <strong>▣ 조회 조건 </strong>
                    <li>
                      포함 Prefix :{" "}
                      <strong>{workspace.moduleOption.in.join(" , ")}</strong>
                    </li>
                    <li>
                      불포함 Prefix :{" "}
                      <strong>
                        {workspace.moduleOption.notIn.join(" , ")}
                      </strong>
                    </li>
                  </ul>
                ),
              });
            }}
          >
            조회 조건
          </Button>
        </div>

        <div style={{ display: "flex", gap: "10px" }}>
          <Button
            variant="outline-secondary"
            size="sm"
            className="light-font-color"
            onClick={() => getList({ refresh: false })}
            disabled={isLoading}
          >
            데이터 조회
          </Button>
          <Button
            variant="outline-secondary"
            size="sm"
            className="light-font-color"
            onClick={() => {
              Message.confirm(
                "Entity 목록을 최신 정보로 갱신합니다.\n 테이블의 양에 따라 시간이 오래 걸릴 수도 있습니다. \n 진행하시겠습니까?",
                () => {
                  getList({ refresh: true });
                }
              );
            }}
            disabled={isLoading}
          >
            {isLoading ? (
              <>
                <CircularProgress color="inherit" size={13} />
              </>
            ) : (
              <Tooltip title="데이터 싱크" placement="left">
                <span>
                  <AiOutlineSync color="inherit" size={13} />
                </span>
              </Tooltip>
            )}
          </Button>
        </div>

        <Overlay
          show={helperPopup.popoverShow}
          target={helperPopup.popoverTarget}
          placement="bottom"
          containerPadding={20}
        >
          <Popover id="popover-contained">
            <Popover.Header as="h3">도움말</Popover.Header>
            <Popover.Body>{helperPopup.popoverContents}</Popover.Body>
          </Popover>
        </Overlay>
      </PropertiesHeader>
      <div className="table-filter">
        <PropertyLable
          style={{
            textAlign: "center",
          }}
        >
          테이블 명
        </PropertyLable>
        <PropertyValue>
          <input
            type="text"
            id="programName"
            className="form-control form-control-sm"
            placeholder="테이블 명 입력"
            value={searchTableNm}
            onChange={onChangeTableNm}
          />
        </PropertyValue>
      </div>
      <Accordion alwaysOpen defaultActiveKey={["TABLE"]}>
        {Object.keys(DPTableList).map((key) => {
          return (
            <Accordion.Item eventKey={key} key={key}>
              <Accordion.Header>
                {key} ({DPTableList[key] ? DPTableList[key].length : 0} 개)
              </Accordion.Header>
              <Accordion.Body>
                {DPTableList[key].map((table) => {
                  return (
                    <DraggableEntityContents key={table.tableNm} data={table} />
                  );
                })}
              </Accordion.Body>
            </Accordion.Item>
          );
        })}
      </Accordion>
    </div>
  );
}

export default DynamicApiTab;

export const DraggableEntityContents = ({ data, ...props }) => {
  const onDragStart = (event) => {
    if (
      StringUtils.equalsIgnoreCase(data.entityType, "PROCEDURE") ||
      StringUtils.equalsIgnoreCase(data.entityType, "FUNCTION")
    ) {
      data.type = Enums.EntityComponentType.ENTITY;
      data.compId = StringUtils.getUuid();
    } else {
      data.type = Enums.EntityComponentType.ENTITY;
      data.compId = StringUtils.getUuid();
    }
    const StringData = JSON.stringify(data);
    event.dataTransfer.setData("application/reactflow", StringData);
    event.dataTransfer.effectAllowed = "move";
  };

  const DraggableIcon = StringUtils.equalsIgnoreCase(
    data.entityType,
    "TABLE"
  ) ? (
    <BsTable size={20} />
  ) : StringUtils.equalsIgnoreCase(data.entityType, "VIEW") ? (
    <MdPreview size={20} />
  ) : StringUtils.equalsIgnoreCase(data.entityType, "SYNONYM") ? (
    <BsTable size={20} color="lime" />
  ) : StringUtils.equalsIgnoreCase(data.entityType, "PROCEDURE") ? (
    <HiOutlineDatabase size={20} />
  ) : StringUtils.equalsIgnoreCase(data.entityType, "FUNCTION") ? (
    <AiOutlineFunction size={20} />
  ) : (
    <></>
  );
  return (
    <div onDragStart={onDragStart} draggable className="sidebar-item">
      <div
        className=""
        style={{
          display: "flex",
          alignItems: "center",
          marginRight: "10px",
          maxWidth: "21px",
        }}
      >
        {DraggableIcon}
      </div>
      <div
        style={{
          width: "calc(100% - 21px)",
          textOverflow: "ellipsis",
          whiteSpace: "pre",
          overflow: "hidden",
        }}
      >
        {data.tableNm}
      </div>
    </div>
  );
};
