import ActionType from "components/builder/BuilderActionType";

export function setSidebarCollapse(flag) {
  return {
    type: ActionType.MENU.SET_SIDEBAR_COLLAPSE,
    payload: flag,
  };
}

export function initMenu() {
  return {
    type: ActionType.MENU.INIT,
  };
}

export function setProgramSearchTerm(body) {
  return {
    type: ActionType.MENU.SEARCH_TERM.PROGRAM,
    payload: body,
  };
}

export function setDataModelSearchTerm(body) {
  return {
    type: ActionType.MENU.SEARCH_TERM.DATA_MODEL,
    payload: body,
  };
}

export function setWorkflowSearchTerm(body) {
  return {
    type: ActionType.MENU.SEARCH_TERM.WORKFLOW,
    payload: body,
  };
}
