import { Enums } from "components/builder/BuilderEnum";
import Message from "components/common/Message";
import Modal from "components/common/modal/UModal";
import UmodalTemplate from "components/common/modal/UModalTemplate";
import Popup from "components/common/Popup";
import ArrayUtils from "components/common/utils/ArrayUtils";
import StringUtils from "components/common/utils/StringUtils";
import produce from "immer";
import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import AppService from "services/common/AppService";

function AppRegisterPopup({ app, appTypeList = [], saveCallback, ...props }) {
  const [appNm, setAppNm] = useState(app ? app.appNm : "");
  const [appDesc, setAppDesc] = useState(app ? app.appDesc : "");
  const [appGroupCd, setAppGroupCd] = useState(app ? app.appGroupCd : "");
  const [config, setConfig] = useState(app ? app.config : []);
  const [configDefault, setConfigDefault] = useState([]);

  useEffect(() => {
    console.log(config);

    getAppDefaultConfig();
  }, []);

  const onSave = (e) => {
    if (e) e.preventDefault();

    const body = {
      ...app,
      appNm,
      appDesc,
      appGroupCd,
      config,
      useYn: "Y",
    };

    AppService.saveApplication(body, (res) => {
      Popup.close();
      Message.alert(`${appNm} 앱이 등록되었습니다.`, Enums.MessageType.SUCCESS);
      if (saveCallback) {
        saveCallback(res.data);
      }
    });
  };

  const getAppDefaultConfig = () => {
    AppService.getAppDefaultConfig({}, (res) => {
      setConfigDefault(res.data);
    });
  };

  const setConfigValue = (value, index) => {
    setConfig(
      produce(config, (draft) => {
        draft[index].configValue = value;
      })
    );
  };

  const renderConfigOption = (con, index) => {
    //설정 호출
    const optionDefaultConfiguration = configDefault.find(
      (c) => c.configCd === con.configCd
    );
    //설정 없으면 리턴 처리
    if (!optionDefaultConfiguration) return <></>;

    //기 설정된 값 확인
    const configValue = config.find(
      (conf) => conf.configCd === con.configCd
    )?.configValue;

    if (
      StringUtils.equalsIgnoreCase(
        optionDefaultConfiguration.inputType,
        "select"
      )
    ) {
      const inputOption = JSON.parse(optionDefaultConfiguration.inputOption);
      return (
        <Form.Select
          id={optionDefaultConfiguration.configCd}
          value={configValue}
          defaultValue={optionDefaultConfiguration.configValue}
          onChange={(e) => setConfigValue(e.target.value, index)}
        >
          {inputOption.map((option, index) => {
            return (
              <option value={option.value} key={index}>
                {option.label}
              </option>
            );
          })}
        </Form.Select>
      );
    } else if (
      StringUtils.equalsIgnoreCase(
        optionDefaultConfiguration.inputType,
        "input"
      )
    ) {
      return (
        <Form.Control
          id={optionDefaultConfiguration.configCd}
          value={configValue || optionDefaultConfiguration.configValue}
          disabled
          onChange={(e) => setConfigValue(e.target.value, index)}
        />
      );
    }
  };

  return (
    <Modal>
      <Modal.Header title="신규 Application 등록" />
      <Modal.Body>
        <UmodalTemplate>
          <Row>
            <Form.Group as={Col} xs={12} className="mb-3">
              <Form.Label className="required">
                Application Type 선택
              </Form.Label>
              <Form.Select
                value={appGroupCd}
                onChange={(e) => setAppGroupCd(e.target.value)}
              >
                <option>선택</option>
                {appTypeList.map((app) => {
                  return (
                    <option value={app.id} key={app.id}>
                      {app.text}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
            <Form.Group as={Col} xs={12} className="mb-3">
              <Form.Label className="required">
                Application 이름 입력
              </Form.Label>
              <Form.Control
                value={appNm}
                onChange={(e) => setAppNm(e.target.value)}
              />
            </Form.Group>

            <Form.Group as={Col} xs={12} className="mb-3">
              <Form.Label>Application 설명 입력</Form.Label>
              <Form.Control
                as={"textarea"}
                rows={3}
                style={{ resize: "none" }}
                value={appDesc}
                onChange={(e) => setAppDesc(e.target.value)}
              />
            </Form.Group>
            {config.map((con, index) => {
              return (
                <Form.Group
                  as={Col}
                  xs={12}
                  className="mb-3"
                  key={con.appConfigDefaultId}
                >
                  <Form.Label
                    className={
                      StringUtils.equalsIgnoreCase(con.notNullYn, "Y")
                        ? "required"
                        : ""
                    }
                  >
                    {con.configDesc}
                  </Form.Label>
                  {renderConfigOption(con, index)}
                  {/* <Form.Select
                    id={con.configCd}
                    value={con.configValue}
                    onChange={(e) => setConfigValue(e.target.value)}
                  >
                    <option value="N">허가된 인원만 접근</option>
                    <option value="Y">모두에게 허용</option>
                  </Form.Select> */}
                </Form.Group>
              );
            })}
          </Row>
        </UmodalTemplate>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Button onClick={onSave}>저장</Modal.Footer.Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AppRegisterPopup;
