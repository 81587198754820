/*!
 * Builder Detect activation Component Reducer for react v.17
 *
 * 현재 Activation 되어있는 Component/Layout을 감지하기 이한 Reducer
 *
 *   Author: Bizentro
 *   Date: 2021-04
 */

import ActionType from "components/builder/BuilderActionType";
import produce from "immer";

const initialState = {
  component: {},
  treeNodeIds: [],
  treeOpen: false,
};

/**
 * UI Builder에서 component item을 active , deactive 하여 다른 component(프로그램)에서 active,deactive 된 component를 감지하여 re-redering토록함.
 * action type subfix는 UI (API Builder : API, Report Builder : RPT)
 *
 * @param {*} state
 * @param {*} action
 * @returns
 */
export default function DetectActivedComponent(state = initialState, action) {
  switch (action.type) {
    case ActionType.UI.ACTIVATE_UI: {
      return {
        ...state,
        component: action.component,
      };
    }
    case ActionType.UI.UPDATE_ACTIVATE_PROPS_UI: {
      return produce(state, (draft) => {
        draft.component.propertyValue = action.propertyValue;
      });
    }
    case ActionType.UI.UPDATE_ACTIVATE_EDITOR_ATTR: {
      return produce(state, (draft) => {
        draft.component.editorAttr = action.payload;
      });
    }
    case ActionType.UI.DEACTIVATE_UI: {
      return {
        ...state,
        component: {},
      };
    }
    case ActionType.UI.INIT_ACTIVE_UI: {
      return initialState;
    }
    case ActionType.UI.SET_BUILDER_TREE_NODE_ID: {
      return {
        ...state,
        treeNodeIds: [...action.payload],
      };
    }
    case ActionType.UI.SET_TREE_OPEN: {
      return produce(state, (draft) => {
        draft.treeOpen = action.payload;
      });
    }
    default:
      return state;
  }
}
