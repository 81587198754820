export function setSearchConsoleCollapse(flag) {
  return {
    type: "set_search_console_collapse",
    payload: flag,
  };
}

export function setSearchConsoleHidden(flag) {
  return {
    type: "set_search_console_hidden",
    payload: flag,
  };
}

export function setSearchList(list) {
  return {
    type: "set_search_list",
    payload: list,
  };
}

export function setSearchType(type) {
  return {
    type: "set_search_type",
    payload: type,
  };
}

export function setSearchTerm(term) {
  return {
    type: "set_search_term",
    payload: term,
  };
}

export function setSearchOptions(options) {
  return {
    type: "set_search_options",
    payload: options,
  };
}

export function initSearch() {
  return {
    type: "init_search_console",
  };
}
