import Blockly from "blockly";
import { childComboType } from "../blockExtension/BlockRegisterExtension";

/**
 * Serializes the blocks of the given workspace.
 *
 * @param workspace The workspace to save the blocks of.
 * @returns The state of the workspace's blocks, or null if there are no
 *     blocks.
 */
Blockly.serialization.blocks.BlockSerializer.prototype.save = function (
  workspace
) {
  const blockStates = [];
  for (const block of workspace.getTopBlocks(false)) {
    const state = Blockly.serialization.blocks.save(block, {
      addCoordinates: true,
      doFullSerialization: false,
    });
    if (state) {
      blockStates.push(state);
    }
  }
  if (blockStates.length) {
    return {
      languageVersion: 0, // Currently unused.
      blocks: blockStates,
    };
  }
  return null;
};

/**
 * Returns the state of the given block as a plain JavaScript object.
 *
 * @param block The block to serialize.
 * @param param1 addCoordinates: If true, the coordinates of the block are added
 *     to the serialized state. False by default. addinputBlocks: If true,
 *     children of the block which are connected to inputs will be serialized.
 *     True by default. addNextBlocks: If true, children of the block which are
 *     connected to the block's next connection (if it exists) will be
 *     serialized. True by default. doFullSerialization: If true, fields that
 *     normally just save a reference to some external state (eg variables) will
 *     instead serialize all of the info about that state. This supports
 *     deserializing the block into a workspace where that state doesn't yet
 *     exist. True by default.
 * @returns The serialized state of the block, or null if the block could not be
 *     serialied (eg it was an insertion marker).
 */
Blockly.serialization.blocks.save = function (
  block,
  {
    addCoordinates = false,
    addInputBlocks = true,
    addNextBlocks = true,
    doFullSerialization = true,
    saveIds = true,
  } = {}
) {
  if (block.isInsertionMarker()) {
    return null;
  }
  const state = {
    type: block.type,
    id: saveIds ? block.id : undefined,
  };

  if (addCoordinates) {
    // AnyDuringMigration because:  Argument of type '{ type: string; id:
    // string; }' is not assignable to parameter of type 'State'.
    saveCoords(block, state);
  }
  // AnyDuringMigration because:  Argument of type '{ type: string; id: string;
  // }' is not assignable to parameter of type 'State'.
  saveAttributes(block, state);
  // AnyDuringMigration because:  Argument of type '{ type: string; id: string;
  // }' is not assignable to parameter of type 'State'.
  saveExtraState(block, state, doFullSerialization);
  // AnyDuringMigration because:  Argument of type '{ type: string; id: string;
  // }' is not assignable to parameter of type 'State'.
  saveIcons(block, state, doFullSerialization);
  // AnyDuringMigration because:  Argument of type '{ type: string; id: string;
  // }' is not assignable to parameter of type 'State'.
  saveFields(block, state, doFullSerialization);
  if (addInputBlocks) {
    // AnyDuringMigration because:  Argument of type '{ type: string; id:
    // string; }' is not assignable to parameter of type 'State'.
    saveInputBlocks(block, state, doFullSerialization, saveIds);
  }
  if (addNextBlocks) {
    // AnyDuringMigration because:  Argument of type '{ type: string; id:
    // string; }' is not assignable to parameter of type 'State'.
    saveNextBlocks(block, state, doFullSerialization, saveIds);
  }

  saveDropDownOptions(block, state);

  // AnyDuringMigration because:  Type '{ type: string; id: string; }' is not
  // assignable to type 'State'.
  return state;
};

/**
 * Adds attributes to the given state object based on the state of the block.
 * Eg collapsed, disabled, inline, etc.
 *
 * @param block The block to base the attributes on.
 * @param state The state object to append to.
 */
const saveAttributes = (block, state) => {
  if (block.isCollapsed()) {
    state["collapsed"] = true;
  }
  if (!block.isEnabled()) {
    state["enabled"] = false;
  }
  if (!block.isOwnDeletable()) {
    state["deletable"] = false;
  }
  if (!block.isOwnMovable()) {
    state["movable"] = false;
  }
  if (!block.isOwnEditable()) {
    state["editable"] = false;
  }
  if (
    block.inputsInline !== undefined &&
    block.inputsInline !== block.inputsInlineDefault
  ) {
    state["inline"] = block.inputsInline;
  }
  // Data is a nullable string, so we don't need to worry about falsy values.
  if (block.data) {
    state["data"] = block.data;
  }
};

/**
 * Adds the coordinates of the given block to the given state object.
 *
 * @param block The block to base the coordinates on.
 * @param state The state object to append to.
 */
const saveCoords = (block, state) => {
  const workspace = block.workspace;
  const xy = block.getRelativeToSurfaceXY();
  state["x"] = Math.round(workspace.RTL ? workspace.getWidth() - xy.x : xy.x);
  state["y"] = Math.round(xy.y);
};

/**
 * Adds any extra state the block may provide to the given state object.
 *
 * @param block The block to serialize the extra state of.
 * @param state The state object to append to.
 * @param doFullSerialization Whether or not to serialize the full state of the
 *     extra state (rather than possibly saving a reference to some state).
 */
const saveExtraState = (block, state, doFullSerialization) => {
  if (block.saveExtraState) {
    const extraState = block.saveExtraState(doFullSerialization);
    if (extraState !== null) {
      state["extraState"] = extraState;
    }
  } else if (block.mutationToDom) {
    const extraState = block.mutationToDom();
    if (extraState !== null) {
      state["extraState"] = Blockly.Xml.domToText(extraState).replace(
        ' xmlns="https://developers.google.com/blockly/xml"',
        ""
      );
    }
  }
};

/**
 * Adds the state of all of the icons on the block to the given state object.
 *
 * @param block The block to serialize the icon state of.
 * @param state The state object to append to.
 * @param doFullSerialization Whether or not to serialize the full state of the
 *     icon (rather than possibly saving a reference to some state).
 */
const saveIcons = (block, state, doFullSerialization) => {
  const icons = Object.create(null);
  for (const icon of block.getIcons()) {
    if (Blockly.isSerializable(icon)) {
      const state = icon.saveState(doFullSerialization);
      if (state) icons[icon.getType().toString()] = state;
      if (icon.savePartCollapsed) {
        const partCollapsed = icon.savePartCollapsed();
        icons["partCollapsed"] = partCollapsed;
      }
    }
  }

  if (Object.keys(icons).length) {
    state["icons"] = icons;
  }
};

/**
 * Adds the state of all of the fields on the block to the given state object.
 *
 * @param block The block to serialize the field state of.
 * @param state The state object to append to.
 * @param doFullSerialization Whether or not to serialize the full state of the
 *     field (rather than possibly saving a reference to some state).
 */
const saveFields = (block, state, doFullSerialization) => {
  const fields = Object.create(null);
  for (let i = 0; i < block.inputList.length; i++) {
    const input = block.inputList[i];
    for (let j = 0; j < input.fieldRow.length; j++) {
      const field = input.fieldRow[j];
      if (field.isSerializable()) {
        fields[field.name] = field.saveState(doFullSerialization);
      }
    }
  }
  if (Object.keys(fields).length) {
    state["fields"] = fields;
  }
};

/**
 * Adds the state of all of the child blocks of the given block (which are
 * connected to inputs) to the given state object.
 *
 * @param block The block to serialize the input blocks of.
 * @param state The state object to append to.
 * @param doFullSerialization Whether or not to do full serialization.
 */
const saveInputBlocks = (block, state, doFullSerialization, saveIds) => {
  const inputs = Object.create(null);
  for (let i = 0; i < block.inputList.length; i++) {
    const input = block.inputList[i];
    if (!input.connection) continue;
    const connectionState = saveConnection(
      input.connection,
      doFullSerialization,
      saveIds
    );
    if (connectionState) {
      inputs[input.name] = connectionState;
    }
  }

  if (Object.keys(inputs).length) {
    state["inputs"] = inputs;
  }
};

/**
 * Adds the state of all of the next blocks of the given block to the given
 * state object.
 *
 * @param block The block to serialize the next blocks of.
 * @param state The state object to append to.
 * @param doFullSerialization Whether or not to do full serialization.
 */
const saveNextBlocks = (block, state, doFullSerialization, saveIds) => {
  if (!block.nextConnection) {
    return;
  }
  const connectionState = saveConnection(
    block.nextConnection,
    doFullSerialization,
    saveIds
  );
  if (connectionState) {
    state["next"] = connectionState;
  }
};

/**
 * Returns the state of the given connection (ie the state of any connected
 * shadow or real blocks).
 *
 * @param connection The connection to serialize the connected blocks of.
 * @returns An object containing the state of any connected shadow block, or any
 *     connected real block.
 * @param doFullSerialization Whether or not to do full serialization.
 */
const saveConnection = (connection, doFullSerialization, saveIds) => {
  const shadow = connection.getShadowState(true);
  const child = connection.targetBlock();
  if (!shadow && !child) {
    return null;
  }
  const state = Object.create(null);
  if (shadow) {
    state["shadow"] = shadow;
  }
  if (child && !child.isShadow()) {
    state["block"] = Blockly.serialization.blocks.save(child, {
      doFullSerialization,
      saveIds,
    });
  }
  return state;
};

/**
 * dropdown의 options 저장
 * @param {*} block
 * @param {*} state
 */
const saveDropDownOptions = (block, state) => {
  childComboType.map((fieldName) => {
    if (block.getField(fieldName) && block.saveBlockDropdownContext) {
      state[fieldName] = block.saveBlockDropdownContext(fieldName);
    }
  });
};
