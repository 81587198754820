import UIComponent from "components/builder/ui/uiComponents/UIComponent";
import {
  PropertyLable,
  PropertyValue,
} from "components/builder/ui/uiComponents/UIComponentStyle";
import { Accordion, Form, InputGroup } from "react-bootstrap";
import React, { Fragment } from "react";

import {
  JsonUtils,
  ObjectUtils,
  StringUtils,
} from "components/common/utils/CommonUtils";
import UIComponentSection from "components/builder/ui/editor/UIComponentSection";
import UTextarea from "components/common/element/UTextarea";
import USelectbox from "components/common/element/USelectbox";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import UInputPopup from "components/common/element/UInputPopup";
import UPopover from "components/common/element/UPopover";

class AMChart3Component extends UIComponent {
  constructor(props) {
    super(props);
  }

  onChangeCode = (e) => {};

  /**
   * Properties tab panel을 Redering
   * @returns
   */
  renderPropertiesPanel = () => {
    return (
      <React.Fragment>
        {/* Title */}
        {this.renderComponentTitle("Chart")}
        <Accordion defaultActiveKey={[0, 1]} alwaysOpen>
          <Accordion.Item eventKey={0}>
            <Accordion.Header>기본정보</Accordion.Header>
            <Accordion.Body>
              <PropertyLable>ID</PropertyLable>
              <PropertyValue>
                <input
                  type="text"
                  id="id"
                  defaultValue={StringUtils.defaultString(
                    this.state.propertyValue.id
                  )}
                  className="form-control form-control-sm"
                  onBlur={this.onChange}
                />
              </PropertyValue>
              <PropertyLable>Code</PropertyLable>
              <PropertyValue>
                <UTextarea
                  popTitle="AMCHARTS3 Code"
                  textareaId="code"
                  defaultValue={StringUtils.defaultString(
                    this.state.propertyValue.code
                  )}
                  // fieldType="json"
                  jsonValidation={true}
                  rows="6"
                  onBlur={this.onChange}
                  isEventBuilder={false}
                />
                <Form.Text>
                  AMCHART version 3 에 맞는 설정을 입력하세요<br></br>{" "}
                  <a
                    href="https://live.amcharts.com/new/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    AMCHART Live Editor Lite
                  </a>{" "}
                  (AMCHART3용 Builder) 를 통해 생성된 [Code] 값을 입력하세요
                </Form.Text>
              </PropertyValue>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey={1}>
            <Accordion.Header>Chart Data 조회 및 전/후 처리</Accordion.Header>
            <Accordion.Body>
              <PropertyLable requried="true">Data 조회방법</PropertyLable>
              <PropertyValue>
                <USelectbox
                  id="searchTp"
                  onChange={this.onChange}
                  defaultValue={StringUtils.defaultString(
                    this.state.propertyValue.searchTp,
                    "N"
                  )}
                  items={[
                    { id: "N", text: "조회하지 않음" },
                    { id: "ENTITY", text: "Data Model Entity" },
                    { id: "URL", text: "URL 입력" },
                  ]}
                  options={{ matchCd: "id", matchNm: "text" }}
                />
              </PropertyValue>

              {this.state.propertyValue.searchTp === "URL" ? (
                <React.Fragment>
                  <PropertyLable requried="true">URL</PropertyLable>
                  <PropertyValue>
                    <input
                      type="text"
                      id="url"
                      defaultValue={StringUtils.defaultString(
                        this.state.propertyValue.url
                      )}
                      onBlur={this.onChange}
                      className="form-control form-control-sm"
                    />
                  </PropertyValue>
                </React.Fragment>
              ) : this.state.propertyValue.searchTp === "ENTITY" ? (
                <React.Fragment>
                  <PropertyLable requried="true">Entity</PropertyLable>
                  <PropertyValue requried="true">
                    <USelectbox
                      type="search"
                      id="entityId"
                      defaultValue={StringUtils.defaultString(
                        this.state.propertyValue.entityId
                      )}
                      onChange={this.onChange}
                      url="/datamodel/getDataModelEntityList"
                      params={{
                        dataModelId: this.getDataModel(),
                      }}
                      options={{
                        matchId: "id",
                        matchNm: "text",
                      }}
                    />
                  </PropertyValue>
                </React.Fragment>
              ) : (
                ""
              )}
              <PropertyLable>Data 수 제한</PropertyLable>
              <PropertyValue>
                <InputGroup size="sm">
                  <BootstrapSwitchButton
                    id="dataLimit"
                    checked={this.state.propertyValue.dataLimit}
                    size="sm"
                    width={100}
                    onstyle="primary"
                    offstyle="dark"
                    onlabel="제한 함"
                    offlabel="제한 하지 않음"
                    onChange={(checked) => {
                      this.onChangePropertyValue("dataLimit", checked);
                    }}
                  />
                  {this.state.propertyValue.dataLimit === true ? (
                    <Fragment>
                      <InputGroup.Text style={{ marginLeft: "10px" }}>
                        최대 Data
                      </InputGroup.Text>
                      <input
                        type="number"
                        id="dataLmitCnt"
                        defaultValue={this.state.propertyValue.dataLmitCnt}
                        onBlur={this.onChange}
                        className="form-control form-control-sm"
                      />
                    </Fragment>
                  ) : (
                    ""
                  )}
                </InputGroup>
              </PropertyValue>
              <PropertyLable>1.실행 전 처리</PropertyLable>
              <PropertyValue>
                <InputGroup>
                  {this.renderEventTextArea(
                    "beforeSubmit",
                    "Chart 실행 전 처리",
                    {
                      jsonValidation: "javascript",
                      rows: 1,
                      eventCd: "widget.chart",
                      eventType: "before",
                    }
                  )}
                  {/* <UInputPopup
                    id="beforeSubmit"
                    defaultValue={StringUtils.defaultString(
                      this.state.propertyValue.beforeSubmit
                    )}
                    onClick={(e) => {
                      this.openExtendPopup(
                        e,
                        "Chart 실행 전 처리",
                        "javascript"
                      );
                    }}
                    onBlur={this.onChange}
                  /> */}
                  <UPopover
                    title="Chart 실행 전 처리 설명"
                    style={{ minWidth: "47px" }}
                  >
                    <ul>
                      <li>
                        <font color="red">
                          Chart을 화면에 그리기 전(Chart Data를 조회 하기전)에
                          실행 되는 함수.
                        </font>
                      </li>
                      <li>
                        <strong>▣ 사용 사례 </strong>
                      </li>
                      <li>
                        1. 조건에 따라 Chart을 변경 (옵션변경) 하고자 할 경우
                      </li>
                      <li>2. 조건에 따라 Chart을 show/hide 할 경우</li>
                      <li>
                        3. 조건에 따라 Chart Data를 직접 조회 하거나 고정 Data를
                        Chart에 적용 할 경우
                        <li>4. 전달 파라미터를 변경</li>
                        <li>5. 조건에 따라 호출 API URL을 변경 </li>
                      </li>
                    </ul>
                  </UPopover>
                </InputGroup>
              </PropertyValue>
              <PropertyLable>2.조회 후 처리</PropertyLable>
              <PropertyValue>
                <InputGroup>
                  {this.renderEventTextArea(
                    "afterSubmit",
                    "Data 조회 후 처리",
                    {
                      jsonValidation: "javascript",
                      rows: 1,
                      eventCd: "widget.chart",
                      eventType: "after",
                    }
                  )}
                  {/* <UInputPopup
                    id="afterSubmit"
                    defaultValue={StringUtils.defaultString(
                      this.state.propertyValue.afterSubmit
                    )}
                    onClick={(e) => {
                      this.openExtendPopup(
                        e,
                        "Data 조회 후 처리",
                        "javascript"
                      );
                    }}
                    onBlur={this.onChange}
                  /> */}
                  <UPopover
                    title="Data 조회 후 처리 설명"
                    style={{ minWidth: "47px" }}
                  >
                    <ul>
                      <li>
                        <font color="red">
                          Chart Data가 조회된 이후 실행 되는 함수.
                        </font>
                      </li>
                      <li>
                        <strong>▣ 사용 사례 </strong>
                      </li>
                      <li>1. 조회된 Chart Data 의 추가 및 변경</li>
                    </ul>
                  </UPopover>
                </InputGroup>
              </PropertyValue>
            </Accordion.Body>
          </Accordion.Item>
          {/* Style property */}
          {this.renderStylePanel("Chart", 1)}
        </Accordion>
      </React.Fragment>
    );
  };

  /**
   * Editor의 component를 Redering
   * << Layout editor props>>
   *   - compId - 현재 component의 고유 ID
   *   - componentInfo - drag & drop시 생성된 component object
   *   - style - dragging style이 포함된 style (사용자가 정의한 style은 각 component에서 적절히 적용해야함)
   *   - event="renderEditor" - 요청 구분
   * @returns
   */
  renderEditor = () => {
    /**
     * UIComponentSection props
     * -item
     * -style
     * -className
     */
    let chartStyle =
      JsonUtils.defaultString(
        this.props.componentInfo.propertyValue,
        "style"
      ) || {};
    return (
      <UIComponentSection
        item={this.props.componentInfo}
        style={{
          ...this.props.style,
        }}
        title="CHART"
        className={`editor-base draggable editor-chart ${StringUtils.defaultString(
          this.state.editorAttr.className
        )}`}
      >
        <div
          class="editor-amchart3"
          id={this.props.componentInfo.compId}
          style={chartStyle}
        >
          <img
            className="editor-chart-image"
            src={JsonUtils.defaultString(
              this.props.componentInfo.editorAttr,
              "imageUrl"
            )}
            alt={JsonUtils.defaultString(
              this.props.componentInfo.editorAttr,
              "imageAlt"
            )}
          ></img>
        </div>
      </UIComponentSection>
    );
  };
}
export default AMChart3Component;
