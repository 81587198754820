import Modal from "components/common/modal/UModal";
import UModalTemplate from "components/common/modal/UModalTemplate";
import CodeMirror from "@uiw/react-codemirror";
import React from "react";
import { csharp } from "@replit/codemirror-lang-csharp";

const CSFileContentDetailPopup = ({ fileInfo, ...props }) => {
  return (
    <Modal>
      <Modal.Header title={`${fileInfo.fileNm} 소스 코드`} />
      <Modal.Body>
        <UModalTemplate>
          <CodeMirror
            value={fileInfo.fileContent}
            className="source-container"
            height="700px"
            extensions={[csharp()]}
            autoFocus={false}
            editable={false}
          />
        </UModalTemplate>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default CSFileContentDetailPopup;
