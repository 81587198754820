import React, { useEffect } from "react";

import { useLocation, useNavigate } from "react-router-dom";

function HOCRouterProps({ Component }) {
  const navigate = useNavigate();
  const location = useLocation();

  return <Component navigate={navigate} location={location} />;
}

export default HOCRouterProps;
