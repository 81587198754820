/* eslint-disable jsx-a11y/alt-text */
import React, { Fragment } from "react";

import Modal from "components/common/modal/UModal";

import UmodalTemplate from "components/common/modal/UModalTemplate";

const GridCellTemplateGuidePopup = (props) => {
  return (
    <Modal>
      <Modal.Header title="Grid Cell Template 사용 가이드" />
      <Modal.Body>
        <UmodalTemplate>
          <Fragment>
            <h5>Grid CellTemplate</h5>
            <div className="doc-page-wrap">
              <div className="event-desc pt-1">
                HTML Tag를 이용해 셀의 스타일을 변경 할 수 있습니다.
              </div>
              <br></br>
              예제) 현재 Cell의 값에 따라 스타일을 다르게 적용한다.
              <code className="mt-2 pt-2">
                {`<span class=\${value > 40000 ? "big-val" : "small-val"}>\${text}</span>`}
              </code>
              예제) 첨부파일 번호가 있을 경우 [보기] 버튼을 cell에 보여준다.
              <code className="mt-2 pt-2">
                {`\${item.atchmnflNo != null ? '<div class="btn-group center"><button type="button" class="btn btn-default mr-2 text-center btnViewFile" data-lang="btnViewFile">보기</button></div>':''}`}
              </code>
              예제) 첨부파일명 + [파일 업로드 버튼] 을 cell에 보여준다.
              <code className="mt-2 pt-2">
                {`\${text} <button type="button" class="wj-btn wj-btn-glyph wj-right" tabindex="-1" data-lang-id="btnFileSearch"><i class="fa fa-upload"></i></button>`}
              </code>
              <div className="event-code">
                function (col,row,value,text,item)
              </div>
              <div className="event-params pb-1">
                <span className="doc-title"> Parameters</span>
                <ul>
                  <li>
                    <span className="event-args-var">col</span>:
                    <span className="event-args-type">Object</span>-
                    <span className="event-args-name">현재 Column Object</span>
                  </li>
                  <li>
                    <span className="event-args-var">row</span>:
                    <span className="event-args-type">Object</span>-
                    <span className="event-args-name">현재 Row Object</span>
                  </li>
                  <li>
                    <span className="event-args-var">value</span>:
                    <span className="event-args-type">String</span>-
                    <span className="event-args-name">
                      현재 Column에 Binding 되어 있는 값
                    </span>
                  </li>
                  <li>
                    <span className="event-args-var">text</span>:
                    <span className="event-args-type">String</span>-
                    <span className="event-args-name">
                      현재 Cell에 표시되는 Text
                    </span>
                  </li>
                  <li>
                    <span className="event-args-var">item</span>:
                    <span className="event-args-type">Object:Map</span>-
                    <span className="event-args-name">
                      현재 Row에 Binding 되어 있는 값
                    </span>
                    <div className="event-args-desc">예) item.userId</div>
                  </li>
                </ul>
              </div>
            </div>
          </Fragment>
        </UmodalTemplate>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default GridCellTemplateGuidePopup;
