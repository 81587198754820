import React, { useCallback, useEffect, useState } from "react";
import ErdCommandButton from "./ErdCommandButton";
import ReactFlow, {
  Background,
  ConnectionMode,
  ControlButton,
  Controls,
  MiniMap,
  useEdgesState,
  useNodesState,
  useOnViewportChange,
} from "reactflow";
import { useRef } from "react";
import { Enums } from "components/builder/BuilderEnum";
import User from "components/common/utils/UserUtils";
import { MdOutlineNightlightRound, MdOutlineWbSunny } from "react-icons/md";
import ErdNodeList from "components/builder/erd/ErdNodeList";
import {
  ErdAreaTemplateType,
  ErdConnectorNodeType,
  ErdTableType,
} from "components/builder/erd/components/ErdNodeTypes";
import { useDispatch, useSelector } from "react-redux";
import { initCommand } from "components/builder/ui/reducers/CommandAction";
import StringUtils from "components/common/utils/StringUtils";
import ErdReduxHelper from "components/builder/erd/editor/helper/ErdReduxHelper";
import Popup from "components/common/Popup";
import TableRegisterPopup from "page/popup/erd/TableRegisterPopup";
import {
  selectErd,
  updateErdViewport,
} from "components/builder/erd/reducers/ErdAction";
import useErdRender from "components/builder/erd/editor/render/useErdRender";
import ArrayUtils from "components/common/utils/ArrayUtils";
import produce from "immer";
import JsonUtils from "components/common/utils/JsonUtils";
import TableAreaListAsFlowWidget from "components/builder/erd/components/TableAreaListAsFlowWidget";
import TableRelationPopup from "page/popup/erd/TableRelationPopup";
import ObjectUtils from "components/common/utils/ObjectUtils";
import Message from "components/common/Message";
import ErdRelationEdge from "components/builder/erd/editor/render/ErdRelationEdge";
import useErdTableListState from "./editor/render/useErdTableListState";
import useErdCallback from "./editor/render/useErdCallback";
import LocalStorageService from "services/common/LocalService";
import { stopEvent } from "../ui/editor/handler/UIEditorEventHandler";
import ErdCodeMirror from "./components/ErdCodeMirror";

export const ErdPopupSize = {
  Register: "1200px",
};

const nodeTypes = {
  [Enums.ErdType.TABLE]: ErdTableType,
  [Enums.ErdType.CONNECTOR]: ErdConnectorNodeType,
  [Enums.ErdType.AREA_TEMPLATE]: ErdAreaTemplateType,
};

const edgeTypes = {
  floating: ErdRelationEdge,
};

function ErdBuilder() {
  const flowRef = useRef();
  const nodeChangeRef = useRef();
  const reactFlowWrapper = useRef();
  const dispatch = useDispatch();
  const erd = useSelector((state) => state.erd);
  const [relationCallback] = useErdCallback();
  //state
  const userTheme = LocalStorageService.get(
    Enums.LocalStorageName.EDITOR_THEME
  );
  const [editorTheme, setEditorTheme] = useState(
    userTheme
      ? userTheme.userId === User.getId()
        ? userTheme.theme
        : "light"
      : "light"
  );
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [tableList] = useErdTableListState();
  const [tabType, setTabType] = useState("E");

  const [erdNode, erdEdge] = useErdRender(editorTheme);
  const inDebounce = useRef();

  //뷰포트 기억할때 쓰는 함수
  const debounceScroll = (func, delay) => {
    if (inDebounce.current) clearTimeout(inDebounce.current);
    inDebounce.current = setTimeout(() => func(), delay);
  };

  /**
   * 뷰포트 바뀔때 redux에 저장
   */
  const viewPortLogger = useOnViewportChange({
    onEnd: useCallback(
      (viewport) =>
        debounceScroll(() => {
          if (
            !StringUtils.isEmpty(erd.erdInfo.erdId) &&
            erd.activatedErd.compId
          )
            dispatch(updateErdViewport(viewport, erd.activatedErd.compId));
        }, 250),
      [erd.activatedErd]
    ),
  });

  useEffect(() => {
    dispatch(initCommand());

    return () => {
      setReactFlowInstance(null);
      setEdges([]);
      setNodes([]);
    };
  }, []);

  useEffect(() => {
    setNodes(erdNode);
    setEdges(erdEdge);
  }, [erdNode]);

  useEffect(() => {
    if (reactFlowInstance) {
      if (erd.activatedErd.compId) {
        reactFlowInstance.setViewport(
          erd.output.areas[erd.activatedErd.compId].viewport
        );
      }
    }
  }, [erd.activatedErd]);

  /**
   * 테마 변경
   * @param {*} e
   * @param {*} theme
   */
  const onChangeTheme = (e, theme) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    setEditorTheme(theme);
    LocalStorageService.set(Enums.LocalStorageName.EDITOR_THEME, {
      userId: User.getId(),
      theme,
    });
  };

  const onMiniMapNodeClick = (e, _node) => {};

  /**
   * 노드 생성 이벤트
   * 노드 생성 순서
   *  1. NodeList 에서 Drag (Draggable Element)
   *  2. ReactFlow 에서 onDragOver
   *  3. onDrop
   *
   */
  const onDrop = useCallback(
    async (event) => {
      if (ObjectUtils.isEmpty(erd.output.areas)) {
        return Message.alert(
          "신규 ERD를 설정해주세요.",
          Enums.MessageType.WARN
        );
      }
      event.preventDefault();
      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });
      const _NodeData = event.dataTransfer.getData("application/reactflow");
      // check if the dropped element is valid
      if (!_NodeData) return false; //데이터가 없는 경우
      const NodeData = JSON.parse(_NodeData);
      const { type } = NodeData;

      if (StringUtils.equalsIgnoreCase(Enums.ErdType.TABLE, type)) {
        onDropTable(position, { nodes, edges }, erd.activatedErd.compId);
      } else if (StringUtils.equalsIgnoreCase(Enums.ErdType.MEMO, type)) {
        onDropMemo(position);
      }
    },
    [reactFlowInstance, erd, nodes, edges]
  );

  /**
   * 메모를 올렸을때
   * @param {*} position
   */
  const onDropMemo = (position) => {
    ErdReduxHelper.addMemo(
      dispatch,
      {
        compId: StringUtils.getUuid(),
        description: "",
        position,
        style: { width: 300, height: 300 },
        type: Enums.ErdType.MEMO,
      },
      erd
    );
  };

  /**
   * Table 을 생성하는 경우
   * @param {*} position
   */
  const onDropTable = (position, nodeInfo, categoryId) => {
    const callback = (tableData) => {
      const prevNode = JsonUtils.findNode(
        erd.output.areas,
        "physicalTableNm",
        tableData.propertyValue.physicalTableNm
      );
      if (!ObjectUtils.isEmpty(prevNode)) {
        Message.alert("이미 등록된 테이블 입니다.", Enums.MessageType.WARN);
      } else {
        ErdReduxHelper.addErdTable(dispatch, tableData, erd, categoryId);
        Popup.close();
      }
    };

    Popup.open(
      <TableRegisterPopup callback={callback} tableInfo={{ position }} />,
      {
        style: { content: { width: ErdPopupSize.Register } },
      }
    );
  };

  /**
   * Flow Panel 위에서 드래그 되었을때 이벤트
   */
  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const onNodeCustomChange = (nodeChange) => {
    onNodesChange(nodeChange);
    if (erd.activatedErd.type === "all") {
    } else {
      let changedEntities = [];
      if (!ArrayUtils.isEmpty(nodeChange)) {
        if (nodeChange[0].type === "position") {
          if (nodeChange.length === 1 && nodeChange[0].id === "track") {
          } else if (nodeChange[0].dragging === true) {
            nodeChangeRef.current = nodeChange;
          } else if (nodeChange[0].dragging === false) {
            if (nodeChangeRef.current) {
              changedEntities = getChangedNode("position");
              nodeChangeRef.current = null;
            }
          }
        }
        if (changedEntities.length > 0) {
          ErdReduxHelper.updateNodes(dispatch, changedEntities, erd);
        }
      }
    }
  };
  /**
   * 바뀐 노드 목록 리턴
   * onChangeNode에서 사용
   * @param {String} key
   * @returns {Array}
   */
  const getChangedNode = (key) => {
    const changedEntities = nodeChangeRef.current.map((_nodeInfo) => {
      let changedContents = {};
      const { id } = _nodeInfo;
      changedContents = JsonUtils.findNode(
        erd.output.areas[erd.activatedErd.compId],
        "compId",
        id
      );
      changedContents = produce(changedContents, (draft) => {
        JsonUtils.overrideNode(draft, "compId", id, key, _nodeInfo[key]);
      });
      return changedContents;
    });
    return changedEntities;
  };

  /**
   * 테이블간 연결 로직
   */
  const onConnect = useCallback(
    (connection) => {
      const { source, target, sourceHandle, targetHandle } = connection;
      //원천 테이블
      const sourceNode = nodes.find((t) => t.id === source);
      const sourceTable = { ...sourceNode.data };
      //타겟 테이블
      const targetNode = nodes.find((t) => t.id === target);
      const targetTable = { ...targetNode.data };
      const callback = (relationType, joinColumns, props) => {
        relationCallback(sourceTable, targetTable, relationType, joinColumns, {
          ...props,
          cb: () => {
            Popup.close();
          },
        });
      };

      Popup.open(
        <TableRelationPopup
          callback={callback}
          sourceTable={sourceTable}
          targetTable={targetTable}
          newRelation={true}
          tableList={tableList}
          refChangeable={false}
        />,
        {
          style: { content: { width: "600px" } },
        }
      );
    },
    [setEdges, erd.output, nodes, edges]
  );

  /**
   * 빌더 좌측 영역 선택 콤보에서 노드 선택하는 이벤트
   * 전체 볼때와 일반 로직 선택했을 때 다름
   * @param {*} e
   * @param {*} node
   */
  const onNodeClickInFlowWidget = (e, area) => {
    stopEvent(e);
    dispatch(selectErd(area));
  };

  return (
    <div className="reactflow-wrapper erd" ref={reactFlowWrapper}>
      <div className="command-button-wrapper">
        <ErdCommandButton tepType={tabType} setTabType={setTabType} />
      </div>
      {StringUtils.equalsIgnoreCase(tabType, "S") ? (
        <ErdCodeMirror />
      ) : (
        <div className={`flow-editor-wrapper erd ${editorTheme}`}>
          <ReactFlow
            nodes={nodes}
            nodeTypes={nodeTypes}
            edges={edges}
            maxZoom={5}
            minZoom={0.2}
            onNodesChange={onNodeCustomChange}
            onInit={setReactFlowInstance}
            onDrop={onDrop}
            onDragOver={onDragOver}
            zoomOnDoubleClick
            snapToGrid
            onConnect={onConnect}
            edgeTypes={edgeTypes}
            multiSelectionKeyCode={"Control"}
            connectionMode={ConnectionMode.Loose}
            proOptions={{ hideAttribution: true }}
            ref={flowRef}
          >
            <Controls
              position={"top-left"}
              style={{
                left: -12,
                display: "flex",
                outline: "1px solid gray",
                borderRadius: "5px",
                boxShadow: "1px 1px 3px black",
              }}
            >
              {editorTheme === "light" ? (
                <ControlButton
                  title="어둡게"
                  onClick={(e) => onChangeTheme(e, "dark")}
                >
                  <MdOutlineNightlightRound />
                </ControlButton>
              ) : (
                <ControlButton
                  title="밝게"
                  onClick={(e) => onChangeTheme(e, "light")}
                >
                  <MdOutlineWbSunny />
                </ControlButton>
              )}
            </Controls>

            <Background
              style={{
                background: editorTheme === "light" ? "white" : "#282828",
              }}
            />
            <TableAreaListAsFlowWidget onNodeClick={onNodeClickInFlowWidget} />
            <ErdNodeList theme={editorTheme} />
            <MiniMap
              zoomable
              pannable
              style={{
                outline: "1px solid gray",
                borderRadius: "5px",
                boxShadow: "1px 1px 3px black",
                top: 352,
                left: -12,
              }}
              nodeColor={"#3c3c3c"}
              onNodeClick={onMiniMapNodeClick}
              maskColor={"#D3D3D3cc"}
              position={"top-left"}
            />
          </ReactFlow>
        </div>
      )}
    </div>
  );
}

export default ErdBuilder;
