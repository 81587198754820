import { Enums } from "components/builder/BuilderEnum";
import USelectbox from "components/common/element/USelectbox";
import Message from "components/common/Message";
import Modal from "components/common/modal/UModal";
import UmodalTemplate from "components/common/modal/UModalTemplate";
import Popup from "components/common/Popup";
import ObjectUtils from "components/common/utils/ObjectUtils";
import StringUtils from "components/common/utils/StringUtils";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { BsQuestionLg } from "react-icons/bs";
import { RiDeleteBinLine } from "react-icons/ri";
import ValidationValueSettingGuide from "page/popup/ValidationValueSettingGuide";

function ValidationPopup({
  valRuleList,
  appliedRules = {},
  onSave,
  gridValidaton,
  ...props
}) {
  const [myValidationRules, setMyValidationRules] = useState([]);

  useEffect(() => {
    parseAppliedRules();
  }, []);

  /**
   * 기 지정된 검증(Object || String)을 표출하기 편하도록 배열로 바꿈
   */
  const parseAppliedRules = () => {
    if (StringUtils.equalsIgnoreCase(typeof appliedRules, "string")) {
      appliedRules = JSON.parse(appliedRules);
    }
    const validationCds = Object.keys(appliedRules).filter(
      (key) => key.indexOf("-error") === -1
    );
    const parsedValidations = validationCds.map((key, idx) => {
      return {
        [key]: appliedRules[key],
        [`${key}-error`]: appliedRules[`${key}-error`],
      };
    });
    if (parsedValidations.length === 0) {
      setMyValidationRules([{}]);
    } else {
      setMyValidationRules(parsedValidations);
    }
  };

  /**
   * 검증 추가
   */
  const onAddVal = () => {
    setMyValidationRules([...myValidationRules, {}]);
  };

  /**
   * 검증 저장
   * 배열 => 1개 오브젝트로 변환해서 리턴
   * @returns {function} 팝업 종료
   */
  const onSaveValidation = () => {
    try {
      const retObject = {};
      myValidationRules.forEach((validation, idx) => {
        console.log(validation);
        if (ObjectUtils.isEmpty(validation)) {
          throw new Error(
            "입력되지 않은 검증 조건이 있습니다. 내용을 확인해주세요"
          );
        }
        for (let key in validation) {
          retObject[key] = validation[key];

          if (key.includes("-error")) {
            //key에 -error 이 있는 것은 메세지 내용이다. {}가 포함되어있으면 안된다고 본다.
            if (validation[key].includes("{}"))
              throw new Error("완성되지 못한 메세지가 있습니다.");
          }
        }
      });
      if (ObjectUtils.isEmpty(retObject)) {
        onSave(null);
      } else {
        onSave(retObject);
      }

      return Popup.close();
    } catch (error) {
      Message.alert(error.message, Enums.MessageType.WARN);
    }
  };

  /**
   * 검증 내용이 바뀌는 경우
   * @param {*} obj
   * @param {*} index
   */
  const onChangeValidation = (obj, index) => {
    const newMyVal = [...myValidationRules];
    newMyVal[index] = { ...obj };
    setMyValidationRules(newMyVal);
  };

  /**
   * 검증 삭제
   * @param {*} index
   */
  const onRemove = (index) => {
    const newMyVal = [...myValidationRules];
    newMyVal.splice(index, 1);
    setMyValidationRules(newMyVal);
  };

  const opentHelp = (e) => {
    const options = {
      style: {
        content: {
          width: "45%",
        },
      },
    };
    Popup.open(
      <ValidationValueSettingGuide
        title="
      유효성 체크 - '검증 값' 입력 가이드"
      />,
      options
    );
  };

  return (
    <Modal>
      <Modal.Header title={"기타 유효성 검사 설정"} />
      <Modal.Body>
        <UmodalTemplate>
          <Row>
            <Col xs={9} />
            <Col xs={3}>
              <Button variant="outline-primary" onClick={onAddVal}>
                유효성 추가
              </Button>
            </Col>
          </Row>
          <Form as={Row}>
            <Col xs={4}>
              <Form.Label className="required">조건</Form.Label>
            </Col>
            <Col xs={2}>
              <Form.Label className="required">검증 값</Form.Label>{" "}
            </Col>
            <Col xs={3}>
              <Form.Label className={`${gridValidaton ? "" : "required"}`}>
                메세지 {gridValidaton ? " 코드" : ""}
              </Form.Label>{" "}
            </Col>
          </Form>
          {myValidationRules.map((item, idx) => {
            return (
              <ValidationForm
                key={idx}
                item={item}
                index={idx}
                valRuleList={valRuleList}
                myValidationRules={myValidationRules}
                onChangeValidation={onChangeValidation}
                onRemove={onRemove}
                gridValidaton={gridValidaton}
              />
            );
          })}
        </UmodalTemplate>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Button onClick={opentHelp} variant="success" side="left">
          <BsQuestionLg />
          "검증 값" 입력 가이드
        </Modal.Footer.Button>
        <Modal.Footer.Button onClick={onSaveValidation}>
          저장
        </Modal.Footer.Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ValidationPopup;

const ValidationForm = ({
  item,
  index,
  valRuleList,
  onChangeValidation,
  onRemove,
  gridValidaton,
  myValidationRules,
  ...props
}) => {
  const [selectedValRule, setSelectedValRule] = useState({});
  const [validationOption, setValidationOption] = useState("");
  const [validationMessage, setValidationMessage] = useState("");

  const valRuleOptions = useRef(valRuleList);
  const [dpValRule, setDpValRule] = useState([]);

  useEffect(() => {
    //선택한 항목은 다른 유효성 선택에서 나오지 않도록 함
    if (myValidationRules.length > 0) {
      let list = [...valRuleOptions.current];
      myValidationRules.map((myrule) => {
        for (let key in myrule) {
          const keyIndex = list.findIndex((cv) =>
            StringUtils.equalsIgnoreType(cv.componentValRuleCd, key)
          );
          if (keyIndex > -1) {
            list.splice(keyIndex, 1);
          }
        }
      });
      setDpValRule(list);
    } else {
      setDpValRule(valRuleOptions.current);
    }
  }, [myValidationRules]);

  useEffect(() => {
    if (!ObjectUtils.isEmpty(item)) {
      const validationCd = Object.keys(item)[0];
      const validation = valRuleList.find((v) =>
        StringUtils.equalsIgnoreType(v.componentValRuleCd, validationCd)
      );
      setSelectedValRule(validation);
      setValidationOption(item[validationCd]);
      setValidationMessage(item[`${validationCd}-error`]);
    }
  }, [item]);

  useEffect(() => {
    if (!ObjectUtils.isEmpty(selectedValRule)) {
      const obj = {
        [selectedValRule.componentValRuleCd]: validationOption,
        [`${selectedValRule.componentValRuleCd}-error`]: validationMessage,
      };
      onChangeValidation(obj, index);
    } else {
      onChangeValidation({}, index);
    }
  }, [selectedValRule, validationOption, validationMessage]);

  /**
   * 검사 조건이 바뀌는 경우
   * @param {*} e
   */
  const onChangeValRule = (e) => {
    const validation = valRuleList.find((v) =>
      StringUtils.equalsIgnoreType(v.componentValRuleCd, e.target.value)
    );
    if (validation) {
      setSelectedValRule(validation);
      if (!gridValidaton) setValidationMessage(validation.defaultMessage);
    } else {
      setValidationMessage("");
      setValidationOption("");
      setSelectedValRule({});
    }
  };

  /**
   * 검사 조건 삭제
   */
  const onClickRemove = () => {
    onRemove(index);
  };

  /**
   * 메세지 파싱 함수
   * @returns {String} 검증 오류 메세지
   */
  const validationMessageParser = () => {
    try {
      if (!selectedValRule) {
        return "";
      } else {
        let option = validationMessage;
        if (option.includes("{}")) {
          return '메세지를 완성 해주세요. "{}" 자리에 들어갈 말을 입력해주세요.';
        } else {
          return `${validationMessage}`;
        }
      }
    } catch (error) {
      Message.alert(error.message, Enums.MessageType.WARN);
    }
  };

  return (
    <Form as={Row} className={"mb-3"}>
      <Col xs={4}>
        <USelectbox
          size={"md"}
          items={
            ObjectUtils.isEmpty(selectedValRule)
              ? [...dpValRule]
              : [selectedValRule, ...dpValRule]
          }
          id="valid"
          value={selectedValRule?.componentValRuleCd}
          onChange={onChangeValRule}
          options={{
            matchId: "componentValRuleCd",
            matchNm: "componentValRuleNm",
          }}
        ></USelectbox>
      </Col>
      <Col xs={2}>
        <Form.Control
          value={validationOption}
          onChange={(e) => setValidationOption(e.target.value)}
        />
      </Col>
      <Col xs={4}>
        <Form.Control
          value={validationMessage}
          onChange={(e) => setValidationMessage(e.target.value)}
          placeholder={gridValidaton ? "메세지 코드 입력" : "메세지 내용 입력"}
        />
      </Col>
      <Col xs={2}>
        <Button variant="danger" onClick={onClickRemove}>
          <RiDeleteBinLine />
        </Button>
      </Col>
      {!gridValidaton && !ObjectUtils.isEmpty(selectedValRule) && (
        <>
          <Col
            xs={3}
            className="mt-1"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            출력 메세지 :
          </Col>
          <Col xs={9} className="mt-1">
            {validationMessageParser()}
          </Col>
        </>
      )}
    </Form>
  );
};
