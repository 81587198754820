import { Enums } from "components/builder/BuilderEnum";
import USelectbox from "components/common/element/USelectbox";
import Message from "components/common/Message";
import UmodalTemplate from "components/common/modal/UModalTemplate";
import StringUtils from "components/common/utils/StringUtils";
import User from "components/common/utils/UserUtils";
import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Modal from "components/common/modal/UModal";

function ConnectionAddPopup({ saveConnection, connection, ...props }) {
  const [connectionId, setConnectionId] = useState("");
  const [connectionNm, setConnectionNm] = useState("");
  const [protocol, setProtocol] = useState("HTTP");
  const [host, setHost] = useState("");
  const [systemUserId, setSystemUserId] = useState("");
  const [systemUserPw, setSystemUserPw] = useState("");
  const [tenantId, setTenantId] = useState("*");
  const [coCd, setCoCd] = useState("*");
  const [IsLoading, setIsLoading] = useState(false);

  const connectionNmRef = useRef();
  const systemUserPwRef = useRef();

  useEffect(() => {
    if (connection) {
      setConnectionId(connection.connectionId);
      setConnectionNm(connection.connectionNm);
      setProtocol(connection.protocol);
      setHost(connection.host);
      setSystemUserId(connection.userId);
      setTenantId(connection.tenantId);
      setCoCd(connection.coCd);
    }
  }, []);

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      onSaveConnect();
    }
  };

  const validationCheck = () => {
    if (StringUtils.isEmpty(tenantId) || StringUtils.isEmpty(coCd)) {
      Message.alert(
        "테넌트 ID와 회사코드를 입력해주세요.",
        Enums.MessageType.WARN
      );
      return false;
    }
    if (StringUtils.isEmpty(protocol)) {
      Message.alert("스키마를 입력 해주세요", Enums.MessageType.WARN);
      return false;
    }
    if (StringUtils.isEmpty(host)) {
      Message.alert("호스트를 입력해주세요", Enums.MessageType.WARN);
      return false;
    }
    if (StringUtils.isEmpty(systemUserId)) {
      Message.alert("시스템 아이디를 입력해주세요", Enums.MessageType.WARN);
      return false;
    }
    // if (StringUtils.isEmpty(systemUserPw)) {
    //   Message.alert("시스템 비밀번호를 입력해주세요", Enums.MessageType.WARN);
    //   return false;
    // }
    return {
      tenantId,
      coCd,
      protocol,
      host,
      systemUserId,
      systemUserPw,
      connectionId: connectionId,
    };
  };

  const onSaveConnect = () => {
    //연결 및 DB 저장
    if (StringUtils.isEmpty(connectionNm)) {
      Message.alert("연결 명을 설정해주세요", Enums.MessageType.WARN);
      return false;
    }
    const body = validationCheck();
    if (body) {
      body.connectionNm = connectionNm;
      setIsLoading(true);
      saveConnection(body, () => {
        setIsLoading(false);
      });
    }
  };

  return (
    <Modal>
      <Modal.Header title={"새 연결 추가"} />
      <Modal.Body>
        <Form>
          <UmodalTemplate>
            <Form.Group className="mb-3">
              <Form.Label className="required">연결명</Form.Label>
              <input
                type="text"
                id="connectionNm"
                name="connectionNm"
                className="form-control"
                onChange={(e) => setConnectionNm(e.target.value)}
                value={connectionNm}
                ref={connectionNmRef}
                onKeyDown={onKeyDown}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="required">연결 스키마</Form.Label>
              <USelectbox
                id="protocol"
                onChange={(e) => setProtocol(e.target.value)}
                defaultValue={StringUtils.defaultString(protocol, "HTTP")}
                items={[
                  { id: "HTTP", text: "HTTP" },
                  { id: "HTTPS", text: "HTTPS" },
                ]}
                options={{
                  matchCd: "id",
                  matchNm: "text",
                }}
                size="m"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="required">호스트</Form.Label>
              <input
                type="text"
                id="host"
                name="host"
                className="form-control"
                onChange={(e) => setHost(e.target.value)}
                value={host}
                onKeyDown={onKeyDown}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="required">접속 유저 ID</Form.Label>
              <input
                type="text"
                id="systemUserId"
                name="systemUserId"
                className="form-control"
                onChange={(e) => setSystemUserId(e.target.value)}
                value={systemUserId}
                onKeyDown={onKeyDown}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="required">접속 유저 비밀번호</Form.Label>
              <input
                type="password"
                id="systemUserPw"
                name="systemUserPw"
                className="form-control"
                onChange={(e) => setSystemUserPw(e.target.value)}
                value={systemUserPw}
                ref={systemUserPwRef}
                onKeyDown={onKeyDown}
              />
            </Form.Group>
            <Row>
              <Form.Group as={Col}>
                <Form.Label className="required">테넌트 ID</Form.Label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="테넌트 ID"
                  value={tenantId}
                  onChange={(e) => setTenantId(e.currentTarget.value)}
                  onKeyDown={onKeyDown}
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label className="required">회사 코드</Form.Label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="회사 코드"
                  value={coCd}
                  onChange={(e) => setCoCd(e.currentTarget.value)}
                  onKeyDown={onKeyDown}
                />
              </Form.Group>
            </Row>
          </UmodalTemplate>
        </Form>
      </Modal.Body>
      <Modal.Footer
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Modal.Footer.ProgressButton
          onClick={onSaveConnect}
          doing={IsLoading}
          doingText={"저장 중"}
        >
          저장
        </Modal.Footer.ProgressButton>
      </Modal.Footer>
    </Modal>
  );
}

export default ConnectionAddPopup;
