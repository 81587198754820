import { TextField } from "@mui/material";
import { Enums } from "components/builder/BuilderEnum";
import { updateErd } from "components/builder/erd/reducers/ErdAction";
import { initCommand } from "components/builder/ui/reducers/CommandAction";
import { AppContext } from "components/common/AppContextProvider";
import Message from "components/common/Message";
import WijmoGrid from "components/common/element/WijmoGrid";
import CommonUtils, { ObjectUtils } from "components/common/utils/CommonUtils";
import produce from "immer";
import PageTemplate from "page/common/PageTemplate";
import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { BiEditAlt } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import { RiDeleteBinLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ErdService from "services/erd/ErdService";
import ProgramService from "services/ui/ProgramService";

/**
 * ERD 목록 보여주는 화면
 * @param {*} props
 * @returns
 */
function ErdList(props) {
  const workspace = useSelector((state) => state.workspace);
  const [isLoading, setIsLoading] = useState(false);
  const [erdList, setErdList] = useState([]);
  const {
    workspace: { openPopup },
  } = useContext(AppContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState({
    erdId: "",
    erdUid: "",
    erdName: "",
    workerId: "",
  });

  const breadcrum = [
    {
      name: "ERD 목록",
      url: Enums.BuilderPath.ERD.MAIN + "/" + Enums.BuilderPath.ERD.LIST,
      active: true,
    },
  ];

  const columns = [
    {
      field: "tenantId",
      headerName: "테넌트 ID",
      width: 120,
    },
    {
      field: "coCd",
      headerName: "회사 코드",
      width: 120,
    },
    {
      field: "erdUid",
      headerName: "ERD UID",
      width: 130,
      align: "left",
    },
    {
      field: "erdId",
      headerName: "ERD ID",
      width: 150,
    },
    {
      field: "erdName",
      headerName: "ERD 명",
      width: "*",
    },
    {
      field: "description",
      headerName: "ERD 설명",
      width: "*",
    },
    {
      field: "updtUserId",
      headerName: "최종 수정자",
      width: 130,
    },
    {
      field: "updtDt",
      headerName: "최종 수정일",
      width: 150,
      renderCell: (params) =>
        `${CommonUtils.getDate(params.updtDt, "datetime")}`,
    },
    {
      field: "Edit",
      headerName: "편집",
      headerAlign: "center",
      width: 80,
      align: "center",
      renderCell: (params) => {
        return (
          <Button
            size="sm"
            variant="outline-success"
            onClick={(e) => onEdit(params)}
          >
            <BiEditAlt />
          </Button>
        );
      },
    },
    {
      field: "Delete",
      headerName: "삭제",
      headerAlign: "center",
      width: 80,
      align: "center",
      renderCell: (params) => {
        return (
          <Button
            size="sm"
            variant="outline-danger"
            onClick={(e) => onDelete(params)}
          >
            <RiDeleteBinLine />
          </Button>
        );
      },
    },
  ];

  useEffect(() => {
    onSearch();
  }, []);

  const onChangeSearchTerm = (e) => {
    setSearchTerm(
      produce(searchTerm, (draft) => {
        draft[e.target.id] = e.target.value;
      })
    );
  };

  /**
   * 편집(불러오기)
   * @param {*} param
   */
  const onEdit = (param) => {
    //불러오기
    const LoadEnd = () => setIsLoading(false);
    setIsLoading(true);
    ErdService.getErd(
      param,
      (res) => {
        const { erdContent, erdMemo, ...otherData } = res.data;
        const output = JSON.parse(erdContent);
        const firstCategory = output.areas[Object.keys(output.areas)[0]];
        const data = {
          erdInfo: {
            ...otherData,
          },
          output: output,
          memo: JSON.parse(erdMemo) || [],
          activatedErd: {
            name: firstCategory.name,
            compId: firstCategory.compId,
          },
        };
        dispatch(updateErd(data));
        dispatch(initCommand());
        navigate(
          Enums.BuilderPath.ERD.MAIN + "/" + Enums.BuilderPath.ERD.BUILDER
        );
      },
      LoadEnd
    );
  };

  /**
   * 삭제
   * @param {*} param
   */
  const onDelete = (param) => {
    Message.confirm(
      "삭제 하시겠습니까?",
      () => {
        setIsLoading(true);
        ErdService.deleteErd(param, (res) => {
          Message.alert("삭제 되었습니다.", Enums.MessageType.SUCCESS);
          onSearch();
        });
      },
      () => setIsLoading(false)
    );
  };

  /**
   * 검색창 키다운 이벤트
   * @param {*} e
   */
  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      onSearch();
    }
  };

  /**
   * 목록호출
   */
  const onSearch = () => {
    setIsLoading(true);
    const LoadEnd = () => setIsLoading(false);
    const body = {
      ...searchTerm,
      ...workspace,
    };
    ErdService.getErdList(
      body,
      (res) => {
        const list = ProgramService.filterByTenantIdAndCoCd(
          res.data,
          body.tenantId,
          body.coCd,
          "erdId"
        );
        setErdList(list);
        LoadEnd();
      },
      LoadEnd
    );
  };

  return (
    <PageTemplate breadcrum={breadcrum}>
      <PageTemplate.Box boxClass="mb-0">
        {ObjectUtils.isEmpty(workspace) ? (
          <div className="workspace-empty-alert">
            <div className="alert-msg">워크 스페이스 설정이 필요 합니다.</div>
            <Button onClick={() => openPopup()}>팝업 열기</Button>
          </div>
        ) : (
          <>
            <Form>
              <Row className="pb-3 pt-3">
                <Col xs={2}>
                  <TextField
                    size={"small"}
                    label="ERD UID"
                    placeholder="ERD UID 검색..."
                    value={searchTerm.erdUid}
                    id={"erdUid"}
                    fullWidth={true}
                    onChange={onChangeSearchTerm}
                    onKeyDown={onKeyDown}
                  />
                </Col>
                <Col xs={2}>
                  <TextField
                    size={"small"}
                    label="ERD ID"
                    placeholder="ERD ID 검색..."
                    value={searchTerm.erdId}
                    id={"erdId"}
                    fullWidth={true}
                    onChange={onChangeSearchTerm}
                    onKeyDown={onKeyDown}
                  />
                </Col>
                <Col xs={2}>
                  <TextField
                    size="small"
                    label="서비스 명"
                    placeholder="서비스 명"
                    value={searchTerm.erdName}
                    id={"erdName"}
                    onChange={onChangeSearchTerm}
                    onKeyDown={onKeyDown}
                    fullWidth
                  />
                </Col>
                <Col xs={2}>
                  <TextField
                    size="small"
                    label="최종 수정자 ID"
                    placeholder="최종 수정자 ID"
                    value={searchTerm.workerId}
                    id={"workerId"}
                    onChange={onChangeSearchTerm}
                    onKeyDown={onKeyDown}
                    fullWidth
                  />
                </Col>
                <Col xs={1}>
                  <Button variant="primary" onClick={onSearch}>
                    검색 <BsSearch size="14" />
                  </Button>
                </Col>
              </Row>
            </Form>
            <Row style={{ height: "calc(100% - 70px)" }}>
              <Col xs={12}>
                <WijmoGrid
                  isLoading={isLoading}
                  columns={columns}
                  rows={erdList}
                  onRowDoubleClick={(params) => onEdit(params)}
                  getRowId={(row) => row.serviceUid}
                  style={{ height: "70vh" }}
                />
              </Col>
            </Row>
          </>
        )}
      </PageTemplate.Box>
    </PageTemplate>
  );
}

export default ErdList;
