import { Checkbox, FormControlLabel, FormGroup, Tooltip } from "@mui/material";
import { Enums } from "components/builder/BuilderEnum";
import Message from "components/common/Message";
import ArrayUtils from "components/common/utils/ArrayUtils";
import StringUtils from "components/common/utils/StringUtils";
import User from "components/common/utils/UserUtils";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { flushSync } from "react-dom";
import styled from "styled-components";
import {
  keyArrayToString,
  KeyCodeSet,
} from "components/setting/section/shortKey/ShortKeyConfig";

const Wrapper = styled.div`
  width: 100%;
  flex: 0 0 40px;
  margin-top: 15px;
  display: flex;
  border-bottom: 1px solid lightgray;
  & > div {
    display: flex;
    align-items: center;
  }

  & .key_name {
    width: 30%;
    padding-left: 10%;
  }
  & .key_value {
    padding-left: 5%;
    width: 70%;
    & .edit_box {
      display: flex;
      align-items: center;
      gap: 15px;
      & input[type="text"] {
        border: none;
        border-bottom: 1px solid lightgray;
      }
    }

    & .key_box {
      width: 70%;
      cursor: default;
    }
  }
`;

function ShortKeyItem({ name, keyCode, keyMappingName, onSetKey, ...props }) {
  const [editMode, setEditMode] = useState(false);
  const [keyValue, setKeyValue] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const [shift, setShift] = useState(false);
  const [alt, setAlt] = useState(false);
  const [ctrl, setCtrl] = useState(false);

  const inputRef = useRef();

  useEffect(() => {
    if (!ArrayUtils.isEmpty(keyCode)) {
      if (keyCode.includes(16)) setShift(true);
      if (keyCode.includes(17)) setCtrl(true);
      if (keyCode.includes(18)) setAlt(true);
      setInputValue(KeyCodeSet[keyCode.at(-1)]);
      setKeyValue(keyCode);
    }
  }, [editMode]);

  /**
   * 수정 할때
   * @param {*} e
   */
  const onEditKeyCode = (e) => {
    e.stopPropagation();
    e.preventDefault();

    flushSync(() => {
      setEditMode(true);
    });
    inputRef.current.focus();
  };

  /**
   * 취소버튼 클릭
   * @param {*} e
   */
  const onCancel = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setEditMode(false);
  };

  /**
   * Shift, alt, ctrl 변경
   * @param {*} e
   * @param {*} setState
   */
  const onChangeSpecialKey = (e, setState) => {
    setState(e.target.checked);
    if (e.target.checked) {
      const newK = [...keyValue];
      newK.push(Number(e.target.value));
      newK.sort();
      setKeyValue(newK);
    } else {
      setKeyValue(keyValue.filter((k) => k !== Number(e.target.value)));
    }
  };

  /**
   * 키코드 키보드 입력
   * @param {*} e
   * @returns
   */
  const onKeyDown = (e) => {
    e.preventDefault();
    console.log(e.keyCode, e.nativeEvent);
    if (e.nativeEvent.key === "Process") {
      inputRef.current.value = "";
      setInputValue("");
      return Message.alert("영문 입력을 해주세요", Enums.MessageType.INFO);
    }
    if (e.altKey || e.ctrlKey || e.shiftKey) {
      inputRef.current.blur();
      return Message.alert(
        "Alt, Ctrl, Shift 는 왼쪽 콤보에서 선택해주세요",
        Enums.MessageType.INFO
      );
    }
    if (KeyCodeSet[e.keyCode]) {
      let keyCombo = [];
      if (shift) keyCombo.push(16);
      if (ctrl) keyCombo.push(17);
      if (alt) keyCombo.push(18);
      keyCombo.push(e.keyCode);
      setKeyValue(keyCombo);
      setInputValue(KeyCodeSet[e.keyCode]);
    } else {
      setInputValue("");
      return Message.alert(
        "허용 되지 않는 단축키 입니다.",
        Enums.MessageType.INFO
      );
    }
  };
  /**
   * 적용 버튼 누름
   * @param {*} e
   * @returns
   */
  const onSetKeyCode = (e) => {
    if (StringUtils.isEmpty(inputValue)) {
      return Message.alert(
        "단축키가 설정되지 않았습니다.",
        Enums.MessageType.ERROR
      );
    } else if (onSetKey) {
      setEditMode(false);
      onSetKey(keyMappingName, keyValue);
    }
  };
  /**
   * 단축키 삭제
   * @param {*} e
   */
  const onDeleteKey = (e) => {
    setEditMode(false);
    onSetKey(keyMappingName, []);
  };

  return (
    <Wrapper>
      <div className="key_name">{name}</div>
      <div className="key_value">
        {editMode ? (
          <div className="edit_box">
            <Form.Check
              label="Shift"
              value={16}
              checked={shift}
              onChange={(e) => onChangeSpecialKey(e, setShift)}
            />
            {/* <Form.Check
              label="Ctrl"
              value={17}
              checked={ctrl}
              onChange={(e) => onChangeSpecialKey(e, setCtrl)}
            /> */}
            <Form.Check
              label="Alt"
              value={18}
              checked={alt}
              onChange={(e) => onChangeSpecialKey(e, setAlt)}
            />
            <input
              type={"text"}
              ref={inputRef}
              value={inputValue}
              onChange={(e) => {}}
              onKeyDown={onKeyDown}
              placeholder="단축키 입력..."
            />
            <div style={{ display: "flex", gap: "10px" }}>
              <Button variant="success" size="sm" onClick={onSetKeyCode}>
                적용
              </Button>
              <Button variant="outline-danger" size="sm" onClick={onCancel}>
                취소
              </Button>
              <Button variant="danger" size="sm" onClick={onDeleteKey}>
                삭제
              </Button>
            </div>
          </div>
        ) : (
          <div className="key_box">
            {ArrayUtils.isEmpty(keyCode) ? (
              <Button variant="outline-primary" onClick={onEditKeyCode}>
                설정하기
              </Button>
            ) : (
              <span>
                {keyArrayToString(keyCode)}
                &nbsp;&nbsp;&nbsp;
                <Button variant="outline-success" onClick={onEditKeyCode}>
                  수정
                </Button>
              </span>
            )}
          </div>
        )}
      </div>
    </Wrapper>
  );
}

export default ShortKeyItem;
