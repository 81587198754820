import React, { useCallback, useEffect, useRef } from "react";
import { useDrag } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import produce from "immer";

import GridHeader from "components/builder/ui/uiComponents/grid/GridHeader";
import GridCell from "components/builder/ui/uiComponents/grid/GridCell";
import * as Enums from "components/builder/BuilderEnum";

const UIEditorGridColumn = (props) => {
  const { data, path, style, className, gridOptions } = props;
  const ref = useRef(null);
  const item = produce(data, (draft) => {
    draft.path = path;
    draft.gridOptions = gridOptions;
  });
  const [{ isDragging }, drag, preview] = useDrag({
    item: item,
    type: item.type,
    //canDrag: canDrag
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, []);

  const opacity = isDragging ? 0.5 : 1;
  drag(ref);

  const getHeaderComponentInfo = useCallback(() => {
    let headerItem = { ...item };
    headerItem.type = Enums.ComponentType.GRID_HEADER;
    headerItem.gridId = item.propertyValue.gridId;
    headerItem.compId = "Header-" + item.propertyValue.compId;
    headerItem.componentClass = "grid/GridHeader";

    return headerItem;
  }, [item]);

  const getCellComponentInfo = useCallback(() => {
    let cellItem = { ...item };
    cellItem.type = Enums.ComponentType.GRID_CELL;
    cellItem.gridId = item.propertyValue.gridId;
    cellItem.compId = "Cell-" + item.propertyValue.compId;
    cellItem.componentClass = "grid/GridCell";

    return cellItem;
  }, [item]);

  //dragging 중일때 Column 사이의 drop-zone을 활성화
  if (isDragging) {
    ref.current.parentElement.classList.add("dragging");
  } else {
    if (ref.current) ref.current.parentElement.classList.remove("dragging");
  }
  return (
    <div ref={ref} style={{ ...style, opacity }} className={className}>
      <GridHeader
        componentInfo={getHeaderComponentInfo()}
        event="renderEditor"
      />
      <GridCell componentInfo={getCellComponentInfo()} event="renderEditor" />
    </div>
  );
};
export default React.memo(UIEditorGridColumn);
