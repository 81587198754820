import styled from "styled-components";
import Modal from "components/common/modal/UModal";
import UmodalTemplate from "components/common/modal/UModalTemplate";
import { Col, Form, Row } from "react-bootstrap";
import StringUtils from "components/common/utils/StringUtils";
import ArrayUtils from "components/common/utils/ArrayUtils";
import ObjectUtils from "components/common/utils/ObjectUtils";
import USelectbox from "components/common/element/USelectbox";
import { BsPencilSquare } from "react-icons/bs";
import { useEffect, useState } from "react";

const ModalOptionPopupStyle = styled.div`
  .edit-source {
    border: 1px solid #ddd;
  }
`;
// data = [position, backdrop, keyboard, draggable, resizeable, width, animinClass, animOutClass]

/**
 * Popup Open Block에서 모달 Option 설정 버튼 클릭 시 생성되는 팝업 창
 * @param {*} props
 * @returns
 */
const ModalOptionPopup = (props) => {
  const { title, callbackFnc, existOptions, connectFieldData } = props;

  const data = {
    // size: [
    //   { value: "fl", id: "full" },
    //   { value: "lg", id: "large" },
    //   { value: "md", id: "middle" },
    //   { value: "sm", id: "small" },
    // ],
    position: [
      { value: "top" },
      { value: "left" },
      { value: "right" },
      { value: "center" },
      { value: "bottom" },
    ],

    backdrop: [
      { value: "static", id: "static - 배경 비활성화" },
      { value: false, id: "false - 배경 활성화" },
      { value: true, id: "true - 배경 비활성화, 배경 클릭시 창 닫기" },
    ],

    keyboard: [
      { value: true, id: "true" },
      { value: false, id: "false" },
    ],
    draggable: [
      { value: true, id: "true" },
      { value: false, id: "false" },
    ],
    resizable: [
      { value: true, id: "true" },
      { value: false, id: "false" },
    ],

    animInClass: [
      {
        value: "bouncing",
        items: [
          { value: "bounceIn" },
          { value: "bounceInDown" },
          { value: "bounceInLeft" },
          { value: "bounceInRight" },
          { value: "bounceInUp" },
        ],
      },
      {
        value: "fading",
        items: [
          { value: "fadeIn" },
          { value: "fadeInDown" },
          { value: "fadeInDownBig" },
          { value: "fadeInLeft" },
          { value: "fadeInLeftBig" },
          { value: "fadeInRight" },
          { value: "fadeInRightBig" },
          { value: "fadeInUp" },
          { value: "fadeInUpBig" },
        ],
      },
      {
        value: "rotating",
        items: [
          { value: "rotateIn" },
          { value: "rotateInDonLeft" },
          { value: "rotateInDownRight" },
          { value: "rotateInUpLeft" },
          { value: "rotateInUpRight" },
        ],
      },
      {
        value: "zooming",
        items: [
          { value: "zoomIn" },
          { value: "zoomInDown" },
          { value: "zoomInLeft" },
          { value: "zoomInRight" },
          { value: "zoomInUp" },
        ],
      },
      {
        value: "sliding",
        items: [
          { value: "slideInDown" },
          { value: "slideInLeft" },
          { value: "slideInRight" },
          { value: "slideInUp" },
        ],
      },
    ],

    animOutClass: [
      {
        value: "bouncOutg",
        items: [
          { value: "bounceOut" },
          { value: "bounceOutDown" },
          { value: "bounceOutLeft" },
          { value: "bounceOutRight" },
          { value: "bounceOutUp" },
        ],
      },
      {
        value: "fadeOutg",
        items: [
          { value: "fadeOut" },
          { value: "fadeOutDown" },
          { value: "fadeOutDownBig" },
          { value: "fadeOutLeft" },
          { value: "fadeOutLeftBig" },
          { value: "fadeOutRight" },
          { value: "fadeOutRightBig" },
          { value: "fadeOutUp" },
          { value: "fadeOutUpBig" },
        ],
      },
      {
        value: "rotatOutg",
        items: [
          { value: "rotateOut" },
          { value: "rotateOutDownLeft" },
          { value: "rotateOutDownRight" },
          { value: "rotateOutUpLeft" },
          { value: "rotateOutUpRight" },
        ],
      },
      {
        value: "zoomOutg",
        items: [
          { value: "zoomOut" },
          { value: "zoomOutDown" },
          { value: "zoomOutLeft" },
          { value: "zoomOutRight" },
          { value: "zoomOutUp" },
        ],
      },
      {
        value: "slidOutg",
        items: [
          { value: "slideOutDown" },
          { value: "slideOutLeft" },
          { value: "slideOutRight" },
          { value: "slideOutUp" },
        ],
      },
    ],
  };

  //const [size, setSize] = useState(existOptions.size);
  const [position, setPosition] = useState(existOptions.position);
  const [backdrop, setBackdrop] = useState(existOptions.backdrop);
  const [keyboard, setKeyboard] = useState(existOptions.keyboard);
  const [draggable, setDraggable] = useState(existOptions.draggable);
  const [resizable, setResizable] = useState(existOptions.resizable);
  const [sizeInput, setSizeInput] = useState(existOptions.sizeInput);
  const [animInCategory, setAnimInCategory] = useState();
  const [animInItems, setAnimInItems] = useState();
  const [animIn, setAnimIn] = useState(existOptions.animInClass);
  const [animOutCategory, setAnimOutCategory] = useState();
  const [animOutItems, setAnimOutItems] = useState();
  const [animOut, setAnimOut] = useState(existOptions.animOutClass);

  const [options, setOptions] = useState(existOptions);

  useEffect(() => {
    if (!animIn) {
      for (const key of data.animInClass) {
        if (key.value === "fading") {
          setAnimInItems(key.items);
          setAnimIn(key.items[0].value);
          break;
        }
      }
    }
    if (!animOut) {
      for (const key of data.animOutClass) {
        if (key.value === "fadeOutg") {
          setAnimOutItems(key.items);
          setAnimOut(key.items[0].value);
          break;
        }
      }
    }
  }, []);

  useEffect(() => {
    if (
      connectFieldData.length > 1 &&
      ObjectUtils.isObject(JSON.parse(connectFieldData))
    ) {
      const options = JSON.parse(connectFieldData);
      Object.keys(options).map((key) => {
        if (key === "size") {
          //setSize(options[key]);
        } else if (key === "position") {
          setPosition(options[key]);
        } else if (key === "backdrop") {
          setBackdrop(options[key]);
        } else if (key === "keyboard") {
          setKeyboard(options[key]);
        } else if (key === "draggable") {
          setDraggable(options[key]);
        } else if (key === "resizable") {
          setResizable(options[key]);
        } else if (key === "sizeInput") {
          setSizeInput(options[key]);
        } else if (key === "animInClass") {
          for (let i = 0; i < data.animInClass.length; i++) {
            for (let j = 0; j < data.animInClass[i].items.length; j++) {
              if (data.animInClass[i].items[j].value === options[key]) {
                setAnimInCategory(data.animInClass[i].value);
                setAnimInItems(data.animInClass[i].items);
                setAnimIn(data.animInClass[i].items[j].value);
              }
            }
          }
        } else if (key === "animOutClass") {
          for (let i = 0; i < data.animOutClass.length; i++) {
            for (let j = 0; j < data.animOutClass[i].items.length; j++) {
              if (data.animOutClass[i].items[j].value === options[key]) {
                setAnimOutCategory(data.animOutClass[i].value);
                setAnimOutItems(data.animOutClass[i].items);
                setAnimOut(data.animOutClass[i].items[j].value);
              }
            }
          }
        }
      });
      setOptions(options);
    }
  }, [connectFieldData]);

  // const onChangeSize = (e) => {
  //   const newValue = e.target.value;
  //   setSize(newValue);

  //   options.size = newValue;
  //   setOptions(options);
  // };

  const onChangePosition = (e) => {
    const newValue = e.target.value;
    setPosition(newValue);

    options.position = newValue;
    setOptions(options);
  };

  const onChangeBackdrop = (e) => {
    const newValue = e.target.value;
    setBackdrop(newValue);

    options.backdrop = newValue;
    setOptions(options);
  };

  const onChangeKeyboard = (e) => {
    const newValue = e.target.value;
    setKeyboard(newValue);

    options.keyboard = newValue;
    setOptions(options);
  };

  const onChangeDraggable = (e) => {
    const newValue = e.target.value;
    setDraggable(newValue);

    options.draggable = newValue;
    setOptions(options);
  };

  const onChangeResizable = (e) => {
    const newValue = e.target.value;
    setResizable(newValue);

    options.resizable = newValue;
    setOptions(options);
  };

  const onChangeSizeInput = (e) => {
    const newValue = e.target.value;
    setSizeInput(newValue);

    options.width = newValue;
    setOptions(options);
  };

  const onChangeAnimInCategory = (e) => {
    const newValue = e.target.value;
    setAnimInCategory(newValue);

    for (const key of data.animInClass) {
      if (key.value === newValue) {
        setAnimInItems(key.items);
        break;
      }
    }
  };

  const onChangeAnimOutCategory = (e) => {
    const newValue = e.target.value;
    setAnimOutCategory(newValue);

    for (const key of data.animOutClass) {
      if (key.value === newValue) {
        setAnimOutItems(key.items);
        break;
      }
    }
  };

  const onChangeAnimIn = (e) => {
    const newValue = e.target.value;
    setAnimIn(newValue);

    options.animInClass = newValue;
    setOptions(options);
  };

  const onChangeAnimOut = (e) => {
    const newValue = e.target.value;
    setAnimOut(newValue);

    options.animOutClass = newValue;
    setOptions(options);
  };

  const onLoadOption = () => {
    callbackFnc(JSON.stringify(options));
  };

  return (
    <ModalOptionPopupStyle>
      <Modal>
        <Modal.Header title={title} />
        <Modal.Body>
          <UmodalTemplate>
            <Row>
              {/* <Form.Group as={Col} className="mb-3">
                <Form.Label>크기</Form.Label>
                <USelectbox
                  id="size"
                  name="size"
                  size="default"
                  onChange={(e) => onChangeSize(e)}
                  value={StringUtils.defaultString(size, "선택")}
                  items={data.size || []}
                  options={{ matchCd: "value", matchNm: "id" }}
                />
              </Form.Group> */}

              <Form.Group as={Col}>
                <Form.Label>위치</Form.Label>
                <USelectbox
                  id="position"
                  name="position"
                  size="default"
                  onChange={(e) => onChangePosition(e)}
                  value={StringUtils.defaultString(position, "center")}
                  items={data.position || []}
                  options={{ matchCd: "value", matchNm: "value" }}
                />
              </Form.Group>
              <Form.Group as={Col} className="mb-3">
                <Form.Label>배경</Form.Label>
                <USelectbox
                  id="backdrop"
                  name="backdrop"
                  size="default"
                  onChange={(e) => onChangeBackdrop(e)}
                  value={StringUtils.defaultString(backdrop, "static")}
                  items={data.backdrop || []}
                  options={{ matchCd: "value", matchNm: "id" }}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col}>
                <Form.Label>ESC 키 입력시 창 닫을지 여부</Form.Label>
                <USelectbox
                  id="keyboard"
                  name="keyboard"
                  size="default"
                  onChange={(e) => onChangeKeyboard(e)}
                  value={StringUtils.defaultString(keyboard, "true")}
                  items={data.keyboard || []}
                  options={{ matchCd: "value", matchNm: "id" }}
                />
              </Form.Group>
              <Form.Group as={Col} className="mb-3">
                <Form.Label>drag로 이동 기능</Form.Label>
                <USelectbox
                  id="draggable"
                  name="draggable"
                  size="default"
                  onChange={(e) => onChangeDraggable(e)}
                  value={StringUtils.defaultString(draggable, "true")}
                  items={data.draggable || []}
                  options={{ matchCd: "value", matchNm: "id" }}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col}>
                <Form.Label>크기 변경 가능</Form.Label>
                <USelectbox
                  id="resizable"
                  name="resizable"
                  size="default"
                  onChange={(e) => onChangeResizable(e)}
                  value={StringUtils.defaultString(resizable, "true")}
                  items={data.resizable || []}
                  options={{ matchCd: "value", matchNm: "id" }}
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>크기 입력</Form.Label>
                {/* <Row className="mb-2"> */}
                {/* <Col> */}
                <input
                  type="text"
                  id="sizeInput"
                  className="form-control form-select-sm"
                  placeholder="모달창 크기 직접입력 값 (%, pt, px..)"
                  value={StringUtils.defaultString(sizeInput, "")}
                  onChange={(e) => onChangeSizeInput(e)}
                />
                {/* </Col> */}
                {/* </Row> */}
              </Form.Group>
            </Row>

            <Row>
              <Form.Group as={Col} className="mb-3">
                <Form.Label>모달창 열릴 때 효과</Form.Label>
                <USelectbox
                  id="animInCategory"
                  name="animInCategory"
                  size="default"
                  onChange={(e) => onChangeAnimInCategory(e)}
                  value={StringUtils.defaultString(animInCategory, "fading")}
                  items={data.animInClass || []}
                  options={{ matchCd: "value", matchNm: "value" }}
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>상세 설정</Form.Label>
                <USelectbox
                  id="animIn"
                  name="animIn"
                  size="default"
                  onChange={(e) => onChangeAnimIn(e)}
                  value={StringUtils.defaultString(animIn, "fadeIn")}
                  items={animInItems || []}
                  options={{ matchCd: "value", matchNm: "value" }}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} className="mb-3">
                <Form.Label>모달창 닫힐 때 효과</Form.Label>
                <USelectbox
                  id="animOutCategory"
                  name="animOutCategory"
                  size="default"
                  onChange={(e) => onChangeAnimOutCategory(e)}
                  value={StringUtils.defaultString(animOutCategory, "fadeOutg")}
                  items={data.animOutClass || []}
                  options={{ matchCd: "value", matchNm: "value" }}
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>상세 설정</Form.Label>
                <USelectbox
                  id="animOut"
                  name="animOut"
                  size="default"
                  onChange={(e) => onChangeAnimOut(e)}
                  value={StringUtils.defaultString(animOut, "fadeOut")}
                  items={animOutItems || []}
                  options={{ matchCd: "value", matchNm: "value" }}
                />
              </Form.Group>
            </Row>
          </UmodalTemplate>
        </Modal.Body>
        <Modal.Footer>
          <Modal.Footer.Button
            type="button"
            className="btn btn-primary"
            onClick={onLoadOption}
          >
            <BsPencilSquare />
            &nbsp;확인
          </Modal.Footer.Button>
        </Modal.Footer>
      </Modal>
    </ModalOptionPopupStyle>
  );
};

export default ModalOptionPopup;
