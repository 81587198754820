import Modal from "components/common/modal/UModal";
import UmodalTemplate from "components/common/modal/UModalTemplate";
import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import USelectbox from "components/common/element/USelectbox";
import StringUtils from "components/common/utils/StringUtils";
import Popup from "components/common/Popup";
import Message from "components/common/Message";
import { Enums } from "components/builder/BuilderEnum";

const MessageContext = {
  D: {
    dupField: "이미 등록된 칼럼 명 입니다.",
    columnNm: "컬럼명을 입력해주세요",
    physFieldNm: "물리 Field 명을 입력해주세요",
    logFieldNm: "논리 Field 명을 입력해주세요",
    uiDisplayType: "UI Display Type을 선택 해주세요",
    alias: "Alias를 입력해주세요.",
    formula: "Field Formula를 입력해주세요",
  },
  S: {
    columnNm: "ID를 입력해주세요",
    dupField: "이미 등록된 ID 입니다.",
    logFieldNm: "논리 명을 입력해주세요",
    uiDisplayType: "UI Display Type을 선택 해주세요",
  },
  E: {
    dupField: "이미 등록된 칼럼 명 입니다.",
    columnNm: "컬럼명을 입력해주세요",
    physFieldNm: "물리 Field 명을 입력해주세요",
    logFieldNm: "논리 Field 명을 입력해주세요",
    uiDisplayType: "UI Display Type을 선택 해주세요",
  },
};

function DataModelAddRowPopup({ dataModelType, entity, onOk, ...props }) {
  const [physFieldNm, setphysFieldNm] = useState("");
  const [logFieldNm, setLogFieldNm] = useState("");
  const [columnNm, setColumnNm] = useState("");
  const [columnType, setColumnType] = useState(null);
  const [autoIncreamentYn, setAutoIncreamentYn] = useState("N");
  const [pkYn, setPkYn] = useState("N");
  const [requiredYn, setRequiredYn] = useState("N");
  const [uniqueYn, setUniqueYn] = useState("N");
  const [readonlyYn, setReadonlyYn] = useState("Y");
  const [uiDisplayType, setUiDisplayType] = useState("string");
  const [formula, setFormula] = useState("");
  const [fieldOption, setFieldOption] = useState({});
  const [alias, setAlias] = useState("");
  const [insertDefault, setInsertDefault] = useState("");
  const [updateDefault, setUpdateDefault] = useState("");
  const [virtualYn, setVirtualYn] = useState("Y");

  const onSaveVirtualField = () => {
    if (StringUtils.isEmpty(columnNm)) {
      return Message.alert(
        MessageContext[dataModelType].columnNm,
        Enums.MessageType.ERROR
      );
    }
    if (StringUtils.isEmpty(physFieldNm)) {
      return Message.alert(
        MessageContext[dataModelType].physFieldNm,
        Enums.MessageType.ERROR
      );
    }
    if (StringUtils.isEmpty(logFieldNm)) {
      return Message.alert(
        MessageContext[dataModelType].logFieldNm,
        Enums.MessageType.ERROR
      );
    }
    if (StringUtils.isEmpty(uiDisplayType)) {
      return Message.alert(
        MessageContext[dataModelType].uiDisplayType,
        Enums.MessageType.ERROR
      );
    }
    if (dataModelType === "D" && StringUtils.isEmpty(alias)) {
      return Message.alert(
        MessageContext[dataModelType].alias,
        Enums.MessageType.ERROR
      );
    }
    if (dataModelType === "D" && StringUtils.isEmpty(formula)) {
      return Message.alert(
        MessageContext[dataModelType].formula,
        Enums.MessageType.ERROR
      );
    }
    //기존에 등록된 칼럼은 안됨
    if (entity) {
      const dupField = entity.dataModelEntityFields.find(
        (field) => field.columnNm.toLowerCase() === columnNm.toLowerCase()
      );
      if (dupField) {
        return Message.alert(
          MessageContext[dataModelType].dupField,
          Enums.MessageType.ERROR
        );
      }
    }

    const body = {
      physFieldNm,
      logFieldNm,
      columnNm,
      columnType,
      autoIncreamentYn,
      pkYn,
      requiredYn,
      uniqueYn,
      readonlyYn,
      uiDisplayType,
      formula,
      fieldOption,
      alias,
      insertDefault,
      updateDefault,
      virtualYn,
      compId: StringUtils.getUuid(),
      type: Enums.EntityComponentType.ENTITY_FIELD,
    };
    if (onOk) onOk(body);
    Popup.close();
  };

  if (StringUtils.equalsIgnoreCase(dataModelType, "D")) {
    //다이나믹 API 버츄얼 칼럼 추가
    return (
      <Modal>
        <Modal.Header title={"새로운 가상 Field 추가"} />
        <Modal.Body>
          <UmodalTemplate>
            <Form>
              <Form.Group className="mt-3 ">
                <Form.Label className="required">Table Column 명</Form.Label>
                <Form.Control
                  value={columnNm}
                  onChange={(e) => setColumnNm(e.currentTarget.value)}
                />
              </Form.Group>
              <Form.Group className="mt-3">
                <Form.Label className="required">물리 명</Form.Label>
                <Form.Control
                  id={"physFieldNm"}
                  value={physFieldNm}
                  onChange={(e) => {
                    setphysFieldNm(e.currentTarget.value);
                  }}
                />
              </Form.Group>
              <Form.Group className="mt-3">
                <Form.Label className="required">논리 명</Form.Label>
                <Form.Control
                  id={"logFieldNm"}
                  value={logFieldNm}
                  onChange={(e) => {
                    setLogFieldNm(e.currentTarget.value);
                  }}
                />
              </Form.Group>
              <Form.Group className="mt-3">
                <Form.Label className="required">Alias 명</Form.Label>
                <Form.Control
                  id={"alias"}
                  value={alias}
                  onChange={(e) => {
                    setAlias(e.currentTarget.value);
                  }}
                />
              </Form.Group>

              {/* <Form.Group className="mt-3">
                <Form.Label>Table Column Type</Form.Label>
                <Form.Control id={"alias"} value={columnType} disabled />
              </Form.Group> */}
              <Form.Group className="mt-3">
                <Form.Label className="required">UI Display</Form.Label>
                <USelectbox
                  id={"uiDisplayType"}
                  onChange={(e) => {
                    setUiDisplayType(e.target.value);
                  }}
                  defaultValue={StringUtils.defaultString(
                    uiDisplayType,
                    "string"
                  )}
                  items={[
                    { value: "string", text: "문자열" },
                    { value: "number", text: "숫자" },
                    { value: "date", text: "날짜" },
                  ]}
                  options={{
                    matchCd: "value",
                    matchNm: "text",
                  }}
                  size="m"
                />
              </Form.Group>
              <Form.Group className="mt-3">
                <Form.Label className="required">Field Formula</Form.Label>
                <Form.Control
                  id={"formula"}
                  value={formula}
                  onChange={(e) => {
                    setFormula(e.currentTarget.value);
                  }}
                />
              </Form.Group>
            </Form>
          </UmodalTemplate>
        </Modal.Body>
        <Modal.Footer>
          <Modal.Footer.Button onClick={onSaveVirtualField}>
            저장
          </Modal.Footer.Button>
        </Modal.Footer>
      </Modal>
    );
  } else if (StringUtils.equalsIgnoreCase(dataModelType, "S")) {
    //서비스 API 버츄얼 칼럼 추가
    return (
      <Modal>
        <Modal.Header title={"새로운 Field 추가"} />
        <Modal.Body>
          <UmodalTemplate>
            <Form>
              <Form.Group className="mt-3 ">
                <Form.Label className="required">ID</Form.Label>
                <Form.Control value={columnNm} disabled />
              </Form.Group>
              <Form.Group className="mt-3">
                <Form.Label className="required">물리 명</Form.Label>
                <Form.Control
                  id={"physFieldNm"}
                  value={physFieldNm}
                  onChange={(e) => {
                    setColumnNm(e.currentTarget.value);
                    setphysFieldNm(e.currentTarget.value);
                  }}
                />
              </Form.Group>
              <Form.Group className="mt-3">
                <Form.Label className="required">논리 명</Form.Label>
                <Form.Control
                  id={"logFieldNm"}
                  value={logFieldNm}
                  onChange={(e) => {
                    setLogFieldNm(e.currentTarget.value);
                  }}
                />
              </Form.Group>

              <Form.Group className="mt-3">
                <Form.Label className="required">UI Display</Form.Label>
                <USelectbox
                  id={"uiDisplayType"}
                  onChange={(e) => {
                    setUiDisplayType(e.target.value);
                  }}
                  defaultValue={StringUtils.defaultString(
                    uiDisplayType,
                    "string"
                  )}
                  items={[
                    { text: "문자열", value: "string" },
                    { text: "숫자", value: "number" },
                    { text: "날짜", value: "date" },
                  ]}
                  options={{
                    matchCd: "value",
                    matchNm: "text",
                  }}
                  size="m"
                />
              </Form.Group>
            </Form>
          </UmodalTemplate>
        </Modal.Body>
        <Modal.Footer>
          <Modal.Footer.Button onClick={onSaveVirtualField}>
            저장
          </Modal.Footer.Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default DataModelAddRowPopup;
