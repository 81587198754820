import { Enums } from "components/builder/BuilderEnum";
import ArrayUtils from "components/common/utils/ArrayUtils";
import ObjectUtils from "components/common/utils/ObjectUtils";
import StringUtils from "components/common/utils/StringUtils";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { MarkerType } from "reactflow";
import useErdTableListState from "./useErdTableListState";

const useErdRender = (theme) => {
  const [Nodes, setNodes] = useState([]);
  const [Edge, setEdge] = useState([]);
  const erd = useSelector((state) => state.erd);
  const [allTableList] = useErdTableListState();
  const { output, memo, activatedErd } = erd;

  useEffect(() => {
    renderNode();
  }, [output, memo, activatedErd, theme]);

  /**
   * 엣지 렌더링
   */
  const renderTableEdge = (targetNodeCompId, table, relation) => {
    let edge = {
      // id: `edge-from-${from.entity.physEntityNm}-to-${entity.physEntityNm}`,
      id: StringUtils.getUuid(),
      target: targetNodeCompId,
      source: table.compId,
      type: "floating",
      animated: relation.relationType === "identifying" ? true : false,
      data: {
        relation: relation,
      },
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 8,
        height: 8,
        color: theme === "light" ? "dodgerblue" : "#60b9c4",
      },
      style: {
        strokeWidth: 4,
        stroke: theme === "light" ? "dodgerblue" : "#60b9c4",
      },
    };
    return edge;
  };

  /**
   * 노드 & 엣지 렌더링
   */
  const renderNode = () => {
    let nodes = []; //랜더링되는 노드
    let edges = []; //랜더링 되는 연결선

    if (!ObjectUtils.isEmpty(erd.activatedErd)) {
      if (erd.activatedErd.type === "all") {
        /** 전체 보기인 경우  */

        /**
         * 1. 각 영역의 최대 넓이와 최대 높이를 구한다.
         *  - 영역간 간격은 100px 정도로 한다.
         *  - 최대 영역 사이즈로 각 영역구역을 지정한다.
         *  - 최대 영역 사이즈는 X 최대값 + 150, Y 최대값 + 150 으로 한다.
         * 2. 각 영역마다 테두리로 감싼다.
         * 3. 한 화면에 넣는다
         */

        //area 최대 사이즈 보정치
        const maxSizeAccurate = 200;
        const maxAreaSize = {
          x: 0,
          y: 0,
        };
        /**
         * 에어리어 포지션
         */
        let parentPosition = {
          x: 0,
          y: 0,
        };
        // 멀티 에어리어 일때 3개씩 1개 행으로 한다.
        // 이후 2행으로 넘어갈때의 index 값
        let yIndex = 0;
        const areas = erd.output.areas;

        for (const [index, key] of Object.keys(areas).entries()) {
          const area = areas[key];
          const { table: tableList } = area;
          /**
           * 1. 최소 위치(X,Y) 구하고
           * 2. 최대 위치(X,Y) 구하고
           *
           * 최대 위치 X는 노드 X값 + 노드 가로
           * 최대 위치 Y는 노드 Y값 + 노드 세로
           * 노드 세로 길이는 각 필드당 26.5
           * 필드 헤더 30,
           * 테이블 헤더 30
           * 을 기준으로 한다.
           */
          if (ArrayUtils.isEmpty(tableList)) continue;
          const areaMinPosition = {
            x: tableList[0].position.x,
            y: tableList[0].position.y,
          };
          const areaMaxPosition = {
            x: tableList[0].position.x,
            y: tableList[0].position.y,
          };

          //1. 각영역에서 사이즈를 추출하기 위한 첫번째 테이블 루프
          for (const table of tableList) {
            if (table.position.x < areaMinPosition.x) {
              areaMinPosition.x = table.position.x;
            }

            if (table.position.y < areaMinPosition.y) {
              areaMinPosition.y = table.position.y;
            }

            if (table.position.x + 500 > areaMaxPosition.x) {
              areaMaxPosition.x = table.position.x + 500;
            }
            const tableHeight =
              table.propertyValue.columnList.length * 26.5 + 60;
            if (table.position.y + tableHeight > areaMaxPosition.y) {
              areaMaxPosition.y = table.position.y + tableHeight;
            }

            //영역 사이즈
            let areaSize = {
              x: Math.abs(areaMaxPosition.x - areaMinPosition.x),
              y: Math.abs(areaMaxPosition.y - areaMinPosition.y),
            };

            if (maxAreaSize.x < areaSize.x) {
              maxAreaSize.x = areaSize.x;
            }
            if (maxAreaSize.y < areaSize.y) {
              maxAreaSize.y = areaSize.y;
            }
          }
          //2. 최소 영역 확인 후 데이터를 입력하기 위한 루프
          for (const table of tableList) {
            //- 최대 영역 사이즈는 X 최대값 + 150, Y 최대값 + 150 으로 한다.
            parentPosition.x =
              (index % 3) * (maxAreaSize.x + maxSizeAccurate) +
              (index % 3) * 100;
            parentPosition.y = maxAreaSize.y + maxSizeAccurate;
            // 각 노드의 포지션은 최소 위치 노드에서 마이너스 하여 0,0 에서 시작하도록 한다.
            // Sub Flow의 포지션은 0부터 시작한다.
            // 상하 좌우 여유를 위해 +50 을 각 포지션에 해준다.

            const node = {
              id: table.compId,
              type: Enums.ErdType.TABLE,
              position: {
                x: table.position.x - areaMinPosition.x + 50,
                y: table.position.y - areaMinPosition.y + 50,
              },
              data: {
                ...table,
              },
              style: {
                zIndex: 1,
              },
              parentNode: area.compId,
              extent: "parent",
              isSelectable: true,
            };
            nodes.push(node);

            const { relation: relations } = table;

            if (ArrayUtils.isArray(relations) && relations.length > 0) {
              for (const relation of relations) {
                const targetNode = allTableList.find(
                  (n) => n.propertyValue.physicalTableNm === relation.target
                );
                //다른 영역에 관계를 가진 경우는 타겟 노드가 검색되지 않을수도 있기 때문에 있을때만 엣지를 추가하도록 한다.
                if (targetNode) {
                  const edge = renderTableEdge(
                    targetNode.compId,
                    table,
                    relation
                  );
                  edges.push(edge);
                }
              }
            }
          }
          /**
           *3.  Area Template 정의
           * 사이즈는 가로세로 중 큰걸 기준으로 해서 정사각형 모양이 나오도록 한다.
           * 3개씩 가로 정렬하고 3개가 넘어가면 밑으로 내려서 그리드 형태다 나오도록 한다.
           */
          let areaSize = 0;
          if (maxAreaSize.x > maxAreaSize.y) {
            areaSize = maxAreaSize.x;
          } else if (maxAreaSize.x < maxAreaSize.y) {
            areaSize = maxAreaSize.y;
          } else {
            areaSize = maxAreaSize.x;
          }
          const { table, memo, ...otherData } = area;
          let yPosition = 0;

          if (index % 3 === 0) {
            yPosition =
              yIndex * (areaSize + maxSizeAccurate + (yIndex !== 0 ? 100 : 0));
            yIndex++;
          }
          const areaNode = {
            id: area.compId,
            className: "erd-area-template",
            type: Enums.ErdType.AREA_TEMPLATE,
            position: {
              x: parentPosition.x,
              y: yPosition,
              // y: 0,
            },
            style: {
              width: areaSize + maxSizeAccurate,
              height: areaSize + maxSizeAccurate,
              zIndex: 0,
            },
            data: {
              ...otherData,
            },
          };
          nodes.push(areaNode);
        }
      } else {
        /** 전체 보기가 아닌 경우  */
        const { table: tableList, memo: memoList = [] } =
          erd.output.areas[erd.activatedErd.compId];

        //memo
        for (const memo of memoList) {
          const node = {
            //노드 정보
            id: memo.compId,
            style: memo.style,
            type: Enums.WorkflowNodeType.MEMO, //WorkflowNodeTypes에서 찾을것
            position: memo.position,
            data: {
              memo,
            },
          };
          nodes.push(node);
        }

        for (const table of tableList) {
          const node = {
            id: table.compId,
            type: Enums.ErdType.TABLE,
            position: table.position,
            data: {
              ...table,
            },
          };
          nodes.push(node);

          const { relation: relations } = table;

          if (ArrayUtils.isArray(relations) && relations.length > 0) {
            for (const relation of relations) {
              const targetNode = nodes.find(
                (n) => n.data.propertyValue.physicalTableNm === relation.target
              );
              //다른 영역에 관계를 가진 경우는 타겟 노드가 검색되지 않을수도 있기 때문에 있을때만 엣지를 추가하도록 한다.
              if (targetNode) {
                const edge = renderTableEdge(targetNode.id, table, relation);
                edges.push(edge);
              }
            }
          }
        }
      }
      setNodes(nodes);
      setEdge(edges);
    }
  };

  return [Nodes, Edge];
};

export default useErdRender;
