export const faBrandIcon = [
  "Fa500Px",
  "FaAccessibleIcon",
  "FaAccusoft",
  "FaAcquisitionsIncorporated",
  "FaAdn",
  "FaAdversal",
  "FaAffiliatetheme",
  "FaAirbnb",
  "FaAlgolia",
  "FaAlipay",
  "FaAmazonPay",
  "FaAmazon",
  "FaAmilia",
  "FaAndroid",
  "FaAngellist",
  "FaAngrycreative",
  "FaAngular",
  "FaAppStoreIos",
  "FaAppStore",
  "FaApper",
  "FaApplePay",
  "FaApple",
  "FaArtstation",
  "FaAsymmetrik",
  "FaAtlassian",
  "FaAudible",
  "FaAutoprefixer",
  "FaAvianex",
  "FaAviato",
  "FaAws",
  "FaBandcamp",
  "FaBattleNet",
  "FaBehanceSquare",
  "FaBehance",
  "FaBimobject",
  "FaBitbucket",
  "FaBitcoin",
  "FaBity",
  "FaBlackTie",
  "FaBlackberry",
  "FaBloggerB",
  "FaBlogger",
  "FaBluetoothB",
  "FaBluetooth",
  "FaBootstrap",
  "FaBtc",
  "FaBuffer",
  "FaBuromobelexperte",
  "FaBuyNLarge",
  "FaBuysellads",
  "FaCanadianMapleLeaf",
  "FaCcAmazonPay",
  "FaCcAmex",
  "FaCcApplePay",
  "FaCcDinersClub",
  "FaCcDiscover",
  "FaCcJcb",
  "FaCcMastercard",
  "FaCcPaypal",
  "FaCcStripe",
  "FaCcVisa",
  "FaCentercode",
  "FaCentos",
  "FaChrome",
  "FaChromecast",
  "FaCloudflare",
  "FaCloudscale",
  "FaCloudsmith",
  "FaCloudversify",
  "FaCodepen",
  "FaCodiepie",
  "FaConfluence",
  "FaConnectdevelop",
  "FaContao",
  "FaCottonBureau",
  "FaCpanel",
  "FaCreativeCommonsBy",
  "FaCreativeCommonsNcEu",
  "FaCreativeCommonsNcJp",
  "FaCreativeCommonsNc",
  "FaCreativeCommonsNd",
  "FaCreativeCommonsPdAlt",
  "FaCreativeCommonsPd",
  "FaCreativeCommonsRemix",
  "FaCreativeCommonsSa",
  "FaCreativeCommonsSamplingPlus",
  "FaCreativeCommonsSampling",
  "FaCreativeCommonsShare",
  "FaCreativeCommonsZero",
  "FaCreativeCommons",
  "FaCriticalRole",
  "FaCss3Alt",
  "FaCss3",
  "FaCuttlefish",
  "FaDAndDBeyond",
  "FaDAndD",
  "FaDailymotion",
  "FaDashcube",
  "FaDeezer",
  "FaDelicious",
  "FaDeploydog",
  "FaDeskpro",
  "FaDev",
  "FaDeviantart",
  "FaDhl",
  "FaDiaspora",
  "FaDigg",
  "FaDigitalOcean",
  "FaDiscord",
  "FaDiscourse",
  "FaDochub",
  "FaDocker",
  "FaDraft2Digital",
  "FaDribbbleSquare",
  "FaDribbble",
  "FaDropbox",
  "FaDrupal",
  "FaDyalog",
  "FaEarlybirds",
  "FaEbay",
  "FaEdgeLegacy",
  "FaEdge",
  "FaElementor",
  "FaEllo",
  "FaEmber",
  "FaEmpire",
  "FaEnvira",
  "FaErlang",
  "FaEthereum",
  "FaEtsy",
  "FaEvernote",
  "FaExpeditedssl",
  "FaFacebookF",
  "FaFacebookMessenger",
  "FaFacebookSquare",
  "FaFacebook",
  "FaFantasyFlightGames",
  "FaFedex",
  "FaFedora",
  "FaFigma",
  "FaFirefoxBrowser",
  "FaFirefox",
  "FaFirstOrderAlt",
  "FaFirstOrder",
  "FaFirstdraft",
  "FaFlickr",
  "FaFlipboard",
  "FaFly",
  "FaFontAwesomeAlt",
  "FaFontAwesomeFlag",
  "FaFontAwesomeLogoFull",
  "FaFontAwesome",
  "FaFonticonsFi",
  "FaFonticons",
  "FaFortAwesomeAlt",
  "FaFortAwesome",
  "FaForumbee",
  "FaFoursquare",
  "FaFreeCodeCamp",
  "FaFreebsd",
  "FaFulcrum",
  "FaGalacticRepublic",
  "FaGalacticSenate",
  "FaGetPocket",
  "FaGgCircle",
  "FaGg",
  "FaGitAlt",
  "FaGitSquare",
  "FaGit",
  "FaGithubAlt",
  "FaGithubSquare",
  "FaGithub",
  "FaGitkraken",
  "FaGitlab",
  "FaGitter",
  "FaGlideG",
  "FaGlide",
  "FaGofore",
  "FaGoodreadsG",
  "FaGoodreads",
  "FaGoogleDrive",
  "FaGooglePay",
  "FaGooglePlay",
  "FaGooglePlusG",
  "FaGooglePlusSquare",
  "FaGooglePlus",
  "FaGoogleWallet",
  "FaGoogle",
  "FaGratipay",
  "FaGrav",
  "FaGripfire",
  "FaGrunt",
  "FaGuilded",
  "FaGulp",
  "FaHackerNewsSquare",
  "FaHackerNews",
  "FaHackerrank",
  "FaHips",
  "FaHireAHelper",
  "FaHive",
  "FaHooli",
  "FaHornbill",
  "FaHotjar",
  "FaHouzz",
  "FaHtml5",
  "FaHubspot",
  "FaIdeal",
  "FaImdb",
  "FaInnosoft",
  "FaInstagramSquare",
  "FaInstagram",
  "FaInstalod",
  "FaIntercom",
  "FaInternetExplorer",
  "FaInvision",
  "FaIoxhost",
  "FaItchIo",
  "FaItunesNote",
  "FaItunes",
  "FaJava",
  "FaJediOrder",
  "FaJenkins",
  "FaJira",
  "FaJoget",
  "FaJoomla",
  "FaJsSquare",
  "FaJs",
  "FaJsfiddle",
  "FaKaggle",
  "FaKeybase",
  "FaKeycdn",
  "FaKickstarterK",
  "FaKickstarter",
  "FaKorvue",
  "FaLaravel",
  "FaLastfmSquare",
  "FaLastfm",
  "FaLeanpub",
  "FaLess",
  "FaLine",
  "FaLinkedinIn",
  "FaLinkedin",
  "FaLinode",
  "FaLinux",
  "FaLyft",
  "FaMagento",
  "FaMailchimp",
  "FaMandalorian",
  "FaMarkdown",
  "FaMastodon",
  "FaMaxcdn",
  "FaMdb",
  "FaMedapps",
  "FaMediumM",
  "FaMedium",
  "FaMedrt",
  "FaMeetup",
  "FaMegaport",
  "FaMendeley",
  "FaMicroblog",
  "FaMicrosoft",
  "FaMix",
  "FaMixcloud",
  "FaMixer",
  "FaMizuni",
  "FaModx",
  "FaMonero",
  "FaNapster",
  "FaNeos",
  "FaNimblr",
  "FaNodeJs",
  "FaNode",
  "FaNpm",
  "FaNs8",
  "FaNutritionix",
  "FaOctopusDeploy",
  "FaOdnoklassnikiSquare",
  "FaOdnoklassniki",
  "FaOldRepublic",
  "FaOpencart",
  "FaOpenid",
  "FaOpera",
  "FaOptinMonster",
  "FaOrcid",
  "FaOsi",
  "FaPage4",
  "FaPagelines",
  "FaPalfed",
  "FaPatreon",
  "FaPaypal",
  "FaPennyArcade",
  "FaPerbyte",
  "FaPeriscope",
  "FaPhabricator",
  "FaPhoenixFramework",
  "FaPhoenixSquadron",
  "FaPhp",
  "FaPiedPiperAlt",
  "FaPiedPiperHat",
  "FaPiedPiperPp",
  "FaPiedPiperSquare",
  "FaPiedPiper",
  "FaPinterestP",
  "FaPinterestSquare",
  "FaPinterest",
  "FaPlaystation",
  "FaProductHunt",
  "FaPushed",
  "FaPython",
  "FaQq",
  "FaQuinscape",
  "FaQuora",
  "FaRProject",
  "FaRaspberryPi",
  "FaRavelry",
  "FaReact",
  "FaReacteurope",
  "FaReadme",
  "FaRebel",
  "FaRedRiver",
  "FaRedditAlien",
  "FaRedditSquare",
  "FaReddit",
  "FaRedhat",
  "FaRenren",
  "FaReplyd",
  "FaResearchgate",
  "FaResolving",
  "FaRev",
  "FaRocketchat",
  "FaRockrms",
  "FaRust",
  "FaSafari",
  "FaSalesforce",
  "FaSass",
  "FaSchlix",
  "FaScribd",
  "FaSearchengin",
  "FaSellcast",
  "FaSellsy",
  "FaServicestack",
  "FaShirtsinbulk",
  "FaShopify",
  "FaShopware",
  "FaSimplybuilt",
  "FaSistrix",
  "FaSith",
  "FaSketch",
  "FaSkyatlas",
  "FaSkype",
  "FaSlackHash",
  "FaSlack",
  "FaSlideshare",
  "FaSnapchatGhost",
  "FaSnapchatSquare",
  "FaSnapchat",
  "FaSoundcloud",
  "FaSourcetree",
  "FaSpeakap",
  "FaSpeakerDeck",
  "FaSpotify",
  "FaSquarespace",
  "FaStackExchange",
  "FaStackOverflow",
  "FaStackpath",
  "FaStaylinked",
  "FaSteamSquare",
  "FaSteamSymbol",
  "FaSteam",
  "FaStickerMule",
  "FaStrava",
  "FaStripeS",
  "FaStripe",
  "FaStudiovinari",
  "FaStumbleuponCircle",
  "FaStumbleupon",
  "FaSuperpowers",
  "FaSupple",
  "FaSuse",
  "FaSwift",
  "FaSymfony",
  "FaTeamspeak",
  "FaTelegramPlane",
  "FaTelegram",
  "FaTencentWeibo",
  "FaTheRedYeti",
  "FaThemeco",
  "FaThemeisle",
  "FaThinkPeaks",
  "FaTiktok",
  "FaTradeFederation",
  "FaTrello",
  "FaTripadvisor",
  "FaTumblrSquare",
  "FaTumblr",
  "FaTwitch",
  "FaTwitterSquare",
  "FaTwitter",
  "FaTypo3",
  "FaUber",
  "FaUbuntu",
  "FaUikit",
  "FaUmbraco",
  "FaUncharted",
  "FaUniregistry",
  "FaUnity",
  "FaUnsplash",
  "FaUntappd",
  "FaUps",
  "FaUsb",
  "FaUsps",
  "FaUssunnah",
  "FaVaadin",
  "FaViacoin",
  "FaViadeoSquare",
  "FaViadeo",
  "FaViber",
  "FaVimeoSquare",
  "FaVimeoV",
  "FaVimeo",
  "FaVine",
  "FaVk",
  "FaVnv",
  "FaVuejs",
  "FaWatchmanMonitoring",
  "FaWaze",
  "FaWeebly",
  "FaWeibo",
  "FaWeixin",
  "FaWhatsappSquare",
  "FaWhatsapp",
  "FaWhmcs",
  "FaWikipediaW",
  "FaWindows",
  "FaWix",
  "FaWizardsOfTheCoast",
  "FaWodu",
  "FaWolfPackBattalion",
  "FaWordpressSimple",
  "FaWordpress",
  "FaWpbeginner",
  "FaWpexplorer",
  "FaWpforms",
  "FaWpressr",
  "FaXbox",
  "FaXingSquare",
  "FaXing",
  "FaYCombinator",
  "FaYahoo",
  "FaYammer",
  "FaYandexInternational",
  "FaYandex",
  "FaYarn",
  "FaYelp",
  "FaYoast",
  "FaYoutubeSquare",
  "FaYoutube",
  "FaZhihu",
];
