import React, { useEffect, useState } from "react";
import CodeMirror from "@uiw/react-codemirror";
import { useSelector } from "react-redux";
import { javascript } from "@codemirror/lang-javascript";
import { json } from "@codemirror/lang-json";

const EventHandlerWorkspaceCode = ({ show, ...props }) => {
  const eventWorkspace = useSelector((state) => state.eventHandler.workspace);
  return (
    <div className={`edit-source ${show ? "show" : "hidden"}`}>
      <div className="codeMirror-area">
        <CodeMirror
          value={JSON.stringify(eventWorkspace, null, 2)}
          className="source-container"
          height="calc(100vh - 30px)"
          extensions={[json(true)]}
          autoFocus={false}
          editable={false}
        />
      </div>
    </div>
  );
};

export default EventHandlerWorkspaceCode;
