import Api from "components/common/Api";
import Message from "components/common/Message";
import * as Enums from "components/builder/BuilderEnum";
import User from "components/common/utils/UserUtils";
import ComponentService from "services/datamodel/DataModelService";
import StringUtils from "components/common/utils/StringUtils";
import JsonUtils from "components/common/utils/JsonUtils";
import moment from "moment/moment";
/**
 * UI - builder 저장 및 조회
 * **/
class SearchService {
  /**
   * 프로그램 통합 검색 결과
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static getProgramSearch(data, callbackFnc, errCallbackFnc) {
    Api.post("/common/getProgramSearch", data, callbackFnc, errCallbackFnc);
  }

  /**
   * 프로그램 이력 검색
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static getProgramHistorySearch(data, callbackFnc, errCallbackFnc) {
    Api.post(
      "/common/getProgramHistorySearch",
      data,
      callbackFnc,
      errCallbackFnc
    );
  }

  /**
   * 데이터 모델 통합 검색
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static getDataModelSearch(data, callbackFnc, errCallbackFnc) {
    Api.post("/common/getDataModelSearch", data, callbackFnc, errCallbackFnc);
  }

  /**
   * 엔티티 통합 검색
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static getEntitySearch(data, callbackFnc, errCallbackFnc) {
    Api.post("/common/getEntitySearch", data, callbackFnc, errCallbackFnc);
  }
}
export default SearchService;
