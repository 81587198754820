import produce from "immer";

const initialState = {
  prev: [],
  latest: [],
  undoIndex: -1,
  clipboard: {},
};

export default function CommandReducer(state = initialState, action) {
  switch (action.type) {
    case "init_command":
      if (action.payload) {
        return produce(state, (draft) => {
          draft.prev = [action.payload];
          draft.latest = [action.payload];
          draft.undoIndex = -1;
        });
      } else {
        return initialState;
      }
    case "init_do":
      return produce(state, (draft) => {
        draft.prev = [];
        draft.latest = [];
        draft.undoIndex = -1;
      });

    case "stack_undo":
      return produce(state, (draft) => {
        if (draft.undoIndex < -1) {
          draft.prev = [
            ...state.prev.slice(0, state.undoIndex + 1),
            action.payload,
          ];
        } else {
          draft.prev = [...state.prev, action.payload];
          if (draft.prev.length > 10) draft.prev = draft.prev.slice(1);
        }
        draft.undoIndex = -1;
      });
    case "stack_redo":
      return produce(state, (draft) => {
        if (draft.undoIndex < -1) {
          draft.latest = [
            ...state.latest.slice(0, state.undoIndex),
            action.payload,
          ];
        } else {
          draft.latest = [...state.latest, action.payload];
          if (draft.latest.length > 10) draft.latest = draft.latest.slice(1);
        }
      });
    case "undo":
      return produce(state, (draft) => {
        draft.undoIndex = draft.undoIndex - 1;
        // draft.prev.splice(-1);
      });

    case "redo":
      return produce(state, (draft) => {
        if (draft.undoIndex + 1 > -1) return draft;
        const popIndexFromLatest = draft.undoIndex + 1;
        draft.undoIndex = popIndexFromLatest;
        // draft.prev = [...draft.prev, draft.latest.at(popIndexFromLatest)];
      });

    case "set_clipboard":
      return produce(state, (draft) => {
        draft.clipboard = action.payload;
      });
    default:
      return state;
  }
}
