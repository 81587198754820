/*!
 * Entity Builder Redux Helper for react v.17
 *
 * Entity Redux에서 관리 되는 Builder 정보를 update하기 위한 Logic
 *
 *   Author: Bizentro
 *   Date: 2021-04
 */

import {
  activateComponent,
  activateComponentProperty,
  initActivedEntity,
  initDataModel,
  updateDataModel,
} from "components/builder/entity/reducers/EntityBuilderAction";
import StringUtils from "components/common/utils/StringUtils";
import {
  initCommand,
  stackRedo,
  stackUndo,
} from "components/builder/ui/reducers/CommandAction";
import produce from "immer";

class EntityReduxHelper {
  /**
   * 사용중인 컴포넌트 적용
   * @param {*} newItem
   * @param {*} dispatch
   */
  static activateComponent(dispatch, newItem) {
    dispatch(activateComponent(newItem));
  }
  static activateComponentProperty(dispatch, props) {
    dispatch(activateComponentProperty(props));
  }
  /**
   * 데이터 모델 업데이트
   * @param {*} dispatch
   * @param {*} dataModel
   */
  static updateDataModel(dispatch, dataModel) {
    dispatch(updateDataModel(dataModel));
    dispatch(stackRedo(dataModel));
  }

  /**
   * 엔티티 추가
   * @param {*} newItem
   * @param {*} dispatch
   */
  static addNewEntity(dispatch, output, newItem) {
    dispatch(stackUndo(output));
    const newOutput = produce(output, (draft) => {
      draft.dataModelEntities = [...(draft.dataModelEntities || []), newItem];
    });
    this.updateDataModel(dispatch, newOutput);
  }
  /**
   * 엔티티 여러개 추가
   * @param {*} newItem
   * @param {*} dispatch
   */
  static addNewEntities(dispatch, output, newEntities) {
    dispatch(stackUndo(output));
    const newOutput = produce(output, (draft) => {
      draft.dataModelEntities = [
        ...(draft.dataModelEntities || []),
        ...newEntities,
      ];
    });
    this.updateDataModel(dispatch, newOutput);
  }
  /**
   * 업데이트 엔티티
   * @param {*} item
   * @param {*} dispatch
   */
  static updateEntity(dispatch, output, entity) {
    dispatch(stackUndo(output));
    const newOutput = produce(output, (draft) => {
      const targetEntity = draft.dataModelEntities.find(
        (_entity) => _entity.compId === entity.compId
      );
      const targetIndex = draft.dataModelEntities.findIndex(
        (_entity) => _entity.compId === entity.compId
      );
      const newEntity = { ...targetEntity, ...entity };
      draft.dataModelEntities[targetIndex] = newEntity;
    });
    this.updateDataModel(dispatch, newOutput);
  }

  /**
   * 업데이트 엔티티 (복수)
   * @param {*} dispatch
   * @param {*} output
   * @param {*} entities
   */
  static updateEntities(dispatch, output, entities) {
    dispatch(stackUndo(output));
    const newOutput = produce(output, (draft) => {
      entities.map((entity) => {
        const targetEntity = draft.dataModelEntities.find(
          (_entity) => _entity.compId === entity.compId
        );
        const targetIndex = draft.dataModelEntities.findIndex(
          (_entity) => _entity.compId === entity.compId
        );
        const newEntity = { ...targetEntity, ...entity };
        draft.dataModelEntities[targetIndex] = newEntity;
      });
    });
    this.updateDataModel(dispatch, newOutput);
  }

  /**
   * 엔티티 삭제
   * @param {*} dispatch
   * @param {*} entity
   */
  static removeEntity(dispatch, output, _entity) {
    dispatch(initActivedEntity());
    // dispatch(removeEntityInDataModel(entity));
    dispatch(stackUndo(output));
    const newOutput = produce(output, (draft) => {
      // 엔티티 삭제
      draft.dataModelEntities = draft.dataModelEntities.filter(
        (entity) => entity.compId !== _entity.compId
      );
      if (StringUtils.equalsIgnoreCase(draft.dataModelType, "D")) {
        // 관련 릴레이션 삭제
        draft.dataModelEntities = draft.dataModelEntities.map((entity) => {
          entity.relation =
            entity.relation.filter(
              (_rel) => _rel.targetEntity !== _entity.physEntityNm
            ) || [];
          // 필드목록에서 포뮬러가 지정된 필드 삭제
          entity.dataModelEntityFields = entity.dataModelEntityFields.filter(
            (field) => {
              if (
                field.formula &&
                field.formula.indexOf(_entity.physEntityNm) > -1
              ) {
                if (field.virtualYn === "Y") {
                  //가상 칼럼은 삭제
                  return false;
                } else {
                  //가상 칼럼 아니면 포뮬라면 초기화
                  field.formula = null;
                  return true;
                }
              } else {
                return true;
              }
            }
          );

          return entity;
        });
      }
    });
    this.updateDataModel(dispatch, newOutput);
  }

  /**
   * 신규 데이터 모델 생성
   * @param {*} dispatch
   */
  static openNewModel(dispatch, dataModel) {
    dispatch(initCommand());
    dispatch(activateComponent(dataModel));
    this.updateDataModel(dispatch, dataModel);
  }

  /**
   * 데이터 모델 삭제
   * @param {*} dispatch
   */
  static removeDataModel(dispatch) {
    dispatch(initDataModel());
    dispatch(initActivedEntity());
    dispatch(initCommand());
  }

  /**
   * 데이터 모델 이름(물리명) 업데이트
   * @param {*} dispatch
   * @param {*} nameObject
   */
  static updateRelationTargetPhysEntityNm(dispatch, output, nameObject) {
    dispatch(stackUndo(output));
    const { prevTargetName, newTargetName } = nameObject;
    const newOutput = produce(output, (draft) => {
      draft.dataModelEntities.map((_dme) => {
        if (_dme.relation && _dme.relation.length > 0) {
          _dme.relation.map((_rel) => {
            if (
              StringUtils.equalsIgnoreType(_rel.targetEntity, prevTargetName)
            ) {
              _rel.targetEntity = newTargetName;
            }
          });
        }
      });
    });

    this.updateDataModel(dispatch, newOutput);
  }

  /**
   * 해당 엔티티 명이 들어간 릴레이션 전체 삭제(엔티티 병합 또는 오버라이드에 사용)
   * @param {*} dispatch
   * @param {*} targetEntityName
   */
  static deleteRelationTargetEntity(dispatch, output, targetEntityName) {
    // dispatch(deleteRelationTargetEntity(targetEntityName));
    dispatch(stackUndo(output));
    const { targetEntity } = targetEntityName;
    const newOutput = produce(output, (draft) => {
      draft.dataModelEntities.map((_dme) => {
        if (_dme.relation.length > 0) {
          const deleteIndex = _dme.relation.findIndex((_rel) =>
            StringUtils.equalsIgnoreType(_rel.targetEntity, targetEntity)
          );
          if (deleteIndex > -1) {
            _dme.relation.splice(deleteIndex, 1);
          }
        }
      });
    });

    this.updateDataModel(dispatch, newOutput);
  }

  /**
   * 데이터 모델 업데이트 (1개 프롭스씩)
   * @param {*} dispatch
   * @param {*} props
   */
  static updateDataModelProperty(dispatch, output, props) {
    dispatch(stackUndo(output));
    const newOutput = produce(output, (draft) => {
      draft[props.id] = props.value;
    });
    this.updateDataModel(dispatch, newOutput);
  }

  /**
   * 필드 업데이트 (1개 프롭스씩)
   * @param {*} dispatch
   * @param {*} props
   */
  static updateFieldPropety(dispatch, output, props) {
    dispatch(stackUndo(output));
    const dataChangeMethod = (dme, compId) => {
      const newDme = [...dme].map((_entity) => {
        const newE = { ..._entity };
        newE.dataModelEntityFields = newE.dataModelEntityFields.map(
          (_field) => {
            if (_field.compId === compId) {
              const obj = {};
              if (Array.isArray(props)) {
                props.map((v) => {
                  obj[v.id] = v.value;
                });
              } else {
                const { id, value } = props;
                obj[id] = value;
              }
              _field = { ..._field, ...obj };
              return _field;
            } else {
              return _field;
            }
          }
        );
        return newE;
      });

      return newDme;
    };

    const newState = { ...output };
    const { dataModelEntities } = newState;
    const compId = props.compId || props[0].compId;
    const newDME = dataChangeMethod(dataModelEntities, compId);
    let newOutput = {
      ...newState,
      dataModelEntities: newDME,
    };
    this.updateDataModel(dispatch, newOutput);
  }

  /**
   * 가상 칼럼 추가
   * @param {*} dispatch
   * @param {*} field
   * @param {*} entity
   */
  static addVirtualField(dispatch, output, field, entity) {
    dispatch(stackUndo(output));
    const newOutput = produce(output, (draft) => {
      const EntityIndex = draft.dataModelEntities.findIndex(
        (_entity) => _entity.compId === entity.compId
      );
      draft.dataModelEntities[EntityIndex].dataModelEntityFields.push(field);
    });
    this.updateDataModel(dispatch, newOutput);
  }

  static removeEntityField(dispatch, output, entity, fieldCompId) {
    dispatch(stackUndo(output));
    const newOutput = produce(output, (draft) => {
      const EntityIndex = draft.dataModelEntities.findIndex(
        (_entity) => _entity.compId === entity.compId
      );
      const fieldIdx = { ...output }.dataModelEntities[
        EntityIndex
      ].dataModelEntityFields.findIndex((f) => f.compId === fieldCompId);

      draft.dataModelEntities[EntityIndex].dataModelEntityFields.splice(
        fieldIdx,
        1
      );
    });

    this.updateDataModel(dispatch, newOutput);
  }

  /**
   * 아웃풋 추가할때 인풋에 연결
   * @param {*} dispatch
   * @param {*} tableNm
   */
  static addRelationFromInputEntity(dispatch, output, tableNm, physEntityNm) {
    const newOutput = produce(output, (draft) => {
      const EntityIndex = draft.dataModelEntities.findIndex((entity) =>
        StringUtils.equalsIgnoreCase(entity.serviceInout, "input")
      );
      draft.dataModelEntities[EntityIndex].relation = [
        ...draft.dataModelEntities[EntityIndex].relation,
        {
          targetEntity: physEntityNm,
          targetTable: tableNm,
        },
      ];
    });
    // this.updateDataModel(dispatch, newOutput);
    return newOutput;
  }

  /**
   * 활성화된 엔티티 초기화
   */
  static initActivedEntity(dispatch) {
    dispatch(initActivedEntity());
  }
}

export default EntityReduxHelper;
