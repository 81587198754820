import { Enums } from "components/builder/BuilderEnum";
import Message from "components/common/Message";
import Popup from "components/common/Popup";
import Modal from "components/common/modal/UModal";
import UModalTemplate from "components/common/modal/UModalTemplate";
import ArrayUtils from "components/common/utils/ArrayUtils";
import StringUtils from "components/common/utils/StringUtils";
import produce from "immer";
import React from "react";
import { useState } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { MdDelete, MdEdit } from "react-icons/md";
import ColumnOptionPopup from "./ColumnOptionPopup";
import { useEffect } from "react";
import ObjectUtils from "components/common/utils/ObjectUtils";

function TableRegisterPopup({ tableInfo, ...props }) {
  const [data, setData] = useState({
    compId: StringUtils.getUuid(),
    position: {},
    type: Enums.ErdType.TABLE,
    relation: [],
    propertyValue: {
      physicalTableNm: "",
      logicalTableNm: "",
      columnList: [
        {
          physicalName: "",
          logicalName: "",
          dataType: "",
          pkYn: "N",
          notNull: "N",
          default: "",
          autoIncrement: "N",
          extra: "",
        },
      ],
    },
  });
  const [columnInput, setColumnInput] = useState("");
  const [columnInputMode, setColumnInputMode] = useState(false);

  useEffect(() => {
    if (tableInfo) {
      setData(
        produce(data, (draft) => {
          for (const k in tableInfo) {
            draft[k] = tableInfo[k];
          }
        })
      );
    }
  }, []);

  /**
   * property Value change 이벤트
   * @param {*} e
   */
  const onChangePropValue = (e) => {
    setData(
      produce(data, (draft) => {
        draft.propertyValue[e.target.id] = e.target.value;
      })
    );
  };

  /**
   * 칼럼 세부항목 값 체인지 이벤트
   * @param {*} e
   * @param {*} index
   */
  const onChangeColumn = (e, index) => {
    if (index > -1) {
      setData(
        produce(data, (draft) => {
          if (StringUtils.equalsIgnoreCase(e.target.type, "checkbox")) {
            draft.propertyValue.columnList[index][e.target.id] = e.target
              .checked
              ? "Y"
              : "N";
            if (
              StringUtils.equalsIgnoreCase(e.target.id, "pkYn") &&
              e.target.checked
            ) {
              draft.propertyValue.columnList[index]["notNull"] = "Y";
            }
          } else {
            draft.propertyValue.columnList[index][e.target.id] = e.target.value;
          }
        })
      );
    }
  };

  /**
   * 칼럼 추가
   */
  const onAddColumn = () => {
    setData(
      produce(data, (draft) => {
        draft.propertyValue.columnList.push({
          physicalName: "",
          logicalName: "",
          dataType: "",
          pkYn: "N",
          notNull: "N",
          default: "",
          autoIncrement: "N",
          extra: "",
        });
      })
    );
  };

  /**
   * 칼럼 목록을 ColumnList에 채움
   * @param {*} e
   */
  const onConfirmColumnConfig = (e) => {
    e.preventDefault();
    if (StringUtils.isEmpty(columnInput))
      return Message.alert("컬럼 정의를 입력해주세요.", Enums.MessageType.WARN);

    const columnList = [];

    try {
      // const data = JSON.parse(columnInput);
      const columns = columnInput.split(",");
      if (!ArrayUtils.isArray(columns))
        return Message.alert(
          "배열 형태의 데이터가 아닙니다.",
          Enums.MessageType.WARN
        );
      const dataType = typeof columnInput[0];

      for (const obj of columns) {
        let col = {
          physicalName: "",
          logicalName: "",
          dataType: "",
          pkYn: "N",
          notNull: "N",
          default: "",
          autoIncrement: "N",
          extra: "",
        };

        if (typeof obj !== dataType)
          throw Error("데이터 형식이 일관되지 않습니다.");
        else if (typeof obj === "string" && !StringUtils.isEmpty(obj)) {
          let nm = obj.trim();
          col.physicalName = nm;
          col.logicalName = nm;
          col.dataType = "nvarchar";
          columnList.push(col);
        } else if (typeof obj === "object") {
          col = {
            ...col,
            ...obj,
          };
          columnList.push(col);
        }
      }
      setData(
        produce(data, (draft) => {
          draft.propertyValue.columnList = columnList;
        })
      );
    } catch (error) {
      Message.alert(error.message, Enums.MessageType.WARN);
    }
  };

  /**
   * 칼럼 입력 스크립트를 클립보드에 복사함
   * @param {*} e
   */
  const onCopyColumnScript = (e) => {
    const data = `
/**
 * 칼럼 설명
 * physicalName : 물리명
 * logicalName : 논리명
 * dataType : 데이터 타입
 * pkYn : PK 여부 - Boolean , default false
 * notNull : Not Null 여부 - Boolean , default false
 * default : 기본값
 * autoIncrement : auto Increment 여부(시퀀스)  - Boolean , default false
 * extra : 기타 옵션
 * 대괄호(배열)를 포함해서 등록해주세요.
 */
[
  {
    physicalName: "",
    logicalName: "",
    dataType: "",
    pkYn: false,
    notNull: false,
    default: "",
    autoIncrement: false,
    extra: "",
  },
  {... 위 항목을 참고해서 추가입력}
]
    `;
    try {
      navigator.clipboard.writeText(data).then(() => {
        Message.alert(
          "JSON Array 양식이 클립보드에 복사 되었습니다.",
          Enums.MessageType.SUCCESS
        );
      });
    } catch (error) {
      Message.alert(error.message, Enums.MessageType.WARN);
    }
  };

  /**
   * 확인 버튼 이벤트
   */
  const onClickConfirm = () => {
    if (StringUtils.isEmpty(data.propertyValue.physicalTableNm)) {
      return Message.alert(
        "테이블의 물리명을 입력해주세요.",
        Enums.MessageType.WARN
      );
    }

    if (ArrayUtils.isEmpty(data.propertyValue.columnList)) {
      return Message.alert(
        "컬럼이 등록되지 않았습니다.",
        Enums.MessageType.WARN
      );
    }
    let columnInValid = false;
    for (const column of data.propertyValue.columnList) {
      if (StringUtils.isEmpty(column.physicalName)) {
        columnInValid = true;
        break;
      }
      if (StringUtils.isEmpty(column.dataType)) {
        columnInValid = true;
        break;
      }
    }

    if (columnInValid) {
      return Message.alert(
        "컬럼 설정에 필수항목이 누락되었습니다.",
        Enums.MessageType.WARN
      );
    }
    //필드명 중복 확인

    let dupCol = null;
    for (const col of data.propertyValue.columnList) {
      const dupArr = data.propertyValue.columnList.filter(
        (c) => c.physicalName.trim() === col.physicalName.trim()
      );
      if (dupArr.length > 1) {
        dupCol = { ...col };
        break;
      }
    }
    if (!ObjectUtils.isEmpty(dupCol)) {
      return Message.alert(
        `${dupCol.physicalName} 컬럼이 중복 되었습니다.`,
        Enums.MessageType.WARN
      );
    }

    /**
     * 컬럼명 트림 처리
     */
    const saveData = produce(data, (draft) => {
      for (const key in data) {
        if (typeof data[key] === "string") {
          draft[key] = data[key].trim();
        }
      }
      draft.propertyValue.columnList = StringUtils.trimAll(
        draft.propertyValue.columnList
      );

      if (StringUtils.isEmpty(data.propertyValue.logicalTableNm)) {
        draft.propertyValue.logicalTableNm = data.propertyValue.physicalTableNm;
      }
    });

    if (props.callback) {
      props.callback(saveData);
    }
  };

  /**
   * 컬럼 삭제
   * @param {*} e
   * @param {*} i
   */
  const onClickColumnDelete = (e, i) => {
    setData(
      produce(data, (draft) => {
        draft.propertyValue.columnList.splice(i, 1);
      })
    );
  };

  /**
   * 컬럼 옵션 팝업 열기
   * @param {*} e
   * @param {*} i
   */
  const onClickColumnOptionPopup = (e, i) => {
    const callback = (options) => {
      setData(
        produce(data, (draft) => {
          draft.propertyValue.columnList[i] = options;
        })
      );
    };

    Popup.open(
      <ColumnOptionPopup
        column={data.propertyValue.columnList[i]}
        callback={callback}
      />,
      {
        style: { content: { width: "400px" } },
      }
    );
  };

  return (
    <Modal>
      <Modal.Header title="테이블 생성" />
      <Modal.Body>
        <UModalTemplate>
          <Form.Group>
            <Row>
              <Col xs={6}>
                <Form.Label className="required">테이블 명 (물리)</Form.Label>
              </Col>
              <Col xs={6}>
                <Form.Label>테이블 명 (논리)</Form.Label>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col xs={6}>
                <Form.Control
                  id={"physicalTableNm"}
                  value={data.propertyValue.physicalTableNm}
                  onChange={onChangePropValue}
                />
              </Col>
              <Col xs={6}>
                <Form.Control
                  id={"logicalTableNm"}
                  value={data.propertyValue.logicalTableNm}
                  onChange={onChangePropValue}
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={3}>
                <Form.Label>컬럼 목록</Form.Label>
              </Col>
              <Col xs={9}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: "5px",
                  }}
                >
                  <div>
                    {columnInputMode ? (
                      <InputGroup style={{ width: "500px" }}>
                        {/* <Button
                          onClick={onCopyColumnScript}
                          variant="outline-secondary"
                        >
                          스크립트 복사
                        </Button> */}
                        <Form.Control
                          placeholder="컬럼명을 ','로 구분하여 입력..."
                          value={columnInput}
                          onChange={(e) => setColumnInput(e.target.value)}
                        />
                        <Button onClick={onConfirmColumnConfig}>확인 </Button>
                        <Button
                          variant="danger"
                          onClick={(e) => setColumnInputMode(false)}
                        >
                          취소
                        </Button>
                      </InputGroup>
                    ) : (
                      <Button
                        variant="success"
                        onClick={(e) => setColumnInputMode(true)}
                      >
                        컬럼 등록
                      </Button>
                    )}
                  </div>
                  <div>
                    <Button onClick={onAddColumn}>컬럼 추가</Button>
                  </div>
                </div>
              </Col>
            </Row>
            <div className="erd-table-column-grid">
              <div className="header">
                <Row>
                  <Col className="cell required" xs={2}>
                    컬럼 물리명
                  </Col>
                  <Col className="cell" xs={2}>
                    컬럼 논리명
                  </Col>
                  <Col className="cell required" xs={2}>
                    데이터 타입
                  </Col>
                  <Col className="cell" xs={1}>
                    PK 여부
                  </Col>
                  <Col className="cell" xs={1}>
                    Not Null
                  </Col>
                  <Col className="cell" xs={1}>
                    자동 증가
                  </Col>
                  <Col className="cell" xs={2}>
                    기본값
                  </Col>
                  <Col className="cell" xs={1}>
                    옵션
                  </Col>
                </Row>
              </div>
              <div className="body">
                {data.propertyValue.columnList.map((column, index) => {
                  return (
                    <Row key={index} className="mb-1">
                      <Col className="cell" xs={2}>
                        <Form.Control
                          id={"physicalName"}
                          value={column.physicalName}
                          onChange={(e) => onChangeColumn(e, index)}
                          placeholder="필수 입력..."
                        />
                      </Col>
                      <Col className="cell" xs={2}>
                        <Form.Control
                          id={"logicalName"}
                          value={column.logicalName}
                          onChange={(e) => onChangeColumn(e, index)}
                        />
                      </Col>
                      <Col className="cell" xs={2}>
                        <Form.Control
                          id={"dataType"}
                          value={column.dataType}
                          onChange={(e) => onChangeColumn(e, index)}
                          placeholder="필수 입력..."
                        />
                      </Col>
                      <Col className="cell center" xs={1}>
                        <Form.Check
                          id={"pkYn"}
                          checked={column.pkYn === "Y" ? true : false}
                          onChange={(e) => onChangeColumn(e, index)}
                        />
                      </Col>
                      <Col className="cell center" xs={1}>
                        <Form.Check
                          id={"notNull"}
                          checked={column.notNull === "Y" ? true : false}
                          onChange={(e) => onChangeColumn(e, index)}
                        />
                      </Col>
                      <Col className="cell center" xs={1}>
                        <Form.Check
                          id={"autoIncrement"}
                          checked={column.autoIncrement === "Y" ? true : false}
                          onChange={(e) => onChangeColumn(e, index)}
                        />
                      </Col>
                      <Col className="cell" xs={2}>
                        <Form.Control
                          id={"default"}
                          value={column.default}
                          onChange={(e) => onChangeColumn(e, index)}
                        />
                      </Col>
                      <Col
                        className="cell center"
                        xs={1}
                        style={{ display: "flex", gap: "5px" }}
                      >
                        <Button
                          size={"sm"}
                          variant="outline-success"
                          onClick={(e) => onClickColumnOptionPopup(e, index)}
                        >
                          <MdEdit />
                        </Button>
                        <Button
                          size={"sm"}
                          variant="outline-danger"
                          onClick={(e) => onClickColumnDelete(e, index)}
                        >
                          <MdDelete />
                        </Button>
                      </Col>
                    </Row>
                  );
                })}
              </div>
            </div>
          </Form.Group>
        </UModalTemplate>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Button onClick={onClickConfirm}>확인</Modal.Footer.Button>
      </Modal.Footer>
    </Modal>
  );
}

export default TableRegisterPopup;
