import {
  TextField as MInput,
  InputLabel as MInputLabel,
  Select as MSelect,
} from "@mui/material";
import { Enums } from "components/builder/BuilderEnum";
import MuiConfig from "components/common/config/MuiConfig";
import MSelectbox from "components/common/element/MSelectbox";
import WijmoGrid from "components/common/element/WijmoGrid";
import ArrayUtils from "components/common/utils/ArrayUtils";
import CommonUtils from "components/common/utils/CommonUtils";
import StringUtils from "components/common/utils/StringUtils";
import User from "components/common/utils/UserUtils";
import PageTemplate from "page/common/PageTemplate";
import { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { BsSearch } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import AppService from "services/common/AppService";
import UserService from "services/common/UserService";

const breadcrum = [{ name: "사용자 관리", active: true }];
function UserMain() {
  const [appList, setAppList] = useState([]);
  const [searchAppNm, setSearchAppNm] = useState();
  const initialList = useRef([]);
  const navigate = useNavigate();
  const [userList, setUserList] = useState([]);

  useEffect(() => {
    if (User.getAuthTypes().length > 0) {
      getUserListForApp();
      getAppList();
    } else {
      navigate(
        `${Enums.BuilderPath.USER.MAIN}/detail/-1/${StringUtils.encJson(
          User.getMastRecId()
        )}`
      );
    }
    return () => {
      setAppList([]);
    };
  }, []);

  /**
   * APP 전체 사용자 호출
   */
  const getUserListForApp = () => {
    UserService.getAllUserList({}, (res) => {
      setUserList(res.data);
    });
  };
  /**
   * 앱목록 호출
   */
  const getAppList = () => {
    const body = {};
    if (!User.isAdmin())
      body.appIds = User.getAuthTypes().map((auth) => auth.appId);
    AppService.getAppList(body, (res) => {
      setAppList(res.data);
      initialList.current = res.data;
    });
  };

  /**
   * 이름 검색
   * @param {*} e
   */
  const onChangeSearchNm = (e) => {
    setSearchAppNm(e.currentTarget.value);
    const value = e.currentTarget.value;
    if (value) {
      let newAppList = [...initialList.current];
      newAppList = newAppList.filter(
        (a) =>
          StringUtils.indexOf(a.appNm.toLowerCase(), value.toLowerCase()) > -1
      );
      setAppList(newAppList);
    } else {
      setAppList([...initialList.current]);
    }
  };

  /**
   * 그리드 클릭
   * @param {*} e
   */
  const onClickRow = (e) => {
    const userAuth = User.getAuthType(e.appId);
    if (StringUtils.equalsIgnoreCase(userAuth, "D")) {
      navigate(
        `${Enums.BuilderPath.USER.MAIN}/detail/${e.appId}/${StringUtils.encJson(
          User.getMastRecId()
        )}`
      );
    } else {
      navigate(`${Enums.BuilderPath.USER.MAIN}/list/${e.appId}`);
    }
  };

  /**
   * 앱 대표 담당자 및 전체 담당자 표시
   * @param {*} appId
   * @returns
   */
  const getManger = (app) => {
    if (CommonUtils.getAppConfig(app, "appAccessibleYn") === "Y")
      return "모두에게 허용";
    if (!ArrayUtils.isEmpty(userList)) {
      const appManager = [...userList].filter((user) => {
        const auth = user.userAuths.find((a) =>
          StringUtils.equalsIgnoreType(a.appId, app.appId)
        );

        return auth && auth.userAuthType === "AM";
      });
      const representName = appManager[0]?.userNm;
      const count = appManager.length - 1;
      if (appManager.length > 0) {
        let string = representName;
        if (count > 0) string += `외 ${count} 명`;
        return string;
      } else {
        return `없음`;
      }
    }
  };

  /**
   * 앱별 전체 개발자 수
   * @param {*} appId
   * @returns
   */
  const getDeveloperCount = (app) => {
    if (CommonUtils.getAppConfig(app, "appAccessibleYn") === "Y")
      return "모두에게 허용";
    if (!ArrayUtils.isEmpty(userList)) {
      const developers = [...userList].filter((user) => {
        return (
          user.userAuths.findIndex((a) =>
            StringUtils.equalsIgnoreType(a.appId, app.appId)
          ) > -1
        );
      });
      return `${developers.length} 명`;
    }
  };

  const column = [
    {
      field: "appNm",
      headerName: "Application 이름",
      width: "*",
    },
    {
      field: "appDesc",
      headerName: "Application 설명",
      width: "*",
    },
    {
      field: "userAuthType",
      headerName: "나의 권한",
      align: "center",
      renderCell: (params) => {
        if (User.isAdmin()) {
          return <span>관리자</span>;
        } else {
          return <span>{params.userAuthName}</span>;
        }
      },
    },
    {
      field: "appDetail",
      headerName: "상세 보기",
      align: "center",
      width: 200,
      renderCell: (params) => {
        if (CommonUtils.getAppConfig(params, "appAccessibleYn") !== "Y")
          return (
            <Button
              size="sm"
              variant="outline-primary"
              onClick={(e) => onClickRow(params)}
            >
              사용자 상세 보기
            </Button>
          );
        else return "모두에게 허용";
      },
    },
    {
      field: "manager",
      headerName: "APP 담당자",
      align: "center",
      width: 150,
      renderCell: (params) => {
        return getManger(params);
      },
    },
    {
      field: "crew",
      headerName: "전체 개발자",
      align: "center",
      width: 150,
      renderCell: (params) => {
        return getDeveloperCount(params);
      },
    },
  ];

  return (
    <PageTemplate breadcrum={breadcrum}>
      <PageTemplate.Box boxClass="mb-0">
        <Form>
          <Row
            className="pb-3 pt-3 box-line"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Col xs={2}>
              <MInput
                id="entity"
                label="이름"
                type="text"
                fullWidth
                size="small"
                className="xmall-input"
                color="primary"
                placeholder="앱 이름"
                value={searchAppNm}
                onChange={onChangeSearchNm}
              />
            </Col>
            {!User.isAdmin() && (
              <Col xs={2}>
                <MSelectbox fullWidth size="small">
                  <MInputLabel id="auth-label">권한</MInputLabel>
                  <MSelect
                    name="moduleCd"
                    labelId="auth-label"
                    label="권한"
                    fullWidth
                    className="xmall-select"
                    color="primary"
                  ></MSelect>
                </MSelectbox>
              </Col>
            )}

            <Col xs={1}>
              <Button variant="primary">
                검색 <BsSearch size="14" />
              </Button>
            </Col>
          </Row>
        </Form>
        <div style={{ margin: 15 }}>
          사용자 목록을 확인할 Application을 선택해주세요
        </div>
        <div style={{ height: "calc(100% - 70px)" }}>
          <WijmoGrid
            style={{ height: "60vh" }}
            columns={column}
            getRowId={(row) => row.appId}
            rows={appList}
            {...MuiConfig.grid.options}
            rowsPerPageOptions={[15]}
            pageSize={15}
          />
        </div>
      </PageTemplate.Box>
    </PageTemplate>
  );
}

export default UserMain;
