import React, { createContext, memo } from "react";
import "css/builder/eventhandler.css";

import { useState } from "react";
import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import StringUtils from "components/common/utils/StringUtils";
import { Enums } from "components/builder/BuilderEnum";
import BuilderHeaderComponent from "components/builder/BuilderHeaderComponent";

const EventHandler = memo(({ ...props }) => {
  const [currentPath, setCurrentPath] = useState();
  const location = useLocation();

  useEffect(() => {
    const CurrentPath = location.pathname.split("/")[2];
    setCurrentPath(CurrentPath);
  }, [location]);

  return (
    <>
      {StringUtils.equalsIgnoreCase(
        currentPath,
        Enums.BuilderPath.EVENT_HANDLER.BUILDER
      ) ? (
        //이벤트 헨들러 헤더는 REF 특성상 Builder 내부에서 실행
        <></>
      ) : (
        <BuilderHeaderComponent {...props.header} />
      )}

      <Outlet />
    </>
  );
});

export default EventHandler;
