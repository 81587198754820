import { PureComponent } from "react";
import { confirmAlert } from "react-confirm-alert"; // Import
import { toast, Slide /*, Zoom, Flip, Bounce*/ } from "react-toastify";
import * as Enums from "components/builder/BuilderEnum";

class Message extends PureComponent {
  /**
   * Confirm message
   * @param {Map} contents
   * @param {function} okCallback
   * @param {function} noCallback
   * @param {Map} options
   */
  static confirm = (contents, okCallback, noCallback, options) => {
    let title = "";
    let desc = "";
    if (typeof contents == "object" && contents.title && contents.desc) {
      title = <h1>{contents.title}</h1>;
      desc = <p>{contents.desc}</p>;
    } else {
      desc = <p>{contents}</p>;
    }
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="confirm-ui">
            {title}
            {desc}
            <button
              onClick={() => {
                if (typeof okCallback == "function") okCallback.call(this);
                onClose();
              }}
            >
              {Enums.ConfirmLabel.YES}
            </button>
            <button
              onClick={() => {
                if (typeof noCallback == "function") noCallback.call(this);
                onClose();
              }}
            >
              {Enums.ConfirmLabel.NO}
            </button>
          </div>
        );
      },
    });
  };

  /**
   * Confirm message
   * @param {Map} contents
   * @param {Object[]} selectCallBack
   * @param {Map} options
   */
  static multiConfirm = (
    contents,
    selectCallback = [],
    noCallback,
    options
  ) => {
    let title = "";
    let desc = "";
    if (typeof contents == "object" && contents.title && contents.desc) {
      title = <h1>{contents.title}</h1>;
      desc = <p>{contents.desc}</p>;
    } else {
      desc = <p>{contents}</p>;
    }
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="confirm-ui">
            {title}
            {desc}
            {selectCallback.map((cbObj, idx) => {
              return (
                <button
                  key={idx}
                  onClick={() => {
                    onClose();
                    if (typeof cbObj.cb == "function") cbObj.cb.call(this);
                  }}
                >
                  {cbObj.text}
                </button>
              );
            })}

            <button
              onClick={() => {
                if (typeof noCallback == "function") noCallback.call(this);
                onClose();
              }}
            >
              {Enums.ConfirmLabel.NO}
            </button>
          </div>
        );
      },
    });
  };

  /**
   * alert default
   */
  static ALERT_DEFAULT = {
    position: "top-right", //top-left,top-right,top-center,bottom-left,bottom-right,bottom-center
    autoClose: 5000, //Delay ms or false
    hideProgressBar: false, //Progress bar
    closeOnClick: true, //message 클릭시 close시킴
    pauseOnHover: true, //message 마우스 hover시 delay time 멈춤
    draggable: true,
    transition: Slide, //Slide, Zoom, Flip, Bounce
  };

  /**
   * alert message
   * @param {String} message
   * @param {String} type
   * @param {Map} options
   */
  static alert = (message, type, options) => {
    let mOptions = { ...this.ALERT_DEFAULT, ...options };
    switch (type) {
      case Enums.MessageType.INFO:
        toast.info(message, mOptions);
        break;
      case Enums.MessageType.ERROR:
        toast.error(message, mOptions);
        break;
      case Enums.MessageType.SUCCESS:
        toast.success(message, mOptions);
        break;
      case Enums.MessageType.WARN:
        toast.warning(message, mOptions);
        break;
      default:
        toast(message, mOptions);
    }
  };
}

export default Message;
