import StringUtils from "components/common/utils/StringUtils";
import { EventHandlerContext } from "page/eventhandler/EventHandlerBuilderMain";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import EventhandlerWorkspaceCode from "components/builder/eventhandler/editor/layout/EventHandlerWorkspaceCode";
import ObjectUtils from "components/common/utils/ObjectUtils";

import EventHandlerBlockly from "components/builder/eventhandler/editor/layout/EventHandlerBlockly";
import EventHandlerCodeMirror from "components/builder/eventhandler/editor/layout/EventHandlerCodeMirror";
import EventHandlerCommandButton from "components/builder/eventhandler/editor/layout/EventHandlerCommandButton";

import { AppContext } from "components/common/AppContextProvider";

//config INIT
import "components/builder/eventhandler/editor/render/init/blockIcon";
import "components/builder/eventhandler/editor/render/init/blockInsertionMarker";
import "components/builder/eventhandler/editor/render/init/blockMutator";
import "components/builder/eventhandler/editor/render/init/blockRender";
import { useNavigate } from "react-router-dom";
import EventhandlerService from "services/eventhandler/EventhandlerService";
import BlockFieldPopupButton from "../render/init/blockField/BlockFieldPopupButton";
import { FieldDate } from "@blockly/field-date";

/**
 * 이벤트 핸들어 브로콜리 부분
 * @param {*} param0
 * @returns
 */
function EventHandlerBuilder({ blocklyWorkspaceRef, ...props }) {
  //redux
  const eventHandler = useSelector((state) => state.eventHandler);
  const output = useSelector((state) => state.outputUI.output);

  const {
    isTreeOpen,
    setIsTreeOpen,
    parameterInfo,
    setParameterInfo,
    blockInfo,
    blockAdditionalInfo,
  } = useContext(EventHandlerContext);
  const { workspace } = useContext(AppContext);

  //state
  const [tabType, setTabType] = useState("E");
  const [viewSource, setViewSource] = useState(eventHandler.output);
  const [eventPath, setEventPath] = useState(eventHandler.path);

  //hook
  const navigate = useNavigate();

  useEffect(() => {
    // block의 popup button field 정의
    BlockFieldPopupButton(workspace);
  }, [workspace]);

  useEffect(() => {
    const { eventType, eventCd, targetType, programType } =
      eventHandler.componentProp;
    if (
      !StringUtils.isEmpty(eventType) &&
      !StringUtils.isEmpty(eventCd) &&
      !StringUtils.isEmpty(targetType) &&
      !StringUtils.isEmpty(programType)
    ) {
      const body = { eventType, eventCd, targetType, programType };
      EventhandlerService.getEventHandlerInfo(body, (res) => {
        if (res.data) {
          setParameterInfo(res.data);
        }
      });
    }
  }, [eventHandler.componentProp]);

  return (
    <div className="reactflow-wrapper blockly-wrapper">
      <div className="command-button-wrapper">
        <EventHandlerCommandButton
          tabType={tabType}
          setTabType={setTabType}
          viewSource={viewSource}
          navigate={navigate}
          openTree={isTreeOpen}
          setOpenTree={setIsTreeOpen}
          eventPath={eventPath}
          workspace={blocklyWorkspaceRef}
          parameterInfo={parameterInfo}
        />
      </div>
      <EventHandlerBlockly
        workspace={blocklyWorkspaceRef}
        isReload={false}
        show={StringUtils.equalsIgnoreCase(tabType, "E") ? true : false}
      />
      <EventHandlerCodeMirror
        show={StringUtils.equalsIgnoreCase(tabType, "S") ? true : false}
      />
      {!ObjectUtils.isEmpty(eventHandler.workspace) && (
        <EventhandlerWorkspaceCode
          show={StringUtils.equalsIgnoreCase(tabType, "W") ? true : false}
        />
      )}
    </div>
  );
}

export default EventHandlerBuilder;
