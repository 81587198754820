import { DaafMessage } from "@alpha/com.bizentro.daaf.front.framework";
import axios from "axios";
import { Enums } from "components/builder/BuilderEnum";
import { stopEvent } from "components/builder/ui/editor/handler/UIEditorEventHandler";
import WijmoGrid from "components/common/element/WijmoGrid";
import config from "components/common/EnvConfig";
import Message from "components/common/Message";
import Modal from "components/common/modal/UModal";
import UModalTemplate from "components/common/modal/UModalTemplate";
import Popup from "components/common/Popup";
import CommonUtils from "components/common/utils/CommonUtils";
import { useEffect, useRef, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { MdDelete, MdDownload, MdEdit } from "react-icons/md";
import WorkflowService from "services/workflow/WorkflowService";

/**
 * @param {Object} props - 컴포넌트의 속성
 * @param {Function} props.uploadCallback
 * @param {Object} props.workspace
 * @param {Function} props.loadCallback
 * @returns
 */
const CSFileListPopup = ({
  workspace,
  uploadCallback,
  loadCallback,
  ...props
}) => {
  const [fileList, setFileList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState({
    fileNm: "",
  });
  const inputFileRef = useRef();

  useEffect(() => {
    getCsFileList();
  }, []);

  const getCsFileList = () => {
    WorkflowService.getCSFileList(
      {
        ...workspace,
        ...searchTerm,
      },
      (res) => {
        setFileList(res.data);
      }
    );
  };

  const columns = [
    {
      field: "fileNm",
      headerName: "파일 명",
      renderCell: (data) => {
        return data.fileNm + "." + data.fileExtension;
      },
    },
    {
      field: "updtUserId",
      headerName: "최종 수정자",
      width: 130,
    },
    {
      field: "updtDt",
      headerName: "최종 수정일",
      width: 150,
      renderCell: (params) =>
        `${CommonUtils.getDate(params.updtDt, "datetime")}`,

      // valueGetter: (params) =>
      //   `${CommonUtils.getDate(params.row.updtDt, "datetime")}`,
    },
    {
      field: "remark",
      headerName: "편집",
      width: 70,
      align: "center",
      renderCell: (params) => {
        return (
          <Button
            size="sm"
            variant="outline-primary"
            onClick={(e) => onFileDetail(params)}
            disabled={isLoading}
          >
            <MdEdit />
          </Button>
        );
      },
    },
    {
      field: "remark2",
      headerName: "다운로드",
      width: 90,
      align: "center",
      renderCell: (params) => {
        return (
          <Button
            size="sm"
            variant="outline-success"
            onClick={(e) => onFileDownLoad(params)}
            disabled={isLoading}
          >
            <MdDownload />
          </Button>
        );
      },
    },
    {
      field: "remark3",
      headerName: "삭제",
      width: 70,
      align: "center",
      renderCell: (params) => {
        return (
          <Button
            size="sm"
            variant="outline-danger"
            onClick={(e) => onDeleteFile(params)}
            disabled={isLoading}
          >
            <MdDelete />
          </Button>
        );
      },
    },
  ];

  /**
   * C# 파일 업로드 하는 로직
   * @param {*} e
   */
  const openCsharpFileUpload = (e) => {
    stopEvent(e);

    inputFileRef.current.click();
  };

  const handleFileChange = (event) => {
    setIsLoading(true);
    const file = event.target.files[0];
    if (file) {
      //File definition;
      const splitedNm = file.name.split(".");
      const fileNm = splitedNm[0];
      const fileSize = file.size;
      const fileExtension = splitedNm[1];

      // You can add more logic to handle the file here
      let fileContent = "";
      //file 내부 읽기
      const reader = new FileReader();
      reader.onload = (e) => {
        fileContent = e.target.result;
      };
      reader.readAsText(file);
      //서버에 보낼 formData 설정
      const formData = new FormData();
      formData.append("file", file);
      axios
        .post(
          `${config.baseUrl.server}/workflow/convertCSFileToWorkflow`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          setIsLoading(false);
          if (!res.data)
            return DaafMessage.alert(
              "변환 데이터가 확인되지 않습니다.",
              "warning"
            );
          if (uploadCallback) {
            uploadCallback(res.data.data, {
              fileNm,
              fileSize,
              fileExtension,
              fileContent,
            });
            Popup.close();
          }
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  };

  const onRowDoubleClick = (data, index) => {
    onFileDetail(data);
  };

  const onFileDetail = (data) => {
    setIsLoading(true);
    // 더블클릭 실행 활성화
    if (loadCallback) {
      WorkflowService.getCsFileDetail(data, (res) => {
        setIsLoading(false);
        loadCallback(res.data);
      });
    }
  };

  /**
   * 파일 다운로드
   * @param {*} data
   */
  const onFileDownLoad = (data) => {
    const blob = new Blob([data.fileContent], { type: "text/plain" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${data.fileNm}.${data.fileExtension}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  /**
   * 파일 삭제
   * @param {*} param
   */
  const onDeleteFile = (param) => {
    setIsLoading(true);
    Message.confirm(
      `${param.fileNm} 파일을 삭제 하시겠습니까? 서비스까지 전부 삭제 됩니다.`,
      () => {
        WorkflowService.deleteFile(param, (res) => {
          if (!res.isError) {
            Message.alert("삭제 되었습니다.", Enums.MessageType.SUCCESS);
            setIsLoading(false);
            getCsFileList();
          }
        });
      }
    );
  };

  return (
    <Modal>
      <input
        type="file"
        ref={inputFileRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
        accept=".cs"
      />
      <Modal.Header title={"C# 파일 현황"} />
      <Modal.Body>
        <UModalTemplate>
          <Row className="mb-3">
            <Col
              xs={12}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button onClick={openCsharpFileUpload}>C# 파일 변환</Button>
            </Col>
          </Row>
          <WijmoGrid
            columns={columns}
            rows={fileList}
            style={{ height: "40vh" }}
            headersVisibility={"Column"}
            onRowDoubleClick={onRowDoubleClick}
            allowPinning={"None"}
          />
        </UModalTemplate>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.ProgressButton doing={isLoading} doingText="작업 진행 중">
          확인
        </Modal.Footer.ProgressButton>
      </Modal.Footer>
    </Modal>
  );
};

export default CSFileListPopup;
