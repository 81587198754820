import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import CodeMirror from "@uiw/react-codemirror";
import { css } from "@codemirror/lang-css";
import { Button, Form } from "react-bootstrap";
import StringUtils from "components/common/utils/StringUtils";
import Message from "components/common/Message";
import { Enums } from "components/builder/BuilderEnum";
import User from "components/common/utils/UserUtils";
import { Checkbox, FormControlLabel, Tooltip } from "@mui/material";
import SettingService from "services/common/SettingService";
import { useDispatch, useSelector } from "react-redux";
import StyleUtils from "components/common/utils/StyleUtils";

export const SearchBar = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  flex-direction: column;
  & input[type="file"] {
    display: none;
  }
  & > div {
    height: 50%;
    display: flex;
    gap: 5px;
  }

  & .search {
    width: 100%;
    & > div {
      width: 50%;
      display: flex;
      gap: 5px;
    }
    & span {
      width: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    & .moduleSearch {
      & select {
        border: 1px solid lightgray;
        border-radius: 5px;
        padding-left: 20px;
      }
    }
    & .fileList {
      & select {
        width: 70%;
        padding-left: 20px;
        border: 1px solid lightgray;
        border-radius: 5px;
      }
    }
  }

  & .fileSearch {
    & span {
      width: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    & .fileName {
      width: 40%;
      padding-left: 20px;
      border: 1px solid lightgray;
      border-radius: 5px;
      align-items: center;
      display: flex;
    }
  }
`;
export const CodeMirrorBox = styled.div`
  min-height: 80%;
`;

function StyleCustom() {
  const { style: styleRedux } = useSelector((state) => state.setting);
  const workspace = useSelector((state) => state.workspace);

  const dispatch = useDispatch();
  const [styleList, setStyleList] = useState([]);
  const [selectedStyle, setSelectedStyle] = useState(styleRedux.styleId);

  const [styleId, setStyleId] = useState(styleRedux.styleId || "");
  const [styleNm, setStyleNm] = useState(styleRedux.styleNm || "");
  const [styleCode, setStyleCode] = useState(styleRedux.styleCode || "");
  const [defaultYn, setDefaultYn] = useState(false);

  const fileRef = useRef();

  const setCodeMirrorContents = (value) => {
    setStyleCode(value);
  };

  useEffect(() => {
    getStyleList();
  }, []);

  const onClickFileSearch = (e) => {
    e.stopPropagation();
    e.preventDefault();
    fileRef.current.click();
  };
  /**
   * CSS 파일 선택시
   * @param {*} e
   * @returns
   */
  const onChangeInputFile = (e) => {
    const file = e.target.files[0];
    if (!StringUtils.equalsIgnoreCase(file.type, "text/css")) {
      fileRef.current.value = null;
      setStyleNm("");
      return Message.alert(
        "스타일 파일만 가능합니다.",
        Enums.MessageType.ERROR
      );
    }
    setStyleNm(file.name);
    const reader = new FileReader();

    reader.onload = () => {
      const resultString = reader.result;
      setCodeMirrorContents(resultString);
    };

    reader.readAsText(file);
  };

  /**
   * 코드 미러
   * @param {*} value
   * @param {*} viewUpdate
   */
  const onChangeCodeMirror = (value, viewUpdate) => {
    setCodeMirrorContents(value);
  };
  /**
   * 스타일 선택
   * @param {*} e
   */
  const onChangeStyle = (e) => {
    setSelectedStyle(e.target.value);
  };
  const setStyle = (styleId) => {
    if (styleId) {
      SettingService.getStyle({ styleId }, (res) => {
        setStyleId(res.data.styleId);
        setStyleNm(res.data.styleNm);
        setStyleCode(JSON.parse(res.data.styleCode));
        setDefaultYn(res.data.defaultYn === "Y" ? true : false);
      });
    } else {
      return Message.alert(
        "목록에서 스타일을 선택해주세요",
        Enums.MessageType.ERROR
      );
    }
  };
  /**
   * 스타일 목록 호출
   * @param {*} moduleCd
   */
  const getStyleList = () => {
    SettingService.getStyleList({ ...workspace }, (res) => {
      setStyleList(res.data);
      if (res.data.length > 0) {
        if (
          styleRedux.styleId &&
          res.data.find((s) => s.styleId === styleRedux.styleId)
        ) {
          //기존에 적용한 스타일이 있으면 불러낼수 있도록 함
          setSelectedStyle(styleRedux.styleId);
          setStyleId(styleRedux.styleId);
          setDefaultYn(
            StringUtils.equalsIgnoreCase(styleRedux.defaultYn, "Y")
              ? true
              : false
          );
        }
      }
    });
  };

  /**
   * 저장
   * @param {*} e
   */
  const onSaveCss = (e) => {
    e.preventDefault();
    const body = {
      styleId,
      styleNm,
      styleCode: JSON.stringify(styleCode),
    };
    saveMethod(body);
  };
  /**
   * 다른 이름 저장
   * @param {*} e
   */
  const onSaveAsCss = (e) => {
    e.preventDefault();
    const body = {
      styleNm: styleNm,
      styleCode: JSON.stringify(styleCode),
    };

    saveMethod(body);
  };

  /**
   * 저장
   * @param {*} body
   * @returns
   */
  const saveMethod = (body) => {
    body = { ...workspace, ...body, builderDefaultYn: "N" };
    if (StringUtils.isEmpty(body.styleNm)) {
      return Message.alert("파일 명을 입력해주세요.", Enums.MessageType.ERROR);
    }
    if (StringUtils.isEmpty(body.styleCode)) {
      return Message.alert(
        "스타일 코드를 입력해주세요.",
        Enums.MessageType.ERROR
      );
    }
    if (
      !body.styleId &&
      styleList.find((s) =>
        StringUtils.equalsIgnoreType(s.styleNm, body.styleNm)
      )
    ) {
      return Message.alert(
        "해당 모듈에 같은 이름을 가진 스타일이 이미 존재합니다.",
        Enums.MessageType.INFO
      );
    }

    if (defaultYn) {
      body.defaultYn = "Y";
    } else {
      body.defaultYn = "N";
    }

    SettingService.saveStyle(body, (res) => {
      Message.alert("저장 및 적용 되었습니다.", Enums.MessageType.SUCCESS);
      setStyleId(res.data.styleId);
      setStyleNm(res.data.styleNm);
      setStyleCode(JSON.parse(res.data.styleCode));
    });
  };

  /**
   * 스타일 적용
   * @param {*} e
   */
  const onApplyStlye = (e) => {
    StyleUtils.ApplyStyle(dispatch, {
      ...workspace,
      styleId,
      styleNm,
      styleCode,
      apply: true,
    });
  };

  const onApplyOffStyle = (e) => {
    StyleUtils.ApplyOffStyle(dispatch);
  };

  return (
    <>
      <div className="setting-header">Custom Style</div>
      <div className="setting-container custom">
        <SearchBar>
          <div className="search">
            <div className="fileList">
              <span>스타일 목록</span>
              <select value={selectedStyle} onChange={onChangeStyle}>
                {styleList.length > 0 ? (
                  <>
                    <option value="">선택</option>
                    {styleList.map((style) => (
                      <option key={style.styleId} value={style.styleId}>
                        {style.styleNm}
                      </option>
                    ))}
                  </>
                ) : (
                  <option>등록된 스타일이 없습니다.</option>
                )}
              </select>
              <Button onClick={() => setStyle(selectedStyle)}>불러오기</Button>
            </div>
          </div>

          <div className="fileSearch">
            <span>스타일 명</span>
            <input
              className="fileName"
              placeholder="CSS의 이름을 입력하거나 파일을 선택해주세요."
              value={styleNm}
              onChange={(e) => setStyleNm(e.target.value)}
            />
            <Button
              variant="primary"
              onClick={(e) => {
                setStyleId(null);
                setStyleNm("");
                setStyleCode("");
              }}
            >
              새로 만들기
            </Button>
            <Tooltip title="로컬 시스템에서 파일 검색">
              <Button variant="outline-primary" onClick={onClickFileSearch}>
                파일 찾기
              </Button>
            </Tooltip>
          </div>
          <input
            ref={fileRef}
            type={"file"}
            accept="text/css"
            onChange={onChangeInputFile}
          />
        </SearchBar>
        <CodeMirrorBox>
          <CodeMirror
            style={{
              height: "100%",
              textAlign: "initial",
              color: "black",
              border: "1px solid lightgray",
              borderRadius: "5px",
            }}
            height={"100%"}
            value={styleCode}
            className="source-container"
            extensions={[css(true)]}
            autoFocus={false}
            editable={true}
            onChange={onChangeCodeMirror}
          />
        </CodeMirrorBox>
      </div>
      <div className="setting-button split">
        <div>
          <Button variant="primary" onClick={onApplyStlye}>
            스타일 적용
          </Button>
          {styleRedux.apply && (
            <Button variant="outline-secondary" onClick={onApplyOffStyle}>
              스타일 적용 해제
            </Button>
          )}
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
          <FormControlLabel
            label="Workspace 기본 테마"
            control={
              <Checkbox
                checked={defaultYn}
                onChange={(e) => setDefaultYn(e.target.checked)}
              />
            }
          />
          {styleId ? (
            <Button variant="outline-primary" onClick={onSaveCss}>
              수정
            </Button>
          ) : (
            <Button variant="success" onClick={onSaveCss}>
              저장 및 적용
            </Button>
          )}

          {styleId && (
            <Button variant="success" onClick={onSaveAsCss}>
              다른 이름으로 저장
            </Button>
          )}
        </div>
      </div>
    </>
  );
}

export default StyleCustom;
