import React from "react";
import { Tabs, Tab } from "react-bootstrap";
import styled from "styled-components";

import Modal from "components/common/modal/UModal";
import MesFunPopup from "./MesFunPopup";
import { useState } from "react";

const PopupStyle = styled.div`
  .edit-source {
    border: 1px solid #ddd;
  }
  .tab-pane .modal-header {
    display: none;
  }
  .modal-body:not(.tab-pane .modal-body) {
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
  }

  .nav-item button:not(.active),
  .nav-item button:not(.active):focus {
    color: #333;
  }

  .nav-item button:hover {
    background: #f7f7f7;
  }
`;

/**
 * MesFunTabPopup : Popup 전처리
 *
 * 2022.08.31: init: songe.park
 *
 * @param {*} props
 * @returns value
 */
const MesFunTabPopup = (props) => {
  const {
    title,
    item,
    callbackFnc,
    eventInfo,
    entityId,
    codeList,
    isShowOperator,
    onClickEventBuilder,
  } = props;

  const [items, setItems] = useState({});

  const setRetItems = (id, value) => {
    setItems({ ...items, [id]: value });
  };

  const closePopup = (backItems) => {
    callbackFnc.call(this, items);
  };

  return (
    <PopupStyle>
      <Modal>
        <Modal.Header title={title} />
        <Modal.Body>
          <Tabs justify defaultActiveKey="tab-pre" id="toolbar-tabs">
            <Tab eventKey="tab-pre" title="전 처리" id="tab-pre">
              <MesFunPopup
                fieldType="json"
                isShowMsgCd={eventInfo.preHandleMsgYn === "Y"}
                isShowFnc={eventInfo.preHandleFncYn === "Y"}
                isInputMapping={true}
                entityId={entityId}
                msgOptions={{
                  id: "beforMsgCd",
                  value: item["beforMsgCd"],
                }}
                fnOptions={{
                  id: "beforeSubmit",
                  value: item["beforeSubmit"],
                  height: "300px",
                }}
                item={item}
                callbackFnc={closePopup}
                setRetItems={setRetItems}
                codeList={codeList}
                isShowOperator={isShowOperator}
                onClickEventBuilder={() => onClickEventBuilder("beforeSubmit")}
                getEventInfo={() => props.getEventInfo("beforeSubmit")}
              />
            </Tab>
            <Tab eventKey="tab-post" title="후 처리" id="tab-post">
              <MesFunPopup
                fieldType="json"
                isShowMsgCd={eventInfo.postHandleMsgYn === "Y"}
                isShowFnc={eventInfo.postHandleFncYn === "Y"}
                entityId={entityId}
                msgOptions={{
                  id: "afterMsgCd",
                  value: item["afterMsgCd"],
                }}
                fnOptions={{
                  id: "afterSubmit",
                  value: item["afterSubmit"],
                  height: "300px",
                }}
                item={item}
                callbackFnc={closePopup}
                setRetItems={setRetItems}
                onClickEventBuilder={() => onClickEventBuilder("afterSubmit")}
                getEventInfo={() => props.getEventInfo("afterSubmit")}
              />
            </Tab>
          </Tabs>
        </Modal.Body>
      </Modal>
    </PopupStyle>
  );
};

export default MesFunTabPopup;
