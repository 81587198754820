import StringUtils from "components/common/utils/StringUtils";
import SessionUtils from "components/common/utils/SessionUtils";

class User {
  /**
   *
   * @returns {Object} User_Mast_Rec
   */
  static getAll() {
    return SessionUtils.get();
  }

  /**
   *
   * @returns {String} userId
   */
  static getId() {
    return SessionUtils.get("userId");
  }
  /**
   *
   * @returns {String} user_mast_rec_id
   */
  static getMastRecId() {
    return SessionUtils.get("userMastRecId");
  }
  /**
   *
   * @returns {String} user_nm
   */
  static getNm() {
    return SessionUtils.get("userNm");
  }
  /**
   *
   * @returns {String} user_eng_nm
   */
  static getEngNm() {
    return SessionUtils.get("userEngNm");
  }
  /**
   *
   * @returns {Date} user_valid_dt
   */
  static getValidDt() {
    const userValidDt = new Date(SessionUtils.get("userValidDt"));
    return userValidDt;
  }
  /**
   *
   * @returns {String} user_email
   */
  static getEmail() {
    return SessionUtils.get("userEmail");
  }
  /**
   *
   * @returns {String} default_lang_cd
   */
  static getDefaultLangCd() {
    return SessionUtils.get("defaultLangCd");
  }
  /**
   *
   * @returns {String} use_yn
   */
  static getUseYn() {
    return SessionUtils.get("useYn");
  }
  /**
   *
   * @returns {String} user_auth_type
   */
  static getAuthType(appId) {
    const auths = SessionUtils.get("userAuths");
    if (!auths) return null;

    const AdminAuthIndex = auths.findIndex((a) =>
      StringUtils.equalsIgnoreCase(a.userAuthType, "S")
    );

    if (AdminAuthIndex > -1) {
      return "S";
    } else {
      if (appId) {
        const result = auths.find((a) =>
          StringUtils.equalsIgnoreType(a.appId, appId)
        );
        return result?.userAuthType;
      } else {
        if (auths) {
          const authList = auths.map((a) => a.userAuthType);
          if (authList.indexOf("S") > -1) {
            //관리자
            return "S";
          } else if (authList.indexOf("AM") > -1) {
            //APP 담당자
            return "AM";
          } else if (authList.indexOf("M") > -1) {
            //담당자
            return "M";
          } else if (authList.indexOf("D") > -1) {
            //개발자
            return "D";
          }
        } else {
          return null;
        }
      }
    }
  }

  /**
   * 가능한 auth 목록 반환
   * @returns {[Object]}
   */
  static getAuthTypes(appId) {
    const auths = SessionUtils.get("userAuths");
    if (appId) {
      return auths.find((a) => StringUtils.equalsIgnoreType(a.appId, appId));
    } else {
      return auths;
    }
  }

  /**
   * 가능한 모듈의 오브젝트 반환
   * @returns {[Object]} user_available_module
   */
  static selectAvailableModuleCds(appId) {
    if (!appId) return [];
    else {
      const auth = this.getAuthTypes(appId);
      return auth.availableModuleCodes;
    }
  }

  /**
   * 가능한 모듈의 코드만 반환
   * @returns {[String]} user_available_module
   */
  static selectAvailableModuleCdsOnly(appId) {
    let codeList = [];

    if (appId) {
      let list = this.selectAvailableModuleCds(appId);
      codeList = list.map((c) => c.moduleCd);
    } else {
      let list = this.selectAvailableModuleCds();
      for (const module of list) {
        if (codeList.indexOf(module.moduleCd) === -1) {
          //없으면 넣어서 중복제거
          codeList.push(module.moduleCd);
        }
      }
    }
    return codeList;
  }

  /**
   * 가능한 APP ID 목록을 반환
   * @returns {[Number]}
   */
  static selectAvailableApps() {
    let ret = [];
    const authList = this.getAuthTypes();
    ret = authList.map((a) => a.appId);
    return ret;
  }

  /**
   * 자신이 가진 모듈의 특정 인덱스의 Object를 반환
   * @param {number} index 0 ~ length of List
   * @default {index} last number of List
   * @returns {Object} user_available_module
   */
  static getAvailableModuleCd(appId, index) {
    if (appId) {
      const myModuleList = this.getAuthTypes(appId).availableModuleCodes;
      if (!myModuleList || myModuleList.length < 1) {
        return null;
      }
      return myModuleList;
    } else {
      return null;
    }
  }
  /**
   * 필요한 연결 정보를 리턴
   * 파라미터 있을때는 스트링 리턴
   * @param {String} tenantMstId
   * @param {String} connectionKey
   * @returns {Obejct | String}
   */
  static getConnection(tenantMstId, connectionKey) {
    let connectionInfo = SessionUtils.get(
      "connection",
      SessionUtils.storageName
    );
    if (connectionInfo) {
      if (connectionInfo[tenantMstId] && connectionKey) {
        return connectionInfo[tenantMstId][connectionKey];
      } else if (connectionInfo[tenantMstId]) {
        return connectionInfo[tenantMstId];
      } else {
        return null;
      }
    } else {
      return null;
    }
  }
  /**
   * 연결 정보를 sesstion에 추가함
   * @param {String} connection
   */
  static setConnection(tenantMstId, connection) {
    let userInfo = SessionUtils.get(null, SessionUtils.storageName);
    let targetObj = {
      ...userInfo,
      connection: {
        ...userInfo.connection,
        [tenantMstId]: { ...connection },
      },
    };
    SessionUtils.set(targetObj);
  }

  /**
   * 세션에 저장된 연결 정보 삭제
   */
  static disconnect(tenantMstId) {
    let connectionList = SessionUtils.get("connection");
    delete connectionList[tenantMstId];
    let userInfo = SessionUtils.get(null, SessionUtils.storageName);
    let targetObj = {
      ...userInfo,
      connection: connectionList,
    };
    SessionUtils.set(targetObj);
  }

  /**
   * 토큰 삭제
   * 토큰이 유효하지 않는 경우 기존 토큰 삭제 처리
   */
  static initToken() {
    let userInfo = SessionUtils.get(null, SessionUtils.storageName);
    let targetObj = {
      ...userInfo,
      connection: {
        ...userInfo.connection,
        token: null,
      },
    };
    SessionUtils.set(targetObj);
  }

  /**
   * 토큰 업데이트
   * @param {String} _token
   */
  static updateToken(_token) {
    let userInfo = SessionUtils.get(null, SessionUtils.storageName);
    let targetObj = {
      ...userInfo,
      connection: {
        ...userInfo.connection,
        token: _token,
      },
    };
    SessionUtils.set(targetObj);
  }
  /**
   * 사용자 설정 정보를 session에 추가함
   * @param {Object} config
   */
  static setConfiguration(configuration) {
    let userInfo = SessionUtils.get(null, SessionUtils.storageName);
    let targetObj = { ...userInfo, configuration };
    SessionUtils.set(targetObj);
  }
  static overrideConfig(configurationCode, value) {
    let configuration = SessionUtils.get(
      "configuration",
      SessionUtils.storageName
    );
    configuration = { ...configuration, [configurationCode]: value };
    SessionUtils.set({ configuration });
  }
  /**
   * 사용자 키 config가져옴
   * @param {Object} config
   */
  static getConfiguration(configCode) {
    let configuration = SessionUtils.get(
      "configuration",
      SessionUtils.storageName
    );
    if (configuration) {
      if (configCode) {
        return configuration[configCode];
      } else {
        return configuration;
      }
    } else {
      return {};
    }
  }

  /**
   * 사용자가 관리자인지 확인
   * @returns {Boolean}
   */
  static isAdmin(user) {
    if (user) {
      const AdminAuthIndex = user.userAuths.findIndex((a) =>
        StringUtils.equalsIgnoreCase(a.userAuthType, "S")
      );
      return AdminAuthIndex > -1 ? true : false;
    } else {
      const authType = this.getAuthType();
      if (StringUtils.equalsIgnoreCase(authType, "S")) {
        return true;
      } else {
        return false;
      }
    }
  }

  /**
   * 사용자 언어 정보
   * @returns {String} userId
   */
  static getLanguage() {
    return SessionUtils.get("defaultLangCd");
  }
}

export default User;
