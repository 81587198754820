import React from "react";
import { useState } from "react";
import { BsXSquare } from "react-icons/bs";
import { FaExpandArrowsAlt } from "react-icons/fa";

const CodeMirrorWrapper = ({ children }) => {
  const [isFullScreen, setIsFullScreen] = useState(false);

  const toggleButton = () => {
    return isFullScreen ? (
      <BsXSquare
        className="resize-area"
        onClick={() => setIsFullScreen(false)}
      />
    ) : (
      <FaExpandArrowsAlt
        className="resize-area"
        onClick={() => setIsFullScreen(true)}
      />
    );
  };

  return (
    <div className={`code-mirror-wraper ${isFullScreen ? "fullscreen" : ""}`}>
      {children}
      {toggleButton()}
    </div>
  );
};

export default CodeMirrorWrapper;
