import { Enums } from "components/builder/BuilderEnum";
import BuilderHeaderComponent from "components/builder/BuilderHeaderComponent";
import EntityHeader from "components/builder/entity/EntityHeader";
import StringUtils from "components/common/utils/StringUtils";
import React, { memo, useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";

const DataModel = memo(({ ...props }) => {
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState();

  useEffect(() => {
    const CurrentPath = location.pathname.split("/")[2];
    setCurrentPath(CurrentPath);
    return () => {};
  }, [location]);

  return (
    <>
      {StringUtils.includesIgnoreCase(currentPath, [
        Enums.BuilderPath.ENTITY.CHOICE,
        Enums.BuilderPath.ENTITY.CREATE,
      ]) ? (
        <EntityHeader {...props.header} />
      ) : (
        <BuilderHeaderComponent {...props.header} />
      )}
      <Outlet />
    </>
  );
});

export default DataModel;
