import { Enums } from "components/builder/BuilderEnum";
import Message from "components/common/Message";
import Modal from "components/common/modal/UModal";
import UModalTemplate from "components/common/modal/UModalTemplate";
import StringUtils from "components/common/utils/StringUtils";
import produce from "immer";
import React from "react";
import { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";

function ErdSavePopup({ workspace, erdInfo: _erdInfo, ...props }) {
  const [erdInfo, setErdInfo] = useState(_erdInfo);
  const [isSaving, setIsSaving] = useState(false);

  const onChangeValue = (e) => {
    setErdInfo(
      produce(erdInfo, (draft) => {
        draft[e.target.id] = e.target.value;
      })
    );
  };

  const onClickConfirm = () => {
    const data = StringUtils.trimAll({
      ...erdInfo,
      useYn: "Y",
    });
    const warnMsg = (msg) => Message.alert(msg, Enums.MessageType.WARN);

    if (StringUtils.isEmpty(erdInfo.erdId)) {
      return warnMsg("ERD ID가 비어있습니다.");
    }

    if (StringUtils.isEmpty(erdInfo.erdName)) {
      return warnMsg("ERD 명이 비어있습니다.");
    }

    if (props.callback) {
      setIsSaving(true);
      props.callback(data, () => setIsSaving(false));
    }
  };

  return (
    <Modal>
      <Modal.Header title={"ERD 저장"} />
      <Modal.Body>
        <UModalTemplate>
          <Form.Group className="mb-3">
            <Form.Label style={{ cursor: "pointer" }}>
              저장 경로 확인
            </Form.Label>
            <Row>
              <Form.Group className="mb-3" as={Col}>
                <Form.Label>Application</Form.Label>
                <Form.Control disabled defaultValue={workspace.appNm} />
              </Form.Group>
              <Form.Group className="mb-3" as={Col}>
                <Form.Label>모듈</Form.Label>
                <Form.Control disabled defaultValue={workspace.moduleNm} />
              </Form.Group>
              <Form.Group className="mb-3" as={Col}>
                <Form.Label>버전</Form.Label>
                <Form.Control disabled defaultValue={workspace.version} />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} className="mb-3">
                <Form.Label>테넌트 ID</Form.Label>
                <Form.Control disabled defaultValue={workspace.tenantId} />
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label>회사 코드</Form.Label>
                <Form.Control disabled defaultValue={workspace.coCd} />
              </Form.Group>
            </Row>
            <Form.Label className="required">ERD ID</Form.Label>
            <Form.Control
              value={erdInfo.erdId}
              onChange={onChangeValue}
              id={"erdId"}
            />
            <Form.Label className="required">ERD 명</Form.Label>
            <Form.Control
              value={erdInfo.erdName}
              onChange={onChangeValue}
              id={"erdName"}
            />
            <Form.Label>설명</Form.Label>
            <Form.Control
              value={erdInfo.description}
              onChange={onChangeValue}
              id={"description"}
            />
          </Form.Group>
        </UModalTemplate>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.ProgressButton
          doing={isSaving}
          doingText="저장 중"
          disabled={isSaving}
          onClick={onClickConfirm}
        >
          확인
        </Modal.Footer.ProgressButton>
      </Modal.Footer>
    </Modal>
  );
}

export default ErdSavePopup;
