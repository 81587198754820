import ActionType from "components/builder/BuilderActionType";
import produce from "immer";

const initialState = {
  breakpoint: [],
  inCommunication: false, //통신 여부
  isDebugging: false, //디버깅 여부
  trace: [], //디버깅시 진행중인 노드 배열
  process: {}, // 현재 디버깅 중인 프로세스
  breakpointType: "", //현재 디버깅 중인 프로세스의 진행 전 ( before ) / 후 ( after )
  errType: "",
};

export default function WorkflowDebugReducer(state = initialState, action) {
  switch (action.type) {
    case ActionType.WORKFLOW.DEBUG.BREAK_POINT.ADD:
      return produce(state, (draft) => {
        const body = {
          ...action.payload,
          check: true,
        };
        draft.breakpoint.push(body);
      });
    case ActionType.WORKFLOW.DEBUG.BREAK_POINT.UPDATE:
      return produce(state, (draft) => {
        const { compId } = action.payload;
        const targetIndex = state.breakpoint.findIndex(
          (bp) => bp.compId === compId
        );
        draft.breakpoint[targetIndex] = action.payload;
      });
    case ActionType.WORKFLOW.DEBUG.BREAK_POINT.DELETE:
      return produce(state, (draft) => {
        const { compId } = action.payload;
        const targetIndex = state.breakpoint.findIndex(
          (bp) => bp.compId === compId
        );
        draft.breakpoint.splice(targetIndex, 1);
      });
    case ActionType.WORKFLOW.DEBUG.BREAK_POINT.SET:
      return produce(state, (draft) => {
        draft.breakpoint = action.payload;
      });
    case ActionType.WORKFLOW.DEBUG.SET_TRACE:
      return produce(state, (draft) => {
        draft.trace = action.payload;
      });
    case ActionType.WORKFLOW.DEBUG.BREAK_POINT.SET_TYPE:
      return produce(state, (draft) => {
        draft.breakpointType = action.payload;
      });
    case ActionType.WORKFLOW.DEBUG.SET_PROCESS:
      return produce(state, (draft) => {
        draft.process = action.payload;
      });
    case ActionType.WORKFLOW.DEBUG.SET_IS_DEBUGGING:
      return produce(state, (draft) => {
        draft.isDebugging = action.payload;
      });
    case ActionType.WORKFLOW.DEBUG.SET_IN_COMMUNICATION:
      return produce(state, (draft) => {
        draft.inCommunication = action.payload;
      });
    case ActionType.WORKFLOW.DEBUG.INIT:
      return produce(state, (draft) => {
        draft.breakpointType = initialState.breakpointType;
        draft.process = initialState.process;
        draft.trace = initialState.trace;
        draft.isDebugging = initialState.isDebugging;
        draft.inCommunication = initialState.inCommunication;
        draft.errType = initialState.errType;
      });
    case ActionType.WORKFLOW.DEBUG.SET_ERROR_TYPE:
      return produce(state, (draft) => {
        draft.errType = action.payload;
      });

    default:
      return state;
  }
}
