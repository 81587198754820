import MuiConfig from "components/common/config/MuiConfig";
import ModuleSelector from "components/common/element/ModuleSelector";
import WijmoGrid from "components/common/element/WijmoGrid";
import Modal from "components/common/modal/UModal";
import UModalTemplate from "components/common/modal/UModalTemplate";
import CommonUtils, { StringUtils } from "components/common/utils/CommonUtils";
import { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { BsSearch } from "react-icons/bs";
import ProgramService from "services/ui/ProgramService";
import WorkflowService from "services/workflow/WorkflowService";

const columns = [
  {
    field: "moduleNm",
    headerName: "모듈 명",
    width: 120,
  },
  {
    field: "tenantId",
    headerName: "테넌트 ID",
    width: 120,
  },
  {
    field: "coCd",
    headerName: "회사 코드",
    width: 120,
  },
  {
    field: "serviceUid",
    headerName: "UID",
    width: 100,
  },
  {
    field: "serviceId",
    headerName: "서비스 ID",
    width: 150,
  },
  {
    field: "serviceName",
    headerName: "서비스 명",
    width: 150,
  },
  {
    field: "description",
    headerName: "서비스 설명",
    width: 200,
  },
  {
    field: "updtUserId",
    headerName: "최종 수정자",
    width: 130,
  },
  {
    field: "updtDt",
    headerName: "최종 수정일",
    width: 150,
    renderCell: (params) => `${CommonUtils.getDate(params.updtDt, "datetime")}`,

    // valueGetter: (params) =>
    //   `${CommonUtils.getDate(params.row.updtDt, "datetime")}`,
  },
];

function WorkflowListPopup({ workspace, callbackFnc, ...props }) {
  const [isLoading, setIsLoading] = useState(false);
  const [serviceList, setServiceList] = useState([]);

  const [serviceName, setServiceName] = useState("");
  const [pageSize, setPageSize] = useState(20);
  const [selectedRow, setSelectedRow] = useState({});
  const [serviceId, setServiceId] = useState("");
  const [serviceUid, setServiceUid] = useState();
  const [workerId, setWorkerId] = useState("");
  const [selectedModule, setSelectedModule] = useState(workspace.moduleCd);

  useEffect(() => {
    onSearch();
  }, []);

  const onSearch = () => {
    const body = {
      appId: workspace.appId,
      // moduleCd: workspace.moduleCd,
      // appReleaseId: workspace.appReleaseId,
      tenantId: workspace.tenantId,
      coCd: workspace.coCd,
      serviceName,
      serviceId,
      workerId,
    };
    if (!StringUtils.isEmpty(selectedModule)) {
      body.moduleCd = selectedModule;
    }

    WorkflowService.getServiceList(
      body,
      (res) => {
        if (!res?.isError) {
          const { data } = res;
          const list = ProgramService.filterByTenantIdAndCoCd(
            data,
            body.tenantId,
            body.coCd,
            "serviceId"
          );
          setIsLoading(false);
          setServiceList(list);
        }
      },
      () => {
        setIsLoading(false);
      }
    );
  };

  const onClickConfirm = (e) => {
    const body = {
      serviceUid: selectedRow.serviceUid,
      serviceId: selectedRow.serviceId,
      serviceName: selectedRow.serviceName,
    };
    if (callbackFnc) {
      callbackFnc(body);
    }
  };

  const onRowDoubleClick = (param) => {
    const body = {
      serviceUid: param.serviceUid,
      serviceId: param.serviceId,
      serviceName: param.serviceName,
    };
    if (callbackFnc) {
      callbackFnc(body);
    }
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      onSearch();
    }
  };

  return (
    <Modal>
      <Modal.Header title="Workflow 선택" />
      <Modal.Body>
        <UModalTemplate>
          <Row className="pb-3 pt-3">
            <Col
              xs={1}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Form.Label>검색어 입력</Form.Label>
            </Col>

            <Col xs={2}>
              <ModuleSelector
                size="small"
                value={selectedModule}
                onChange={(e) => setSelectedModule(e.target.value)}
                appId={workspace.appId}
                showEmpty
              />
            </Col>
            <Col xs={2}>
              <Form.Control
                label="서비스 UID"
                placeholder="서비스 UID 검색..."
                value={serviceUid}
                fullWidth={true}
                onChange={(e) => setServiceUid(e.currentTarget.value)}
                onKeyDown={onKeyDown}
              />
            </Col>
            <Col xs={2}>
              <Form.Control
                label="서비스 ID"
                placeholder="서비스 ID 검색..."
                value={serviceId}
                fullWidth={true}
                onChange={(e) => setServiceId(e.currentTarget.value)}
                onKeyDown={onKeyDown}
              />
            </Col>
            <Col xs={2}>
              <Form.Control
                label="서비스 명"
                placeholder="서비스 명"
                value={serviceName}
                onChange={(e) => setServiceName(e.currentTarget.value)}
                onKeyDown={onKeyDown}
                fullWidth
              />
            </Col>
            <Col xs={2}>
              <Form.Control
                label="최종 수정자 ID"
                placeholder="최종 수정자 ID"
                value={workerId}
                onChange={(e) => setWorkerId(e.currentTarget.value)}
                onKeyDown={onKeyDown}
                fullWidth
              />
            </Col>
            <Col xs={1}>
              <Button variant="primary" onClick={onSearch}>
                검색 <BsSearch size="14" />
              </Button>
            </Col>
          </Row>
          <Row>
            <Col style={{}}>
              <WijmoGrid
                isLoading={isLoading}
                columns={columns}
                rows={serviceList}
                getRowId={(row) => row.serviceUid}
                onRowClick={(param) => {
                  setSelectedRow(param);
                }}
                onRowDoubleClick={onRowDoubleClick}
                {...MuiConfig.grid.options}
                rowsPerPageOptions={[pageSize]}
                pageSize={pageSize}
                style={{ height: "50vh" }}
              />
            </Col>
          </Row>
        </UModalTemplate>
      </Modal.Body>
      <Modal.Footer>
        {props.remove && (
          <Modal.Footer.Button
            side={"left"}
            onClick={props.remove}
            variant="danger"
          >
            적용 취소
          </Modal.Footer.Button>
        )}

        <Modal.Footer.Button onClick={onClickConfirm}>확인</Modal.Footer.Button>
      </Modal.Footer>
    </Modal>
  );
}

export default WorkflowListPopup;
