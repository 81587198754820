import React, { Component } from "react";
import ProcessModal from "page/popup/workflow/process/ProcessModal";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import produce from "immer";
import { MdDelete } from "react-icons/md";
import ArrayUtils from "components/common/utils/ArrayUtils";
import { AiOutlineEllipsis } from "react-icons/ai";
import WorkFlowProcessListPopup from "page/popup/workflow/WorkFlowProcessListPopup";
import Popup from "components/common/Popup";
import { stopEvent } from "components/builder/ui/editor/handler/UIEditorEventHandler";

export const START_PROCESS_DATA_TYPE = {
  ENTITY: "entity",
  ENTITY_FIELD: "entityField",
};

class StartProcess extends ProcessModal {
  constructor(props) {
    super(props);
    this.onChangeDataType = this.onChangeDataType.bind(this);
    this.onChangeInputEntity = this.onChangeInputEntity.bind(this);
    this.state = {
      processNm: "",
      dataType: START_PROCESS_DATA_TYPE.ENTITY,
      inputList: [
        {
          inputId: "",
          inputNm: "",
        },
      ],
    };
  }

  componentDidMount() {
    if (this.props.processInfo) {
      this.setState(
        produce(this.state, (draft) => {
          draft.processNm = this.props.processInfo.processNm;
          draft.dataType =
            this.props.processInfo.dataType || START_PROCESS_DATA_TYPE.ENTITY;
          if (this.props.processInfo.inputList)
            draft.inputList = this.props.processInfo.inputList;
        })
      );
    }
  }

  onValidationCheck() {
    let flag = true;
    return flag;
  }
  onClickConfirm() {
    if (this.props.callbackFnc) {
      this.props.callbackFnc(this.state);
    }
  }

  /**
   * Data Type Change 이벤트
   * @param {*} e
   */
  onChangeDataType(e) {
    this.setState(
      produce(this.state, (draft) => {
        draft.dataType = e.target.id;
        if (draft.inputList.length > 1) {
          draft.inputList.splice(1);
          this.WarnMessage("Entity 형식은 1개의 파라미터만 입력 가능합니다.");
        } else if (ArrayUtils.isEmpty(draft.inputList)) {
          draft.inputList = [{ inputId: "", inputNm: "" }];
        }
      })
    );
  }

  /**
   * Input Entity 수정
   * @param {*} e
   */
  onChangeInputEntity(e) {
    this.setState(
      produce(this.state, (draft) => {
        draft.inputEntity[e.target.id] = e.target.value;
      })
    );
  }

  /**
   * Input Entity Field 수정
   * @param {*} e
   * @param {*} idx
   */
  onChangeInput(e, idx) {
    this.setState(
      produce(this.state, (draft) => {
        draft.inputList[idx][e.target.name] = e.target.value;
      })
    );
  }

  onDeleteInput(e, index) {
    this.setState(
      produce(this.state, (draft) => {
        draft.inputList.splice(index, 1);
      })
    );
  }

  /**
   * Entity Definition 목록 팝업 호출
   * @param {*} e
   * @param {*} paramNm
   */
  onOpenEntityList(e, props) {
    stopEvent(e);
    const callbackFnc = (entity) => {
      Popup.close();
      const { entityVariable, entityNm, entityFieldList } = entity;
      if (props === START_PROCESS_DATA_TYPE.ENTITY) {
        this.setState(
          produce(this.state, (draft) => {
            draft.inputList[0].inputId = entityVariable;
            draft.inputList[0].inputNm = entityNm;
          })
        );
      } else {
        if (entityFieldList && ArrayUtils.isArray(entityFieldList)) {
          this.setState(
            produce(this.state, (draft) => {
              draft.inputList = entityFieldList.map((field) => {
                return {
                  inputId: field.fieldId,
                  inputNm: field.fieldNm,
                };
              });
            })
          );
        } else {
          this.WarnMessage(
            "해당 엔티티는 필드 목록이 없거나 호출 할 수 없는 상태입니다."
          );
        }
      }
    };

    Popup.open(
      <WorkFlowProcessListPopup
        callbackFnc={callbackFnc}
        workflow={this.props.workflow}
        nodes={this.props.nodes}
        edges={this.props.edges}
        iterator={this.props.iterator}
        includeSelf={this.props.connector ? true : false}
        getAccessibleEntityList={this.getAccessibleEntityList}
        showAll={true}
        {...props}
      />,
      {
        style: {
          content: {
            width: "550px",
          },
        },
      }
    );
  }

  renderBody() {
    return (
      <>
        <Row className="mb-3">
          <Col xs={2} className="col-label">
            <Form.Label className="required">Input Data Type</Form.Label>
          </Col>
          <Col xs={10}>
            <Form.Check
              type="radio"
              name="dataType"
              id={START_PROCESS_DATA_TYPE.ENTITY_FIELD}
              onChange={this.onChangeDataType}
              checked={
                this.state.dataType === START_PROCESS_DATA_TYPE.ENTITY_FIELD
              }
              label="Entity Field "
              inline
            />
            <Form.Check
              id={START_PROCESS_DATA_TYPE.ENTITY}
              type="radio"
              name="dataType"
              onChange={this.onChangeDataType}
              checked={this.state.dataType === START_PROCESS_DATA_TYPE.ENTITY}
              label="Entity "
              inline
            />
          </Col>
        </Row>
        <Row className="mb-1">
          <Col xs={9}>
            <Form.Label>Input Parameter</Form.Label>
          </Col>
          <Col xs={3} className="fr">
            {this.state.dataType === START_PROCESS_DATA_TYPE.ENTITY_FIELD ? (
              <>
                <Button
                  variant="outline-success"
                  onClick={(e) =>
                    this.onOpenEntityList(
                      e,
                      START_PROCESS_DATA_TYPE.ENTITY_FIELD
                    )
                  }
                  size="sm"
                >
                  참조 Entity 선택
                </Button>
                <Button
                  variant="outline-primary"
                  size="sm"
                  onClick={(e) => {
                    this.setState(
                      produce(this.state, (draft) => {
                        draft.inputList.push({
                          inputId: "",
                          inputNm: "",
                        });
                      })
                    );
                  }}
                >
                  Input 추가
                </Button>
              </>
            ) : (
              <></>
            )}
          </Col>
        </Row>
        {this.state.inputList.map((item, index) => {
          if (this.state.dataType === START_PROCESS_DATA_TYPE.ENTITY) {
            return (
              <EntityInput
                input={item}
                onChangeInput={(e) => this.onChangeInput(e, index)}
                onOpenEntityList={(e) =>
                  this.onOpenEntityList(e, START_PROCESS_DATA_TYPE.ENTITY)
                }
                key={index}
              />
            );
          } else {
            return (
              <EntityFieldInput
                input={item}
                onChangeInput={(e) => this.onChangeInput(e, index)}
                onDeleteInput={(e) => this.onDeleteInput(e, index)}
                key={index}
              />
            );
          }
        })}
      </>
    );
  }
}

StartProcess.propTypes = {};

export default StartProcess;

const EntityInput = ({ onChangeInput, onOpenEntityList, input, ...props }) => {
  return (
    <Row className="mb-1">
      <Col xs={5}>
        <InputGroup>
          <Form.Control
            placeholder="Input ID"
            value={input.inputId}
            name="inputId"
            onChange={(e) => onChangeInput(e)}
          />
          <Button
            size="sm"
            variant="outline-secondary"
            onClick={onOpenEntityList}
          >
            <AiOutlineEllipsis />
          </Button>
        </InputGroup>
      </Col>
      <Col xs={7}>
        <InputGroup>
          <Form.Control
            placeholder="Input 명"
            value={input.inputNm}
            name="inputNm"
            onChange={(e) => onChangeInput(e)}
          />
        </InputGroup>
      </Col>
    </Row>
  );
};

const EntityFieldInput = ({
  onChangeInput,
  input,
  onDeleteInput,
  ...props
}) => {
  return (
    <Row className="mb-1">
      <Col xs={5}>
        <Form.Control
          placeholder="Input ID"
          value={input.inputId}
          name="inputId"
          onChange={(e) => onChangeInput(e)}
        />
      </Col>
      <Col xs={7}>
        <InputGroup>
          <Form.Control
            placeholder="Input 명"
            value={input.inputNm}
            name="inputNm"
            onChange={(e) => onChangeInput(e)}
          />
          <Button variant="outline-danger" size="sm" onClick={onDeleteInput}>
            <MdDelete />
          </Button>
        </InputGroup>
      </Col>
    </Row>
  );
};
