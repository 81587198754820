import React from "react";
import { Component } from "react";
import ObjectUtils from "../utils/ObjectUtils";
import {
  FlexGrid,
  FlexGridColumn,
  FlexGridCellTemplate,
} from "@grapecity/wijmo.react.grid";
import { ListBox } from "@grapecity/wijmo.react.input";
import * as wjGridFilter from "@grapecity/wijmo.grid.filter";
import { showPopup, hidePopup, contains, closest } from "@grapecity/wijmo";
import { BsFillGearFill } from "react-icons/bs";
import { ArrayUtils, StringUtils } from "components/common/utils/CommonUtils";
import { FaBoxOpen } from "react-icons/fa";
import ContentsLoading from "page/common/ContentsLoading";
import { AllowPinning, SelectionMode } from "@grapecity/wijmo.grid";
import PropTypes from "prop-types";

/**
 * 위즈모 그리드 통합 컴포넌트
 * @param {{rows:Array,
 * columns:Array,
 * onRowClick:Function,
 * isLoading:Boolean,
 * selectMode:String,
 * headerFilter:Boolean,
 * allowPinning:String,
 * emptyMessage:String,
 * style:CSSProperties,
 * onRowDoubleClick:Function,
 * headersVisibility:HeadersVisibility,
 * selectedItem:Object,
 * getId:Function,
 * isCheckBox:Boolean,
 * onClickCheckItem:Function,
 * checkedItems:Array,
 * getRowKey:String
 * checkingIfRowClick: Boolean,
 * }} property
 * rows: row data
 * columns: column data
 * onRowClick: Row 클릭시 이벤트
 * onRowDoubleClick: Row 더블 클릭시 이벤트
 * isCheckBox: 체크박스 유무
 * onClickCheckItem: 체크박스 클릭시 이벤트
 * checkedItems: 체크된 item들
 * getRowKey: item들을 구벌할 수 있는 key값
 * checkingIfRowClick: Row 클릭 시 CheckBox도 같이 Check 할 것인지 여부
 * @returns
 */
class WijmoGrid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedItems: [],
      checkingItems: new Set(),
    };

    this.columnPicker = React.createRef();
    this.flexGrid = React.createRef();

    this.checkBoxChange = this.checkBoxChange.bind(this);
    this.allCheckBoxChange = this.allCheckBoxChange.bind(this);
  }

  componentDidUpdate() {
    if (!ObjectUtils.isEmpty(this.props.selectedItem) && this.props.getId) {
      const index = this.props.rows.findIndex(
        (r) => this.props.getId(r) === this.props.getId(this.props.selectedItem)
      );
      if (index > -1) this.flexGrid.current.select(index, 0);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    // 기존의 check items와 변경될 check items를 비교하여 다르면 checkedItems의 상태를 바꿔준다.
    const nowCheckedItems = new Set(this.state.checkingItems);
    if (!ObjectUtils.isEmpty(this.props.checkedItems) && this.props.getRowKey) {
      for (let i = 0; i < this.props.rows.length; i++) {
        for (let j = 0; j < this.props.checkedItems.length; j++) {
          if (
            !this.props.rows[i][this.props.getRowKey] ||
            this.props.checkedItems[j] === undefined ||
            !this.props.checkedItems[j][this.props.getRowKey]
          ) {
            return;
          }
          if (
            this.props.rows[i][this.props.getRowKey] ===
            this.props.checkedItems[j][this.props.getRowKey]
          ) {
            nowCheckedItems.add(i);
          }
        }
      }
    }

    const newCheckedItems = new Set(nextState.checkingItems);
    // check된 item들이 props로 넘어올 때 해당 item들을 따로 저장
    if (!ObjectUtils.isEmpty(nextProps.checkedItems) && nextProps.getRowKey) {
      for (let i = 0; i < nextProps.rows.length; i++) {
        for (let j = 0; j < nextProps.checkedItems.length; j++) {
          if (
            !nextProps.rows[i][nextProps.getRowKey] ||
            nextProps.checkedItems[j] === undefined ||
            !nextProps.checkedItems[j][nextProps.getRowKey]
          ) {
            return;
          }
          if (
            nextProps.rows[i][nextProps.getRowKey] ===
            nextProps.checkedItems[j][nextProps.getRowKey]
          ) {
            newCheckedItems.add(i);
          }
        }
      }
    }

    if (nowCheckedItems.size !== newCheckedItems.size) {
      this.setState({ checkingItems: newCheckedItems });
    } else {
      const filterItems = Array.from(newCheckedItems).filter(
        (item) => !nowCheckedItems.has(item)
      );
      if (filterItems.length > 0) {
        this.setState({ checkingItems: newCheckedItems });
      }
    }
    return true;
  }

  // flexGrid init 함수
  flexInitialized = (flexgrid) => {
    this.flexGrid.current = flexgrid;

    if (this.props.headerFilter) new wjGridFilter.FlexGridFilter(flexgrid);

    this.initializedPicker(this.columnPicker.current);

    //row 클릭 시 이벤트
    if (this.props.onRowClick) {
      let rowFocus;
      let colFocus;
      const onRowClick = this.props.onRowClick;

      flexgrid.addEventListener(
        flexgrid.hostElement.getElementsByClassName("wj-cells")[0],
        "click",
        function (e) {
          rowFocus = flexgrid.selection._row;
          colFocus = flexgrid.selection._col;
          onRowClick(flexgrid.selectedItems[0]);
          flexgrid.select(rowFocus, colFocus);
        }
      );
    }
    // row 더블 클릭 시 이벤트
    if (this.props.onRowDoubleClick) {
      const onRowDoubleClick = this.props.onRowDoubleClick;

      flexgrid.addEventListener(
        flexgrid.hostElement.getElementsByClassName("wj-cells")[0],
        "dblclick",
        function (e) {
          onRowDoubleClick(flexgrid.selectedItems[0], e);
        }
      );
    }

    // drag 이벤트
    flexgrid.addEventListener(
      flexgrid.hostElement.getElementsByClassName("wj-cells")[0],
      "mouseup",
      (e) => {
        if (this.props.checkingIfRowClick) {
          const newCheckedItems = new Set(this.state.checkingItems);
          const selectedItems = [];

          flexgrid.selectedRows.map((row) => {
            if (newCheckedItems.has(row._idx)) {
              newCheckedItems.delete(row._idx);
            } else {
              newCheckedItems.add(row._idx);
            }
          });
          flexgrid.itemsSource.map((item, index) => {
            if (newCheckedItems.has(index)) {
              selectedItems.push(item);
            }
          });

          this.setState({ checkingItems: newCheckedItems });

          if (this.props.onClickCheckItem) {
            this.props.onClickCheckItem(selectedItems);
          }
        }
      }
    );

    // show the column picker when the user clicks the top-left cell
    let ref = flexgrid.hostElement.querySelector(".wj-topleft");
    ref.addEventListener("mousedown", (e) => {
      let host = this.columnPicker.current.hostElement;
      if (!host.offsetHeight) {
        showPopup(host, ref, false, true, false);
        this.columnPicker.current.focus();
      } else {
        hidePopup(host, true, true);
        this.flexGrid.current.focus();
      }
      this.columnPicker.current.focus();
      e.preventDefault();
    });

    // work around Safari/IOS bug (TFS 321525, 361500, 402670)
    // https://developer.mozilla.org/en-US/docs/Web/Events/click#Safari_Mobile
    window.addEventListener("touchstart", (e) => {
      let host = this.columnPicker.current.hostElement;
      if (!contains(host, e.target) && !closest(e.target, ".wj-flexgrid")) {
        hidePopup(host, true, true);
      }
    });
  };

  // picker init 함수
  initializedPicker = (picker) => {
    this.columnPicker.current = picker;
    hidePopup(picker.hostElement);
    if (this.flexGrid.current) {
      picker.itemsSource = this.flexGrid.current.columns.filter(
        (column) => column.header !== null
      );
      picker.checkedMemberPath = "visible";
      picker.displayMemberPath = "header";
      picker.lostFocus.addHandler(() => {
        hidePopup(picker.hostElement);
      });
      picker.formatItem.addHandler((s, e) => {
        e.item.setAttribute("draggable", false);
      });
    }
  };

  checkBoxChange = (e, context) => {
    let selectedItems = [];
    const newCheckedItems = new Set(this.state.checkingItems);

    if (e.currentTarget.checked) {
      newCheckedItems.add(Number(context.row.index));
    } else {
      newCheckedItems.delete(Number(context.row.index));
    }

    this.setState({ checkingItems: newCheckedItems });

    this.flexGrid.current.itemsSource.map((item, index) => {
      if (newCheckedItems.has(index)) {
        selectedItems.push(item);
      }
    });

    if (this.props.onClickCheckItem) {
      this.props.onClickCheckItem(selectedItems);
    }
  };

  allCheckBoxChange = (e) => {
    let selectedItems = [];

    const newCheckedItems = new Set(this.state.checkingItems);

    if (e.currentTarget.checked) {
      this.flexGrid.current.itemsSource.map((item, index) => {
        newCheckedItems.add(index);
        selectedItems.push(item);
      });
    } else {
      newCheckedItems.clear();
    }
    this.setState({ checkingItems: newCheckedItems });

    if (this.props.onClickCheckItem) {
      this.props.onClickCheckItem(selectedItems);
    }
  };

  render() {
    return (
      <div style={{ position: "relative", height: "100%" }}>
        <FlexGrid
          initialized={this.flexInitialized.bind(this)}
          itemsSource={this.props.rows}
          style={ObjectUtils.isEmpty(this.props.style) ? {} : this.props.style}
          allowPinning={this.props.allowPinning || AllowPinning.ColumnRange}
          selectionMode={this.props.selectMode || SelectionMode.Row}
          isReadOnly={true}
          headersVisibility={
            this.props.headersVisibility ? this.props.headersVisibility : "All"
          }
        >
          <ListBox
            className="column-picker"
            initialized={this.initializedPicker.bind(this)}
          />

          <FlexGridCellTemplate
            cellType="RowHeader"
            template={(context) => context.row.index}
          />

          <FlexGridCellTemplate
            cellType="TopLeft"
            template={(context) => <BsFillGearFill />}
          />

          {this.props.isCheckBox && (
            <FlexGridColumn width={40} align="center">
              <FlexGridCellTemplate
                cellType="ColumnHeader"
                template={(context) => {
                  return (
                    <WijmoCell
                      value={
                        <input
                          onChange={(e) => {
                            this.allCheckBoxChange(e);
                          }}
                          onMouseUp={(e) => {
                            e.stopPropagation();
                          }}
                          type="checkbox"
                        />
                      }
                      column={{ align: "center" }}
                    />
                  );
                }}
              />

              <FlexGridCellTemplate
                cellType="Cell"
                template={(context) => {
                  return (
                    <WijmoCell
                      value={
                        <input
                          onChange={(e) => {
                            this.checkBoxChange(e, context);
                          }}
                          onMouseUp={(e) => {
                            e.stopPropagation();
                          }}
                          id={"gridCheckBox" + Number(context.row.index)}
                          type="checkbox"
                          checked={
                            this.state.checkingItems.has(
                              Number(context.row.index)
                            )
                              ? true
                              : false
                          }
                        />
                      }
                      column={{ align: "center" }}
                    />
                  );
                }}
              />
            </FlexGridColumn>
          )}
          {this.props.columns.map((column, index) => {
            return column.renderCell ? (
              <FlexGridColumn
                key={index}
                header={column.headerName}
                align={column.align}
                width={column.width ? column.width : "*"}
              >
                <FlexGridCellTemplate
                  cellType="Cell"
                  template={(context) => {
                    return (
                      <WijmoCell
                        value={column.renderCell(context.item)}
                        column={column}
                      />
                    );
                  }}
                />
              </FlexGridColumn>
            ) : (
              <FlexGridColumn
                key={index}
                header={column.headerName}
                align={column.align}
                binding={column.field}
                width={column.width}
              >
                <FlexGridCellTemplate
                  cellType="Cell"
                  template={(context) => {
                    return (
                      <WijmoCell
                        value={context.item[column.field]}
                        column={column}
                      />
                    );
                  }}
                />
              </FlexGridColumn>
            );
          })}
        </FlexGrid>

        {ArrayUtils.isEmpty(this.props.rows) && (
          <div className="data-load-wrapper">
            <div
              className="data-load-box"
              style={{ display: "flex", gap: "15px", color: "#3c3c3c" }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FaBoxOpen color="inherit" size={20} />
              </div>
              <div>
                {this.props.emptyMessage
                  ? this.props.emptyMessage
                  : "조회된 데이터가 없습니다."}
              </div>
            </div>
          </div>
        )}

        {this.props.isLoading && (
          <ContentsLoading show={this.props.isLoading} loadingText={""} />
        )}
      </div>
    );
  }
}

// PropTypes 정의
WijmoGrid.propTypes = {
  rows: PropTypes.array.isRequired, // rows는 배열로 필수
  columns: PropTypes.array.isRequired, // columns도 배열로 필수
  onRowClick: PropTypes.func, // onRowClick은 함수
  isLoading: PropTypes.bool, // isLoading은 Boolean
  selectMode: PropTypes.string, // selectMode는 문자열
  headerFilter: PropTypes.bool, // headerFilter는 Boolean
  allowPinning: PropTypes.string, // allowPinning은 문자열
  emptyMessage: PropTypes.string, // emptyMessage는 문자열
  style: PropTypes.object, // style은 CSSProperties (객체)
  onRowDoubleClick: PropTypes.func, // onRowDoubleClick은 함수
  headersVisibility: PropTypes.oneOf(["All", "Column", "None"]), // headersVisibility는 특정 문자열 중 하나
  selectedItem: PropTypes.object, // selectedItem은 객체
  getId: PropTypes.func, // getId는 함수
  isCheckBox: PropTypes.bool, // isCheckBox는 Boolean
  onClickCheckItem: PropTypes.func, // onClickCheckItem은 함수
  checkedItems: PropTypes.array, // checkedItems는 배열
  getRowKey: PropTypes.string, // getRowKey는 문자열
  checkingIfRowClick: PropTypes.bool, // checkingIfRowClick은 Boolean
};

export default WijmoGrid;

const WijmoCell = ({ column, value, ...props }) => {
  let style = {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: column.align,
    alignItems: "center",
  };

  return <div style={style}>{value}</div>;
};

/**
 * 위즈모 키다운 이벤트
 * 키이벤트에 들고가는 것이기 때문에 State가 아닌 REF에 값을 담아 넘길것
 * @param {Event} e
 * @param {Ref} selectedItemRef
 * @param {Ref} dpListRef
 * @param {Function} setSelect // 대상 선택하는 이벤트
 * @param {Function} enterEvent // 엔터 이벤트
 * @param {Function} getId // 아이디 빼는 함수
 */
export const onKeyDownEvent = (
  e,
  selectedItemRef,
  dpListRef,
  setSelect,
  enterEvent,
  getId
) => {
  if (getId) {
    if (StringUtils.equalsIgnoreCase(e.key, "enter")) {
      if (enterEvent) enterEvent(selectedItemRef.current);
    } else if (StringUtils.equalsIgnoreCase(e.key, "ArrowDown")) {
      const index = dpListRef.current.findIndex(
        (p) => getId(p) === getId(selectedItemRef.current)
      );
      let content = null;
      if (setSelect && index + 1 === dpListRef.current.length - 1) {
        content = dpListRef.current[0];
      } else {
        content = dpListRef.current[index + 1];
      }
      setSelect(content);
    } else if (StringUtils.equalsIgnoreCase(e.key, "ArrowUp")) {
      const index = dpListRef.current.findIndex(
        (p) => getId(p) === getId(selectedItemRef.current)
      );
      let content = null;
      if (setSelect && index - 1 < 0) {
        content = dpListRef.current[dpListRef.current.length - 1];
      } else {
        content = dpListRef.current[index - 1];
      }
      setSelect(content);
    }
  }
};
