import React, { useState } from "react";
import Modal from "components/common/modal/UModal";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import Popup from "components/common/Popup";
import StringUtils from "components/common/utils/StringUtils";
import Message from "components/common/Message";
import * as Enums from "components/builder/BuilderEnum";
import UserService from "services/common/UserService";
import UmodalTemplate from "components/common/modal/UModalTemplate";

/**
 * 회원 등록
 * **/
function UserRegisterPopup(props) {
  const { callbackFnc, joinRequest } = props;
  const [userId, setUserId] = useState("");
  const [userNm, setuserNm] = useState("");
  const [userEngNm, setUserEngNm] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [pwd, setPwd] = useState("");
  const [pwdConfirm, setPwdConfirm] = useState("");
  const [defaultLangCd, setDefaultLangCd] = useState("KO");
  const [IdDupCheck, setIdDupCheck] = useState(false);

  const onRegister = (requestYn) => {
    if (StringUtils.isEmpty(userId))
      return Message.alert("아이디를 입력해주세요", Enums.MessageType.WARN);
    if (StringUtils.isEmpty(userNm))
      return Message.alert("국문명 입력해주세요", Enums.MessageType.WARN);
    if (StringUtils.isEmpty(userEmail))
      return Message.alert("이메일을 입력해주세요", Enums.MessageType.WARN);
    if (pwd !== pwdConfirm)
      return Message.alert("비밀번호가 맞지 않습니다.", Enums.MessageType.WARN);
    if (StringUtils.isEmpty(pwdConfirm))
      return Message.alert(
        "비밀번호 확인을 입력해주세요",
        Enums.MessageType.WARN
      );

    const body = {
      userId,
      userNm,
      userEngNm,
      userEmail,
      pwd,
      defaultLangCd,
      requestYn,
    };
    if (joinRequest) {
      Message.confirm("사용자 등록 및 사용 요청을 진행합니다.", () => {
        UserService.register(body, (res) => {
          const { isSuccess, message } = res.data;
          if (isSuccess === "Y") {
            Message.alert(
              "요청이 완료 되었습니다. 담당자 또는 관리자의 승인을 기다려 주세요",
              Enums.MessageType.SUCCESS
            );
            Popup.close();
          } else {
            Message.alert(message, Enums.MessageType.ERROR);
          }
        });
      });
    } else {
      Message.confirm("사용자를 등록하시겠습니까?", () => {
        UserService.register(body, (res) => {
          const { isSuccess, message } = res.data;
          if (isSuccess === "Y") {
            Message.alert(message, Enums.MessageType.SUCCESS);
            Popup.close();
            if (callbackFnc) {
              callbackFnc();
            }
          } else {
            Message.alert(message, Enums.MessageType.ERROR);
          }
        });
      });
    }
  };

  const dupCheck = () => {
    if (IdDupCheck) {
      //중복조회가 다된 경우
      setIdDupCheck((prev) => !prev);
    } else {
      // 중복조회 해야하는 경우
      if (StringUtils.isEmpty(userId)) {
        return Message.alert("아이디를 입력해주세요", Enums.MessageType.WARN);
      }

      UserService.checkDuplicate({ userId }, (res) => {
        if (res.data) {
          Message.confirm(
            `"${userId}" 사용가능한 아이디 입니다. 그대로 사용하시겠습니까?`,
            () => {
              setIdDupCheck(true);
            }
          );
        } else {
          setIdDupCheck(false);
          Message.alert(
            "중복되는 아이디가 존재합니다.",
            Enums.MessageType.INFO
          );
        }
      });
    }
  };

  return (
    <Modal>
      <Modal.Header title={"사용자 신규 등록"} />
      <Modal.Body>
        <Form>
          <UmodalTemplate>
            <Form.Group>
              <Form.Label className="required">사용자 ID</Form.Label>
              <Row>
                <Col xs={12}>
                  <InputGroup>
                    <Form.Control
                      value={userId}
                      onChange={(e) => setUserId(e.currentTarget.value)}
                      readOnly={IdDupCheck}
                    />
                    <Button
                      variant={IdDupCheck ? "secondary" : "primary"}
                      onClick={dupCheck}
                    >
                      {IdDupCheck ? "아이디 변경" : "중복 조회"}
                    </Button>
                  </InputGroup>
                </Col>
              </Row>
            </Form.Group>
            <Form.Group>
              <Form.Label className="required">사용자 국문명</Form.Label>
              <Form.Control
                value={userNm}
                onChange={(e) => setuserNm(e.currentTarget.value)}
              />
              <Form.Text></Form.Text>
            </Form.Group>
            <Form.Group>
              <Form.Label>사용자 영문명</Form.Label>
              <Form.Control
                value={userEngNm}
                onChange={(e) => setUserEngNm(e.currentTarget.value)}
              />
              <Form.Text></Form.Text>
            </Form.Group>
            <Form.Group>
              <Form.Label className="required">사용자 이메일</Form.Label>
              <Form.Control
                value={userEmail}
                onChange={(e) => setUserEmail(e.currentTarget.value)}
              />
              <Form.Text></Form.Text>
            </Form.Group>
            <Form.Group>
              <Form.Label className="required">비밀번호</Form.Label>
              <Form.Control
                value={pwd}
                onChange={(e) => setPwd(e.currentTarget.value)}
                type={"password"}
              />
              <Form.Text></Form.Text>
            </Form.Group>
            <Form.Group>
              <Form.Label className="required">비밀번호 확인</Form.Label>
              <Form.Control
                value={pwdConfirm}
                type={"password"}
                onChange={(e) => setPwdConfirm(e.currentTarget.value)}
              />
              <Form.Text></Form.Text>
            </Form.Group>
            <Form.Group>
              <Form.Label>사용 언어</Form.Label>
              <Form.Select
                value={defaultLangCd}
                onChange={(e) => setDefaultLangCd(e.target.value)}
              >
                <option value={"KO"}>한국어</option>
                <option value={"EN"}>영어</option>
                <option value={"CN"}>중국어</option>
                <option value={"JP"}>일본어</option>
                <option value={"BN"}>베트남어</option>
              </Form.Select>
              <Form.Text></Form.Text>
            </Form.Group>
          </UmodalTemplate>
        </Form>
      </Modal.Body>
      <Modal.Footer closeText={"취소"}>
        {joinRequest ? (
          <Modal.Footer.Button
            onClick={() => onRegister("Y")}
            variant={"primary"}
          >
            등록 요청
          </Modal.Footer.Button>
        ) : (
          <Modal.Footer.Button
            onClick={() => onRegister("N")}
            variant={"primary"}
          >
            등록
          </Modal.Footer.Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default UserRegisterPopup;
