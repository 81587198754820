import Blockly from "blockly";
import { BlockPartCollapsedIcon } from "../blockIcon/BlockPartCollapsedIcon";

/**
 * Generate the context menu for this block.
 *
 * @returns Context menu options or null if no menu.
 */
Blockly.BlockSvg.prototype.generateContextMenu = function () {
  if (this.workspace.options.readOnly || !this.contextMenu) {
    return null;
  }
  let menuOptions = Blockly.ContextMenuRegistry.registry.getContextMenuOptions(
    Blockly.ContextMenuRegistry.ScopeType.BLOCK,
    { block: this }
  );

  // Allow the block to add or modify menuOptions.
  if (this.customContextMenu) {
    menuOptions = this.customContextMenu(menuOptions);
  }

  return menuOptions;
};

// Block을 마우스 우클릭 했을 때 나오는 menu item에 custom item 추가
Blockly.BlockSvg.prototype.customContextMenu = function (menuOptions) {
  menuOptions.push(duplicateConnBlocks(this, menuOptions.length));
  menuOptions.push(deleteConnBlocks(this, menuOptions.length));
  return menuOptions;
};

// Block을 복제 하면 연결된 블록 모두 복제 하는 menu item 생성
const duplicateConnBlocks = (block, index) => {
  // Block을 복제 하면 연결된 블록 모두 복제 하는 함수
  const callback = (scope) => {
    let duplicateBlock = Blockly.clipboard.duplicate(scope.block);
    let duplicateConnection = duplicateBlock.nextConnection;

    let block = scope.block;
    let connection = block.nextConnection;

    while (
      connection.targetBlock() &&
      !connection.targetBlock().isInsertionMarker()
    ) {
      block = connection.targetBlock();

      duplicateBlock = Blockly.clipboard.duplicate(block);

      duplicateConnection.connect(duplicateBlock.previousConnection);
      duplicateConnection = duplicateBlock.nextConnection;

      connection = block.nextConnection;
    }
  };
  const newOption = {
    callback: callback,
    enabled: true,
    scope: { block: block },
    text: "연결된 모든 블록 복제",
    weight: index + 1,
  };
  return newOption;
};

const deleteConnBlocks = (block, index) => {
  // Block을 삭제 하면 연결된 블록 모두 삭제 하는 함수
  const callback = (scope) => {
    let block = scope.block;
    let connection = block.nextConnection;
    let nextBlock = connection.targetBlock();
    block.checkAndDelete();

    while (nextBlock && !nextBlock.isInsertionMarker()) {
      block = nextBlock;
      connection = block.nextConnection;
      nextBlock = connection.targetBlock();
      block.checkAndDelete();
    }
  };
  const newOption = {
    callback: callback,
    enabled: true,
    scope: { block: block },
    text: "연결된 모든 블록 삭제",
    weight: index + 1,
  };
  return newOption;
};

/**
 * Set whether the block is collapsed or not.
 *
 * @param collapsed True if collapsed.
 */
Blockly.BlockSvg.prototype.setCollapsed = function (
  collapsed,
  partCollapsed = null
) {
  if (this.collapsed_ === collapsed && partCollapsed === null) {
    return;
  }

  if (this.collapsed_ !== collapsed) {
    Blockly.Events.fire(
      new (Blockly.Events.get(Blockly.Events.BLOCK_CHANGE))(
        this,
        "collapsed",
        null,
        this.collapsed_,
        collapsed
      )
    );
    this.collapsed_ = collapsed;
  }
  if (partCollapsed !== null) {
    this.updateCollapsed_();
    this.updatePartCollapsed_(partCollapsed);
    return;
  }
  this.updateCollapsed_();
};

/**
 * Makes sure that when the block is collapsed, it is rendered correctly
 * for that state.
 * @private
 */
Blockly.BlockSvg.prototype.updateCollapsed_ = function () {
  const collapsed = this.isCollapsed();
  const collapsedInputName = Blockly.Block.COLLAPSED_INPUT_NAME;
  const collapsedFieldName = Blockly.Block.COLLAPSED_FIELD_NAME;

  for (let i = 0, input; (input = this.inputList[i]); i++) {
    if (input.name !== collapsedInputName) {
      input.setVisible(!collapsed);
    }
  }

  for (const icon of this.getIcons()) {
    icon.updateCollapsed();
  }

  if (!collapsed) {
    this.updateDisabled();
    if (this.getInput(collapsedInputName)) {
      this.removeInput(collapsedInputName);
    }
    return;
  }

  var text = this.toString(Blockly.COLLAPSE_CHARS);
  var field = this.getField(collapsedFieldName);
  if (field) {
    field.setValue(text);
    return;
  }

  var input =
    this.getInput(collapsedInputName) ||
    this.appendDummyInput(collapsedInputName);
  // input.appendField(new Blockly.FieldLabel(text), collapsedFieldName);
  if (!input.fieldRow.length) {
    let text = this.type;
    let len = 0;
    let realLen = 0;
    for (let i = 0; i < text.length; i++) {
      if (escape(text.charAt(i)).length === 6) {
        len++;
      }
      len++;
      realLen++;
    }

    // text의 길이가 블록의 길이를 넘어갈 때 "..."을 붙여준다.
    if (len >= 35) {
      if (len - realLen > 10 && realLen >= 22) {
        input.appendField(text.slice(0, 19) + "...");
      } else {
        input.appendField(text);
      }
    } else {
      input.appendField(text);
    }
  }
};

/**
 * 블록을 부분적으로 접기
 * @param {*} partCollapsed
 * @returns
 */
Blockly.BlockSvg.prototype.updatePartCollapsed_ = function (partCollapsed) {
  const collapsedInputName = Blockly.Block.COLLAPSED_INPUT_NAME;
  const collapsedFieldName = Blockly.Block.COLLAPSED_FIELD_NAME;
  const notCollapsedInput = [];

  // part_collapsed_icon 일 때 접히는 부분과 안 접히는 부분을 구분하기 위한 값 저장
  let partCollapsedIndex = -1;
  for (const icon of this.getIcons()) {
    if (icon instanceof BlockPartCollapsedIcon) {
      partCollapsedIndex = Number(icon.state);
      break;
    }
  }
  if (partCollapsedIndex === -1) {
    return;
  }

  for (let i = 0, input; (input = this.inputList[i]); i++) {
    if (input.name !== collapsedInputName) {
      // 부분 접기
      if (partCollapsedIndex < i && partCollapsed) {
        input.setVisible(false);
      } else {
        notCollapsedInput.push(input);
        input.setVisible(true);
      }
    }
  }

  this.updateDisabled();
  // if (!this.isCollapsed()) {
  //   this.removeInput(collapsedInputName);
  //   return;
  // }

  var text = this.toString(Blockly.COLLAPSE_CHARS);
  var field = this.getField(collapsedFieldName);
  if (field) {
    field.setValue(text);
    return;
  }

  notCollapsedInput.map((input) => {
    input.appendField(new Blockly.FieldLabel(), "dummyField");
    input.removeField("dummyField");
  });
};

/**
 * Position a block so that it doesn't move the target block when connected.
 * The block to position is usually either the first block in a dragged stack or
 * an insertion marker.
 * @param {!Blockly.RenderedConnection} sourceConnection The connection on the
 *     moving block's stack.
 * @param {!Blockly.RenderedConnection} targetConnection The connection that
 *     should stay stationary as this block is positioned.
 * @package
 */
Blockly.BlockSvg.prototype.positionNearConnection = function (
  sourceConnection,
  targetConnection
) {
  // We only need to position the new block if it's before the existing one,
  // otherwise its position is set by the previous block.
  if (
    sourceConnection.type === Blockly.connectionTypes.NEXT_STATEMENT ||
    sourceConnection.type === Blockly.connectionTypes.INPUT_VALUE
  ) {
    var dx = targetConnection.x - sourceConnection.x;
    var dy = targetConnection.y - sourceConnection.y;

    this.moveBy(dx, dy);
  }
};
