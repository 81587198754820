import React from "react";
import { PropertiesHeader } from "components/builder/entity/entityComponents/EntityComponentStyle";
import { DraggableEntityContents } from "./DynamicApiTab";

const tables = [
  {
    key: "input",
    tableNm: "Input Entity",
    physEntityNm: "inputEntity",
    logEntityNm: "Input Entity",
    dataModelEntityFields: [],
    serviceInout: "input",
    relation: [],
  },
  {
    key: "output",
    tableNm: "Output Entity",
    physEntityNm: "outputEntity",
    logEntityNm: "Output Entity",
    serviceInout: "output",
    dataModelEntityFields: [],
  },
];

function ServiceApiTab() {
  return (
    <div className="table-list">
      <PropertiesHeader>Service API Entity</PropertiesHeader>
      {tables.map((table) => {
        return <DraggableEntityContents key={table.key} data={table} />;
      })}
    </div>
  );
}

export default ServiceApiTab;
