import Modal from "components/common/modal/UModal";
import UModalTemplate from "components/common/modal/UModalTemplate";
import User from "components/common/utils/UserUtils";
import produce from "immer";
import React from "react";
import { useState } from "react";
import { Col, Form, FormGroup, Row } from "react-bootstrap";
import WorkflowService from "services/workflow/WorkflowService";

/**
 * CS File의 서비스 목록을 저장할 때 사용
 * @returns
 */
const CSFileSavePopup = ({ fileInfo, workspace, callback, ...props }) => {
  const [isSaving, setIsSaving] = useState(false);
  const [saveFileInfo, setSaveFileInfo] = useState({
    ...fileInfo,
  });

  const onChangeProperty = (e) => {
    setSaveFileInfo(
      produce(saveFileInfo, (draft) => {
        draft[e.target.id] = e.target.value;
      })
    );
  };

  const onClickConfirm = () => {
    const body = produce(saveFileInfo, (draft) => {
      draft.tenantId = workspace.tenantId;
      draft.coCd = workspace.coCd;
      draft.appReleaseId = workspace.appReleaseId;
      draft.appId = workspace.appId;
      draft.moduleCd = workspace.moduleCd;
      draft.userId = User.getId();
      draft.serviceList = draft.serviceList.map((service) => {
        const serviceObj = {
          ...service,
          ...service.serviceInfo,
        };
        delete serviceObj.serviceInfo;
        serviceObj.serviceContent = JSON.stringify(serviceObj.serviceContent);
        serviceObj.serviceMemo = JSON.stringify(serviceObj.serviceMemo);
        serviceObj.convertLog = JSON.stringify(serviceObj.convertLog);
        serviceObj.serviceComment = JSON.stringify(serviceObj.serviceComment);
        serviceObj.useYn = "Y";
        serviceObj.commitComment = `${saveFileInfo.fileNm} 파일 서비스 저장 `;
        serviceObj.releaseCommentYn = "N";

        return serviceObj;
      });
    });
    if (callback) {
      setIsSaving(true);
      WorkflowService.saveCSWorkflow(
        body,
        (res) => {
          callback(res.data);
          setIsSaving(false);
        },
        () => {
          setIsSaving(false);
        }
      );
    }
  };

  return (
    <Modal>
      <Modal.Header title={`${fileInfo.fileNm} 업로드`} />
      <Modal.Body>
        <UModalTemplate>
          <Row>
            <Form.Group className="mb-3" as={Col}>
              <Form.Label>Application</Form.Label>
              <Form.Control disabled defaultValue={workspace.appNm} />
            </Form.Group>
            <Form.Group className="mb-3" as={Col}>
              <Form.Label>모듈</Form.Label>
              <Form.Control disabled defaultValue={workspace.moduleNm} />
            </Form.Group>
            <Form.Group className="mb-3" as={Col}>
              <Form.Label>버전</Form.Label>
              <Form.Control disabled defaultValue={workspace.version} />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} className="mb-3">
              <Form.Label>테넌트 ID</Form.Label>
              <Form.Control disabled defaultValue={workspace.tenantId} />
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>회사 코드</Form.Label>
              <Form.Control disabled defaultValue={workspace.coCd} />
            </Form.Group>
          </Row>
          <FormGroup>
            <Form.Label className="required">파일 명</Form.Label>
            <Form.Control
              className="mb-3"
              defaultValue={`${saveFileInfo.fileNm}.${saveFileInfo.fileExtension}`}
              disabled
            />
          </FormGroup>
          <FormGroup>
            <Form.Label>서비스 목록</Form.Label>
            {fileInfo.serviceList.map((service, index) => {
              return (
                <div
                  style={{ display: "flex", height: "32px" }}
                  className="mb-1"
                >
                  <div
                    style={{
                      width: "30px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    {index + 1}
                  </div>
                  <div style={{ width: "100%" }}>
                    <Form.Control
                      value={service.serviceInfo.serviceName}
                      disabled={true}
                    />
                  </div>
                </div>
              );
            })}
          </FormGroup>
        </UModalTemplate>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.ProgressButton
          doing={isSaving}
          doingText="저장 중"
          onClick={onClickConfirm}
        >
          저장
        </Modal.Footer.ProgressButton>
      </Modal.Footer>
    </Modal>
  );
};

export default CSFileSavePopup;
