import Api from "components/common/Api";
import ObjectUtils from "components/common/utils/ObjectUtils";

class AppService {
  static getAppList(data, callbackFnc) {
    const body = { ...data };
    Api.post("/common/getAppList", body, callbackFnc);
  }
  static getApp(data, callbackFnc) {
    Api.post("/common/getApp", data, callbackFnc);
  }
  static getAppModuleList(data, callbackFnc) {
    Api.post("/common/getAppModuleList", data, callbackFnc);
  }
  static deleteModule(data, callbackFnc, errCallback) {
    Api.post("/common/deleteModule", data, callbackFnc);
  }
  static saveApplication(data, callbackFnc) {
    Api.post("/common/saveApplication", data, callbackFnc);
  }
  static deleteApplication(data, callbackFnc) {
    Api.post("/common/deleteApplication", data, callbackFnc);
  }
  static getLatestReleaseVersion(data, callbackFnc) {
    Api.post("/common/getLatestReleaseVersion", data, callbackFnc);
  }
  static saveRelease(data, callbackFnc, errCallback) {
    Api.post("/common/saveRelease", data, callbackFnc, errCallback);
  }
  static getReleaseList(data, callbackFnc) {
    Api.post("/common/getReleaseList", data, callbackFnc);
  }
  static deleteRelease(data, callbackFnc) {
    Api.post("/common/deleteRelease", data, callbackFnc);
  }
  static getTenantList(data, callbackFnc) {
    Api.post("/common/getTenantList", data, callbackFnc);
  }
  static saveTenant(data, callbackFnc) {
    Api.post("/common/saveTenant", data, callbackFnc);
  }
  static deleteTenant(data, callbackFnc) {
    Api.post("/common/deleteTenant", data, callbackFnc);
  }
  static getCompanyList(data, callbackFnc) {
    Api.post("/common/getCompanyList", data, callbackFnc);
  }
  static getTenantMst(data, callbackFnc) {
    Api.post("/common/getTenantMst", data, callbackFnc);
  }
  static getTenantMstList(data, callbackFnc) {
    Api.post("/common/getTenantMstList", data, callbackFnc);
  }
  static getAppModule(data, callbackFnc) {
    Api.post("/common/getAppModule", data, callbackFnc);
  }
  static getTenantDistinctList(data, callbackFnc) {
    Api.post("/common/getTenantDistinctList", data, callbackFnc);
  }
  static getAppModuleMst(data, callbackFnc) {
    Api.post("/common/getAppModuleMst", data, callbackFnc);
  }
  static getAvailableAppReleaseList(data, callbackFnc) {
    Api.post("/common/getAvailableAppReleaseList", data, callbackFnc);
  }
  static saveTenantBatch(data, callbackFnc) {
    Api.post("/common/saveTenantBatch", data, callbackFnc);
  }
  static updateTenantMst(data, callbackFnc) {
    Api.post("/common/updateTenantMst", data, callbackFnc);
  }
  static getUserAuthList(data, callbackFnc, errCallback) {
    Api.post("/common/getUserAuthList", data, callbackFnc, errCallback);
  }
  static saveAppModule(data, callbackFnc, errCallback) {
    Api.post("/common/saveAppModule", data, callbackFnc, errCallback);
  }

  /**
   * App 신규 생성시 default Config를 가져온다.
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallback
   */
  static getAppDefaultConfig(data, callbackFnc, errCallback) {
    Api.post("/common/getAppDefaultConfig", data, callbackFnc, errCallback);
  }
  static getAppConfig(data, callbackFnc, errCallback) {
    Api.post("/common/getAppConfig", data, callbackFnc, errCallback);
  }

  static getWorkspaceName = (workspace) => {
    if (!ObjectUtils.isEmpty(workspace)) {
      return `${workspace.appNm} > ${workspace.moduleNm} > ${
        workspace.version
      } > ${workspace.tenantNm}${
        workspace.tenantId !== "*" ? `(${workspace.tenantId})` : ""
      } > ${workspace.coNm}${
        workspace.coCd !== "*" ? `(${workspace.coCd})` : ""
      }`;
    } else {
      return null;
    }
  };
}

export default AppService;
