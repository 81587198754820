import React from "react";
import styled from "styled-components";

const DraggableDiv = styled.div`
  position: absolute;
  pointer-events: none;
  height: 0;
  width: 0;
`;

class BuilderDraggableContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <DraggableDiv id={this.props.id} />;
  }
}
export default BuilderDraggableContainer;
