import React, { useEffect, useState } from "react";
import Modal from "components/common/modal/UModal";
import Message from "components/common/Message";
import StringUtils from "components/common/utils/StringUtils";
import * as Enums from "components/builder/BuilderEnum";
import JsonUtils from "components/common/utils/JsonUtils";
import produce from "immer";
import ObjectUtils from "components/common/utils/ObjectUtils";
import User from "components/common/utils/UserUtils";
import { Col, Form, Row } from "react-bootstrap";
import { Checkbox, FormControlLabel, Tooltip } from "@mui/material";

function DeployPopup(props) {
  const {
    title,
    callbackFnc,
    information,
    output,
    saveAs,
    updatePageByProps,
    callbackDeployFnc,
    workspace,
    connection,
  } = props;

  const [programId, setProgramId] = useState("");
  const [programNm, setProgramNm] = useState("");
  const [description, setdescription] = useState("");
  const [templateShareYn, setTemplateShareYn] = useState(false);
  const [commitComment, setCommitComment] = useState("");
  const [releaseCommentYn, setReleaseCommentYn] = useState("N");
  const [deployWithDmYn, setDeployWithDmYn] = useState("N");

  const [isDeploying, setIsDeploying] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [expandSaveDetail, setExpandSaveDetail] = useState(true);

  useEffect(() => {
    if (information) {
      setProgramId(information.programId);
      setProgramNm(information.programNm);
      setdescription(information.description);
      setTemplateShareYn(information.templateShareYn === "Y" ? true : false);
    }
    if (output.page.propertyValue) {
      const { propertyValue: pageInfo } = output.page;
      setProgramId(pageInfo?.programId);
      setProgramNm(pageInfo?.programName);
      setdescription(pageInfo?.programDesc);
    }
    if (saveAs) {
      setProgramId("");
    }
  }, []);

  const inputValueValidation = () => {
    if (StringUtils.isEmpty(programId)) {
      Message.alert("프로그램 ID를 설정해주세요.", Enums.MessageType.WARN);
      return false;
    }
    if (StringUtils.isEmpty(programNm)) {
      Message.alert("프로그램 명을 설정해주세요.", Enums.MessageType.WARN);
      return false;
    }
    if (StringUtils.isEmpty(commitComment)) {
      Message.alert("저장 코멘트를 입력해주세요.", Enums.MessageType.WARN);
      return false;
    }
    //기존에 저장된 프로그램을 다름이름으로 저장할때 같은 아이디로 저장 안되도록 방지
    if (saveAs && information.programId === programId) {
      Message.alert(
        "이전에 저장했던 ID와 동일 합니다.",
        Enums.MessageType.WARN
      );
      return false;
    }

    return {
      appId: workspace.appId,
      moduleCd: workspace.moduleCd,
      appReleaseId: workspace.appReleaseId,
      tenantId: workspace.tenantId,
      coCd: workspace.coCd,
      programId,
      programNm,
      description,
      releaseCommentYn,
      deployWithDmYn,
      templateSaveYn: "Y",
      templateShareYn: templateShareYn ? "Y" : "N",
      appApplyType: "N",
      useYn: "Y",
      committer: User.getMastRecId(),
      commitComment,
    };
  };

  const setUpdateProperties = () => {
    return {
      programId,
      programDesc: description,
      programName: programNm,
    };
  };

  // combobox load
  const deployData = (event) => {
    event.preventDefault();
    const inputValue = inputValueValidation();
    if (inputValue) {
      Message.confirm("지정 서버에 Deploy 하시겠습니까?", () => {
        SaveTransaction(setIsDeploying, callbackDeployFnc);
      });
    }
  };

  const SaveTransaction = (setIsLoading, callback) => {
    updateProperty({
      ...output.page.propertyValue,
      ...setUpdateProperties(),
    });
    setIsLoading(true);
    setTimeout(() => {
      callback.call(this, inputValueValidation(), () => setIsLoading(false));
    }, 1000);
  };

  const btnTemplateShare = (value) => {
    setTemplateShareYn(value);
  };

  const updateProperty = (propertyValue) => {
    const compId = output.page.compId;
    const changedOutput = produce(output, (draft) => {
      const targetNode = JsonUtils.overrideNode(
        draft,
        "compId",
        compId,
        "propertyValue",
        propertyValue
      );
      if (ObjectUtils.isEmpty(targetNode)) {
        console.log("Could not find target node !!!!");
        return false;
      }
    });
    if (updatePageByProps) {
      updatePageByProps(changedOutput, propertyValue);
    }
  };

  const onChangeProgramId = (e) => {
    let value = e.currentTarget.value.toUpperCase().split(" ").join("");
    setProgramId(value);
  };
  const onChangeProgramName = (e) => {
    let value = e.currentTarget.value;
    setProgramNm(value);
  };
  const onChangeProgramDesc = (e) => {
    let value = e.currentTarget.value;
    setdescription(value);
  };
  const onChangeReleaseCommentYn = (e) => {
    let value = e.target.checked;
    if (value) {
      setReleaseCommentYn("Y");
    } else {
      setReleaseCommentYn("N");
    }
  };
  const onChangeDeployWithDmYn = (e) => {
    let value = e.target.checked;
    if (value) {
      setDeployWithDmYn("Y");
    } else {
      setDeployWithDmYn("N");
    }
  };

  return (
    <Modal>
      {/*
       * Modal.Header props
       *   - title
       */}
      <Modal.Header title={title} />
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label
              onClick={(e) => setExpandSaveDetail(!expandSaveDetail)}
              style={{ cursor: "pointer" }}
            >
              저장 경로 확인 {expandSaveDetail ? "▲" : "▼"}
            </Form.Label>
            {expandSaveDetail ? (
              <>
                <Row>
                  <Form.Group className="mb-3" as={Col}>
                    <Form.Label>Application</Form.Label>
                    <Form.Control disabled defaultValue={workspace.appNm} />
                  </Form.Group>
                  <Form.Group className="mb-3" as={Col}>
                    <Form.Label>모듈</Form.Label>
                    <Form.Control disabled defaultValue={workspace.moduleNm} />
                  </Form.Group>
                  <Form.Group className="mb-3" as={Col}>
                    <Form.Label>버전</Form.Label>
                    <Form.Control disabled defaultValue={workspace.version} />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col} className="mb-3">
                    <Form.Label>테넌트 ID</Form.Label>
                    <Form.Control disabled defaultValue={workspace.tenantId} />
                  </Form.Group>

                  <Form.Group as={Col}>
                    <Form.Label>회사 코드</Form.Label>
                    <Form.Control disabled defaultValue={workspace.coCd} />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group className="mb-3" as={Col}>
                    <Form.Label>연결 명</Form.Label>
                    <Form.Control
                      defaultValue={connection.connectionNm}
                      disabled={true}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" as={Col}>
                    <Form.Label>연결 HOST</Form.Label>
                    <Form.Control
                      defaultValue={connection.host}
                      disabled={true}
                    />
                  </Form.Group>
                </Row>
              </>
            ) : (
              <></>
            )}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label className="required">프로그램 ID</Form.Label>
            <input
              type="text"
              id="programId"
              name="programId"
              className="form-control"
              onChange={onChangeProgramId}
              value={programId}
              disabled={true}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="required">프로그램 명</Form.Label>
            <input
              type="text"
              id="programNm"
              name="programNm"
              className="form-control"
              onChange={onChangeProgramName}
              value={programNm}
              disabled={true}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>프로그램 설명</Form.Label>
            <textarea
              id="description"
              className="form-control form-control-sm"
              onChange={onChangeProgramDesc}
              defaultValue={description}
              disabled={true}
              rows={3}
              style={{ resize: "none" }}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="required">프로그램 배포 코멘트</Form.Label>
            <input
              type="text"
              id="programComment"
              name="programComment"
              className="form-control"
              max={120}
              placeholder={"최대 120자"}
              value={commitComment}
              onChange={(e) => setCommitComment(e.target.value)}
            />
          </Form.Group>
          <Form.Group
            className="mb-3"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Tooltip
              title="Data Model을 별도 배포 하고, 프로그램 배포시 Data Model을 배포에서 제외하면 배포하는데 소요되는 시간을 줄일 수 있습니다."
              placement="left"
            >
              <FormControlLabel
                label={"Data Model 포함 배포"}
                control={
                  <Checkbox
                    checked={deployWithDmYn === "Y"}
                    onChange={onChangeDeployWithDmYn}
                  />
                }
              />
            </Tooltip>
            <Tooltip
              title="신규 버전 배포시 이전 버전으로 부터의 개정내용에 포함할지 여부를 선택합니다."
              placement="left"
            >
              <FormControlLabel
                label={"배포 코멘트를 개정 내용에 포함"}
                control={
                  <Checkbox
                    checked={releaseCommentYn === "Y"}
                    onChange={onChangeReleaseCommentYn}
                  />
                }
              />
            </Tooltip>
          </Form.Group>
        </Form>
      </Modal.Body>

      {/*
       * Modal.Footer props
       *   - invisibleClose : Close 버튼을 보이지 않게 (default empty or false)
       *   - closeText : close 버튼명  (default "Close") -->  닫기,Close,취소,Cancel 을 상황에 따라 넣을수 있음.
       *   - ex closeText="취소"
       *
       * Popup 수동 Close
       * import Popup from "components/common/Popup";
       *   - Popup.close();
       *
       */}
      <Modal.Footer
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Modal.Footer.ProgressButton
          onClick={deployData}
          variant="success"
          doing={isDeploying}
          doingText={"배포 중"}
          disabled={isSaving}
        >
          서버 배포
        </Modal.Footer.ProgressButton>
      </Modal.Footer>
    </Modal>
  );
}

export default DeployPopup;
