import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { Enums } from "components/builder/BuilderEnum";
import Message from "components/common/Message";
import Popup from "components/common/Popup";
import Modal from "components/common/modal/UModal";
import UModalTemplate from "components/common/modal/UModalTemplate";
import StringUtils from "components/common/utils/StringUtils";
import produce from "immer";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { MdDelete } from "react-icons/md";

/**
 * 신규 ERD 등록 팝업
 * @param {*} param0
 * @returns
 */
function NewErdPopup({ ...props }) {
  const [erdInfo, setErdInfo] = useState({
    erdId: "",
    erdName: "",
    description: "",
    areaList: [],
    erdType: "single",
    dbType: "",
  });

  useEffect(() => {
    if (props.erdInfo) {
      setErdInfo(
        produce(erdInfo, (draft) => {
          draft.erdId = props.erdInfo.erdId;
          draft.erdName = props.erdInfo.erdName;
          draft.description = props.erdInfo.description;
          draft.erdType = props.erdInfo.erdType;
          draft.dbType = props.erdInfo.dbType;

          for (const ct in props.areas) {
            draft.areaList.push({
              compId: props.areas[ct].compId,
              name: props.areas[ct].name,
              type: props.areas[ct].type,
              alias: props.areas[ct].alias,
            });
          }
        })
      );
    }
  }, []);

  /**
   * 기본 항목 체인지 이벤트
   * @param {*} e
   */
  const onChangeValue = (e) => {
    setErdInfo(
      produce(erdInfo, (draft) => {
        if (e.target.type === "checkbox") {
          draft[e.target.id] = e.target.checked;
        } else draft[e.target.id] = e.target.value;
      })
    );
  };

  /**
   * 확인 버튼 클릭 이벤트
   * @returns
   */
  const onClickConfirm = () => {
    const erdData = { ...erdInfo };

    const warnMsg = (text) => Message.alert(text, Enums.MessageType.WARN);
    if (StringUtils.isEmpty(erdData.erdId)) {
      return warnMsg("ERD ID를 입력해주세요.");
    }
    if (StringUtils.isEmpty(erdData.erdName)) {
      return warnMsg("ERD 명을 입력해주세요.");
    }

    if (StringUtils.equalsIgnoreCase(erdData.erdType, "single")) {
      erdData.areaList = [
        {
          compId: `_0${StringUtils.getUuid()}`,
          name: "전체",
          type: "",
          alias: "",
        },
      ];
    } else if (StringUtils.equalsIgnoreCase(erdData.erdType, "multi")) {
      for (const ca of erdData.areaList) {
        if (StringUtils.isEmpty(ca.name)) {
          return warnMsg("카테고리 명이 비어 있는 항목이 있습니다.");
        }
      }
    }

    if (props.callback) {
      props.callback(erdData);
      Popup.close();
    }
  };

  /**
   * 카테고리 목록 체인지 이벤트
   * @param {*} e
   * @param {*} index
   */
  const onChangeCategoty = (e, index) => {
    setErdInfo(
      produce(erdInfo, (draft) => {
        draft.areaList[index][e.target.id] = e.target.value;
      })
    );
  };

  /**
   * 컬럼추가
   * @param {*} e
   */
  const addColumn = (e) => {
    const index = erdInfo.areaList.length;
    setErdInfo(
      produce(erdInfo, (draft) => {
        draft.areaList.push({
          compId: `_${index}${StringUtils.getUuid()}`,
          name: "",
          type: "",
          alias: "",
        });
      })
    );
  };

  const onChangeErdType = (flag, e) => {
    if (erdInfo.areaList.length === 0) {
      setErdInfo({
        ...erdInfo,
        erdType: flag ? "multi" : "single",
        areaList:
          erdInfo.areaList.length === 0 && flag
            ? [
                {
                  compId: `_0${StringUtils.getUuid()}`,
                  name: "",
                  type: "",
                  alias: "",
                },
              ]
            : [],
      });
    } else {
      setErdInfo({
        ...erdInfo,
        erdType: flag ? "multi" : "single",
      });
    }
  };

  return (
    <Modal>
      <Modal.Header title="신규 ERD 생성" />
      <Modal.Body>
        <UModalTemplate>
          <Form.Group>
            <Form.Label className="required ">ERD ID</Form.Label>
            <Form.Control
              className="mb-3"
              id={"erdId"}
              value={erdInfo.erdId}
              onChange={onChangeValue}
            />
            <Form.Label className="required ">ERD 명</Form.Label>
            <Form.Control
              className="mb-3"
              id={"erdName"}
              value={erdInfo.erdName}
              onChange={onChangeValue}
            />
            <Row className="mb-3">
              <Col style={{ display: "flex", justifyContent: "space-between" }}>
                <Form.Label>테이블 영역 유형</Form.Label>
                <BootstrapSwitchButton
                  checked={erdInfo.erdType === "multi" ? true : false}
                  size="sm"
                  onstyle="primary"
                  offstyle="dark"
                  offlabel="Single 영역"
                  onlabel="Multi 영역"
                  width={120}
                  onChange={onChangeErdType}
                />
              </Col>
            </Row>
            {erdInfo.erdType === "multi" && (
              <>
                <Form.Label>TABLE 영역 목록</Form.Label>
                <div className="erd-table-column-grid">
                  <div
                    className="mb-1"
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Button size="sm" onClick={addColumn}>
                      영역 추가
                    </Button>
                  </div>

                  <div className="header">
                    <Row className="mb-1 ">
                      <Col className="cell" xs={4}>
                        영역 명
                      </Col>
                      <Col className="cell" xs={3}>
                        분류 유형
                      </Col>
                      <Col className="cell" xs={3}>
                        분류 기준
                      </Col>
                      <Col className="cell" xs={2}></Col>
                    </Row>
                  </div>
                  <div className="body">
                    {erdInfo.areaList.map((area, index) => {
                      return (
                        <Row key={index} className="mb-1">
                          <Col className="cell" xs={4}>
                            <Form.Control
                              id="name"
                              placeholder="영역 명 입력"
                              value={area.name}
                              onChange={(e) => onChangeCategoty(e, index)}
                            />
                          </Col>
                          <Col className="cell" xs={3}>
                            <Form.Select
                              id="type"
                              value={area.type}
                              onChange={(e) => onChangeCategoty(e, index)}
                            >
                              <option value={""}>선택</option>
                              <option value={"BE"}>prefix</option>
                              <option value={"ED"}>suffix</option>
                              <option value={"CT"}>포함</option>
                            </Form.Select>
                          </Col>
                          <Col className="cell" xs={3}>
                            <Form.Control
                              id="alias"
                              onChange={(e) => onChangeCategoty(e, index)}
                              value={area.alias}
                              placeholder="분류 기준 입력"
                            />
                          </Col>
                          <Col className="cell center" xs={2}>
                            <Button size="sm" variant="outline-danger">
                              <MdDelete />
                            </Button>
                          </Col>
                        </Row>
                      );
                    })}
                  </div>
                </div>
              </>
            )}

            <Form.Label>ERD 설명</Form.Label>
            <Form.Control
              className="mb-3"
              as="textarea"
              id={"description"}
              value={erdInfo.description}
              onChange={onChangeValue}
              rows={5}
            />
          </Form.Group>
        </UModalTemplate>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Button onClick={onClickConfirm}>확인</Modal.Footer.Button>
      </Modal.Footer>
    </Modal>
  );
}

export default NewErdPopup;
