import { Tooltip } from "@mui/material";
import { Enums } from "components/builder/BuilderEnum";
import ObjectUtils from "components/common/utils/ObjectUtils";
import StringUtils from "components/common/utils/StringUtils";
import {
  UserShortKey,
  shortKeyBind,
} from "components/setting/section/shortKey/ShortKeyConfig";
import React, { Component } from "react";
import { ButtonGroup, ToggleButton } from "react-bootstrap";
import { BiCut, BiPaste } from "react-icons/bi";
import { BsCodeSlash, BsPencilSquare } from "react-icons/bs";
import { FaRedo, FaUndo } from "react-icons/fa";
import { RiFileCopyLine } from "react-icons/ri";

class CommandButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabType: "E",
      rightSideData: null,
    };
    this.undoRef = React.createRef();
    this.redoRef = React.createRef();
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.onClickPaste = this.onClickPaste.bind(this);
    this.onPaste = this.onPaste.bind(this);
    this.onClickUndo = this.onClickUndo.bind(this);
    this.onClickCopy = this.onClickCopy.bind(this);
    this.onCopy = this.onCopy.bind(this);
    this.onClickRedo = this.onClickRedo.bind(this);
    this.onClickCut = this.onClickCut.bind(this);
    this.onCut = this.onCut.bind(this);
    this.renderUndoRedo = this.renderUndoRedo.bind(this);
    this.renderCutCopyPaste = this.renderCutCopyPaste.bind(this);
    this.renderComponent = this.renderComponent.bind(this);
    this.renderTabButton = this.renderTabButton.bind(this);
    this.renderDataNm = this.renderDataNm.bind(this);
    this.onChangeTabType = this.onChangeTabType.bind(this);
    this.undo = this.undo.bind(this);
    this.redo = this.redo.bind(this);
    this.setRightSideData = this.setRightSideData.bind(this);
    this.renderAdditionalButton = this.renderAdditionalButton.bind(this);
  }

  componentDidMount() {
    window.addEventListener("keyup", this.handleKeyUp);
  }
  componentWillUnmount() {
    if (this.props.commandInit) this.props.commandInit();
    window.removeEventListener("keyup", this.handleKeyUp);
  }

  /**
   * 뒤로가기 클릭 이벤트
   * 상속 받아서 사용시 undo만 수정
   */
  onClickUndo() {
    if (
      this.props.command &&
      this.props.command.prev.length + this.props.command.undoIndex > -1
    ) {
      this.undo();
    }
  }

  /**
   * 뒤로가기 이벤트
   */
  undo() {}

  /**
   * 앞으로 가기 클릭이벤트
   * 상속 받아서 사용시 redo만 수정
   */
  onClickRedo() {
    if (this.props.command && this.props.command.undoIndex < -1) {
      this.redo();
    }
  }

  redo() {}

  /**
   * 잘라내기 클릭 이벤트
   */
  onClickCut(e) {
    this.onCut(e);
  }

  /**
   * 잘라내기 이벤트
   */
  onCut() {}

  /**
   * 복사하기 클릭 이벤트
   */
  onClickCopy(e) {
    this.onCopy(e);
  }

  /**
   * 복사하기 이벤트
   */
  onCopy() {}

  /**
   * 붙여넣기 클릭 이벤트
   */
  onClickPaste() {
    this.onPaste();
  }

  /**
   * 붙여넣기
   */
  onPaste() {}

  handleKeyUp(e) {
    shortKeyBind(e, Enums.ShortKeyDefine.UNDO, () =>
      this.undoRef.current.click()
    );
    shortKeyBind(e, Enums.ShortKeyDefine.REDO, () =>
      this.redoRef.current.click()
    );
    shortKeyBind(e, Enums.ShortKeyDefine.CUT, this.onClickCut);
    shortKeyBind(e, Enums.ShortKeyDefine.COPY, this.onClickCopy);
    shortKeyBind(e, Enums.ShortKeyDefine.PASTE, this.onClickPaste);
  }

  onChangeTabType(e) {
    this.setState({
      tabType: e.currentTarget.value,
    });
    if (this.props.setTabType) {
      this.props.setTabType(e.currentTarget.value);
    }
  }

  renderTabButton() {
    return (
      <ButtonGroup>
        <ToggleButton
          key={1}
          id="editorTab1"
          name="editorTab1"
          type="radio"
          variant="outline-dark"
          value="E"
          size="sm"
          checked={StringUtils.equalsIgnoreCase(this.state.tabType, "E")}
          onChange={this.onChangeTabType}
          style={{ fontSize: "smaller" }}
        >
          <BsPencilSquare size="14" /> Editor
        </ToggleButton>
        <ToggleButton
          key={2}
          id="editorTab2"
          name="editorTab2"
          type="radio"
          variant="outline-dark"
          value="S"
          size="sm"
          checked={StringUtils.equalsIgnoreCase(this.state.tabType, "S")}
          onChange={this.onChangeTabType}
          style={{ fontSize: "smaller" }}
        >
          <BsCodeSlash size="14" /> Code
        </ToggleButton>
      </ButtonGroup>
    );
  }

  renderUndoRedo() {
    return (
      <>
        <div className="edit-button-group">
          <Tooltip
            title={`실행 취소 (${UserShortKey(Enums.ShortKeyDefine.UNDO)})`}
          >
            <div
              key={"undo"}
              id="undo"
              name="undo"
              onClick={this.onClickUndo}
              disabled={
                this.props.command.prev.length + this.props.command.undoIndex >
                -1
              }
              className={
                this.props.command.prev.length + this.props.command.undoIndex >
                -1
                  ? "able"
                  : "disabled"
              }
              ref={this.undoRef}
            >
              <FaUndo />
            </div>
          </Tooltip>
          <Tooltip
            title={`다시 실행 (${UserShortKey(Enums.ShortKeyDefine.REDO)})`}
          >
            <div
              key={"redo"}
              id="redo"
              name="redo"
              onClick={this.onClickRedo}
              disabled={this.props.command.undoIndex < -1}
              className={
                this.props.command.undoIndex < -1 ? "able" : "disabled"
              }
              ref={this.redoRef}
            >
              <FaRedo />
            </div>
          </Tooltip>
        </div>
      </>
    );
  }

  renderCutCopyPaste() {
    return (
      <div className="edit-button-group">
        <Tooltip title={`잘라내기 (${UserShortKey(Enums.ShortKeyDefine.CUT)})`}>
          <div
            key={"cut"}
            id="cut"
            name="cut"
            variant="outline-dark"
            size="sm"
            onClick={this.onClickCut}
          >
            <BiCut size={14} />
          </div>
        </Tooltip>
        <Tooltip title={`복사 (${UserShortKey(Enums.ShortKeyDefine.COPY)})`}>
          <div
            key={"copy"}
            id="copy"
            name="copy"
            variant="outline-dark"
            size="sm"
            onClick={this.onClickCopy}
          >
            <RiFileCopyLine size={14} />
          </div>
        </Tooltip>
        <Tooltip
          title={`붙여 넣기 (${UserShortKey(Enums.ShortKeyDefine.PASTE)})`}
        >
          <div
            key={"paste"}
            id="paste"
            name="paste"
            size="sm"
            onClick={
              ObjectUtils.isEmpty(this.props.command.clipboard)
                ? () => {}
                : this.onClickPaste
            }
            disabled={ObjectUtils.isEmpty(this.props.command.clipboard)}
            className={
              ObjectUtils.isEmpty(this.props.command.clipboard)
                ? "disabled"
                : "able"
            }
          >
            <BiPaste size={14} />
          </div>
        </Tooltip>
      </div>
    );
  }

  /**
   * 오른쪽
   * @returns
   */
  renderDataNm() {
    const component = this.setRightSideData();
    if (component) {
      return <div className="program-id-label">{component}</div>;
    } else {
      return <></>;
    }
  }

  /**
   * 오른쪽 데이터 내용 입력
   * @param {*} dataComponent
   * @returns
   */
  setRightSideData(dataComponent) {
    return dataComponent;
  }

  renderAdditionalButton() {}

  renderComponent() {
    return (
      <div className="edit-tab">
        <div className="left side">
          {this.renderTabButton()}
          {this.renderUndoRedo()}
          {this.renderCutCopyPaste()}
          {this.renderAdditionalButton()}
        </div>
        <div className="right side">{this.renderDataNm()}</div>
      </div>
    );
  }

  render() {
    return this.renderComponent();
  }
}

export default CommandButton;
