import React, { useRef } from "react";

import { useDrag } from "react-dnd";
import produce from "immer";
import { useSelector, useDispatch } from "react-redux";
import { useContext } from "react";
import { AppContext } from "components/common/AppContextProvider";
import * as actions from "components/builder/ui/reducers/UIBuilderAction";
import UIComponentSection from "components/builder/ui/editor/UIComponentSection";
import * as Event from "components/builder/ui/editor/handler/UIEditorEventHandler";
import AMChart3Component from "components/builder/ui/uiComponents/widget/chart/AMChart3Component";
import { ObjectUtils } from "components/common/utils/CommonUtils";
import { generateNodeTreeIds } from "components/builder/ui/tree/treeUtils";

const UIEditorAMChart3 = ({ data, path, rootLocation, style }) => {
  const ref = useRef(null);
  const activedComponent = useSelector(
    (state) => state.activedUIComponent.component
  );
  const components = useContext(AppContext).component.getComponentList("B");
  const output = useSelector((state) => state.outputUI.output);
  const dispatch = useDispatch();

  const item = produce(data, (draft) => {
    draft.path = path;
    draft.rootLocation = rootLocation;
  });
  const [{ isDragging }, drag] = useDrag({
    item: item,
    type: item.type,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(ref);

  const editorAttr = ObjectUtils.isEmpty(item.editorAttr)
    ? {}
    : item.editorAttr;

  /**
   * Onclick event
   * @param {Event} e
   * @returns
   */
  const onClick = (e) => {
    const nodeIds = generateNodeTreeIds(output.page, item.compId);
    dispatch(actions.setBuilderTreeNodeIds(nodeIds));
    Event.clickComponent(e, item, components, activedComponent, dispatch);
  };

  return (
    <div className="editor-component-wrap" style={{ ...style }} ref={ref}>
      <UIComponentSection
        item={item}
        style={{ opacity }}
        className="editor-component editor-base draggable"
        focusDelegation={true}
        title={editorAttr.title}
      >
        <AMChart3Component
          componentInfo={item}
          event="renderEditor"
          isFocus={activedComponent.compId === item.compId ? true : false}
          onClick={onClick}
        ></AMChart3Component>
      </UIComponentSection>
    </div>
  );
};

export default React.memo(UIEditorAMChart3);
