import { json } from "@codemirror/lang-json";
import CodeMirror from "@uiw/react-codemirror";
import { Enums } from "components/builder/BuilderEnum";
import Message from "components/common/Message";
import Modal from "components/common/modal/UModal";
import Popup from "components/common/Popup";
import React from "react";
import { useState } from "react";
import CodeMirrorWrapper from "components/common/element/CodeMirrorWrapper";

function CodeMirrorPopup({ title, onOk, fieldOption, ...props }) {
  const [code, setCode] = useState(JSON.stringify(fieldOption, null, 2));

  const onClickSave = (e) => {
    e.preventDefault();
    if (onOk) {
      try {
        const fo = JSON.parse(code);
        onOk(fo);
      } catch (error) {
        return Message.alert(
          "유효하지 않은 옵션입니다. 내용을 확인해주세요",
          Enums.MessageType.INFO
        );
      }
    }
    Popup.close();
  };

  return (
    <Modal>
      <Modal.Header title={title} />
      <Modal.Body>
        <CodeMirrorWrapper>
          <CodeMirror
            value={code}
            className="source-container"
            height="100%"
            minHeight="150px"
            extensions={[json(true)]}
            autoFocus={false}
            onChange={(value, viewUpdate) => {
              setCode(value);
            }}
          />
        </CodeMirrorWrapper>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Button onClick={onClickSave}>저장</Modal.Footer.Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CodeMirrorPopup;
