import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Tab,
  Tabs,
} from "@mui/material";
import { Enums } from "components/builder/BuilderEnum";
import Message from "components/common/Message";
import Modal from "components/common/modal/UModal";
import UModalTemplate from "components/common/modal/UModalTemplate";
import StringUtils from "components/common/utils/StringUtils";
import React, { Component, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import ProgramService from "services/ui/ProgramService";
import SearchService from "services/common/SearchService";

export const ConsoleTabKey = {
  PROGRAM: "P",
  PROGRAM_HISTORY: "PH",
  DATA_MODEL: "DM",
  ENTITY: "E",
};

function TotalSearchPopup({ ...props }) {
  const [currentTabKey, setCurrentTabKey] = useState(
    props.searchConsole.searchType || ConsoleTabKey.PROGRAM
  );

  const onHandleChange = (e, value) => {
    setCurrentTabKey(value);
  };
  return (
    <Modal>
      <Modal.Header title="통합 검색" />
      <Modal.Body>
        <UModalTemplate>
          <Tabs value={currentTabKey} onChange={onHandleChange}>
            <Tab
              label={"프로그램"}
              value={ConsoleTabKey.PROGRAM}
              style={{ fontSize: "larger", fontWeight: "bold" }}
            />
            <Tab
              label={"프로그램 이력"}
              value={ConsoleTabKey.PROGRAM_HISTORY}
              style={{ fontSize: "larger", fontWeight: "bold" }}
            />
            <Tab
              label={"데이터 모델"}
              value={ConsoleTabKey.DATA_MODEL}
              style={{ fontSize: "larger", fontWeight: "bold" }}
            />
            <Tab
              label={"엔티티"}
              value={ConsoleTabKey.ENTITY}
              style={{ fontSize: "larger", fontWeight: "bold" }}
            />
          </Tabs>
          {/* 프로그램 */}
          <TabPanel value={currentTabKey} tabKey={ConsoleTabKey.PROGRAM}>
            <ProgramSearch searchType={ConsoleTabKey.PROGRAM} {...props} />
          </TabPanel>
          {/* 프로그램 이력 */}
          <TabPanel
            value={currentTabKey}
            tabKey={ConsoleTabKey.PROGRAM_HISTORY}
          >
            <ProgramHistorySearch
              searchType={ConsoleTabKey.PROGRAM_HISTORY}
              {...props}
            />
          </TabPanel>
          {/* 데이터 모델 */}
          <TabPanel value={currentTabKey} tabKey={ConsoleTabKey.DATA_MODEL}>
            <DataModelSearch searchType={ConsoleTabKey.DATA_MODEL} {...props} />
          </TabPanel>
          {/* 엔티티 */}
          <TabPanel value={currentTabKey} tabKey={ConsoleTabKey.ENTITY}>
            <DataModelEntitySearch
              searchType={ConsoleTabKey.ENTITY}
              {...props}
            />
          </TabPanel>
        </UModalTemplate>
      </Modal.Body>
      <Modal.Footer />
    </Modal>
  );
}

export default TotalSearchPopup;

function TabPanel(props) {
  const { children, value, tabKey, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== tabKey}
      id={`simple-tabpanel-${tabKey}`}
      aria-labelledby={`simple-tab-${tabKey}`}
      {...other}
      style={{
        border: "1px solid lightgray",
        borderRadius: "5px",
        padding: "15px",
        marginTop: "15px",
      }}
    >
      <Grid container>
        <Grid item xs={12}>
          <Form.Label className="required">검색 항목 선택</Form.Label>
        </Grid>
        {props.children}
      </Grid>
    </div>
  );
}

class SearchTab extends Component {
  constructor(props) {
    super(props);
    this.onSearch = this.onSearch.bind(this);
    this.enterSearch = this.enterSearch.bind(this);
    this.onChangeCheckBox = this.onChangeCheckBox.bind(this);
    this.state = {
      searchTerm: this.props.searchTerm || "",
    };
  }

  onSearch() {
    if (Object.values(this.state).filter((o) => o === "Y").length === 0) {
      return Message.alert(
        "조건이 선택되지 않았습니다.",
        Enums.MessageType.INFO
      );
    }
    if (StringUtils.isEmpty(this.state.searchTerm)) {
      return Message.alert("검색어를 입력해주세요.", Enums.MessageType.INFO);
    }

    this.dataSearch();
  }

  dataSearch() {}

  setList(list) {
    /**
     * 검색 조건 뽑아내기
     * 기본 조건은 전부 Y 이기 때문에 , 검색하지 않는 것만 뽑아내서 따로 리덕스 저장
     * */
    const optionObjects = {};
    for (const key of Object.keys(this.state)) {
      if (this.state[key] === "N" && key !== "searchTerm") {
        optionObjects[key] = this.state[key];
      }
    }

    this.props.setTotalSearchList(
      this.props.searchType,
      list,
      this.state.searchTerm,
      optionObjects
    );
  }

  onChangeCheckBox(e) {
    this.setState({
      ...this.state,
      [e.target.id]: e.target.checked ? "Y" : "N",
    });
  }
  renderCheckBox(_state, label) {
    return (
      <FormControlLabel
        control={
          <Checkbox
            id={_state}
            checked={this.state[_state] === "Y" ? true : false}
            onChange={this.onChangeCheckBox}
          />
        }
        label={label}
      />
    );
  }
  enterSearch(e) {
    if (e.keyCode === 13) {
      this.onSearch();
    }
  }

  renderSearchBar() {
    return (
      <Grid item xs={12}>
        <Form.Group>
          <Form.Label className="required">검색어 입력</Form.Label>
          <InputGroup>
            <Form.Control
              size="sm"
              placeholder="검색어를 입력해주세요."
              value={this.state.searchTerm || ""}
              onChange={(e) =>
                this.setState({
                  ...this.state,
                  searchTerm: e.target.value,
                })
              }
              onKeyDown={this.enterSearch}
            />
            {this.state.isLoading ? (
              <Button disabled={true}>
                <CircularProgress color="inherit" size={13} /> 검색 중
              </Button>
            ) : (
              <Button onClick={this.onSearch}>검색</Button>
            )}
          </InputGroup>
        </Form.Group>
      </Grid>
    );
  }

  render() {
    return <></>;
  }
}

class ProgramSearch extends SearchTab {
  constructor(props) {
    super(props);
    this.state = {
      programNm: "Y",
      description: "Y",
      programId: "Y",
      dataModelNm: "Y",
      logEntityNm: "Y",
      physEntityNm: "Y",
      ...this.props.workspace,
      searchTerm: this.props.searchConsole.searchTerm || "",
      ...this.props.searchConsole.searchOptions,
    };
  }

  dataSearch() {
    this.setState({
      ...this.state,
      isLoading: true,
    });
    const body = {
      ...this.state,
    };
    SearchService.getProgramSearch(
      body,
      (res) => {
        const filteredList = ProgramService.filterByTenantIdAndCoCd(
          res.data,
          this.props.workspace.tenantId,
          this.props.workspace.coCd,
          "programId"
        );
        this.setList(filteredList);
      },
      () => {
        this.setState({
          ...this.state,
          isLoading: false,
        });
      }
    );
  }

  render() {
    return (
      <>
        <Grid item xs={4}>
          {this.renderCheckBox("programNm", "프로그램 명")}
        </Grid>
        <Grid item xs={4}>
          {this.renderCheckBox("programId", "프로그램 ID")}
        </Grid>
        <Grid item xs={4}>
          {this.renderCheckBox("description", "프로그램 설명")}
        </Grid>
        <Grid item xs={4}>
          {this.renderCheckBox("dataModelNm", "데이터 모델 명")}
        </Grid>
        <Grid item xs={4}>
          {this.renderCheckBox("logEntityNm", "엔티티 논리 명")}
        </Grid>
        <Grid item xs={4}>
          {this.renderCheckBox("physEntityNm", "엔티티 물리 명")}
        </Grid>
        {this.renderSearchBar()}
      </>
    );
  }
}

class ProgramHistorySearch extends SearchTab {
  constructor(props) {
    super(props);

    this.state = {
      programNm: "Y",
      programId: "Y",
      programDescription: "Y",
      commitComment: "Y",
      ...this.props.workspace,
      searchTerm: this.props.searchConsole.searchTerm || "",
      ...this.props.searchConsole.searchOptions,
      isLoading: false,
    };
  }

  dataSearch() {
    this.setState({
      ...this.state,
      isLoading: true,
    });
    const body = {
      ...this.state,
    };
    SearchService.getProgramHistorySearch(
      body,
      (res) => {
        this.setList(res.data);
      },
      () => {
        this.setState({
          ...this.state,
          isLoading: false,
        });
      }
    );
  }

  render() {
    return (
      <>
        <Grid item xs={4}>
          {this.renderCheckBox("programNm", "프로그램 명")}
        </Grid>
        <Grid item xs={4}>
          {this.renderCheckBox("programId", "프로그램 ID")}
        </Grid>
        <Grid item xs={4}>
          {this.renderCheckBox("programDescription", "프로그램 설명")}
        </Grid>
        <Grid item xs={4}>
          {this.renderCheckBox("commitComment", "배포 코멘트")}
        </Grid>
        {this.renderSearchBar()}
      </>
    );
  }
}

class DataModelSearch extends SearchTab {
  constructor(props) {
    super(props);

    this.state = {
      dataModelNm: "Y",
      description: "Y",
      transactionNm: "Y",
      procedureNm: "Y",
      usrApiUrl: "Y",
      ...this.props.workspace,
      searchTerm: this.props.searchConsole.searchTerm || "",
      ...this.props.searchConsole.searchOptions,
    };
  }
  dataSearch() {
    this.setState({
      ...this.state,
      isLoading: true,
    });
    const body = {
      ...this.state,
    };

    SearchService.getDataModelSearch(
      body,
      (res) => {
        const list = ProgramService.filterByTenantIdAndCoCd(
          res.data,
          this.props.workspace.tenantId,
          this.props.workspace.coCd,
          "dataModelNm"
        );
        this.setList(list);
      },
      () => {
        this.setState({
          ...this.state,
          isLoading: false,
        });
      }
    );
  }

  render() {
    return (
      <>
        <Grid item xs={4}>
          {this.renderCheckBox("dataModelNm", "데이터 모델 명")}
        </Grid>
        <Grid item xs={4}>
          {this.renderCheckBox("description", "데이터 모델 설명")}
        </Grid>
        <Grid item xs={4}>
          {this.renderCheckBox("transactionNm", "트랜잭션 명")}
        </Grid>
        <Grid item xs={4}>
          {this.renderCheckBox("procedureNm", "프로시저 명")}
        </Grid>
        <Grid item xs={4}>
          {this.renderCheckBox("usrApiUrl", "API 주소")}
        </Grid>
        {this.renderSearchBar()}
      </>
    );
  }
}
class DataModelEntitySearch extends SearchTab {
  constructor(props) {
    super(props);
    this.state = {
      dataModelNm: "Y",
      physEntityNm: "Y",
      logEntityNm: "Y",
      tableNm: "Y",
      ...this.props.workspace,
      searchTerm: this.props.searchConsole.searchTerm || "",
      ...this.props.searchConsole.searchOptions,
    };
  }

  dataSearch() {
    this.setState({
      ...this.state,
      isLoading: true,
    });
    const body = {
      ...this.state,
    };

    SearchService.getEntitySearch(
      body,
      (res) => this.setList(res.data),
      () => {
        this.setState({
          ...this.state,
          isLoading: true,
        });
      }
    );
  }

  render() {
    return (
      <>
        <Grid item xs={4}>
          {this.renderCheckBox("dataModelNm", "데이터 모델 명")}
        </Grid>
        <Grid item xs={4}>
          {this.renderCheckBox("physEntityNm", "엔티티 물리 명")}
        </Grid>
        <Grid item xs={4}>
          {this.renderCheckBox("logEntityNm", "엔티티 논리 명")}
        </Grid>
        <Grid item xs={4}>
          {this.renderCheckBox("tableNm", "테이블 명")}
        </Grid>
        {this.renderSearchBar()}
      </>
    );
  }
}
