import {
  stackRedo,
  stackUndo,
} from "components/builder/ui/reducers/CommandAction";
import { updateErd } from "../../reducers/ErdAction";
import produce from "immer";
import { Enums } from "components/builder/BuilderEnum";
import ObjectUtils from "components/common/utils/ObjectUtils";
import StringUtils from "components/common/utils/StringUtils";

class ErdReduxHelper {
  /**
   * ERD를 통으로 업데이트
   * @param {*} dispatch
   * @param {*} erd
   */
  static _updateNode(dispatch, erd) {
    dispatch(updateErd(erd));
    dispatch(stackRedo(erd));
  }

  /**
   * 메모 추가
   * @param {*} dispatch
   * @param {*} data
   * @param {*} prevErd
   */
  static addMemo(dispatch, data, prevErd) {
    dispatch(stackUndo(prevErd));
    const newErd = produce(prevErd, (draft) => {
      if (!draft.output.areas[prevErd.activatedErd.compId].memo)
        draft.output.areas[prevErd.activatedErd.compId].memo = [];
      draft.output.areas[prevErd.activatedErd.compId].memo.push(data);
    });
    this._updateNode(dispatch, newErd);
  }

  /**
   * ERD에 테이블 추가하는 로직
   * @param {*} dispatch
   * @param {*} data
   * @param {*} prevErd
   * @param {*} areaId
   */
  static addErdTable(dispatch, data, prevErd, areaId) {
    dispatch(stackUndo(prevErd));

    const newErd = produce(prevErd, (draft) => {
      draft.output.areas[areaId].table.push(data);
    });
    this._updateNode(dispatch, newErd);
  }

  /**
   * 노드(테이블) 정보 업데이트
   * @param {Function} dispatch
   * @param {Array} data
   * @param {Object} prevErd
   * @param {String} areaId
   */
  static updateNodes(dispatch, data, prevErd) {
    dispatch(stackUndo(prevErd));
    const nodeIdList = data.map((n) => n.compId);
    const erd = { ...prevErd };

    const areaId = erd.activatedErd.compId;

    /**
     * compId를 검색해서 원하는 노드를 수정
     * @param {*} object
     * @returns
     */
    const findObj = (object) => {
      if (object && typeof object === "object") {
        if (object["compId"] && nodeIdList.indexOf(object["compId"]) > -1) {
          const node = data[nodeIdList.indexOf(object["compId"])];
          if (node) {
            Object.keys(node).map((key) => {
              if (key !== "compId" && node[key]) {
                object[key] = node[key];
              } else if (object[key] && !node[key]) {
                delete object[key];
              }
            });
          }
          if (object.child) {
            object.child = findObj(object.child);
          }
        } else {
          for (const k in object) {
            object[k] = findObj(object[k]);
          }
        }
        return object;
      } else {
        return object;
      }
    };

    /**
     * 해당 카테고리 내에서만 데이터 업데이트
     */
    erd.output = produce(erd.output, (draft) => {
      if (areaId) {
        draft.areas[areaId] = findObj(draft.areas[areaId]);
      } else {
        draft.areas = findObj(draft.areas);
      }
    });
    this._updateNode(dispatch, erd);
  }

  /**
   * ERD에서 테이블 삭제
   * @param {*} dispatch
   * @param {*} targetTable
   * @param {*} prevErd
   */
  static deleteTable = (dispatch, targetTable, prevErd) => {
    dispatch(stackUndo(prevErd));
    const erd = { ...prevErd };
    const {
      activatedErd: { compId: activatedCompId },
    } = erd;
    const { compId: targetCompId } = targetTable;
    // 활동영역에서 테이블 삭제
    const newErd = produce(erd, (draft) => {
      const targetIndex = draft.output.areas[activatedCompId].table.findIndex(
        (t) => t.compId === targetCompId
      );
      if (targetIndex > -1) {
        draft.output.areas[activatedCompId].table.splice(targetIndex, 1);
      }
      //해당 테이블을 타겟으로 하는 릴레이션

      for (const table of draft.output.areas[activatedCompId].table) {
        const { relation } = table;
        if (relation) {
          const relIndex = relation.findIndex(
            (r) => r.propertyValue.physicalTableNm === targetCompId
          );
          if (relIndex > -1) {
            relation.splice(relIndex, 1);
          }
        }
      }
    });

    this._updateNode(dispatch, newErd);
  };

  /**
   * 커넥터 추가 이벤트
   * @param {*} dispatch
   * @param {*} data
   * @param {*} prevErd
   * @param {*} areaId
   */
  static addConnector = (dispatch, data, prevErd, areaId) => {
    dispatch(stackUndo(prevErd));
    const erd = { ...prevErd };

    const newErd = produce(erd, (draft) => {
      for (const d of data) {
        if (d.type === Enums.ErdType.CONNECTOR) {
          draft.output.areas[areaId].connector.push(d);
        } else {
          const tIndex = erd.output.areas[areaId].table.findIndex(
            (t) => t.compId === d.compId
          );
          if (tIndex > -1) {
            draft.output.areas[areaId].table[tIndex] = d;
          }
        }
      }
    });

    this._updateNode(dispatch, newErd);
  };

  /**
   * 관계 삭제 로직
   * @param {*} dispatch
   * @param {*} compId
   * @param {*} predErd
   */
  static deleteConnector = (dispatch, connector, prevErd) => {
    dispatch(stackUndo(prevErd));
    const erd = { ...prevErd };
    const { compId: activatedCompId } = erd.activatedErd;
    const { from, to, compId } = connector;
    const newErd = produce(erd, (draft) => {
      //커넥터 삭제
      const conIndex = erd.output.areas[activatedCompId].connector.findIndex(
        (t) => t.compId === compId
      );
      if (conIndex > -1) {
        draft.output.areas[activatedCompId].connector.splice(conIndex, 1);
      }
    });
    this._updateNode(dispatch, newErd);
  };

  /**
   * 코드미러에서 수정되는 경우
   * @param {*} dispatch
   * @param {*} code
   * @param {*} prevErd
   */
  static updateErd = (dispatch, code, prevErd) => {
    dispatch(stackUndo(prevErd));
    const activatedCompId = prevErd.activatedErd.compId;
    let newErd = { ...prevErd };
    if (activatedCompId) {
      newErd = produce(prevErd, (draft) => {
        draft.output.areas[activatedCompId] = code;
      });
    }

    this._updateNode(dispatch, newErd);
  };
}

export default ErdReduxHelper;
