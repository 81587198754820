import { Tab, Tabs } from "@mui/material";
import { Enums } from "components/builder/BuilderEnum";
import { stopEvent } from "components/builder/ui/editor/handler/UIEditorEventHandler";
import { getEntityPropertyValue } from "components/builder/workflow/editor/render/WorkflowRenderUtils";
import Message from "components/common/Message";
import MuiConfig from "components/common/config/MuiConfig";
import WijmoGrid from "components/common/element/WijmoGrid";
import Modal from "components/common/modal/UModal";
import UModalTemplate from "components/common/modal/UModalTemplate";
import StringUtils from "components/common/utils/StringUtils";
import produce from "immer";
import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { AiOutlineCheck } from "react-icons/ai";
import ComponentService from "services/datamodel/DataModelService";

const TabKey = {
  RESERVED: "reserved",
  ENTITY: "entity", //다른 노드에서 찾기
};

function ProcessDataBindingPopup(props) {
  const {
    callbackFnc,
    dateYn,
    workflow,
    nodes,
    edges,
    compId,
    fieldId,
    processType,
    exceptReserved = false,
    getAccessibleEntityList,
    showAll,
    ...others
  } = props;
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedTab, setSelectedTab] = useState(TabKey.ENTITY);

  const onTabChange = (e, value) => {
    setSelectedTab(value);
  };
  const onConfirm = (e) => {
    stopEvent(e);
    if (callbackFnc) {
      if (StringUtils.isEmpty(selectedValue))
        return Message.alert(
          "값을 선택 또는 입력해주세요.",
          Enums.MessageType.WARN
        );
      callbackFnc(selectedValue);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", onKeyEnterDown);

    return () => {
      document.removeEventListener("keydown", onKeyEnterDown);
    };
  }, [selectedValue]);

  const onKeyEnterDown = (e) => {
    if (e.key === "Enter") {
      onConfirm(e);
    }
  };

  return (
    <Modal>
      <Modal.Header title={"입력 값 선택"} />
      <Modal.Body>
        <UModalTemplate>
          <Tabs value={selectedTab} onChange={onTabChange}>
            <Tab
              label={"Entity"}
              value={TabKey.ENTITY}
              style={{ fontWeight: "bold", fontSize: "15px" }}
            />
            {!exceptReserved && (
              <Tab
                label={"예약어"}
                value={TabKey.RESERVED}
                style={{ fontWeight: "bold", fontSize: "15px" }}
              />
            )}
          </Tabs>
          {/* 예약어 */}
          <TabPanel value={selectedTab} tabKey={TabKey.RESERVED}>
            <ReservedWordTab
              callbackFnc={callbackFnc}
              setSelectedValue={setSelectedValue}
              dateYn={dateYn}
            />
          </TabPanel>
          <TabPanel value={selectedTab} tabKey={TabKey.ENTITY}>
            <EntitySelectTab
              value={selectedValue}
              setValue={setSelectedValue}
              {...props}
            />
          </TabPanel>
        </UModalTemplate>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Button onClick={onConfirm}>확인</Modal.Footer.Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ProcessDataBindingPopup;

function TabPanel(props) {
  const { children, value, tabKey, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== tabKey}
      id={`simple-tabpanel-${tabKey}`}
      aria-labelledby={`simple-tab-${tabKey}`}
      {...other}
      style={{
        marginTop: "5px",
      }}
    >
      {props.children}
    </div>
  );
}

const ReservedWordTab = ({ callbackFnc, setSelectedValue, ...props }) => {
  const column = [
    {
      field: "reservedWord",
      headerName: "예약어",
      align: "center",
      width: 150,
    },
    {
      field: "reservedWordNm",
      headerName: "예약어 명",
      width: 150,
    },
    {
      field: "description",
      headerName: "예약어 설명",
      width: "*",
    },
  ];

  const [defaultValueList, setDefaultValueList] = useState([]);

  useEffect(() => {
    ComponentService.getReservedWordList({}, (res) => {
      // let filtered = [];
      // //날짜 포함 여부
      // if (props.dateYn) {
      //   filtered = res.data.filter(
      //     (item) => item.reservedWord.indexOf("currentTime") > -1
      //   );
      // } else {
      //   filtered = res.data.filter(
      //     (item) => item.reservedWord.indexOf("currentTime") === -1
      //   );
      // }
      setDefaultValueList(res.data);
    });
  }, []);

  const onRowClick = (param, e) => {
    if (setSelectedValue) setSelectedValue(param.reservedWord);
  };

  const onRowDoubleClick = (param, e) => {
    if (callbackFnc) {
      callbackFnc(param.reservedWord);
    }
  };

  return (
    <div style={{ height: "500px" }}>
      <WijmoGrid
        columns={column}
        getRowId={(row) => row.reservedWordId}
        rows={defaultValueList}
        {...MuiConfig.grid.options}
        pageSize={10}
        //   onRowDoubleClick={onRowDoubleClick}
        onRowClick={onRowClick}
        onRowDoubleClick={onRowDoubleClick}
        rowsPerPageOptions={[10]}
        style={{ height: `calc(50vh + 20px)` }}
      />
    </div>
  );
};

const EntitySelectTab = ({
  workflow,
  nodes,
  edges,
  compId,
  value,
  setValue,
  callbackFnc,
  fieldId,
  processType,
  getAccessibleEntityList,
  showAll,
  ...props
}) => {
  const [entityList, setEntityList] = useState([]);
  const [selectedEntity, setSelectedEntity] = useState({
    compId: "requestParam",
    entityFieldList: [],
  });
  const [selectedField, setSelectedField] = useState({});
  const [isRequestParamInput, setisRequestParamInput] = useState(true);
  const [requestParamInput, setRequestParamInput] = useState("");

  useEffect(() => {
    setEntityList(getAccessibleEntityList(processType, false, showAll));
  }, []);

  const onClickRequestParam = () => {
    setSelectedEntity({
      compId: "requestParam",
    });
    setisRequestParamInput(true);
    setRequestParamInput("");
    setValue("");
  };

  const onChangeEntity = (_entity) => {
    if (
      StringUtils.includes(_entity.processType, [
        Enums.WorkflowProcessType.SELECT_ENTITY,
        Enums.WorkflowProcessType.SELECT_ENTITY_BY_QUERY,
      ])
    ) {
      //Select Entity가 단일 조회할때는 참조하는 CompId의 entityFieldList를 가져온다.
      //참조 아이디가 없으면 조회하지 않는다.
      _entity = produce(_entity, (draft) => {
        if (
          _entity.outputEntity.referenceCompId ||
          _entity.inputEntity.referenceCompId
        ) {
          const refNode = nodes.find(
            (n) =>
              n.id ===
              (_entity.outputEntity.referenceCompId ||
                _entity.inputEntity.referenceCompId)
          );
          draft.entityVariable = _entity.outputEntity.entityVariable;
          draft.entityFieldList =
            refNode.data.process.propertyValue.entityFieldList;
        }
      });
    } else if (
      StringUtils.includes(_entity.processType, [
        Enums.WorkflowNodeType.ITERATOR,
      ])
    ) {
      // 엔티티의 Variable이 선언된 def를 검색하고 해당 필드리스트 리턴
      let refNode = undefined;
      const compId = _entity.editorAttr?.referenceCompId;
      if (!StringUtils.isEmpty(compId)) {
        refNode = nodes.find((n) => n.id === compId);
      }
      _entity = produce(_entity, (draft) => {
        draft.entityVariable = _entity.entityVariable;

        console.log(refNode);
        if (
          refNode &&
          refNode.data.process.processType ===
            Enums.WorkflowProcessType.ENTITY_DEFINITION
        ) {
          draft.entityFieldList =
            refNode.data.process.propertyValue.entityFieldList;
        }
        // draft.entityFieldList = _entity.outputEntity.entityVariable;
      });

      // console.log(_entity);
      // draft.entityVariable = _entity.outputEntity.entityVariable;
      // draft.entityFieldList =
      //   refNode.data.process.propertyValue.entityFieldList;
    }
    setSelectedEntity(_entity);
    setisRequestParamInput(false);
    if (fieldId) {
      //fieldId가 있는 경우 엔티티 필드 목록내에 같은 ID를 가진 필드를 자동 선택한다.
      const entityFieldForFieldId = _entity.entityFieldList?.find(
        (f) => f.fieldId === fieldId
      );
      if (entityFieldForFieldId) {
        setSelectedField(entityFieldForFieldId);
        setValue(
          `${_entity.entityVariable}.get("${entityFieldForFieldId.fieldId}")`
        );
      } else {
        setSelectedField({});
      }
    } else {
      setSelectedField({});
      setValue(_entity.entityVariable);
    }
  };

  const onChangeField = (f) => {
    setSelectedField(f);
    if (
      StringUtils.equalsIgnoreCase(
        selectedEntity.processType,
        Enums.WorkflowProcessType.SELECT_ENTITY_BY_QUERY
      ) ||
      (StringUtils.equalsIgnoreCase(
        selectedEntity.processType,
        Enums.WorkflowProcessType.SELECT_ENTITY
      ) &&
        !(
          selectedEntity.maxDataCount === 1 ||
          selectedEntity.filterOption.primaryFilter
        ))
    ) {
      setValue(`${selectedEntity.entityVariable}.get(0).get("${f.fieldId}")`);
    } else {
      setValue(`${selectedEntity.entityVariable}.get("${f.fieldId}")`);
    }
  };

  const onChangeRequestParamInput = (e) => {
    setRequestParamInput(e.currentTarget.value);
    setValue(`requestParam.get("${e.currentTarget.value}")`);
  };

  const onDoubleClickColumn = (f) => {
    if (callbackFnc) {
      if (
        StringUtils.equalsIgnoreCase(
          selectedEntity.processType,
          Enums.WorkflowProcessType.SELECT_ENTITY_BY_QUERY
        ) ||
        (StringUtils.equalsIgnoreCase(
          selectedEntity.processType,
          Enums.WorkflowProcessType.SELECT_ENTITY
        ) &&
          !(
            selectedEntity.maxDataCount === 1 ||
            selectedEntity.filterOption.primaryFilter
          ))
      ) {
        callbackFnc(
          `${selectedEntity.entityVariable}.get(0).get("${f.fieldId}")`
        );
      } else {
        callbackFnc(`${selectedEntity.entityVariable}.get("${f.fieldId}")`);
      }
    }
  };

  /**
   * 엔티티만 선택하는 경우
   * @param {*} e
   */
  const onSelectEntity = (event, entity) => {
    stopEvent(event);
    if (callbackFnc) {
      callbackFnc(entity.entityVariable);
    }
  };

  return (
    <div
      className="data-model-entity-select-wrapper"
      style={{ marginTop: "25px" }}
    >
      <div className="data-model-entity-list-wrapper ">
        <div className="list data-binding" style={{ width: "350px" }}>
          <div className="header">
            <div>프로세스 명</div>
            <div>Entity 변수 명</div>
            <div>선택</div>
          </div>
          <div className="body">
            <div
              className={`dm-row ${
                StringUtils.equalsIgnoreType(
                  selectedEntity.compId,
                  "requestParam"
                )
                  ? "selected"
                  : ""
              }`}
              onClick={onClickRequestParam}
            >
              <div className="cell">HTTP 요청 파라미터</div>
              <div className="cell">requestParam</div>
              <div className="cell" />
            </div>
            {entityList.map((e) => {
              const name =
                e.propertyValue.processNm ||
                e.propertyValue.serviceName ||
                e.propertyValue.iteratorNm;
              const _entityList = getEntityPropertyValue(e);
              return _entityList.map((entity, idx) => {
                return (
                  <div
                    className={`dm-row ${
                      StringUtils.equalsIgnoreType(
                        selectedEntity.entityVariable,
                        entity.entityVariable
                      )
                        ? "selected"
                        : ""
                    }`}
                    onClick={(event) => onChangeEntity(entity)}
                    onDoubleClick={(event) => onSelectEntity(event, entity)}
                    key={entity.entityVariable}
                  >
                    <div className="cell">{name}</div>
                    <div className="cell">{entity.entityVariable}</div>
                    <div
                      className="cell"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        variant="outline-success"
                        size="sm"
                        onClick={(event) => onSelectEntity(event, entity)}
                      >
                        <AiOutlineCheck />
                      </Button>
                    </div>
                  </div>
                );
              });
            })}
          </div>
        </div>
        <div className="list" style={{ width: "350px" }}>
          {isRequestParamInput ? (
            <>
              <div className="header">
                <div>가져올 Field ID 입력</div>
              </div>
              <div className="body" style={{ padding: "0 3px" }}>
                <Form.Label>Field ID</Form.Label>
                <Form.Control
                  placeholder="Field ID 입력"
                  value={requestParamInput}
                  onChange={onChangeRequestParamInput}
                />
              </div>
            </>
          ) : (
            <>
              <div className="header">
                <div>Field ID</div>
                <div>칼럼 명</div>
              </div>
              <div className="body">
                {selectedEntity.entityFieldList ? (
                  selectedEntity.entityFieldList.map((f) => {
                    if (f.columnNm || f.fieldId)
                      return (
                        <div
                          className={`dm-row ${
                            StringUtils.equalsIgnoreType(
                              selectedField.fieldId,
                              f.fieldId
                            )
                              ? "selected"
                              : ""
                          }`}
                          id={f.fieldId}
                          onClick={(e) => onChangeField(f)}
                          onDoubleClick={(e) => onDoubleClickColumn(f)}
                          key={f.fieldId}
                        >
                          <div className="cell">{f.fieldId}</div>
                          <div className="cell">{f.columnNm}</div>
                        </div>
                      );
                  })
                ) : (
                  <div className="empty">
                    <div>선택된 엔티티가 목록형 엔티티 이거나</div>
                    <div>필드를 가져올 수 있는 형태가 아닙니다.</div>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
