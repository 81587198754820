import WijmoGrid, { onKeyDownEvent } from "components/common/element/WijmoGrid";
import Modal from "components/common/modal/UModal";
import UModalTemplate from "components/common/modal/UModalTemplate";
import StringUtils from "components/common/utils/StringUtils";
import { useEffect, useRef, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import WorkflowService from "services/workflow/WorkflowService";

const columns = [
  {
    field: "processId",
    headerName: "Process",
    width: "*",
  },
  {
    field: "processName",
    headerName: "Process 명",
    width: "1.5*",
  },
];
/**
 * 프로세스 목록을 보여주는 팝업
 * 워크플로우에서 사용합니다.
 * @param {*} param0
 * @returns
 */
function ProcessPopup({ callbackFnc, ...props }) {
  const processList = useRef([]);
  const [dpList, _setDpList] = useState([]);
  const [selectedProcess, _setSelectedProcess] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const searchTermRef = useRef();
  const selectedProcessRef = useRef({});
  const dpListRef = useRef([]);

  /**
   * 이동키 입력을 위한 항목
   * State는 표시용
   * Ref는 이동키 입력을 위한 부분
   * @param {*} data
   */
  const setSelectedProcess = (data) => {
    _setSelectedProcess(data);
    selectedProcessRef.current = data;
  };

  /**
   * 이동키 입력을 위한 항목
   * State는 표시용
   * Ref는 이동키 입력을 위한 부분
   * @param {*} data
   */
  const setDpList = (data) => {
    _setDpList(data);
    dpListRef.current = data;
  };

  useEffect(() => {
    WorkflowService.getProcessList({}, (res) => {
      if (res.data.length > 0) {
        setSelectedProcess(res.data[0]);
      }
      processList.current = res.data;
      setDpList(res.data);
    });
    if (searchTermRef.current) {
      searchTermRef.current.focus();
    }
    window.addEventListener("keydown", keyDown);
    return () => {
      window.removeEventListener("keydown", keyDown);
    };
  }, []);

  /**
   * 이동키 이벤트
   * @param {*} e
   * @returns
   */
  const keyDown = (e) =>
    onKeyDownEvent(
      e,
      selectedProcessRef,
      dpListRef,
      setSelectedProcess,
      onDoubleClickRow,
      (obj) => obj.processId
    );

  const onClickConfirm = () => {
    if (callbackFnc) {
      callbackFnc(selectedProcess);
    }
  };

  const onSelectRow = (param) => {
    setSelectedProcess(param);
  };

  const onDoubleClickRow = (param) => {
    if (callbackFnc) {
      callbackFnc(param);
    }
  };

  const onChangeSearchTerm = (e) => {
    const value = String(e.currentTarget.value).toLocaleLowerCase();
    setSearchTerm(value);
    if (StringUtils.isEmpty(value)) {
      setDpList(processList.current);
      setSelectedProcess(processList.current[0]);
    } else {
      const filtered = [...processList.current].filter(
        (f) =>
          String(f.processId).toLowerCase().includes(value) ||
          String(f.processName).toLowerCase().includes(value) ||
          StringUtils.changeCamelToSpace(f.processId)
            .toLowerCase()
            .includes(value) ||
          StringUtils.changeCamelToSpace(f.processName)
            .toLowerCase()
            .includes(value)
      );
      if (filtered.length > 0) setSelectedProcess(filtered[0]);
      setDpList(filtered);
    }
  };

  return (
    <Modal>
      <Modal.Header title={"Process 생성"} />
      <Modal.Body>
        <UModalTemplate className={"process-popup"}>
          <Row className="mb-3">
            <Col xs={12}>
              <Form.Label>프로세스 검색</Form.Label>
            </Col>
            <Col xs={12}>
              <Form.Control
                placeholder="검색어 입력"
                value={searchTerm}
                onChange={onChangeSearchTerm}
                ref={searchTermRef}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    onDoubleClickRow(dpList[0]);
                  }
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <WijmoGrid
                style={{ height: "60vh" }}
                columns={columns}
                rows={dpList}
                headersVisibility={"Column"}
                onRowClick={onSelectRow}
                onRowDoubleClick={onDoubleClickRow}
                selectedItem={selectedProcess}
                getId={(obj) => obj.processId}
              />
            </Col>
            <Col xs={6} className="process-detail">
              <div className="wrapper">
                <Row className="mb-3">
                  <Col xs={12}>
                    <Form.Label>Process Type</Form.Label>
                    <Form.Control
                      value={selectedProcess.processId || ""}
                      onChange={() => {}}
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col xs={12}>
                    <Form.Label>Process 명</Form.Label>
                    <Form.Control
                      value={selectedProcess.processName || ""}
                      onChange={() => {}}
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col xs={12}>
                    <Form.Label>Process 설명</Form.Label>
                    <div
                      className="process-desc"
                      dangerouslySetInnerHTML={{
                        __html: selectedProcess.processDesc,
                      }}
                    />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </UModalTemplate>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Button onClick={onClickConfirm}>선택</Modal.Footer.Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ProcessPopup;
