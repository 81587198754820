import React, { useRef } from "react";
import { useDrag } from "react-dnd";
import produce from "immer";

import UIEditorChildRender from "components/builder/ui/editor/UIEditorChildRender";
import * as Enums from "components/builder/BuilderEnum";
import UIEditorDNDContainer, {
  UIViewerDNDContainer,
} from "components/builder/ui/editor/UIEditorDNDContainer";
import {
  ArrayUtils,
  JsonUtils,
  StringUtils,
} from "components/common/utils/CommonUtils";
import TabContainer from "components/builder/ui/uiComponents/layout/TabContainer";

const style = {};
const UIEditorTab = (props) => {
  const { data, handleDrop, path, templateComponents, rootLocation } = props;
  const [activedTab, setActivedTab] = React.useState("");

  const ref = useRef(null);

  const item = produce(data, (draft) => {
    draft.path = path;
    draft.rootLocation = rootLocation;
  });
  const [{ isDragging }, drag] = useDrag({
    item: item,
    type: item.type,
    //canDrag: canDrag
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(ref);

  const onTabChange = (event, newValue) => {
    setActivedTab(newValue);
  };

  let defaultActiveKey;
  if (!StringUtils.isEmpty(activedTab)) {
    defaultActiveKey = activedTab;
  } else if (!StringUtils.isEmpty(item.defaultActiveKey)) {
    defaultActiveKey = item.defaultActiveKey;
  } else if (!ArrayUtils.isEmpty(item.child)) {
    defaultActiveKey = JsonUtils.defaultString(
      item.child[0].propertyValue,
      "id"
    );
  }
  const DNDContainer =
    JsonUtils.defaultString(item.propertyValue, "tabType") === "S"
      ? UIViewerDNDContainer
      : props.dndContainer
      ? props.dndContainer
      : UIEditorDNDContainer;

  return (
    <div ref={ref} className="whole-wrap">
      <TabContainer
        style={{ ...style, opacity }}
        componentInfo={item}
        event="renderEditor"
        onTabChange={onTabChange}
        activedTab={defaultActiveKey}
      >
        {!ArrayUtils.isEmpty(item.child)
          ? item.child.map((tab, tabindex) => {
              let tabProps = tab.propertyValue || {};
              let tabId = StringUtils.defaultString(tabProps.id, tab.compId);
              return (
                <TabPanel
                  id={tabId}
                  key={tabId}
                  active={tabId === defaultActiveKey && true}
                >
                  {!ArrayUtils.isEmpty(tab.child)
                    ? tab.child.map((child, index) => {
                        const currentPath = `${path}-${tabindex}-${index}`;
                        return (
                          <React.Fragment key={index}>
                            <DNDContainer
                              data={{
                                location: Enums.ComponentType.CONTAINER,
                                rootLocation: rootLocation,
                                path: currentPath,
                                childrenCount: tab.child.length,
                              }}
                              onDrop={handleDrop}
                              templateComponents={templateComponents}
                            />
                            <UIEditorChildRender
                              key={child.compId}
                              data={child}
                              handleDrop={handleDrop}
                              templateComponents={templateComponents}
                              path={currentPath}
                              dndContainer={DNDContainer}
                              rootLocation={rootLocation}
                            />
                          </React.Fragment>
                        );
                      })
                    : ""}
                  <DNDContainer
                    data={{
                      location: Enums.ComponentType.CONTAINER,
                      rootLocation: rootLocation,
                      path: `${path}-${tabindex}-${
                        ArrayUtils.isEmpty(tab.child) ? 0 : tab.child.length
                      }`,
                      childrenCount: ArrayUtils.isEmpty(tab.child)
                        ? 0
                        : tab.child.length,
                    }}
                    onDrop={handleDrop}
                    templateComponents={templateComponents}
                    className={`${
                      ArrayUtils.isEmpty(tab.child)
                        ? "horizontal-drag-full"
                        : ""
                    }`}
                  />
                  {JsonUtils.defaultString(item.propertyValue, "tabType") ===
                  "S" ? (
                    <div className="editor-empty-tabpanel">
                      {StringUtils.defaultString(
                        tabProps.programId,
                        tabProps.url
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </TabPanel>
              );
            })
          : ""}
      </TabContainer>
    </div>
  );
};

export default React.memo(UIEditorTab);

const TabPanel = (props) => {
  const { children, id, active, ...other } = props;

  return (
    <div
      role="tabpanel"
      className="editor-tab-panel"
      hidden={active !== true}
      id={id}
      aria-labelledby={`tab-${id}`}
      {...other}
    >
      {active && children}
    </div>
  );
};
