import React from "react";

const ProgressSpinner = (props) => {
  const type = props.type || "circle";
  return (
    <div className="load-container">
      <div className={`${type}-loader`}></div>
    </div>
  );
};
export default ProgressSpinner;
