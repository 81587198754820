/*!
 * Builder Redux Helper for react v.17
 *
 * Redux에서 관리 되는 Builder 정보를 update하기 위한 Logic
 *
 *   Author: Bizentro
 *   Date: 2021-04
 */

import * as Enums from "components/builder/BuilderEnum";
import {
  ObjectUtils,
  JsonUtils,
  StringUtils,
  ArrayUtils,
} from "components/common/utils/CommonUtils";
import produce from "immer";
import * as actions from "components/builder/ui/reducers/UIBuilderAction";
import UIDndHelper from "components/builder/ui/editor/helper/UIDndHelper";
import cloneDeep from "lodash/cloneDeep";
import {
  initCommand,
  stackRedo,
  stackUndo,
} from "components/builder/ui/reducers/CommandAction";
import {
  initWorkspace,
  setWorkspace,
} from "components/builder/ui/reducers/WorkspaceAction";
import { initSetting } from "components/builder/ui/reducers/SettingAction";
import { initMenu } from "components/builder/ui/reducers/MenuAction";
import {
  initSearch,
  setSearchList,
  setSearchType,
} from "../../reducers/SearchConsoleAction";

class UIReduxHelper {
  /**
   * [Page외 component/layout] Properties tab의 변경된 property value 를 output에 update 한다.
   * @param {*} dispatch
   * @param {*} output
   * @param {*} componentInfo - 현재 active된 component
   * @param {*} propertyValue
   * @param {*} targetComponent - 현재 active 되어있는 component외 다른 component가 수정된경우
   */
  static updateOutputByProps = (
    dispatch,
    output,
    componentInfo,
    propertyValue,
    targetComponent
  ) => {
    if (
      componentInfo.type === Enums.ComponentType.GRID_CELL ||
      componentInfo.type === Enums.ComponentType.GRID_HEADER
    ) {
      this._updateGridColumnByProps(
        dispatch,
        output,
        componentInfo,
        propertyValue
      );
    } else {
      this._updateOutputByProps(
        dispatch,
        output,
        componentInfo,
        propertyValue,
        targetComponent
      );
    }
  };

  /**
   * [Page] Properties tab의 변경된 property value 를 output에 update 한다.
   * @param {*} dispatch
   * @param {*} output
   * @param {*} componentInfo
   * @param {*} propertyValue
   */
  static updatePageByProps = (
    dispatch,
    output,
    componentInfo,
    propertyValue
  ) => {
    const changedOutput = produce(output, (draft) => {
      draft.page.propertyValue = propertyValue;
      const programType = StringUtils.defaultString(
        propertyValue.programType,
        "M"
      );
      //footer정보가 있으면 삭제
      if (programType !== "P" && !ObjectUtils.isEmpty(draft.page.footer))
        delete draft.page.footer;
    });
    if (changedOutput !== false) {
      this._updateOutput(changedOutput, dispatch);
      dispatch(actions.updateActivateProps(propertyValue));
    }
  };

  /**
   * [Popup Page] Properties tab의 변경된 property value 를 output에 update 한다.
   *  - templates통해 Footer Layout 을 생성한다.
   *  - templates가 null일 경우 Footer Layout을 제거 한다.
   * @param {*} dispatch
   * @param {*} output
   * @param {*} componentInfo
   * @param {Array} footerTemplate  - Footer column Template
   * @param {*} propertyValue
   */
  static updatePageWithFooterByProps = (
    dispatch,
    output,
    componentInfo,
    footerTemplate,
    propertyValue
  ) => {
    dispatch(stackUndo(output));
    const changedOutput = produce(output, (draft) => {
      draft.page.propertyValue = propertyValue;
      //footer 삭제
      if (footerTemplate == null) {
        delete draft.page.footer;

        //footer 생성
      } else {
        draft.page.footer = { child: footerTemplate };
      }
    });
    if (changedOutput !== false) {
      this._updateOutput(changedOutput, dispatch);
      dispatch(actions.updateActivateProps(propertyValue));
    }
  };

  /**
   * [Grid외 component/layout] Properties tab의 변경된 property value 를 output에 update 한다.
   * @param {*} dispatch
   * @param {*} output
   * @param {*} componentInfo
   * @param {*} propertyValue
   * @param {*} targetComponent
   */
  static _updateOutputByProps = (
    dispatch,
    output,
    componentInfo,
    propertyValue,
    targetComponent
  ) => {
    let component = targetComponent ? targetComponent : componentInfo;
    const compId = component.compId;
    const changedOutput = produce(output, (draft) => {
      const targetNode = JsonUtils.overrideNode(
        draft,
        "compId",
        compId,
        "propertyValue",
        propertyValue
      );
      if (ObjectUtils.isEmpty(targetNode)) {
        console.log("Could not find the target node !!!!");
        return false;
      }
    });
    if (changedOutput !== false) {
      this._updateOutput(changedOutput, dispatch);

      //현재 active 되어있는 component가  변경되었을 경우
      if (!targetComponent) {
        dispatch(actions.updateActivateProps(propertyValue));
      }
    }
  };

  /**
   * [Grid Column/Cell] Properties tab의 변경된 property value 를 output에 update 한다.
   * @param {*} dispatch
   * @param {*} output
   * @param {*} componentInfo
   * @param {*} propertyValue
   */
  static _updateGridColumnByProps = (
    dispatch,
    output,
    componentInfo,
    propertyValue
  ) => {
    dispatch(stackUndo(output));
    const gridId = componentInfo.gridId;
    let newProptyValue = { ...propertyValue };
    const changedOutput = produce(output, (draft) => {
      const gridNode = JsonUtils.findNode(draft, "gridId", gridId);

      if (ObjectUtils.isEmpty(gridNode)) {
        console.log("Could not find the target grid !!!!");
        return false;
      }
      const splitDropZonePath = componentInfo.path.split("-");

      const index = Number(splitDropZonePath[splitDropZonePath.length - 1]);
      // gridNode.columns[index] = {
      //   ...gridNode.columns[index],
      //   ...propertyValue,
      // };

      //data 해당사항없음 선택시 name 을 _none_으로 변경 (직접 수정한경우 제외)
      if (
        StringUtils.isEmpty(newProptyValue.data) &&
        gridNode.columns[index].data === gridNode.columns[index].name
      ) {
        newProptyValue["name"] = "_none_" + newProptyValue.sortOrder;

        //data 변경시 name도 동일하게 변경해준다.
      } else if (newProptyValue.data !== gridNode.columns[index].data) {
        newProptyValue["name"] = newProptyValue.data;
      }
      gridNode.columns[index] = { ...newProptyValue };
    });
    if (changedOutput !== false) {
      this._updateOutput(changedOutput, dispatch);
      dispatch(actions.updateActivateProps(newProptyValue));
    }
  };

  /**
   * component type을 변경한다.
   * @param {*} output
   * @param {*} oldComponent 현재
   * @param {*} newComponent  신규
   */
  static changeComponentType = (
    output,
    oldComponent,
    newComponent,
    dispatch
  ) => {
    dispatch(stackUndo(output));
    const compId = oldComponent.compId;
    let newActivateComponent = { ...newComponent };
    const changedOutput = produce(output, (draft) => {
      const targetNode = JsonUtils.findNode(draft, "compId", compId);
      if (ObjectUtils.isEmpty(targetNode)) {
        console.log("Could not find the target node !!!!");
        return false;
      }
      //node를 변경한다.
      targetNode.compId =
        StringUtils.substringAfter(newComponent.componentClass, "/") +
        "-" +
        StringUtils.getUuid();
      targetNode.baseCompId = newActivateComponent.componentDtlId;
      targetNode.propertyValue = {
        ...JsonUtils.parseJson(newActivateComponent.defaultProperty),
        ...targetNode.propertyValue,
      };
      targetNode.editorAttr = JsonUtils.parseJson(
        newActivateComponent.editorAttr
      );
      targetNode.viewerAttr = JsonUtils.parseJson(
        newActivateComponent.viewerAttr
      );
      console.log(JsonUtils.findNode(draft, "compId", targetNode.compId));

      //변경된 component를 activation시킨다.
      newActivateComponent.propertyValue = cloneDeep(targetNode.propertyValue);
      newActivateComponent.compId = targetNode.compId;
    });
    if (changedOutput !== false && newActivateComponent) {
      this._updateOutput(changedOutput, dispatch);
      dispatch(actions.activateComponent(newActivateComponent));
    }
  };
  /**
   * component를 추가한다. (properties tab에서 버튼을 통해 component를 추가할 경우)
   * @param {*} dispatch
   * @param {*} output
   * @param {*} componentInfo actived되어있는 component
   * @param {*} propertyValue 신규 추가할 component propertyValue
   * @param {String} type "B" : Before, "A" : After
   */
  static addComponent = (
    dispatch,
    output,
    componentInfo,
    propertyValue,
    type
  ) => {
    if (
      componentInfo.type === Enums.ComponentType.GRID_CELL ||
      componentInfo.type === Enums.ComponentType.GRID_HEADER
    ) {
      this.addGridColumn(dispatch, output, componentInfo, propertyValue, type);
    } else {
      //not yet
    }
  };

  /**
   * Grid Column component를 추가한다. (properties tab에서 버튼을 통해 Grid Column  component를 추가할 경우)
   * @param {*} dispatch
   * @param {*} output
   * @param {*} componentInfo actived되어있는 component
   * @param {*} propertyValue 신규 추가할 component propertyValue
   * @param {String} type "B" : Before, "A" : After
   */
  static addGridColumn = (
    dispatch,
    output,
    componentInfo,
    propertyValue,
    type
  ) => {
    dispatch(stackUndo(output));
    const gridId = componentInfo.gridId;
    let activeComponent;
    const updatedLayout = produce(output, (draft) => {
      const gridNode = JsonUtils.findNode(draft, "gridId", gridId);

      if (ObjectUtils.isEmpty(gridNode)) {
        console.log("Could not find the target grid !!!!");
        return false;
      }
      if (ArrayUtils.isEmpty(gridNode.columns)) {
        return false;
      }
      const sourceColIndex = ArrayUtils.getIndex(
        gridNode.columns,
        "compId",
        componentInfo.compId
      );

      const newColNode = { ...propertyValue };
      newColNode.compId = StringUtils.getUuid();
      newColNode.name = "New-" + newColNode.compId;
      activeComponent = { ...newColNode };
      activeComponent.compId = "Header-" + newColNode.compId;
      activeComponent.gridId = componentInfo.gridId;
      activeComponent.componentClass = componentInfo.componentClass;

      const insertIndex = type === "B" ? sourceColIndex : sourceColIndex + 1;

      gridNode.columns = UIDndHelper.insert(
        gridNode.columns,
        insertIndex,
        newColNode
      );

      //sortOrder 재조정
      gridNode.columns.map((column, index) => {
        column.sortOrder = index + 1;
      });
    });
    if (updatedLayout !== false && !ObjectUtils.isEmpty(updatedLayout)) {
      this._updateOutput(updatedLayout, dispatch);
      this.activateComponent(activeComponent, dispatch);
    }
  };

  /**
   * New Page Data 생성 후 output 정보를 update 한다.
   * @param {*} dispatch
   * @param {*} componentInfo
   */
  static createPage = (dispatch, componentInfo) => {
    const newItem = {
      compId: `page-${StringUtils.getUuid()}`,
    };
    if (!StringUtils.isEmpty(componentInfo.defaultProperty)) {
      newItem.propertyValue = JsonUtils.parseJson(
        componentInfo.defaultProperty
      );
      componentInfo.propertyValue = newItem.propertyValue;
    }
    if (!StringUtils.isEmpty(componentInfo.editorAttr)) {
      newItem.editorAttr = JsonUtils.parseJson(componentInfo.editorAttr);
    }
    if (!StringUtils.isEmpty(componentInfo.viewerAttr)) {
      newItem.viewerAttr = JsonUtils.parseJson(componentInfo.viewerAttr);
    }
    componentInfo.compId = newItem.compId;
    dispatch(actions.createPage({ page: newItem }));
    dispatch(initCommand({ page: newItem }));
    dispatch(actions.activateComponent(componentInfo));
  };

  /**
   * 표준 / 공유 Template 적용 > template data를 output 정보에 update 한다.
   * @param {*} dispatch
   * @param {*} componentInfo
   * @param {*} tmplOutput
   */
  static applyTemplate = (dispatch, componentInfo, tmplOutput) => {
    try {
      const newComponentInfo = { ...componentInfo };
      let templateOutput = JsonUtils.parseJson(tmplOutput);
      //compId를 재생성
      JsonUtils.updateCompId(templateOutput);

      newComponentInfo.compId = templateOutput.page.compId;
      newComponentInfo.propertyValue =
        { ...templateOutput.page.propertyValue } || {};

      console.log("new active component", newComponentInfo.compId);

      dispatch(actions.activateComponent(newComponentInfo));
      this._updateOutput(templateOutput, dispatch);
    } catch (e) {
      console.log("Template parsing error !!!!");
      console.log(e);
      throw new Error("Template data parsing 중 에러 입니다 .(" + e + ")");
    }
  };
  /**
   * Load 한 output 정보를 그대로 template에 update 한다.
   * @param {*} dispatch
   * @param {*} componentInfo
   * @param {*} tmplOutput
   */
  static loadTemplate = (dispatch, componentInfo, tmplOutput) => {
    try {
      const newComponentInfo = { ...componentInfo };
      let templateOutput = JsonUtils.parseJson(tmplOutput);

      newComponentInfo.compId = templateOutput.page.compId;
      newComponentInfo.propertyValue =
        { ...templateOutput.page.propertyValue } || {};

      dispatch(actions.activateComponent(newComponentInfo));
      dispatch(actions.updateOutput(templateOutput));
      dispatch(initCommand(templateOutput));
    } catch (e) {
      console.log("Template parsing error !!!!");
      console.log(e);
      throw new Error("Template data parsing 중 에러 입니다 .(" + e + ")");
    }
  };
  /**
   * 대상 component를 activation 시킨다.
   * @param {Map} targetComponent
   */
  static activateComponent = (activedComponent, dispatch) => {
    if (!ObjectUtils.isEmpty(activedComponent)) {
      dispatch(actions.activateComponent(activedComponent));
    }
  };
  /**
   * active component를 초기화 한다.
   */
  static initActivateComponent = (dispatch) => {
    dispatch(actions.activateComponent({}));
  };

  /*
   * 변경된 output을 update한다.
   * @param {Map} targetComponent
   */
  static updateOutput = (changedOutput, dispatch) => {
    dispatch(stackUndo(changedOutput));
    this._updateOutput(changedOutput, dispatch);
  };

  static _updateOutput = (changedOutput, dispatch) => {
    dispatch(actions.updateOutput(changedOutput));
    dispatch(stackRedo(changedOutput));
  };

  /**
   * 변경된 child를 최종 output 을 변경한다.
   * @param {*} output
   * @param {*} changedChild
   * @param {*} rootLocation
   * @param {*} dispatch
   */
  static updateOutputChild = (output, changedChild, rootLocation, dispatch) => {
    dispatch(stackUndo(output));
    const changedOutput = produce(output, (draft) => {
      if (rootLocation === Enums.ComponentType.FOOTER) {
        draft.page.footer.child = changedChild;
      } else {
        draft.page.child = changedChild;
      }
      draft.page.lastUpdatedDate = new Date();
    });
    this._updateOutput(changedOutput, dispatch);
  };

  /**
   * 변경된 Page 정보를 최종 output 을 변경한다.
   * @param {*} output
   * @param {*} updatedLayout
   * @param {*} rootLocation
   * @param {*} dispatch
   */
  static updateOutputPage = (output, updatedLayout, rootLocation, dispatch) => {
    dispatch(stackUndo(output));
    const changedOutput = produce(output, (draft) => {
      if (rootLocation === Enums.ComponentType.FOOTER) {
        draft.page.footer = updatedLayout;
      } else {
        draft.page = updatedLayout;
      }
      //draft.page.lastUpdatedDate = new Date(); //왜..readonly...
    });
    this._updateOutput(changedOutput, dispatch);
  };
  /**
   * 불러온 Program 정보를 Redux에 update 한다. 없는 경우에는 null로 초기화 한다.
   * @param {*} information
   * **/
  static updateInformation = (dispatch, information) => {
    if (typeof information?.programContent === "string") {
      information.programContent = JSON.parse(information.programContent);
    }
    dispatch(actions.updateInformation(information));
  };

  /**
   * 워크 스페이스 설정
   */
  static setWorkspace = (dispatch, workspace) => {
    dispatch(setWorkspace(workspace));
  };

  /**
   * 종합 검색 결과 설정
   * @param {*} dispatch
   * @param {*} workspace
   */
  static setTotalSearchList = (dispatch, list) => {
    dispatch(setSearchList(list));
  };

  static setTotalSearchType = (dispatch, type) => {
    dispatch(setSearchType(type));
  };

  /**
   * 리덕스 value 초기값으로 전환
   * @param {*} dispatch
   */
  static initializeAllRedux = (dispatch) => {
    dispatch(initWorkspace());
    dispatch(initCommand());
    dispatch(initSetting());
    dispatch(initMenu());
    dispatch(initSearch());
  };
}

export default UIReduxHelper;
