import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BoardService from "services/prototype/BoardService";

import Message from "components/common/Message";
import Popup from "components/common/Popup";
import SamplePopup from "page/popup/SamplePopup";
import { Button } from "react-bootstrap";

const BoardList = () => {
  const [boards, setBoards] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    /* Service 파일 생성후 호출 */
    let params = {};
    BoardService.getBoardList(params, (res) => {
      setBoards(res.data);
    });

    /* API 직접호출
    Api.get("/prototype/board", null, (res) => {
      setBoards(res.data);
    });
    */
  }, []);

  const createBoard = () => {
    navigate("/create-board/");
  };

  const confirmDefault = () => {
    /*
    메시지만 있을 경우.
    */
    Message.confirm(
      "글작성 화면으로 이동하시겠습니까?",
      () => {
        //this.props.history.push("/create-board/"); //v5용
        navigate("/create-board/");
      }, // yes callback
      () => {} //no callback
    );
  };

  const confirmCustom = () => {
    /*
     * title과 desc(상세내용)을 구분할 경우
     */
    Message.confirm(
      {
        title: "이동 하시겠습니까?",
        desc: "예를 클릭하시면 글 작성 화면으로 이동합니다.",
      },
      () => {
        navigate("/create-board/");
      }, // yes callback
      () => {} //no callback
    );
  };

  const alertDefault = () => {
    Message.alert("저장되었습니다.");
  };

  const alertCustom = () => {
    /**
     * option을 변경
     * */
    let options = {
      position: "top-center",
      hideProgressBar: true,
    };
    Message.alert("글작성화면으로 이동합니다.", "info", options);
  };

  const callbackFnc = (inputValue) => {
    console.log("input Value", inputValue);
  };

  const popupDefault = () => {
    const popupTitle = "Sample Popup";
    /*
     * 1) props 는 해당 popup 자체적으로 사용하기 위해 필요시 넣는다.
     *  - ex) title을 클릭하는 시점에 동적으로 변경된경우 아래처럼 title을 props에 넘겨서 SamplePopup.js에서 넘겨 받는다.
     *
     * 2) options
     *   - onRequestClose :  popup 외부 클릭시 popup을 닫을지 여부 or callback함수
     *     : true or false (default) or callback function
     *   -  effect : popup display effect 지정
     *     : Effect.SlideFromTop (default)
     *      (*) import { Effect} from "components/common/modal/ModalConfig";
     *   - style : Popup style에 대한 모든 설정 (크기,위치.등등...)
     *     : ModalConfig.Style (default)
     */

    //option 정보는 components/common/Popup 참조
    const options = {
      effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
      style: {
        content: {
          width: "50%", //popup의 크기를 50% (default 60%)
        },
      },
    };
    Popup.open(
      <SamplePopup title={popupTitle} callbackFnc={callbackFnc} />,
      options
    );
  };

  return (
    <div className="content">
      <h2 className="text-center">Boards List</h2>
      <div className="row">
        <div className="col-12">
          <div className="mt-5 mb-1">
            <Button variant="primary" onClick={createBoard}>
              글 작성
            </Button>
            <Button variant="danger" onClick={confirmDefault}>
              Confirm default
            </Button>
            <Button variant="danger" onClick={confirmCustom}>
              Confirm custom
            </Button>
            <Button variant="success" onClick={alertDefault}>
              Alert default
            </Button>
            <Button variant="success" onClick={alertCustom}>
              Alert custom
            </Button>
            <Button variant="warning" onClick={popupDefault}>
              Popup default
            </Button>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12"></div>
      </div>
      <div className="row">
        <div className="col-12">
          <table className="table table-striped table-bordered">
            <thead>
              <tr>
                <th>글 번호</th>
                <th>타이틀 </th>
                <th>작성자 </th>
                <th>작성일 </th>
                <th>갱신일 </th>
                <th>좋아요수</th>
                <th>조회수</th>
              </tr>
            </thead>
            <tbody>
              {boards.map((board) => (
                <tr key={board.boardId}>
                  <td> {board.boardId} </td>
                  <td> {board.title} </td>
                  <td> {board.memberNo} </td>
                  <td> {board.createdTime} </td>
                  <td> {board.updatedTime} </td>
                  <td> {board.likes} </td>
                  <td> {board.counts} </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default BoardList;
