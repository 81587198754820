import { Checkbox, FormControlLabel } from "@mui/material";
import { Enums } from "components/builder/BuilderEnum";
import { stopEvent } from "components/builder/ui/editor/handler/UIEditorEventHandler";
import Popup from "components/common/Popup";
import Modal from "components/common/modal/UModal";
import UModalTemplate from "components/common/modal/UModalTemplate";
import User from "components/common/utils/UserUtils";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import LocalStorageService from "services/common/LocalService";

function RunWorkflowQuestPopup({ callback, ...props }) {
  const [autoSave, setAutoSave] = useState("N");

  const onClickAutoSave = (e) => {
    e.stopPropagation();
    setAutoSave(e.target.checked ? "Y" : "N");
  };

  const onClickConfirm = (e) => {
    stopEvent(e);
    if (autoSave === "Y") {
      const userId = User.getId();
      const body = {
        userId,
        autoSave,
      };
      LocalStorageService.set(
        Enums.LocalStorageName.WORKFLOW_AUTO_DEPLOY,
        body
      );
    }
    Popup.close();
    if (callback) {
      callback();
    }
  };

  return (
    <Modal>
      <Modal.Header />
      <Modal.Body>
        <UModalTemplate>
          <p>저장(수정) 후 배포 기록이 없거나,</p>
          <p>배포된 버전과 다른 부분이 있습니다.</p>
          <p>배포하시겠습니까?</p>

          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={autoSave === "Y" ? true : false}
                  onClick={onClickAutoSave}
                />
              }
              label="다시 묻지 않기 ( 자동 배포 )"
            />
          </div>
        </UModalTemplate>
      </Modal.Body>
      <Modal.Footer closeText="아니오">
        <Modal.Footer.Button onClick={onClickConfirm}>예</Modal.Footer.Button>
      </Modal.Footer>
    </Modal>
  );
}

export default RunWorkflowQuestPopup;
