import React from "react";
import StringUtils from "../utils/StringUtils";
import { Button } from "react-bootstrap";
import ExtendTextEditorPopup from "page/popup/ExtendTextEditorPopup";
import ExtendPopup from "page/popup/ExtendPopup";
import Popup from "../Popup";
import { AiOutlineFunction } from "react-icons/ai";

/**
 * 버튼과 코드 인풋터가 결합된 컴포넌트
 * @param {*} param0
 * @returns
 */
const UCodeMirrorButton = ({
  defaultValue,
  popTitle,
  propId,
  fieldType,
  jsonValidation,
  placeholder,
  onClickEventBuilder,
  eventWorkspace,
  onDeleteEventWorkspace,
  isEventBuilder = true,
  callbackFnc,
  onBlur,
  className,
  ...props
}) => {
  const onClickPopup = (e) => {
    const options = {
      keyDownEvent: false,
      effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
      style: {
        content: {
          minHeight: "200px",
        },
      },
    };

    let PopupEditor;

    if (fieldType === "html") {
      PopupEditor = ExtendTextEditorPopup;
    } else {
      PopupEditor = ExtendPopup;
    }
    Popup.open(
      <PopupEditor
        title={popTitle}
        defaultValue={defaultValue}
        fieldType={fieldType}
        jsonValidation={props.jsonValidation}
        callbackFnc={extendPopupCallback}
        placeholder={placeholder}
        eventInfo={props.getEventInfo()}
        onClickEventBuilder={onClickEventBuilder}
        eventWorkspace={eventWorkspace}
        onDeleteEventWorkspace={onDeleteEventWorkspace}
        isEventBuilder={isEventBuilder}
        documentPage={props.documentPage} //해당 코드에 대한 가이드 문서
      />,
      options
    );
  };

  const extendPopupCallback = (popVal) => {
    if (onBlur != null) {
      onBlur.call(this, { target: { id: propId, value: popVal } });
    }
    if (callbackFnc != null) {
      callbackFnc.call(this, popVal);
    }
  };

  if (StringUtils.isEmpty(defaultValue)) {
    return (
      <Button
        className={className || ""}
        onClick={onClickPopup}
        size={"sm"}
        variant="light"
      >
        <AiOutlineFunction className="mr-5" />
      </Button>
    );
  } else {
    return (
      <Button className={className || ""} onClick={onClickPopup} size={"sm"}>
        <AiOutlineFunction className="mr-5" />
      </Button>
    );
  }
};

export default UCodeMirrorButton;
