/* eslint-disable jsx-a11y/alt-text */
import React, { Fragment } from "react";

import Modal from "components/common/modal/UModal";

import UmodalTemplate from "components/common/modal/UModalTemplate";

const GridProgressCellTemplateGuidePopup = (props) => {
  return (
    <Modal>
      <Modal.Header title="Grid Progress Bar Cell Template 사용 가이드" />
      <Modal.Body>
        <UmodalTemplate>
          <Fragment>
            <h4>Grid Progress Bar CellTemplate</h4>
            <div className="doc-page-wrap">
              <h5>▶ HTML 코드 작성 가이드</h5>
              <div className="event-desc pt-1">
                HTML Tag를 이용해 셀의 스타일을 변경 할 수 있습니다.
              </div>
              <br></br>
              예제) 진행 상태를 표시하려면 width 속성을 넣어준다.(최대 진행
              상태가 100일 경우)
              <code className="mt-2 pt-2">
                {`<div style="width: width: \${item.progressState / 100 * 100}%;"></div>`}
              </code>
              <br></br>
              예제) 진행률에 따라 색을 다르게 표시하는 경우.
              <code className="mt-2 pt-2">
                {`<div style="{ item.progressState / 100 * 100 < 30 ? 
                \`background-color: #dc3545;\` :
                item.progressState / 100 * 100 < 50 ?
                \`background-color: #ffc107;\` :
                item.progressState / 100 * 100 < 70 ?
                \`background-color: #17a2b8;\` :
                item.progressState / 100 * 100 < 100 ?
                \`background-color: #498ff7;\` :
                \`background-color: #28a745;\` }">
                </div>`}
              </code>
              <br></br>
              예제) 날짜 Data를 표시하고 싶은 경우.
              <code className="mt-2 pt-2">
                {`<div>\n\${item.utils.Date.yyyymmdd(new Date(item.startDate))} ~ \n\${item.utils.Date.yyyymmdd(new Date(item.endDate))}\n</div>`}
              </code>
              <br></br>
              <div className="event-code">
                function (col,row,value,text,item)
              </div>
              <div className="event-params pb-1">
                <span className="doc-title"> Parameters</span>
                <ul>
                  <li>
                    <span className="event-args-var">col</span>:
                    <span className="event-args-type">Object</span>-
                    <span className="event-args-name">현재 Column Object</span>
                  </li>
                  <li>
                    <span className="event-args-var">row</span>:
                    <span className="event-args-type">Object</span>-
                    <span className="event-args-name">현재 Row Object</span>
                  </li>
                  <li>
                    <span className="event-args-var">value</span>:
                    <span className="event-args-type">String</span>-
                    <span className="event-args-name">
                      현재 Column에 Binding 되어 있는 값
                    </span>
                  </li>
                  <li>
                    <span className="event-args-var">text</span>:
                    <span className="event-args-type">String</span>-
                    <span className="event-args-name">
                      현재 Cell에 표시되는 Text
                    </span>
                  </li>
                  <li>
                    <span className="event-args-var">item</span>:
                    <span className="event-args-type">Object:Map</span>-
                    <span className="event-args-name">
                      현재 Row에 Binding 되어 있는 값
                    </span>
                    <div className="event-args-desc">예) item.userId</div>
                  </li>
                </ul>
              </div>
            </div>
          </Fragment>
        </UmodalTemplate>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default GridProgressCellTemplateGuidePopup;
