import { InputLabel, Select, TextField } from "@mui/material";
import { Enums } from "components/builder/BuilderEnum";
import { AppContext } from "components/common/AppContextProvider";
import MuiConfig from "components/common/config/MuiConfig";
import MSelectbox from "components/common/element/MSelectbox";
import WijmoGrid from "components/common/element/WijmoGrid";
import CommonUtils, { ObjectUtils } from "components/common/utils/CommonUtils";
import StringUtils from "components/common/utils/StringUtils";
import PageTemplate from "page/common/PageTemplate";
import { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { BsSearch } from "react-icons/bs";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DataModelService from "services/datamodel/DataModelService";
import ProgramService from "services/ui/ProgramService";

const columns = [
  { field: "tenantId", headerName: "Tenant ID", width: 150 },
  { field: "coCd", headerName: "회사 코드", width: 150 },
  {
    field: "dataModelNm",
    headerName: "데이터 모델 명",
    width: "*",
  },
  {
    field: "dataModelTypeNm",
    headerName: "데이터 모델 타입",
    width: "*",
  },
  {
    field: "status",
    headerName: "상태",
    width: 120,
    renderCell: (params) => {
      const { status } = params;
      if (StringUtils.equalsIgnoreCase(status, "R")) {
        return "삭제 됨";
      } else if (StringUtils.equalsIgnoreCase(status, "A")) {
        return "사용 중";
      }
    },
    // valueGetter: (params) => {
    //   const { status } = params.row;
    //   if (StringUtils.equalsIgnoreCase(status, "R")) {
    //     return "삭제 됨";
    //   } else if (StringUtils.equalsIgnoreCase(status, "A")) {
    //     return "사용 중";
    //   }
    // },
  },
  {
    field: "updtUserId",
    headerName: "최종 수정자",
    width: 250,
  },
  {
    field: "lastUpdtDt",
    headerName: "최종 수정일시",
    width: 300,
    renderCell: (params) => `${CommonUtils.getDate(params.updtDt, "dateTime")}`,
    // valueGetter: (params) =>
    //   `${CommonUtils.getDate(params.row.updtDt, "dateTime")}`,
  },
];

function DataModelHistory() {
  const breadcrum = [
    {
      name: "데이터 모델 이력",
      url:
        Enums.BuilderPath.ENTITY.MAIN + "/" + Enums.BuilderPath.ENTITY.HISTORY,
      active: true,
    },
  ];

  const workspace = useSelector((state) => state.workspace);
  const {
    code: { getCodeList },
    workspace: { openPopup },
  } = useContext(AppContext); //프로그램 유형
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [dataModelNm, setDataModelNm] = useState("");
  const [dataModelType, setDataModelType] = useState("");
  const [status, setStatus] = useState("A");
  const [dataModelHistoryList, setDataModelHistoryList] = useState([]);
  const [pageSize, setPageSize] = useState(20);

  useEffect(() => {
    if (!ObjectUtils.isEmpty(workspace)) {
      getDataModelHistoryMstList();
    }
    return () => {
      setDataModelHistoryList([]);
    };
  }, [workspace]);

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      getDataModelHistoryMstList();
    }
  };

  const getDataModelHistoryMstList = () => {
    setIsLoading(true);
    const body = {
      appId: workspace.appId,
      moduleCd: workspace.moduleCd,
      appReleaseId: workspace.appReleaseId,
      tenantId: workspace.tenantId,
      coCd: workspace.coCd,
      dataModelNm,
      dataModelType,
      status,
    };

    DataModelService.getDataModelHistoryMstList(body, (res) => {
      const list = ProgramService.filterByTenantIdAndCoCd(
        res.data,
        workspace.tenantId,
        workspace.coCd,
        "dataModelNm"
      );
      setDataModelHistoryList(list);
      setIsLoading(false);
    });
  };

  const onMoveToDetail = (historyMst) => {
    const { dataModelId, historyMstId } = historyMst;
    navigate(`${dataModelId}/${historyMstId}`);
  };

  return (
    <PageTemplate breadcrum={breadcrum}>
      <PageTemplate.Box boxClass="mb-0">
        {ObjectUtils.isEmpty(workspace) ? (
          <>
            <div className="workspace-empty-alert">
              <div className="alert-msg">워크 스페이스 설정이 필요 합니다.</div>
              <Button onClick={() => openPopup()}>팝업 열기</Button>
            </div>
          </>
        ) : (
          <>
            <Form>
              <Row className="pb-3 pt-3">
                <Col>
                  <TextField
                    size="small"
                    label="데이터 모델 명"
                    placeholder="데이터 모델 명"
                    fullWidth={true}
                    value={dataModelNm}
                    onChange={(e) => setDataModelNm(e.currentTarget.value)}
                    onKeyDown={onKeyDown}
                  />
                </Col>

                <Col>
                  <MSelectbox
                    size="small"
                    fullWidth={true}
                    data={getCodeList("Z0014")}
                    options={{ matchCd: "codeDtlCd", matchNm: "codeDtlNm" }}
                  >
                    <InputLabel id="module-label">데이터 모델 타입</InputLabel>
                    <Select
                      name="dataModelType"
                      labelId="module-label"
                      label="데이터 모델 타입"
                      fullWidth
                      color="primary"
                      value={dataModelType}
                      onChange={(e) => setDataModelType(e.target.value)}
                    ></Select>
                  </MSelectbox>
                </Col>
                <Col>
                  <MSelectbox
                    size="small"
                    fullWidth={true}
                    data={[
                      { id: "*", text: "전체" },
                      { id: "A", text: "사용 중" },
                      { id: "R", text: "삭제 됨" },
                    ]}
                    options={{ matchCd: "id", matchNm: "text" }}
                  >
                    <InputLabel id="module-label">상태</InputLabel>
                    <Select
                      name="programType"
                      labelId="module-label"
                      label="상태"
                      fullWidth
                      color="primary"
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                    ></Select>
                  </MSelectbox>
                </Col>
                <Col>
                  <Button
                    variant="primary"
                    onClick={getDataModelHistoryMstList}
                  >
                    검색 <BsSearch size="14" />
                  </Button>
                </Col>
              </Row>
            </Form>
            <Row style={{ height: "calc(100% - 70px)" }}>
              <Col xs={12}>
                <WijmoGrid
                  getRowId={(row) => row.historyMstId}
                  rows={dataModelHistoryList}
                  columns={columns}
                  onRowClick={(data) => onMoveToDetail(data)}
                  {...MuiConfig.grid.options}
                  rowsPerPageOptions={[pageSize]}
                  pageSize={pageSize}
                  isLoading={isLoading}
                  style={{ height: "70vh" }}
                />
              </Col>
            </Row>
          </>
        )}
      </PageTemplate.Box>
    </PageTemplate>
  );
}

export default DataModelHistory;
