import StringUtils from "components/common/utils/StringUtils";

class ObjectUtils {
  /**
   * isEmpty
   * @param {Object} object
   * @returns
   */
  static isEmpty = (object) => {
    return (
      object == null ||
      typeof object === "undefined" ||
      (ObjectUtils.isObject(object) && Object.entries(object).length === 0)
    );
  };

  /**
   * isObject
   * @param {Object} object
   * @returns
   */
  static isObject = (object) => {
    return typeof object === "object";
  };

  /**
   * isEmptyValue
   * @param {Object} object
   * @returns
   */
  static isEmptyValue = (object) => {
    if (this.isEmpty(object)) {
      return true;
    } else {
      const values = Object.values(object);
      const validation = values.reduce(
        (ac, cu) => ac && StringUtils.isEmpty(cu),
        true
      );
      return validation;
    }
  };
}

export default ObjectUtils;
