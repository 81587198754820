import React, { Component } from "react";
import ProcessModal from "./process/ProcessModal";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { AiOutlineEllipsis } from "react-icons/ai";
import produce from "immer";
import { MdDelete } from "react-icons/md";
import Popup from "components/common/Popup";
import WorkflowListPopup from "./WorkflowListPopup";
import {
  getInOutputEntity,
  getServiceInOutputEntity,
} from "components/builder/workflow/editor/render/WorkflowRenderUtils";
import { stopEvent } from "components/builder/ui/editor/handler/UIEditorEventHandler";
import { START_PROCESS_DATA_TYPE } from "./process/StartProcess";
import ProcessDataBindingPopup from "./ProcessDataBindingPopup";
import StringUtils from "components/common/utils/StringUtils";
import WorkFlowProcessListPopup from "./WorkFlowProcessListPopup";
import ObjectUtils from "components/common/utils/ObjectUtils";
import { BiRefresh } from "react-icons/bi";
import { Tooltip } from "@mui/material";
import WorkflowService from "services/workflow/WorkflowService";
import UModal from "components/common/modal/UModal";
import Message from "components/common/Message";
import { Enums } from "components/builder/BuilderEnum";

class ServiceModal extends ProcessModal {
  constructor(props) {
    super(props);
    this.onOpenWorkflowList = this.onOpenWorkflowList.bind(this);
    this.onAddInput = this.onAddInput.bind(this);
    this.getInputMapping = this.getInputMapping.bind(this);
    this.onDeleteInput = this.onDeleteInput.bind(this);
    this.onChangeInputField = this.onChangeInputField.bind(this);
    this.onChangeOutputField = this.onChangeOutputField.bind(this);
    this.setInOutEntity = this.setInOutEntity.bind(this);
    this.onRefreshService = this.onRefreshService.bind(this);
    this.state = {
      serviceId: "",
      serviceUid: undefined,
      serviceName: "",
      inputMapping: [],
      outputMapping: [],
      inputDataType: START_PROCESS_DATA_TYPE.ENTITY,
      isLoading: false,
    };
  }

  async componentDidMount() {
    const state = { ...this.state, ...this.props.processInfo };
    if (this.props.processInfo) {
      const result = await getServiceInOutputEntity(this.props.processInfo);
      if (result) {
        const {
          inputList = [],
          inputDataType = START_PROCESS_DATA_TYPE.ENTITY,
          outputList = [],
        } = result;
        let inputMapping = inputList.map((input, index) => {
          return {
            inputId: input.inputId,
            inputNm: input.inputNm,
            value: "",
          };
        });
        const prevInputMapping = this.props.processInfo.inputMapping;
        if (prevInputMapping) inputMapping = prevInputMapping;

        const prevMapping = this.props.processInfo.outputMapping || [];
        const outputMapping = outputList.map((outputValue, index) => {
          if (typeof outputValue === "string") {
            return {
              entityNm: "",
              entityVariable: outputValue,
              value: outputValue,
            };
          } else if (ObjectUtils.isObject(outputValue)) {
            const prevMap = prevMapping.find(
              (om) => om.value === outputValue.entityVariable
            );
            if (prevMap) {
              return {
                entityNm: prevMap.entityNm || "",
                entityVariable:
                  prevMap.entityVariable || outputValue.entityVariable,
                value: prevMap.value,
              };
            } else {
              return {
                entityNm: outputValue.entityNm,
                entityVariable: outputValue.entityVariable,
                value: outputValue.entityVariable,
              };
            }
          }
        });
        state.inputDataType = inputDataType;
        state.inputMapping = inputMapping;
        state.outputMapping = outputMapping;
      }
    }
    this.setState(state);
  }

  renderProcessNm() {
    return <></>;
  }

  onValidationCheck() {
    if (!this.state.serviceUid) {
      Message.alert(
        "Service 선택이 모호합니다. Service의 선택 여부를 확인해주세요",
        Enums.MessageType.WARN
      );
      return false;
    }

    return true;
  }

  validateCommonParam() {
    if (this.onValidationCheck() === true) {
      this.onClickConfirm();
    }
  }

  getInputMapping(inputList, inputDataType, inputEntity) {
    let inputMapping = [];
    inputMapping = inputList.map((input, index) => {
      return {
        inputId: input.inputId,
        inputNm: input.inputNm,
        value: "",
      };
    });
    return inputMapping;
  }

  /**
   * 인아풋 세팅
   * @param {*} service
   */
  async setInOutEntity(serviceParam) {
    const result = await getServiceInOutputEntity(serviceParam);
    if (!result) return false;
    const { inputList, inputDataType, inputEntity, outputList, service } =
      result;

    let inputMapping = this.getInputMapping(
      inputList,
      inputDataType,
      inputEntity
    );
    const outputMapping = outputList.map((outputValue, index) => {
      if (typeof outputValue === "string") {
        return {
          entityNm: "",
          entityVariable: outputValue,
          value: outputValue,
        };
      } else {
        return {
          entityNm: outputValue.entityNm,
          entityVariable: outputValue.entityVariable,
          value: outputValue.entityVariable,
        };
      }
    });

    this.setState(
      produce(this.state, (draft) => {
        draft.serviceId = service.serviceId;
        draft.serviceName = service.serviceName;
        draft.serviceUid = service.serviceUid;
        draft.inputDataType = inputDataType;
        draft.inputMapping = inputMapping;
        draft.outputMapping = outputMapping;
      })
    );
  }

  onClickConfirm() {
    if (this.props.callbackFnc) {
      const state = { ...this.state };
      delete state.isLoading;
      this.props.callbackFnc(state);
    }
  }

  /**
   * 워크플로우 (서비스) 목록 팝업 호출
   */
  onOpenWorkflowList() {
    const callbackFnc = (service) => {
      if (service.serviceUid === this.props.workflow.serviceInfo.serviceUid)
        return this.WarnMessage("같은 서비스는 선택할 수 없습니다.");
      this.setInOutEntity(service);
      Popup.close();
    };

    Popup.open(
      <WorkflowListPopup
        callbackFnc={callbackFnc}
        workspace={this.props.workspace}
      />,
      {
        style: {
          content: {
            width: "55%",
          },
        },
      }
    );
  }

  /**
   * Input 삭제
   * @param {*} e
   * @param {*} idx
   */
  onDeleteInput(e, idx) {
    stopEvent(e);
    this.setState(
      produce(this.state, (draft) => {
        draft.inputMapping.splice(idx, 1);
      })
    );
  }
  /**
   * 인풋추가
   * @param {*} e
   */
  onAddInput(e) {
    this.setState(
      produce(this.state, (draft) => {
        draft.inputMapping.push({
          inputId: "",
          inputNm: "",
          value: "",
        });
      })
    );
  }

  callbackDataBinding(args, value) {
    const { type, event, index } = args;
    if (type === START_PROCESS_DATA_TYPE.ENTITY) {
      this.setState(
        produce(this.state, (draft) => {
          draft.inputMapping.value = value;
        })
      );
    } else if (type === START_PROCESS_DATA_TYPE.ENTITY_FIELD) {
      this.setState(
        produce(this.state, (draft) => {
          draft.inputMapping[index].value = value;
        })
      );
    }
  }

  /**
   * 데이터 바인딩 팝업 열기
   * @param {*} e
   * @param {*} idx
   */
  onOpenDataBindingPopup(args) {
    const callbackFnc = (value) => {
      Popup.close();
      this.callbackDataBinding(args, value);
    };
    Popup.open(
      <ProcessDataBindingPopup
        workflow={this.props.workflow}
        nodes={this.props.nodes}
        edges={this.props.edges}
        callbackFnc={callbackFnc}
        compId={this.props.compId}
        showAll={true}
        getAccessibleEntityList={this.getAccessibleEntityList}
      />,
      {
        style: { content: { width: "800px" } },
      }
    );
  }

  /**
   * Entity Definition 목록 팝업 호출
   * @param {*} e
   * @param {*} paramNm
   */
  onOpenEntityList(e, idx) {
    stopEvent(e);
    const callbackFnc = (entity) => {
      Popup.close();
      this.setState(
        produce(this.state, (draft) => {
          draft.outputMapping[idx].entityVariable = entity.entityVariable;
        })
      );
    };

    Popup.open(
      <WorkFlowProcessListPopup
        callbackFnc={callbackFnc}
        workflow={this.props.workflow}
        nodes={this.props.nodes}
        edges={this.props.edges}
        iterator={this.props.iterator}
        compId={this.props.compId}
        getAccessibleEntityList={this.getAccessibleEntityList}
      />,
      {
        style: {
          content: {
            width: "550px",
          },
        },
      }
    );
  }

  onChangeInputField(e, idx) {
    this.setState(
      produce(this.state, (draft) => {
        draft.inputMapping[idx][e.target.id] = e.target.value;
      })
    );
  }

  onChangeOutputField(e, idx) {
    this.setState(
      produce(this.state, (draft) => {
        draft.outputMapping[idx][e.target.id] = e.target.value;
      })
    );
  }

  onRefreshService(e) {
    this.setInOutEntity({ serviceUid: this.state.serviceUid });
  }

  /**
   * CS 파일을 사용하여 만든 service 검색
   * incompleteYn이 적용된 경우에만 활성화 된다.
   */
  onClickServiceConnect = (e) => {
    const { tenantId, coCd, appReleaseId } = this.props.workspace;
    const state = { ...this.state };
    this.setState({
      isLoading: true,
    });
    WorkflowService.getWorkflowByServiceId(
      { ...this.state, tenantId, coCd, appReleaseId },
      async (res) => {
        if (!res.data)
          return Message.alert(
            "해당 ID로 등록된 서비스가 없습니다.",
            Enums.MessageType.WARN
          );
        // 호출한 service로 부터 inputList, outputList 호출
        const { inputDataType, inputList, outputList } = getInOutputEntity(
          res.data
        );
        // 이전기록 (파일에서 가져왔을때) 그대로 유지 후 merge 진행
        const prevInputMapping = [...state.inputMapping];
        const prevOutputMapping = [...state.outputMapping];

        state.isLoading = false;
        state.serviceId = res.data.serviceId;
        state.inputDataType = inputDataType;

        //파일에서 가져왔을때 데이터가 있으면 해당 데이터 넣고 없으면 공백 처리
        state.inputMapping = inputList.map((input, index) => {
          return {
            inputId: input.inputVariable,
            inputNm: input.description,
            value: prevInputMapping[index] ? prevInputMapping[index].value : "",
          };
        });
        state.outputMapping = outputList.map((outputValue, index) => {
          if (typeof outputValue === "string") {
            return {
              entityNm: "",
              entityVariable: outputValue,
              value: prevOutputMapping[index]
                ? prevOutputMapping[index]
                : outputValue,
            };
          } else {
            return {
              entityNm: outputValue.entityNm,
              entityVariable: outputValue.entityVariable,
              value: prevOutputMapping[index]
                ? prevOutputMapping[index].entityVariable
                : outputValue.entityVariable,
            };
          }
        });
        delete state.incompleteYn;
        this.setState(state);
      },
      () => {
        this.setState({
          isLoading: false,
        });
      }
    );
  };

  renderFooter() {
    return (
      <>
        {StringUtils.equalsIgnoreCase(this.state.incompleteYn, "Y") && (
          <UModal.Footer.ProgressButton
            side="left"
            onClick={this.onClickServiceConnect}
            variant="outline-success"
            doing={this.state.isLoading}
            doingText="불러오는 중"
          >
            서비스 연결
          </UModal.Footer.ProgressButton>
        )}

        <UModal.Footer.Button onClick={this.validateCommonParam}>
          확인
        </UModal.Footer.Button>
      </>
    );
  }

  renderBody() {
    return (
      <>
        <Row className="mb-3">
          <Col xs={2} className="col-label ">
            <Form.Label className="required">서비스 선택</Form.Label>
          </Col>
          <Col xs={5}>
            <InputGroup>
              <Form.Control
                value={this.state.serviceId}
                placeholder="서비스를 선택해주세요."
                readOnly
                onChange={(e) => {}}
                size="sm"
              />
              {!StringUtils.isEmpty(this.state.serviceId) ? (
                <Tooltip title="서비스 In & Output 정보 갱신" placement="top">
                  <Button
                    size="sm"
                    variant="outline-success"
                    onClick={this.onRefreshService}
                  >
                    <BiRefresh size={20} />
                  </Button>
                </Tooltip>
              ) : (
                <></>
              )}

              <Button
                size="sm"
                variant="outline-secondary"
                onClick={this.onOpenWorkflowList}
              >
                <AiOutlineEllipsis size={20} />
              </Button>
            </InputGroup>
          </Col>
          <Col xs={5}>
            <Form.Control
              value={this.state.serviceName}
              onChange={(e) => {}}
              placeholder="서비스 명"
            />
          </Col>
        </Row>
        <Row className="grid-title">
          <Col xs={10}>
            <Form.Label>
              서비스
              <span style={{ color: "limegreen" }}> 실행 전</span> Input Mapping
            </Form.Label>
          </Col>

          <Col xs={2} className="fr">
            <Button variant="outline-primary" onClick={this.onAddInput}>
              필드 추가
            </Button>
          </Col>
        </Row>
        <div className="grid-wrapper">
          <div className="grid-body">
            <Row>
              <Col className="header" xs={4}>
                Input Field ID
              </Col>
              <Col className="header" xs={4}>
                Input Field 명
              </Col>
              <Col className="header" xs={4}>
                값
              </Col>
            </Row>
            {this.state.inputMapping.map((field, idx) => {
              return (
                <Row>
                  <Col xs={4} className="cell">
                    <Form.Control
                      size={"sm"}
                      id={"inputId"}
                      onChange={(e) => this.onChangeInputField(e, idx)}
                      value={field.inputId}
                    />
                  </Col>
                  <Col xs={4} className="cell">
                    <Form.Control
                      size={"sm"}
                      id={"inputNm"}
                      onChange={(e) => this.onChangeInputField(e, idx)}
                      value={field.inputNm}
                    />
                  </Col>
                  <Col xs={4} className="cell">
                    <InputGroup>
                      <Form.Control
                        size={"sm"}
                        id={"value"}
                        onChange={(e) => this.onChangeInputField(e, idx)}
                        value={field.value}
                      />
                      <Button
                        size="sm"
                        variant="outline-secondary"
                        name="value"
                        onClick={(event) =>
                          this.onOpenDataBindingPopup({
                            event,
                            type: START_PROCESS_DATA_TYPE.ENTITY_FIELD,
                            index: idx,
                          })
                        }
                      >
                        <AiOutlineEllipsis />
                      </Button>
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={(e) => this.onDeleteInput(e, idx)}
                      >
                        <MdDelete />
                      </Button>
                    </InputGroup>
                  </Col>
                </Row>
              );
            })}
          </div>
        </div>

        <div className="grid-title">
          <Form.Label>
            서비스
            <span style={{ color: "tomato" }}> 실행 후 </span>
            Output Mapping
          </Form.Label>
        </div>
        <div className="grid-wrapper">
          <div className="grid-body">
            <Row>
              <Col className="header" xs={4}>
                Entity 변수
              </Col>
              <Col className="header" xs={4}>
                Entity 명
              </Col>
              <Col className="header" xs={4}>
                Return Entity
              </Col>
            </Row>
            {(this.state.outputMapping && this.state.outputMapping.length) >
            0 ? (
              <>
                {this.state.outputMapping.map((output, idx) => {
                  return (
                    <Row key={output.value}>
                      <Col className="cell" xs={4}>
                        <InputGroup>
                          <Form.Control
                            size={"sm"}
                            value={output.entityVariable}
                            id={"entityVariable"}
                            onChange={(e) => this.onChangeOutputField(e, idx)}
                          />
                          <Button
                            size="sm"
                            variant="outline-secondary"
                            name="entityVariable"
                            onClick={(e) => this.onOpenEntityList(e, idx)}
                          >
                            <AiOutlineEllipsis />
                          </Button>
                        </InputGroup>
                      </Col>
                      <Col className="cell" xs={4}>
                        <Form.Control
                          size={"sm"}
                          value={output.entityNm}
                          id={"entityNm"}
                          onChange={(e) => this.onChangeOutputField(e, idx)}
                        />
                      </Col>
                      <Col className="cell" xs={4}>
                        <Form.Control
                          size={"sm"}
                          value={output.value}
                          readOnly
                          onChange={(e) => {}}
                        />
                      </Col>
                    </Row>
                  );
                })}
              </>
            ) : (
              <>
                <Row>
                  <Col xs={4} />
                  <Col
                    xs={4}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Form.Label>Return Entity가 없습니다.</Form.Label>
                  </Col>
                  <Col xs={4} />
                </Row>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default ServiceModal;
