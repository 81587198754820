import { InputLabel, Select } from "@mui/material";
import MSelectbox from "components/common/element/MSelectbox";
import StringUtils from "components/common/utils/StringUtils";
import User from "components/common/utils/UserUtils";
import { useEffect, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { BiRefresh } from "react-icons/bi";
import AppService from "services/common/AppService";
import ArrayUtils from "../utils/ArrayUtils";
import CommonUtils from "../utils/CommonUtils";

const ModuleSelector = ({
  value,
  onChange,
  type = "m",
  showAll = false, // 권한에 상관없이 전체 노출 여부
  size,
  style,
  id,
  name,
  showEmpty = false, // "선택" 옵션 노출 여부
  disabled,
  options = {
    matchCd: "moduleCd",
    matchNm: "moduleNm",
  },
  appId,
  placeholder,
  moduleCreatable = false,
  moduleCreateCb,
  ...propertyProps
}) => {
  const [moduleList, setModuleList] = useState([]);
  const [selectedModule, setSelectedModule] = useState(value);

  useEffect(() => {
    getModuleList();
  }, [appId]);

  const getModuleList = () => {
    if (!appId) return false;
    const authType = User.getAuthType(appId);

    AppService.getAppModuleList({ appId }, (moduleRes) => {
      const _moduleList = moduleRes.data;
      AppService.getApp({ appId }, (appRes) => {
        const app = appRes.data;
        if (
          StringUtils.equalsIgnoreCase(
            CommonUtils.getAppConfig(app, "appAccessibleYn"),
            "Y"
          )
        ) {
          setModuleList(_moduleList);
          if (_moduleList.length === 1) {
            onChangeModule({
              target: { id, value: _moduleList[0][options.matchCd] },
            });
          }
        } else {
          let myAuthModule = [];
          if (
            showAll ||
            StringUtils.equalsIgnoreCase("S", authType) ||
            StringUtils.equalsIgnoreCase("AM", authType)
          ) {
            myAuthModule = _moduleList;
          } else {
            const availableModuleCodes = User.selectAvailableModuleCds(appId);
            myAuthModule = _moduleList.filter((_m) => {
              return availableModuleCodes.find(
                (_a) => _a.moduleCd === _m.moduleCd
              );
            });
          }
          setModuleList(myAuthModule);
          if (myAuthModule.length === 1) {
            onChangeModule({
              target: { id, value: myAuthModule[0][options.matchCd] },
            });
          }
        }
      });
    });
  };

  useEffect(() => {
    if (value) {
      setSelectedModule(value);
    }
  }, [value]);

  const onChangeModule = (e) => {
    setSelectedModule(e.target.value);

    if (onChange) {
      onChange(e);
    }
  };

  if (appId && moduleCreatable && ArrayUtils.isEmpty(moduleList)) {
    return (
      <InputGroup>
        <Form.Control
          defaultValue={"생성할 수 있는 모듈이 없습니다. 모듈을 생성해주세요."}
          disabled
        />
        <Button onClick={moduleCreateCb}>모듈 생성</Button>
        <Button variant="outline-success" onClick={getModuleList}>
          <BiRefresh />
        </Button>
      </InputGroup>
    );
  } else if (type === "m") {
    return (
      <MSelectbox
        fullWidth
        size={"small"}
        style={{ ...style }}
        {...propertyProps}
        data={
          showEmpty
            ? [
                {
                  moduleCd: "",
                  moduleNm: `${placeholder ? placeholder : "선택"}`,
                },
                ...moduleList,
              ]
            : [...moduleList]
        }
        options={options}
      >
        <InputLabel id="module-label">모듈</InputLabel>
        <Select
          id={StringUtils.defaultString(id, "codeDtlNm")}
          name={StringUtils.defaultString(name, "codeDtlNm")}
          labelId="module-label"
          label="모듈"
          fullWidth
          color="primary"
          value={selectedModule}
          onChange={onChangeModule}
          disabled={disabled}
        ></Select>
      </MSelectbox>
    );
  } else if (type === "u") {
    return (
      <Form.Select
        id={StringUtils.defaultString(id, "codeDtlNm")}
        name={StringUtils.defaultString(name, "codeDtlNm")}
        onChange={onChangeModule}
        value={value}
        disabled={disabled}
        {...propertyProps}
      >
        {showEmpty && (
          <option value="">{placeholder ? placeholder : "선택"}</option>
        )}
        {moduleList.map((_module) => {
          return (
            <option value={_module[options.matchCd]} key={_module.moduleCd}>
              {_module[options.matchNm]}
            </option>
          );
        })}
      </Form.Select>
    );
  } else if (type === "b") {
    return (
      <select
        id={StringUtils.defaultString(id, "codeDtlNm")}
        name={StringUtils.defaultString(name, "codeDtlNm")}
        onChange={onChangeModule}
        value={value}
        style={{
          width: "100%",
          borderRadius: "5px",
          padding:
            size === "sm"
              ? "3px"
              : size === "md"
              ? "5px"
              : size === "lg"
              ? "8px"
              : "5px",
          paddingLeft: "5px",
          ...style,
        }}
        disabled={disabled}
        {...propertyProps}
      >
        {showEmpty && (
          <option value="">{placeholder ? placeholder : "선택"}</option>
        )}
        {moduleList.map((_module) => {
          return (
            <option value={_module[options.matchCd]} key={_module.moduleCd}>
              {_module[options.matchNm]}
            </option>
          );
        })}
      </select>
    );
  }
};

export default ModuleSelector;
