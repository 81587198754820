import * as Enums from "components/builder/BuilderEnum";
import {
  initActive,
  initOutput,
} from "components/builder/ui/reducers/UIBuilderAction";
import Api from "components/common/Api";
import Message from "components/common/Message";
import SessionUtils from "components/common/utils/SessionUtils";
import User from "components/common/utils/UserUtils";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import SettingService from "services/common/SettingService";
import UserService from "services/common/UserService";

function UnissoLogin() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const token = params.get("AccessToken");

  const ssoLogin = (accessToken) => {
    Api.post("/unite/builder/checkSSO", { accessToken }, (res) => {
      UserService.SSOLogin(
        { userId: res.data },
        (res) => {
          const { isSuccess, message } = res.data;
          if (isSuccess === "Y") {
            SessionUtils.set(res.data);
            SettingService.getUserConfig({}, (res) => {
              const userConfig = {};
              res.data.map((config) => {
                const key = config.configurationCode;
                const value = JSON.parse(config.configurationValue);
                userConfig[key] = value;
              });
              User.setConfiguration(userConfig);
            });
            dispatch(initOutput());
            dispatch(initActive());
            navigate(
              Enums.BuilderPath.UI.MAIN + "/" + Enums.BuilderPath.UI.EDITOR
            );
          } else {
            Message.alert(message, Enums.MessageType.ERROR);
            navigate(Enums.BuilderPath.LOGIN);
          }
        },
        (err) => {
          Message.alert(
            "접속 중 오류가 발생하였습니다.",
            Enums.MessageType.ERROR
          );
          navigate(Enums.BuilderPath.LOGIN);
        }
      );
    });
  };

  const ssoLoginTest = () => {
    // u1erp gun.han ID -> 테스트용 토큰
    const token =
      "eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiJCMjQwNTAxIiwibG9naW5JZCI6ImRhZWh5dW4ueXVuIiwiYXVkIjoiZjJmZTBkYzQtMzJjNS00Yjc3LTkwZmUtZmZiY2NiZTMxOWM0IiwiZXhwIjoxNzI1MDU4NjEyLCJpc3MiOiJodHRwczovL3Nzby5iaXplbnRyby5jb20ifQ.19cDHCDbfF85ZXucKe2klk1prCcKQWnocTIa5McvPIdnjeKq7F1U5qYceQtjNORFy-UlbFf1i4eO5nXpFSbeCg";
    // "eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiJCMjIwNTAyIiwibG9naW5JZCI6ImtpeW91bmcucGFyayIsImF1ZCI6ImYyZmUwZGM0LTMyYzUtNGI3Ny05MGZlLWZmYmNjYmUzMTljNCIsImV4cCI6MTcyNTA2Mjk4MCwiaXNzIjoiaHR0cHM6Ly9zc28uYml6ZW50cm8uY29tIn0.Z2w-dFfFrPiVAuWAROD76jFWotuSgv59ALIRZHiPvbOa_9Z_BIsx8bSbWbDZz6lsH8ixCwMoGq_KoxgmfmmkxA";
    ssoLogin(token);
  };

  ssoLogin(token);

  return (
    <div>
      연결 중입니다.
      {/* <Button onClick={ssoLoginTest}>확인</Button> */}
    </div>
  );
}

export default UnissoLogin;
