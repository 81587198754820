import React, { useEffect, useState } from "react";
import CodeMirror from "@uiw/react-codemirror";
import { useSelector } from "react-redux";
import { javascript } from "@codemirror/lang-javascript";

function EventHandlerCodeMirror({ show, ...props }) {
  const [sourceViewOptions, setSourceViewOptions] = useState({
    containEditorAttr: false,
  });
  const eventHandler = useSelector((state) => state.eventHandler);
  return (
    <div className={`edit-source ${show ? "show" : "hidden"}`}>
      <div className="codeMirror-area">
        <CodeMirror
          value={eventHandler.output}
          className="source-container"
          height="calc(100vh - 30px - 50px)"
          extensions={[javascript(true)]}
          autoFocus={false}
          editable={sourceViewOptions.containEditorAttr}
        />
      </div>
    </div>
  );
}

export default EventHandlerCodeMirror;
