import Api from "components/common/Api";

class DbPatchService {
  static getDBPatchList(data, callbackFnc) {
    const body = { ...data };
    Api.post("/common/getDBPatchList", body, callbackFnc);
  }

  static saveDbPatch(data, callbackFnc) {
    const body = { ...data };
    Api.post("/common/saveDBPatch", body, callbackFnc);
  }

  static getAppVersionList(data, callbackFnc) {
    const body = { ...data };
    Api.post("/common/getAppVersionList", body, callbackFnc);
  }

  static getMaxDbPatchNo(data, callbackFnc) {
    const body = { ...data };
    Api.post("/common/getMaxDbPatchNo", body, callbackFnc);
  }

  static deleteDbPatch(data, callbackFnc) {
    const body = { ...data };
    Api.post("/common/deleteDbPatch", body, callbackFnc);
  }
}

export default DbPatchService;
